import { useRef, useState } from "react"
import regularExpression from "../../utils/regularExpression"
import utils from "../../utils"
import styled from "styled-components"
import InputBox, { TEXT_INPUT_TYPE } from "./ui/InputBox"
import { User } from "../../model/User"
import { useRecoilState } from "recoil"
import { IUserAuthInfo, authState } from "../../states/authStates"
import { IUserInfo, userInfoState } from "../../states/userInfoState"
import { useNavigate } from "react-router"
import PopupController from "../../controller/PopupController"
import { ERROR_TYPE } from "../../interfaces/Error"


interface PasswordEditProps {
  pwExpiredUser:boolean
  onSubmit:Function
  onCancel:Function
  isAdmin?:boolean
}

interface PasswordEditState {
  pw:string
  pwError:string
  confirmPw:string
  confirmPwError:string
  currentPw:string
  currentPwError:string
}

const PasswordEdit = (props: PasswordEditProps) => {
  const [ authInfo, setAuthInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  const user = User()
  const navigate = useNavigate()
  const popupController = PopupController.getInstance()

  const [ state, _setState ] = useState<PasswordEditState>({
    pw: '',
    pwError:'',
    confirmPw: '',
    confirmPwError:'',
    currentPw: '',
    currentPwError:'',
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const inputHandler = (target:string, value:any) => {
    let errorText:string = ''

    switch (target) {
      case 'currentPw':
        let pwErrorText = state.pwError
        if (state.pwError === '사용중인 비밀번호와 일치합니다.') {
          pwErrorText = ''
        }
        setState({
          ...state,
          currentPw: value,
          currentPwError: errorText,
          pwError: pwErrorText
        })
        break
      case 'pw':
        if (value !== '') {
          if (value.length < 9 || value.length > 100 || value.match(regularExpression.regExp.PW_REG_EXP) === null || value.match(regularExpression.regExp.PW_REG_SPECIAL_SYMBOLS) !== null) {
            errorText = '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
          } else if(/(\w)\1\1/.test(value) || /(\$|\@|\!|\%|\*|\#|\?|\&)\1\1/.test(value) || utils.pwContinue(value)) {
            errorText = '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다'
          }
        }
        setState({
          ...state,
          pw: value,
          pwError: errorText,
          confirmPwError: value !== state.confirmPw && state.confirmPw !== '' ? '비밀번호가 일치하지 않습니다.' : '' 
        })
        break
      case 'confirmPw':
        if (value !== '') {
          if (value !== stateRef.current.pw) {
            errorText = '비밀번호가 일치하지 않습니다.'
          }
        }
        setState({
          ...state,
          confirmPw: value,
          confirmPwError: errorText
        })
        break
    }
  }

  const pwEditPassHandler = async (e:any) => {
    try{
      e.preventDefault()
      const response = await user.pwEditPass(Number(userInfo?.userNo))
      if(response === 200) {
        if(authInfo) {
          setAuthInfo({
            ...authInfo,
            isPasswordExpired: false
          })
        }
        navigate('/workspaces')  
      }
    } catch (error: any) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - d3d1f3')
      return
    }
  }

  const submitHandlerPw = async (e:any) => {
    try {
      e.preventDefault()
      let blankErrorFlag:boolean = false
      let validationErrorFlag:boolean = false
      let currentPwError:string = state.currentPwError
      let pwError:string = state.pwError
      let confirmPwError:string = state.confirmPwError
      let confirmErrorFlag:boolean = false

      if (state.currentPw === '') {
        blankErrorFlag = true
        currentPwError = '필수 입력 항목입니다.'
      }

      if (state.pw === '') {
        blankErrorFlag = true
        pwError = '필수 입력 항목입니다.'
      } else {
        if (state.pw.length < 9 || state.pw.length > 100 || state.pw.match(regularExpression.regExp.PW_REG_EXP) === null || state.pw.match(regularExpression.regExp.PW_REG_SPECIAL_SYMBOLS) !== null) {
          pwError = '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
          validationErrorFlag = true
        } else if (/(\w)\1\1/.test(state.pw) || /(\$|\@|\!|\%|\*|\#|\?|\&)\1\1/.test(state.pw) || utils.pwContinue(state.pw)) {
          pwError = '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다'
          validationErrorFlag = true
        } else if (state.pw === state.currentPw) {
          pwError = '사용중인 비밀번호와 일치합니다.'
          validationErrorFlag = true
        }
      }

      if (state.confirmPw === '') {
        blankErrorFlag = true
        confirmPwError = '필수 입력 항목입니다.'
      } else {
        if (state.confirmPw !== state.pw) {
          confirmPwError = '비밀번호가 일치하지 않습니다.'
          confirmErrorFlag = true
        }
      }

      if(blankErrorFlag === true || validationErrorFlag === true) {
        setState({
          ...state,
          currentPwError: currentPwError,
          pwError: pwError,
          confirmPwError: confirmPwError
        })
      }
      if(blankErrorFlag === true) {
        popupController.confirm('필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      } else if (blankErrorFlag === false && state.pw !== state.confirmPw) {
        popupController.confirm('비밀번호가 일치하지 않습니다. 다시 입력해 주세요.')
      } else if (blankErrorFlag === false && validationErrorFlag === true ) {
        popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      } else {
        let payload = {
          userNo: userInfo?.userNo,
          userId: userInfo?.userId,
          orgPw: state.currentPw,
          pw: state.pw,
        }
        const response = await props.onSubmit(payload)
        if (response && response.error === ERROR_TYPE.WRONGPW) {
          throw new Error(ERROR_TYPE.WRONGPW)
        }
      }
    }  catch(e){
      if ((e as Error).message === ERROR_TYPE.WRONGPW) {
        popupController.confirm('사용중인 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.')
        setState({
          ...state,
          currentPwError: '사용중인 비밀번호와 일치하지 않습니다.'
        })
      } else {
        // 여기서 api 호출을 하지 않음, api 호출해주는 부분에서 에러 코드 띄워주기 때문에 여긴 주석처리 함
        return
      }
    }
  }

  const cancelHandler = (e:any) => {
    e.preventDefault()
    props.onCancel()
  }

  return (
    <PasswordEditFragment>
      <div className="formWrap">
        <div className="titleWrap">
          <h1>비밀번호 변경</h1>
          {props.pwExpiredUser ?
            <p><span style={{ color: "#4B82FF" }}>60일 이상 동일 비밀번호를 사용 중입니다. </span>소중한 개인 정보 보호를 위해 비밀번호를 주기적으로 변경해 주세요.</p>
            : <p>{props.isAdmin? 
                '안전한 비밀번호로 내정보를 보호하세요.' : 
                '안전한 비밀번호로 내정보를 보호하세요. 관리자에 의해 초기화 된 비밀번호는 반드시 새 비밀번호로 변경해 주세요.'}</p>}
        </div>
        <form>
          <div className="formGroup">
            <label className="label">현재 비밀번호 *</label>
            <div className="inputWrap">
              <div className='inputBox'>
                <InputBox id="currentPw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder='현재 사용중인 비밀번호를 입력하세요.' value={state.currentPw} warning={state.currentPwError} onChange={inputHandler} />
              </div>
            </div>
          </div>
          <div className="formGroup">
            <label className="label">새 비밀번호 *</label>
            <div className="inputWrap">
              <div className='inputBox'>
                <InputBox id="pw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder="신규 비밀번호를 입력하세요." value={state.pw} warning={state.pwError} onChange={inputHandler} />
              </div>
            </div>
          </div>
          <div className="formGroup">
            <label className="label">새 비밀번호 확인 *</label>
            <div className="inputWrap">
              <div className='inputBox'>
                <InputBox id="confirmPw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder="신규 비밀번호를 한번 더 입력하세요." value={state.confirmPw} warning={state.confirmPwError} onChange={inputHandler} />
              </div>
            </div>
          </div>
          <div className="btnWrap">
            {props.pwExpiredUser === true ?
              <button className="btn grey" onClick={pwEditPassHandler}>60일 후 변경</button> :
              <button className="btn grey" onClick={cancelHandler}>취소</button>
            }
            <button className="btn blue" onClick={submitHandlerPw}>변경하기</button>
          </div>
        </form>
      </div>
    </PasswordEditFragment>
  )
}

const PasswordEditFragment = styled.div`
.formWrap{
  max-width:830px; min-height:800px; width:100%; padding:0 50px;
  .titleWrap { display:flex; align-items:center; gap:10px; margin-bottom:40px; color:#303030; }
  .titleWrap h1 { font-size:24px; }
  .titleWrap p { font-size:14px; }
  .formGroup + .formGroup { margin-top:20px; }
  .label { font-weight:bold }
  .btn.blue { display:block; width:240px; margin:120px auto 0; }
  .btnWrap { display:flex; align-items:center; justify-content:center; gap:10px; margin-top:120px; }
  .btnWrap .btn { width:240px; margin:0; }
}
`
export default PasswordEdit