import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { NavigateFunction, Params } from 'react-router-dom'
import ISelectOption from '../../../interfaces/SelectOption'
import Select from '../ui/Select'
import regularExpression from '../../../utils/regularExpression'
import PopupController from '../../../controller/PopupController'
import InputBox, { TEXT_INPUT_TYPE } from '../ui/InputBox'
import { Image, IImageRegistry } from "../../../model/Image"
import ToastEvent from "../../../events/ToastEvent"

interface ICommitPopupProps {
  navigate?:NavigateFunction
  params?:Params
  onClose:Function
  name:string
  namespace:string
}

interface ICommitPopupState {
  registryList:IImageRegistry[]
  registrySelectList:ISelectOption[]
  imageInfo:string
  registry:IImageRegistry
  regIp:string
  regPort:number
  repository:string
  tag:string
  imageName:string
  error: {
    registry:string
    tag:string
    imageName:string
    repository:string
  }
  duplicationCheckFlag:boolean
}

const CommitPopup = (props:ICommitPopupProps) => {
  const regExp = regularExpression.regExp
  
  const [ state, _setState ] = useState<ICommitPopupState>({
    registryList: [],
    registrySelectList: [{
      label: '저장할 이미지 레지스트리를 선택해 주세요.',
      value: ''
    }],
    imageInfo:'',
    registry: {
      name:'',
      regIp:'',
      regPort:0,
      regType:0},
    regIp: '',
    regPort: 0,
    repository: '',
    tag: '',
    imageName: '',
    error: {
      registry: '',
      tag: '',
      imageName: '',
      repository: ''
    },
    duplicationCheckFlag: false
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const selectRegRef = useRef<any>()

  const popupController = PopupController.getInstance()

  useEffect(() => {
    getRegistryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRegistryData = async () => {
    try {
      const response = await Image.getRegList()
      let registryList:ISelectOption[] = []
  
      for (let key in response.regList) {
        if(response.regList[key].regType === 0) {
          registryList.push({
            fakeLabel: '공용 이미지 레지스트리',
            label: response.regList[key].name,
            value: key
          })
        } else if(response.regList[key].regType === 1) {
          registryList.push({
            fakeLabel: '사용자 이미지 레지스트리',
            label: response.regList[key].name,
            value: key
          })
        } else {
          registryList.push({
            fakeLabel: response.regList[key].name.substring(7) +' 이미지 레지스트리',
            label: response.regList[key].name, 
            value: key
          })  
        }
      }
      setState({
        ...state,
        registryList: response.regList,
        registrySelectList: registryList
      })
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 69fcbf')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const registrySelectHandler = (data:ISelectOption) => {

    const registry = state.registryList[Number(data.value)]
    if(registry) {
      setState ({
        ...state,
        registry: registry,
        regIp: registry.regIp,
        regPort: registry.regPort,
        error: {
          ...state.error,
          registry:''
        }
      })
    }
  }

  const inputHandler = (target:string, value:any, idx:number = 0) => {
    let error: boolean = false

    switch (target) {
      case 'imageName':
        error = value === '' ? false : !regExp.IMAGE_NAME_RULE.test(value)
        setState({
          ...state,
          imageName: value,
          error: {
            ...state.error,
            imageName: error ? '영어 소문자, 숫자, 특수문자(-)(/)(.)만 입력 가능합니다.' : ''
          },
          duplicationCheckFlag: false
        })
        break
      case 'tag':
        error = value === '' ? false : !regExp.IMAGE_NAME_RULE.test(value)
        setState({
          ...state,
          tag: value,
          error: {
            ...state.error,
            tag: error ? '영어 소문자, 숫자, 특수문자(-)(/)(.)만 입력 가능합니다.' : ''
          },
          duplicationCheckFlag: false
        })
        break
    }
  }

  const checkDuplicateHandler = async (callbackPopupFlag:boolean = true) => {
    try {
      const result:boolean = await Image.checkDuplicateName(stateRef.current.regPort, stateRef.current.imageName, stateRef.current.tag, props.namespace)
      if (result === false) {
        setState({
          ...state,
          duplicationCheckFlag: false,
          error:{
            ...state.error,
            repository: '사용 중인 이미지이름:태그입니다. 이미지 이름 또는 태그를 수정해 주세요.'
          }
        })
        popupController.confirm('사용 중인 이미지이름:태그입니다. 이미지 이름 또는 태그를 수정해 주세요.')
        return false
      } else {
        if (callbackPopupFlag === true) {
          setState({
            ...state,
            duplicationCheckFlag: true,
            error:{
              ...state.error,
              repository: ''
            }
          })
          popupController.confirm('사용 가능한 이미지 이름:태그입니다.')
        }
        return true
      }  
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - b88241')
    }
  }

  const submitHandler = async () => {
    // let errorFlag = false
    let blankErrorFlag = false
    let validationErrorFlag = false
    let registryError = ''
    let imageNameError = ''
    let tagError = ''
    let repositoryError = ''
    //duplicationCheckFlag가 true면 commit가능
    
    setState({
      ...state,
      repository: state.imageName + state.tag
    })

    if (state.registry.name === '') {
      blankErrorFlag = true
      registryError = '필수 선택 항목입니다.' //wsform에 동일문구적혀있음 기획서상문구와 다름 맞추는게좋을거같아 그대로 함
    } else {
      registryError = ''
    }

    if (state.imageName.trim() === '') {
      blankErrorFlag = true
      imageNameError = '필수 입력 항목입니다.'
    }
    if (!regExp.IMAGE_NAME_RULE.test(state.imageName.trim())) {
      validationErrorFlag = true
      imageNameError = '영어 소문자, 숫자, 특수문자(-)(/)(.)만 입력 가능합니다.'
    }
    if (state.tag.trim() === '') {
      blankErrorFlag = true
      tagError = '필수 입력 항목입니다.'
    }
    if (!regExp.IMAGE_NAME_RULE.test(state.tag.trim())) {
      validationErrorFlag = true
      tagError = '영어 소문자, 숫자, 특수문자(-)(/)(.)만 입력 가능합니다.'
    }
    
    if (blankErrorFlag === false && validationErrorFlag === false && state.duplicationCheckFlag === true) {
      try {
        const response = await Image.commit(props.name, props.namespace, stateRef.current.registry.name, stateRef.current.registry.regIp, stateRef.current.registry.regPort, stateRef.current.imageName, stateRef.current.tag)
        if(response === 200) {
          let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          toastEvent.payload = { message: `[${stateRef.current.imageName}:${stateRef.current.tag}] 이미지 커밋이 신청되었습니다.` }
          window.dispatchEvent(toastEvent)
          closeHandler()
        }
      } catch(error:any) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 244c02')
        return
      }
    } else if(blankErrorFlag || validationErrorFlag) {
      setState({
        ...state,
        error: {
          tag: tagError,
          imageName: imageNameError,
          repository: repositoryError,
          registry: registryError
        }
      })
      if(blankErrorFlag && !validationErrorFlag) {
        popupController.confirm('입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      } else if (!blankErrorFlag && validationErrorFlag) {
        popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      } else if(blankErrorFlag && validationErrorFlag) {
        popupController.confirm('입력 값이 없거나 잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      }
    } else if (state.duplicationCheckFlag === false) {
      repositoryError = '이미지 이름:태그의 중복 여부를 확인해 주세요.'
      setState({
        ...state,
        error: {
          tag: tagError,
          imageName: imageNameError,
          repository: repositoryError,
          registry: registryError
        }
      })
      popupController.confirm('이미지 이름:태그의 중복 여부를 확인해 주세요.')
    } 
  }

  return (
    <CommitPopupFragment>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
      <button onClick={closeHandler} className="btnClose">닫기</button>
          <div className="pageTitle">
            이미지 커밋 설정
          </div>
        <div className="scrollWrap">
          <div className="formGroup bothEnd">
            <label className="inputBoxTitle" style={{ paddingTop: "10px" }}>
              저장 위치*
            </label>
            <div className="inputWrap selectWrap">
              <Select selectRef={selectRegRef} option={state.registrySelectList} placeholder={'저장할 이미지 레지스트리를 선택해 주세요.'} onChange={registrySelectHandler} />
              {state.error.registry ? <p className="message">{state.error.registry}</p> : false}
              <div style={{ marginTop: '10px' }}>
                {state.registry.name === '' ? <div className="imageNameCheck"></div> : <div className="imageNameCheck">{stateRef.current.regIp + ':' + stateRef.current.regPort}
                </div>}
              </div>
            </div>
            <div className="endBox"></div>
          </div>
          <div className="formGroup bothEnd smallGroup">
            <label className="inputBoxTitle">이미지 이름*</label>
            <div className="inputWrap">
              <div className="inputBox">
                <InputBox id="imageName" type={TEXT_INPUT_TYPE.TEXT} value={state.imageName} maxLength={63} placeholder="커밋할 이미지의 이름을 입력해 주세요." warning={state.error.imageName} onChange={inputHandler} />
              </div>
            </div>
            <div className="endBox"></div>
          </div>
          <div className="formGroup bothEnd smallGroup" style={{marginTop:'20px'}}>
            <label className="inputBoxTitle">태그*</label>
            <div className="inputWrap">
              <div className="inputBox">
                <InputBox id="tag" type={TEXT_INPUT_TYPE.TEXT} value={state.tag} maxLength={63} placeholder="커밋할 이미지의 태그를 입력해 주세요." warning={state.error.tag} onChange={inputHandler} />
              </div>
            </div>
            <div className="endBox"></div>
          </div>
          <div className="formGroup bothEnd">
            <div className="startBox"></div>
            <div className="imageName">
              <div className={state.error.repository ? 'font-red' : ''}>
                {!state.registry.name/*  && !stateRef.current.imageName && !stateRef.current.tag */ ? <div className="imageNameCheck">이미지 이름 : 태그명</div> : <div className="imageNameCheck">{stateRef.current.regIp + ':' + stateRef.current.regPort + ' / ' + stateRef.current.imageName + ':' + stateRef.current.tag}
                </div>}
              </div>
            </div>
            <button className={"btn outline " + (state.registry.name !== '' && state.imageName !== '' && state.tag !== '' && !state.error.imageName && !state.error.tag ? '' : 'disabled')} onClick={() => checkDuplicateHandler()}>중복 확인</button>
          </div>
          {state.error.repository ? <p style={{marginLeft:'115px'}}className="message">{state.error.repository}</p> : false}
        </div>
        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          <button className={"btn blue"} onClick={(e) => { submitHandler() }}>{'커밋하기'}</button>
        </div>
      </form>
    </CommitPopupFragment>
  )
}

const CommitPopupFragment = styled.div`
  .formWrap { display:flex; flex-direction:column; width:800px; min-height:541px; padding:50px 50px 20px; box-sizing:border-box; }
  .scrollWrap { overflow:auto; flex:0.65 0; margin-top:30px; min-height:390px;}
  .serviceTypeTitleBox { display:flex; gap:5px; margin-bottom:10px; }
  .pageTitle { flex:none; margin-bottom:0px; }
  p { font-size:14px; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:15px; }
  .btnWrap .btn { width:188px; height:40px; line-height:40px; }
  form .formGroup { width:700px; }
  form .formGroup + .formGroup { margin-top:40px;}
  .smallGroup {align-items:center;}

  .inputGroup { margin-left:10px; }
  .inputWrap { flex: none; }
  .inputWrap p { width:465px; }
  .size p { width:68px; }
  .inputBox + .inputBox { margin-top: 30px; }
  .inputBox.inputBoxTitle:not(:first-of-type) { padding-top: 20px; margin-top:0px; }
  .inputBox.inputBoxTitle + .inputBox { margin-top:7px; }
  form .inputBox .input { width:496px; max-width:496px; }
  form .inputBox input { width:496px; }
  .inputBox .btn { width: 79px; white-space:pre; }
  .inputBox { font-size:14px; }
  .inputBoxTitle { width:85px; font-size:14px; }
  .label, .inputBoxTitle { font-weight:500; }
  .message { font-size:12px; margin: 5px 0 0 15px; }
  img.disabled { opacity:0; visibility:hidden }
  button.disabled { opacity:0.5; pointer-events:none }

  .inputBox .stringLengthBox { position:relative; }
  .inputBox .stringLengthBox p { font-size:14px; position:absolute; bottom:10px; right:0px; width:60px; text-align:right; padding-right:15px; }
  .inputBox .numOfCharBox { width:512px; }
  .inputBox .numOfCharBox .input { padding-right:63px; }
  .testbox .input { width:400px !important; }
  
  .imageNmaeLabel { margin:0 0 10px 0; }
  .imageNmaeLabel p { font-size:14px; }
  .image + .image { margin-top:15px; }
  .imageNameCheck { width:467px; background-color:#F5F5F5; border-radius:2px; padding:10px 15px; min-height:20px; font-size:14px; word-break: break-all;}
  .inputWrap .imageName {width:510;}

  .endBox { width:80px; }
  .startBox { width:85px; }
  .font-red { color:#FF0000; }
`

export default CommitPopup