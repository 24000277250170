import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ResourceGroup } from "../../../model/ResourceGroup"
import RadioBox from '../ui/RadioBox'
import PopupController from '../../../controller/PopupController'

export interface IVolume {
  pvc:string
  mountPath:string
}

interface IVolumePopupProps {
  idx: number
  rgroup: string
  onClose: Function
  onSelected: Function
  preSelectedVolume: string
  preSelectedVolumes?: IVolume[] // 비활성용 (중복 방지)
  isDev?: boolean
}

interface IVolumePopupState {
  items: any[]
  selected: (string | number)[]
  //preSelected: string[] // 비활성용 (중복 방지)
}

const VolumePopup = (props: IVolumePopupProps) => {
  const popupController = PopupController.getInstance()
  const [state, setState] = useState<IVolumePopupState>({
    items: [],
    selected: [],
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const volumes = (await ResourceGroup.getRgroupVolume(props.rgroup)).result.volumeList
  
      const items = []
      for (let eachVolume of volumes) {
        // if(eachVolume.isUserVol === true) {
        //   eachVolume.fakeName = '사용자 개인 볼륨'
        // }
        items.push(eachVolume)
      }

      let index = -1
      if (props.preSelectedVolume) {
        // let index = (props.isDev && props.isDev === true) ?
        //   state.items.findIndex(i => i === props.preSelectedVolume?.pvc) :
        //   state.items.findIndex(i => i.name === props.preSelectedVolume?.pvc)
        index = items.findIndex(i => i.name === props.preSelectedVolume)
      }

      setState({
        ...state,
        items: items,
        selected: index >= 0 ? [Number(index)+1] : [0]
      })

    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 928f5f')
    }
  }

  const radioHandler = (id: string) => {
    setState({
      ...state,
      selected: [Number(id)]
    })
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    if (props.onSelected) {
      if(Number(state.selected[0]) > 0) {
        props.onSelected(state.items[Number(state.selected[0])-1])
      }
      else {
        props.onSelected('')
      }
      // if(props.isDev && props.isDev === true){
      //   props.onSelected(props.idx, state.items[Number(state.selected[0])])
      // }
      // else {
      //   props.onSelected(props.idx, state.items[Number(state.selected[0])].name)
      // }
    }
    closeHandler()
  }

  return (
    <VolumePopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
      <h2 className="pageTitle"> {/* style={{display:'flex', alignItems:'end'}} */}
        볼륨 선택
        {/* <Tooltip des="볼륨 선택 툴팁" /> */}
        <span className="total"><b>{state.items.length}</b>개</span>
      </h2>
      {state.items.length > 0 ?
        <>
          <div className="scrollWrap">
            <table>
              <colgroup>
                <col width="45px" />
                <col width="45px" />
              </colgroup>
              <tbody>

                {/* 설정 없음 */}
                <tr>
                  <td>
                    <RadioBox id={'0'} checked={state.selected.indexOf(0) !== -1}
                     onChange={(id) => { radioHandler(id) }} />
                  </td>
                  <td className='col1'></td>
                  <td><div>{'설정 없음'}</div> </td>
                </tr>

                { /* 볼륨 리스트 */
                  state.items.map((item: any, idx: number) => {
                    let isPreSelected = false //this.props.isDev && this.props.isDev === true? this.state.preSelected?.indexOf(item) !== -1: this.state.preSelected?.indexOf(item.name) !== -1 // 비활성용 (중복 방지)
                    return (
                      <tr key={idx} className={state.selected.indexOf(idx+1) !== -1 ? 'selected' : ''}>
                        <td className="col0">
                          <RadioBox /* name={this.tableName} */ id={`${idx+1}`} checked={state.selected.indexOf(idx+1) !== -1}
                            onChange={(id) => { radioHandler(id) }} disabled={isPreSelected} />
                        </td>
                        <td className={isPreSelected? 'disabledData col1' : 'col1'}>{idx + 1}.</td>
                        <td className={'col2'}>
                          {/* <div className={isPreSelected? 'disabledData' : ''}>{(props.isDev && props.isDev === true)? `${item}`: `${item.name} / ${item.type}`}</div> */}
                          <div className={isPreSelected? 'disabledData' : ''}>{item.fakeName ? `${item.fakeName}` : `${item.name}`}</div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </>
        :
        <>
          <div className="scrollWrap">
            <div className="noData">볼륨이 없습니다.</div>
          </div>
        </>
      }
      <div className="btnWrap">
        <button className="btn grey" onClick={closeHandler}>취소</button>
        {state.items.length > 0 && state.selected.length > 0 ?
          <button className="btn blue" onClick={submitHandler}>가져오기</button> :
          <button className="btn off">가져오기</button>
        }
      </div>
    </VolumePopupFragment>
  )
}

const VolumePopupFragment = styled.div`
	display:flex;flex-direction:column;width:677px;height:calc(100vh - 186px);max-height:682px;padding: 50px 50px 20px;box-sizing:border-box;
	.pageTitle{flex:none;}
	p{font-size:14px;}
	.scrollWrap{overflow:auto;flex:1;margin-top:50px;}
	.btnWrap{flex:none;display:flex;justify-content:center;gap:20px;margin-top:20px;}
	.btnWrap .btn{width:188px;height:40px;line-height:40px;}
	table{font-size:14px; border-collapse: collapse; width:100%;}
	table tbody tr td {border-top: 1px solid #DFDFDF;border-bottom: 1px solid #DFDFDF; height:42px;}
	.noData{text-align:center;color:#A7A7A7;font-size:20px; display:flex; justify-content:center; align-items:center;height:100%; margin-top:-30px;}
	.disabledData{color:gray;}
	.col1{padding:0 15px 0 20px;}
  tbody tr:hover td {background:#fafafa}
  .selected td {background: #e1f5ff !important;}
`

export default VolumePopup