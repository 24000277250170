import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'

interface ICheckboxProps {
  name?:string
  id:string
  checked:boolean
  label?:string
  onChange:(id:string) => void
  disabled?:boolean
}

interface ICheckboxState {
  checked:boolean
}

const RadioBox = (props:ICheckboxProps) => {
  const [ state, setState ] = useState<ICheckboxState>({
    checked: false
  })
  const prevState = Utils.usePrevState(state)

  useEffect(() => {
    if (prevState.checked !== props.checked) {
      // props updated
      setState({
        ...state,
        checked: props.checked
      })
    } else if (prevState.checked === props.checked && props.checked !== state.checked) {
      setState({
        ...state,
        checked: props.checked
      })
    } else if (prevState.checked !== state.checked) {
      // stats updated
    }
  }, [prevState.checked, props.checked, state.checked])

  return (
    <RadioBoxFragment>
      <input className="hide" type="radio" name={props.name} id={props.id} checked={state.checked} disabled={props.disabled && props.disabled === true ? true : false} onChange={(e) => {
        setState({
          ...state,
          checked: !state.checked
        })
        props.onChange(props.id)
      }} />
      <label htmlFor={props.id}>
        <span className="box"></span>
        {props.label ? <span className="text">{props.label}</span> : false}
      </label>
    </RadioBoxFragment>
  )
}

const RadioBoxFragment = styled.div`
  position:relative;
  input { position:absolute; left:0; top:0; width:0; height:0; opacity:0; }
  label { display:flex; align-items:center; justify-content:center; gap:10px; padding:11px 0;}
  label .box { position:relative; display:block; width:16px; height:16px; border:2px solid #ddd; border-radius:50%; background:#fff; }
  label .text { font-size:14px; }
  input:checked + label .box { border-color:#2c78ff; }
  input:checked + label .box:before { content:''; position:absolute; left:3px; top:3px; width:10px; height:10px; border-radius:50%; background:#2c78ff; }
  input:disabled + label .box { border-color:gray; background:lightgray; }
`

export default RadioBox
