import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import PopupController from "../../../controller/PopupController"
import { IEmail, Service } from "../../../model/Service"
import { SORT_ORDER } from "../../../model/BaseDataType"
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../uiv2/Button"
import Table, { CHECKBOX_TYPE, TABLE_CELL_TYPE } from "../uiv2/Table"
import TableEvent from "../../../events/TableEvent"

interface IEmailPopupProps {
  onClose:Function
  onSelected:Function
  preSelected:IEmail[]
}
interface IEmailPopupState {
  items:any[]
  selected:IEmail[]
  preSelected:IEmail[]
}

const EmailPopup = (props:IEmailPopupProps) => {
  const popupController = PopupController.getInstance()

  const [state, setState] = useState<IEmailPopupState>({
    items: [],
    selected: [],
    preSelected: []
  })

  const tableHeadData = [
    {
      label: '이름',
      key: 'name',
      type: TABLE_CELL_TYPE.STRING,
      sortKey: 'name',
      sort: SORT_ORDER.ASC,
      orderFlag: true,
    }, {
      label: '이메일',
      key: 'email',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: true,
      sort: false,
    }
  ]

  const tableRef = useRef<any>()

  useEffect(() => {
    getData()

    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, getData)
    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, getData)
    }
  }, [])

  const getData = async () => {
    try {
      let emailArray:IEmail[]=[]
      let selectedEmailArray:IEmail[]=[]

      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      const response = await Service.getEmailList('', sortOrder)
      if (response) {
        // 데이터 정리 - (이름 없음) 추가
        for(let idx in response) {
          let email = response[idx]
          emailArray.push({
            name: email.name !== null ? email.name : '(이름 없음)',
            email: email.email,
            selected: false
          })
        }

        // props.selected
        for (let idx in props.preSelected) {
          let addIdx = response.findIndex((e:IEmail) => e.email === props.preSelected[Number(idx)].email)
          if (addIdx !== -1) {
            emailArray[addIdx].selected = true
            selectedEmailArray.push(emailArray[addIdx])
          }
        }

        setState({
          items: emailArray,
          selected: selectedEmailArray,
          preSelected: selectedEmailArray
        })  
      }
    } catch (error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - a85bd2')
    }
  }

  const selectBoxCheckHandler = () => {
    const selectedIdx = tableRef.current?.selected
    if (selectedIdx && selectedIdx.length > 0) {
      let selectedArray: IEmail[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(state.items[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    let selectedEmail = state.selected.concat([])
    for(let email of selectedEmail){
      if(email.name === '(이름 없음)') {
        email.name = ''
      }
    }
    if (props.onSelected) props.onSelected(selectedEmail)
    closeHandler()
  }

  return (
    <EmailPopupFragment>
      <div className="pageTitle">이메일 주소</div>
      <div className="scrollWrap">
        <Table checkBoxType={CHECKBOX_TYPE.USE_ALL} colWidth={[20, 200, null]} ref={tableRef}
          headerLabel={tableHeadData}
          items={state.items}
          noData={'데이터가 없습니다.'}
          preselectedKey="selected"
          checkBoxPreserveKeys={["selected"]}
          onSelect={selectBoxCheckHandler} />
      </div>
      <div className="btnWrap">
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={closeHandler}>취소</Button>
        { state.selected.length > 0 || state.preSelected.length ? 
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>{state.selected.length > 0 ? state.selected.length + '개 ' : false}선택완료</Button> :
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} disabled={true}>선택완료</Button>
        }
      </div>
    </EmailPopupFragment>
  )
}

const EmailPopupFragment = styled.div`
  display:flex; flex-direction:column; width:600px; height:calc(100vh - 186px); max-height:800px; padding:24px 6px 24px 32px; box-sizing:border-box;
  .pageTitle{font-size:24px; line-height:28.64px; font-weight:700;}
  p{font-size:14px;}
  .scrollWrap{overflow:auto;flex:1;margin-top:24px; padding-right:20px;}
	.btnWrap{flex:none;display:flex;justify-content:end;gap:12px;margin-top:24px; padding-right:26px;}
  table td{padding:11.5px 12px;}
`

export default EmailPopup