import React, { Component } from 'react'
import styled from 'styled-components'

interface INoDataComponentProps {
  height:number
}

interface INoDataComponentState {
}

export default class NoDataComponent extends Component<INoDataComponentProps, INoDataComponentState> {
  /*constructor(props:any) {
    super(props)
  }*/

  public render():React.ReactElement {
    return (
      <NoDataComponentFragment style={{height: this.props.height+'px'}}>
        데이터가 없습니다.
      </NoDataComponentFragment>
    )
  }
}

const NoDataComponentFragment = styled.div`
  display:flex;align-items:center;justify-content:center;background: #f5f5f5;font-size:18px;color:#646469;
`