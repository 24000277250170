import { useEffect, useState } from "react"
import styled from "styled-components"

import PopupController from "../../controller/PopupController"
import ModalEvent from "../../events/ModalEvent"
import MessagePopup from "./modal/MessagePopup"
import MessagePopupOps from "./modalv2/MessagePopup"
import Config from "../../Config"

interface ModalMessageState {
  modalQueue:any[]
}

const ModalMessage = () => {
  const [state, setState] = useState<ModalMessageState>({
    modalQueue: []
  })

  const openMessageHandler = (e:ModalEvent) => {
    const newQueue = state.modalQueue
    newQueue.push(e.payload)
    setState({
      modalQueue: newQueue
    })
  }

  const closeMessageHandler = (e:ModalEvent) => {
    let newQueue:any[] = state.modalQueue
    if (e) {
      switch(e.type) {
        case ModalEvent.CLOSE_MESSAGE:
          newQueue.shift()
          break
        case ModalEvent.CLEAR_MESSAGE:
          newQueue = []
          break
      }
    } else {
      newQueue.shift()
    }
    setState({
      modalQueue: newQueue
    })
  }

  const popupController = PopupController.getInstance()
  useEffect(() => {
    popupController.addEventListener(ModalEvent.OPEN_MESSAGE, openMessageHandler)
    popupController.addEventListener(ModalEvent.CLOSE_MESSAGE, closeMessageHandler)
    popupController.addEventListener(ModalEvent.CLEAR_MESSAGE, closeMessageHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.OPEN_MESSAGE, openMessageHandler)
      popupController.removeEventListener(ModalEvent.CLOSE_MESSAGE, closeMessageHandler)
      popupController.removeEventListener(ModalEvent.CLEAR_MESSAGE, closeMessageHandler)
    }
  },[])

  return (
    <ModalMessageFragment>
    {
      state.modalQueue.length > 0 ?
        <div className="modalWrap">
            {Config.env.REACT_APP_MODE === 'OPS' ?
              <div className="modalOps">
                <div className="modalContent">
                  <MessagePopupOps onClose={closeMessageHandler} message={state.modalQueue[0].message} button={state.modalQueue[0].button} /* title={state.modalQueue[0].title} */ />
                </div>
              </div>
              :
              <div className="modal">
                <div className="modalContent">
                  <MessagePopup onClose={closeMessageHandler} message={state.modalQueue[0].message} button={state.modalQueue[0].button} />
                </div>
              </div>
            }
        </div>
      : false
    }
    </ModalMessageFragment>
  );
}

const ModalMessageFragment = styled.div`
  .modalWrap{position:fixed;top:0;left:0;width:100%;height:100vh;display:flex;align-items:center;justify-content:center;background:rgba(0, 0, 0, 0.3);z-index:999;}
  .modal{position:relative;display:flex;flex-direction:column;background:#fff;border-radius:2px}
  .btnClose{position:absolute;right:10px;top:10px;width:30px;height:30px;background:url(/images/ico-close.png) no-repeat center / 14px 14px;font-size:0;}
  
  //ops
  .modalOps{position:fixed;top:88px;display:flex;flex-direction:column;background-color:#444444;border-radius:12px;color:#FFFFFF;font-size:16px !important;line-height:24px;font-weight:400;}
`

export default ModalMessage;
