import React,{ Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalEvent,{ MODAL_TYPE } from '../../events/ModalEvent'
import ImagePopup from './modal/ImagePopup'
// import InfoPopup from './modal/InfoPopup'
// import ServiceTypePopup from './modal/ServiceTypePopup'
// import EmailPopup from './modal/EmailPopup'
// import VolumePopup from './modal/VolumePopup'
// import LogPopup from './modal/LogPopup'
// import PortEditPopup from './modal/PortEditPopup'
// import GroupUserPopup from './modal/GroupUserPopup'
// import NodeListPopup from './modal/NodeListPopup'
// import PvListPopup from './modal/PvListPopup'
// import PvcCreatePopup from "./modal/PvcCreatePopup"
// import GroupNamePopup from "./modal/GroupNamePopup"
// import ServiceTransferPoup from './modal/ServiceTransferPopup'
import MigPopup from "./modal/MigPopup"
import PopupController from '../../controller/PopupController'
import ResourceLimitPopup from './modal/ResourceLimitPopup'
import GroupNamePopup from './modal/GroupNamePopup'
import VolumePopup from './modal/VolumePopup'
// import WatingQueuePopup from './modal/WatingQueuePopup'
import GroupUserPopup from './modal/UserListPopup'
import NodeListPopup from './modal/NodeListPopup'
import PvListPopup from './modal/PvListPopup'
import PvcCreatePopup from './modal/PvcCreatePopup'
import PortEditPopup from './modal/PortEditPopup'
import CommitPopup from "./modal/CommitPopup"
import EmailPopup from './modalv2/EmailPopup'
import ServiceTypePopup from './modalv2/ServiceTypePopup'
import ImageListPopup from './modalv2/ImageLlistPopup'
import VolumeListPopup from './modalv2/VolumeListPopup'
import VersionDetailPopup from './modalv2/VersionDetailPopup'
import PortFormPopup from './modalv2/PortFormPopup'
import LogPopup from './modalv2/LogPopup'
import ServiceEventPopup from './modalv2/ServiceEventPopup'
import WaitingQueuePopup from './modal/WaitingQueuePopup'
import Config from '../../Config'

interface IModalProps {}
interface IModalState {
  modalType:MODAL_TYPE|null
  modalData:any
}

const Modal = (props:IModalProps) => {
  const [ state, setState ] = useState<IModalState>({
    modalType: null,
    modalData: {}
  })
  const openModalHandler = (e:ModalEvent):void => {
    //prevent add event listener
    document.addEventListener('keydown', preventEnterKey)
    setState({
      modalType: e.modalType,
      modalData: e.payload
    })
  }

  const closeModalHandler = (e:ModalEvent):void => {
    //prevent remove event listener
    document.removeEventListener('keydown', preventEnterKey)
    setState({
      modalType: null,
      modalData: {}
    })
  }

  const preventEnterKey = (e:any) => {
    if(document.activeElement?.classList.contains('submitButton')) {
      // do nothing
    } else {
      if(e.keyCode === 13) {  
        e.preventDefault()
      }  
    }
  }

  const popupController = PopupController.getInstance()
  useEffect(() => {
    popupController.addEventListener(ModalEvent.OPEN_MODAL, openModalHandler)
    popupController.addEventListener(ModalEvent.CLOSE_MODAL, closeModalHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.OPEN_MODAL, openModalHandler)
      popupController.removeEventListener(ModalEvent.CLOSE_MODAL, closeModalHandler)
    }
  }, [])

  return(
    <ModalFragment>
      {
        state.modalType !== null ?
          <div className="modalWrap">
            <div className={Config.env.REACT_APP_MODE === 'OPS' ? 'modalOps' : 'modal'}>
              <div className="modalContent">
                {
                  state.modalType === MODAL_TYPE.IMAGE ?
                  <ImagePopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} jupyterFlag={state.modalData.jupyterFlag} 
                  rgroup={state.modalData.rgroup} preSelectedReg={state.modalData.preSelectedReg} preSelectedImage={state.modalData.preSelectedImage} isCommon={state.modalData.isCommon} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.IMAGE_OPS ?
                  <ImageListPopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} jupyterFlag={state.modalData.jupyterFlag} 
                  namespace={state.modalData.namespace} preSelectedReg={state.modalData.preSelectedReg} preSelectedImage={state.modalData.preSelectedImage} isCommon={state.modalData.isCommon} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.VOLUME ?
                  <VolumePopup onClose={closeModalHandler} idx={state.modalData.idx} rgroup={state.modalData.rgroup} preSelectedVolume={state.modalData.preSelectedVolume} onSelected={state.modalData.onSelected} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.MIG_SETTING ?
                  <MigPopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} groupName={state.modalData.nodeName} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.RESOURCE_LIMIT ?
                  <ResourceLimitPopup onClose={closeModalHandler} resourceLimit={state.modalData.resourceLimit} userInfo = {state.modalData.userInfo} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.GROUP_NAME ?
                  <GroupNamePopup onClose={closeModalHandler} onSuccess={state.modalData.onSuccess}/> : false
                }
                {
                  state.modalType === MODAL_TYPE.GROUP_USER ?
                  <GroupUserPopup onClose={closeModalHandler} groupName={state.modalData.groupName} onSelected={state.modalData.onSelected} preSelectedUser={state.modalData.preSelectedUser} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.NODE ?
                  <NodeListPopup onClose={closeModalHandler} groupName={state.modalData.groupName} onSelected={state.modalData.onSelected} firstselectedNode={state.modalData.selected}/> : false
                }
                {
                  state.modalType === MODAL_TYPE.PV ?
                  <PvListPopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} selected={state.modalData.selected}/> : false
                }
                {
                  state.modalType === MODAL_TYPE.PVCREATE ?
                  <PvcCreatePopup onClose={closeModalHandler} groupName={state.modalData.groupName} onSelected={state.modalData.onSelected} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.PORT_EDIT ?
                  <PortEditPopup onClose={closeModalHandler} id={state.modalData.id} name={state.modalData.name} namespace={state.modalData.namespace} ports={state.modalData.ports} onSelected={state.modalData.onSelected}/> : false
                }
                {
                  state.modalType === MODAL_TYPE.COMMIT ?
                  <CommitPopup onClose={closeModalHandler} namespace={state.modalData.namespace} name={state.modalData.name}  /> : false
                }
                {
                  state.modalType === MODAL_TYPE.EMAIL ?
                  <EmailPopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} preSelected={state.modalData.selected} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.SERVICE_TYPE ?
                  <ServiceTypePopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} preSelectedProject={state.modalData.preSelectedProject} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.VOLUME_OPS ?
                  <VolumeListPopup onClose={closeModalHandler} idx={state.modalData.idx} namespace={state.modalData.namespace} preSelectedVolume={state.modalData.preSelectedVolume} onSelected={state.modalData.onSelected} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.VERSION_DETAIL ?
                  <VersionDetailPopup onClose={closeModalHandler} name={state.modalData.name} namespace={state.modalData.namespace} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.PORT_OPS ?
                  <PortFormPopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} data={state.modalData.data} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.LOG_OPS ?
                  <LogPopup onClose={closeModalHandler} data={state.modalData.data} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.SERVICE_EVENT ?
                  <ServiceEventPopup onClose={closeModalHandler} data={state.modalData.data} /> : false
                }
                {
                  state.modalType === MODAL_TYPE.WAITING_QUEUE ?
                  <WaitingQueuePopup onClose={closeModalHandler} onSelected={state.modalData.onSelected} resourceGroup={state.modalData.resourceGroup} /> : false
                }
              </div>
            </div>
          </div>
        : false
      }
      </ModalFragment>
  )
}

const ModalFragment = styled.div`
  .modalWrap{position:fixed;top:0;left:0;width:100%;height:100vh;display:flex;align-items:center;justify-content:center;background:rgba(0, 0, 0, 0.3);z-index:998;}
  .modal{position:relative;display:flex;flex-direction:column;background:#fff;border-radius:2px}
  .modalOps{position:relative;display:flex;flex-direction:column;background:#fff;border-radius:12px}
  .btnClose{position:absolute;right:10px;top:10px;width:30px;height:30px;background:url(/images/ico-close.png) no-repeat center / 14px 14px;font-size:0;}
`

export default Modal