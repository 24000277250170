import EllipsisToolTip from "ellipsis-tooltip-react-chan"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import dayjs from 'dayjs'
import Config from '../../Config'
import Button, { BUTTON_COLOR, BUTTON_LINK, BUTTON_SIZE } from "../components/uiv2/Button"
import { NavigateFunction, Params, useParams, useNavigate } from 'react-router-dom'
import ISelectOption from "../../interfaces/SelectOption"
import { Service, IServiceDetail, IProject, SERVICE_STATUS} from "../../model/Service"
import { IChartSeries } from "../../interfaces/Chart"
import PageEvent from "../../events/PageEvent"
import PopupController from "../../controller/PopupController"
import ModalEvent, { MODAL_TYPE } from "../../events/ModalEvent"
import { ERROR_TYPE } from "../../interfaces/Error"
import Select from "../components/uiv2/Select"
import { SUBPOPUP_TYPE } from "../components/modal/sub/SubPopup"  //DEV에있는 SubPopup에 있는것이라 추후 DEV에있는것 삭제되지않게 조심
import SubPopupList from "../components/modalv2/sub/SubPopupList"
import SubPopupTable from "../components/modalv2/sub/SubPopupTable"
import { RgroupInfo } from "../../model/Workspace"
import ToastEvent from "../../events/ToastEvent"
import DateRangePicker from "../components/uiv2/DateRangePicker"
import LineChart from "../components/chart/LineChart"
import NoData from '../components/NoData'
import { useRecoilState } from "recoil"
import { IUserInfo, userInfoState } from "../../states/userInfoState"
import utils from "../../utils"
import WebSocketController from '../../controller/WebSocketController'
import WebSocketEvent from '../../events/WebSocketEvent'

interface OperatingTimeState {
  operatingTime: {
    day:number,
    hour:number,
    min:number,
    sec:number
  }
}

interface IServiceDetailProps {
  navigate?:NavigateFunction
  params?:Params
  searchParams?:URLSearchParams
}

interface IServiceDetailState {
  serviceId:number
  serviceNameList:ISelectOption[]
  serviceName:ISelectOption
  serviceDetail:IServiceDetail
  day:number
  hour:number
  minute:Number
  limit: {
    from:string
    to:string
  }
  commandModal:boolean
  envinronmentModal:boolean
  argumentModal:boolean
  voluemMountModal:boolean
  nodeModal:boolean
  volumeModal:boolean
  portModal:boolean
  autoscaleModal:boolean
  emailModal:boolean

  startTimestamp:number
  endTimestamp:number

  initialFrom:string
  initialTo:string
}

interface IEmailState {
  IsEmailLineBreak:boolean
}

interface IServiceProjectState {
  projectList:IProject[]
}

interface IServiceRgroupState {
  rgroupInfo:RgroupInfo
}

interface IChartDataState {
  chartData:{
    request:IChartSeries[]
    totalRequest:IChartSeries[]
    succResponse:IChartSeries[]
    failResponse:IChartSeries[]
    totalLatency:IChartSeries[]
    infLatency:IChartSeries[]
  }
}

interface IRangeState {
  range: {
    from:string
    to:string
  }
}

const ServiceDetail = (props:IServiceDetailProps) => {
  const pickerRef = useRef<any>()
  const emailRef = useRef<any>()
  const params = useParams()
  const navigate = useNavigate()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  let creationTimestamp:number = 0
  const startFrom:string = dayjs(creationTimestamp * 1000).format('YYYY-MM-DD HH:mm')  //기존에는 계정의 start.
  //let defaultFrom:string = dayjs().subtract(12, 'hour').unix() < creationTimestamp ? startFrom : dayjs().subtract(12, 'hour').format('YYYY-MM-DD HH:mm')
  //let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const [ state, _setState ] = useState<IServiceDetailState>({
    serviceId: (params?.id ? Number(params?.id) : -1),
    serviceNameList: [],
    serviceName: { label: '', value: '' },
    serviceDetail: {
      id:0, 
      rgroup:'-',
      isCommon:false, 
      project:'',
      name:'-',
      startedAt:null,
      deletedAt:null,
      ports: [{ autoFlag:false, internal:'-', external:'-' }],
      resourceBlock:{
        cpu:0, mem:0, memUnit:'-', type:'-', realType:null, gpuMem:null, gpuMemUnit:null, gpuPer:null, isPartedGpu:false, gpuNum:null
      },
      replicas:0,
      readyReplicas:0,
      statusId:0,
      ip:'-',
      workingTime:0,
      command:[],
      args:[],
      env:[],
      volumes:[],
      emails:[],
      recentDeployTimestamp:0,
      projectList:[]
    },
    day: 0,
    hour: 0,
    minute: 0,
    limit: {
      from: '-',//defaultFrom,
      to: '-'//defaultTo
    },
    commandModal:false,
    envinronmentModal:false,
    argumentModal:false,
    voluemMountModal:false,
    nodeModal:false,
    volumeModal:false,
    portModal: false,
    autoscaleModal: false,
    emailModal: false,
    
    startTimestamp: 0,
    endTimestamp:0,

    initialFrom:'',
    initialTo:'',
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  const prevState = utils.usePrevState(state)

  const [ emailLinestate, _setEmailLine ] = useState<IEmailState>({
    IsEmailLineBreak: false
  })
  const emailLineRef = useRef(emailLinestate)
  const setEmailLine = (data:any) => {
    emailLineRef.current = data
    _setEmailLine(data)
  }

  const [ chartData, _setChartData ] = useState<IChartDataState>({
    chartData: {
      request: [],
      totalRequest: [],
      succResponse: [],
      failResponse: [],
      totalLatency: [],
      infLatency: []
    }
  })

  const chartDataRef = useRef(chartData)
  const setChartData = (data:any) => {
    chartDataRef.current = data
    _setChartData(data)
  }

  const [ project, setProject ] = useState<IServiceProjectState>({
    projectList:[{
      id:0,
      name:'-',
      statusId:0
    }]
  })

  const [ rgroup, setRgroup ] = useState<IServiceRgroupState>({
    rgroupInfo:{ name:'-', nodeList: [{ name:'-', ip:'-' }],
      volumeList: [{ name:'-', size:'-', type:'-'}],
      reg:'-',
      isCommon:false
    }
  })

  const [ optime, setOptime ] = useState<OperatingTimeState>({
    operatingTime: {
      day:0,
      hour:0,
      min:0,
      sec:0
    },
  })

  const [ rangestate, setRangeState ] = useState<IRangeState>({
    range: {
      from: '-',//defaultFrom,
      to: '-'//defaultTo
    }
  })

  const [monitoringFlag, setMonitoringFlag] = useState<boolean>(false)

  const popupController = PopupController.getInstance()
  const webSocketController = WebSocketController.getInstance()

  useEffect(() =>{
    getData(Number(params.id))
    //window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    webSocketController.addEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)
    return() => {
     // window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      //window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      webSocketController.removeEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)
    }
  },[])

  const autoRefreshHandler = async () => {

  }

  const webSocketActionHandler = (e:WebSocketEvent) => {
    switch (e.payload.action) {
      case 'servicereload':
        if(e.payload.id === Number(params.id)) getData(Number(params.id))
        break
    }
  }

  useEffect(() => {
    if (rangestate.range.from !== '-') {
      if (monitoringFlag === true) {
        getMonitoringData(rangestate.range)
      } else {
        setMonitoringFlag(true)
      }
    }
  }, [rangestate.range])

  useEffect(() => {
    if(state.serviceDetail.project !== prevState.serviceDetail.project) {
      if(state.serviceDetail.project === null) {
        getData(Number(params.id))
      }
    }
  }, [state.serviceDetail.project])
  
  const getData = async (serviceId:string | number) => {
    //console.log('getData', state.serviceDetail.project)
    try {
      const response = await Service.getDetail(serviceId)
      let list: ISelectOption[] = [], selectedLabel: string = '', selectedValue: string = ''
      response.projectList.forEach((project:any) => {
        let statusText
        switch (project.statusId) {
          case 1:
            statusText = '(장애) '
            break
          case 2:
            statusText = '(대기) '
            break
          case 3:
            statusText = '(주의) '
            break
          case 4:
            statusText = ''
            break
          case 5:
            statusText = '(중지) ' //중지됨
            break
          case 7:
            statusText = '(중지중) ' //중지중
            break
          default:
            statusText = ''
        }
        list.push({ label: `${statusText}${project.name}`, value: project.name })

        if (project.id + '' === serviceId + '') {
          selectedLabel = `${statusText}${project.name}`
          selectedValue = project.name
        }

        // if(response.service?.startedAt) {
        //   if(state.endTimestamp - response.service.startedAt < 86400){ //24시간 넘었는지 체크
        //     // 24시간 미만 - defaultFrom/initialFrom을 진짜 시작 시간(api에서 받은 startTimestamp)으로 바꿔주기
        //     //console.log('24시간 미만!')
        //     defaultFrom = dayjs(Number(response.service.startedAt)*1000).format('YYYY-MM-DD HH:mm')
        //     setState({
        //       ...state,
        //       startTimestamp: response.service.startedAt,
        //       range: {
        //         from: defaultFrom,
        //         to: defaultTo
        //       }
        //     })
        //   }
        //   else {
        //     //24시간 이상 - 그대로 두기
        //   }
        // }
      })

      let initialFrom = ''
      let initialTo = ''
      let limitFrom = ''
      let limitTo = ''
      if (response.service.deletedAt) {  //회수, 반납된, 장애가 난 워크스페이스인경우
        limitFrom = dayjs(response.service.startedAt * 1000).format('YYYY-MM-DD HH:mm')
        limitTo = dayjs(response.service.deletedAt * 1000).format('YYYY-MM-DD HH:mm')
        if (response.service.deletedAt - response.service.startedAt < 43200) { //  운영된시간이 12시간 미만인 경우
          initialFrom = dayjs(response.service.startedAt * 1000).format('YYYY-MM-DD HH:mm')
          initialTo = dayjs(response.service.deletedAt * 1000).format('YYYY-MM-DD HH:mm')
        } else {  //운영된 시간이 12시간 이상인 경우
          initialFrom = dayjs((response.service.deletedAt - 43200) * 1000).format('YYYY-MM-DD HH:mm')
          initialTo = dayjs(response.service.deletedAt * 1000).format('YYYY-MM-DD HH:mm')
        }
      } else {  //그외 운영중인 워크스페이스인 경우
        limitFrom = dayjs(response.service.startedAt * 1000).format('YYYY-MM-DD HH:mm')
        limitTo = dayjs().format('YYYY-MM-DD HH:mm')
        if (Math.floor(Date.now() / 1000) - response.service.startedAt < 43200) { //  운영된 시간이 12시간 미만인 경우
          initialFrom = dayjs(response.service.startedAt * 1000).format('YYYY-MM-DD HH:mm')
          initialTo = dayjs().format('YYYY-MM-DD HH:mm')
        } else { //운영된 시간이 12시간 이상인 경우
          initialFrom = dayjs().subtract(12, 'hour').format('YYYY-MM-DD HH:mm')
          initialTo = dayjs().format('YYYY-MM-DD HH:mm')
        }
      }
      parseDeploySeconds(stateRef.current.serviceDetail.workingTime)

      setState({
        ...state,
        serviceDetail: response.service,
        serviceNameList: list,
        serviceName: { label: selectedLabel, value: selectedValue },
        limit: {
          from: limitFrom,
          to: limitTo
        },
        initialFrom: initialFrom,
        initialTo: initialTo
      })

      setRgroup({
        rgroupInfo: response.resourceGroup,
      })

      setProject({
        projectList: response.projectList //쓸데가있나..?
      })

      setRangeState({
        range:{
          from: initialFrom,
          to: initialTo
        }
      })
      isLineBreak()

      window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))

      if (response.service === null) {
        navigate(-1)
      }

    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 4cf843')
      }
    }
  }

  const getServiceId = (serviceName:string):number => {
    let result:number = -1
    project.projectList.forEach((project:any)=>{
      if(project.name === serviceName){
        result = Number(project.id)
      }
    })
    
    if(result !== Number(params.id)) navigate('/service/'+result)
    return result
  }

  // const getServiceStatus = (serviceName:string) => {
  // }

  const parseChartData = (data:any) => {
    let i: number, item: any, j: number, jtem: any
    let chartData: any = []
    let dataList: any = []
    //console.log(data)
    for (i = 0; i < data.length; i++) {
      item = data[i]
      dataList = []
      for (j = 0; j < item.data.length; j++) {
        jtem = item.data[j]
        dataList.push([new Date(dayjs.unix(jtem.timestamp).utc().format()).getTime(), jtem.value])
      }
      chartData.push({
        name: item.label, 
        data: dataList
      })
    }
    return chartData
  }

  const parseDeploySeconds = (seconds:number) => {
    //console.log(seconds)
    let totalMin = Math.floor(seconds/60)
    let sec:number = seconds%60
    let min:number = totalMin%60
    totalMin = Math.floor(totalMin/60)
    let hour:number = totalMin%24
    let day:number = Math.floor(totalMin/24)

    //console.log(`${day}일 ${hour}시간 ${min}분`)

    setOptime({
      operatingTime: {
        day:day,
        hour:hour,
        min:min,
        sec:sec
      }
    })
  }

  const getMonitoringData = async (newRange:any):Promise<void> => {
    try {
     const FROM = dayjs(newRange.from).unix()
     //console.log(FROM,'from')
      const TO = dayjs(newRange.to).unix()

      if (FROM !== TO && stateRef.current.serviceDetail && stateRef.current.serviceDetail.name !=='-') {
        const data = await Service.getDetailMonitoring(stateRef.current.serviceDetail.name, stateRef.current.serviceDetail.rgroup, FROM, TO)
        //console.log(data)
        if (data) {
          let request = data.request
          for (let eachservice of request) {
            for (let eachGpuAdditionalData of data.request) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let totalRequest = data.totalRequest
          for (let eachservice of totalRequest) {
            for (let eachGpuAdditionalData of data.totalRequest) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let succResponse = data.succResponse
          for (let eachservice of succResponse) {
            for (let eachGpuAdditionalData of data.succResponse) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let failResponse = data.failResponse 
          for (let eachservice of failResponse) {
            for (let eachGpuAdditionalData of data.failResponse) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let totalLatency = data.totalLatency 
          for (let eachservice of totalLatency) {
            for (let eachGpuAdditionalData of data.totalLatency) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let infLatency = data.infLatency
          for (let eachservice of infLatency) {
            for (let eachGpuAdditionalData of data.infLatency) {
              if (eachservice.name === eachGpuAdditionalData.label) {
                eachservice.data = eachservice.data.concat(eachGpuAdditionalData.data)
              }
            }
          }

          setChartData({
            chartData: {
              request: parseChartData(data.request),
              totalRequest: parseChartData(data.totalRequest),
              succResponse: parseChartData(data.succResponse),
              failResponse: parseChartData(data.failResponse),
              totalLatency: parseChartData(data.totalLatency),
              infLatency: parseChartData(data.infLatency)
            }
          })
        } else {
          // DOES NOTHING
        }
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6f75a5')
    }
  }

  const modalActionHandler = async (e:any) => {
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)

    switch(e.payload.action) {
      case 'deleteService':
        if (state.serviceDetail !== null) {
          try {
            let deleteServicelist=[]
            deleteServicelist.push(stateRef.current.serviceDetail.id)
            const response = await Service.removeServices(deleteServicelist)
            if (response) {
              toastEvent.payload = { message: `${stateRef.current.serviceDetail.name} 서비스가 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 삭제해 주세요.` }
              window.dispatchEvent(toastEvent)
              navigate('/services')
            }
          } catch (error) {
            console.log(error)
            popupController.confirm(`${stateRef.current.serviceDetail.name} 서비스 삭제에 실패하였습니다. 다시 시도해 주세요.`)
          }
        }
        break
      case 'stopService':
        if (state.serviceDetail !== null) {
          try {
            const response = await Service.stop(stateRef.current.serviceDetail.id)
            toastEvent.payload = { message: `${stateRef.current.serviceDetail.name} 서비스 중지를 신청하였습니다. 서비스 중지를 시작합니다.` }
            window.dispatchEvent(toastEvent)
            if (response) {
              getData(stateRef.current.serviceDetail.id)
            }
          } catch (error) {
            console.log(error)
            popupController.confirm(`${stateRef.current.serviceDetail.name} 서비스 중지 신청에 실패하였습니다.`)
          }
        }
        break
      case 'restartService':
        if (state.serviceDetail !== null) {
          try {
            const response = await Service.restart(stateRef.current.serviceDetail.id)
            toastEvent.payload = { message: `${stateRef.current.serviceDetail.name} 서비스 재개를 신청하였습니다. 서비스 재개를 시작합니다.` }
            window.dispatchEvent(toastEvent)
            if (response) {
              getData(stateRef.current.serviceDetail.id)
            }
          } catch (error) {
            console.log(error)
            popupController.confirm(`${stateRef.current.serviceDetail.name} 서비스 재개 신청에 실패하였습니다.`)
          }
        }
        break
      case 'closePortPopup':
        getData(props.params?.id as string)
        break
    }
  }

  const deleteHandler = async () => {
    if (state.serviceDetail !== null) {
      popupController.dialouge(`${state.serviceDetail.name} 서비스가 삭제됩니다. 진행하시겠습니까?`,
        'deleteService', 'deleteService', '확인', '취소')
    }
  }

  const stopHandler = async () => {
    if (state.serviceDetail !== null) {
      popupController.dialouge(`${state.serviceDetail.name} 서비스가 중지됩니다. 진행하시겠습니까?`,
        'stopService', 'stopService', '확인', '취소')
    }
  }

  const onChangeDatePicker = (range:any) => {
    setChartData({
      ...chartData,
      chartData: {
        request: [],
        totalRequest: [],
        succResponse: [],
        failResponse: [],
        totalLatency: [],
        infLatency: []
      }
    })
    setRangeState({
      range: {
        from: range.from,
        to: range.to
      }
    })
  }

  const restartHandler = async () => {
    if (state.serviceDetail !== null) {
      popupController.dialouge(`${state.serviceDetail.name} 서비스가 재개됩니다. 진행하시겠습니까?`,
        'restartService', 'restartService', '확인', '취소')
    }
  }

  const updateHandler = () => {
    if (state.serviceDetail !== null) {
      navigate('/service/'+state.serviceId+'/update')
    }
  }

  const logPopupHandler = (e:any) => {
    let item:any = serviceDetail
    popupController.log(item)
  }

  const serviceEventPopupHandler = (e:any) => {
    let item:any = serviceDetail
    popupController.serviceEvent(item)
  }

  const isLineBreak = () => {
    if(Number(emailRef.current?.clientHeight) > 30){
      setEmailLine({
        ...emailLinestate, 
        IsEmailLineBreak: true
      })
    }
    else {
      setEmailLine({
        ...emailLinestate, 
        IsEmailLineBreak: false
      })
    }
  }
  
  const serviceDetail = state.serviceDetail
  const resourceGroup = rgroup.rgroupInfo
  const opTime = optime.operatingTime
  const chartDatas = chartData.chartData
  let statusColor =''
  //console.log(state.range)

  return (
    <ServiceDetailFragment>
      <div className="titleArea">
        {state.serviceDetail.project ? <div className="flexCenter font14label"><img src="/images-v2/service-label.svg" />{state.serviceDetail.project}</div> : false}
        <h1 className={'pageTitle ' + 
        (stateRef.current.serviceDetail.statusId === 1 ? statusColor = 'error' 
        : stateRef.current.serviceDetail.statusId === 2 || stateRef.current.serviceDetail.statusId === 5 || stateRef.current.serviceDetail.statusId === 7 ? 'opacity' : '')} >
          {
            state.serviceNameList && state.serviceNameList.length > 1 ?
              <Select option={state.serviceNameList} selected={state.serviceName} maxWidth={'644px'} titleStyle={true} 
              onChange={
                (e:any) => { 
                  getData(getServiceId(e.value))
                  setState({
                    ...state,
                    serviceName: {
                      value:e.value,
                      label:e.label
                    }
                  })
                }
              } />
              : <div className="text" style={{ maxWidth: '644px' }}><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{state.serviceName.label}</EllipsisToolTip></div>
          }
        </h1>
      </div>
      <div className="btnArea bothEnd">
        <div className="btnGroup">
          <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => {
            let item:any = serviceDetail
            popupController.port(() => {
              getData(serviceDetail.id)
            }, item)
            // let evt: ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
            // evt.modalType = MODAL_TYPE.PORT_OPS
            // evt.payload = {
            //   onSelected: (value: any) => {
            //     getData(serviceDetail.id)
            //   },
            //   data: serviceDetail //데이터는 규격화 되는 것이 좀 더 좋음
            // }
            // popupController.dispatchEvent(evt)
          }}>포트 설정</Button>
          <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => {logPopupHandler(e)}}>서비스 로그</Button>
          <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => {serviceEventPopupHandler(e)}}>서비스 이벤트</Button>
          <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} url={'/service/'+state.serviceId+'/version'} target={BUTTON_LINK.SELF} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => { }}>버전 목록</Button>
        </div>
        <div className="btnGroup flexCenter">
          <Button color={ BUTTON_COLOR.OUTLINE_DEFAULT} disabled={serviceDetail.statusId === SERVICE_STATUS.STOPPED ? false : true} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => {deleteHandler()}}>서비스 삭제</Button>
          <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} size={BUTTON_SIZE.MEDIUM} onClickButton={() => { serviceDetail.statusId === SERVICE_STATUS.STOPPED || serviceDetail.statusId === SERVICE_STATUS.STOPPING ? restartHandler() : stopHandler() }}>{serviceDetail.statusId === SERVICE_STATUS.STOPPED || serviceDetail.statusId === SERVICE_STATUS.STOPPING ? '서비스 재개' : '서비스 중지'}</Button>
          <span className="bar1"> </span>
          <Button color={BUTTON_COLOR.OUTLINE_SECONDARY} size={BUTTON_SIZE.MEDIUM} onClickButton={(e:any) => {updateHandler()}}>서비스 업데이트</Button>
        </div>
      </div>
      <div className="boxArea bothEnd">
        <div className="box">
          <div className="boxTop" style={{ marginBottom: "89px" }}>
            <div className="runningTime bothEnd">
              <div>총 운영 시간</div>
              <div className="recentUpdate">최근 배포 : {dayjs.unix(serviceDetail.recentDeployTimestamp).format('YYYY/MM/DD HH:mm:ss')}</div>
            </div>
            <div style={{ fontSize: "32px", fontWeight: "700", marginBottom: "8px" }}>{opTime.day.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}일 {opTime.hour}시간 {opTime.min}분 {opTime.sec}초</div>
            <div style={{ color: "var(--ui-2878791, #878791)", fontSize: "13px", display: "flex", gap: "4px" }}><img src="/images-v2/corner-down-right.svg" alt="" />서비스 시작 : {serviceDetail.startedAt ? dayjs.unix(serviceDetail.startedAt).format('YYYY/MM/DD HH:mm:ss') : '-'}</div>
          </div>
          <div className="boxMiddle bothEnd alignTop">
            <div className="leftContent">
              <div className="contentBox">
                <div className="itemTitle">이미지 이름</div>
                <div className="contents"><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.image}</EllipsisToolTip></div>
              </div>
              <div className="contentBox">
                <div className="itemTitle flexCenter">Environment{serviceDetail.env.length > 1 ?
                  <SubPopupList title={"Environment"} datas={serviceDetail.env} type={SUBPOPUP_TYPE.ENVIRONMENT} onRight={true}/>
                  /* <SubPopup titles={["Environment"]} datas={serviceDetail.env} type={SUBPOPUP_TYPE.ENVIRONMENT} /> */
                   : false}</div>
                <div className="contents">{serviceDetail.env && serviceDetail.env.length > 0 ?
                  <div className="" style={{ paddingRight: '10px' }}>
                    <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.env[0].key + "=" + serviceDetail.env[0].value}</EllipsisToolTip>
                  </div>
                  : <p className="">{'-'}</p>}</div>
              </div>
              <div className="contentBoxHorizontal">
                <div className="itemTitle">데이터 볼륨</div>
                <div className="contents" style={{ maxWidth: "100px" }}>{serviceDetail.volumes.length > 0 ?
                  <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.volumes[0].pvc}</EllipsisToolTip>
                  : <p>-</p>}</div>
              </div>
            </div>
            <div className="rightContent">
              <div className="contentBox">
                <div className="itemTitle flexCenter">Command{serviceDetail.command.length > 1 ?
                  <SubPopupList title={"Command"} datas={serviceDetail.command}/>
                  /* <SubPopup titles={["Command"]} datas={serviceDetail.command} /> */
                   : false}</div>
                <div className="contents">{serviceDetail.command.length > 0 ?
                  <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.command[0]}</EllipsisToolTip>
                  : <p className="">{'-'}</p>}</div>
              </div>
              <div className="contentBox">
                <div className="itemTitle flexCenter">Argument{serviceDetail.args.length > 1 ?
                  <SubPopupList title={"Argument"} datas={serviceDetail.args}/>
                  /* <SubPopup titles={["Argument"]} datas={serviceDetail.args} />  */
                  : false}</div>
                <div className="contents">{serviceDetail.args.length > 0 ?
                  <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.args[0]}</EllipsisToolTip>
                  : <p className="">{'-'}</p>}</div>
              </div>
              <div className="contentBoxHorizontal">
                <div className="itemTitle flexCenter">마운트 경로</div>
                <div className="contents" style={{ maxWidth: "100px" }}>{serviceDetail.volumes.length > 0 ?
                  <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.volumes[0].mountPath}</EllipsisToolTip>
                  : <p>-</p>}</div>
                {serviceDetail.volumes.length > 1 ?
                  <SubPopupTable title="데이터 볼륨 및 마운트 경로" datas={serviceDetail.volumes} tableHeaders={['데이터 볼륨','마운트 경로']}/>
                  /* <SubPopup titles={["데이터 볼륨", "마운트 경로"]} datas={serviceDetail.volumes} /> */
                   : false}
              </div>
            </div>
          </div>
          <div className="divider" style={{ margin: "18px 0 24px 0" }}></div>
          <div className="boxBottom">
            <div className="contentBoxHorizontal">
              <div className="itemTitle">접속 주소</div>
              <div className="contents" style={{ width: "206px", marginRight:"20px" }}><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.ip ?
                serviceDetail.ip : '-'}</EllipsisToolTip></div>
            </div>
            <div className="contentBoxHorizontal">
              <div className="itemTitle">내부 포트</div>
              <div className="contents" style={{ width: "69px" }}><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.ports && serviceDetail.ports.length > 0 ? serviceDetail.ports[0].internal : '-'}</EllipsisToolTip></div>
            </div>
            <div className="contentBoxHorizontal">
              <div className="itemTitle">외부 포트</div>
              <div className="contents" style={{ width: "51px" }}><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.ports && serviceDetail.ports.length > 0 ? serviceDetail.ports[0].external : '-'}</EllipsisToolTip>
              </div> {/* Subpopup쪽에 제목란 추가 해야 함 */}{serviceDetail.ports && serviceDetail.ports.length > 1 ?
                <SubPopupTable title="포트 정보" datas={serviceDetail.ports} tableHeaders={['내부 포트', '외부 포트']}/>
                /* <SubPopup titles={['내부포트', '외부포트']} datas={serviceDetail.ports} />  */
                : false}
            </div>
          </div>
        </div>
        <div className="box">
          <div className="boxTop">
            <div style={{ fontWeight: "700", marginBottom: "32px", height:"24px" }}>{resourceGroup.isCommon === true? '기본 리소스 그룹' : resourceGroup.name}</div>
            <div className="alignTop bothEnd">
              <div className="leftContent">
                <div className="contentBox">
                  <div className="itemTitle flexCenter">워커 노드 (IP/이름){resourceGroup.nodeList.length > 1 ?
                  <SubPopupList title="워커 노드 (IP/이름)" datas={resourceGroup.nodeList} type={SUBPOPUP_TYPE.NODE}/>
                  /* <SubPopup titles={["워커 노드 (IP/이름)"]} datas={resourceGroup.nodeList} type={SUBPOPUP_TYPE.NODE}/> */
                    : false}</div>
                  <div className="contents">{resourceGroup.nodeList && resourceGroup.nodeList.length > 0 ?
                    <div>{resourceGroup.nodeList[0].ip + " / " + resourceGroup.nodeList[0].name}</div>
                    : '-'}</div>
                </div>
                <div className="contentBox">
                  <div className="itemTitle">이미지 레지스트리</div>
                  <div className="contents"><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{resourceGroup.reg ? resourceGroup.reg : '-'}</EllipsisToolTip></div>
                </div>
              </div>
              <div className="rightContent">
                <div className="contentBox"> 
                  <div className="itemTitle flexCenter">볼륨 {resourceGroup.volumeList && resourceGroup.volumeList.length > 1 ?
                    <SubPopupList title="볼륨" datas={resourceGroup.volumeList} type={SUBPOPUP_TYPE.VOLUME_OPS}/>
                    /* <SubPopup titles={["볼륨"]} datas={resourceGroup.volumeList} type={SUBPOPUP_TYPE.VOLUME_OPS}/>  */
                    : false}</div>
                  <div className="contents">{resourceGroup.volumeList && resourceGroup.volumeList.length > 0 ?
                    <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{resourceGroup.volumeList[0].name + " / " + resourceGroup.volumeList[0].size}</EllipsisToolTip>
                    : '-'}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider" style={{ margin: "0 0 24px" }}></div>
          <div className="boxMiddle">
            <div className="contentBoxHorizontal" style={{ alignItems:"center", marginBottom:"24px", height:"17px" }}>
              <div className="itemTitle" style={{ margin:"0"}}>리소스 타입</div>
              <div style={{ fontSize: "14px", height:"17px"}}>
                <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{serviceDetail.resourceBlock.type === "cpu" ? 
                <div className="cpuResource">CPU resource</div> 
                : <div className="boldText">{serviceDetail.resourceBlock.type}</div>}</EllipsisToolTip></div>
            </div> 
            <div className="alignTop bothEnd resourceDetail">
              <div className="leftContent">
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">CPU</div>
                  <div className="contents">{serviceDetail.resourceBlock.cpu} 코어</div>
                </div>
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">MEM</div>
                  <div className="contents">{serviceDetail.resourceBlock.mem} {serviceDetail.resourceBlock.memUnit}</div>
                </div>
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">GPU</div>
                  <div className="contents">{serviceDetail.resourceBlock.gpuPer ? serviceDetail.resourceBlock.gpuPer+' %' : '-'}</div>
                </div>
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">GPU MEM</div>
                  <div className="contents">{serviceDetail.resourceBlock.gpuMem ? serviceDetail.resourceBlock.gpuMem+' '+serviceDetail.resourceBlock.gpuMemUnit : '-'}</div>
                </div>
              </div>
              <div className="rightContent">
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">GPU 리소스 블록 개수</div>
                  <div className="contents">{serviceDetail.resourceBlock.gpuNum ? serviceDetail.resourceBlock.gpuNum+" 개" : '-'}</div>
                </div>
                <div className="contentBoxHorizontal">
                  <div className="itemTitle">레플리카 개수</div>
                  <div className="contents">{serviceDetail.readyReplicas+ " 개 / "+ serviceDetail.replicas + " 개"}</div>
                </div>
                {/* <div> 오토스케일은 없애기로함?
                <div className="itemTitle">오토스케일</div>
                <div className="contents">{}</div>
              </div> */}
              </div>
            </div>
          </div>
          <div className="divider" style={{ margin: "18px 0 24px 0" }}></div>
          <div className="boxBottom flexCenter">
            <div className="itemTitle emailTitle">알림대상</div>
            <div className="emailContent flexCenter">
              <div className="resPreviewBox">
                <div className="resPreviewWrap" ref={emailRef}>
                  {
                    serviceDetail.emails.length > 0 ? 
                    serviceDetail.emails.map((eachEmail, idx) => {
                      return (<div key={idx}>{eachEmail.name? <span className="emailName">{eachEmail.name}<span className='smallBar'></span></span> : ''}{eachEmail.email}</div>)
                    })
                    : <span style={{fontSize:'13px', marginBottom:'6px', marginTop:'2px'}}>-</span>
                  }
                </div>
              </div>
              {emailLinestate.IsEmailLineBreak ? 
                <SubPopupTable title="알림 대상" datas={serviceDetail.emails} tableHeaders={['이름','이메일']} type={SUBPOPUP_TYPE.EMAIL}/>
                /* <SubPopup titles={["알림 대상"]} datas={serviceDetail.emails} type={SUBPOPUP_TYPE.EMAIL} onLeft={truze} />  */
                : false}</div>
          </div>
        </div>
      </div>
      <div className="dividerBig"></div>
      <div className="dateRangePicker">
        <DateRangePicker ref={pickerRef} initial={{ from:state.initialFrom, to:state.initialTo }} default={{ from:rangestate.range.from, to:rangestate.range.to }} limit={stateRef.current.limit} data={rangestate.range} onChange={onChangeDatePicker} /></div>
      <div className="chartArea">
        <div className="chartLine">
          <div className="leftChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />요청 건수</div>
            <div className="chartContent">{
                chartDatas.request.length && serviceDetail.startedAt!== null?
                  <LineChart unit="건" height={300} min={0} max={100} data={chartDatas.request} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                  <NoData height={300} />
              }</div>
          </div>
          <div className="rightChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />누적 요청 건수</div>
            <div className="chartContent">{
                chartDatas.totalRequest.length && serviceDetail.startedAt!== null?
                  <LineChart unit="건" height={300} min={0} data={chartDatas.totalRequest} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="xy" /> :
                  <NoData height={300} />
              }</div>
          </div>
        </div>
        <div className="chartLine">
          <div className="leftChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />응답 성공 건수</div>
            <div className="chartContent">{
                chartDatas.succResponse.length && serviceDetail.startedAt!== null?
                  <LineChart unit="건" height={300} min={0} max={100} data={chartDatas.succResponse} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                  <NoData height={300} />
              }</div>
          </div>
          <div className="rightChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />응답 실패 건수</div>
            <div className="chartContent">{
                chartDatas.failResponse.length && serviceDetail.startedAt!== null?
                  <LineChart unit="건" height={300} min={0} max={100} data={chartDatas.failResponse} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                  <NoData height={300} />
              }</div>
          </div>
        </div>
        <div className="chartLine">
          <div className="leftChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />응답 지연 시간</div>
            <div className="chartContent">{
                chartDatas.totalLatency.length && serviceDetail.startedAt!== null?
                  <LineChart unit="ms" height={300} min={0} data={chartDatas.totalLatency} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                  <NoData height={300} />
              }</div>
          </div>
          <div className="rightChart">
            <div className="chartTitle"><img src="/images-v2/bar-chart-2.svg" alt="그래프아이콘" />응답 관대 지연 건수</div>
            <div className="chartContent">{
                chartDatas.infLatency.length && serviceDetail.startedAt!== null?
                  <LineChart unit="건" height={300} min={0} max={100} data={chartDatas.infLatency} datetime={true} doubleY={true} legend={false} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                  <NoData height={300} />
              }</div>
          </div>
        </div>
      </div>
    </ServiceDetailFragment>
  )
}

const ServiceDetailFragment = styled.div`
.Contents { width:1312px; }
.font14label { font-size:14px; }
.boldText { font-weight:700; }
.alignTop { align-items:baseline; }
.flexCenter { display:flex; align-items:center; gap:6px; }
.titleArea { display:flex; flex-direction:column; justify-content:center; align-items:flex-start; gap:6px; align-self:stretch; margin-bottom:50px; }
.titleArea p { margin-top:0; }
.btnArea { display:flex; margin-bottom:12px;}
.btnGroup { display:flex; gap:8px; }
.selectBox { border:none; height:30px; font-size:24px !important; font-style:normal; font-weight:700; padding:0px 8px 0px 0px; } 
.dropWrap { width:max-content; }
.dividerBig { margin:80px 0; background:#D5D5DA; height: 1px;}
.dateRangePicker { margin-bottom:48px; }

.boxArea { display:flex; gap:24px; margin-bottom:80px; }
.box { width:580px; height:397px; padding:24px 32px; border-radius:6px; border: 1px solid var(--ui-2878791, #878791); background: var(--g-00-ffffff, #FFF);}
.runningTime { margin-bottom:16px; }
.recentUpdate { height:24px; display:flex; height:24px; padding:0px 8px; align-items:center; font-size: 12px; color:var(--ui-1646469, #646469); border-radius:3px; border:1px solid var(--ui-3-b-4-b-4-be, #B4B4BE);} 

.contentBox { margin-bottom:24px; width:274px; }

.itemTitle { font-size:13px; font-weight:500; margin-bottom:6px;}
.contents { color:var(--ui-2878791, #878791); font-size:13px; font-weight: 400; }
.contentBoxHorizontal { display:flex; gap:12px; }
.boxBottom { display:inline-flex; height:24px; gap:12px; }
.resourceDetail .contentBoxHorizontal { width:274px; }
.chartLine { display:flex; justify-content:space-between; gap:24px; margin-bottom:56px; }
.chartTitle { display:flex; align-items:center; font-size:14px; gap:8px; margin-bottom:20px; font-weight:700; }
.leftChart { width:644px; }
.rightChart { width:644px; }
.error .text { color:#FF0000}
.opacity .text { color:rgba(51, 51, 51, 0.40); }
.bar1 { width:1px; height:24px; background:#D5D5DA; margin:0 4px;}
.cpuResource { font-weight:700; }

.emailTitle { height:24px; align-self:start; margin-top:2px;}
.smallBar { display:inline-block; margin:0px 6px; width:1px; height:8px; background:#D5D5DA; vertical-align:middle; }

.box .resPreviewBox{max-width:490px; height:24px; overflow:hidden; display:flex; align-items:start;}
.box .resPreviewWrap{ display:flex; gap:10px; flex-wrap:wrap; }
.box .resPreviewWrap > div{color:#646469; font-size:12px; border-radius:16px; background-color:#F1F2F6; padding:4.5px 10px;}
.emailName { color:#333333; font-size:12px; font-weight: 500; }
`

export default ServiceDetail