export default class TableEvent extends Event {
  public static CHANGE_SORT_ORDER:string = 'changeSortOrder'
  public static SELECTBOX_CHECKED:string = 'selectBoxCheck'
  public static CHANGE_ORDER:string = 'changeRowOrder'
  public static RECREATE_WORKSPACE:string = 'recreateWorkspace'
  public static GROUP_ADDED_USER:string = 'groupAddedUser'
  public static GROUP_ADDED_NODE:string = 'groupAddedNode'
  public static USER_PERMITTED:string = 'userPermitted'
  public static NAMESPACE_GET:string = 'namespaceget'
  public static ACTION:string = 'action'
  public static RESOURCE_LIMITED:string = 'resourceLimited'
  public static REFRESH:string = 'refresh'
  //public static EDIT_TEMPSERVICE:string = 'editTempService'
  public static PRIORITY_MODE_FLAG_CHANGED = 'priorityModeFlagChanged'

  public payload:any = {}
}