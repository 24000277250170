import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Pagenation from '../components/uiv2/Pagenation'
import { Alert, IAlertListItem, ALERT_ORDER_BY, ALERT_STATUS, ALERT_TYPE_OPS } from '../../model/Alert'
import { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/uiv2/Table'
import { SORT_ORDER } from '../../model/BaseDataType'
import PageEvent from '../../events/PageEvent'
import PopupController from '../../controller/PopupController'
import Table from '../components/uiv2/Table'
import dayjs from 'dayjs'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../components/uiv2/Button'
import InfiniteScroll from 'react-infinite-scroll-component'
import CheckBox from '../components/uiv2/CheckBox'
import DateRangePicker from '../components/uiv2/DateRangePicker'
import { TIME_PICKER_TYPE } from '../components/uiv2/TimePicker'
import RadioBox from '../components/uiv2/RadioBox'
import { ERROR_TYPE } from '../../interfaces/Error'
import Tab, { TAB_SHAPE } from '../components/uiv2/Tab'

interface IAlertCenterPageProps {
}

interface IAlertCenterState {
  //totalCount:number
  alertList:any[] //IAlertListItem[]
  deleteItems:string[]
  alertPaging: {
    hasMore:boolean
    page:number
    total:number
  }
  hwCount:number
  objCount:number
  userCount:number
}

const AlertCenter = (props:IAlertCenterPageProps) => {
  const alertLabel = ['일반', '주의', '경고']
  const [ authInfo ] = useRecoilState(authState)
  const popupController = PopupController.getInstance()
  
  const levelRef0 = useRef<any>()
  const levelRef1 = useRef<any>()
  const levelRef2 = useRef<any>()
  const pickerRef = useRef<any>()

  const startTimestamp:number = 0
  const startFrom:string = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(1, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let initialFrom:string = dayjs().subtract(1, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm')
  let initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const [ page, setPage ] = useState<number>(1)
  const [ unreadCount, setUnreadCount ] = useState<number>(0)
  const [ selectedDate, setSelectedDate ] = useState<string[]>([])
  const [ selected, setSelected ] = useState<number[]>([])
  const [ filterBoxFlag, setFilterBoxFlag ] = useState<boolean>(false)
  const [ range, _setRange ] = useState<any>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: defaultFrom,
      to: defaultTo
    },
    init: {
      from: defaultFrom,
      to: defaultTo
    },
  })
  const rangeRef = useRef(range)
  const setRange = (data:any) => {
    rangeRef.current = data
    _setRange(data)
  }
  const [ alertLevel, setAlertLevel ] = useState<boolean[]>([true, true, true])
  const [ order, _setOrder ] = useState<SORT_ORDER>(SORT_ORDER.DESC)
  const orderRef = useRef(order)
  const setOrder = (data:any) => {
    orderRef.current = data
    _setOrder(data)
  }
  const [ state, _setState ] = useState<IAlertCenterState> ({
    //totalCount: 0,
    alertList: [],
    deleteItems: [],
    alertPaging: {
      page: 1,
      hasMore: true,
      total: -1
    },
    hwCount:0,
    objCount:0,
    userCount:0
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  const [ canSubmit, setCanSubmit ] = useState<boolean>(true)

  const filterBtnRef = useRef<any>()
  const filterBoxRef = useRef<any>()


  // 필터 변경 전 값 저장용
  const [ prev, setPrev ] = useState<any>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    order: SORT_ORDER.DESC,
    alertLevel: [true, true, true]
  })

  const [ tab, _setTab ] = useState<ALERT_TYPE_OPS>(ALERT_TYPE_OPS.INFRA)
  const tabRef = useRef(tab)
  const setTab = (data:any) => {
    tabRef.current = data
    _setTab(data)
  }
  
  useEffect(() => {
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, showRefreshButton)
    window.addEventListener('refreshalarmlist', eventControl)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, showRefreshButton)
      window.removeEventListener('refreshalarmlist', eventControl)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    if(filterBoxFlag) {
      setPrev({
        range: range.range,
        order: order,
        alertLevel: alertLevel.concat()
      })
    } else {
      setRange({
        ...range,
        range: prev.range
      })
      setOrder(prev.order)
      setAlertLevel(prev.alertLevel.concat())
    }
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [filterBoxFlag])

  const clickOutsideHandler = (event:any) => {
    if (!filterBtnRef.current?.contains(event.target)
      && !filterBoxRef.current?.contains(event.target) && filterBoxFlag) {
      setFilterBoxFlag(false)
    }
  }

  useEffect(() => {
    eventControl()
  }, [page])

  useEffect(() => {
    eventControl2()
  }, [tab])

  const eventControl = () => {
    //updateAlertCounter()
    getAlertList(page, alertLevel, page === 1, tab)
  }

  const eventControl2 = () => {
    getAlertList(page, alertLevel, true, tab)
  }
  const showRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const updateAlertCounter = async():Promise<void> => {
    try {
      let alertInfo = await Alert.getAlertCount()
      if (alertInfo) {
        setUnreadCount(alertInfo.numNewAlert)
      }  
    }
    catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 53e089')
    }
  }

  const getAlertList = async(page:number, levelArray:boolean[], resetFlag:boolean=false, alertType:number):Promise<void> => {
    const to = dayjs(rangeRef.current.range.to).unix()
    const from = dayjs(rangeRef.current.range.from).unix()
    // const level = 0
    // console.log(alertLevel)
    const selectedTab = alertType

    try {
      let data = await Alert.getList(authInfo?.userNo || -1, from, to, page, 50, ALERT_ORDER_BY.DATETIME, orderRef.current, levelArray, authInfo, selectedTab)
      if (data) {
        for (let eachData of data.alertList) {
          eachData.dateIndex = dayjs.unix(Number(eachData.timestamp)).format('YYYYMMDD')
        }
        let newList = (resetFlag === true ? data.alertList : state.alertList.concat(data.alertList))
        let total
        switch(tab) {
          case ALERT_TYPE_OPS.INFRA:
            total = data.hwCount
            break
          case ALERT_TYPE_OPS.SERVICE:
            total = data.objCount
            break
          case ALERT_TYPE_OPS.USER:
            total = data.usrCount
            break
          }
        
        setState({
          ...state,
          //totalCount: total,
          alertList: newList,
          alertPaging: {
            hasMore: (newList.length !== total),
            total: total
          },
          hwCount:data.hwCount,
          objCount:data.objCount,
          userCount:data.usrCount
        })
        setUnreadCount(data.newCount)
        window.dispatchEvent(new Event('refreshalarm'))
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 8050f3')
      }
    }
  }

  const removeAlertHandler = async(): Promise<void> => {
    try {
      const items = state.alertList
      const selectedIdx = selected.concat()

      if (items && selectedIdx && selectedIdx.length > 0) {
        popupController.dialouge(`${selectedIdx.length}개의 알림이 삭제됩니다. 진행하시겠습니까?`, 'deleteAlert', selectedIdx , '확인', '취소')
        setState({
          ...state,
          deleteItems: selectedIdx
        })
      } else {
        popupController.confirm(`선택된 항목이 없습니다.`)
      }
    } catch {
      popupController.confirm(`에러가 발생했습니다.`)
    }
    
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteAlert':
        try {
          const deleteItems = e.payload.key
          await Alert.deleteList(deleteItems)
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${deleteItems.length}개의 알림이 삭제되었습니다.` }
          window.dispatchEvent(evt)

          // 삭제 후 갱신
          // 삭제 실패의경우 응답형태확인필요
          setSelected([])
          setPage(1)

          if(page === 1){ //page가 1일 때 삭제하면 setPage에서 갱신이 안되므로 추가
            updateAlertCounter()
            getAlertList(page, alertLevel, page === 1, tabRef.current)
          }
        } catch (error) {
          console.log(error)
          popupController.confirm(`에러가 발생했습니다.\n에러코드 - 8de9e4`)
        }
        break
    }
  }

  const tabHandler = (target:ALERT_TYPE_OPS) => {
    setTab(target)
  }

  const dateCheckBoxHandler = (id:string, checked:boolean) => {
    const alertIdArray = id.split('-')

    let newDateArray = selectedDate.concat()
    if (checked === true) {
      newDateArray.push(alertIdArray[1])
    } else {
      let addr = newDateArray.indexOf(alertIdArray[1])
      newDateArray.splice(addr, 1)
    }
    newDateArray.sort()
    setSelectedDate(newDateArray)

    let newArray = selected.concat()
    for (let eachAlert of state.alertList) {
      if (eachAlert.dateIndex === alertIdArray[1]) {
        if (checked === true && newArray.indexOf(eachAlert.alertId) === -1) {
          newArray.push(eachAlert.alertId)
        } else if (checked === false && newArray.indexOf(eachAlert.alertId) !== -1) {
          let addr = newArray.indexOf(eachAlert.alertId)
          newArray.splice(addr, 1)
        }
      }
    }
    newArray.sort()
    setSelected(newArray)
  }

  const checkBoxHandler = (id:string, checked:boolean) => {
    const alertIdArray = id.split('-')
    const alertId = Number(alertIdArray[2])
    
    let newArray = selected.concat()
    if (checked === true) {
      newArray.push(alertId)
    } else {
      let addr = newArray.indexOf(alertId)
      newArray.splice(addr, 1)
    }
    newArray.sort()
    setSelected(newArray)
    
    let newDateArray = selectedDate.concat()
    let addr = newDateArray.indexOf(alertIdArray[1])
    let checkedAllFlag = true
    for (let eachAlert of state.alertList) {
      if (eachAlert.dateIndex === alertIdArray[1]) {
        if (newArray.indexOf(eachAlert.alertId) === -1) {
          checkedAllFlag = false
        }
      }
    }
    if (checkedAllFlag === true && addr === -1) {
      newDateArray.push(alertIdArray[1])
    } else if (checkedAllFlag === false && addr !== -1) {
      newDateArray.splice(addr, 1)
    }
    newDateArray.sort()
    setSelectedDate(newDateArray)
  }

  const loadMore = () => {
    setPage(page+1)
  }

  const onChangeDatePicker = (rangeData:any) => {
    setRange({
      ...range,
      range: {
        from: rangeData.from,
        to: rangeData.to
      }
    })
  }
  
  const radioHandler = (id:string) => { 
    setOrder(id === 'sortDesc' ? SORT_ORDER.DESC : SORT_ORDER.ASC)
  }

  const levelHandler = (id:string, checked:boolean) => {
    const addr = Number(id.substring(5))
    let newArray = alertLevel
    newArray[addr] = checked
    setAlertLevel(newArray)

    // 적용 버튼 disabled 여부
    if(!newArray[0] && !newArray[1] && !newArray[2]) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }

  return (
    <AlertCenterFragment>
      <div className="titleArea bothEnd">
        <h2 className="pageTitle">
          통합 알림 센터
        </h2>
        <Tab shape={TAB_SHAPE.LARGE}>
          <button className={tab === ALERT_TYPE_OPS.INFRA ? 'on' : ''}
            onClick={(): void => tabHandler(ALERT_TYPE_OPS.INFRA)}>
            하드웨어 인프라 알림
            <span>{state.hwCount}</span>
          </button>
          <button className={tab === ALERT_TYPE_OPS.SERVICE ? 'on' : ''}
            onClick={(): void => tabHandler(ALERT_TYPE_OPS.SERVICE)}>
            서비스 알림
            <span>{state.objCount}</span>
          </button>
          <button className={tab === ALERT_TYPE_OPS.USER ? 'on' : ''}
            onClick={(): void => tabHandler(ALERT_TYPE_OPS.USER)}>
            사용자 알림
            <span>{state.userCount}</span>
          </button>
        </Tab>
      </div>
      <div style={{display:'flex', alignItems:'start', justifyContent:'space-between', marginBottom:'12px', fontSize:'14px'}}>
        <div style={{display:'flex', gap:'8px', color:'#646469', height:'32px', alignItems:'center'}}>
          <span className='subTitle'>{tab === ALERT_TYPE_OPS.INFRA ? '하드웨어 인프라 알림': tab === ALERT_TYPE_OPS.SERVICE ? '서비스 알림' : '사용자 알림'}</span>
          <span className="total">전체 <b>{tab === ALERT_TYPE_OPS.INFRA ? state.hwCount: tab === ALERT_TYPE_OPS.SERVICE ? state.objCount : state.userCount}</b>개</span>
          <span className="countBar"></span>
          <span className={unreadCount === 0 ? '' :"unread" }>읽지 않음 <span>{unreadCount}</span>개</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={removeAlertHandler} disabled={selected.length === 0}>선택 항목 삭제</Button>
          <span className="bar1"> </span>
          <div style={{ position: 'relative' }}>
            <Button size={BUTTON_SIZE.MEDIUM} ref={filterBtnRef} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => {
              setFilterBoxFlag(!filterBoxFlag)
            }}>
              필터 설정 <img src="/images-v2/sliders.svg" alt="filter" style={{ width: '12px' }} />
            </Button>
            {filterBoxFlag ?
              <div className='filterBox' ref={filterBoxRef}>
                <section style={{ fontWeight:'700', borderBottom: '1px solid #E8E8EE', paddingBottom: '12px', lineHeight:'24px' }}>
                  필터 설정
                </section>
                <section style={{ paddingBottom: '24px' }}>
                  <p>조회 기간</p>
                  <div className='filter'>
                    <DateRangePicker type={TIME_PICKER_TYPE.MINI} ref={pickerRef} initial={{ from: initialFrom, to: initialTo }}
                      default={{ from: range.range.from, to: range.range.to }} limit={range.limit} data={range.range} onChangeCalendar={onChangeDatePicker} />
                  </div>
                  <p>알림 상태</p>
                  <div className='filter' style={{ gap:'12px' }}>
                    <span style={{ width:'62px' }}><CheckBox ref={levelRef0} id='level0' checked={alertLevel[0]} label='일반' onChange={levelHandler} /></span>
                    <span style={{ width:'62px' }}><CheckBox ref={levelRef1} id='level1' checked={alertLevel[1]} label='주의' onChange={levelHandler} /></span>
                    <span style={{ width:'62px' }}><CheckBox ref={levelRef2} id='level2' checked={alertLevel[2]} label='경고' onChange={levelHandler} /></span>
                  </div>
                  <p>정렬 방식</p>
                  <div className='filter' style={{ gap:'12px' }}>
                    <span style={{ width:'99px' }}><RadioBox name='sort' id='sortDesc' checked={order === SORT_ORDER.DESC} label='최신순↑' onChange={radioHandler} /></span>
                    <span style={{ width:'99px' }}><RadioBox name='sort' id='sortAsc' checked={order === SORT_ORDER.ASC} label='오래된순↓' onChange={radioHandler} /></span>
                  </div>
                </section>
                <section className='buttoninSection'>
                  <button style={{ background: '#F1F2F6' }} onClick={() => {
                    levelRef0.current?.setValue(true)
                    levelRef1.current?.setValue(true)
                    levelRef2.current?.setValue(true)
                    setAlertLevel([true, true, true])
                    setOrder(SORT_ORDER.DESC)
                    setRange({
                      ...range,
                      range: range.init
                    })
                    setPrev({
                      range: range.init,
                      order: SORT_ORDER.DESC,
                      alertLevel: [true, true, true]
                    })
                    getAlertList(1, [true, true, true], true, tab)
                  }}>초기화</button>
                  <button className='yellow' disabled={!canSubmit}
                    onClick={() => {
                      setPrev({
                        range: range.range,
                        order: order,
                        alertLevel: alertLevel.concat()
                      })
                      getAlertList(1, alertLevel, true, tab)
                    }}>적용</button>
                </section>
              </div> : false}
          </div>
        </div>
      </div>
      {
        state.alertList.length > 0 ?
          <InfiniteScroll className="listWrap"
            scrollableTarget="alertWrap"
            dataLength={state.alertList.length}
            next={loadMore}
            hasMore={state.alertPaging.hasMore}
            loader={
              <div className='viewMore'>
                <a href="#" onClick={(e) => {
                  e.preventDefault()
                  loadMore()
                }}>
                  <span>더보기</span> <img src="/images-v2/chevron-down.svg" />
                </a>
              </div>
            }
            endMessage={
              false
            } >
            <div id="alertWrap">
              {
                state.alertList.map((alert, idx) => (
                  <div key={idx} className={(alert.isRead === true ? 'alert read' : 'alert') + (idx === 0 || dayjs.unix(Number(state.alertList[idx - 1].timestamp)).format('YYYY.MM.DD') !== dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD') ? ' dateIndex' : '')}>
                    {
                      idx === 0 ?
                        <div className="date">
                          <CheckBox id={"selected-" + state.alertList[0].dateIndex} checked={selectedDate.indexOf(state.alertList[0].dateIndex) !== -1} onChange={dateCheckBoxHandler} />
                          <h4>
                            {
                              dayjs().format('YYYY.MM.DD') === dayjs.unix(Number(state.alertList[0].timestamp)).format('YYYY.MM.DD') ?
                                '오늘' :
                                dayjs.unix(Number(state.alertList[0].timestamp)).format('YYYY.MM.DD')
                            }
                          </h4>
                        </div> :
                        dayjs.unix(Number(state.alertList[idx - 1].timestamp)).format('YYYY.MM.DD') !== dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD') ?
                          <div className="date">
                            <CheckBox id={"selected-" + alert.dateIndex} checked={selectedDate.indexOf(alert.dateIndex) !== -1} onChange={dateCheckBoxHandler} />
                            <h4>{dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD')}</h4>
                          </div> :
                          false
                    }
                    <div className="msg">
                      <a>
                        <div className="detail">
                          <span>
                            <CheckBox id={"selected-" + alert.dateIndex + "-" + alert.alertId}
                              checked={selected.indexOf(alert.alertId) !== -1}
                              onChange={checkBoxHandler} />
                          </span>
                          <span className={`status status${alert.level}`}>{alertLabel[alert.level]}</span>
                          <p className="text">{alert.msg}</p>
                          <p className="datetime">{dayjs.unix(Number(alert.timestamp)).format('HH:mm:ss')}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                ))
              }
            </div>

          </InfiniteScroll>
          : <div className="emptyWrap">새로운 알림 내역이 없습니다.</div>
      }
    </AlertCenterFragment>
  )
}

const AlertCenterFragment = styled.div`
  .pageTitle .new{ display:inline-block; width:20px; height:20px; background:url(/images/new.png) no-repeat center / 100%; }
  table tbody td { padding:11px 20px }
  table .col0, .col2{ text-align:center; }
  label { padding-left:0 }
  .subTitle { font-size: 14px; font-weight:600; margin-right:8px; }

  i {display:inline-block;width:12px;height:12px;margin-right:10px;vertical-align:middle;}
  .alert0 i {background:url(/images/alert.png) no-repeat center / 100%;}
  .alert1 i {background:url(/images/alert-warn.png) no-repeat center / 100%;}
  .alert2 i {width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}

  .countBar{width:1px;height:12px;background:#D5D5DA;margin-top:3px}
  span.total b {font-weight:400}
  span.unread span {font-weight:600; color:#4B82FF;}

  .filterBox { 
    position:absolute; right:0; top:47px; width:242px;padding:12px 0 16px 0; border-radius:6px;border:1px solid #646469;background:#fff;
    box-shadow:0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50); 
  }
  .filterBox section { padding: 0 16px }
  .filterBox .buttoninSection button { width:50%;height:32px;font-weight:500; }
  .filterBox p { margin:24px 0 8px 0;font-weight:700; color:#333; line-height:24px; }
  .filterBox div.filter { display:flex;width:100% }
  .filterBox label { padding:0;justify-content:left }
  
  .listWrap .alert.dateIndex {margin-top:36px}
  .listWrap .alert.dateIndex:first-child {margin-top:0}
  .listWrap .date{margin-top:36px;display:flex}
  .listWrap .date:first-child{margin-top:0}
  .listWrap .date h4{margin:0 0 12px 10px;font-size:14px;color: #303030;}
  .listWrap a{display:flex;justify-content:space-between;align-items:center;padding: 16px 0;gap: 40px;border-top:1px solid #dfdfdf}
  .listWrap a:hover .text{text-decoration:none;cursor:default;}
  .listWrap a > div{display:flex;align-items:flex-start;gap:10px;}
  .listWrap .detail {display:flex;width:100%;font-size:13px;line-height:20px;}
  .listWrap .status{flex:none;width:40px;height:20px;box-sizing: border-box;font-size: 12px;text-align:center;line-height:18px;border-radius:10px;border:1px solid}
  .listWrap .status0{border-color: #B4B4BE;color: #646469;}
  .listWrap .status1{border-color: #FFD028;color: #D7A700;}
  .listWrap .status2{border-color: #F66;color: #FF0000;}
  .listWrap .text{flex-grow:1;color:#333;font-weight:700;}
  .listWrap .datetime{color:#33333366;}
  .listWrap .arrow{flex:none;width:7px;height:17px;background:url(/images-v2/arrow2.png) no-repeat center / 100%;}
  .listWrap .toast{position:fixed;right:108px;bottom: 70px;padding:0 15px;background:rgba(78, 78, 78, 0.8); box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);line-height:37px;color:#fff;font-size:14px;font-weight:bold;}
  .listWrap .toast img{display:inline-block;width:18px;vertical-align:middle;}
  .listWrap .endMsg{margin-top:30px;font-size:14px;color:#303030}
  .listWrap .alert.read .text{font-weight:normal; word-break:break-all;}

  .viewMore { display: flex;height: 56px;padding: 0px 12px;justify-content: center;align-items: center;gap: 6px;align-self: stretch; font-size: 13px;font-weight: 500; border-top:1px solid #dfdfdf;}
  .viewMore a {color:#222; border:none; gap:6px;}
  .viewMore a img {vertical-align:middle}

  .emptyWrap {font-size:14px; font-weight:400; width:100%; height:756px; text-align:center; line-height:756px; border-top:1px solid #E8E8EE;}

  button {color:#333333;}
  button.yellow {background: #FFDB20;}
  button:disabled.yellow {cursor:auto; background:#FFDB2033; color: #3333334d;}
  .bar1 { width:1px; height:24px; background:#D5D5DA; margin:4px 12px;}
`

export default AlertCenter