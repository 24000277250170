import { useEffect } from "react"
import { useLocation } from "react-router"

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (document.getElementsByClassName('contentWrap')[0]) {
      document.getElementsByClassName('contentWrap')[0].scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

export default ScrollToTop