import { createRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../../Config"

import CheckBox from './CheckBox'
import { IResourceUnit } from "../../../model/Workspace"
import utils from '../../../utils'
import { uniqueId } from 'lodash'

export enum ResourceUnitType {
  UNITTYPE_DEV = 'blockTypeDev', //dev gpu
  UNITTYPE_OPS = 'blockTypeOps', //ops gpu
  UNITTYPE_CPU = 'blockTypeCpu' //dev cpu
}

interface IResourceUnitProps {
  selected:boolean
  index:number
  data:IResourceUnit
  onSelect?:Function
  type?:ResourceUnitType
}

interface IResourceUnitState {
  id:number
  blockName:string
  blockCount:number
  blockCountRemain:number
  gpuName:string
  cpuData:string
  ramData:string
  gpuPer:string
  gpuMemory:string
  selected:boolean
  type:ResourceUnitType
}

const ResourceUnit = (props:IResourceUnitProps) => {
  const [ state, setState ] = useState<IResourceUnitState>({
    id: props.data.resourceBlockSpec.id,
    blockName: props.data.resourceBlockSpec.type,
    blockCount: props.data.numBlock,
    blockCountRemain: props.data.freeNumBlock || 0,
    gpuName: props.data.resourceBlockSpec.type,
    cpuData: String(props.data.resourceBlockSpec.cpu),
    ramData: props.data.resourceBlockSpec.mem+props.data.resourceBlockSpec.memUnit,
    gpuPer: props.data.resourceBlockSpec.gpuPer+'%',
    gpuMemory: props.data.resourceBlockSpec.gpuMem+props.data.resourceBlockSpec.gpuMemUnit,
    selected: (props.selected ? props.selected: false),
    type: (props.type? props.type: ResourceUnitType.UNITTYPE_DEV)
  })

  const prevState = utils.usePrevState(state)

  useEffect(() => {
    if(props.type){
      setState({
        ...state,
        type : props.type
      })
    }
  }, [])

  useEffect(() => {
    if (prevState.selected !== props.selected) {
      setState({
        ...state,
        selected: props.selected
      })
    }
    if (prevState.data !== props.data) {
      setResourceUnitData()
    }
  }, [prevState.selected, props.selected, prevState.data, props.data])

  const setResourceUnitData = () => {
    setState({
      id: props.data.resourceBlockSpec.id,
      blockName: props.data.resourceBlockSpec.type,
      blockCount: props.data.totalNumBlock || 0,
      blockCountRemain: props.data.freeNumBlock || 0,
      gpuName: props.data.resourceBlockSpec.type,
      cpuData: props.data.resourceBlockSpec.cpu + ' 코어',
      ramData: props.data.resourceBlockSpec.mem + ' ' + props.data.resourceBlockSpec.memUnit,
      gpuPer: props.data.resourceBlockSpec.gpuPer + ' %',
      gpuMemory: props.data.resourceBlockSpec.gpuMem + ' ' + props.data.resourceBlockSpec.gpuMemUnit,
      selected: (props.selected ? props.selected: false),
      type: (props.type? props.type: ResourceUnitType.UNITTYPE_DEV)
    })
  }

  return (
    <ResourceUnitFragment>
      <div className={"resourceBox " + (state.selected ? 'selected' : '')}>
        <div className="titleBoxWrap">
          <CheckBox id={"selected" + props.index} checked={state.selected} 
          label={state.type === ResourceUnitType.UNITTYPE_CPU ? `CPU node ${state.id}` : `블록 타입 ${props.index+1}: ${state.type === ResourceUnitType.UNITTYPE_OPS ? '잔여 '+state.blockCountRemain+'개 / ' : ''}총 ${state.blockCount}개`} 
          onChange={(id: string, checked: boolean) => {
            setState({ 
              ...state,
              selected: checked 
            })
            if (props.onSelect) props.onSelect(state.id, checked)
          }} />
        </div>
        {
        <div className="inner">
          <div>
            <div className="titleBox">
              <div className="detail">
                <div className='tooltipWrap' style={{ width: '260px' }}>
                    {state.type === ResourceUnitType.UNITTYPE_CPU ?
                      <EllipsisToolTip options={Config.ellipsisTipOptions}>{}</EllipsisToolTip>
                      :
                      <EllipsisToolTip options={Config.ellipsisTipOptions}>{state.gpuName}</EllipsisToolTip> 
                    }
                </div>
              </div>
            </div>
            <div className={state.type === ResourceUnitType.UNITTYPE_OPS ? "contentBoxOps" : "contentBox"}>
              <p className="dl">
                <span className="dt" style={{width:'36px'}}>CPU</span>
                <span className="dd">{state.type === ResourceUnitType.UNITTYPE_CPU ? '최대 ' : false}{state.cpuData}</span>
              </p>
              <p className="dl">
                <span className="dt" style={{width:'70px'}}>GPU</span>
                <span className="dd">{state.type === ResourceUnitType.UNITTYPE_CPU ? '-' : state.gpuPer}</span>
              </p>
              <p className="dl">
                <span className="dt" style={{width:'36px'}}>MEM</span>
                <span className="dd">{state.type === ResourceUnitType.UNITTYPE_CPU ? '최대 ' : false}{state.ramData}</span>
              </p>
              <p className="dl">
                <span className="dt" style={{width:'70px'}}>GPU MEM</span>
                <span className="dd">{state.type === ResourceUnitType.UNITTYPE_CPU ? '-' : state.gpuMemory}</span>
              </p>
            </div>
          </div>
        </div>
        }
      </div>
    </ResourceUnitFragment>
  )
}

const ResourceUnitFragment = styled.div`
  display:inline-block;
  margin-right:18px;
  .resourceBox{color:#303030;font-size: 0; }
  .resourceBox .inner{display:inline-block; width:310px; box-sizing:border-box;}
  .resourceBox .inner > div{border:1px solid #dfdfdf;border-radius:6px;padding:12px 16px 10px 16px}
  .resourceBox .titleBox{ padding-bottom:12px;border-bottom:1px solid #dfdfdf;}
  .resourceBox .titleBox .boxTitle {font-size:14px}
  .resourceBox .contentBox{ display:flex;flex-wrap:wrap;padding-top:12px;line-height:14px; min-height:86px; }
  .resourceBox .contentBoxOps{ display:flex;flex-wrap:wrap;padding-top:12px;line-height:14px; }
  .resourceBox .detail{font-size:14px;font-weight:500;}
  .resourceBox .dl{flex-basis:50%;font-size:14px;padding-bottom:6px}
  .resourceBox .boxTitle,
  .resourceBox b{font-weight:600;}
  .resourceBox b span{color:#2c78ff;}
  /* .resourceBox + .resourceBox {margin-left: 5px;} */
  .resourceBox.selected .inner > div{border-color:#646469;}
  .resourceBox .dl .dt {display:inline-block;color:#333}
  .resourceBox .dl .dd {display:inline-block;word-break:break-all;color:#646469}

  .titleBoxWrap {display:flex; justify-content:space-between;padding-bottom:12px}
  .titleBoxWrapRow {flex-direction:row; justify-content:space-between; gap:7px;}
  .titleBoxWrap label, .titleBoxWrapRow label {padding:0px;}

  .blockTypeWrap {width: 180px; height:34px; font-weight:600; margin-top:7px; overflow:hidden; white-space: pre-wrap;}
`

export default ResourceUnit