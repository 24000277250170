import { IBaseDataType, SORT_ORDER } from "./BaseDataType"
import ApiController from "../controller/ApiController"
import { ERROR_TYPE } from "../interfaces/Error"
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from "snakecase-keys"
import { ObjType } from "./BaseDataType"
import { IPort, IPortMap2 } from "./Ports"
import { IResourceBlock, RgroupInfo } from "./Workspace"
//이역시 리소스그룹으로 뺄필요는 없을지

export enum SERVICE_STATUS {
  ERROR = 1, // 장애
  PENDING = 2, // 대기
  CAUTION = 3, // 주의
  RUNNING = 4, // 정상
  STOPPED = 5, // 중지
  STOPPING = 7 //중지중
}

export enum SERVICE_LIST_ORDER_BY {
  RESOURCE_GROUP_NAME = 1,
  SERVICE_TYPE = 2,
  NAME = 3,
  OPERATION_PERIOD = 4,
  RESOURCE_TYPE = 5,
  REPLICAS_COUNT = 6,
  STATUS = 7
}

export enum DASHBOARD_DATA_TYPE {
  LOG = 1,
  EVENT = 2
}

export interface IEmail {
  name:string
  email:string
  selected?:boolean //서비스폼 - 이메일 팝업에서 이미 선택된 이메일인지 표기할 때 사용
}

export interface ITolerance {
  min:number
  max:number
  period?:number
}

export interface IEnvironment {
  key:string
  value:string
}

export interface IProject {
  id:number
  name:string
  statusId:number
}

export interface IVolume {
  pvc:string
  mountPath:string
}

export interface IServiceItem extends IBaseDataType {
  id: number
  rgroup: string
  isCommon: boolean
  project: string
  name: string
  startedAt: number
  deletedAt: number
  ports: IPort[]
  resourceBlock: {
    cpu: number
    mem: number
    memUnit: string
    type: string
    gpuMem: number
    gpuMemUnit: string
    gpuPer: number
    isPartedGpu: boolean
    gpuNum: number
  }
  replicas: number
  readyReplicas: number
  statusId: number
  ip: string
}

export interface IServiceDetail extends IBaseDataType {
  id:number
  rgroup:string
  isCommon:boolean
  project:string|null
  name:string
  startedAt:number|null
  deletedAt:number|null
  ports:IPortMap2[]
  resourceBlock:IResourceBlock
  replicas:number
  readyReplicas:number
  statusId:number
  ip:string
  
  workingTime:number
  command:string[]
  args:string[]
  env:IEnvironment[]
  volumes:IVolume[]
  emails:IEmail[]
  recentDeployTimestamp:number
  //projectList:IProject[]
  //rgroupInfo:RgroupInfo

  // imageName:string
  // creationTimestamp:number
  // errorTimestamp:number
  // statusMsg:string
  // autoscale: {
  //   isAutoscale:false
  //   responseTolerance:ITolerance
  //   replicasTolerance:ITolerance
  // }
}

export interface IReplica {
  name:string
  createdAt:number
  isError:boolean
}

export class Service {
  public static async getList(userNo:number, orderBy:SERVICE_LIST_ORDER_BY, sortBy:SORT_ORDER, pageNum:number=1, pageSize:number=50) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/operations', {
        user_no: userNo,
        page_num: pageNum,
        page_size: pageSize,
        order_by: orderBy,
        sort_by: sortBy
      })
      
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Service.getList error>', response)
          break
      }
    } catch (error:any){
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async checkDuplicate(namespace:string, name:string) {
    try {
      const apiController = ApiController.getInstance() 
      const response = await apiController.get(`/operations/check/${name}/${namespace}`, {})
      switch(response.status) {
        case 200:
          return (response.data.is_exist === false)
        default:
          console.log('Service.checkDuplicateName error>', response)
          return false
      }
    } catch (error) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async create(data:any) {
    try {
      const payload:any = snakecaseKeys(data)
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/operations', payload)
      
      switch(response.status) {
        case 200:
          return response.data
        default:
          break
      }
    } catch (error: any) {
      console.log('Service.create error>', error)
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async update(serviceId:string|number, data:any) {
    try {
      const payload:any = snakecaseKeys(data)
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.put('/operations/'+serviceId, payload)
      
      switch(response.status) {
        case 200:
          return response.data
        default:
          break
      }
    } catch (error: any) {
      console.log('Service.create error>', error)
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async removeItem(serviceList:any[], deleteFlag:boolean=true, priorityFlag?:boolean) {
    try {
      const apiController = ApiController.getInstance()
      // const response = await apiController.del(
      //   '/operations?is_delete='+String(deleteFlag)
      //   +'&is_pending_queue='+String(priorityFlag !== undefined ? (priorityFlag === true ? 'false' : 'true') : 'false')
      //   +'&is_priority_queue='+String(priorityFlag !== undefined && priorityFlag === true ? 'true' : 'false'), 
      //   {operation_list: serviceList}
      // )
      const response = await apiController.del(
        '/operations',
        { operation_list: serviceList }
      )
      switch(response.status) {
        case 200:
          return response.data
        default:
          console.log('service.removeService error>', response)
          return response
      }
    } catch (error:any){
      switch(error.response.status) {
        case 400:
          return camelcaseKeys(error.response.data, { deep:true })
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }  
    }
  }

  public static async getServiceTypeList(q:string='') {
    try {
      const apiController = ApiController.getInstance()
      apiController.useMaskFlag = false
      const response = await apiController.get('projects', {
        q: q
      })
      apiController.useMaskFlag = true
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          break
      }
    } catch (error) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getEmailList(q:string='', sortBy:SORT_ORDER=SORT_ORDER.ASC) {
    try {
      const apiController = ApiController.getInstance()
      if (q) apiController.useMaskFlag = false
      const requestBody:any = {}
      if (q) requestBody.q = q
      if (sortBy !== undefined) requestBody.sort_by = sortBy
      const response = await apiController.get('emails', requestBody)
      apiController.useMaskFlag = true
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          break
      }
    } catch (error) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getVersionList(serviceId:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('replicasets', {
        id: serviceId
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          break
      }
    } catch (error) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getVersionDetail(versionName:string, versionNamespace:string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('replicasets/'+versionName, {
        namespace: versionNamespace
      })

      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          break
      }
    } catch (error) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async patchServiceVersionRollback(versionName:string, serviceId:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/replicasets/'+versionName+'?id='+serviceId, {})

      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          break
      }
    } catch (error:any) {
      switch(error.response.status) {
        //case 422:
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async getDetail(id:number|string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/operations/'+id, {})
      switch (response.status) {
        case 200:
          return {
            service: camelcaseKeys(response.data.detail, { deep: true }),
            resourceGroup: camelcaseKeys(response.data.rgroup_info, { deep: true }),
            projectList: camelcaseKeys(response.data.project_list, { deep: true })
            //monitoring: camelcaseKeys(response.data.monitoring, {deep:true})
          }
        default:
          console.log('Services.getDetail error>', response)
          //alert(response.data.msg || response.statusText)
          return {
            service: null,
            resourceGroup: null,
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async stop(id:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/operations/' + id + '/suspend', {})

      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Operation.stop error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async restart(id:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/operations/' + id + '/resume', {})

      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Operation.restart error>', response)
          //alert(response.data.msg)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async removeServices(list:number[]) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.del('/operations', {
        operation_list: list
      })

      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Operation.removeServices error>', response)
          //alert(response.data.msg)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getDetailMonitoring(name:string, namespace:string, startTimestamp:number, endTimestamp:number):Promise<any> {
    try {
      const end = endTimestamp ? endTimestamp : Math.floor(new Date().getTime() / 1000)
      const start = startTimestamp ? startTimestamp : end - 604800 // 1 week (임시)
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/metrics/ops/detail?ops_name='+name+'&namespace='+namespace+'&start_timestamp='+start+'&end_timestamp='+end,{})
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('Services.getDetailMonitoring error>', response)
          //alert(response.data.msg || response.statusText)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getLogEventData(id:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/dashboards/'+id,{})
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('Services.getLogEventData error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
  
  public static async getLogEventDashboardData(id:number, name:string, type:DASHBOARD_DATA_TYPE) {
    //type- 1:로그 , 2:이벤트
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/dashboards/'+id+'/'+name,{
        type:type
      })
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('Services.getLogEventDashboardData error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
}