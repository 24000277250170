import styled from 'styled-components'

export enum CHIP_TYPE {
  ROUND = 'round',
  SQUARE = ''
}

export enum CHIP_COLOR {
  NORMAL = '',
  WARNNING = ' yellow',
  ERROR  = ' red',
  FILL = ' fill',
}

interface IChipProps {
  type:CHIP_TYPE
  value:string|string[]
  color?:CHIP_COLOR
  delete?:boolean
  onClickDelete?:Function
}

const defaultProps = {
  type: CHIP_TYPE.SQUARE,
  color: CHIP_COLOR.NORMAL
}


const Chip = (props:IChipProps) => {
  let valueHtml = ''
  if (Array.isArray(props.value)) {
    for (let i = 0; i < props.value.length; i++) {
      if (i === 0) valueHtml += props.value[i]
      else valueHtml += '<span class="bar"> / </span>' + props.value[i]
    }
  } else {
    valueHtml = props.value
  }
  return (
      <ChipFragment className={props.type + props.color + (props.delete ? ' del' : '')}>
        <span dangerouslySetInnerHTML={{__html: valueHtml}}></span>
        {props.delete ? <button onClick={() => {
          if (props.onClickDelete) props.onClickDelete()
        }}>
          <img src={props.color === CHIP_COLOR.ERROR ? '/images-v2/clip-x-r.png' : '/images-v2/clip-x.png'} alt="x" />
        </button> : false}
      </ChipFragment>
  )
}

Chip.defaultProps = defaultProps

const ChipFragment = styled.div`
display:flex;align-items:center;gap:12px;width:fit-content;height: 20px;box-sizing:border-box;padding:0 8px;border-radius:3px;font-size: 12px;border:1px solid #D5D5DA;color: #878791;line-height: 18px;letter-spacing: 0;
&.yellow{border-color:#FFD028;color: #F4B000;}
&.red{border-color:#F66;color: #FF0000}
&.fill{background: #F7F8FB;border-color:#E8E8EE;color: #333;}
&.del{height: 24px;}
&.round{border-radius:12px;}
&.round > span{display:flex;align-items:center;gap:6px; }
&.round .bar{width:1px;height: 8px;font-size:0;background: #B4B4BE;}
&.round.yellow .bar{background: #F4B000;}
&.round.red .bar{background: #F00;}
button img{width: 10px;}
`
export default Chip