import React, { createRef, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import { delay, uniqueId } from "lodash"

import PopupController from "../../controller/PopupController"

import { IResourceGroup, IResourceNode, IResourceVolume, ResourceGroup } from "../../model/ResourceGroup"
import { IEmail, IEnvironment, Service } from "../../model/Service"
import { IResourceUnit, IVolume, NODE_TYPE } from "../../model/Workspace"
import { Image } from "../../model/Image"

import ISelectOption from "../../interfaces/SelectOption"
import { ERROR_TYPE } from "../../interfaces/Error"

import { authState } from "../../states/authStates"
import { recreateState } from "../../states/recreateState"

import ModalEvent from "../../events/ModalEvent"
import ToastEvent from "../../events/ToastEvent"
import PageEvent from "../../events/PageEvent"

import Form from "../components/uiv2/Form"
import Select from "../components/uiv2/Select"
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../components/uiv2/Button"
import InputBox, { TEXT_INPUT_RULE, TEXT_INPUT_RULE_LEVEL, TEXT_INPUT_SHAPE, TEXT_INPUT_TYPE } from "../components/uiv2/InputBox"
import MultiInputBox, { IMultiInputValue, VALUE_STATUS } from "../components/uiv2/MultiInputBox"
import ResourceUnit, { ResourceUnitType } from "../components/uiv2/ResourceUnit"

import regularExpression from "../../utils/regularExpression"
import { SORT_ORDER } from "../../model/BaseDataType"

interface IFormError {
  resourceGroup:string
  project:string
  email:string
  serviceName:string
  image:string
  command:string[]
  environment:IEnvironment[]
  dataVolume:IVolume[]
  resourceType:string
  resourceBlock:string
  resourceNodeCpu:string
  resourceNodeRam:string
  resourceNodeReplica:string
}

interface IFormState {
  // 서비스 기본정보
  project:string
  projectList:string[]
  email:IMultiInputValue[]
  emailList:string[]
  serviceName:string
  serviceNameCheckFlag:boolean

  // 이미지
  image:string
  imageDigest:string
  reg:string
  command:string[]
  environment:IEnvironment[]
  argument:string[]
  dataVolume:IVolume[]

  // 리소스 유닛
  selectedResourceType:ISelectOption
  // GPU 리소스
  selectedResourceUnitType:string|null,
  selectedResourceUnitKey:number|null
  selectedResourceUnitId:number|null
  resourceBlockQty:number|string
  // CPU 리소스
  resourceNodeCpuQty:number|string
  resourceNodeRamSize:number|string
  resourceNodeReplica:number|string
}

interface IResourceGroupState {
  // 리소스 그룹
  list:IResourceGroup[]
  selectList:ISelectOption[]
  selectedOption?:ISelectOption
  selected?:IResourceGroup //선택된 리소스 그룹
}

interface IResourceUnitState {
  typeList:ISelectOption[]
  gpuUnitList:IResourceUnit[]
  cpuUnitList:any[]
  cpuMaxCore:number
  memMaxGiB:number
  cpuMinCore:number
  memMinGiB:number
  availableCpuNodeList:any[]
}

const ServiceForm = () => {
  const RegExp = regularExpression.regExp

  const [ authInfo ] = useRecoilState(authState)
  const [ serviceId, setServiceId ] = useState<number>(-1)
  const [ reCreateFlag, setReCreateFlag ] = useState<boolean>(false)
  const [ recreateData, setRecreate ] = useRecoilState(recreateState) 
  const navigate = useNavigate()
  const params = useParams()
  const popupController = PopupController.getInstance()
  
  const formRef = useRef<any>()
  const resourceGroupSelectRef = useRef<any>()
  const resourceTypeSelectRef = useRef<any>()
  let refId:string[] = []
  for (let i = 0; i <= 999; i++) {
    refId.push(uniqueId())
  }
  let elementsRef = refId.map(() => createRef())
  let timeoutId1:number = -1
  let timeoutId2:number = -1
  
  const [ resourceGroup, _setResourceGroup ] = useState<IResourceGroupState>({
    list: [],
    selectList: []
  })
  const resourceGroupeRef = useRef(resourceGroup)
  const setResourceGroup = (data:any) => {
    resourceGroupeRef.current = data
    _setResourceGroup(data)
  }

  const [ resourceUnit, _setResourceUnit ] = useState<IResourceUnitState>({
    typeList: [],
    gpuUnitList: [],
    cpuUnitList: [],
    cpuMaxCore: -1,
    memMaxGiB: -1,
    cpuMinCore: 99999,
    memMinGiB: 99999,
    availableCpuNodeList: [],
  })
  const resourceUnitRef = useRef(resourceUnit)
  const setResourceUnit = (data:any) => {
    resourceUnitRef.current = data
    _setResourceUnit(data)
  }

  const emptyError:IFormError = {
    resourceGroup: '',
    project: '',
    email: '',
    serviceName: '',
    image: '',
    command: [''],
    environment: [{
      key: '',
      value: ''
    }],
    dataVolume: [{
      pvc: '',
      mountPath: ''
    }],
    resourceType: '',
    resourceBlock: '',
    resourceNodeCpu: '',
    resourceNodeRam: '',
    resourceNodeReplica: ''
  }
  const [ error, _setError ] = useState<IFormError>(emptyError)
  const errorRef = useRef(error)
  const setError = (data:any) => {
    stateRef.current = data
    _setError(data)
  }

  const emptyForm:IFormState = {
    // 서비스 기본정보
    project: '',
    projectList: [],
    serviceName: '',
    serviceNameCheckFlag: false,
    email: [],
    emailList: [],
    // 이미지
    image: '',
    imageDigest: '',
    reg: '',
    command:[''],
    environment:[{
      key: '',
      value: ''
    }],
    argument:[''],
    dataVolume: [{
      pvc: '',
      mountPath: ''
    }],
    // 리소스 유닛
    selectedResourceType: {label:'', value:''},
    // GPU 리소스
    selectedResourceUnitType: null,
    selectedResourceUnitKey: null,
    selectedResourceUnitId: null,
    resourceBlockQty: '',
    // CPU 리소스
    resourceNodeCpuQty: '',
    resourceNodeRamSize: '',
    resourceNodeReplica: ''
  }
  const [ updateForm, setUpdateForm ] = useState<IFormState|null>(null)
  const [ state, _setState ] = useState<IFormState>(emptyForm)
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  
  useEffect(() => {
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.addEventListener(PageEvent.REFRESH_PAGE_FORM, refreshPageHandler)
    document.addEventListener('keydown', preventEnterKey)
    return() => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.removeEventListener(PageEvent.REFRESH_PAGE_FORM, refreshPageHandler)
      document.removeEventListener('keydown', preventEnterKey)
    }
  }, [])

  useEffect(() => {
    const newServiceId = params?.id ? Number(params?.id) : -1
    if (serviceId !== -1 && newServiceId === -1) {
      window.location.reload()
    } else {
      resetForm(emptyForm)
      setServiceId(newServiceId)
    }
    getResourceGroupList()
  }, [params?.id])

  useEffect(() => {
    if (resourceGroup.selectList.length > 0) {
      if (serviceId !== -1) {
        getServiceData()
      } else if (recreateData !== null) {
        // 재신청 데이터 불러오기 - 나중에 구현하게 되면...
      }
    }
  }, [resourceGroup.selectList])

  useEffect(() => {
    if (resourceGroup.selected !== undefined) {
      setResourceTypeList()
    }
  }, [resourceGroup.selected])

  useEffect(() => {
    if (resourceUnit.typeList.length > 0) {
      if (updateForm !== null) {
        let tempForm:IFormState = updateForm
        if (tempForm.selectedResourceType.value === 'cpu') {
          for (let key in resourceUnit.typeList) {
            let eachType = resourceUnit.typeList[key]
            if (eachType.value === 'cpu') {
              tempForm.selectedResourceType = eachType
            }
          }
        } else {
          for (let key in resourceUnit.typeList) {
            let eachType = resourceUnit.typeList[key]
            if (eachType.value === 'gpu') {
              tempForm.selectedResourceType = eachType
            }
          }
          for (let key in resourceUnit.gpuUnitList) {
            let eachUnit = resourceUnit.gpuUnitList[key]
            if (eachUnit.resourceBlockSpec.type === tempForm.selectedResourceUnitType) {
              tempForm.selectedResourceUnitKey = Number(key)
              tempForm.selectedResourceUnitId = eachUnit.resourceBlockSpec.id
            }
          }
        }
        if (tempForm.environment.length === 0) tempForm.environment = [{ key: '', value: '' }]
        if (tempForm.argument.length === 0) tempForm.argument = ['']
        resetForm(tempForm)
        setUpdateForm(null)
      } else {
        resetForm(emptyForm)
      }
    }
  }, [resourceUnit.typeList])

  useEffect(() => {
    checkDuplicatedMountPath()
  }, [state.dataVolume])

  const getServiceData = async() => {
    try {
      const response = await Service.getDetail(serviceId)
      
      const serviceData = response.service
      let imageListResponse
      try {
        imageListResponse = await Image.getImageListOps(serviceData.name, false, SORT_ORDER.ASC, serviceData.isCommon)
      } catch(error) {
        if ((error as Error).message === ERROR_TYPE.ERROR) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 925563')
        }
      }
      let repository = serviceData.image.split(':')[0]
      let tag = serviceData.image.split(':')[1]
      let imageDigest = ''
      for (let eachImage of imageListResponse.common.imageList) {
        if (eachImage.rep === repository && eachImage.tag === tag) {
          imageDigest = eachImage.id
        }
      }
      let emailArray:IMultiInputValue[] = []
      for (let eachMail of serviceData.emails) {
        emailArray.push({
          status: VALUE_STATUS.NORMAL,
          value: eachMail.name === null ? [eachMail.email] : [eachMail.name, eachMail.email]
        })
      }
      if (serviceData.volumes.length === 0) serviceData.volumes = [{ pvc: '', mountPath: '' }]
      for (let idx in serviceData.volumes) {
        let eachVolume:IVolume = serviceData.volumes[idx]
        if (eachVolume.pvc === authInfo.id) eachVolume.fakePvc = '사용자 개인 볼륨'
      }
      
      setUpdateForm({
        // 서비스 기본정보
        project: serviceData.project,
        projectList: [],
        email: emailArray,
        emailList: [],
        serviceName: serviceData.name,
        serviceNameCheckFlag: true,
        // 이미지
        image: imageDigest ? serviceData.image : '',
        imageDigest: imageDigest,
        reg: imageDigest ? imageListResponse.common.reg : '',
        command: serviceData.command,
        environment: serviceData.env,
        argument: serviceData.args,
        dataVolume: serviceData.volumes,
        // 리소스 유닛
        selectedResourceType: {label:'', value:serviceData.resourceBlock.type},
        // GPU 리소스
        selectedResourceUnitType: serviceData.resourceBlock.type,
        selectedResourceUnitKey: null,
        selectedResourceUnitId: null,
        resourceBlockQty: serviceData.resourceBlock.gpuNum || '',
        // CPU 리소스
        resourceNodeCpuQty: serviceData.resourceBlock.cpu,
        resourceNodeRamSize: serviceData.resourceBlock.mem,
        resourceNodeReplica: serviceData.replicas
      })

      for (let eachSelect of resourceGroup.selectList) {
        if (eachSelect.label === serviceData.rgroup) setResourceGroup({ 
          ...resourceGroup, 
          selectedOption:eachSelect 
        })
      }

      if (imageDigest === '') {
        setError({
          ...error,
          image: '이미지 목록에 등록한 이미지가 없습니다. 다시 이미지를 등록하시거나 다른 이미지를 선택해 주세요.'
        })
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 4cf843')
      }
    }
  }

  const preventEnterKey = (e:any) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    let payload
    switch (e.payload.action) {
      case 'cancelForm':
        navigate('/')
        break
      case 'initForm':
        // 신청 초기화 (새로고침)
        window.location.reload()
        break
      case 'submitService':
      case 'updateService':
        payload = e.payload.key
        //console.log(payload.serviceId, e, payload)
        let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        let message = ''
        try {
          if (e.payload.action === 'submitService') {
            await Service.create(payload)
            message = `${payload.name} 서비스 생성을 신청하였습니다. 서비스 생성을 시작합니다.`
          } else {
            await Service.update(payload.serviceId, payload)
            message = `${payload.name} 서비스 업데이트를 신청하였습니다. 서비스 업데이트를 시작합니다.`
          }
          toastEvent.payload = { message: message }
          window.dispatchEvent(toastEvent)
          
          navigate('/services')
        } catch (error) {
          if (e.payload.action === 'submitService') {
            popupController.confirm(`${payload.name} 서비스 생성 신청에 실패하였습니다.`)
          } else {
            popupController.confirm(`${payload.name} 서비스 업데이트 신청에 실패하였습니다.`)
          }
        }
        break
      case 'cancelCreateService':
        navigate('/services')
        break
      case 'cancelUpdateService':
        payload = e.payload.key
        navigate('/service/'+payload.serviceId)
        break
    }
  }

  // 메뉴에서 신청 버튼 클릭 시 초기화
  const refreshPageHandler = () => {
    window.location.reload()
  }

  // 리소스 그룹
  const getResourceGroupList = async() => {
    try {
      const response = await ResourceGroup.getRgroupList(authInfo.userNo)
      let resourceGroupSelectList:ISelectOption[] = []
  
      for (let key in response.rgroup) {
        if(response.rgroup[key].isCommon) {
          resourceGroupSelectList.push({
            fakeLabel: '기본 리소스 그룹',
            label: response.rgroup[key].name,
            value: key
          })
        } else {
          resourceGroupSelectList.push({
            label: response.rgroup[key].name, 
            value: key
          })  
        }
      }
  
      setResourceGroup({
        ...resourceGroup,
        list: response.rgroup,
        selectList: resourceGroupSelectList
      })
    } catch(error) {
      if ((error as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - e16df0')
      }
    }
  }

  const onSelectResourceGroup = async(data:ISelectOption) => {
    if (reCreateFlag) return
    if (data.value === '') return

    try {
      // 리소스 그룹 설정 결과 반영
      const selectedResourceGroup = resourceGroup.list[Number(data.value)]
      setResourceGroup({
        ...resourceGroup,
        selected: selectedResourceGroup,
        selectedOption: data
      })
    } catch(e) {
      console.log(e)
      if ((e as Error).message === ERROR_TYPE.IS_INIT_MIG) {
        popupController.confirm('해당 리소스 그룹에 포함된 노드 중 MIG 설정 중인 노드가 있습니다. 잠시 후 다시 시도해 주세요.')
        setResourceGroup({
          ...resourceGroup,
          selectedOption: {label: resourceGroup.selectedOption?.label || '', value: resourceGroup.selectedOption?.label || ''},
        })
      } else {
        popupController.confirm('에러가 발생했습니다.')
      }
    }
  }

  // 서비스 기본정보
  const checkDuplicateHandler = async():Promise<boolean> => {
    let result = false
    // console.log(resourceGroup.selected, stateRef.current.serviceName, state.serviceName, errorRef.current.serviceName)
    if (resourceGroup.selected !== undefined) {
      if (state.serviceName === '') {
        setState({
          ...state,
          serviceNameCheckFlag: false
        })
        setError({
          ...error,
          serviceName: '필수 입력 항목입니다.'
        })
        return false
      }

      let available:boolean = false
      try {
        available = await Service.checkDuplicate(resourceGroup.selected.name, state.serviceName)
      } catch(error) {
        if ((error as Error).message === ERROR_TYPE.ERROR) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 16fd44')
        }
      }
      result = available
      setState({
        ...state,
        serviceNameCheckFlag: available
      })
      setError({
        ...error,
        serviceName: available === true ? '' : '사용 중인 서비스 이름입니다.'
      })
    }
    return result
  }

  // 이미지
  const addCommandHandler = () => {
    setState({
      ...state,
      command: state.command.concat([''])
    })
    setError({
      ...error,
      command: error.command.concat([''])
    })
  }
  
  const removeCommandHandler = (idx:number) => {
    if (idx === 0 && state.command.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = state.command.concat([])
      const newArray2 = error.command.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setState({
        ...state,
        command: newArray1
      })
      setError({
        ...error,
        command: newArray2
      })
    }
  }

  const addEnvironmentHandler = () => {
    setState({
      ...state,
      environment: state.environment.concat({
        key: '',
        value: ''
      })
    })
    setError({
      ...error,
      environment: error.environment.concat({
        key: '',
        value: ''
      })
    })
  }

  const removeEnvironmentHandler = (idx:number) => {
    if (idx === 0 && state.environment.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = state.environment.concat([])
      const newArray2 = error.environment.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setState({
        ...state,
        environment: newArray1
      })
      setError({
        ...error,
        environment: newArray2
      })
    }
  }
 
  const addArgumentHandler = () => {
    setState({
      ...state,
      argument: state.argument.concat('')
    })
  }
  
  const removeArgumentHandler = (idx:number) => {
    if (idx === 0 && state.argument.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray = state.argument.concat([])
      newArray.splice(idx, 1)
      setState({
        ...state,
        argument: newArray
      })
    }
  }

  // 데이터 볼륨
  const addVolumeHandler = () => {
    setState({
      ...state,
      dataVolume: state.dataVolume.concat({
        pvc: '',
        mountPath: ''
      })
    })
    setError({
      ...error,
      dataVolume: error.dataVolume.concat({
        pvc: '',
        mountPath: ''
      })
    })
  }

  const removeVolumeHandler = (idx:number) => {
    if (idx === 0 && state.dataVolume.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = state.dataVolume.concat([])
      const newArray2 = error.dataVolume.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setState({
        ...state,
        dataVolume: newArray1
      })
      setError({
        ...error,
        dataVolume: newArray2
      })
    }
  }
 
  // 리소스 유닛
  const setResourceTypeList = async () => {
    try {
      if (resourceGroup.selected) {
        // 리소스 설정 selectBox 세팅
        const resourceBlockList = await ResourceGroup.getResourceBlockInfo(resourceGroup.selected.name)
        const resourceTypeSelectList: ISelectOption[] = []
        if (resourceBlockList.cpuResourceBlockList.length > 0) {
          resourceTypeSelectList.push({ label: 'CPU 리소스 타입', value: NODE_TYPE.CPU })
        }
        if (resourceBlockList.gpuResourceBlockList.length > 0) {
          resourceTypeSelectList.push({ label: 'GPU 리소스 블록 타입', value: NODE_TYPE.GPU })
        }

        // 리소스 설정 cpu 관련 Max값 세팅
        let cpuMaxCore = -1, memMaxGiB = -1, cpuMinCore = 99999, memMinGiB = 99999
        if (resourceBlockList.cpuResourceBlockList.length > 0) {
          for (let eachCpu of resourceBlockList.cpuResourceBlockList) {
            if (eachCpu.resourceBlockSpec.cpu > cpuMaxCore) cpuMaxCore = eachCpu.resourceBlockSpec.cpu
            if (eachCpu.resourceBlockSpec.cpu < cpuMinCore) cpuMinCore = eachCpu.resourceBlockSpec.cpu
            if (eachCpu.resourceBlockSpec.mem > memMaxGiB) memMaxGiB = eachCpu.resourceBlockSpec.mem
            if (eachCpu.resourceBlockSpec.mem < memMinGiB) memMinGiB = eachCpu.resourceBlockSpec.mem
          }
        }

        setResourceUnit({
          ...resourceUnit,
          typeList: resourceTypeSelectList,
          gpuUnitList: resourceBlockList.gpuResourceBlockList,
          cpuUnitList: resourceBlockList.cpuResourceBlockList,
          cpuMaxCore: cpuMaxCore,
          memMaxGiB: memMaxGiB,
          cpuMinCore: cpuMinCore,
          memMinGiB: memMinGiB
        })
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 04e692')
      } 
      else if((e as Error).message === ERROR_TYPE.IS_INIT_MIG) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 6909bd')
      }
    }
  }

  const onSelectResourceType = (data:ISelectOption) => {
    if (state.selectedResourceType.value !== data.value) {
      setState({
        ...state,
        selectedResourceUnitKey: null,
        selectedResourceUnitId: null,
        resourceBlockQty: '',
        resourceNodeCpuQty: '',
        resourceNodeRamSize: '',
        resourceNodeReplica: '',
        selectedResourceType: data
      }) 
      delay(() => {
        setError({
          ...error,
          resourceType: '',
          resourceBlock: '',
          resourceNodeCpu: '',
          resourceNodeRamSize: '',
          resourceNodeReplica: '',
          selectedResourceType: reCreateFlag && resourceUnit.typeList.length === 1 ? '리소스 설정이 초기화되었습니다. 리소스를 재설정해 주세요.' : '' //재신청 시, 노드 타입이 하나뿐일때 해당 노드 자동으로 선택되면서 문구 초기화되는 부분 수정
        })
      }, 100)
    }
  }

  const selectResourceBlockTypeHandler = (idx:number, id:number, checked:boolean) => {
    if (checked === true) {
      setState({
        ...state,
        resourceBlockQty: '',
        resourceNodeCpuQty: '',
        resourceNodeRamSize: '',
        resourceNodeReplica: '',
        selectedResourceUnitKey: idx,
        selectedResourceUnitId: id
      })
    } else {
      setState({
        ...state,
        resourceBlockQty: '',
        resourceNodeCpuQty: '',
        resourceNodeRamSize: '',
        resourceNodeReplica: '',
        selectedResourceUnitKey: null,
        selectedResourceUnitId: null
      })
    }
    setError({
      ...error,
      resourceBlock: ' ',
      resourceNodeReplica: ' ',
      resourceNodeCpu: ' ',
      resourceNodeRam: ' '
    })
    delay(() => {
      setError({
        ...error,
        resourceBlock: '',
        resourceNodeReplica: '',
        resourceNodeCpu: '',
        resourceNodeRam: ''
      })
    }, 10)
  }

  // 그 외
  const callPopupHandler = (popupName:string, idx?:number) => {
    switch(popupName) {
      case 'projectName':
        popupController.serviceType(
          (value:any) => {
            setState({
              ...state,
              project: value,
              projectList: []
            })
          }, 
          state.project
        )
        break
      case 'email':
        let emailArray:IEmail[] = []
        for (let eachMail of state.email) {
          emailArray.push(eachMail.value.length === 2 ? {
            name: eachMail.value[0],
            email: eachMail.value[1],
            selected: true
          } : {
            name: '',
            email: eachMail.value[0],
            selected: true
          })
        }
        popupController.email(
          (value:IEmail[]) => {
            let totalEmail = state.email
            for(let idx in value){
              let findIdx = totalEmail.findIndex((e) => e.value[0] === value[idx].email || e.value[1] === value[idx].email)
              if(findIdx === -1){ //email 리스트에 없으면 추가
                totalEmail.push({
                  value: value[idx].name.length > 0 ? [value[idx].name, value[idx].email] : [value[idx].email],
                  status: RegExp.MAIL_REG_EXP.test(value[idx].email) ? VALUE_STATUS.NORMAL : VALUE_STATUS.ERROR
                })
              }
            }
            setState({
              ...state,
              email: totalEmail
            })
          }, emailArray
        )
        break
      case 'imageSelect':
        popupController.imageOps(
          (value:any, reg:string) => {
            setState({
              ...state,
              image: value.rep + ':' + value.tag,
              imageDigest: value.id,
              reg: reg
            })
            setError({
              ...error,
              image: '',
            })
          },
          false,
          resourceGroup.selected?.isCommon ? authInfo?.id : resourceGroup.selected?.name || '',
          (resourceGroup.selected ? resourceGroup.selected.isCommon : false) ,
          state.reg,
          state.image
        )
        break
      case 'volumeSelect':
        if (idx !== undefined) popupController.volumeOps(
          (value:any) => {
            let volumes = state.dataVolume
            volumes[idx].pvc = value ? value.name : ''
            volumes[idx].fakePvc = value && value.fakeName ? value.fakeName : undefined
            setState({
              ...state,
              dataVolume: volumes
            })
          },
          idx,
          resourceGroup.selected?.name || '',
          state.dataVolume[idx].pvc
        )
        break
    }
  }

  const resetForm = (newFormState:IFormState) => {
    // gpu나 cpu 중 한 가지만 있을 때는 해당 타입으로 자동 고정
    if (resourceUnit.typeList.length === 1) {
      newFormState.selectedResourceType = resourceUnit.typeList[0]
    }

    setError(emptyError)
    setState(newFormState)
  }

  const getNameAutoCompleteList = async(id:any, value:any) => {
    window.clearTimeout(timeoutId1)
    timeoutId1 = window.setTimeout(async() => {
      try {
        let response
        if (value === '') {
          response = []
        } else {
          response = await Service.getServiceTypeList(value)
        }
        setState({
          ...state,
          project: value,
          projectList: response
        })
      } catch (e) {
        if ((e as Error).message === ERROR_TYPE.ERROR) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 07ef51')
        }
      }
    }, 1000)
  }

  const updateEmailList = (emailList:IMultiInputValue[]) => {
    let emailError = ''
    for (let eachEmail of emailList) {
      if (eachEmail.status === VALUE_STATUS.ERROR) {
        emailError = '형식에 맞지 않은 이메일 주소를 삭제해 주세요'
      }
    }
    setState({ ...state, email: emailList })
    setError({ ...error, email: emailError })
  }

  const getMailAutoCompleteList = async(id:any, value:any) => {
    window.clearTimeout(timeoutId2)
    timeoutId2 = window.setTimeout(async() => {
      try {
        let emailArray:string[] = []
        if (value !== '') {
          const response = await Service.getEmailList(value)
          if (response) {
            for (let idx in response) {
              let email = response[idx]
              emailArray.push(email.email)
            }
          }
        }
        setState({
          ...state,
          emailList: emailArray
        }) 
      } catch (e) {
        if ((e as Error).message === ERROR_TYPE.ERROR) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - a85bd2')
        }
      }
    }, 500)
  }

  const submitHandler = async () => {
    let key
    let errorFlag = 0

    if (resourceGroup.selected === undefined) {
      setError({
        ...error,
        resourceGroup: '필수 선택 항목입니다.'
      })
      popupController.confirm(`리소스 그룹을 먼저 설정해 주세요.`)
      return false
    }
    
    let resourceTypeError = ''
    if (state.selectedResourceType.value === NODE_TYPE.NONE) {
      errorFlag++
      resourceTypeError = '필수 입력 항목입니다.'
    }
    
    let command:string[] = []
    let commandError:string[] = []
    for (key = 0; key < state.command.length; key++) {
      if (state.command[key]) {
        command.push(state.command[key])
        commandError.push(error.command[key])
      }
    }
    if (command.length === 0) errorFlag++

    let env:IEnvironment[] = []
    let envError:IEnvironment[] = []
    for (key = 0; key < state.environment.length; key++) {
      if (state.environment[key].key !== '' || state.environment[key].value !== '') {
        env.push(state.environment[key])
        envError.push(error.environment[key])
      }
    }
    for (key in env) {
      let eachEnvironment = env[key]
      if (eachEnvironment.key === '' && eachEnvironment.value !== '') {
        envError[Number(key)].key = 'Value값 입력 시 Key값을 함께 입력해 주세요.'
      }
      if (eachEnvironment.key !== '' && eachEnvironment.value === '') {
        envError[Number(key)].key = 'Key값 입력 시 Value값을 함께 입력해 주세요.'
      }
    }

    let args:string[] = []
    for (key = 0; key < state.argument.length; key++) {
      if (state.argument[key]) args.push(state.argument[key])
    }

    let volumes:IVolume[] = []
    let volumeError:IVolume[] = []
    for (key = 0; key < state.dataVolume.length; key++) {
      if (state.dataVolume[key].pvc !== '' || state.dataVolume[key].mountPath !== '') {
        volumes.push(state.dataVolume[key])
        volumeError.push(error.dataVolume[key])
      }
    }
    
    if (formRef.current?.validate() === false || errorFlag > 0) {
      if (env.length === 0) env = envError = [{key: '', value: ''}]
      if (args.length === 0) args = ['']
      if (volumes.length === 0) volumes = volumeError = [{pvc: '', mountPath: ''}]
      volumeError = getMountPathError(volumes, volumeError)

      if (command.length === 0) {
        setState({ 
          ...state, 
          command: [''], 
          environment: env, 
          argument: args,
          dataVolume: volumes
        })
        setError({ 
          ...error, 
          command: [''], 
          environment: envError,
          dataVolume: volumeError
        })
      }
      delay(() => {
        setState({
          ...state,
          command: command.length === 0 ? [''] : command, 
          environment: env, 
          argument: args,
          dataVolume: volumes
        })
        setError({
          ...error,
          resourceType: resourceTypeError,
          command: command.length === 0 ? ['필수 입력 항목입니다.'] : commandError, 
          environment: envError,
          dataVolume: volumeError
        })
      }, 100)
      popupController.confirm(`입력 값이 없거나 잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.`)
      return false
    }
    errorFlag = 0

    let payload = formRef.current?.serialize() 
    payload.name = state.serviceName
    payload.imageDigest = state.imageDigest
    payload.reg = state.reg 
    payload.command = command
    payload.args = args
    payload.env = env
    payload.volumes = volumes

    let emailError = ''
    payload.emails = []
    for (let eachEmail of state.email) {
      if (eachEmail.status === VALUE_STATUS.ERROR) {
        if (errorFlag % 2 === 0) errorFlag += 1
        emailError = '형식에 맞지 않은 이메일 주소를 삭제해 주세요'
      } else {
        payload.emails.push({
          name: (eachEmail.value.length === 2 ? eachEmail.value[0] : null),
          email: (eachEmail.value.length === 2 ? eachEmail.value[1] : eachEmail.value[0])
        })
      }
    }

    let serviceNameError = ''
    if (serviceId === -1) {
      if (state.serviceNameCheckFlag === false) {
        if (errorFlag <= 4) errorFlag += 4
        serviceNameError = '서비스 이름의 중복 확인을 해주세요'
        popupController.confirm(`서비스 이름의 중복 여부를 확인해 주세요.`)
      } else {
        let available = await checkDuplicateHandler()
        if (available === false) {
          popupController.confirm('사용 중인 서비스 이름입니다.')
          if (errorFlag <= 4) errorFlag += 4
        }
      }
    }

    payload.resourceBlock = {}
    payload.resourceBlock.type = state.selectedResourceType.value
    payload.rgroup = resourceGroup.selected.name
    payload.namespace = resourceGroup.selected.name
    payload.isCommon = resourceGroup.selected.isCommon
    payload.nodes = []
    switch (state.selectedResourceType.value) {
      case NODE_TYPE.GPU:
        if (state.selectedResourceUnitKey !== null) {
          const RESOURCE = resourceUnit.gpuUnitList[state.selectedResourceUnitKey]
          payload.resourceBlock = RESOURCE.resourceBlockSpec
          payload.resourceBlock.gpuNum = Number(state.resourceBlockQty)
          payload.nodes = RESOURCE.nodeList
        } else {
          resourceTypeError = '필수 입력 항목입니다.'
          if (Math.ceil((errorFlag+1)/2) % 2 !== 0) errorFlag += 2
        }
        break
      case NODE_TYPE.CPU:
        for (let eachUnit of resourceUnit.cpuUnitList) {
          payload.nodes.push(eachUnit.nodeName)
        }
        payload.resourceBlock.cpu = Number(state.resourceNodeCpuQty)
        payload.resourceBlock.mem = Number(state.resourceNodeRamSize)
        payload.resourceBlock.memUnit = 'GiB'
        payload.resourceBlock.gpuMem = null
        payload.resourceBlock.gpuMemUnit = null
        payload.resourceBlock.gpuPer = null
        payload.resourceBlock.gpuMem = null
        payload.resourceBlock.isPartedGpu = false
        payload.resourceBlock.gpuNum = null
        payload.resourceBlock.realType = null
        break
      case NODE_TYPE.NONE:
        resourceTypeError = '필수 입력 항목입니다.'
        if (Math.ceil((errorFlag+1)/2) % 2 !== 0) errorFlag += 2
        break
    }
    payload.replicas = Number(state.resourceNodeReplica)

    // console.log('payload', payload)
    
    if (env.length === 0) env = envError = [{key: '', value: ''}]
    if (args.length === 0) args = ['']
    if (volumes.length === 0) volumes = volumeError = [{pvc: '', mountPath: ''}]
    volumeError = getMountPathError(volumes, volumeError)
    for (key in volumeError) {
      if ((volumeError[key].pvc !== '' && volumeError[key].mountPath === '') || (volumeError[key].pvc === '' && volumeError[key].mountPath !== '')) {
        if (errorFlag % 2 === 0) errorFlag += 1
      }
    }
    
    for (key in env) {
      let eachEnvironment = env[key]
      if (eachEnvironment.key === '' && eachEnvironment.value !== '') {
        if (Math.ceil((errorFlag+1)/2) % 2 !== 0) errorFlag += 2
      }
      if (eachEnvironment.key !== '' && eachEnvironment.value === '') {
        if (Math.ceil((errorFlag+1)/2) % 2 !== 0) errorFlag += 2
      }
    }
    
    setState({ 
      ...state, 
      dataVolume: payload.volumes,
      environment: payload.env,
      argument: payload.args,
      command: payload.command
    })

    if (errorFlag !== 0) {
      switch (errorFlag) {
        case 1:
          popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
          break
        case 2:
          popupController.confirm('입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
          break
        case 3:
          popupController.confirm('입력 값이 없거나 잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
          break
      }
      delay(setError, 500, {
        ...error,
        email: emailError,
        serviceName: serviceNameError,
        resourceType: resourceTypeError,
        environment: envError,
        dataVolume: volumeError
      })
      return false
    } else {
      if (serviceId === -1) {
        popupController.dialouge(`${payload.name} 서비스를 생성합니다. 진행하시겠습니까?`, 'submitService', payload, '확인', '취소')
      } else {
        payload.serviceId = serviceId
        popupController.dialouge(`${payload.name} 서비스를 업데이트합니다. 진행하시겠습니까?`, 'updateService', payload, '확인', '취소')
      }
    }
  }

  const cancelHandler = () => {
    if (serviceId === -1) {
      popupController.dialouge(`서비스 생성을 취소합니다. 진행하시겠습니까?`, 'cancelCreateService', null, '확인', '취소')
    } else {
      popupController.dialouge(`서비스 업데이트를 취소합니다. 진행하시겠습니까?`, 'cancelUpdateService', { serviceId: serviceId }, '확인', '취소')
    }
  }

  const checkDuplicatedMountPath = () => {
    let volumeError:IVolume[] = []
    for (let i = 0; i < state.dataVolume.length; i++) {
      volumeError.push({ pvc: '', mountPath: '' })
    }
    setError({ ...error, dataVolume: volumeError })

    delay(() => {
      volumeError = getMountPathError(state.dataVolume.concat(), error.dataVolume.concat())
      setError({ ...error, dataVolume: volumeError })
    }, 10)
  }

  const getMountPathError = (volumeArray:IVolume[], volumeError:IVolume[]):IVolume[] => {
    let mountPathSet:string[] = []
    for (let key in volumeArray) {
      let eachVolume:IVolume = volumeArray[key]
      mountPathSet.push(eachVolume.mountPath)
      if (volumeError[key]) volumeError[key].mountPath = ''
    }

    for (let key = 0; key < mountPathSet.length; key++) {
      if (mountPathSet[key] !== '' && mountPathSet.lastIndexOf(mountPathSet[key]) !== key) {
        volumeError[mountPathSet.lastIndexOf(mountPathSet[key])].mountPath = '중복된 마운트 경로입니다. 경로를 다시 확인해 주세요.'
        if (volumeError[key]) volumeError[key].mountPath = '중복된 마운트 경로입니다. 경로를 다시 확인해 주세요.'
      }
    }

    return volumeError
  }

  const parseNumber = (value:string|number):number => {
    return Number(String(value)) || 0
  }

  return (
    <FormFragment>
      <div className='pageTitle' style={{display:'flex', gap:'16px'}}>
        <div>서비스 { serviceId === -1 ? '생성' : '업데이트' }</div>
      </div>
      <Form ref={formRef} className="formWrap">
        <h3 className="sectionTitle">리소스 그룹 설정 <span className="requiredMark">•</span></h3>
        <section>
          <div className="inputWrap mb8">
            <Select id="rgroup" ref={resourceGroupSelectRef} option={resourceGroup.selectList} 
              placeholder="리소스 그룹을 선택해 주세요." 
              readonly={reCreateFlag || serviceId !== -1} 
              errorText={error.resourceGroup}
              selected={reCreateFlag ? resourceGroup.selectList.find(element => element.label === resourceGroup.selected?.name) : resourceGroup.selectedOption} 
              onChange={onSelectResourceGroup}></Select>
          </div>
          <div className="inputWrap mb8"></div>
        </section>
        {resourceGroup.selected ? <section>
          <div className="inputWrap" style={{ flexGrow: 1 }}>
            <div className="resPreview">
                <div className="resPreviewWrap">
                  <p className="resField">노드 (IP/이름)</p>
                  { resourceGroup.selected.nodeList.length > 0 ? 
                  <>
                    { resourceGroup.selected.nodeList.map((value:IResourceNode, idx:number) => {
                      return (<p key={idx}>{value.ip+" / "+value.name}</p>)
                    }) } 
                  </> 
                  : '-'}
                </div>
                <div className="resPreviewWrap">
                  <p className="resField">볼륨</p>
                  { resourceGroup.selected.volumeList.length > 0 ? 
                  <>
                    { resourceGroup.selected.volumeList.map((value:IResourceVolume, idx:number) => {
                      return (<p key={idx}>{value.name+" / "+value.size}</p>)
                    }) }
                  </>
                  : '-'}
                </div>
                <div className="resPreviewWrap">
                  <p className="resField">이미지 레지스트리</p>
                  {resourceGroup.selected.reg ? <p>{resourceGroup.selected.reg}</p> : '-' }
                </div>
              </div>
          </div>
        </section> : false }

        <div className="divider"></div>

        <h3 className="sectionTitle">서비스 기본 설정</h3>
        <section>
          <div className="inputWrap">
            <label className="label">서비스 분류명</label>
            <InputBox id="project" ref={elementsRef[0]} type={TEXT_INPUT_TYPE.TEXT} 
              value={state.project} errorText={errorRef.current.project}
              rules={[{
                basis: TEXT_INPUT_RULE.REGEXP,
                rule: RegExp.SERVICE_NAME_RULE,
                invalidateMessage: '소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요.'
              }]}
              disabled={resourceGroup.selected === undefined} 
              placeholder="소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요." maxLength={63} 
              onChange={getNameAutoCompleteList} useSystemAutoComplete={false} autoCompleteValue={state.project === '' ? [] : state.projectList}
              button="가져오기" onClickButton={() => { callPopupHandler('projectName') }} />
          </div>
          <div className="inputWrap">
            <label className="label">서비스 이름 <span className="requiredMark">•</span></label>
            <InputBox id="name" ref={elementsRef[1]} type={TEXT_INPUT_TYPE.TEXT} 
              value={state.serviceName} errorText={error.serviceName} 
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED,
                invalidateMessage: '필수 입력 항목입니다.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP,
                rule: RegExp.SERVICE_NAME_RULE,
                invalidateMessage: '소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요.'
              }]}
              disabled={resourceGroup.selected === undefined}
              readonly={serviceId !== -1} 
              placeholder="소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요." maxLength={63} 
              onChange={(id:any, value:any) => {
                setState({ ...state, serviceName:value, serviceNameCheckFlag: false })
                setError({ ...error, serviceName:'' })
              }}
              button="중복확인" buttonDisabled={state.serviceNameCheckFlag || state.serviceName.length === 0}
              onClickButton={async() => { 
                if (RegExp.SERVICE_NAME_RULE.test(state.serviceName) === true) {
                  let available = await checkDuplicateHandler()
                  popupController.confirm(available === true ? '사용 가능한 서비스 이름입니다.' : '사용 중인 서비스 이름입니다.')
                }
              }} />
          </div>
        </section>
        <section>
          <div className="inputWrap">
            <label className="label">이상징후 알림 이메일 등록</label>
            <MultiInputBox id="email" ref={elementsRef[2]} 
              valueList={state.email} errorText={error.email}
              placeholder="알림을 받을 이메일 주소를 입력해 주세요." 
              disabled={resourceGroup.selected === undefined}
              button="가져오기" onClickButton={() => { callPopupHandler('email') }} 
              onDeleteButton={(id:string, value:number) => {
                let totalEmail = state.email
                totalEmail.splice(value, 1)
                updateEmailList(totalEmail)
              }}
              onChange={getMailAutoCompleteList}
              onUpdate={async (id:string, value:string) => {
                let newValue = [value]
                window.clearTimeout(timeoutId2)
                try {
                  const response = await Service.getEmailList(value)
                  if (response && response.length === 1 && value === response[0]['email']) {
                    newValue = [response[0]['name'], response[0]['email']]
                  }
                } catch (error) {
                  if ((error as Error).message === ERROR_TYPE.ERROR) {
                    popupController.confirm('에러가 발생했습니다.\n에러코드 - a85bd2')
                  }
                }
                let totalEmail = state.email
                totalEmail.push({
                  value: newValue,
                  status: RegExp.MAIL_REG_EXP.test(value) ? VALUE_STATUS.NORMAL : VALUE_STATUS.ERROR
                })
                updateEmailList(totalEmail)
              }} useSystemAutoComplete={false} autoCompleteValue={state.emailList}/>
          </div>
          <div className="inputWrap"></div>
        </section>

        <h3 className="sectionTitle">이미지 설정</h3>
        <section>
          <div className="inputWrap">
            <label className="label">이미지 선택 <span className="requiredMark">•</span></label>
            <InputBox id="image" ref={elementsRef[3]} type={TEXT_INPUT_TYPE.TEXT} 
              value={state.image} errorText={error.image}
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED,
                invalidateMessage: '필수 입력 항목입니다.'
              }]}
              disabled={resourceGroup.selected === undefined} readonly={true} deleteButtonFlag={true}
              placeholder="‘가져오기' 버튼을 눌러 사용할 이미지를 선택해 주세요." 
              button="가져오기" onClickButton={() => { callPopupHandler('imageSelect') }}
              onChange={() => {
                // DELETE BUTTON
                setState({
                  ...state,
                  image: '',
                  imageDigest: '',
                  reg: ''
                })
              }} />
          </div>
          <div className="inputWrap"></div>
        </section>
        <section>
          <div className="inputWrap">
            <label className="label">Command <span className="requiredMark">•</span></label>
            {state.command.map((eachCommand:string, idx:number) => {
              return (
                <React.Fragment key={idx}>
                  <div className='inputRow' >
                    <InputBox id={'command'+idx} ref={elementsRef[100+idx]} type={TEXT_INPUT_TYPE.TEXT} 
                      value={eachCommand} errorText={error.command[idx]}
                      disabled={resourceGroup.selected === undefined}
                      onChange={(id:any, value:any) => {
                        let uv = state.command
                        uv[idx] = value
                        setState({ ...state, command:uv })
                      }}
                      placeholder="해당 서비스 실행을 위한 커맨드를 입력해 주세요." />
                    <div>
                      {
                        state.command.length !== 1 && idx !== state.command.length - 1 ? <img src='/images-v2/buttonTrash.png' alt='삭제' className="buttonImage" onClick={() => removeCommandHandler(idx)} /> : false
                      }
                      {
                        idx === state.command.length - 1 ? <img src='/images-v2/buttonPlus.png' alt='추가' className="buttonImage" onClick={addCommandHandler} /> : false
                      }
                    </div>
                  </div>
                </React.Fragment>)
            })}
          </div>
          <div className="inputWrap"></div>
        </section>
        <section>
          <div className="inputWrap">
            <label className="label">Environment</label>
            {state.environment.map((eachEnvironment:IEnvironment, idx:number) => {
              let errorObj = errorRef.current.environment[idx]
              if (!errorObj) errorObj = {
                key: '',
                value: ''
              }
              return (
                <React.Fragment key={idx}>
                  <div className='inputRow' key={idx}>
                    <InputBox id={'env-key'+idx} ref={elementsRef[200+idx]} type={TEXT_INPUT_TYPE.TEXT} 
                      value={eachEnvironment.key} errorText={errorObj.key}
                      rules={[
                        {
                          basis: TEXT_INPUT_RULE.REGEXP,
                          rule: RegExp.KEY_RULE,
                          invalidateMessage: '영어, 숫자, 언더바, 마침표, 하이픈만 입력 가능하며, 첫글자는 영어만 가능합니다.'
                        },
                        {
                          basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                          ref: elementsRef[300+idx],
                          invalidateMessage: 'Value값 입력 시 Key값을 함께 입력해 주세요.',
                          refsInvalidateMessage: 'Key값 입력 시 Value값을 함께 입력해 주세요.'
                        }
                      ]}
                      onChange={(id:any, value:any) => {
                        let uv = state.environment
                        uv[idx].key = value
                        setState({ ...state, environment:uv })
                      }}
                      disabled={resourceGroup.selected === undefined}
                      placeholder="Key 값을 입력해 주세요." />
                    <img src='/images-v2/equal.svg' alt='는' className='equalIcon' />
                    <InputBox id={'env-value'+idx} ref={elementsRef[300+idx]} type={TEXT_INPUT_TYPE.TEXT} 
                      value={eachEnvironment.value} errorText={errorObj.value}
                      rules={[
                        {
                          basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                          ref: elementsRef[200+idx],
                          invalidateMessage: 'Key값 입력 시 Value값을 함께 입력해 주세요.',
                          refsInvalidateMessage: 'Value값 입력 시 Key값을 함께 입력해 주세요.'
                        }
                      ]}
                      onChange={(id:any, value:any) => {
                        let uv = state.environment
                        uv[idx].value = value
                        setState({ ...state, environment:uv })
                      }}
                      disabled={resourceGroup.selected === undefined}
                      placeholder="Value 값을 입력해 주세요." />
                    <div>
                      {
                        state.environment.length !== 1 && idx !== state.environment.length - 1 ? <img src='/images-v2/buttonTrash.png' alt='삭제' className="buttonImage" onClick={() => removeEnvironmentHandler(idx)} /> : false
                      }
                      {
                        idx === state.environment.length - 1 ? <img src='/images-v2/buttonPlus.png' alt='추가' className="buttonImage" onClick={addEnvironmentHandler} /> : false
                      }
                    </div>
                  </div>
                </React.Fragment>)
            })}
          </div>
          <div className="inputWrap"></div>
        </section>
        <section>
          <div className="inputWrap">
            <label className="label">Argument</label>
            {state.argument.map((eachArgument:string, idx:number) => {
              return (
                <React.Fragment key={idx}>
                  <div className='inputRow' key={idx}>
                    <InputBox id={'args'+idx} ref={elementsRef[400+idx]} type={TEXT_INPUT_TYPE.TEXT} 
                      value={eachArgument} errorText=''
                      onChange={(id:any, value:any) => {
                        let uv = state.argument
                        uv[idx] = value
                        setState({ ...state, argument:uv })
                      }}
                      disabled={resourceGroup.selected === undefined}
                      placeholder="해당 서비스 실행에 필요한 Argument를 입력해 주세요." />
                    <div>
                      {
                        state.argument.length !== 1 && idx !== state.argument.length - 1 ? <img src='/images-v2/buttonTrash.png' alt='삭제' className="buttonImage" onClick={() => removeArgumentHandler(idx)} /> : false
                      }
                      {
                        idx === state.argument.length - 1 ? <img src='/images-v2/buttonPlus.png' alt='추가' className="buttonImage" onClick={addArgumentHandler} /> : false
                      }
                    </div>
                  </div>
                </React.Fragment>)
            })}
          </div>
          <div className="inputWrap"></div>
        </section>
        <section style={{marginTop:'32px'}}>
          <div className="inputWrap" style={{ flexGrow:1 }}>
            <div className='inputRow' style={{ marginBottom:0 }}>
              <label className="label" style={{ flex:'1 1 100%' }}>데이터 볼륨</label>
              <label className="label" style={{ flex:'1 1 100%' }}>볼륨 마운트 경로</label>
            </div>
            { state.dataVolume.map((eachVolume:IVolume, idx:number) => {
              return (<div className='inputRow' key={idx}>
                <InputBox id={'volumes-pvc'+idx} ref={elementsRef[500+idx]} type={TEXT_INPUT_TYPE.TEXT} idx={idx}
                  disabled={resourceGroup.selected === undefined} readonly={true} deleteButtonFlag={true}
                  value={eachVolume.fakePvc ? eachVolume.fakePvc : eachVolume.pvc} 
                  errorText={error.dataVolume[idx] ? error.dataVolume[idx].pvc : ''} 
                  rules={[{
                    basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                    ref: elementsRef[600+idx],
                    invalidateMessage: '마운트 경로 입력 시 데이터 볼륨을 함께 입력해 주세요.',
                    refsInvalidateMessage: '볼륨 선택 시 마운트 경로를 함께 입력해 주세요.'
                  }]}
                  placeholder="사용할 볼륨을 선택해 주세요." button="가져오기"
                  onClickButton={() => { 
                    if (resourceGroup.selected !== undefined) callPopupHandler('volumeSelect', idx)
                  }} 
                  onChange={() => {
                    // DELETE BUTTON
                    let volumes = state.dataVolume
                    volumes[idx].pvc = ''
                    volumes[idx].fakePvc = undefined
                    setState({ ...state, dataVolume: volumes })
                  }}/>
                <div style={{ display:'flex', flex:'1 1 100%', gap:'24px' }}>
                  <InputBox id={'volumes-mountPath'+idx} ref={elementsRef[600+idx]} type={TEXT_INPUT_TYPE.TEXT} idx={idx} 
                    disabled={resourceGroup.selected === undefined}
                    value={eachVolume.mountPath} 
                    errorText={error.dataVolume[idx] ? error.dataVolume[idx].mountPath : ''} 
                    onChange={(id:any, value:any) => {
                      let uv = state.dataVolume
                      uv[idx].mountPath = value
                      setState({ ...state, dataVolume:uv })
                    }}
                    onValidate={checkDuplicatedMountPath}
                    rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                      ref: elementsRef[500+idx],
                      invalidateMessage: '볼륨 선택 시 마운트 경로를 함께 입력해 주세요.',
                      refsInvalidateMessage: '마운트 경로 입력 시 데이터 볼륨을 함께 입력해 주세요.'
                    }]}
                    placeholder="볼륨과 마운트 할 경로를 입력해 주세요." />
                    <div style={{height:'40px'}}>
                      {
                        state.dataVolume.length !== 1 && idx !== state.dataVolume.length - 1 ? <img src='/images-v2/buttonTrash.png' alt='삭제' className="buttonImage" onClick={() => removeVolumeHandler(idx)} /> : false
                      }
                      {
                        idx === state.dataVolume.length - 1 ? <img src='/images-v2/buttonPlus.png' alt='추가' className="buttonImage" onClick={addVolumeHandler} /> : false
                      }
                    </div>
                  </div>
              </div>)
            }) }
          </div>
        </section>
        
        <h3 className="sectionTitle">리소스 설정 <span className="requiredMark">•</span></h3>
        <section>
          <div className="inputWrap">
            <Select ref={resourceTypeSelectRef} option={resourceUnit.typeList} errorText={error.resourceType}
              placeholder="리소스 타입을 선택해 주세요." disabled={resourceGroup.selected === undefined}
              onChange={onSelectResourceType} 
              selected={state.selectedResourceType} />
          </div>
          <div className="inputWrap"></div>
        </section>
        {state.selectedResourceType.value === NODE_TYPE.GPU ?
        <section style={{padding:'8px 0 24px 0'}}>
          <div className="resourceTextBoxGroup">
            {resourceUnit.gpuUnitList.map((eachResourceUnit:IResourceUnit, idx:number) => {
              return (<ResourceUnit data={eachResourceUnit} selected={state.selectedResourceUnitKey === idx} index={idx} key={idx} 
                type={state.selectedResourceType.value === NODE_TYPE.CPU ? ResourceUnitType.UNITTYPE_CPU : ResourceUnitType.UNITTYPE_OPS}
                onSelect={(id:number, checked:boolean)=> selectResourceBlockTypeHandler(idx, id, checked) } />)
            })}
          </div>
        </section> : false }
        <section>
          {state.selectedResourceType.value === NODE_TYPE.NONE ? // 리소스 그룹 미설정 시
            false
            : // 리소스 그룹 설정시
            <>
              {state.selectedResourceType.value === NODE_TYPE.GPU ? <> 
                {/* gpu */}
                <div className="inputWrap mb8">
                  <label className="label">레플리카 당 GPU 블록 개수 <span className="requiredMark">•</span></label>
                  <InputBox id="resourceBlock-gpuNum" ref={elementsRef[700]} type={TEXT_INPUT_TYPE.TEXT} 
                    value={state.resourceBlockQty} errorText={error.resourceBlock} 
                    disabled={state.selectedResourceUnitKey === null}
                    placeholder="0" unit="개" shape={TEXT_INPUT_SHAPE.SMALL}
                    helpText={
                      state.selectedResourceUnitKey !== null && 
                      (state.resourceBlockQty === '') ?
                      `입력 가능한 최대 개수는 ${resourceUnitRef.current.gpuUnitList[stateRef.current.selectedResourceUnitKey || 0].maxNumBlock}개 입니다.` : ''
                    }
                    rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED,
                      invalidateMessage: '필수 입력 항목입니다.'
                    }, {
                      basis: TEXT_INPUT_RULE.REGEXP,
                      rule: RegExp.PVC_SIZE_RULE,
                      invalidateMessage: '양의 정수로 입력해 주세요.'
                    }, {
                      basis: TEXT_INPUT_RULE.GREATER_THEN,
                      rule: 0,
                      invalidateMessage: '양의 정수로 입력해 주세요.'
                    }]}
                    onChange={(id:any, value:any) => { setState({...state, resourceBlockQty: value}) }} />
                </div>
                <div className="inputWrap mb8">
                  <label className="label" style={{marginLeft:'32px'}}>레플리카 개수 <span className="requiredMark">•</span></label>
                  <div>
                    <img src='/images-v2/multiply.svg' alt='곱하기' style={{margin:'4px 8px 4px 0', verticalAlign:'top'}} />
                    <div style={{display:'inline-block', width:'calc(100% - 32px)'}}>
                      <InputBox id="replicas" ref={elementsRef[701]} type={TEXT_INPUT_TYPE.TEXT} 
                        value={state.resourceNodeReplica} errorText={error.resourceNodeReplica} 
                        disabled={state.selectedResourceUnitKey === null || state.resourceBlockQty === '' || isNaN(Number(state.resourceBlockQty)) || Number(state.resourceBlockQty) < 1}
                        placeholder="0" unit="개" shape={TEXT_INPUT_SHAPE.SMALL}
                        helpText={
                          state.selectedResourceUnitKey !== null && state.resourceBlockQty !== '' &&
                          !isNaN(Math.floor((resourceUnit.gpuUnitList[state.selectedResourceUnitKey].maxNumBlock || 0)/Number(stateRef.current.resourceBlockQty))) &&
                          Math.floor((resourceUnit.gpuUnitList[state.selectedResourceUnitKey].maxNumBlock || 0)/Number(stateRef.current.resourceBlockQty)) !== Infinity &&
                          (!state.resourceNodeReplica) ?
                          `입력 가능한 최대 개수는 ${Math.floor((resourceUnit.gpuUnitList[state.selectedResourceUnitKey].maxNumBlock || 0)/Number(stateRef.current.resourceBlockQty))}개 입니다.` : ''
                        }
                        rules={[{
                          basis: TEXT_INPUT_RULE.REQUIRED,
                          invalidateMessage: '필수 입력 항목입니다.'
                        }, {
                          basis: TEXT_INPUT_RULE.REGEXP,
                          rule: RegExp.PVC_SIZE_RULE,
                          invalidateMessage: '양의 정수로 입력해 주세요.'
                        }, {
                          basis: TEXT_INPUT_RULE.GREATER_THEN,
                          rule: 0,
                          invalidateMessage: '양의 정수로 입력해 주세요.'
                        }]}
                        onChange={(id:any, value:any) => { setState({...state, resourceNodeReplica: value}) }} />
                    </div>
                  </div>
                </div>
                <div className="inputWrap mb8">
                  <label className="label">리소스 합계</label>
                  <div className="resourceTextBox" style={{display:'flex', flexDirection:'row-reverse', padding:'8px 8px 8px 12px'}}>
                    <p>
                      { parseNumber(state.resourceBlockQty) * parseNumber(state.resourceNodeReplica) }
                      <strong style={{marginLeft:'8px'}}>개</strong>
                    </p>
                  </div>
                </div>
                <div className="inputWrap mb8"></div>
              </> : <> 
                {/* cpu */}
                <div className="inputWrap mb8">
                  <label className="label">레플리카 당 CPU 코어 수 <span className="requiredMark">•</span></label>
                  <InputBox id="resourceBlock-cpu" ref={elementsRef[702]} type={TEXT_INPUT_TYPE.TEXT} 
                    value={state.resourceNodeCpuQty} errorText={error.resourceNodeCpu}
                    helpText={
                      state.resourceNodeRamSize && (!state.resourceNodeCpuQty) ? 
                      `입력 가능한 최대 CPU는 ${resourceUnit.cpuMaxCore}코어 입니다.` : ''
                    }
                    placeholder="0" unit="코어" shape={TEXT_INPUT_SHAPE.SMALL}
                    rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED,
                      invalidateMessage: '필수 입력 항목입니다.'
                    }, {
                      basis: TEXT_INPUT_RULE.REGEXP,
                      rule: RegExp.CPU_NODE_SCALE_RULE,
                      invalidateMessage: '소수점 두 자리 까지의 양수로 입력해 주세요.'
                    }, {
                      basis: TEXT_INPUT_RULE.GREATER_THEN,
                      rule: 0,
                      invalidateMessage: '소수점 두 자리 까지의 양수로 입력해 주세요.'
                    }]}
                    onChange={(id:any, value:any) => { setState({...state, resourceNodeCpuQty: value}) }} />
                </div>
                <div className="inputWrap mb8">
                  <label className="label">레플리카 당 MEM <span className="requiredMark">•</span></label>
                  <InputBox id="resourceBlock-mem" ref={elementsRef[703]} type={TEXT_INPUT_TYPE.TEXT} 
                    value={state.resourceNodeRamSize} errorText={error.resourceNodeRam} 
                    helpText={
                      state.resourceNodeCpuQty && (!state.resourceNodeRamSize) ? 
                      `입력 가능한 최대 MEM은 ${resourceUnit.memMaxGiB}GiB 입니다.` : ''
                    }
                    placeholder="0" unit="GiB" shape={TEXT_INPUT_SHAPE.SMALL}
                    rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED,
                      invalidateMessage: '필수 입력 항목입니다.'
                    }, {
                      basis: TEXT_INPUT_RULE.REGEXP,
                      rule: RegExp.CPU_NODE_SCALE_RULE,
                      invalidateMessage: '소수점 두 자리 까지의 양수로 입력해 주세요.'
                    }, {
                      basis: TEXT_INPUT_RULE.GREATER_THEN,
                      rule: 0,
                      invalidateMessage: '소수점 두 자리 까지의 양수로 입력해 주세요.'
                    }]}
                    onChange={(id:any, value:any) => { setState({...state, resourceNodeRamSize: value}) }} />
                  <input type="hidden" id="resourceBlock-memUnit" value="GiB" />
                </div>
                <div className="inputWrap mb8">
                  <label className="label" style={{marginLeft:'32px'}}>레플리카 개수 <span className="requiredMark">•</span></label>
                  <div>
                    <img src='/images-v2/multiply.svg' alt='곱하기' style={{margin:'4px 8px 4px 0', verticalAlign:'top'}} />
                    <div style={{display:'inline-block', width:'calc(100% - 32px)'}}>
                      <InputBox id="replicas" ref={elementsRef[704]} type={TEXT_INPUT_TYPE.TEXT}
                        value={state.resourceNodeReplica} errorText={error.resourceNodeReplica} 
                        placeholder="0" unit="개" shape={TEXT_INPUT_SHAPE.SMALL}
                        helpText={
                          state.resourceNodeCpuQty !== '' && state.resourceNodeRamSize !== '' &&
                          !isNaN(Math.min(Math.floor(resourceUnit.cpuMaxCore/Number(state.resourceNodeCpuQty)), Math.floor(resourceUnit.memMaxGiB/Number(state.resourceNodeRamSize)))) && 
                          Math.floor(resourceUnit.memMaxGiB/Number(state.resourceNodeRamSize)) !== Infinity && Math.floor(resourceUnit.cpuMaxCore/Number(state.resourceNodeCpuQty)) !== Infinity &&
                          (!state.resourceNodeReplica) ?
                          `입력 가능한 최대 개수는 ${Math.max(0, Math.min(Math.floor(resourceUnit.cpuMaxCore/Number(state.resourceNodeCpuQty)), Math.floor(resourceUnit.memMaxGiB/Number(state.resourceNodeRamSize))))}개 입니다.` : ''
                        }
                        disabled={
                          state.resourceNodeCpuQty === '' || state.resourceNodeRamSize === '' || 
                          Math.floor(resourceUnit.memMaxGiB/Number(state.resourceNodeRamSize)) === Infinity || Math.floor(resourceUnit.cpuMaxCore/Number(state.resourceNodeCpuQty)) === Infinity ||
                          isNaN(Math.min(Math.floor(resourceUnit.cpuMaxCore/Number(state.resourceNodeCpuQty)), Math.floor(resourceUnit.memMaxGiB/Number(state.resourceNodeRamSize))))
                        }
                        rules={[{
                          basis: TEXT_INPUT_RULE.REQUIRED,
                          invalidateMessage: '필수 입력 항목입니다.'
                        }, {
                          basis: TEXT_INPUT_RULE.REGEXP,
                          rule: RegExp.PVC_SIZE_RULE,
                          invalidateMessage: '양의 정수로 입력해 주세요.'
                        }, {
                          basis: TEXT_INPUT_RULE.GREATER_THEN,
                          rule: 0,
                          invalidateMessage: '양의 정수로 입력해 주세요.'
                        }]}
                        onChange={(id:any, value:any) => { setState({...state, resourceNodeReplica: value}) }} />
                    </div>
                  </div>
                </div>
                <div className="inputWrap mb8">
                  <label className="label">리소스 합계</label>
                  <div className="resourceTextBox" style={{display:'flex', gap:'10px', padding:'8px 8px 8px 12px'}}>
                    <span style={{flex:'1',textAlign:'right'}}>
                      { 
                        parseNumber(state.resourceNodeCpuQty) > 0 && parseNumber(state.resourceNodeRamSize) > 0 && parseNumber(state.resourceNodeReplica) > 0 ? 
                        Math.floor(parseNumber(state.resourceNodeCpuQty)*parseNumber(state.resourceNodeReplica)*100)/100 : 0 
                      }
                    </span>
                    <strong style={{flex:'1'}}>코어</strong>
                    <span style={{flex:'1',textAlign:'right'}}>
                      { 
                        parseNumber(state.resourceNodeCpuQty) > 0 && parseNumber(state.resourceNodeRamSize) > 0 && parseNumber(state.resourceNodeReplica) > 0 ? 
                        Math.floor(parseNumber(state.resourceNodeRamSize)*parseNumber(state.resourceNodeReplica)*100)/100 : 0 
                      }
                    </span>
                    <strong style={{flex:'1'}}>GiB</strong>
                  </div>
                </div>
              </>}
            </>
          }
        </section>
        { state.selectedResourceType.value ? <section>
          <div className="inputWrap" style={{ flexGrow:0.75 }}>
            {state.selectedResourceType.value === NODE_TYPE.GPU ?
              <div className="resourceTextBox">
                { state.selectedResourceUnitKey !== null ?
                  <p>한번에 모든 레플리카를 생성하기에 리소스가 부족할 경우, 가용 리소스가 늘어나면서 레플리카는 추가로 생성됩니다.</p> :
                  <p>GPU 블록 타입을 먼저 선택해 주세요.</p>
                }
              </div> :
              <div className="resourceTextBox">
                <p>CPU와 MEM값은 소수점 2자리 까지 입력 가능합니다. 
                한번에 모든 레플리카를 생성하기에 리소스가 부족할 경우, 가용 리소스가 늘어나면서 레플리카는 추가로 생성됩니다.</p>
              </div> 
            }
          </div>
        </section> : false }
        
        <section style={{marginTop:'56px',flexFlow:'row-reverse',gap:'12px'}}>
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>{ serviceId === -1 ? '생성하기' : '업데이트' }</Button>
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={cancelHandler}>취소</Button>
        </section>
      </Form>
    </FormFragment>
  )
}

const FormFragment = styled.div`
  .pageTitle { font-size:24px; height:32px; line-height:normal; font-weight:700; color:#1A1A1A; margin-bottom:49.5px; }
  .divider { margin-top:48px; margin-bottom:56px !important; }
  .inputWrap { margin-bottom:24px }
  .inputWrap label.label { padding:0 0 8px 0 }
  .sectionTitle { height:24px; padding:auto 0; line-height:24px; margin-bottom:16px }
  .requiredMark { position:relative;top:-4px;color:#F00;font-size:13px;font-weight:700 }
  .buttonImage { cursor:pointer }
  .mb8 { margin-bottom:8px}

  // 리소스 그룹
  .resPreview {border:1px solid #E8E8EE;border-radius:6px;padding:16px;margin-top:0;}
  .resPreview > p {font-size:14px; font-weight:bold; margin: 15px 0 10px 0;}
  .resPreview > p:first-child { margin-top:0; }
  .resPreview .resPreviewWrap {display:flex; gap:10px; flex-wrap:wrap;padding:12px 0;border-bottom:1px dashed #E8E8EE}
  .resPreview .resPreviewWrap:first-child {padding-top:0;padding-top:0}
  .resPreview .resPreviewWrap:last-child {border-bottom:0;padding-bottom:0}
  .resPreview .resPreviewWrap > p {font-size:14px; border:1px solid #DFDFDF; border-radius:2px; background-color:#F5F5F5; padding: 2px 10px;}
  .resPreview .resPreviewWrap > p.resField {width:108px; border:0; border-radius:3px; background-color:#FFF; padding: 3px 0;}

  .formWrap section + .sectionTitle { margin-top:32px; }
  .sectionTitle + .sectionTitle { margin-top:20px }

  .resourceTextBox { height:16px; background:#F7F8FB; border-radius:6px; padding:12px 8px 12px 12px; font-size:14px; color: #646469 }
  .resourceTextBox strong { font-weight:700 }

  form .label { line-height:16px; color:#878791; }
  form .equalIcon { align-self:baseline; padding:8px 0 }
`

export default ServiceForm
