import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import PopupController from "../../../controller/PopupController"
import { DASHBOARD_DATA_TYPE, IServiceItem, Service } from "../../../model/Service"
import Button, { BUTTON_COLOR } from "../uiv2/Button"
import ISelectOption from '../../../interfaces/SelectOption'
import Select, { SELECT_SIZE } from "../uiv2/Select"
import utils from '../../../utils'
import dayjs from 'dayjs'
import EllipsisToolTip from "ellipsis-tooltip-react-chan"

interface ILogPopupProps {
  onClose:Function
  data:IServiceItem
}

interface ILogPopupState {
  recentReplicas:ISelectOption[]
  oldReplicas:ISelectOption[]
}

const LogPopup = (props:ILogPopupProps) => {
  const popupController = PopupController.getInstance()
  const [state, _setState] = useState<ILogPopupState>({
    recentReplicas:[],
    oldReplicas:[]
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ recentreplicas, _recentsetReplicas] = useState<ISelectOption>()
  const recentReplicasRef = useRef(recentreplicas)
  const setRecentReplicas = (data:any) => {
    recentReplicasRef.current = data
    _recentsetReplicas(data)
  }

  const [ oldreplicas, _oldsetReplicas] = useState<ISelectOption>()
  const oldReplicasRef = useRef(oldreplicas)
  const setOldReplicas = (data:any) => {
    oldReplicasRef.current = data
    _oldsetReplicas(data)
  }

  const [logUrl, setLogUrl] = useState<string>('/emptyLog.html')
  useEffect(() => {
    getData()
  }, [])

  const getData = async() => {
    if (!props.data.id) return false
    try {
      const response = await Service.getLogEventData(props.data.id)
        if (response) {
          let recentReplicas:ISelectOption[] = []
          let oldReplicas:ISelectOption[] = []
          for(let key in response.recentReplicas) {
            recentReplicas.push({
              label: response.recentReplicas[key].name,
              value: key,
              fakeLabel: dayjs(response.recentReplicas[key].createAt * 1000).format('YYYY/MM/DD HH:mm:ss ')+response.recentReplicas[key].name,
              error: response.recentReplicas[key].isError === true ? true : false
            })
          }
          for(let key in response.oldReplicas) {
            oldReplicas.push({
              label: response.oldReplicas[key].name,
              value: key,
              fakeLabel: dayjs(response.oldReplicas[key].createAt * 1000).format('YYYY/MM/DD HH:mm:ss ')+response.oldReplicas[key].name
            })
          }
          setState({
            ...state,
            recentReplicas: recentReplicas,
            oldReplicas: oldReplicas
          })
        }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - b6dfa6')
    }
  }

  const getUrlData = async (testtext:any) => {
    try {
      if (!props.data.id) return false
      const data = await Service.getLogEventDashboardData(props.data.id, testtext.label, DASHBOARD_DATA_TYPE.LOG)
      if(data) {
        setLogUrl(data.url)
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - be78cc')
    }
  }

  const selectRecentReplicaHandler = async(select:ISelectOption) => {
    try {
      if (select.value !== '') {
        setRecentReplicas(select)
        getUrlData(select)
        setOldReplicas({label: '이전 레플리카를 선택해 주세요.', value: ''})
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const selectOldReplicaHandler = async(select:ISelectOption) => {
    try {
      if (select.value !== '') {
        setOldReplicas(select)
        getUrlData(select)
        setRecentReplicas({label: '현재 레플리카를 선택해 주세요.', value: ''})
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const closeHandler = () => {
    if (props.onClose) props.onClose()
  }

  return (
    <LogPopupFragment>
      { props.data.project && props.data.project !=='(No label)' ? 
      <p className="label">
        <img src="/images-v2/icon-folder.svg" alt="" />
        {props.data.project}
      </p>
      : false }
      <h2 className="pageTitle"><span style={{maxWidth:'884px'}}><EllipsisToolTip>{props.data?.name}</EllipsisToolTip></span><span style={{width:'42px'}}>로그</span></h2> 
      <div className="topArea">
        <div style={{display:'flex', flex:'1 1 100%', gap:'12px'}}>
          <Select option={state.recentReplicas}
              width="320px"
              size={SELECT_SIZE.SMALL}
              placeholder="현재 레플리카를 선택해 주세요."
              disabled={state.recentReplicas.length === 0? true : false}
              selected={recentreplicas}
              onChange={selectRecentReplicaHandler}/>
          <div className="countBar"></div>

          <Select option={state.oldReplicas}
              width="320px"
              size={SELECT_SIZE.SMALL}
              placeholder="이전 레플리카를 선택해 주세요."
              disabled={state.oldReplicas.length === 0? true : false}
              selected={oldreplicas}
              onChange={selectOldReplicaHandler}/>
        </div>
        <div className="count">{/* 
          <dl className="error">
            <dt>오류발생</dt>
            <dd>{utils.numberMark(0)}개</dd>
          </dl>
          <span className="bar" />
          <dl className="running">
            <dt>구동중</dt>
            <dd>{utils.numberMark(0)}개</dd>
          </dl> */}
        </div>
      </div>
      <div className="scrollWrap">
        {state.recentReplicas.length === 0 && state.oldReplicas.length === 0 ? 
        <div style={{width:"880px", textAlign:"center", padding:"265px 0"}}>데이터가 없습니다.</div>
        : <iframe src={logUrl} style={{width:'880px', height:'610px', border:0}}>
        </iframe>}
      </div>
      <div className="btnWrap">
        <Button color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={closeHandler}>확인</Button>
      </div>
    </LogPopupFragment>
  )
}

const LogPopupFragment = styled.div`
  display:flex; flex-direction:column; width:1000px; height:calc(100vh - 186px); max-height:800px; padding:24px 32px; box-sizing:border-box;
  .label{display:flex;align-items:center;gap:6px;font-size:14px;color:#646469;}
  .label img{width:14px}
  .pageTitle{ margin-top:0; font-size:24px; line-height:normal; font-weight:700; height:32px; gap:6px; }
  .pageTitle span{overflow:hidden;max-width: 680px;white-space:nowrap;text-overflow:ellipsis; height:29px;}
  .scrollWrap{overflow-y:auto; flex:1; margin-top:12px; padding:16px 24px; background:#F7F8FB; font-size:13px;color:#555; font-weight:400; line-height:20px; border-radius:6px; }
	.btnWrap{flex:none;display:flex;justify-content:end;gap:12px;margin-top:24px;}
  .topArea{display:flex;align-items:center;justify-content:space-between;margin-top:24px;}
  .topArea .count{flex:none;display:flex;align-items:center;gap:12px;}
  .topArea .count .bar{width:1px;height:10px;background:#878791;}
  .topArea dl{display:flex;align-items:center;gap:6px;font-size:13px;}
  .topArea dl.error{color:#FF0000;}
  .topArea dl.running dt{color:#646469;}
  .topArea dl.running dd{color:#333;}
  .topArea dl dt{font-weight: 400;}
  .topArea dl dd{font-weight: 600;}
  .countBar{width:1px;height:24px;background:#D5D5DA;margin-top:7px}
  .dropWrap { min-width:320px; width:max-content; }
  .dropWrap button.selected {font-size:13px!important;}
  .selectBox.small span{height:15px;}
`

export default LogPopup