import React, { Component, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export enum SUBPOPUP_TYPE {
  NORMAL = 0,
  ENVIRONMENT,
  EMAIL,
  GPUSPEC,
  MODEL,
  MODELSIZE,
  GROUPUSER,
  NODE,
  VOLUME,
  BLOCKTYPEARRAY,
  BLOCKTYPE,
  VOLUME_OPS
}

interface ISubPopupProps {
  titles: string[]
  datas: any[]
  isTableBtn?:boolean
  onLeft?:boolean
  onTop?:boolean
  type?:SUBPOPUP_TYPE
  numbering?:boolean
  width?:number
  padding?:string
  colWidth?:number[]
  colAlignCenter?: boolean
}
interface ISubPopupState {
  keys:string[],
  isOpen:boolean
}

const SubPopup = (props: ISubPopupProps) => {
  const [ state, setState ] = useState<ISubPopupState>({
    keys: [],
    isOpen: false
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    if (props.titles.length > 1 && props.datas.length > 0) {
      let arr = Object.keys(props.datas[0])
      setState({
        ...state,
        keys: arr
      })
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen:!state.isOpen })
  }

  return (
    <SubPopupFragment>
      <div className={props.isTableBtn ? "tableWrap" : "normalWrap"}>
        <button onClick={onBtnClick} ref={modalBtnRef}>
          {state.isOpen && state.isOpen === true ?
            <img src='/images/buttonMoreRounded.svg' alt='more' className='moreIconButton' />
            : <img src='/images/buttonMoreRounded_1.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      <>
        {state.isOpen && state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className={"commonModal" + (props.onLeft ? " onLeft" : " onRight") + (props.onTop ? " onTop" : " onBottom")} style={{ 'width': props.width ? props.width : '300px', 'padding': props.padding ? props.padding : '15px 30px' }}>
              <table>
                {props.colWidth && props.colWidth.length > 0 ?
                  <colgroup>
                    {props.colWidth.map((col, index) =>
                      <col key={index} width={`${col}%`}></col>
                    )}
                  </colgroup>
                  :
                  <>
                    {props.titles.length > 1 ?
                      <colgroup>
                        <col width="15%"></col>
                        <col width="46%"></col>
                        <col width="46%"></col>
                      </colgroup>
                      : <colgroup>
                        <col width="15%"></col>
                      </colgroup>
                    }
                  </>
                }
                <thead><tr>
                  {
                    props.titles.length > 1 ?
                      <>
                        <th></th>
                        {props.titles.map((title: string, idx: number) => (
                          <th key={idx}>{title}</th>
                        ))}
                      </>
                      : <th className="textAlignStart" colSpan={2}>{props.titles[0]}</th>
                  }
                </tr></thead>
                <tbody>
                  {
                    props.datas && props.datas.map((data: any, idx: number) => (
                      <tr key={idx}>
                        {props.numbering === false ? false : <td className="textAlignStart">{idx + 1}.</td>}
                        {(() => {
                          switch (props.type) {
                            case SUBPOPUP_TYPE.ENVIRONMENT:
                              return (<td className="textAlignStart">{data.key}={data.value}</td>)
                            case SUBPOPUP_TYPE.EMAIL:
                              return (<td className="textAlignStart">{data.name ? <>{data.name} | </> : false}{data.email}</td>)
                            case SUBPOPUP_TYPE.NODE:
                              return (<td className="textAlignStart">{data.ip} / {data.name}</td>)
                            case SUBPOPUP_TYPE.VOLUME:
                              return (<td className="textAlignStart">{data.name}</td>)
                            case SUBPOPUP_TYPE.MODEL:
                              return (<td className="textAlignStart">{data.model}</td>)
                            case SUBPOPUP_TYPE.MODELSIZE:
                              return (<td className="textAlignStart">{data.model} {data.size}</td>)
                            case SUBPOPUP_TYPE.BLOCKTYPE:
                              return (<td className="textAlignStart">
                                <h3 style={{ marginBottom: '10px' }}>블록타입 {idx + 1}:{data.gpuType}</h3>
                                <div className="bothEnd">
                                  <div className="half">
                                    <div>CPU: {data.cpu} 개</div>
                                    <div>RAM: {data.mem} {data.memUnit}</div>
                                  </div>
                                  <div className="half">
                                    <div>GPU: {data.gpuPer} %</div>
                                    <div>GPUmem: {data.gpuMem} {data.gpuMemUnit}</div>
                                  </div>
                                </div>
                              </td>)
                            case SUBPOPUP_TYPE.GPUSPEC:
                              return (<><td>{String(data.key).replace('GPU', 'GPU ')}:</td><td>{data.value}</td></>)
                            case SUBPOPUP_TYPE.VOLUME_OPS:
                              return (<td className="textAlignStart">{data.name} / {data.size}</td>)
                            case SUBPOPUP_TYPE.NORMAL:
                            default:
                              return (<>
                                {
                                  props.titles.length > 1 ?
                                    state.keys?.map((key: string, idx2: number) => (
                                      <td key={idx2} className={props.colAlignCenter === true ? 'textAlignCenter' : 'textAlignStart'}>{data[key]}</td>
                                    ))
                                    :
                                    <td className={props.colAlignCenter === true ? 'textAlignCenter' : 'textAlignStart'}>{data}</td>
                                }
                              </>)
                          }
                        })()}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          : false}
      </>
    </SubPopupFragment>
  )
}

const SubPopupFragment = styled.div`
.tableWrap {height:100%; display:flex; flex-direction:row; justify-content:center; align-items:center;}
  .normalWrap {height:18px;}

  .subModalPopupWrap { position:relative; align-items:center; z-index:201;}
  .subModalPopupWrap img.moreIconButton { width:17px !important;height:18px;vertical-align:bottom; padding-top:0px; padding-left:4px;}
  .subModalPopupWrap .commonModal{position:absolute;width:400px;max-height:250px;overflow-y:auto;flex-direction:column;box-sizing:border-box;padding:15px 30px;border-radius: 2px;box-shadow: 3px 3px 3px 0px #DFDFDF;border: solid 1px #DFDFDF;background:#fff;}
  .subModalPopupWrap .onRight{left:-20px;}
  .subModalPopupWrap .onLeft{left:-300px;}
  .subModalPopupWrap .onTop{bottom:30px;}
  .subModalPopupWrap .onBottom{top:5px;}

  .subModalPopupWrap .commonModal p{font-size:14px;line-height:24px; vertical-align:middle;}
  .subModalPopupWrap .commonModal .modalTitle + modalTitle{margin-top:10px;}
  .subModalPopupWrap .commonModal .modalTitle{font-weight:bold;}
  .subModalPopupWrap .commonModal .modalTitle .content{font-weight:normal;}

  .subModalPopupWrap .commonModal table {width:100%; border-collapse:collapse; font-size:14px; line-height:38px;}
  .subModalPopupWrap .commonModal table thead tr {border-bottom: 1px solid #DFDFDF;}
  .subModalPopupWrap .commonModal table tbody tr:last-child td{border-bottom: 0;}
  .subModalPopupWrap .commonModal table tbody td{border-bottom: 1px solid #DFDFDF; line-height:19px; padding-top:10px; padding-bottom:10px; word-break:break-all; }
  .subModalPopupWrap .commonModal table td{text-align:left;}
  //.subModalPopupWrap .commonModal table tbody {max-height:195px; overflow:auto;}

  /* 상위 테이블 css에 영향받는 요소들 재설정 */
  .subModalPopupWrap .commonModal table thead th{background-color:white; font-weight:bold;}
  .subModalPopupWrap .commonModal table td{padding: 0; border-right:0;}
  //.subModalPopupWrap .commonModal table tbody tr:hover td{background-color:#fafafa;}

  .textAlignStart {text-align:start !important;}
  .textAlignCenter {text-align:center !important;}

  .half {width:50%;}
`

export default SubPopup