import camelcaseKeys from "camelcase-keys"
import ApiController from "../controller/ApiController"
import { ERROR_TYPE } from "../interfaces/Error"
import { IBaseDataType, SORT_ORDER } from "./BaseDataType"
import snakecaseKeys from "snakecase-keys"
import { IWorkspaceDetail } from "./Workspace"

export interface IResourceGroupListResponse {
  total:number
  list:IResourceGroupListItem[]
}

export interface IGroupPropertyDeleteProps {
  label:string
  type:string
  key:string
}
export interface IResourceNode {
  //해당인터페이스는 리소스그룹에 할당된 노드를 불러오는것임
  name:string
  ip:string
  serviceCount:number|string
  serviceList:string[]
  status:boolean
  deleteInfo?:IGroupPropertyDeleteProps
  isRegMount:boolean
}
export interface IGroupVolumePv {
  name?:string
  size?:string
  server?:string
  path?:string
  type:string
}
export interface IResourceVolume {
  name:string
  size:string
  usage:string
  isReg:boolean
  pvInfo:IGroupVolumePv
  pvName?:string
  pvSize?:string
  pvServer?:string
  pvPath?:string
  pvType?:string
  typeDetail?:string
  regInfo:string
  serviceCount:string|number
  serviceList:string[]
  status:boolean
  deleteInfo?:IGroupPropertyDeleteProps
}
export interface IResourceUser {
  userNo:number
  userId:string
  name:string
  email:string
  phoneNumber:string
  department:string|null
}
export interface IGroupNodeAdd {
  nodeList:string[]
}
export interface IResourceGroup {
  isCommon:boolean
  name:string
  nodeList:IResourceNode[]
  volumeList:IResourceVolume[]
  userList:IResourceUser[]
  reg:string
  userListData:IGroupNodeAdd[]
}

export interface IResourceGroupListItem extends IBaseDataType {
  name:string
  resourceGroup: {
    groupName?:string
    nodeList: {
      name:string
      ip:string
    }
    volumeList: {
      name:string
      size:string
      type:string
    }
    reg:string
  }
  userList:[]
  serviceCount:number
  userCount:number
}

export enum GROUP_LIST_ORDER_BY {//혹시라도 생길수있으니 두는것으로
  NAME = 0
}

export interface IResourceUseradd {
  userList:number[]
  userNameList:string[]
  userIdList:string[]
  isDelete:boolean
  newUser?:any
}

export interface IGroupNodeadd {
  nodeList:string[]
}

export interface IGroupNode {
  name:string,
  ip:string,
  status?:boolean
}

export interface IGroupNodeList {
  //리소스그룹에 할당되지않은 노드 리스트를 불러오는것임
  nodeList:IGroupNode[]
  nodeCount:number
}

export interface IPvcCreationInfo {
  nodeList:IGroupNode[]
  creationType:IPvcCreationType[]
  sizeUnits:string[]
}

export interface IPvcCreationType {
  volType:string
  resType:string
}

export interface IPvcCreation {
  pvcName:string
  size:string
  pvInfo:IGroupVolumePv
  sizeEnd?:string
}

export class ResourceGroup {
  public static async getRgroupList(userNo:number):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups', {
        user_no: userNo
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep:true})
        default:
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getResourceBlockInfo(rgroupName:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/resource-block', {
        name: rgroupName
      })
      switch(response.status) {
        case 200:
          // response.data.gpu_resource_block_list = []
          // response.data.cpu_resource_block_list = []
          return camelcaseKeys(response.data, {deep:true})
        default:
          return {
            error: response
          }
      }
    } catch (error: any) {
      switch(error.response.status) {
        case 400:
          throw new Error(ERROR_TYPE.IS_INIT_MIG)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async getRgroupResourceWithName(name:string){ //이거 바껴야할듯 - 리소스 블록 정보 가져오기?
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroup/resource/'+name, {}) 
      switch(response.status) {
        case 200:
          return {
            result: camelcaseKeys(response.data, {deep:true}) 
          }
        default:
          //console.log('ResourceGroup.getRgroupResourceWithName error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getRgroupVolume(name:string){
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/volumes', {
        name: name
      })
      switch(response.status) {
        case 200:
          return {
            result: camelcaseKeys(response.data, {deep:true}) 
          }
        default:
          //console.log('ResourceGroup.getRgroupVolume error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getGroupList(sortOrder:SORT_ORDER, page?:number, size?:number):Promise<IResourceGroupListResponse|any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/admin', 
      page && size ?
      {
        page:page,
        size:size,
        sort_order: sortOrder
      } :
      {
        sort_order: sortOrder
      }
      )
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('ResourceGroup.getGroupList error>', response)
          return {
            rgroupCount: 0,
            rgroup: [],
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async updateGroupUser(groupName:string, payload:IResourceUseradd) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.patch('/rgroups/users?name=' + groupName, snakecaseKeys(payload, { deep: true }))

      switch (response.status) {
        case 200:
          return response.status
        default:
          console.log('ResourceGroup.updateGroupUser error', response)
          return {
            error: response
          }
      }

    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async updateGroupNode(name:string, payload:IGroupNodeadd) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.patch('/rgroups/nodes?name=' + name, snakecaseKeys(payload, { deep: true }))
      
      switch (response.status) {
        case 200:
          return response.data
        default:
          console.log('ResourceGroup.updateGroupNode error', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getDetail(name:string):Promise<IResourceGroup|any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/admin/'+name+'/detail', {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('ResourceGroup.getDetail error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async checkExists(name:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/exists', {
        name: name
      })
      switch (response.status) {
        case 200:
          return {
            isExist: response.data.is_exist
          }
        default:
          console.log('ResourceGroup.checkExists error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async create(name:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/rgroups?name='+name,{})

      switch (response.status) {
        case 201:
          return {
            result: true
          }
        default:
          console.log('ResourceGroup.create error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getAllNodes():Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/all-nodes',{})

      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true}) 
        default:
          console.log('ResourceGroup.getAllNodes error>', response)
          break
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getGroupNode():Promise<IGroupNodeList|any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/free-nodes', {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getPvList():Promise<IGroupVolumePv[]|any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/pv', {})
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('ResourceGroup.getPvList error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async setPvcWithPv(name: string, payload: IGroupVolumePv[]) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/rgroups/pvc_with_pv?name='+name, {
        pv_list: snakecaseKeys(payload, { deep: true })
      })

      switch (response.status) {
        case 200:
          return response.status
        default:
          console.log('ResourceGroup.setPvcWithPv error', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getPvcCreationInfo(name: string):Promise<IPvcCreationInfo | any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/pvc/creation-info?name='+name, {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('ResourceGroup.getPvcCreationInfo error>', response)
          return {//이작업 전체에 해주어야함... 
            nodeList: [],
            creationType: [],
            sizeUnits: [],
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async createPvc(name: string, payload: IPvcCreation): Promise<any> {
    try {
      let payload2 = {
        pvcName: '',
        size: '',
        pvInfo: {
          type: ''
        }
      }
      if (payload.pvInfo.type === 'nfs') {
        payload2 = {
          pvInfo: payload.pvInfo,
          pvcName: payload.pvcName,
          size: payload.sizeEnd? payload.sizeEnd : ''
        }
      } else {
        payload2 = {
          pvcName: payload.pvcName,
          size: payload.size,
          pvInfo: {
            type: payload.pvInfo.type
          }
        }
      }
      let reqType = payload.pvInfo.type === 'ontap-nas' ? 2 : 1
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/rgroups/pvc?name=' + name + '&volume_req_type=' + reqType, snakecaseKeys(payload2, { deep: true }))

      switch (response.status) {
        case 200:
          return response.status
        case 500: // msg : 생성 실패
        default:
          console.log('ResourceGroup.createPvc error>', response)
          return {
            error: response
          }
      }
    } catch (error: any) {
      switch (error.response.status) {
        case 409:
          throw new Error(ERROR_TYPE.DUPLICATED)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async mountVolume(groupName:string, pvcName:string, isDelete:boolean, isCommon:boolean):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.patch('/rgroups/reg?name='+groupName, {
        pvc_name: pvcName,
        is_delete: isDelete,
        is_common: isCommon
      })
      switch (response.status) {
        case 200:
          return {
            result: true
          }
        default:
          console.log('ResourceGroup.mountVolume error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async delete(name:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.del('/rgroups?name='+name, {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response, {deep: true})
        default:
          console.log('ResourceGroup.delete error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async deleteNode(groupName:string, nodeName:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.del('/rgroups/nodes?name='+groupName+'&node_name='+nodeName, {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response, {deep: true})
        default:
          console.log('ResourceGroup.deleteNode error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async deleteVolume(groupName:string, pvcName:string):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/rgroups/pvc?name='+groupName+'&volume_req_type='+0, {
        pvc_name: pvcName
      })

      switch (response.status) {
        case 200:
          return camelcaseKeys(response, {deep: true})
        default:
          console.log('ResourceGroup.deleteVolume error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getConnectionInfo(userNo:number):Promise<any> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/rgroups/connection-info', {
        user_no: userNo
      })

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('ResourceGroup.deleteVolume error>', response)
          return {
            error: response
          }
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

}