import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { IChartSeries } from '../../interfaces/Chart'
import DateRangePicker from '../components/ui/DateRangePicker'
import PageEvent from '../../events/PageEvent'
import MonitoringAPI, { IGpu, IMigData } from '../../model/Monitoring'
import BarChart from '../components/chart/BarChart'
import NoData from '../components/NoData'
import Tooltip from '../components/ui/Tooltip'
import { Link } from 'react-router-dom'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import LineChart from '../components/chart/LineChart'
import { useRecoilState } from 'recoil'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import PopupController from '../../controller/PopupController'
import ModalEvent from '../../events/ModalEvent'
import Select from '../components/ui/Select'
import ISelectOption from '../../interfaces/SelectOption'
import { ResourceGroup } from '../../model/ResourceGroup'
import { SORT_ORDER } from '../../model/BaseDataType'
import { TIME_PICKER_TYPE } from '../components/ui/TimePicker'
import GPUInfoPopup from '../components/modal/sub/GPUInfoPopup'
import LoadingAnimation from '../components/LoadingAnimation'

export enum SERVER_STATUS {
  NORMAL = 0,
  CONCERN = 1,
  CRITICAL = 2
}

export enum CLASSTYPE {
  CPU = 0,
  STORAGE = 1,
  MEMORY = 2,
  STORAGE_USAGE = 3,
}

export enum MONITORING_TYPE {
  MASTER = 0,
  STORAGE = 1,
  WORKER = 2
}

interface IGPUType {
  isParted:boolean
  gpuList:IGpu[]
  total:number
}

interface IStorage {
  name:string
  status:SERVER_STATUS
  storageRate:number
  url?:string
}

interface INode {
  name:string
  nodeResource: {
    isRunning:boolean
    cpu:number
    gpu: {
      allocatedGpu:number[]
      totalGpu:number[]
      usedGpu:number[]
    }
    canMig:boolean
    isInitMig:boolean
    memory:number
    storage:number
  },
  gpuType:IGPUType
  statusTotal:SERVER_STATUS
  statusCpu:SERVER_STATUS
  statusStorage:SERVER_STATUS
  statusMemory:SERVER_STATUS
  popupOpenFlag:boolean
}

interface IMonitoringServerData {
  masterList: INode[]
  workerList: INode[]
  storageList: IStorage[][]
  categories: string[]
}

interface IMonitoringProps {
  mode:string
}

interface IMonitoringState {
  range: {
    from:string
    to:string
  }
  limit: {
    from:string
    to:string
  }
  serverTime:string
  nodeList:INode[]
  refreshFlag:boolean
  settingModeFlag:boolean
  nodeNameFilter:string
}

const Monitoring = (props:IMonitoringProps) => {
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  let startTimestamp:number = userInfo?.startTimestamp || 0
  let startFrom:string = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom :dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  const initialFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom :dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  const initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  const initialServerTime:string = dayjs().format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let defaultServerTime:string = dayjs().format('YYYY-MM-DD HH:mm')

  const pickerRef1:any = useRef()
  const pickerRef2:any = useRef()

  const [ rgroupList, setRgroupList ] = useState<any[]>([])

  const [ filterListChart, setFilterListChart ] = useState<ISelectOption[]>([])
  const [ filterChart, _setFilterChart ] = useState<ISelectOption>()
  const filterChartRef = useRef(filterChart)
  const setFilterChart = (data:any) => {
    filterChartRef.current = data
    _setFilterChart(data)
  }
  const [ chartTitle, setChartTitle ] = useState<string>('GPU 가동률')
  const [ filterListNode, setFilterListNode ] = useState<ISelectOption[]>([])
  const [ filterNode, _setFilterNode ] = useState<ISelectOption>()
  const filterNodeRef = useRef(filterNode)
  const setFilterNode = (data:any) => {
    filterNodeRef.current = data
    _setFilterNode(data)
  }

  const [ gpuData, setGpuData ] = useState<IChartSeries[]>([])
  const [ serverData, setServerData ] = useState<IMonitoringServerData>({
    categories: [],
    masterList: [],
    storageList: [],
    workerList: []
  })
  const [ state, _setState ] = useState<IMonitoringState>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: startFrom,
      to: defaultTo
    },
    serverTime: defaultServerTime,
    nodeList: [],
    refreshFlag: false,
    settingModeFlag: (props.mode === 'setting'),
    nodeNameFilter:''
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const popupController = PopupController.getInstance()

  useEffect(() => {
    getRGroupList()
    // getGPUData(state.range)
    // getServerData(state.serverTime)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
    }
  }, [])

  useEffect(() => { // setting FilterList
    if (rgroupList.length === 0) return

    // 차트 필터 리스트
    let filterListChart:ISelectOption[] = [{ label: '클러스터 전체 GPU 가동률', value: '0' }]
    for (let idx in rgroupList) {
      if(rgroupList[idx].isCommon) {
        filterListChart.push({ fakeLabel: '기본 리소스 그룹', label: rgroupList[idx].name, value: String(Number(idx)+1) })
      } else {
        filterListChart.push({ label: rgroupList[idx].name, value: String(Number(idx)+1) })
      }
    }
    setFilterListChart(filterListChart)

    // 노드 필터 리스트
    let filterListNode:ISelectOption[] = [{ label: '전체 서버 모니터링', value: '0' }]
    for (let idx in rgroupList) {
      if(rgroupList[idx].isCommon) {
        filterListNode.push({ fakeLabel: '기본 리소스 그룹', label: rgroupList[idx].name, value: String(Number(idx)+1) })
      } else {
        filterListNode.push({ label: rgroupList[idx].name, value: String(Number(idx)+1) })
      }
    }
    setFilterListNode(filterListNode)
  }, [rgroupList])

  useEffect(() => {
    if (filterChart !== undefined && filterChart.value !== '') {
      if(filterChart.value === '0') {
        setChartTitle('클러스터 전체 GPU 가동률')
      } else {
        setChartTitle((filterChart.fakeLabel ? filterChart.fakeLabel : filterChart.label) + ' GPU 가동률')
      }
      getGPUData(state.range)
    }
  }, [filterChart])

  useEffect(() => {
    if (filterNode !== undefined && filterNode.value !== '') {
      getServerData(state.serverTime)
    }
  }, [filterNode])

  useEffect(() => {
    document.addEventListener('mousedown', closeNodePopupHandler) //mousedown 대신 타입스크립트에서 제공하는 기본 마우스 이벤트 사용 - MouseEvent.이벤트명
    // window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    return() => {
      document.removeEventListener('mousedown', closeNodePopupHandler)
      // window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
    }
  }, [serverData.workerList])

  useEffect(() => {
    if(state.settingModeFlag === false) {
      defaultFrom = initialFrom
      defaultTo = initialTo
      let range = {
        from: defaultFrom,
        to: defaultTo
      }
      setState({
        ...state,
        range: range
      })
    }
  }, [state.settingModeFlag])

  useEffect(() => {
    startTimestamp = userInfo?.startTimestamp || 0
    startFrom = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
    setState({
      ...state,
      limit: {
        from: startFrom,
        to: defaultTo
      },
    })
  }, [userInfo?.startTimestamp])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const autoRefreshHandler = async (e:PageEvent) => {
    // await utils.setLoadingMask(false)
    if (stateRef.current.settingModeFlag === true) { // MIG 설정 시(e.payload.nodeName) => 서버 설정 모드 시(settingModeFlag)로 변경
      getServerData(stateRef.current.serverTime)
    } else { // 일반 자동 새로고침
      let range = {
        from: defaultFrom,
        to: defaultTo
      }
      // picker의 modifiedFlag가 true가 되는 순간 5초 새로고침이 STOP 되는데.... 해당 if문 조건이 필요한지?
      if (pickerRef1.current?.modifiedFlag === false) {
        defaultFrom = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
        defaultTo = dayjs().format('YYYY-MM-DD HH:mm')
        range = {
          from: defaultFrom,
          to: defaultTo
        }
        getGPUData(range)
      }
      if (pickerRef2.current?.modifiedFlag === false) {
        defaultServerTime = dayjs().format('YYYY-MM-DD HH:mm')
        getServerData(defaultServerTime)
      }
      setState({
        ...state,
        range: range,
        serverTime: defaultServerTime
      })
    }
    // await utils.setLoadingMask(true)
  }

  const getRGroupList = async () => {
    try {
      let result = await ResourceGroup.getGroupList(SORT_ORDER.ASC)
      if(result) {
        setRgroupList(result.rgroup)
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c62f98')
    }
  }
  const getGPUData = async (newRange:any): Promise<void> => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()
      let data = await MonitoringAPI.getGPUData(FROM, TO, filterChartRef.current)
      if (data) {
        setGpuData(parseChartData(data.gpuUsageList))
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6ad9f0')
    }
  }

  const parseChartData = (data:any) => {
    let chartData:any = []

    chartData = [
      { name: '가동률', data: [], color: '#BECC23' }
    ]

    for (let i:number = 0; i < data.length; i++) {
      let item:any = data[i]
      let assigned:number = item.allocationGpu / item.totalGpu
      // let used:number = item.useGpu / item.totalGpu
      chartData[0].data.push([item.timestamp * 1000, Math.round(assigned * 10000) / 100])
    }
    return chartData
  }

  const getServerData = async (newServerTime:any): Promise<void> => {
    try {
      const TIME = dayjs(newServerTime).unix()
      let data = await MonitoringAPI.getServerData(TIME, filterNodeRef.current),
        storage:any = []
  
      // set chart categories
      let categories:string[] = [],
          date:string = ''
      const today = dayjs().format('YYYY-MM-DD')
       for (let i = 0; i < 7; i++) {
        date = dayjs.unix(TIME).subtract(i, 'day').format('YYYY-MM-DD')
        categories.push(today === date ? '오늘' : dayjs(date).format('DD')+'일')
      }
      // setServerData({
      //   ...serverData,
      //   categories: categories.reverse() //setState 두 번해서 초기화되는 현상 있어서 이 부분 함침 (뒤로 옮김)
      // })
  
      // set data
      if (data) {
        // Server Status 검사
        for(let idx in data){
          for(let i=0; i<data[idx].length; ++i){
            let node = data[idx][i]
            if(node.nodeResource){ // 마스터노드, 워커 노드
              node.statusCpu = getStatus(node.nodeResource.cpu, CLASSTYPE.CPU)
              node.statusStorage = getStatus(node.nodeResource.storage, CLASSTYPE.STORAGE) 
              node.statusMemory = getStatus(node.nodeResource.memory, CLASSTYPE.MEMORY)
  
              if (!node.nodeResource.isRunning) {
                node.statusTotal = SERVER_STATUS.CRITICAL
              } else {
                node.statusTotal = node.statusCpu
                if(node.statusTotal <= node.statusStorage) {node.statusTotal = node.statusStorage}
                if(node.statusTotal <= node.statusMemory) {node.statusTotal = node.statusMemory}
              }
            } else { // 스토리지
              node.status = getStatus(node.storageRate, CLASSTYPE.STORAGE_USAGE)
            }
          }
        }
  
        // storage list 2개씩 묶기 (레이아웃)
        for(let i=0; i<data.storageList.length/2; i++){
          let obj:any[] = []
          if(data.storageList[i*2]){
            obj.push(data.storageList[i*2])
          }
          if(data.storageList[i*2+1]){
            obj.push(data.storageList[i*2+1])
          }
          storage.push(obj)
        }
        setServerData({
          ...serverData,
          masterList: data.masterList,
          storageList: storage,
          workerList: data.workerList,
          categories: categories.reverse() //앞에 있던 부분 여기로 옮김
        })

      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 9cedc0')
    }
  }

  const onChangeDatePicker = (range:any) => {
    setGpuData([])
    setState({
      ...state,
      range: {
        from: range.from,
        to: range.to
      }
    })
    getGPUData(range)
  }

  const onChangeServerDatePicker = (range:any) => {
    setState({
      ...state,
      serverTime: range.to,
      nodeList: []
    })
    setServerData({
      ...serverData,
      storageList: [],
    })
    getServerData(range.to)
  }

  const getStatus = (value:number, type:CLASSTYPE): SERVER_STATUS => {
    let result:SERVER_STATUS = SERVER_STATUS.NORMAL
    let warn:number = 90
    let noti:number = 80

    switch(type){
      case CLASSTYPE.CPU:
        warn = 90
        noti = 80
        break
      case CLASSTYPE.STORAGE:
        warn = 70
        noti = 60
        break
      case CLASSTYPE.MEMORY:
        warn = 90
        noti = 80
        break
      case CLASSTYPE.STORAGE_USAGE:
        warn = 70
        noti = 60
        break
      default:
        warn = 70
        noti = 60
        break
    }

    if (value >= warn) {
      result = SERVER_STATUS.CRITICAL
    } else if (value >= noti) {
      result = SERVER_STATUS.CONCERN
    } else {
      result = SERVER_STATUS.NORMAL
    }
    return result
  }

  const getColorClass = (status:SERVER_STATUS):string => {
    let result:string = ''

    if (status === SERVER_STATUS.CRITICAL) {
      result = 'warn'
    } else if (status === SERVER_STATUS.CONCERN) {
      result = 'noti'
    } else {
      result = 'okay'
    }
    return result
  }

  const openMigSetting = async (node:INode) => {
    popupController.mig(
      async (nodeName:string, data:IMigData[], objCount:number) => {
        if(objCount > 0) {
          popupController.dialouge(`MIG 설정을 적용하기 위해 노드에 생성된 ${objCount}개의 워크스페이스가 모두 회수됩니다. 진행하시겠습니까?`, 'setMigData', JSON.stringify({
            nodeName: nodeName,
            data: data
          }), '확인', '취소')  
        } else {
          popupController.dialouge(`MIG 설정을 적용합니다. 진행하시겠습니까?`, 'setMigData', JSON.stringify({
            nodeName: nodeName,
            data: data
          }), '확인', '취소')  
        }
      }, node.name
    )
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'setMigData':
        try {
          let closeEvent:ModalEvent = new ModalEvent(ModalEvent.CLOSE_MODAL) //이거 필요 없을 듯 - 왜냐하면 팝업 이벤트리스너에서 따로 closeHandler를 호출시켜주기 때문
          window.dispatchEvent(closeEvent) //이거 필요 없을 듯 - 왜냐하면 팝업 이벤트리스너에서 따로 closeHandler를 호출시켜주기 때문
  
          const payload = JSON.parse(e.payload.key)
          const nodeName = payload.nodeName
          const data = payload.data
          const workerList = serverData.workerList
          for (let eachWorker of workerList) {
            if (eachWorker.name === nodeName) {
              eachWorker.nodeResource.isInitMig = true
            }
          }
          await MonitoringAPI.setMigData(nodeName, data)
          setServerData({
            ...serverData,
            workerList: workerList
          })
          getServerData(state.serverTime)
        } catch(error:any) {
          console.log('Monitoring.setMigData error')
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 238422')
        }
        break
    }
  }

  const renderTooltipImg = (status:SERVER_STATUS) => {
    let img:string = 'status.png'
    switch(status){
      case SERVER_STATUS.CRITICAL:
        img = 'alert-error.png'
        break;
      case SERVER_STATUS.CONCERN:
        img = 'alert-warn.png'
        break;
      case SERVER_STATUS.NORMAL:
      default:
        img = 'status.png'
    }
    return img
  }

  const renderTooltipDes = (data:any, type:MONITORING_TYPE) => {
    let des:string = ''
    let node:INode, storage:IStorage
    //console.log(data)
    switch(type){
      case MONITORING_TYPE.MASTER:
        node = data
        // 경고 critical (빨강)
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고인 애들 표기
          if(node.nodeResource.isRunning === false){
            des += '시스템 장애가 발생했습니다.\n'
          }
          else {
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des += `[경고] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n` }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des += `[경고] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 신속하게 확보해 주세요.\n` }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des += `[경고] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n` }
            
            //주의인 애들 표기
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des += `[주의] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n` }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des += `[주의] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 신속하게 확보해 주세요.\n` }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des += `[주의] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n` }
          }
        }
        break;
      case MONITORING_TYPE.WORKER:
        node = data
        // 경고 critical (빨강)
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고인 애들 표기
          if(node.nodeResource.isRunning === false){
            des += '시스템 장애가 발생했습니다.\n'
          }
          else {
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des += `[경고] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des += `[경고] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des += `[경고] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            
            //주의인 애들 표기
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des += `[주의] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des += `[주의] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des += `[주의] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }  
          }
        }
        break;
      case MONITORING_TYPE.STORAGE:
        storage = data
        if(storage.status === SERVER_STATUS.CRITICAL){
          des = `[경고] Storage 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 신속하게 확보해 주세요.`
        }
        else if(storage.status === SERVER_STATUS.CONCERN){
          des = `[주의] Storage 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 확보해 주세요`
        }
        break;
      default:
        des = 'alert.png'
    }
    return des
  }

  const closeNodePopupHandler = () => {
    let workerList = serverData.workerList
    for (let idx in workerList) {
      let node:INode = workerList[idx]
      let nodeData:INode = {
        ...node,
        popupOpenFlag: false
      }
      workerList[idx] = nodeData
    }

    setServerData({
      ...serverData,
      workerList: workerList
    })
  }

  const masterLen = serverData.masterList.length
  return (
    <MonitoringDetailPageFragment>
      { state.settingModeFlag === false ?
        <>
        <div className="titleArea bothEnd" style={{marginBottom:'30px'}}>
          <h2 className="pageTitle">전체 GPU 활용 추이 {/* <Tooltip des="최초 진입시에는 현재 시각으로부터 24시간 이전까지의 데이터가 보여집니다.<br>전체 노드에 탑재된 GPU의 가동률을 확인 할 수 있으며,<br>기간 설정 시 설정한 기간 동안의 GPU 가동률 확인이 가능합니다." /> */}</h2>
        </div>
        <div style={{width:'499px', marginBottom:'17px'}}>
          <Select option={filterListChart} selected={filterChart}
          onChange={(e:any) => {setFilterChart(e)}} />
        </div>
        <div className="bothEnd">
          <div>
            <DateRangePicker ref={pickerRef1} initial={{ to: initialTo, from: initialFrom }} default={{ to: state.range.to, from: state.range.from }} limit={state.limit} data={state.range} onChange={onChangeDatePicker} type={TIME_PICKER_TYPE.HH} />
          </div>
          {/* <Link to={'/gpumonitoring'} className="btn outline">서버 별 GPU 가동률 보기</Link> */}
        </div>
        <h3 className="chartTitle">{chartTitle}</h3>
        {
          gpuData.length ?
            <BarChart height={320} max={100} min={0} unit="%" datetime={true} hideTotalFlag={true} totalLabel="할당된 GPU " data={gpuData} legend={false} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
            toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
              const date =  dayjs(this.x).format('MM/DD HH:mm') //'YYYY/MM/DD HH:mm'
              return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y}%</b><br/>`}} /> :
            <NoData height={320} />
        }
        {/* 사용중 GPU정보도? */}
        <div className="divider"></div>
        </> : false }

        <div className="titleArea bothEnd" style={{marginBottom:'30px'}}>
          <h3 className="pageTitle">서버 모니터링 {/* <Tooltip des="날짜와 시간 설정 시 설정한 시각의 서버 상태 정보 확인이 가능합니다." /> */}</h3>
          <div className="btnWrap">
            <button className={"btn "+(state.settingModeFlag ? 'orange' : 'blue')} onClick={() => {
              setState({
                ...state,
                settingModeFlag: !state.settingModeFlag
              })
            }}>{ state.settingModeFlag ? '서버 모니터링 모드' : '서버 설정 모드' }</button>
          </div>
        </div>
        <div style={{width:'499px', marginBottom:'17px'}}>
          <Select option={filterListNode} selected={filterNode}
          onChange={(e:any) => {setFilterNode(e)}} />
        </div>
        <DateRangePicker ref={pickerRef2} initial={{ to: initialServerTime, from: '' }} default={{ to: state.serverTime, from: '' }} limit={state.limit} data={{ to: state.serverTime, from: '' }} single={true} onChange={onChangeServerDatePicker} />
        <div className="serverWrap">
          {
            serverData.masterList.map((node, idx) =>
              <div key={idx}>
                {idx === 0 ? <div className="serverStyle">[{'Master'}]</div> : <div className="serverStyle"></div>}
                <div className={`server status${node.statusTotal}`}>
                  <div className="title">
                    {
                      node.statusTotal === SERVER_STATUS.NORMAL ?
                        <span className="status"></span> :
                        <div style={{marginRight:'10px'}}>
                          <Tooltip des={renderTooltipDes(node, MONITORING_TYPE.MASTER)} width={14} img={renderTooltipImg(node.statusTotal)} />
                        </div>
                    }
                    <span className="text">
                      <>{`${idx + 1}.`}</>&nbsp;
                      <div className="ellipsis">
                        <EllipsisToolTip options={Config.ellipsisTipOptions2}>{`${node.name}`}</EllipsisToolTip>
                      </div>
                    </span>
                    { node.nodeResource.isRunning? 
                      <Link to={'/monitoring/'+node.name}><img src="/images/more_button.png" alt="" /></Link>:
                      <div><img src="/images/more_button_off.png" alt="" /></div>}
                    {/* <Link to={'/monitoring/'+node.name}><img src="/images/more_button.png" alt="" /></Link> */}
                    <div className='break'></div>
                    <span className="type">
                      {
                        (node.gpuType && node.gpuType.gpuList && node.gpuType.gpuList.length > 0) ? //node.gpuType.total - 분할 포함한 개수, node.gpuType.gpuList.length - 순수한 GPU> 개수
                          <div className="gpuTypeWrap">
                            <div className='tooltipWrap' style={{ width: '170px' }}>
                              <EllipsisToolTip options={Config.ellipsisTipOptions2}>{node.gpuType.gpuList[0].name}</EllipsisToolTip>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '7px' }}>
                              <div className="pointBgColor">전체 {node.gpuType.gpuList.length}개</div>
                              {node.gpuType.gpuList.length > 0 ?
                                <GPUInfoPopup title={(node.gpuType.isParted ? `전체 GPU 모델명 및 분할 설정 정보 [분할: 총 ${node.gpuType.total}개]` : `전체 GPU 모델명 [총 ${node.gpuType.gpuList.length}개]`)} data={node.gpuType.gpuList} onLeft={idx%4===3} />
                                : false}
                            </div>
                          </div> : <>&nbsp;</>
                      }
                    </span>
                  </div>
                  <div className="content">
                    { node.nodeResource.isRunning === true ? //시스템 장애 여부 추가 (from api)
                      <>
                        {
                          (node.gpuType && node.gpuType.total) ?
                          <LineChart height={124} min={0} max={100} sharedTooltip={true} unit="%" showTooltipUnit={true}
                            tickAmount={3/* Math.max(...node.nodeResource.gpu.allocatedGpu)+1 */} categories={serverData.categories} yAxisSingle={true}
                            title='GPU 가동률' data={[
                              { name: '가동률 ', showInLegend: false, color: '#BECC23', data: node.nodeResource.gpu.allocatedGpu },
                              // { name: '사용량 ', showInLegend: false, color: '#E68C4F', data: node.nodeResource.gpuUsage }
                            ]} lineY={0} /> :
                          <div className="content">
                            <p className="noData">GPU가 없는 노드입니다.</p>
                          </div>
                        }
                        <div className="detail">
                          <p>
                            <span className="dt">CPU</span>
                            <b className={getColorClass(node.statusCpu)}>{node.nodeResource.cpu}</b> %
                          </p>
                          <p>
                            <span className="dt">Storage</span>
                            <b className={getColorClass(node.statusStorage)}>{node.nodeResource.storage}</b> %
                          </p>
                          <p>
                            <span className="dt">Memory</span>
                            <b className={getColorClass(node.statusMemory)}>{node.nodeResource.memory}</b> %
                          </p>
                        </div>
                      </>
                      :
                      <>
                        <div className="content">
                          <p className="noData" style={{color:'#f30b0b'}}>시스템 장애가 발생했습니다.</p>
                        </div>
                        <div className="detail">
                          <p>
                            <span className="dt">CPU</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                          <p>
                            <span className="dt">Storage</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                          <p>
                            <span className="dt">Memory</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            )
          }
          
          {serverData.storageList.length > 0 ?
            serverData.storageList.map((storagePair:IStorage[], idx:number) =>
              <div key={idx}>
                {idx === 0 ? <div className="serverStyle">[{'Storage'}]</div> : <div className="serverStyle"></div>}
                {
                  storagePair.map((storage:IStorage, jdx:number) =>
                    <div key={jdx} className={`storageBox`}>
                      <div className={`server half status${storage.status}`}>
                        <div className="title" style={{ height: '38px' }}>
                          {
                            storage.status === SERVER_STATUS.NORMAL ?
                              <span className="status"></span> :
                              <div style={{marginRight:'10px'}}>
                                <Tooltip des={renderTooltipDes(storage, MONITORING_TYPE.STORAGE)} width={14} img={renderTooltipImg(storage.status)} />
                              </div>
                          }
                          <span className="text">
                            <>{`${idx*2+jdx + 1}.`}</>&nbsp;
                            <div className="ellipsis">
                              <EllipsisToolTip options={Config.ellipsisTipOptions2}>{`${storage.name}`}</EllipsisToolTip>
                            </div>
                          </span>
                          { storage.url ? 
                          <Link to={storage.url} target="_blank"><img src="/images/more_button.png" alt="" /></Link> :
                          <div><img src="/images/more_button_off.png" alt="" /></div> }
                          
                        </div>
                        <div className="content" style={{ display: 'block', height: '76px', padding: '17px' }}>
                          <p className="storage" style={{ marginBottom: '5px' }}>사용률</p>
                          {
                            storage.storageRate ?
                              <p><b className={getColorClass(storage.status)}>{storage.storageRate}</b> %</p> :
                              <p className="noData">데이터가 없습니다.</p>
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ): false
          }
        </div>

        <div className="serverWrap resourceGroup">
          {
            serverData.workerList.map((node, idx) => {
              return (<div key={idx}>
                {idx === 0 ? <p className="serverStyle">[{'Worker'}]</p> : <p className="serverStyle"></p>}
                <div className={`server status${node.statusTotal}`}>
                  <div className="title">
                    {
                      node.statusTotal === SERVER_STATUS.NORMAL ?
                        <span className="status"></span> :
                        <div style={{marginRight:'10px'}}>
                          <Tooltip des={renderTooltipDes(node, MONITORING_TYPE.WORKER)} width={14} img={renderTooltipImg(node.statusTotal)} />
                        </div>
                    }
                    <span className="text">
                      <>{`${idx + 1}.`}</>&nbsp;
                      <div className="ellipsis">
                        <EllipsisToolTip options={Config.ellipsisTipOptions2}>{`${node.name}`}</EllipsisToolTip>
                      </div>
                    </span>
                    { node.nodeResource.isRunning? 
                      <Link to={'/monitoring/'+node.name}><img src="/images/more_button.png" alt="" /></Link>:
                      <div><img src="/images/more_button_off.png" alt="" /></div>}
                    <div className='break'></div>
                    <span className="type">
                      {
                        (node.gpuType && node.gpuType.gpuList && node.gpuType.gpuList.length > 0) ? //node.gpuType.total - 분할 포함한 개수, node.gpuType.gpuList.length - 순수한 GPU> 개수
                          <div className="gpuTypeWrap">
                            <div className='tooltipWrap' style={{ width: '170px' }}>
                              <EllipsisToolTip options={Config.ellipsisTipOptions2}>{node.gpuType.gpuList[0].name}</EllipsisToolTip>
                            </div> 
                            <div style={{ display: 'flex', justifyContent:'end', alignItems:'center', gap:'7px' }}>
                              <div className="pointBgColor">전체 {node.gpuType.gpuList.length}개</div>
                              { node.gpuType.gpuList.length > 0 ?
                                <GPUInfoPopup title={(node.gpuType.isParted ? `전체 GPU 모델명 및 분할 설정 정보 [분할: 총 ${node.gpuType.total}개]` : `전체 GPU 모델명 [총 ${node.gpuType.gpuList.length}개]`)} data={node.gpuType.gpuList} onLeft={idx%4===3} />
                                : false }
                            </div>
                          </div> : <>&nbsp;</>
                      }
                    </span>
                  </div>
                  <div className="content">
                    { node.nodeResource.isRunning === true ? //시스템 장애 없을 때
                      <>
                        {node.nodeResource.isInitMig ? // mig 설정 중일 때는 gpuType을 받아올 수 없음!
                          <div className="settingsOverlay">
                            <p className="message">MIG 설정 중입니다.<br />수 초에서 수 분 이상 소요될 수 있습니다.</p>
                            <LoadingAnimation />
                          </div> 
                          : // mig 설정 중이 아닐 때
                          <>
                            {state.settingModeFlag ? // [모드] 서버 설정 모드일 때,
                              <>
                                <div className="settingsOverlay">
                                  <div className="buttonArea">
                                    <Link className="btn outline" to={"/workspace?node=" + node.name} >노드별 워크스페이스 관리</Link>
                                    {node.nodeResource.canMig ? <button className="btn outline" onClick={() => { openMigSetting(node) }}>MIG 설정</button> : false}
                                  </div>
                                </div>
                              </>
                              :  // [모드] 모니터링 모드일 때
                              <>
                                {
                                  (node.gpuType !== null && node.gpuType.total) || node.nodeResource.canMig ?
                                    <LineChart height={124} min={0} max={100} sharedTooltip={true} unit="%" showTooltipUnit={true}
                                      tickAmount={3/* Math.max(...node.nodeResource.gpu.allocatedGpu)+1 */} categories={serverData.categories} yAxisSingle={true}
                                      title='GPU 가동률' data={[
                                        { name: '가동률 ', showInLegend: false, color: '#BECC23', data: node.nodeResource.gpu.allocatedGpu },
                                        // { name: '사용률 ', showInLegend: false, color: '#E68C4F', data: node.nodeResource.gpuUsage }
                                      ]} lineY={0} /> :
                                    <div className="content">
                                      <p className="noData">GPU가 없는 노드입니다.</p>
                                    </div>
                                }
                                <div className="detail">
                                  <p>
                                    <span className="dt">CPU</span>
                                    <b className={getColorClass(node.statusCpu)}>{node.nodeResource.cpu}</b> %
                                  </p>
                                  <p>
                                    <span className="dt">Storage</span>
                                    <b className={getColorClass(node.statusStorage)}>{node.nodeResource.storage}</b> %
                                  </p>
                                  <p>
                                    <span className="dt">Memory</span>
                                    <b className={getColorClass(node.statusMemory)}>{node.nodeResource.memory}</b> %
                                  </p>
                                </div>
                              </>}
                          </>}
                      </>
                      : //시스템 장애 있을 때
                      <>
                        <div className="content">
                          <p className="noData" style={{color:'#f30b0b'}}>시스템 장애가 발생했습니다.</p>
                        </div>
                        <div className="detail">
                          <p>
                            <span className="dt">CPU</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                          <p>
                            <span className="dt">Storage</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                          <p>
                            <span className="dt">Memory</span>
                            <b className={'okay'}>{'-'}</b>
                          </p>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>)
            })
          }
        </div>
    </MonitoringDetailPageFragment>
  )
}


const MonitoringDetailPageFragment = styled.div` 
.period + .chartTitle{margin-top:30px;}
.chartTitle{margin:30px 0 10px;font-size:14px;text-align:center;}
.serverWrap{margin:20px -10px 0;font-size:0; display:flex; flex-wrap: wrap;}
.serverWrap > div {display:inline-block;width:25%;padding:10px 10px;box-sizing:border-box;vertical-align:top;}
.serverWrap .server{border:1px solid #a7a7a7;box-sizing:border-box;border-radius:4px;}
.serverWrap .server.half + .server.half{margin-top:10px;}
.serverWrap .server .noData{display:flex;align-items:center;justify-content:center;height:105px;font-size:18px;color:#a7a7a7;font-weight:bold;}
.serverWrap .server.half .noData{height:auto;}
.serverWrap .title {display:flex;flex-wrap:wrap;align-items:center;padding:0 20px;border-bottom:1px solid #a7a7a7;}
.serverWrap .title .text {display:flex;align-items:center;flex:1;overflow:hidden;font-weight:bold;line-height:34px;font-size:14px;text-overflow:ellipsis;white-space:nowrap;}
.serverWrap .title .text .ellipsis {display:flex;align-items:center;width:210px;height:34px;}
.serverWrap .title .break {flex-basis:100%}
.serverWrap .title .type {flex:1;font-size:14px;margin-bottom:8px;text-overflow:ellipsis;white-space:nowrap;height:22px;}
.serverWrap .server.half .content{display:flex;align-items:center;justify-content:center;height:59px;box-sizing:border-box;}
.serverWrap .content{padding:10px; position:relative;}
.serverWrap .content .detail{display:flex;align-items:center;justify-content:space-around;}
.serverWrap .content p{font-size:13px;text-align:center;}
.serverWrap .content p b{font-size:20px;font-weight:bold;}
.serverWrap .content .dt{margin:0px 0 6px;display:block;font-size: 12px;}

.serverWrap .server .status{display:inline-block;width: 14px;height:12px;margin-right:10px;vertical-align:middle;}
.serverWrap .server.status0 .status{background:url(/images/status.png) no-repeat center / 100%;}
.serverWrap .server.status1 .status{background:url(/images/alert-warn.png) no-repeat center / 100%;}
.serverWrap .server.status2 .status{width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}

.okay {color:#333333}
.noti {color:#ffa900}
.warn {color:#f30b0b}

.gpuTypeWrap {display:flex; align-items:center; justify-content:space-between; position:relative; height:22px;}
.serverStyle {font-weight: 700; font-size: 14px; margin-bottom:10px; height:16px; line-height:16px;}

.settingsOverlay {background-color:rgba(255,255,255,1);width:100%;height:168px;text-align:center;z-index:1}
.settingsOverlay p.message {font-size:14px;padding-top:34px; margin-bottom:12px;}
.settingsOverlay .buttonArea {height:100%; display:flex; flex-direction:column; justify-content:center; align-items:center; gap:10px;}
.settingsOverlay .buttonArea a {display:inline-block; width:188px; height:38px;}
.settingsOverlay .buttonArea button {display:inline-block; width:220px; height:40px;}

.storageBox + .storageBox{margin-top:20px;}

.pointBgColor { display:inline-block;padding:3px 2px;background-color:#E1F5FF;border-radius:2px; }
`

export default Monitoring