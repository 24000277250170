import { IBaseDataType, SORT_ORDER } from "./BaseDataType"
import { IChartSeries } from "../interfaces/Chart"
import ApiController from "../controller/ApiController"
import { ERROR_TYPE } from "../interfaces/Error"
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from "snakecase-keys"
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import { ObjType } from "./BaseDataType"
import { promiseGetRecoil} from 'recoil-outside'
import { ISettings, settingState} from '../states/settingState'

export enum WORKSPACE_STATUS {
ERROR = 1, //장애
FAILED = 2, //생성 실패
PENDING = 3, //대기
CANCELED = 4, //취소
RUNNING = 5, //실행중
RETURNED = 6, //사용자 반납
RECALLED = 7, //관리자 회수
COMPLETED = 8, //Job 완료
ADMIN_CANCELED = 9, //관리자 취소
CREATING = 10, //생성중
PRE_QUEUE = 11, //대기열 등록 전
SYSTEM_CANCELED = 12, //시스템 취소
SYSTEM_RECALLED = 13 //시스템 회수

  /* ERROR = 0,
  FAILED = 1,
  PENDING = 2,
  CANCELED = 3,
  RUNNING = 4,
  RETURNED = 5,
  RECALLED = 6,
  COMPLETED = 8,
  ADMIN_CANCELED = 9 */
}

export enum WORKSPACE_LIST_ORDER_BY {
  NAME = 0,
  RESOURCE_GROUP_NAME = 1,  //jupyter->rgroup
  CREATION_TIMESTAMP = 2,
  START_TIMESTAMP = 3,
  RESOURCE_TYPE = 4,
  STATUS = 5,
  USER = 6
}

export enum NODE_TYPE {
  CPU = 'cpu',
  GPU = 'gpu',
  NONE = ''
}

export interface IWorkspaceListItem extends IBaseDataType {
  id:string
  disabled:boolean
  name:string
  namespace:string
  wsInfo?:IWorkspaceInfo
  userInfo?:IWorkspaceUserInfo
  isPull:boolean
  isJuypter:boolean
  gpuType:string
  gpuNum:number|string
  ports:IPortMap[]
  createdAt:number
  startedAt:number|null
  statusCell:IWorkspaceStatus
  status:WORKSPACE_STATUS
  isJob:boolean
  resourceBlock:IResourceUnitSpec,
  command: string[]
  argument: string[]
  environment: any[] //IEnvironment[]
  volumes: any[] //IVolume[]
  resourceDetail:IResourceUnitSpec2
  regUrl?:string // 나중에 리펙토링 시 추가 예정 (백엔드에서 주는 데이터)
}

export interface IWorkspaceDetail {
  name:string
  namespace:string
  userNo:number
  id:number
  userId:string
  createdAt:number
  startedAt:number
  uid:string
  deletedAt:number|null
  gpuUuid:[{
    idx:number,
    uuid:string
  }]
  detail:IWorkspaceDetailDetail
  resourceBlock:IResourceBlock
  ports:IPortMap[]
  user:IWorkspaceUserInfo
  ssh:string|null
  jupyter:string|null
  ip:string|null
  priority:number|null
  rgroupInfo:RgroupInfo
}

export interface IWorkspaceDetailDetail {
  image:string
  reg:string
  imageDigest:string
  rgroup:string
  isJupyter:boolean
  isJob:boolean
  statusMsg:string
  errorTimestamp:number
  statusId:number
  templateSpec:ITemplateSpec
}

export interface ITemplateSpec {
  command:string[]
  argument:string[]
  environment:IEnvironment[]
  volumes:IVolume[]
}

export interface IWorkspaceInfo {
  address:(string|null)
  image:string
  jupyter:(string|null)
  status:WORKSPACE_STATUS
  isJob:boolean
}

export interface IWorkspaceName {
  name:string
  namespace:string
  creationTimestamp:string|number
}

export interface IWorkspaceUserInfo {
  userNo?:number
  userId:string
  name:string
  phoneNumber:string
  email:string
  department:string  
}

export interface IWorkspaceStatus {
  id:string|number
  namespace:string
  status:WORKSPACE_STATUS
  label?:string //얘뭐지
  userid?:string
  creationTimestamp:string
  startTimestamp:string
  endTimestamp:string
  statusMessage?:string
  userInfo?:any //IUserDetail
  isJob?:boolean
  name?:string //워크스페이스 이름불러오기용으로 추가함
}

export interface IVolume {
  pvc:string
  fakePvc?:string
  mountPath:string
}

export interface IEnvironment {
  key:string
  value:string
}

export interface IPortMap {
  autoFlag:boolean
  internal:number|string
  external:number|string
}

export interface IResourceUnit {
  numBlock:number
  maxNumBlock?:number
  totalNumBlock?:number
  allocatableNumBlock?:number
  freeNumBlock?:number
  resourceBlockSpec:IResourceUnitSpec
  freeCpu?:number
  freeMem?:number
  labels?:string
  nodeList?:string[]
}

export interface IResourceUnitSpec {
  cpu:number
  mem:number
  memUnit:string
  type:string
  gpuMem:number
  gpuMemUnit:string
  gpuPer:number
  isPartedGpu:boolean
  id:number
}

export interface IResourceUnitSpec2 {
  resourceBlock:IResourceUnitSpec
  gpuNum:string
}

export enum RESOURCE_TYPE {
  GPU = 'GPU',
  BLOCK = 'BLOCK',
  MIG = 'MIG',
  CPU = 'CPU'
}
export interface IResourceBlock {
  cpu:number
  mem:number
  memUnit:string
  type:string
  realType:string|null
  gpuMem:number|null
  gpuMemUnit:string|null
  gpuPer:number|null
  resourceType?:RESOURCE_TYPE
  isPartedGpu?:boolean
  gpuNum:number|null
}

export interface IWorkspaceCreateRequest {
  id?:number
  name:string
  namespace:string
  userNo:number
  detail:{
    image:string
    reg:string
    imageDigest:string
    rgroup:string
    isJupyter:boolean
    isJob:boolean
    isMultiTraining:boolean
    command:string[]
    argument:string[]
    ports:IPortMap[]
    environment:IEnvironment[]
    volumes:IVolume[]
    nodeList:string[]
  }
  resourceBlock:IResourceBlock
}

export interface RgroupInfo {
  name: string
  nodeList: [{
    name:string
    ip:string
  }]
  volumeList: [{
    name:string
    size:string
    type:string
  }]
  reg: string
  isCommon: boolean
}

export class Workspace {
  public static async getList(userNo:number, orderBy:WORKSPACE_LIST_ORDER_BY, sortBy:SORT_ORDER, pageNum:number=1, pageSize:number=50, rgroup:string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/workspaces', {
        user_no: userNo,
        page_num: pageNum,
        page_size: pageSize,
        order_by: orderBy,
        sort_by: sortBy,
        rgroup: rgroup
      })
      
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Workspace.getList error>', response)
          break
      }
    } catch (error:any){
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getListAdmin(userNo:number, orderBy:WORKSPACE_LIST_ORDER_BY, sortOrder:SORT_ORDER, page:number=1, size:number=50, nodeFilter?:string[], rgroupFilter?:string[], statusFilter?:number[], isJob?:boolean) {
    try {
      const apiController = ApiController.getInstance()
      let requestBody:any = {
        user_no: userNo,
        order_by: orderBy,
        sort_by: sortOrder,
        page_num: page,
        page_size: size
      }
      if (nodeFilter !== undefined) { requestBody.node = nodeFilter.join(',') }
      if (rgroupFilter !== undefined) { requestBody.rgroup = rgroupFilter.join(',') }
      if (statusFilter !== undefined) { requestBody.status = statusFilter.join(',') }
      if (isJob !== undefined) { requestBody.is_job = isJob }
      const response = await apiController.get('/workspaces', requestBody)
      
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Workspace.getListAdmin error>', response)
          break
      }
    } catch (error:any){
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async removeWorkspace(wsList:any[], deleteFlag:boolean=true, priorityFlag?:boolean) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.del(
        '/workspaces?is_delete='+String(deleteFlag)
        +'&is_pending_queue='+String(priorityFlag !== undefined ? (priorityFlag === true ? 'false' : 'true') : 'false')
        +'&is_priority_queue='+String(priorityFlag !== undefined && priorityFlag === true ? 'true' : 'false'), 
        snakecaseKeys(wsList)
      )
      switch(response.status) {
        case 200:
          return response.data
        default:
          console.log('Workspace.removeWorkspace error>', response)
          return response
      }
    } catch (error:any){
      switch(error.response.status) {
        case 400:
          return camelcaseKeys(error.response.data, { deep:true })
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }  
    }
  }

  public static async getDetail(id:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/workspaces/'+id,{})
      switch(response.status) {
        case 200:
          return camelcaseKeys(response, { deep:true })
        case 204:
          return camelcaseKeys(response, { deep:true }) 
        default:
          console.log('Workspace.getDetail error>', response)
          return response
      }
    } catch (error:any){
      // console.log('Workspace.getDetail error>', error)
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getMonitoring(wsName:string, namespace:string, from:number, to:number, gpuUuid:any, resourceType?:RESOURCE_TYPE , createdAt?:number ) {
    try {
      const apiController = ApiController.getInstance()
      const url = '/metrics/ws/detail?ws_name='+wsName+'&namespace='+namespace+'&start_timestamp='+from+'&end_timestamp='+to+'&created_at='+createdAt+'&resource_type='+resourceType
      let response
      if(gpuUuid.length > 0) {
        response = await apiController.post(url,{
          gpu_uuid:gpuUuid
        })
      } else {
        response = await apiController.post(url)
      }
      
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep:true })
        default:
          console.log('Workspace.getMonitoring error>', response)
          return response
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async returnWorkspace(workspaceIdList:any[]) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      let wsList = workspaceIdList
      const response = await apiController.del('/workspaces?is_delete=' + String(false) , wsList)
      switch (response.status) {
        case 200:
          return response.data
        default:
          console.log('Workspace.returnWorkspace error>', response)
          return response
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  // public static async downloadWorkspace(id:number, namespace:string, timestamp:string) {
  //   try {
  //     const apiController: ApiController = ApiController.getInstance()
  //     const response = await apiController.get('/workspaces/download', {
  //       ws_name: id,
  //       ws_namespace: namespace,
  //       creation_timestamp: timestamp
  //     })

  //     switch (response.status) {
  //       case 200:
  //         let timeline = []
  //         let payload = [
  //           ['워크스페이스/Job 이름', response.data.ws_name],
  //           ['사용자 이름', response.data.user.name],
  //           ['이메일', response.data.user.mail],
  //           ['연락처', response.data.user.hp],
  //           ['부서명', response.data.user.division],
  //           [],
  //           ['datetime']
  //         ]
  //         let commentsLineNumber = payload.length - 1
  //         for (var eachField of response.data.data) {
  //           payload[commentsLineNumber].push(eachField.label)

  //           for (var eachData of eachField.data) {
  //             eachData.datetime = dayjs(eachData.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
  //             let address = timeline.indexOf(eachData.datetime) + commentsLineNumber + 1
  //             if (address === commentsLineNumber) {
  //               timeline.push(eachData.datetime)
  //               address = timeline.length + commentsLineNumber
  //               payload[address] = [eachData.datetime]
  //             }
  //             payload[address].push(eachData.value || ' ')
  //           }
  //         }

  //         const filename = 'Ai Pub DEV_usage details_download_' + dayjs().format('YYYYMMDDHHmm') + '.xlsx'  // response.data.ws_name
  //         var wb = XLSX.utils.book_new()
  //         var ws = XLSX.utils.aoa_to_sheet(payload)
  //         XLSX.utils.book_append_sheet(wb, ws, response.data.ws_name)
  //         XLSX.writeFile(wb, filename)
  //         break
  //       default:
  //         console.log('Workspace.downloadWorkspace error>', response)
  //         return response
  //     }
  //   } catch (error: any) {
  //     throw new Error(ERROR_TYPE.ERROR)
  //   }
  // division등 구조도 바꾸기전임}

  public static async checkDuplicatePort(port:number):Promise<boolean> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get('/ports/check', {
        port: port
      })
      
      switch(response.status) {
        case 200:
          return (response.data.is_exist === false)
        default:
          console.log('Workspace.checkDuplicatePort error>', response)
          return false
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async create(data:IWorkspaceCreateRequest) {
    try {
      const payload:any = snakecaseKeys(data)
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/workspaces', payload)
      
      switch(response.status) {
        case 200:
          return response.data
        default:
          break
      }
    } catch (error: any) {
      console.log('Workspace.create error>', error)
      switch (error.response.status) {
        case 409:
          throw new Error(ERROR_TYPE.DUPLICATED)
        case 400:
          throw new Error(ERROR_TYPE.IS_INIT_MIG)
        case 404:
          throw new Error(ERROR_TYPE.FAILED)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async reCreate(id:number, data:IWorkspaceCreateRequest) {
    try {
      const payload:object = snakecaseKeys(data)
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.patch('/workspaces/'+id, payload)
      
      switch(response.status) {
        case 200:
          return response.data
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    } catch (error: any) {
      console.log('Workspace.reCreate error>', error)
      switch (error.response.status) {
        case 400:
          throw new Error(ERROR_TYPE.IS_INIT_MIG)
        case 404:
          throw new Error(ERROR_TYPE.FAILED)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async checkDuplicateName(name:string, namespace:string):Promise<boolean> {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.get(`/workspaces/check/${name}/${namespace}`, {})
      switch(response.status) {
        case 200:
          return (response.data.is_exist === false)
        default:
          console.log('Workspace.checkDuplicateName error>', response)
          return false
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

}