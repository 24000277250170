import { useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import { userInfoState } from "../../states/userInfoState"
import { recreateState } from "../../states/recreateState"
import PopupController from "../../controller/PopupController"
import { useNavigate } from "react-router"
import { IEnvironment, IPortMap, IResourceUnit, IVolume, IWorkspaceCreateRequest, Workspace, NODE_TYPE, RESOURCE_TYPE } from "../../model/Workspace"
import { IResourceGroup, ResourceGroup } from "../../model/ResourceGroup"
import ISelectOption from "../../interfaces/SelectOption"
import Select from "../components/ui/Select"
import InputBox, { TEXT_INPUT_TYPE } from "../components/ui/InputBox"
import Toggle from "../components/ui/Toggle"
import ResourceBlock, { ResourceUnitType } from "../components/ui/ResourceUnit"
import ModalEvent from "../../events/ModalEvent"
import { Image } from "../../model/Image"
import { SORT_ORDER } from "../../model/BaseDataType"
import { ERROR_TYPE } from "../../interfaces/Error"
import { authState } from "../../states/authStates"
import regularExpression from "../../utils/regularExpression"
import { IResourceNode } from "../../model/ResourceGroup"
import { IResourceVolume } from "../../model/ResourceGroup"
import ToastEvent from "../../events/ToastEvent"

interface IJobFormProps {

}

interface IJobFormState {
  //공통
  reCreateFlag: boolean
  isCommon:boolean //공용리소스그룹 여부
  id?:number

  //리소스 그룹
  resourceGroupList:IResourceGroup[]
  resourceGroupSelectList:ISelectOption[]
  resourceGroup?:IResourceGroup //선택된 리소스 그룹

  //리소스 블록
  cpuResourceBlockList:any[]
  gpuResourceBlockList:any[]
  resourceTypeSelectList:ISelectOption[]

  //리소스 관련
  rgroup:NODE_TYPE
  resourceGroupError:string
  resourceTypeError:string
  //공통-GPU리소스
  selectedResourceBlockKey:number|null
  selectedResourceBlockId:number|null
  resourceBlockQty:number|string
  resourceBlockError:string
  //공통-CPU리소스
  resourceNodeCpuQty:number|string
  resourceNodeRamSize:number|string
  resourceNodeErrorCpu:string
  resourceNodeErrorRam:string
  //공통-리소스 사용 제한
  resourceLimit:any
  

  //Job
  jobName: string
  jobNameError: string
  image: string
  imageId:string
  imageError: string
  // command: string[]
  // commandError: string[]
  // environment: IEnvironment[]
  // environmentError: IEnvironment[]
  // argument: string[]

  //이미지 팝업에서 받아온 레지스트리 주소
  regUrl: string

  //기타
  duplicateNameCheckFlag: boolean
  validCheckedData: any
}
interface ICommandState {
  command:string[]
  commandError:string[]
}
interface IEnvironmentState {
  environment:IEnvironment[]
  environmentError:IEnvironment[]
}
interface IArgumentState {
  argument:string[]
}
interface IVolumeState {
  //볼륨
  dataVolume: IVolume[]
  dataVolumeError: IVolume[]
}
interface IPortState {
  //공통-포트
  portMap: IPortMap[]
  portError: IPortMap[]
}
interface ICpuMaxState {
  cpuMaxCore:number
  memMaxGiB:number
  cpuMinCore:number
  memMinGiB:number
  availableCpuNodeList:any[]
}

const JobForm = (props: IJobFormProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const [ userInfo ] = useRecoilState(userInfoState)
  const [recreateData, setRecreate] = useRecoilState(recreateState)
  const popupController = PopupController.getInstance()
  const navigate = useNavigate()
  const regExp = regularExpression.regExp

  const selectRGroupRef = useRef<any>()
  const selectResourceRef = useRef<any>()

  const [ command, _setCommand ] = useState<ICommandState>({
    command: [],
    commandError: []
  })
  const commandRef = useRef(command)
  const setCommand = (data: any) => {
    commandRef.current = data
    _setCommand(data)
  }

  const [ environment, _setEnvironment ] = useState<IEnvironmentState>({
    environment: [],
    environmentError: []
  })
  const environmentRef = useRef(environment)
  const setEnvironment = (data: any) => {
    environmentRef.current = data
    _setEnvironment(data)
  }

  const [ argument, _setArgument ] = useState<IArgumentState>({
    argument: []
  })
  const argumentRef = useRef(argument)
  const setArgument = (data: any) => {
    argumentRef.current = data
    _setArgument(data)
  }

  const [ port, _setPort ] = useState<IPortState>({
    portMap: [],
    portError: [],
  })
  const portRef = useRef(port)
  const setPort = (data: any) => {
    portRef.current = data
    _setPort(data)
  }

  const [ volume, _setVolume ] = useState<IVolumeState>({
    dataVolume: [],
    dataVolumeError: []
  })
  const volumeRef = useRef(volume)
  const setVolume = (data: any) => {
    volumeRef.current = data
    _setVolume(data)
  }

  const [ cpuMaxState, _setcpuMaxState] = useState<ICpuMaxState>({
    cpuMaxCore: -1,
    memMaxGiB: -1,
    cpuMinCore: 99999,
    memMinGiB: 99999,
    availableCpuNodeList: [],
  })
  const cpuMaxStateRef = useRef(cpuMaxState)
  const setcpuMaxState = (data:any) => {
    cpuMaxStateRef.current = data
    _setcpuMaxState(data)
  }

  const [state, _setState] = useState<IJobFormState>({
    //공통
    reCreateFlag: false,
    isCommon: false,

    //리소스 그룹
    resourceGroupList: [],
    resourceGroupSelectList: [],

    //리소스 블록
    cpuResourceBlockList: [],
    gpuResourceBlockList: [],
    resourceTypeSelectList: [],

    //리소스 관련
    rgroup: NODE_TYPE.NONE, //(리소스 타입 - cpu, gpu 정도만 표기)
    resourceGroupError: '', // 리소스 그룹 에러 메시지
    resourceTypeError: '', // 리소스 타입 에러 메시지
    //공통-GPU리소스
    selectedResourceBlockKey: null,
    selectedResourceBlockId: null,
    resourceBlockQty: '',
    resourceBlockError: '',
    //공통-CPU리소스
    resourceNodeCpuQty: '',
    resourceNodeRamSize: '',
    resourceNodeErrorCpu: '',
    resourceNodeErrorRam: '',
    //공통-리소스 사용 제한
    resourceLimit: null,

    //Job
    jobName: '',
    jobNameError: '',
    image: '',
    imageId: '',
    imageError: '',

    //이미지 팝업에서 받아온 레지스트리 주소
    regUrl: '',

    //기타
    duplicateNameCheckFlag: false,
    validCheckedData: null
  })
  const stateRef = useRef(state)
  const setState = (data: any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if (recreateData !== null) {
      // 재신청 데이터 불러오기
      setRecreateData()

    } else { //재신청 아닐 때
      // 리소스 그룹 SelectBox 세팅
      getRgroupList()

      if (command.command.length === 0) {
        addCommandHandler()
      }
      if (environment.environment.length === 0) {
        addEnvironmentHandler()
      }
      if(argument.argument.length === 0){
        addArgumentHandler()
      }
      if (volume.dataVolume.length === 0) {
        addVolumeHandler()
      }
      if (port.portMap.length === 0) {
        addPortHandler()
      }
    }

    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  const setRecreateData = async () => {
    if (recreateData === null) return

    //resourceGroup
    const resorceGroupList = await ResourceGroup.getRgroupList(authInfo.userNo)
    let resourceGroup
    let resourceGroupSelectList:ISelectOption[] = []
    for(let key in resorceGroupList.rgroup) {
      // find recreate rgroup
      if(resorceGroupList.rgroup[key].name === recreateData.namespace){
        resourceGroup = resorceGroupList.rgroup[key]
      }
      // set selectList
      resourceGroupSelectList.push({
        label: resorceGroupList.rgroup[key].name, 
        value: key
      })
    }

    //resourceTypeSelectList
    const resourceTypeSelectList:ISelectOption[] = []
    let resourceBlockList = undefined
    if (resourceGroup) {
      // 리소스 설정 selectBox 세팅
      resourceBlockList = await ResourceGroup.getResourceBlockInfo(resourceGroup.name)
      if(resourceBlockList.gpuResourceBlockList.length > 0) {
        resourceTypeSelectList.push({label: 'GPU 리소스 블록 타입', value: NODE_TYPE.GPU})
      }
      if(resourceBlockList.cpuResourceBlockList.length > 0) {
        resourceTypeSelectList.push({label: 'CPU 리소스 타입', value: NODE_TYPE.CPU})
      }
    }

    // 리소스 설정 cpu 관련 Max값 세팅
    let cpuMaxCore = -1, memMaxGiB = -1, cpuMinCore = 99999, memMinGiB = 99999
    if(resourceBlockList.cpuResourceBlockList.length > 0) {
      for(let eachCpu of resourceBlockList.cpuResourceBlockList) {
        if(eachCpu.resourceBlockSpec.cpu > cpuMaxCore) {
          cpuMaxCore = eachCpu.resourceBlockSpec.cpu
        }
        if(eachCpu.resourceBlockSpec.cpu < cpuMinCore) {
          cpuMinCore = eachCpu.resourceBlockSpec.cpu
        }

        if(eachCpu.resourceBlockSpec.mem > memMaxGiB) {
          memMaxGiB = eachCpu.resourceBlockSpec.mem
        }
        if(eachCpu.resourceBlockSpec.mem < memMinGiB) {
          memMinGiB = eachCpu.resourceBlockSpec.mem
        }
      }
    }
    // 리소스 설정 cpu 관련 Max값 세팅
    setcpuMaxState({
      ...cpuMaxState,
      cpuMaxCore: cpuMaxCore,
      memMaxGiB: memMaxGiB,
      cpuMinCore: cpuMinCore,
      memMinGiB: memMinGiB
    })

    //resourceblock
    // const resourceBlockList = (await ResourceGroup.getRgroupResourceWithName(recreateData.rgroup)).result.resourceBlock
    // let resourceBlockKey: number | null = null
    // let resourceBlockId: number | null = null
    // for (let key in resourceBlockList) {
    //   let eachResourceBlock = resourceBlockList[key]
    //   if (eachResourceBlock.resourceBlockSpec.id === recreateData.resourceBlock.id) {
    //     resourceBlockKey = Number(key)
    //     resourceBlockId = recreateData.resourceBlock.id
    //     break
    //   }
    // }

    //image - 이미지 목록 불러와서 검사
    let isInImageList: boolean = false
    let ImageUrlError = ''
    const isCommon = resourceGroup.isCommon || false
    const reg = recreateData.detail.reg
    try {
      const data = await Image.getListPopup(isCommon ? authInfo.id : recreateData.namespace, recreateData.isJuypter, SORT_ORDER.ASC, resourceGroup ? isCommon : false)
      let repository = recreateData.detail.image.split(':')[0]
      let tag = recreateData.detail.image.split(':')[1]
      if (reg === data.common.reg || reg === data.type.reg) {
        for (let eachImage of data.common.imageList) {
          if (eachImage.rep === repository && eachImage.tag === tag) {
            isInImageList = true
            break
          }
        }
        if (!isInImageList) {
          for (let eachImage of data.type.imageList) {
            if (eachImage.rep === repository && eachImage.tag === tag) {
              isInImageList = true
              break
            }
          }
        }
      }
      
      if (isInImageList === false) {
        // 이미지 목록에 없다!
        ImageUrlError = '이미지 목록에 등록한 이미지가 없습니다. 다시 이미지를 등록하시거나 다른 이미지를 선택해 주세요.'
      }
    }
    catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        // 이미지 목록 가져오기 실패!
        ImageUrlError = '이미지를 가져오는데 오류가 발생했습니다. 관리자에게 문의해 주세요.'
      }
    }

    // originTimestamp = recreateData.creationTimestamp
    setState({
      ...state,
      // 공통
      reCreateFlag: true,
      isCommon: isCommon,
      id: recreateData.id,

      //리소스 그룹 
      resourceGroupList: resorceGroupList.rgroup,
      resourceGroupSelectList: resourceGroupSelectList,
      resourceGroup: resourceGroup,

      //리소스 설정
      resourceTypeSelectList: resourceTypeSelectList,
      cpuResourceBlockList: resourceBlockList.cpuResourceBlockList,
      gpuResourceBlockList: resourceBlockList.gpuResourceBlockList,

      // 리소스 세팅 초기화
      rgroup: NODE_TYPE.NONE, //(리소스 타입 - cpu, gpu 정도만 표기)
      resourceGroupError: '', // 리소스 그룹 에러 메시지
      resourceTypeError: '리소스 설정이 초기화되었습니다. 리소스를 재설정해 주세요.', 
      resourceBlockList: [], // 리소스 블록 리스트

      // gpu
      resourceBlockQty: '', //parseInt(String(recreateData.gpuNum)),
      selectedResourceBlockKey: null, //resourceBlockKey,
      selectedResourceBlockId: null, //resourceBlockId,
      // cpu
      resourceNodeCpuQty: '', //recreateData.resourceBlock.cpu,
      resourceNodeRamSize: '', //recreateData.resourceBlock.mem,
      
      // 워크스페이스
      jobName: recreateData.name,
      image: recreateData.detail.image || '',
      imageId: recreateData.detail.imageDigest,
      imageError: ImageUrlError,
      regUrl: recreateData.detail.reg,
    })

    //Command
    if(recreateData.detail.templateSpec.command.length > 0) {
      // error가 배열형태인 애들은 배열 길이를 데이터와 똑같이 맞춰줌
      let commandError = command.commandError
      for (let i = 0; i < recreateData.detail.templateSpec.command.length; i++) {
        commandError.push('')
      }
      setCommand({
        command: recreateData.detail.templateSpec.command,
        commandError: commandError
      })  
    } else {
      addCommandHandler()
    }

    //Environment
    if(recreateData.detail.templateSpec.environment.length > 0) {
      // error가 배열형태인 애들은 배열 길이를 데이터와 똑같이 맞춰줌
      let environmentError = environment.environmentError
      for (let i = 0; i < recreateData.detail.templateSpec.environment.length; i++) {
        environmentError.push({ key: '', value: '' })
      }
      setEnvironment({
        environment: recreateData.detail.templateSpec.environment,
        environmentError: environmentError
      })  
    } else {
      addEnvironmentHandler()
    }

    //Argument
    if(recreateData.detail.templateSpec.argument.length > 0) {
      setArgument({
        argument: recreateData.detail.templateSpec.argument,
      })  
    } else {
      addArgumentHandler()
    }

    //볼륨
    if(recreateData.detail.templateSpec.volumes.length > 0) {
      // error가 배열형태인 애들은 배열 길이를 데이터와 똑같이 맞춰줌
      let dataVolumeError = volume.dataVolumeError
      for (let i = 0; i < recreateData.detail.templateSpec.volumes.length; i++) {
        dataVolumeError.push({ pvc: '', mountPath: '' })
      }
      setVolume({
        dataVolume: recreateData.detail.templateSpec.volumes,
        dataVolumeError: dataVolumeError
      })  
    } else {
      addVolumeHandler()
    }

    //포트
    let ports: IPortMap[] = []
    let portError: IPortMap[] = []
    for (let eachPort of recreateData.ports) {
      ports.push({
        autoFlag: eachPort.external === 0,
        internal: eachPort.internal,
        external: (eachPort.external === 0 ? '' : eachPort.external)
      })
      portError.push({
        autoFlag: false,
        internal: '',
        external: ''
      })
    }
    if (ports.length === 0) {
      addPortHandler()
    }
    else {
      setPort({
        portMap: ports,
        portError: portError
      })
    }

    // 재신청 데이터 초기화
    setRecreate(null)
  }

  //rgroupList
  const getRgroupList = async () => {
    const response = await ResourceGroup.getRgroupList(authInfo.userNo)
    // console.log(response)

    let resourceGroupSelectList:ISelectOption[] = []

    for(let key in response.rgroup) {
      resourceGroupSelectList.push({
        label: response.rgroup[key].name, 
        value: key
      })
    }

    setState({
      ...state,
      resourceGroupList: response.rgroup,
      resourceGroupSelectList: resourceGroupSelectList
    })
  }

  //command
  const addCommandHandler = () => {
    setCommand({
      command: command.command.concat(''),
      commandError: command.commandError.concat('')
    })
  }
  const removeCommandHandler = (idx:number) => {
    if (idx === 0 && command.command.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = command.command.concat([])
      const newArray2 = command.commandError.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setCommand({
        command: newArray1,
        commandError: newArray2
      })
    }
  }

  //environment
  const addEnvironmentHandler = () => {
    setEnvironment({
      environment: environment.environment.concat({
        key: '',
        value: ''
      }),
      environmentError: environment.environmentError.concat({
        key: '',
        value: ''
      })
    })
  }
  const removeEnvironmentHandler = (idx:number) => {
    if (idx === 0 && environment.environment.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = environment.environment.concat([])
      const newArray2 = environment.environmentError.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setEnvironment({
        ...state,
        environment: newArray1,
        environmentError: newArray2
      })
    }
  }

  //argument
  const addArgumentHandler = () => {
    setArgument({
      argument: argument.argument.concat('')
    })
  }
  const removeArgumentHandler = (idx:number) => {
    if (idx === 0 && argument.argument.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray = argument.argument.concat([])
      newArray.splice(idx, 1)
      setArgument({
        argument: newArray
      })
    }
  }

  //volumn
  const addVolumeHandler = () => {
    setVolume({
      ...volume,
      dataVolume: volume.dataVolume.concat({
        pvc: '',
        mountPath: ''
      }),
      dataVolumeError: volume.dataVolumeError.concat({
        pvc: '',
        mountPath: ''
      })
    })
  }
  const removeVolumeHandler = (idx: number) => {
    if (idx === 0 && volume.dataVolume.length === 1) {
      // DOES NOTHING!!
    } else {
      const newArray1 = volume.dataVolume.concat([])
      const newArray2 = volume.dataVolumeError.concat([])
      newArray1.splice(idx, 1)
      newArray2.splice(idx, 1)
      setVolume({
        ...volume,
        dataVolume: newArray1,
        dataVolumeError: newArray2
      })
    }
  }

  //port
  const addPortHandler = () => {
    setPort({
      ...port,
      portMap: port.portMap.concat({
        autoFlag: false,
        internal: '',
        external: ''
      }),
      portError: port.portError.concat({
        autoFlag: false,
        internal: '',
        external: ''
      })
    })
  }
  const removePortHandler = (idx: number) => {
    if (idx === 0 && port.portMap.length === 1) {
      // DOES NOTHING!!
    } else {
      const newPortMap = port.portMap.concat([])
      const newPortError = port.portError.concat([])
      newPortMap.splice(idx, 1)
      newPortError.splice(idx, 1)
      setPort({
        ...port,
        portMap: newPortMap,
        portError: newPortError
      })
    }
  }

  const checkValidation = async (e: any) => {
    e.preventDefault()

    const newPortError = port.portError.concat([])

    let blankErrorFlag:boolean = false
    let validationErrorFlag:boolean = false

    let resourceGroupError:string = ''
    let jobNameError:string = ''
    let imageError:string = ''
    let resourceTypeError:string = ''
    let resourceBlockError:string = ''
    let resourceNodeErrorCpu:string = ''
    let resourceNodeErrorRam:string = ''


    let resourceBlock = state.gpuResourceBlockList[state.selectedResourceBlockKey as number]
    // let resourceBlock = state.rgroup === NODE_TYPE.CPU ? state.cpuResourceBlockList[state.selectedResourceBlockKey as number] : state.gpuResourceBlockList[state.selectedResourceBlockKey as number]
    //console.log('resourceBlock>', resourceBlock)
    let payload:IWorkspaceCreateRequest = {
      name: state.jobName,
      namespace: state.resourceGroup?.name || '',
      userNo: authInfo.userNo,
      detail: {
        image: state.image,
        reg: state.regUrl ? state.regUrl : '',
        imageDigest: state.imageId,
        rgroup: state.resourceGroup?.name || '',
        isJupyter: false,
        isJob: true,
        isMultiTraining: false, //멀티노드학습 관련 - 나중에 추가 예정
        command: command.command,
        argument: [],
        ports: port.portMap, //.concat([])
        environment: [],
        volumes: [], //volume.dataVolume,
        nodeList: (state.rgroup === NODE_TYPE.CPU ? cpuMaxState.availableCpuNodeList : (resourceBlock ? resourceBlock.nodeList : []))
      },
      resourceBlock: 
      state.rgroup === NODE_TYPE.CPU?
      {
        cpu: state.resourceNodeCpuQty ? state.resourceNodeCpuQty as number : 0,
        mem: state.resourceNodeRamSize ? state.resourceNodeRamSize as number: 0,
        memUnit: 'GiB',
        type: 'cpu-resource',
        realType: null,
        gpuMem: null,
        gpuMemUnit: null,
        gpuPer: null,
        resourceType: RESOURCE_TYPE.CPU,
        gpuNum: null
      } :
      {
        cpu: resourceBlock ? resourceBlock.resourceBlockSpec.cpu : 0,
        mem: resourceBlock ? resourceBlock.resourceBlockSpec.mem : 0,
        memUnit: resourceBlock ? resourceBlock.resourceBlockSpec.memUnit : 'GiB',
        type: resourceBlock ? resourceBlock.resourceBlockSpec.type : '',
        realType: resourceBlock ? resourceBlock.resourceBlockSpec.realType : '',
        gpuMem: resourceBlock ? resourceBlock.resourceBlockSpec.gpuMem : 0,
        gpuMemUnit: resourceBlock ? resourceBlock.resourceBlockSpec.gpuMemUnit : 'GB',
        gpuPer: resourceBlock ? resourceBlock.resourceBlockSpec.gpuPer : 0,
        resourceType: resourceBlock ? resourceBlock.resourceBlockSpec.resourceType : '',
        gpuNum: state.resourceBlockQty as number
      }
    }

    // console.log('payload>', payload)

    // 공통 - 리소스 그룹
    if (!state.resourceGroup){ //리소스 그룹 미선택시
      setState({
        ...state,
        resourceGroupError: '필수 선택 항목입니다.',
        resourceTypeError: '리소스 그룹을 먼저 선택해 주세요.'
      })
      popupController.confirm('리소스 그룹을 선택해 주세요.')
      return false
    }
    else {
      resourceGroupError = ''
    }
    
    // 공통 - 이름
    if (!payload.name) {
      jobNameError = '필수 입력 항목입니다.' //'소문자, 숫자, 하이픈(-)만 입력할 수 있으며, 이름의 시작과 끝에 (-)은 입력 불가합니다.', //'2~63자 이내의 영어 소문자, 숫자, 하이픈(-)만 사용 가능하며, 첫 글자는 영문만, 마지막 글자는 영문 혹은 숫자만 사용가능합니다.'
      blankErrorFlag = true
    }

    // Job - image
    if (!payload.detail.image) {
      imageError = '필수 입력 항목입니다.' //'필수 입력 항목입니다. 사용할 이미지를 선택해 주세요.'
      blankErrorFlag = true
    }

    // job - Command
    let commandError: string[] = []
    for (let key in payload.detail.command) {
      if (payload.detail.command[key].trim() === '') {
        commandError.push('필수 입력 항목입니다.')
        blankErrorFlag = true
      } else {
        commandError.push('')
      }
    }

    // job - Environment
    let environmentError: IEnvironment[] = []
      for (let key in environment.environment) {
        let eachenvironment = {
          key: environment.environment[key].key.trim(),
          value: environment.environment[key].value.trim()
        }
        if (eachenvironment.key === '' && eachenvironment.value !== '') {
          environmentError.push({ key: 'Value값 입력 시 Key값을 함께 입력해 주세요.', value: '' })
          blankErrorFlag = true
        } else if (eachenvironment.key !== '' && regExp.KEY_RULE.test(eachenvironment.key) === false) {
          environmentError.push({ key: '영어 대소문자, 숫자, 언더바(_), 닷(.), 하이픈(-)만 사용 가능하며, 첫 글자는 영대소문자만 사용 가능합니다.', value: '' })
          validationErrorFlag = true
        } else if (eachenvironment.key !== '' && eachenvironment.value === '') {
          environmentError.push({ key: '', value: 'Key값 입력 시 Value값을 함께 입력해 주세요.' })
          blankErrorFlag = true
        } else if (eachenvironment.key !== '' && eachenvironment.value !== '') {
          payload.detail.environment.push(eachenvironment)
          environmentError.push({ key: '', value: '' })
        } else {
          environmentError.push({ key: '', value: '' })
        }
      }

    // job - Argument
    for (let key in argument.argument) {
      let eachArgument = argument.argument[key]
      if (eachArgument !== ''){
        payload.detail.argument.push(eachArgument)
      }
    }

    // 공통 - 볼륨
    let dataVolumeError: IVolume[] = []
    for (let key in volume.dataVolume) {
      let eachVolume = {
        mountPath: volume.dataVolume[key].mountPath.trim(),
        pvc: volume.dataVolume[key].pvc.trim()
      }

      let eachVolError = { pvc: '', mountPath: '' }
      if (eachVolume.mountPath === '' && eachVolume.pvc !== '') {
        eachVolError.mountPath = '볼륨 선택 시 마운트 경로를 함께 입력해 주세요.'
        blankErrorFlag = true
      } else if (eachVolume.mountPath !== '' && eachVolume.pvc === '') {
        // eachVolError.pvc = '마운트 경로 입력 시 볼륨을 함께 입력해 주세요.'
        eachVolError.mountPath = '볼륨을 선택해 주세요.'
        blankErrorFlag = true
      } else if (eachVolume.mountPath !== '' && eachVolume.pvc !== '') {
        payload.detail.volumes.push(eachVolume)
        //dataVolumeError.push({ pvc: '', mountPath: '' })
      }
      // 중복 체크
      if (eachVolume.mountPath !== '') {
        let duplicatedCheck = volume.dataVolume.filter(element => element.mountPath === eachVolume.mountPath)
        if (duplicatedCheck.length > 1) {
          //dataVolumeError.push({ pvc: '', mountPath: '중복된 마운트 경로입니다. 경로를 다시 확인해 주세요.' })
          eachVolError.mountPath = '중복된 마운트 경로입니다. 경로를 다시 확인해 주세요.'
          validationErrorFlag = true
        }
      }
      dataVolumeError.push(eachVolError)
    }

    // 공통 - (1) GPU/CPU 리소스 블럭 - 구버전(cpu도 블록 정보가 있을 때)
    // if (state.selectedResourceBlockId === null || state.selectedResourceBlockKey === null) {
    //   blankErrorFlag = true
    // }

    // 공통 - 리소스 설정(리소스 타입 선택)을 안했을 경우
    if(state.rgroup === NODE_TYPE.NONE){
      resourceTypeError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    }

    // 공통 - (1) GPU 리소스 블럭
    if(state.rgroup === NODE_TYPE.GPU){
      //if((this.state.resourceBlockQty as string).trim() === ''){
      if((state.resourceBlockQty+'').trim() === ''){
        resourceBlockError = '필수 입력 항목입니다.'
        blankErrorFlag = true
      }
      else if(state.resourceBlockError !== ''){
        resourceBlockError = state.resourceBlockError
        validationErrorFlag = true
      }
    }
    // 공통 - (2) CPU 리소스
    else if (state.rgroup === NODE_TYPE.CPU) {
      if ((state.resourceNodeCpuQty + '').trim() === '') {
        resourceNodeErrorCpu = '필수 입력 항목입니다.'
        blankErrorFlag = true
      }
      //if((this.state.resourceNodeRamSize as string).trim() === '') {
      if ((state.resourceNodeRamSize + '').trim() === '') {
        resourceNodeErrorRam = '필수 입력 항목입니다.'
        blankErrorFlag = true
      }
      if (state.resourceNodeErrorCpu !== '' || state.resourceNodeErrorRam !== '') {
        resourceNodeErrorCpu = state.resourceNodeErrorCpu
        resourceNodeErrorRam = state.resourceNodeErrorRam
        validationErrorFlag = true
      }
    }
    

    // 공통 - 포트
    for (let idx in payload.detail.ports) {
      newPortError[Number(idx)].internal = ''
      newPortError[Number(idx)].external = ''

      let eachPort:IPortMap = payload.detail.ports[Number(idx)]
      if ((eachPort.internal === '' && eachPort.external !== '') || (eachPort.internal === '' && eachPort.external === '' && eachPort.autoFlag === true)) {
        // 내부포트 비어있을 때
        newPortError[Number(idx)].internal = '포트 주소를 입력해 주세요.'
        blankErrorFlag = true
      }
      if (isNaN(Number(eachPort.internal)) === true || (eachPort.internal !== '' && (Number(eachPort.internal) > 65535 || Number(eachPort.internal) < 1))) {
        // 내부포트 잘못된 값일 때
        newPortError[Number(idx)].internal = '65535 이하 숫자로 입력해 주세요.'
        validationErrorFlag = true
      }
      if (eachPort.external === '' && eachPort.autoFlag === false && eachPort.internal !== '') {
        // 외부포트 비어있을 때
        newPortError[Number(idx)].external = '포트 주소를 입력해 주세요.'
        blankErrorFlag = true
      }
      if (isNaN(Number(eachPort.external)) === true || (eachPort.external !== '' && (Number(eachPort.external) > 32767 || Number(eachPort.external) < 30000))) {
        // 외부포트 잘못된 값일 때
        newPortError[Number(idx)].external = '30000~32767 사이의 숫자로 입력해 주세요.'
        validationErrorFlag = true
      }
      if (eachPort.internal !== ''){
        //내부포트 중복 검사
        let internalCheck: any = payload.detail.ports.filter(e => Number(e.internal) === Number(eachPort.internal))
        if (internalCheck.length > 1 || Number(eachPort.internal) === 22) {
          newPortError[Number(idx)].internal = '사용 중인 내부포트입니다. 입력 값을 수정해 주세요.'
          validationErrorFlag = true
        }
      }
      if (eachPort.external !== ''){
        // 외부포트 내부 중복 검사
        let externalCheck: any = payload.detail.ports.filter(e => Number(e.external) === Number(eachPort.external))
        if (externalCheck.length > 1) {
          newPortError[Number(idx)].external = '사용 중인 외부포트입니다. 입력 값을 수정해 주세요.'
          validationErrorFlag = true
        }
      }
      if (eachPort.autoFlag === false && /* eachPort.internal !== '' && */ eachPort.external !== '') {
        //외부포트 외부 중복 검사
        const checkResult:boolean = await Workspace.checkDuplicatePort(Number(eachPort.external))
        if (checkResult === false) {
          newPortError[Number(idx)].external = '다른 워크스페이스 또는 Job에서 사용 중인 외부포트입니다. 입력 값을 수정해 주세요.'
          validationErrorFlag = true
        }
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    if (blankErrorFlag === true || validationErrorFlag === true) {
      setState({
        ...state,
        resourceGroupError: resourceGroupError,
        jobNameError: jobNameError,
        imageError: imageError,
        resourceTypeError: resourceTypeError,
        resourceBlockError: resourceBlockError,
        resourceNodeErrorCpu: resourceNodeErrorCpu,
        resourceNodeErrorRam: resourceNodeErrorRam,
      })
      setPort({
        ...port,
        portError: newPortError
      })
      setVolume({
        ...volume,
        dataVolumeError: dataVolumeError
      })
      setCommand({
        ...command,
        commandError: commandError
      })
      setEnvironment({
        ...environment,
        environmentError: environmentError
      })

      if (blankErrorFlag === true && validationErrorFlag === false) { // || this.state.selectedResourceBlockKey === null
        popupController.confirm('입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
        return false
      }
      else if (validationErrorFlag === true && blankErrorFlag === false) {
        popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
        return false
      }
      else if (blankErrorFlag === true && validationErrorFlag === true) {
        popupController.confirm('입력 값이 없거나 잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
        return false
      }
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    if (state.reCreateFlag === false) {
      if (state.duplicateNameCheckFlag === false) {
        popupController.confirm('Job 이름의 중복 여부를 확인해 주세요.')
        setState({
          ...state,
          resourceGroupError: resourceGroupError,
          jobNameError: 'Job 이름의 중복 확인을 해 주세요.',
          imageError: imageError,
          resourceTypeError: resourceTypeError,
          resourceBlockError: resourceBlockError,
          resourceNodeErrorCpu: resourceNodeErrorCpu,
          resourceNodeErrorRam: resourceNodeErrorRam,
        })
        setPort({
          ...port,
          portError: newPortError
        })
        return false
      }
    }

    // return false가 아닌 경우 마지막으로 저장
    setState({
      ...state,
      resourceGroupError: resourceGroupError,
      jobNameError: jobNameError,
      imageError: imageError,
      resourceTypeError: resourceTypeError,
      resourceBlockError: resourceBlockError,
      resourceNodeErrorCpu: resourceNodeErrorCpu,
      resourceNodeErrorRam: resourceNodeErrorRam,
      validCheckedData: payload
    })
    setPort({
      ...port,
      portError: newPortError
    })
    
    modalHandler(payload)
  }

  const modalHandler = (payload?: any) => {
    let message = ''
    const resourceLimit = payload ? payload.resourceLimit : state.resourceLimit
    if(resourceLimit && resourceLimit.isAlwaysPending === true){ //사용x -리소스 사용제한
      message = `${state.jobName} 사용 신청 시 관리자 확인 후 대기열에 추가됩니다. 진행하시겠습니까?`
    }
    else {
      message = `${state.jobName} 을(를) 신청합니다. 진행하시겠습니까?`
    }
    popupController.dialouge(message, 'submitjob', 'submitjob', '확인', '취소')
  }

  const modalActionHandler = async (e: ModalEvent) => {
    switch (e.payload.action) {
      case 'cancelForm':
        navigate('/')
        break
      case 'submitjob':
        submitHandler()
        break
    }
  }

  const submitHandler = async () => {
    let payload:any = JSON.parse(JSON.stringify(stateRef.current.validCheckedData))

    /////////////////////////////////////////////////////////////////////////////
    // (공통) port 빈 배열 제거
    const portsMap = []
    for (let key in payload.detail.ports) {
      let eachPortMap = payload.detail.ports[key]
      if (eachPortMap.autoFlag === true) {
        eachPortMap.external = 0
      }
      if (eachPortMap.internal === '' && eachPortMap.external === '') {
        // DOES NOTHING
      } else {
        portsMap.push({
          autoFlag:eachPortMap.autoFlag,
          internal:Number(eachPortMap.internal),
          external:Number(eachPortMap.external)
        })
      }
    }
    payload.detail.ports = portsMap
    /////////////////////////////////////////////////////////////////////////////

    try{
      if (stateRef.current.reCreateFlag === false) {
        await Workspace.create(payload)
      } else {
        await Workspace.reCreate(stateRef.current.id || -1, payload)
      }

      // Job 생성 후 처리들

      // 토스트 메시지 표출
      let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
      let message = `${payload.name} 의 사용을 신청하였습니다.`
      toastEvent.payload = { message: message }
      window.dispatchEvent(toastEvent)

      //리소스 제한 정보 불러오기
      // let reloadLimitEvent = new PageEvent(PageEvent.REFRESH_LIMIT)
      // window.dispatchEvent(reloadLimitEvent)

      // 목록 화면으로 이동
      navigate('/workspaces')
    }
    catch(e){
      if((e as Error).message === ERROR_TYPE.DUPLICATED){
        setState({
          ...state,
          jobNameError: '사용 중인 Job 이름입니다. 이름을 수정해 주세요.'
        })
        popupController.confirm('사용 중인 Job 이름입니다. 이름을 수정해 주세요.')
        return
      }
      else {
        popupController.confirm('에러가 발생했습니다.')
        return
      }
    }
  }

  const cancelHandler = () => {
    // popupController.dialouge(`${state.jobName} 이(가) 취소됩니다. 진행하시겠습니까?`, 'cancelForm', 'cancelForm', '확인', '취소')
    popupController.dialouge(`Job 신청을 취소합니다. 진행하시겠습니까?`, 'cancelForm', 'cancelForm', '확인', '취소')
  }

  const checkDuplicateHandler = async (callbackPopupFlag: boolean = true) => {
    if (((state.jobNameError && state.jobNameError !== 'Job 이름의 중복 확인을 해 주세요.') || !state.jobName || !regExp.WS_NAME_RULE.test(state.jobName))) {
      return false
    }

    const result:boolean = await Workspace.checkDuplicateName(state.jobName, state.resourceGroup? state.resourceGroup.name : '')
    if (result === false) {
      setState({
        ...state,
        duplicateNameCheckFlag: false,
        jobNameError: '사용 중인 Job 이름입니다. 이름을 수정해 주세요.',
      })
      return false
    } else {
      if (callbackPopupFlag === true) {
        setState({
          ...state,
          duplicateNameCheckFlag: true,
          jobNameError: '',
        })
        popupController.confirm('사용 가능한 이름입니다.')
      }
      return true
    }
  }

  const imageSelectPopupHandler = (e: any) => {
    e.preventDefault()
    popupController.image(
      (value:any, reg:string) => {
        setState({
          ...state,
          imageError: '',
          image: value.rep + ':' + value.tag,
          imageId: value.id,
          regUrl: reg
        })
      },
      false, //jupyterflag
      state.isCommon ? authInfo?.id : state.resourceGroup?.name || '',
      state.regUrl,
      state.image,
      state.isCommon
    )
  }

  const volumeSelectPopupHandler = (e:any, idx: number) => {
    e.preventDefault()
    popupController.volume(
      (value:any) => {
        let volumes = volume.dataVolume
        volumes[idx].pvc = value ? value.name : ''
        setVolume({
          ...volume,
          dataVolume: volumes
        })
      },
      idx,
      state.resourceGroup?.name || '',
      volume.dataVolume[idx].pvc
    )
  }

  const inputHandler = (target: string, value: any, idx: number = 0) => {
    const newPortMap = port.portMap.concat([])
    const newPortError = port.portError.concat([])

    const newEnvironment = environment.environment.concat([])
    const newEnvironmentError = environment.environmentError.concat([])
    
    //const newEnvironment = JSON.parse(JSON.stringify(state.environment.concat([]))) //왜인지는 모르겠지만 readonly라고 떠서 수정 불가능 오류 -> 깊은복사로 바꿔줌
    //const newEnvironmentError = JSON.parse(JSON.stringify(state.environmentError.concat([]))) //왜인지는 모르겠지만 readonly라고 떠서 수정 불가능 오류 -> 깊은복사로 바꿔줌

    let isError:boolean = false
    let errorText:string = ''

    let error:boolean = false
    switch (target) {
      case 'jobName':
        error = value === '' ? false : !regExp.WS_NAME_RULE.test(value)
        setState({
          ...state,
          jobName: value,
          jobNameError: error ? '소문자, 숫자, 하이픈(-)만 입력할 수 있으며, 이름의 시작과 끝에 (-)은 입력 불가합니다.' : '',
          duplicateNameCheckFlag: false
        })
        break
      case 'internal':
        if (value !== '' && (isNaN(Number(value)) === true || value > 65535 || value < 1)) {
          newPortError[idx].internal = '65535 이하 숫자로 입력해 주세요.'
        } else {
          newPortError[idx].internal = ''
        }
        newPortMap[idx].internal = String(value)
        setPort({
          ...port,
          portMap: newPortMap,
          portError: newPortError
        })
        break
      case 'external':
        if (value !== '' && (isNaN(Number(value)) === true || value > 32767 || value < 30000)) {
          newPortError[idx].external = '30000~32767 사이의 숫자로 입력해 주세요.'
        } else {
          newPortError[idx].external = ''
        }
        newPortMap[idx].external = String(value)
        setPort({
          ...port,
          portMap: newPortMap,
          portError: newPortError
        })
        break
      case 'autoFlag':
        newPortMap[idx].external = ''
        newPortError[idx].external = ''
        newPortMap[idx].autoFlag = value
        setPort({
          ...port,
          portMap: newPortMap,
          portError: newPortError
        })
        break
      case 'resourceBlockQty':
        isError = false
        errorText = ''
        value = value.trim() //스페이스바가 문자로 인식이 안돼서... 스페이스바 사전 차단!
        if (value !== ''){
          if (isNaN(value) === true){
            isError = true
            errorText = '숫자만 입력 가능합니다.'
          }
          else if (!Number.isInteger(Number(value))){
            isError = true
            errorText = '정수만 입력 가능합니다.'
          }
          else if (Number(value) < 1){
            isError = true
            errorText = '1 이상만 입력 가능합니다.'
          }
          else if (state.selectedResourceBlockKey !== null && (state.gpuResourceBlockList[state.selectedResourceBlockKey].maxNumBlock || 0) < value){
            isError = true
            errorText = `${state.gpuResourceBlockList[state.selectedResourceBlockKey].maxNumBlock} 개 이하로 입력해 주세요.`
          }  
          // else if (this.state.resourceLimit.maxGpu.isOn && this.state.resourceLimit.usedGpu + Number(value) > this.state.resourceLimit.maxGpu.resource) {
          //   isError = true
          //   errorText = '리소스 사용 제한의 신청 가능한 GPU 리소스 블록 개수 이하로 입력해 주세요.'
          // }
        }
        setState({
          ...state,
          resourceBlockQty: value,
          resourceBlockError: errorText
        })
        break
      case 'resourceNodeCpuQty':
        isError = false
        errorText = ''
        value = value.trim() //스페이스바가 문자로 인식이 안돼서... 스페이스바 사전 차단!
        if (value !== '') {
          if (isNaN(value) === true) {
            isError = true
            errorText = '숫자, 소수점만 입력 가능합니다.'
          }
          else if (Number(value) <= 0) {
            isError = true
            errorText = '0을 초과하여 입력해 주세요.'
          }
          else if (value.includes('.') && value.substring(value.indexOf('.') + 1).length > 2) {
            isError = true
            errorText = '소수점 2자리까지 입력 가능합니다.'
          }
          else {
            if(Number(value) > cpuMaxState.cpuMaxCore) {
              isError = true
              errorText = `${cpuMaxState.cpuMaxCore} 코어 이하로 입력해 주세요.`
            }
            else {
              onChangeCpuNodeList(value, Number(state.resourceNodeRamSize))
            }
          }
        }
        setState({
          ...state,
          resourceNodeCpuQty: value,
          resourceNodeErrorCpu: errorText
        })
        break
      case 'resourceNodeRamSize':
        isError = false
        errorText = ''
        value = value.trim() //스페이스바가 문자로 인식이 안돼서... 스페이스바 사전 차단!
        if (value !== '') {
          if (isNaN(value) === true) {
            isError = true
            errorText = '숫자, 소수점만 입력 가능합니다.'
          }
          else if (value && Number(value) <= 0) {
            isError = true
            errorText = '0을 초과하여 입력해 주세요.'
          }
          else if (value.includes('.') && value.substring(value.indexOf('.') + 1).length > 2) {
            isError = true
            errorText = '소수점 2자리까지 입력 가능합니다.'
          }
          else {
            if(Number(value) > cpuMaxState.memMaxGiB) {
              isError = true
              errorText = `${cpuMaxState.memMaxGiB} GiB 이하로 입력해 주세요.`
            }
            else {
              onChangeCpuNodeList(Number(state.resourceNodeCpuQty), value)
            }
          }
        }
        setState({
          ...state,
          resourceNodeRamSize: value,
          resourceNodeErrorRam: errorText
        })
        break
      case 'image':
        setState({
          ...state,
          image: value,
          imageError: ''
        })
        break
      case 'mountPath':
        let volumes = volume.dataVolume
        volumes[idx].mountPath = value
        setVolume({
          ...volume,
          dataVolume: volumes
        })
        break
      case 'command':
        const newCommand = command.command.concat([])
        newCommand[idx] = String(value)
        const newCommandError = command.commandError.concat([])
        newCommandError[idx] = ''
        setCommand({
          command: newCommand,
          commandError: newCommandError
        })
        break
      case 'environmentKey':
        error = value === '' ? false : !regExp.KEY_RULE.test(value)
        newEnvironmentError[idx].key = error ? '영어 대소문자, 숫자, 언더바(_), 닷(.), 하이픈(-)만 사용 가능하며, 첫 글자는 영대소문자만 사용 가능합니다.' : ''
        newEnvironment[idx].key = String(value)
        setEnvironment({
          environment: newEnvironment,
          environmentError: newEnvironmentError
        })
        break
      case 'environmentValue':
        newEnvironment[idx].value = String(value)
        newEnvironmentError[idx].value = ''
        setEnvironment({
          environment: newEnvironment,
          environmentError: newEnvironmentError
        })
        break
      case 'argument':
        const newArgument = argument.argument.concat([])
        newArgument[idx] = String(value)
        setArgument({
          argument: newArgument
        })
        break
    }
  }

  const onChangeSelectRGroup = async (data:ISelectOption) => {
    // 리소스 그룹 설정 결과 반영
    const resourceGroup = state.resourceGroupList[Number(data.value)]
    if (resourceGroup) {

      // 리소스 설정 selectBox 세팅
      const resourceBlockList = await ResourceGroup.getResourceBlockInfo(resourceGroup.name)
      const resourceTypeSelectList:ISelectOption[] = []
      const resourceType:ISelectOption = {label: '', value: ''}
      if(resourceBlockList.gpuResourceBlockList.length > 0) {
        resourceTypeSelectList.push({label: 'GPU 리소스 블록 타입', value: NODE_TYPE.GPU})
      }
      if(resourceBlockList.cpuResourceBlockList.length > 0) {
        resourceTypeSelectList.push({label: 'CPU 리소스 타입', value: NODE_TYPE.CPU})
      }
      // gpu나 cpu 중 한 가지만 있을 때는 해당 타입으로 자동 고정
      if(resourceTypeSelectList.length === 1) {
        resourceType.label = resourceTypeSelectList[0].label
        resourceType.value = resourceTypeSelectList[0].value
      }

      // 리소스 설정 cpu 관련 Max값 세팅
      let cpuMaxCore = -1, memMaxGiB = -1, cpuMinCore = 99999, memMinGiB = 99999
      if(resourceBlockList.cpuResourceBlockList.length > 0) {
        for(let eachCpu of resourceBlockList.cpuResourceBlockList) {
          if(eachCpu.resourceBlockSpec.cpu > cpuMaxCore) {
            cpuMaxCore = eachCpu.resourceBlockSpec.cpu
          }
          if(eachCpu.resourceBlockSpec.cpu < cpuMinCore) {
            cpuMinCore = eachCpu.resourceBlockSpec.cpu
          }

          if(eachCpu.resourceBlockSpec.mem > memMaxGiB) {
            memMaxGiB = eachCpu.resourceBlockSpec.mem
          }
          if(eachCpu.resourceBlockSpec.mem < memMinGiB) {
            memMinGiB = eachCpu.resourceBlockSpec.mem
          }
        }
      }
  
      setState({
        ...state,

        // 기본 리소스그룹 여부
        isCommon: resourceGroup.isCommon,

        // Job 이름 초기화
        jobName: '',
        jobNameError: '',
        duplicateNameCheckFlag: false,

        // 리소스 그룹 관련 세팅
        resourceGroup: resourceGroup,
        cpuResourceBlockList: resourceBlockList.cpuResourceBlockList,
        gpuResourceBlockList: resourceBlockList.gpuResourceBlockList,
        resourceTypeSelectList: resourceTypeSelectList,
        resourceType: resourceType,

        // 리소스 세팅 초기화
        rgroup: NODE_TYPE.NONE, //(리소스 타입 - cpu, gpu 정도만 표기)
        resourceGroupError: '', // 리소스 그룹 에러 메시지
        resourceBlockList: [], // 리소스 블록 리스트
        resourceTypeError: '', // 리소스 타입 에러 메시지
        //공통-GPU리소스
        selectedResourceBlockKey: null,
        selectedResourceBlockId: null,
        resourceBlockQty: '',
        resourceBlockError: '',
        //공통-CPU리소스
        resourceNodeCpuQty: '',
        resourceNodeRamSize: '',
        resourceNodeErrorCpu: '',
        resourceNodeErrorRam: '',

        // 이미지 초기화
        image: '',
        imageId: '',
        imageError: '',
        regUrl: '',
      })

      // 볼륨 초기화
      setVolume({
        dataVolume: [{ 
          pvc: '',
          mountPath: ''
        }],
        dataVolumeError: [{ 
          pvc: '',
          mountPath: ''
        }]
      })
      
      // 리소스 설정 cpu 관련 Max값 세팅
      setcpuMaxState({
        ...cpuMaxState,
        cpuMaxCore: cpuMaxCore,
        memMaxGiB: memMaxGiB,
        cpuMinCore: cpuMinCore,
        memMinGiB: memMinGiB
      })
    }
  }

  const onChangeCpuNodeList = (cpu:number, mem:number):any[] => {
    let availableList:any[] = []
    let cpuMaxCore:number = cpuMaxState.cpuMinCore, memMaxGiB = cpuMaxState.memMinGiB
    for(let eachCpu of state.cpuResourceBlockList) {
      if(eachCpu.resourceBlockSpec.cpu >= cpu && eachCpu.resourceBlockSpec.mem >= mem){
        availableList.push(eachCpu.nodeName)
        if(eachCpu.resourceBlockSpec.cpu > cpuMaxCore) { cpuMaxCore = eachCpu.resourceBlockSpec.cpu }
        if(eachCpu.resourceBlockSpec.mem > memMaxGiB) { memMaxGiB = eachCpu.resourceBlockSpec.mem }
      }
    }
    
    setcpuMaxState({
      ...cpuMaxState,
      cpuMaxCore: cpuMaxCore,
      memMaxGiB: memMaxGiB,
      availableCpuNodeList: availableList
    })
    return availableList
  }

  const onChangeSelectResourceType = (data:ISelectOption) => {
    setState({
      ...state,
      rgroup: data.value,
      resourceTypeError: ''
    })
  }

  return (
    <JobFormFragment>
      <div className="titleArea">
        {state.reCreateFlag === true ?
          <h2 className='pageTitle'>
            Job 재신청
            {/* <Tooltip des="멀티 노드 사용이 필요한 경우는 여러 개의 워크스페이스를 신청해 주세요" /> */}
          </h2> :
          <h2 className='pageTitle'>
            Job 신청
            {/* <Tooltip des="멀티 노드 사용이 필요한 경우는 여러 개의 워크스페이스를 신청해 주세요" /> */}
          </h2>
        }
      </div>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault(); }}>
        <div className="formGroup">
          <label className="label">리소스 그룹 설정 *</label>
          <div /* className="inputWrap" */ style={{ width: '578px' }}>
            <Select selectRef={selectRGroupRef} option={state.resourceGroupSelectList} placeholder="리소스 그룹을 선택해 주세요." disabled={state.reCreateFlag}
              selected={ state.reCreateFlag && state.resourceGroup ? state.resourceGroupSelectList.find(element => element.label === state.resourceGroup?.name) : undefined } 
              onChange={onChangeSelectRGroup}></Select>
            {state.resourceGroupError ? <p className="message">{state.resourceGroupError}</p> : false}

            {state.resourceGroup ? <div className="resPreview">
                <p>노드 (IP/이름)</p>
                <div className="resPreviewWrap">
                  { state.resourceGroup.nodeList.length > 0 ? 
                  <>
                    { state.resourceGroup.nodeList.map((value:IResourceNode, idx:number) => {
                      return (<p key={idx}>{value.ip+" / "+value.name}</p>)
                    }) }
                  </>
                  : '-'}
                </div>
                <p>볼륨</p>
                <div className="resPreviewWrap">
                  { state.resourceGroup.volumeList.length > 0 ? 
                  <>
                    { state.resourceGroup.volumeList.map((value:IResourceVolume, idx:number) => {
                      return (<p key={idx}>{value.size+" / "+value.name}</p>)
                    }) }
                  </>
                  : '-'}
                </div>
                <p>이미지 레지스트리</p>
                <div className="resPreviewWrap">
                  {state.resourceGroup.reg ? <p>{state.resourceGroup.reg}</p> : '-' }
                </div>
              </div> : false }
          </div>
        </div>
        <div className="divider"></div>

        <div className="formGroup">
          <label className="label">Job 이름 *</label>
          <div className="inputWrap">
            <div className="inputBox">
              <div style={{ width: '578px' }}>
                <InputBox id="jobName" type={TEXT_INPUT_TYPE.TEXT} maxLength={state.reCreateFlag ? undefined : 63} value={state.jobName} readonly={state.reCreateFlag}
                  placeholder="소문자, 숫자, (-)만 사용 가능, 이름의 시작/끝에 (-) 입력 불가 합니다."
                  warning={state.jobNameError} error={state.jobNameError !== ''} onChange={inputHandler} />
              </div>
              <button className={"btn outline " + (state.reCreateFlag === true || !state.resourceGroup || state.jobName.length === 0 ? 'disabled' : '')} onClick={() => checkDuplicateHandler()}>중복 확인</button>
            </div>
          </div>
        </div>

        <div className="formGroup">
          <label className="label">이미지 설정
            {/* <Tooltip des="사용 여부를 ON하시면 주피터 노트북과 텐서보드를 웹으로 사용할 수 있습니다." /> */}
          </label>
          <div className="inputWrap">
            <div>
              <div className="imageTypeLabel inputBoxTitle" style={{margin:'0 0 15px'}}>
                <p>이미지 선택 *</p>
              </div>
              <div className="inputBox">
                <div style={{ width: '578px' }}>
                  <InputBox id="image" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.image}
                    placeholder="사용할 이미지를 가져오세요." warning={state.imageError} />
                </div>
                <button className={state.resourceGroup ? 'btn outline' : 'btn outline disabled'} onClick={imageSelectPopupHandler}>가져오기</button>
              </div>
            </div>

            <div>
              <div className="imageTypeLabel">
                <p>Command *</p>
              </div>
              {command.command.map((eachCommand: string, idx: number) => {
                /* eslint-disable */
                return (
                  <div className="inputBox" key={idx}>
                    <div style={{ width: '578px' }}>
                      <InputBox id="command" type={TEXT_INPUT_TYPE.TEXT} value={eachCommand} onChange={inputHandler} idx={idx}
                        placeholder="해당 서비스 실행을 위한 커맨드를 입력해 주세요."
                        warning={command.commandError[idx]} />
                    </div>
                    <div className="addBtnWrap">
                    {
                      command.command.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removeCommandHandler(idx)} />
                    }
                    {
                      idx === command.command.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addCommandHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                    </div>
                  </div>)
              })}
            </div>

            <div>
              <div className="imageTypeLabel">
                <p>Environment</p>
              </div>
              {environment.environment.map((eachenvironment: IEnvironment, idx: number) => {
                return (
                  <div className="inputBox inputBoxEnvironment" key={idx}>
                    <div className="inputSideWrap">
                      <div className="inputSideBox">
                        <div>
                          <InputBox id="environmentKey" type={TEXT_INPUT_TYPE.TEXT} value={eachenvironment.key} onChange={inputHandler} idx={idx}
                            placeholder="Key 값을 입력해 주세요." warning={environment.environmentError[idx].key} />
                        </div>
                        <img src='/images/sign-equal.png' alt='는' />
                        <div>
                          <InputBox id="environmentValue" type={TEXT_INPUT_TYPE.TEXT} value={eachenvironment.value} onChange={inputHandler} idx={idx}
                            placeholder="Value 값을 입력해 주세요." warning={environment.environmentError[idx].value} />
                        </div>
                      </div>
                    </div>
                    <div className="addBtnWrap">
                    {
                      environment.environment.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removeEnvironmentHandler(idx)} />
                    }
                    {
                      idx === environment.environment.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addEnvironmentHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                    </div>
                  </div>)
              })}
            </div>
            <div>
              <div className="imageTypeLabel">
                <p>Argument</p>
              </div>
              {argument.argument.map((eachArgument: string, idx: number) => {
                return (
                  <div className="inputBox" key={idx}>
                    <div style={{ width: '578px' }}>
                      <InputBox id="argument" type={TEXT_INPUT_TYPE.TEXT} value={eachArgument} onChange={inputHandler} idx={idx}
                        placeholder="해당 서비스 실행에 필요한 argument를 입력해 주세요."/>
                    </div>
                    <div className="addBtnWrap">
                    {
                      argument.argument.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removeArgumentHandler(idx)} />
                    }
                    {
                      idx === argument.argument.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addArgumentHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                    </div>
                  </div>)
              })}
            </div>
          </div>
        </div>

        <div className="formGroup">
          <label className="label">데이터 볼륨 설정
            {/* <Tooltip des="☆ 데이터 볼륨 설정 툴팁" /> */}
          </label>
          <div className="inputWrap">
          {volume.dataVolume.map((eachVolume: IVolume, idx: number) => {
              return (
                <div className="inputBox dataVolBox" key={idx}>
                  <div className="innerGap">
                    <span>볼륨</span>
                    <div>
                      <InputBox id="volumn" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={eachVolume.pvc} idx={idx}
                        placeholder="사용할 볼륨을 선택해 주세요." warning={volume.dataVolumeError[idx].pvc}
                        cancelSelect={()=>{
                          let volumes = volume.dataVolume
                          volumes[idx].pvc = ''
                          setVolume({
                            ...volume,
                            dataVolume: volumes
                          })
                        }} />
                    </div>
                    <button className={state.resourceGroup ? 'btn outline' : 'btn outline disabled'}
                      onClick={(e) => { volumeSelectPopupHandler(e, idx) }}>가져오기</button>
                  </div>
                  <div className="innerGap">
                    <span>마운트 경로</span>
                    <div>
                      <InputBox id="mountPath" type={TEXT_INPUT_TYPE.TEXT} value={volume.dataVolume[idx].mountPath} idx={idx}
                        placeholder="볼륨과 마운트 할 경로를 입력하세요." warning={volume.dataVolumeError[idx].mountPath} onChange={inputHandler} />
                    </div>
                  </div>
                  <div className="addBtnWrap">
                    {
                      volume.dataVolume.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removeVolumeHandler(idx)} />
                    }
                    {
                      idx === volume.dataVolume.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addVolumeHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                  </div>
                </div>)
            })}
          </div>
        </div>

        {/* 공통 */}
        <div className="formGroup">
          <label htmlFor="name" className="label">리소스 설정 *
            {/* {this.state.rgroup !== NODE_TYPE.NONE && this.state.resourceLimit !== null?
              <div style={{marginTop:'15.5px'}}>
                <h3 style={{marginBottom:'7px'}}>리소스 사용 제한</h3>
                <ul className="resourceLimitList">
                  {this.state.rgroup === NODE_TYPE.GPU?
                  <li>
                    총 사용 가능 GPU 리소스 블록 개수
                    {this.state.resourceLimit.maxGpu.isOn === true?
                    <div>ㄴ총 {Utils.numberMark(this.state.resourceLimit.maxGpu.resource)}개 중 {Utils.numberMark(this.state.resourceLimit.usedGpu)}개 사용</div>:
                    <div className="gray">ㄴ설정 없음</div>}
                  </li>: false}
                  <li>
                    자동 회수 시간
                    {this.state.resourceLimit.autoReturnTime.isOn === true?
                    <div>ㄴ생성 시간 기준 {Utils.numberMark(this.state.resourceLimit.autoReturnTime.resource)}시간 후 자동 회수</div>:
                    <div className="gray">ㄴ설정 없음</div>}
                  </li>
                  <li>
                    워크스페이스/Job 신청 시 대기열 포함 여부
                    {this.state.resourceLimit.isAlwaysPending === true?
                    <div>ㄴ관리자 확인 후 대기열에 포함</div>:
                    <div className="gray">ㄴ자동 대기열 포함</div>}
                  </li>
                </ul>
              </div>
              :
              false
            } */}
          </label>
          <div className="inputWrap">
          <div style={{ width: '578px', marginBottom: '30px' }}>
              <Select selectRef={selectResourceRef} option={state.resourceTypeSelectList} placeholder="리소스 타입을 선택해 주세요." disabled={state.resourceTypeSelectList.length === 1 || state.resourceGroup === undefined}
                selected={state.resourceTypeSelectList.length === 1 ? state.resourceTypeSelectList[0] : undefined} 
                onChange={onChangeSelectResourceType} />
              {state.resourceTypeError ? <p className="message">{state.resourceTypeError}</p> : false}
            </div>
            {state.rgroup === NODE_TYPE.NONE ? //리소스 그룹 미설정 시
              false
              : // 리소스 그룹 설정시
              <>
                {state.rgroup === NODE_TYPE.GPU ? <> {/* gpu */}
                  <div className="inputBox resourceBoxGroup">
                    {state.gpuResourceBlockList.map((eachResourceBlock: IResourceUnit, idx: number) => {
                      return (<ResourceBlock data={eachResourceBlock} selected={state.selectedResourceBlockKey === idx} index={idx} key={idx} type={state.rgroup === NODE_TYPE.CPU ? ResourceUnitType.BLOCKTYPE_CPU : ResourceUnitType.BLOCKTYPE_DEV}
                        onSelect={(id: number, checked: boolean) => {
                          if (checked === true) {
                            setState({
                              ...state,
                              //gpu
                              resourceBlockQty: '',
                              resourceBlockError: '',
                              //cpu
                              resourceNodeCpuQty: '',
                              resourceNodeRamSize: '',
                              resourceNodeErrorCpu: '',
                              resourceNodeErrorRam: '',
                              //공통
                              selectedResourceBlockKey: idx,
                              selectedResourceBlockId: id
                            })
                          } else {
                            setState({
                              ...state,
                              //gpu
                              resourceBlockQty: '',
                              resourceBlockError: '',
                              //cpu
                              resourceNodeCpuQty: '',
                              resourceNodeRamSize: '',
                              resourceNodeErrorCpu: '',
                              resourceNodeErrorRam: '',
                              //공통
                              selectedResourceBlockKey: null,
                              selectedResourceBlockId: null
                            })
                          }
                        }} />)
                    })}
                  </div>
                  <div className="inputWrap">
                    <div className="inputBox inputBoxTitle" style={{ alignItems: 'start', gap: '0px' }}>
                      <p style={{ marginTop: '10px' }}>블록 개수</p> {/* <Tooltip des="블록 개수는 블록 타입 선택 후 신청 가능한 최대 개수 까지만 입력 가능합니다." /> */}
                      <div className="inputSmall" style={{ margin: '0 5px 0 10px' }}>
                        <InputBox id="resourceBlockQty" type={TEXT_INPUT_TYPE.TEXT} readonly={state.selectedResourceBlockId === null} onChange={inputHandler}
                          warning={state.resourceBlockError} value={state.resourceBlockQty} />
                        {/* <input className="input" type="text" placeholder="" disabled={state.selectedResourceBlockId === null} value={state.resourceBlockQty} onChange={(e) => { inputHandler('resourceBlockQty', e.target.value) }} />
                          {state.resourceBlockError ? <p className="message">{state.resourceBlockError}</p> : false} */}
                      </div>
                      <p style={{ marginTop: '10px' }}>개</p>
                    </div>
                  </div>
                  <div className="inputBox">
                    <div className="resourceErrorBox">
                      {state.selectedResourceBlockKey !== null ?
                        <>
                          <p className="resourceErrorBoxDetail">선택한 블록 타입은 <span className="redPoint">최대 {state.gpuResourceBlockList[state.selectedResourceBlockKey].maxNumBlock}개</span> 까지 신청 가능합니다.</p>
                          {state.gpuResourceBlockList[state.selectedResourceBlockKey].allocatableNumBlock as number > 0 ?
                            <p className="resourceErrorBoxDetail">└ 선택한 블록 타입으로 바로 할당이 가능한 블록 개수는 <span className="redPoint">{state.gpuResourceBlockList[state.selectedResourceBlockKey].allocatableNumBlock}개</span>이나, 경우에 따라 대기가 발생할 수 있습니다.</p> :
                            false
                          }
                        </> :
                        <p className="resourceErrorBoxDetail redPoint">리소스 블록 타입을 먼저 선택해 주세요.</p>
                      }
                    </div>
                  </div>
                </> : <> {/* cpu */}
                <div className="inputWrap" style={{ display: 'flex', gap: '30px' }}>
                    <div>
                      <div className="inputBox inputBoxTitle" style={{ gap: '0px' }}>
                        <p>CPU</p> {/* <Tooltip des="선택한 노드의 CPU를 최대 개수 이하로 신청하세요. (소수점 두자리까지 입력 가능)" /> */}
                        <div className="inputSmall" style={{ margin: '0 5px 0 10px' }}>
                          <InputBox id="resourceNodeCpuQty" type={TEXT_INPUT_TYPE.TEXT} /* readonly={state.selectedResourceBlockId === null} */ onChange={inputHandler}
                            warning={state.resourceNodeErrorCpu} placeholder={state.resourceNodeRamSize ? `최대 ${cpuMaxState.cpuMaxCore}` : ''} />
                        </div>
                        <p>코어</p>
                      </div>
                    </div>
                    <div>
                      <div className="inputBox inputBoxTitle" style={{ gap: '0px' }}>
                        <p>Memory</p> {/* <Tooltip des="선택한 노드의 RAM을 최대 용량 이하로 신청하세요. (소수점 두자리까지 입력 가능)" /> */}
                        <div className="inputSmall" style={{ margin: '0 5px 0 10px' }}>
                          <InputBox id="resourceNodeRamSize" type={TEXT_INPUT_TYPE.TEXT} /* readonly={state.selectedResourceBlockId === null} */ onChange={inputHandler}
                            warning={state.resourceNodeErrorRam} placeholder={state.resourceNodeCpuQty ? `최대 ${cpuMaxState.memMaxGiB}` : ''} />
                        </div>
                        <p>GiB</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="inputBox">
                    <div className="resourceErrorBox">
                      {state.selectedResourceBlockKey !== null ?
                        <>{state.resourceBlockList[state.selectedResourceBlockKey].freeCpu as number > 0 && state.resourceBlockList[state.selectedResourceBlockKey].freeMem as number > 0 ?
                          <p className="resourceErrorBoxDetail">선택한 노드의 바로 할당이 가능한 CPU 개수는 <span className="redPoint">{Number(state.resourceBlockList[state.selectedResourceBlockKey].freeCpu?.toFixed(2))}개 </span>
                            그리고 RAM은 <span className="redPoint">{Number(state.resourceBlockList[state.selectedResourceBlockKey].freeMem?.toFixed(2))}{state.resourceBlockList[state.selectedResourceBlockKey].resourceBlockSpec.memUnit}</span> 이나 경우에 따라 대기가 발생할 수 있습니다.</p> :
                          <p className="resourceErrorBoxDetail">선택한 노드의 CPU 개수 최대 <span className="redPoint">{state.resourceBlockList[state.selectedResourceBlockKey].resourceBlockSpec.cpu}개 </span>
                            그리고 RAM은 최대 <span className="redPoint">{state.resourceBlockList[state.selectedResourceBlockKey].resourceBlockSpec.mem}{state.resourceBlockList[state.selectedResourceBlockKey].resourceBlockSpec.memUnit}</span> 까지 신청 가능합니다.</p>
                        }</> :
                        <p className="resourceErrorBoxDetail redPoint">리소스 타입을 먼저 선택해 주세요.</p>
                      }
                    </div>
                  </div> */}
                </>}
              </>
            }
          </div>
        </div>
        <div className="formGroup port">
          <label htmlFor="name" className="label">
            포트 설정
            {/* <Tooltip des="내부 포트 입력 값의 범위는 리눅스 포트 범위를 기준으로 65535 이하 숫자만 입력 가능하며,<br/>외부 포트 입력 값의 범위는 쿠버네티스 포트 범위를 기준으로 30000-32767 사이 숫자만 입력 가능합니다. " /> */}
          </label>
          <div className="inputWrap">
            {port.portMap.map((eachPort: IPortMap, idx: number) => {
              return (
                <div className="inputBox portWrap innerLabel" key={idx} style={{ alignItems: 'start' }}>
                  <div className="innerGap">
                    <span>내부</span>
                    <div className="portBox">
                      <InputBox id="internal" type={TEXT_INPUT_TYPE.TEXT} onChange={inputHandler} idx={idx}
                        value={eachPort.internal} warning={port.portError[idx].internal + ''} />
                    </div>
                  </div>
                  <div className="innerGap">
                    <span>외부</span>
                    <div className="portBox">
                      <InputBox id="external" type={TEXT_INPUT_TYPE.TEXT} readonly={eachPort.autoFlag} onChange={inputHandler} idx={idx}
                        value={eachPort.external} warning={port.portError[idx].external + ''} />
                    </div>
                    <span>자동 할당</span>
                    <div style={{ marginTop: '3px', marginLeft: '-5px' }}>
                      <Toggle id={'autoPortFlag' + idx} checked={eachPort.autoFlag} onChange={(checked: boolean) => { inputHandler('autoFlag', checked, idx) }} />
                    </div>
                  </div>
                  <div className="addBtnWrap">
                    {
                      port.portMap.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removePortHandler(idx)} />
                    }
                    {
                      idx === port.portMap.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addPortHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                  </div>
                </div>)
            })}
          </div>
        </div>

        <div className="btnWrap">
          <button className="btn grey" onClick={cancelHandler}>취소</button>
          <button className="btn blue" onClick={checkValidation}>신청하기</button>
        </div>
      </form>
    </JobFormFragment>
  )
}

const JobFormFragment = styled.div`
.formWrap{max-width: 953px;}

// 리소스 그룹
.resPreview{margin:20px 0 0 15px;}
.resPreview > p{font-size:14px; font-weight:bold; margin: 15px 0 10px 0;}
.resPreview .resPreviewWrap{display:flex; gap:10px; flex-wrap:wrap;}
.resPreview .resPreviewWrap > p{font-size:14px; border:1px solid #DFDFDF; border-radius:2px; background-color:#F5F5F5; padding: 2px 10px;}

// 이미지
.imageTypeLabel{display:flex;align-items:center;gap:50px;margin:30px 0 15px;}
.imageTypeLabel p{font-size:14px;}

// 공통
.divider{margin:60px 0;}
form .formGroup + .formGroup{margin-top: 90px;}
.formGroup{margin-top:60px;}
.formGroup.notice{margin-top:10px;font-size:12px;color:#303030}
.formGroup.notice div {padding:0 12px}
.formWrap .btnWrap{margin-top:120px; justify-content:center;}
.formWrap .btnWrap .btn{width:240px;}
.formGroup.port{width:1024px}

// 공통
.addBtnWrap{display:flex; gap:5px;}
.innerGap{display:flex; gap:10px;}

form .inputBox{gap:30px; align-items:start;}
img.disabled {opacity:0;visibility:hidden}
button.disabled {opacity:0.5;pointer-events:none}

// 공통 - 포트
.portWrap {width:943px;} //margin-bottom:30px;
.portWrap .input{width:240px;}
.portWrap > div > span {margin-top:10px;}
.portWrap .portBox .message {width:220px;}

.resourceBoxGroup {margin: 0 0 30px 0; gap: 15px; flex-wrap: wrap; width:930px;} //width 넓히면 한 줄에 들어가는 리소스 박스 개수 조절 가능
.recreateMsgResource {padding-left:5px; color:red;}
  
.inputSmall {width: 240px;}
.inputWrap .resourceErrorBoxDetail {font-size:12px;}
.resourceErrorBox {background-color:#F5F5F5; border-radius:30px; padding: 10px 15px; margin: 10px 0; font-weight:bold;}
.redPoint {font-weight:bold; color:#EF4F30;}
.smallTooltip{width:13px;}
.innerLabel {font-size:14px;}
.notiLabel {font-size:12px;}

// 공통 - inputBox
.inputBox + .inputBox{margin-top: 15px;}
.inputBox .btn{width: 89px; white-space:pre;}
.inputBoxTitle p:not(.message) {font-size:14px; margin-top:10px;}

.inputSideWrap{flex: 1 1; max-width:578px;}
.inputSideBox{display: flex; justify-content:space-between; align-items: start; gap: 23.5px;}
.inputSideBox .input{width:240px;}
.inputSideBox .message{width:220px;}
.inputSideBox img{margin-top:12px;}
  

// 공통 - 데이터 볼륨
.dataVolBox{width: 943px; display:flex; align-items:start; font-size:14px;}
.dataVolBox span{margin-top:10px;}
.dataVolBox .input{width:240px;}
.dataVolBox div{margin-left:-1px;}

//기타 - 리소스 샤용 제한 (지금 사용하지 않음)
.resourceLimitList {margin-left:10px; padding-right:20px;}
.resourceLimitList li {list-style-type: disc; word-break:break-all;}
.resourceLimitList li+li {margin-top:2px;}
.resourceLimitList li div {margin-top:2px;}
.resourceLimitList li .gray {color:#CCCCCC;}
`

export default JobForm