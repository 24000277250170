import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

/* import { CHECKBOX_TYPE, Table } from "../../components/Table"

import { ITableHeader, TABLE_CELL_TYPE } from '../../interfaces/TableHeader'
import { SORT_ORDER } from "../../model/BaseDataType"
import TableEvent from "../../events/TableEvent"
import { IGroupVolumePv, ResourceGroup } from "../../model/ResourceGroup"
import ModalEvent, { MODAL_TYPE } from '../../events/ModalEvent' */
import { IGroupVolumePv } from '../../../model/ResourceGroup'
import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../ui/Table'
import TableEvent from '../../../events/TableEvent'
import { SORT_ORDER } from '../../../model/BaseDataType'
import { ResourceGroup } from '../../../model/ResourceGroup'
import PopupController from '../../../controller/PopupController'

interface IPvListPopupProps {
  onClose:Function
  onSelected:Function
  selected:IGroupVolumePv[]
}

interface IPvListPopupState {
  selectedItems:IGroupVolumePv[]
  tableItems:IGroupVolumePv[]
  totalArticle:number
}

const PvListPopup = (props:IPvListPopupProps) => {
  const tableRef =  useRef<any>()
  const tableColWidth = [52, 201, 86, 84, 276]
  const headerLabel: ITableHeader[] = [
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'name' }, 
    { label: '용량', key: 'size', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'size' }, 
    { label: '타입', key: 'type', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'type' }, 
    { label: '타입 상세', key: 'path', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'path' }
  ]

  const popupController = PopupController.getInstance()

  const [state, _setState] = useState<IPvListPopupState>({
    selectedItems: [],
    tableItems: [],
    totalArticle : 0
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getData()
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const eventControl = (e:Event) => {
    getData()
  }

  const selectBoxCheckHandler = (selectedIdx:IGroupVolumePv[]):void => {
    if (state.tableItems && selectedIdx && state.tableItems.length > 0) {

      let selectedArray: IGroupVolumePv[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedItems: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedItems: []
      })
    }
  }

  const getData = async () => {
    try {
      const data = await ResourceGroup.getPvList()
      if (data) {
        for (let eachRow of data.pvList) {
          eachRow.path = eachRow.server+':'+eachRow.path
          eachRow.type = eachRow.type.toUpperCase()
        }
        setState({
          ...state,
          tableItems: data.pvList,
          totalArticle : data.pvCount ? data.pvCount : 0
        })
      }  
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 3c3a97')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    let selectedPv = JSON.parse(JSON.stringify(state.selectedItems))
    if (props.onSelected) props.onSelected(selectedPv)
    closeHandler()
  }

  return (
    <PvListPopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
      <h2 className="pageTitle">
        PV 목록<span className="total"><b>{state.totalArticle}</b>개</span>
      </h2>
      <div className="scrollWrap">
        {state.tableItems.length > 0 ?
          <>
            <Table ref={tableRef}
              colWidth={tableColWidth}
              headerLabel={headerLabel}
              items={state.tableItems}
              checkBoxType={CHECKBOX_TYPE.USE_ALL}
              noData="볼륨 정보가 없습니다."
              defaultSortOrder={SORT_ORDER.ASC}
              onSelect={selectBoxCheckHandler}
            />
          </>
          : <>
            <div className="noData">볼륨 정보가 없습니다.</div>
          </>}
      </div>
      <div className="btnWrap">
        <button className="btn grey" onClick={closeHandler}>취소</button>
        {state.selectedItems.length > 0 ?
          <button className="btn blue" onClick={submitHandler}>{state.selectedItems.length}개 가져오기</button> :
          <button className="btn off"> 가져오기</button>
        }
      </div>
    </PvListPopupFragment>
  )
}

const PvListPopupFragment = styled.div`
  display:flex; flex-direction:column; width:800px; height:800px; padding:50px 50px 20px; box-sizing:border-box;
  .pageTitle { flex:none; margin-bottom:0px; }
  .scrollWrap { overflow:auto; flex:1; margin-top:20px; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:15px; }
  .btnWrap .btn { width:188px; height:40px; line-height:33px; }
  .noData { text-align:center; color:#A7A7A7; font-size:20px; display:flex; justify-content:center; align-items:center; height:100%; margin-top:-30px; }
  .col2 { text-align:center; }
  .col1 { padding:15px 18px; }
`
export default PvListPopup