import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE, TABLE_ALIGN } from '../components/uiv2/Table'
import { SORT_ORDER } from '../../model/BaseDataType'
import { Service } from '../../model/Service'
import PopupController from '../../controller/PopupController'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import Button, { BUTTON_SIZE } from '../components/uiv2/Button'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../Config'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import WebSocketEvent from '../../events/WebSocketEvent'
import WebSocketController from '../../controller/WebSocketController'

interface IVersionListProps {
}

interface IVersionListState {
  serviceName:string
  project:string|null
  tableItems:any[]
}

const VersionList = (props:IVersionListProps) => {
  const params = useParams()
  const popupController = PopupController.getInstance()
  const webSocketController = WebSocketController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [41, 373, 200, 200, 200, 82, 82, 134]
  const headerLabel:ITableHeader[] = [
    { label: 'No', key: 'tableNo', type: TABLE_CELL_TYPE.STRING, /* align:TABLE_ALIGN.CENTER, */ orderFlag: true, sort: false, styleKey:'tableStyle' }, 
    { label: '이미지 이름', key: 'image', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey:'tableStyle' }, 
    { label: '태그', key: 'tag', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey:'tableStyle' },
    { label: 'Image ID', key: 'imageDigest', type: TABLE_CELL_TYPE.STRING, /* align:TABLE_ALIGN.CENTER, */ sort: false, styleKey:'tableStyle' }, 
    { label: '버전 생성일시', key: 'tableCreatedAt', type: TABLE_CELL_TYPE.STRING, /* align:TABLE_ALIGN.CENTER, */ sort: false, styleKey:'tableStyle' }, 
    { label: '상세', key: 'tableDetailButton', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, styleKey:'tableStyle' }, 
    { label: '롤백', key: 'tableRollback', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, styleKey:'tableStyle' }, 
    { label: '레플리카 개수', key: 'tableReplicas', type: TABLE_CELL_TYPE.STRING, /* align:TABLE_ALIGN.CENTER, */ sort: false, styleKey:'tableStyle' }, 
  ]
  const [ state, _setState ] = useState<IVersionListState>({
    serviceName: '',
    project: null,
    tableItems: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getData()

    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    webSocketController.addEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      webSocketController.removeEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)
    }
  }, [])

  const webSocketActionHandler = (e:WebSocketEvent) => {
    switch (e.payload.action) {
      case 'versionlistreload':
        if (e.payload.id === Number(params.id)) {
          getData()
        }
        break
    }
  }

  const getData = async () => {
    try {
      const id = Number(params.id)
      const response = await Service.getVersionList(id)

      if (response) {
        let idx = 1
        for (let eachRow of response.replicasets) {
          if (eachRow.isActive === true) {
            eachRow.tableStyle = { backgroundColor: 'rgba(255, 208, 40, 0.06)' }
          }
          eachRow.tableNo = `${idx}`
          eachRow.tableCreatedAt = dayjs(eachRow.createdAt*1000).format('YYYY/MM/DD HH:mm:ss')
          eachRow.tableReplicas = `${eachRow.readyReplicas} 개 / ${eachRow.replicas}개`
          eachRow.tableDetailButton = [[(<Button size={BUTTON_SIZE.SMALL} onClickButton={() => detailPopupHandler(eachRow.name, eachRow.namespace)}>상세보기</Button>)]]
          eachRow.tableRollback = [[(<Button size={BUTTON_SIZE.SMALL} onClickButton={() => {rollbackHandler(eachRow.isActive, eachRow.name)}} disabled={eachRow.isActive}>롤백하기</Button>)]]
          idx++;
        }
        setState({
          serviceName: response.name,
          project: response.project,
          tableItems: response.replicasets
        })  
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - b145a2')
    }
  }

  const detailPopupHandler = (verName:string, verNamespace:string) => {
    popupController.versionDetail(verName, verNamespace)
  }

  const rollbackHandler = (isActive:boolean, versionName:string) => {
    if(isActive) return
    try {
      popupController.dialouge(`${stateRef.current.serviceName} 서비스가 롤백 됩니다. 진행하시겠습니까?`, 'rollback', {versionName: versionName})
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    try {
      switch (e.payload.action) {
        case 'rollback':
          try {
            const id = Number(params.id)
            await Service.patchServiceVersionRollback(e.payload.key.versionName, id)
  
            // 롤백 신청이 성공한 경우 토스트 팝업 표출
            let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
            toastEvent.payload = { message: `${stateRef.current.serviceName} 서비스 롤백을 신청하였습니다. 서비스 롤백을 시작합니다.` }
            window.dispatchEvent(toastEvent)
            getData()
          } catch (error) {
            popupController.confirm('에러가 발생했습니다.\n에러코드 - 58d10e')
          }
          break
      }
    } catch(error) {
      // 롤백 신청이 실패한 경우 컨펌 팝업 표출
      popupController.confirm(`${stateRef.current.serviceName} 서비스 롤백 신청에 실패하였습니다.`)
    }
  }

  return (
    <PageFragment>
      <div className='TitleRect'>
        { state.project ? <div className='serviceLabelRect'><img src="/images-v2/service-label.svg" alt="service-label" />{state.project}</div> : false }
        <div className='pageTitle' style={{display:'flex', alignItems:'center', gap:'0px' }}>
          <div style={{ maxWidth:'1219px', height:'28.8px' }}>
            <EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{state.serviceName}</EllipsisToolTip>
          </div>
          <div>&nbsp;버전목록</div>
        </div>
        <div className='pageDescription'>{'20개 까지의 버전을 볼 수 있습니다.'}</div>
      </div>
      <div className='tableRect'>
        <Table ref={tableRef}
          colWidth={tableColWidth}
          headerLabel={headerLabel}
          items={state.tableItems}
          noData="데이터가 없습니다."
          checkBoxType={CHECKBOX_TYPE.NONE}
        />
      </div>
    </PageFragment>
  )
}

const PageFragment = styled.div`

.TitleRect{margin-bottom:48px;}
.serviceLabelRect{display:flex;gap:6px;font-size:14px;font-weight:500px;color:#646469;margin-bottom:7.5px;}
.pageTitle{font-size:24px;line-height:28.8px;font-weight:700;color:#333333;margin-bottom:13.5px;}
.pageDescription{font-size:13px;line-height:20px;font-weight:400;color:#878791;}

//table
.tableRect {width:1312px; margin-right:auto;}
tbody td{padding:11.5px 12px;}
.col0, .col3, .col4, .col7{text-align: center;}
.col5, .col6{padding:8px 12px;}

//small button
.small {padding:0 6px;}
`

export default VersionList