import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from "react-router-dom"
import MonitoringAPI, { IGpu } from '../../model/Monitoring'
import lodash from 'lodash'
import DateRangePicker from '../components/ui/DateRangePicker'
import LineChart from '../components/chart/LineChart'
import dayjs from 'dayjs'
import { IChartSeries } from '../../interfaces/Chart'
import NoData from '../components/NoData'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import PopupController from '../../controller/PopupController'
import SubPopup, { SUBPOPUP_TYPE } from '../components/modal/sub/SubPopup'
import PageEvent from '../../events/PageEvent'
import GPUInfoPopup from '../components/modal/sub/GPUInfoPopup'
import Utils from '../../utils'
import { ERROR_TYPE } from '../../interfaces/Error'
import { useRecoilState } from 'recoil'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import { RESOURCE_TYPE } from '../../model/Workspace'

interface IResourceBlock {
  cpu:number
  gpuMem:number
  gpuMemUnit:string
  gpuPer:number
  gpuType:string
  id:number
  resourceType:RESOURCE_TYPE
  mem:number
  memUnit:string
}
interface IHWSpec {
  cpu:string
  disk:string[]
  gpu:{
    gpuList:IGpu[]
    isParted:boolean
    total:number
  }
  network:string[]
  mem:string[]
  resourceBlock:IResourceBlock[]
  rgroupInfo: {
    rgroupName:string,
    isCommon:boolean
  }
}

interface IServerChart {
  cpu:IChartSeries[]
  gpuMemory:IChartSeries[]
  gpuTemp:IChartSeries[]
  gpuUsage:IChartSeries[]
  gpuUtil:IChartSeries[]
  isCpuNode?:boolean
  memory:IChartSeries[]
  network:IChartSeries[]
  storage:IChartSeries[]
}

interface ServerDetailProps {
}
interface ServerDetailState {
  name: string
  gpuServerFlag: boolean
  gpuData: IChartSeries[]
  range: {
    from: string
    to: string
  }
  limit: {
    from: string
    to: string
  }
}

const ServerDetail = (props: ServerDetailProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const popupController = PopupController.getInstance()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  // const startTimestamp: number = JSON.parse(window.localStorage.getItem('tenAuthInfo') || '{}').loginState.startTimestamp
  const startTimestamp:number = userInfo?.startTimestamp || 0
  const startFrom:string = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom : dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  let initialFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom : dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  let initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const pickerRef1:any = useRef()

  const [ chartData, _setChartData ] = useState<IServerChart>({
    cpu:[],
    gpuMemory:[],
    gpuTemp:[],
    gpuUsage:[],
    gpuUtil:[],
    isCpuNode:undefined,
    memory:[],
    network:[],
    storage:[]
  })
  const chartDataRef = useRef(chartData)
  const setChartData = (data:any) => {
    chartDataRef.current = data
    _setChartData(data)
  }

  const [ hwSpec, setHwSpec ] = useState<IHWSpec>({
    cpu: '',
    disk: [],
    gpu: {
      gpuList:[],
      isParted: false,
      total: 0
    },
    network: [],
    mem: [],
    resourceBlock: [],
    rgroupInfo: {
      rgroupName: '',
      isCommon: false
    }
  })
  const [ state, _setState ] = useState<ServerDetailState>({
    name: params.name || '',
    gpuServerFlag: false,
    gpuData: [],
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: startFrom,
      to: defaultTo
    },
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getChartData(state.range)
    getHWSpecData()
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    return () => {
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
    }
  }, [])

  useEffect(() => {
    setState({
      ...state,
      limit: {
        from: startFrom,
        to: defaultFrom
      }
    })
  }, [userInfo?.startTimestamp])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const autoRefreshHandler = async () => {
    // await utils.setLoadingMask(false)
    let range
    /* if (pickerRef1.current?.modifiedFlag === false) {
      defaultFrom = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
      defaultTo = dayjs().format('YYYY-MM-DD HH:mm')
      this.setState({
        range: {
          from: this.defaultFrom,
          to: this.defaultTo
        }
      }, () => {
        this.getChartData()
      })
    } */
    if (pickerRef1.current?.modifiedFlag === true) {
      // 데이트 피커 초기화 (피커 범위 최근 24시간 데이터로 변경)
      initialFrom = defaultFrom = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
      initialTo = defaultTo = dayjs().format('YYYY-MM-DD HH:mm')      
      pickerRef1.current?.clearRange()
      range = {
        from: initialFrom,
        to: initialTo
      }
      getChartData(range)
    } else { //pickerRef1.current?.modifiedFlag === false
      range = { // 추가될 데이터 범위 (최근 데이터+1초 ~ 지금)
        from: dayjs(stateRef.current.range.to).add(1, 'second').format('YYYY-MM-DD HH:mm:ss'),
        to: dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
      if(range.to !== range.from) {
        onChangeDatePicker(range, true)
      }
    }
    // await utils.setLoadingMask(true)
  }

  const getChartData = async (newRange:any, autoRefreshFlag?:boolean) => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()
      const response = await MonitoringAPI.getServerDetailData(FROM, TO, state.name)
      if (response) {
        if(response.status === 204) {
          navigate('/404')
        }
        const data = response.data
        for (let idx in data) {
          for (let i = 0; i < data[idx].length; i++) {
            let name = ''
            switch(data[idx][i].label) {
              case 'cpu':
                name = 'CPU'
                break
              case 'memory':
                name = 'RAM'
                break
              case 'storage':
                name = 'Storage'
                break
              case 'in':
                name = 'In'
                break
              case 'out':
                name = 'Out'
                break
            }
            data[idx][i] = parseChartData(data[idx][i], name)
          }
        }
        if(autoRefreshFlag) {
          let newChartData:any = lodash.cloneDeep(chartDataRef.current)
          for(let idx in data) {
            for(let i = 0; i < data[idx].length; i++) {
              if(newChartData[idx][i].name === data[idx][i].name) {
                // 제거 (추가되는 개수만큼 제거를 하게 되면... 추가되는 데이터는 초단위인데 제거되는 데이터가 분단위인 문제가 있음)
                newChartData[idx][i].data.splice(0, data[idx][i].data.length)
                // 추가
                newChartData[idx][i].data = newChartData[idx][i].data.concat(data[idx][i].data)
              }
            }
          }
          setChartData(newChartData)
        } else {
          setChartData(data)
        }
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 959638')
    }
  }
  const parseChartData = (data: any, name?:string) => {
    let chartData: any = { name: name ? name : data.label, data: [] }
    for (let i = 0; i < data.data.length; i++) {
      chartData.data.push([data.data[i].timestamp * 1000, data.data[i].value])
    }
    return chartData
  }

  const getHWSpecData = async () => {
    try {
      const response = await MonitoringAPI.getServerHWSpec(state.name)
      if(response) {
        if(response.status === 204) {
          navigate('/404')
        }
        const data = response.data
        if(data) {
          if(data.rgroupInfo.isCommon === true) {
            data.rgroupInfo.rgroupName = '기본 리소스 그룹'
          }
          setHwSpec(data)    
        }
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 1738bd')
    }
  }

  const onChangeDatePicker = (range:any, autoRefreshFlag?:boolean) => {
    if (autoRefreshFlag) {
      // 자동 새로고침 중일 때, 끝 시간이 변경되면서 새로 추가되는 시간 만큼 기존 시작 시간을 뒤로 미룸 
      // get range gap
      const gap = dayjs(range.to).diff(dayjs(range.from), 'second')
      setState({
        ...state,
        // gpuData: [],
        range: {
          from: dayjs(stateRef.current.range.from).add(gap, 'second').format('YYYY-MM-DD HH:mm:ss'), //stateRef.current.range.from, //dayjs(range.from).format('YYYY-MM-DD HH:mm'),
          to: range.to //dayjs(range.to).format('YYYY-MM-DD HH:mm')
        }
      })
    } else { //autoRefreshFlag !== undefined && autoRefreshFlag === false
      setState({
        ...state,
        gpuData: [],
        range: {
          from: range.from,
          to: range.to
        }
      })  
    }
    getChartData(range, autoRefreshFlag)
  }

  return (
    <ServerDetailPageFragment>
      {chartData.isCpuNode === false ?
        //GPU NODE
        <>
          <div className="rgroupTitle">{hwSpec.rgroupInfo.rgroupName}</div>
          <div className="titleArea bothEnd">
            <h2 className="pageTitle">[{state.name}] 상세 </h2>
          </div>
          <DateRangePicker ref={pickerRef1} initial={{ to: initialTo, from: initialFrom }} default={{ to: state.range.to, from: state.range.from }} limit={state.limit} data={state.range} onChange={onChangeDatePicker} />
          <h3 className="chartTitleTop">워크스페이스 별 GPU 할당률</h3>
          {
            chartData.gpuUsage.length ?
              <LineChart height={450} max={100} min={0} unit="%" zoomType="x" datetime={true} data={chartData.gpuUsage} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000} 
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm')
                let result = `${date}<br>`
                this.points?.forEach((point:any) => {
                  result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} %</b><br/>`
                })
                return result
              }} /> : 
              <NoData height={450} />
          }
          <div className="divider" style={{ margin: "50px 0" }}></div>
          <div className="bothEnd" style={{ alignItems: 'start' }}>
            <div className="serverHdSpec box" style={{ marginTop: '65px' }}>
              <p className="titleBold">서버 하드웨어 스펙</p>
              <div className="divider" style={{ margin: "20px -20px 17px", background: "#DFDFDF" }}></div>
              <div className="content">
                <div className="specGeneral">
                  <ul className="listDot">
                    <li>
                      <div className='specLine'>
                        <h3>CPU:&nbsp;</h3>
                        <span>{hwSpec.cpu ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.cpu}</EllipsisToolTip>
                          </div>
                          : '-'}</span>
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>RAM:&nbsp;</h3>
                        <span>{hwSpec.mem.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.mem[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                          {hwSpec.mem.length > 1 ?
                          <SubPopup titles={["RAM"]} datas={hwSpec.mem} type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'15px 30px'} /> : false}
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>DISK:&nbsp;</h3>
                        <span>{hwSpec.disk.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.disk[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                          {hwSpec.disk.length > 1 ?
                          <SubPopup titles={["Disk"]} datas={hwSpec.disk} type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'15px 30px '} /> : false}
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>NETWORK:&nbsp;</h3>
                        <span>{hwSpec.network.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.network[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                        {hwSpec.network.length > 1 ?
                            <SubPopup titles={["Network"]} datas={hwSpec.network} type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'15px 30px'} /> : false}
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>GPU:&nbsp;</h3>
                        <span>{hwSpec.gpu.gpuList.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.gpu.gpuList.length > 0 ? hwSpec.gpu.gpuList[0].name : ''}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}
                        </span>
                        <div style={{margin:'0 5px 0 3px'}}>
                          <div style={{position:'relative', top: '-3px'}}>
                            {hwSpec.gpu.gpuList.length > 0 ? <div className="pointBgColor">{`전체 ${hwSpec.gpu.gpuList.length}개`}</div> : false}
                            </div>
                        </div>
                        {hwSpec.gpu.gpuList.length > 0 ?
                          // <SubPopup titles={["GPU"]} datas={hwSpec.gpu} type={SUBPOPUP_TYPE.MODEL} width={571} colWidth={[4]} padding={'15px 30px'} /> 
                          <GPUInfoPopup title={(hwSpec.gpu.isParted ? `전체 GPU 모델명 및 분할 설정 정보 [분할: 총 ${hwSpec.gpu.total}개]` : `전체 GPU 모델명 [총 ${hwSpec.gpu.gpuList.length}개]`)} data={hwSpec.gpu.gpuList} />
                          : false}

                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="divider" style={{ margin: "16px -5px 17px", background: "#DFDFDF" }}></div> */}
                {/* <div className="">
                  {hwSpec.resourceBlock.length ?
                    <>
                      <div className="specLine">
                        <h3>블록 타입 1:&nbsp;</h3>
                        <div className="ellipsisWidth">
                          <EllipsisToolTip options={Config.ellipsisTipOptions}><h3>{hwSpec.resourceBlock[0].gpuType}</h3></EllipsisToolTip>&nbsp;&nbsp;
                        </div>
                        {hwSpec.resourceBlock.length > 1 ?
                        <SubPopup titles={["블록 타입"]} datas={hwSpec.resourceBlock} type={SUBPOPUP_TYPE.BLOCKTYPE} width={533} colWidth={[6]} padding={'15px 30px'} /> : false}   
                      </div>
                      <div className="innerBox bothEnd">
                        <div className="half">
                          <div>CPU: {hwSpec.resourceBlock[0].cpu} 개</div>
                          <div>RAM: {hwSpec.resourceBlock[0].mem} {hwSpec.resourceBlock[0].memUnit}</div>
                        </div>
                        <div className="half">
                          <div>GPU: {hwSpec.resourceBlock[0].gpuPer} %</div>
                          <div>GPUmem: {hwSpec.resourceBlock[0].gpuMem} {hwSpec.resourceBlock[0].gpuMemUnit}</div>
                        </div>
                      </div>
                    </>
                    : false}
                </div> */}
              </div>
            </div>
            <div className="rightChart">
              <h3 className="chartTitleUsage" style={{ marginTop: "0" }}>CPU usage</h3>
              {
                chartData.cpu.length ?
                  <LineChart unit="%" zoomType="x" height={305} min={0} max={100} data={chartData.cpu} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={305} />
              }
            </div>
          </div>
          <div className="bothEnd">
            <div className="leftChart">
              <h3 className="chartTitleUsage">Storage usage</h3>
              {
                chartData.storage.length ?
                  <LineChart unit="%" zoomType="x" height={305} min={0} max={100} data={chartData.storage} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={305} />
              }
            </div>
            <div className="rightChart">
              <h3 className="chartTitleUsage">RAM usage</h3>
              {
                chartData.memory.length ?
                  <LineChart unit="%" zoomType="x" height={305} min={0} max={100} data={chartData.memory} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={305} />
              }
            </div>
          </div>
          <div className="divider" style={{ margin: "30px 0 80px 0" }}></div>
          <h3 className="chartTitle">GPU  memory</h3>
          {
            chartData.gpuMemory.length ?
              <LineChart height={320} max={100} min={0} unit="%" zoomType="x" datetime={true} data={chartData.gpuMemory} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                let result = `${date}<br>`
                this.points?.forEach((point:any) => {
                  result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} %</b><br/>`
                })
                return result
              }} /> : <NoData height={320} />
          }
          { hwSpec.resourceBlock[0] && hwSpec.resourceBlock[0].resourceType !== RESOURCE_TYPE.MIG ?
            <>
              <h3 className="chartTitle">GPU utilization</h3>
              {
                chartData.gpuUtil.length ?
                  <LineChart height={320} max={100} min={0} unit="%" zoomType="x" datetime={true} data={chartData.gpuUtil} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000} 
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    let result = `${date}<br>`
                    this.points?.forEach((point:any) => {
                      result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} %</b><br/>`
                    })
                    return result
                  }}/> : <NoData height={320} />
              }
            </> : false}
          <h3 className="chartTitle">GPU temperature</h3>
          {
            chartData.gpuTemp.length ?
              <LineChart height={320} min={0} unit="℃" zoomType="x" datetime={true} data={chartData.gpuTemp} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                let result = `${date}<br>`
                this.points?.forEach((point:any) => {
                  result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ℃</b><br/>`
                })
                return result
              }} /> : <NoData height={320} />
          }
          <h3 className="chartTitle">Network I/O</h3>
          {
            chartData.network.length ?
              <LineChart height={320} zoomType="x" /* unit="Byte" */ format={"B"} datetime={true} data={chartData.network} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                let result = `${date}<br>`
                this.points?.forEach((point:any) => {
                  const data = Utils.byteTransform(point.y)
                  // result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} Byte</b><br/>`
                  result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${data.value} ${data.size}</b><br/>`
                })
                return result
              }} /> : <NoData height={320} />
          }
        </> :
        // CPU NODE
        <>
          <div className="rgroupTitle">{hwSpec.rgroupInfo.rgroupName}</div>
          <div className="titleArea bothEnd">
            <h2 className="pageTitle">[{state.name}] 상세 </h2>
          </div>
          <DateRangePicker initial={{ to: initialTo, from: initialFrom }} default={{ to: state.range.to, from: state.range.from }} limit={state.limit} data={state.range} onChange={onChangeDatePicker} />
          <div className="bothEnd" style={{ marginTop: "40px" }}>
            <div className="serverHdSpec box" style={{ alignSelf: "start", marginTop: '67px' }}>
              <p className="titleBold">서버 하드웨어 스펙</p>
              <div className="divider" style={{ margin: "20px -20px 17px", background: "#DFDFDF" }}></div>
              <div className="content">
                <div className="specGeneral">
                  <ul className="listDot">
                    <li>
                      <div className='specLine'>
                        <h3>CPU:&nbsp;</h3>
                        <span>{hwSpec.cpu ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.cpu}</EllipsisToolTip>
                          </div>
                          : '-'}</span>
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>RAM:&nbsp;</h3>
                        <span>{hwSpec.mem.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.mem[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                        {hwSpec.mem.length > 1 ?
                            <SubPopup titles={["RAM"]} datas={hwSpec.mem} type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'5px 30px 5px 30px'} /> : false}
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>DISK:&nbsp;</h3>
                        <span>{hwSpec.disk.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.disk[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                        {hwSpec.disk.length > 1 ?
                            <SubPopup titles={["Disk"]} datas={hwSpec.disk}  type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'5px 30px 5px 30px'} /> : false}
                      </div>
                    </li>
                    <li>
                      <div className='specLine'>
                        <h3>NETWORK:&nbsp;</h3>
                        <span>{hwSpec.network.length ?
                          <div className="ellipsisWidth">
                            <EllipsisToolTip options={Config.ellipsisTipOptions}>{hwSpec.network[0]}</EllipsisToolTip>&nbsp;&nbsp;
                          </div>
                          : '-'}</span>
                        {hwSpec.network.length > 1 ?
                            <SubPopup titles={["Network"]} datas={hwSpec.network} type={SUBPOPUP_TYPE.NORMAL} width={571} colWidth={[4]} padding={'5px 30px 5px 30px'} /> : false}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="rightChart">
              <h3 className="chartTitleUsage" style={{ marginTop: "0" }}>CPU usage</h3>
              {
                chartData.cpu.length ?
                  <LineChart unit="%" zoomType="x" height={278} min={0} max={100} data={chartData.cpu} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={278} />
              }
            </div>
          </div>
          <div className="bothEnd">
            <div className="leftChart">
              <h3 className="chartTitleUsage">Storage usage</h3>
              {
                chartData.storage.length ?
                  <LineChart unit="%" zoomType="x" height={278} min={0} max={100} data={chartData.storage} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={278} />
              }
            </div>
            <div className="rightChart">
              <h3 className="chartTitleUsage">RAM usage</h3>
              {
                chartData.memory.length ?
                  <LineChart unit="%" zoomType="x" height={278} min={0} max={100} data={chartData.memory} datetime={true} yAxisSingle={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                  toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                    const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                    return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`}} /> :
                  <NoData height={278} />
              }
            </div>
          </div>
          <div className="divider" style={{ margin: "30px 0 80px 0" }}></div>
          <h3 className="chartTitle">Network I/O</h3>
          {
            chartData.network.length ?
              <LineChart height={320} zoomType="x" /* unit="Byte" */ format={"B"} datetime={true} data={chartData.network} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm:ss')
                let result = `${date}<br>`
                this.points?.forEach((point:any) => {
                  const data = Utils.byteTransform(point.y)
                  // result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} Byte</b><br/>`
                  result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${data.value} ${data.size}</b><br/>`
                })
                return result
              }} /> : <NoData height={320} />
          }
        </>
      }
    </ServerDetailPageFragment>
  )
}

const ServerDetailPageFragment = styled.div` 
line-height:17px;
.period + .chartTitle{ margin-top:30px; }
.chartTitleTop { margin:50px 0 10px; font-size:14px; text-align:center; }
.chartTitle{ text-align:center; margin:80px 0 10px 0; font-size:14px; }
.chartTitleUsage{ text-align:center; margin:0 0 10px 0; font-size:14px; }
.titleBold { font-weight:bold; }
div.box{ width:640px; height:203px; box-sizing:border-box; }
.box { border:#DFDFDF solid 1px; padding:20px 20px; border-radius:2px; font-size:14px; color:#303030; }
.specGeneral div { margin:0; }
.specLine { display:flex; height:17px; line-height:17px; }
.ellipsisWidth { max-width:500px; }
.gpuBlockdata { background: #F5F5F5; border-radius:2px; margin-top:10px; padding:20px; }
.innerBox { width:540px; height:43px; margin-top:12px; }
.innerBox .half { width:290px; }
.half div + div{ margin-top:5px; } 
.leftChart { width:640px; margin:20px 20px 50px 0; }
.rightChart { width:640px; margin:20px 0 50px 20px; }
.lineData { width:310px; height:17px;  margin: 0 0 5px 5px;}
.listDot li{ list-style-type:disc; margin-left:15px; }
.listDot li+li{ margin-top:5px }
.pointBgColor { padding:3px 2px;background-color:#E1F5FF;border-radius:2px; }
.rgroupTitle { font-weight:bold; margin-bottom:10px; font-size:14px; }

`

export default ServerDetail