import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'

interface IRadioboxProps {
  name?:string
  id:string
  checked:boolean
  label?:string
  disabled?:boolean
  onChange?:(id:string) => void
}

interface IRadioboxState {
  checked:boolean
}

const RadioBox = (props:IRadioboxProps) => {
  const [ state, setState ] = useState<IRadioboxState>({
    checked: false
  })
  const prevState = Utils.usePrevState(state)

  useEffect(() => {
    if (prevState.checked !== props.checked) {
      // props updated
      setState({
        ...state,
        checked: props.checked
      })
    } else if (prevState.checked === props.checked && props.checked !== state.checked) {
      setState({
        ...state,
        checked: props.checked
      })
    } else if (prevState.checked !== state.checked) {
      // stats updated
    }
  }, [prevState.checked, props.checked, state.checked])

  return (
    <RadioBoxFragment>
      <input className="hide" type="radio" name={props.name} id={props.id} checked={state.checked} disabled={props.disabled && props.disabled === true ? true : false} onChange={(e) => {
        setState({
          ...state,
          checked: !state.checked
        })
        if (props.onChange) props.onChange(props.id)
      }} />
      <label htmlFor={props.id}>
        <span className="box"></span>
        {props.label ? <span className="text">{props.label}</span> : false}
      </label>
    </RadioBoxFragment>
  )
}

const RadioBoxFragment = styled.div`
  position:relative;
  input { position:absolute; left:0; top:0; width:0; height:0; opacity:0; }
  label { display:flex; align-items:center; justify-content:center; gap:8px;}
  label .box { position:relative; display:block; width:18px; height:18px;background: url('/images-v2/radio.png') no-repeat center / 100%; }
  label .text { font-size:14px; }
  input:checked + label .box,
  input:checked:disabled + label .box {background-image: url('/images-v2/radio-on.png');}
  input:checked:disabled + label .box{opacity: 0.3;}
  input:disabled + label .box {background-image: url('/images-v2/radio-off.png');}
  input:disabled + label .text{opacity: 0.3;}
`

export default RadioBox
