import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavigateFunction, useNavigate } from 'react-router-dom'
/* import { withRouter } from '../WithRouter' */

import { ILoginResult, User } from '../../../model/User'

import ModalEvent from '../../../events/ModalEvent'
import PopupController from '../../../controller/PopupController'
import { ERROR_TYPE } from '../../../interfaces/Error'
import { useRecoilState } from 'recoil'
import { userInfoState, IUserInfo } from '../../../states/userInfoState'
import InputBox, { TEXT_INPUT_TYPE } from './InputBox'
import { useRef } from 'react'
import { LOGIN_RESULT } from '../../../model/User'

interface VerificationProps {
  //navigate?:NavigateFunction
  onPwPass:Function
}

interface VerificationState {
  currentPw:string
  currentPwError:string
  error: {
    currentPw:string
  }
}

const Verification = (props:VerificationProps) => {
  const user = User()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  const popupController = PopupController.getInstance()
  const navigate = useNavigate()

  const [ state, _setState ] = useState<VerificationState>({
    currentPw: '',
    currentPwError:'',
    error: {
      currentPw: ''
    }
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    document.addEventListener('keydown', preventEnterKey)
    return () => {
      document.removeEventListener('keydown', preventEnterKey)
    }
  }, [])

  const preventEnterKey = (e:any) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }
  }


  const inputHandler = (target:string, value:any) => {
    let errorText:string = ''

    switch (target) {
      case 'currentPw':
        setState({
          ...state,
          currentPw: value,
          currentPwError: errorText
        })
        break
    }
  }

  const submitHandlerPw = async (e:any) => {
    e.preventDefault()
    try {
      let blankErrorFlag:boolean = false
      let currentPwError:string = state.currentPwError
      if (state.currentPw === '') {
        blankErrorFlag = true
        setState({
          ...state,
          currentPwError:'필수 입력 항목입니다.'
        })
      }

      if(blankErrorFlag === true) {
        setState({
          ...state,
          currentPwError: currentPwError,
        })
      }

      if(blankErrorFlag === true || stateRef.current.currentPw === '') {
        setState({
          ...state,
          currentPwError: '필수 입력 항목입니다.'
        })
      } else {
        const response = await user.checkPw(userInfo?.userId? userInfo.userId : '',state.currentPw)
        switch (response && response.status) {
          case LOGIN_RESULT.OKAY:
            props.onPwPass()
            break
          case LOGIN_RESULT.INVAILD_INFO:
          default:
            setState({
              ...state,
              currentPwError: '사용중인 비밀번호와 일치하지 않습니다.'
            })
            break
        }
      }
    } catch(e){
      if ((e as Error).message === ERROR_TYPE.WRONGPW) {
        setState({
          ...state,
          currentPwError: '사용중인 비밀번호와 일치하지 않습니다.'
        })
      } else {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 86a879')
        return
      }
    }
  }

  return (
    <VerificationFragment>
      <div className="formWrap">
        <div className="titleWrap">
          <h1>비밀번호 재확인</h1>
          <p>* 회원님의 개인정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 확인해 주세요.</p>
        </div>
        <form>
          <div className="formGroup">
            <label className="label">아이디</label>
            <div className="inputWrap">
              <div className='inputBox'>
                <InputBox id="currentPw" type={TEXT_INPUT_TYPE.TEXT} readonly={true} placeholder='현재 사용중인 아이디' value={userInfo?.userId} />
              </div>
            </div>
          </div>
          <div className="formGroup">
            <label className="label">비밀번호 *</label>
            <div className="inputWrap">
              <div className='inputBox'>
                <InputBox id="currentPw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder='현재 사용중인 비밀번호를 입력해 주세요.' value={state.currentPw} warning={state.currentPwError} onChange={inputHandler} />
              </div>
            </div>
          </div>
          <div className="btnWrap">
            <button className="btn grey" onClick={(e) => {
              setState({ ...state, pwEdit: false, currentPw: '', pw: '', confirmPw: '', currentPwError: '', pwError: '', confirmPwError: '' })
              let postUrl = sessionStorage.getItem('url')
              if (!postUrl) { postUrl = '/' }
              navigate(postUrl)
            }}>취소</button>
            <button className="btn blue" onClick={submitHandlerPw}>확인하기</button>
          </div>
        </form>
      </div>
    </VerificationFragment>
  )
}

const VerificationFragment = styled.div`
.formWrap{
  max-width:830px; min-height:800px; width:100%; padding:0 50px;
  .titleWrap { display:flex; align-items:center; gap:10px; margin-bottom:40px; color:#303030; }
  .titleWrap h1 { font-size:24px; }
  .titleWrap p { font-size:14px; }
  .formGroup + .formGroup { margin-top:20px; }
  .label { font-weight:bold }
  .btn.blue { display:block; width:240px; margin:120px auto 0; }
  .btnWrap { display:flex; align-items:center; justify-content:center; gap:10px; margin-top:120px; justify-content:flex-end !important;}
  .btnWrap .btn { width:240px; margin:0; }
}
`
export default Verification