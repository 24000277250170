import { uniqueId } from "lodash"
import React, { JSXElementConstructor, ReactElement, ReactNode, createRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react"

interface IFormProps {
  ref?:React.ForwardedRef<JSX.Element>
  onSubmit?:Function
  className?:string
  children:ReactNode
}

const Form = forwardRef<any, IFormProps>((props:IFormProps, ref) => {
  useImperativeHandle(ref, () => {
    return {
      validate: validate,
      serialize: serialize
    }
  })

  const validate = ():boolean => {
    let result:boolean = true
    let eachResult:boolean = true
    let childrenArray = getChildren(React.Children.toArray(props.children))
    for (let eachNode of childrenArray) {
      if (eachNode.ref !== null && eachNode.ref.current.validate) eachResult = eachNode.ref.current.validate()
      if (eachResult === false) result = false
    }
    return result
  }

  const serialize = (getErrorFlag:boolean=false):object => {
    let result:any = {}
    let childrenArray = getChildren(React.Children.toArray(props.children))
    for (let eachNode of childrenArray) {
      if (eachNode.props.ignore !== true && eachNode.props.id && eachNode.ref !== null) {
        let key = eachNode.props.id
        let subKey = ''
        let idx = -1
        let value = getErrorFlag ? eachNode.ref.current.getErrorMessage() : eachNode.ref.current.getValue()
        if (typeof(value) === "string") value = value.trim()

        if ((getErrorFlag || value !== '') && value !== undefined) {
          let arrayKeyIdx = key.match(/[0-9]+$/)
          if (arrayKeyIdx !== null) {
            idx = Number(arrayKeyIdx[0])
            key = key.substring(0, arrayKeyIdx.index)
          }
          let keyArray = key.split('-', 2)
          if (keyArray.length === 2) {
            key = keyArray[0]
            subKey = keyArray[1]
          }
          
          if (idx === -1 && subKey === '') {
            result[key] = value
          } else if (idx === -1 && subKey !== '') {
            if (result[key] === undefined) result[key] = {}
            result[key][subKey] = value
          } else if (idx !== -1 && subKey === '') {
            if (result[key] === undefined) result[key] = []
            result[key][idx] = value
          } else if (idx !== -1 && subKey !== '') {
            if (result[key] === undefined) result[key] = []
            if (result[key][idx] === undefined) result[key][idx] = {}
            result[key][idx][subKey] = value
          }
        }
      }
    }
    for (let key in result) {
      if (result[key].filter) result[key] = result[key].filter((n:any) => { return n !== undefined })
    }
    return result
  }
  
  const getChildren = (nodes:any[]) => {
    let result = []
    for (let eachNode of nodes) {
      if (eachNode.props) {
        if (eachNode.props.option !== undefined) {
          // SELECT
          result.push(eachNode)
        } else if (eachNode.props.type !== undefined) {
          // INPUT - eachNode.props.rule
          result.push(eachNode)
        } else if (eachNode.props.checked !== undefined) {
          // Toggle
          result.push(eachNode)
        }

        if (eachNode.props.children) {
          let result2:any[] = getChildren(React.Children.toArray(eachNode.props.children))
          result = result.concat(result2)
        }
      }
    }

    return result
  }

  return (
    <form ref={ref} className={props.className} onSubmit={(e) => {
      e.preventDefault()
      if (props.onSubmit) props.onSubmit(e)
    }}>
      {props.children}
    </form>
  )
})

export default Form