import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import PopupController from '../../../controller/PopupController'
import { Image, IImageListItem, IImageItem } from '../../../model/Image'
import { IBaseDataType, SORT_ORDER } from '../../../model/BaseDataType'
import Table, { CHECKBOX_TYPE, TABLE_ALIGN, TABLE_CELL_TYPE } from '../uiv2/Table'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../uiv2/Button'
import TableEvent from '../../../events/TableEvent'
import { ERROR_TYPE } from '../../../interfaces/Error'
import { IUserInfo, userInfoState } from '../../../states/userInfoState'
import { useRecoilState } from 'recoil'

interface IImageListPopupProps {
  onClose:Function
  onSelected:Function
  namespace:string  //namespace가 사용자id와 동일하면 공용리소스그룹. 아니라면 리소스그룹 이름.
  preSelectedReg?:string
  preSelectedImage?:IImageListItem
  jupyterFlag?:boolean
  isCommon?:boolean
}

interface IImageListPopupState {
  page:number
  totalArticle:number
  selectedItem:IBaseDataType
  commonImageList:any[]
  typeImageList:any[]
  registry?:string
  preseledtedItem:IBaseDataType
}

const ImageListPopup = (props:IImageListPopupProps) => {
  const popupController = PopupController.getInstance()
  
  const [ state, _setState ] = useState<IImageListPopupState>({
    totalArticle: 0,
    page: 1, //(props.params?.page ? Number(props.params?.page) : 1),
    selectedItem: [0],
    commonImageList: [],
    typeImageList: [],
    preseledtedItem:[0]
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  const tableRef = useRef<any>()
  const tableColWidth = [42, 470, 224, 200]
  const headerLabel = [
    {
      label: '이미지 이름',
      key: 'rep',
      type: TABLE_CELL_TYPE.STRING,
      sort: SORT_ORDER.ASC,
      sortKey: 'rep'
    }, {
      label: '태그',
      key: 'tag',
      type: TABLE_CELL_TYPE.STRING,
      sort: false
    }, {
      label: 'Image ID',
      key: 'id',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      align: TABLE_ALIGN.CENTER
    }
  ]
  const [ tableItems, setTableItems ] = useState<IImageItem[]>([])

  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    }
  }, [])

  useEffect(() => {
    // set preselectedItem 있다면.
    let selectedIdx: number[] = []
    if (props.preSelectedReg && props.preSelectedImage) {
      if (props.preSelectedReg === state.registry) {
        let preRepository = props.preSelectedImage.split(':')[0]
        let preTag = props.preSelectedImage.split(':')[1]
        let addIdx = tableItems.findIndex((element: IImageItem) => element.rep === preRepository && element.tag === preTag)
        if (addIdx !== -1) {
          selectedIdx.push(addIdx)
        }
        tableRef.current?.setSelected(selectedIdx)
      }
    }
  }, [tableItems])

  const eventControl = (e:Event) => {
    getData()
  }

  const getData = async () => {
    //☆☆☆☆☆☆설정 없음의 경우 table 컴포넌트의 수정이 필요함 ==> 정렬시 해당 항목은 가장 위에 고정되어야함
    try {
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      const data = await Image.getImageListOps(props.namespace, props.jupyterFlag || false, sortOrder, props.isCommon || false)
      if (data) {
        //set data
        let registry = props.namespace === userInfo?.userId ? data.common.reg : data.type.reg

        const commonImageList = data.common.imageList.filter((image:any) => image.commitInfo === null)
        const typeImageList = data.type.imageList.filter((image:any) => image.commitInfo === null)

        commonImageList.unshift({rep:'설정 없음', tag : '', id: ''})
        typeImageList.unshift({rep:'설정 없음', tag : '', id: ''})
        
        setState({
          ...state,
          registry: registry === null ? '' : registry,
          totalArticle: props.namespace === userInfo?.userId ? commonImageList.length-1 : typeImageList.length-1
        })

        setTableItems(props.namespace === userInfo?.userId ? commonImageList : typeImageList)
      } else {
        setTableItems([])
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        console.log(e)
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 925563')
      }
    }
  }

  const onSelectHandler = (item:IBaseDataType) => {
    setState({
      ...state,
      selectedItem: item
    })
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    if (props.onSelected) props.onSelected(tableItems[state.selectedItem[0]], state.registry)
    closeHandler()
  }

  return (
    <ImageListPopupFragment>
      <div className='titleArea'>
        <h1 className='pageTitle'>이미지 목록<span className="bar"/><span><b>{state.totalArticle}</b>개</span></h1>
      </div>
      <div className='detailDataArea'>
        <div className='detailData'>
          <span className='itmeTitle'>리소스 그룹명</span><span className='item'>{props.namespace ? props.namespace === userInfo?.userId ? '기본 리소스그룹' : props.namespace : '리소스그룹'}</span>
        </div>
        <span className="bar" />
        <div className='detailData'>
          <span className='itmeTitle'>이미지 레지스트리</span><span className='item'>{state.registry ? state.registry : '리소스 그룹의 이미지 레지스트리가 없습니다. 관리자에게 문의해 주세요.'}</span>
        </div>
      </div>
        <div className='scrollWrap'>
        <Table ref={tableRef}
          colWidth={tableColWidth}
          headerLabel={headerLabel}
          items={tableItems}
          checkBoxType={CHECKBOX_TYPE.USE_SINGLE}
          name='image'
          noData={'등록된 이미지가 없습니다.'}
          checkBoxPreserveKeys={["selectedItem"]}
          defaultSortOrder={SORT_ORDER.ASC}
          preselectedKey="selectedItem"
          defaultSelect={true}
          onSelect={onSelectHandler} />
      </div>
      <div className='btnWrap'>
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={closeHandler}>취소</Button>
        {tableItems && state.selectedItem && tableItems[state.selectedItem[0]] && state.selectedItem[0] !== 0 ? 
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>선택완료</Button> :
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} disabled={true}>선택완료</Button>
        }
      </div>
    </ImageListPopupFragment>
  )
}

const ImageListPopupFragment = styled.div`
display:flex; flex-direction:column; width:1000px; height:calc(100vh - 186px); max-height:693px; padding:24px 6px 24px 32px; box-sizing:border-box;
  .pageTitle { font-size:24px; line-height:28.64px; font-weight:700; gap:16px!important; }
  p {font-size:14px;}
  .scrollWrap { overflow:auto;flex:1; padding-right:20px; }
	.btnWrap { flex:none; display:flex; justify-content:end; gap:12px; margin-top:24px; padding-right:24px; }
  .normal { font-size:16px; padding: 0px 16px; }

  .titleArea { margin-bottom:24px !important; margin-right:20px; width:936px; }
  .titleArea h1 .bar{ width:1px; height:20px; background:#D5D5DA; }
  .titleArea h1 span{ font-size:18px; font-weight:400; color:#878791; }
  .titleArea h1 span b{ color:#4B82FF; font-weight:700; }
  .detailDataArea { display:flex; gap:16px; width:912px; margin-bottom:12px; padding:8px 12px; align-items:center; gap:16px; align-self:stretch; border-radius:6px; background:#F7F8FB; }
  .detailDataArea .bar{ width:1px; height:10px; background:#878791; }
  .detailData span { font-size:13px; }
  .itmeTitle { font-weight:400; color:var(--ui-1646469, #646469); margin-right:12px; }
  .item {color: var(--g-20333333, #333); font-weight:600;}

  table { border-top:2px solid rgb(51, 51, 51) !important; width:936px; }
  tbody tr {height:40px;}
`

export default ImageListPopup