import { CSSProperties, useState , useEffect } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import ISelectOption from '../../interfaces/SelectOption'
import { IChartSeries } from '../../interfaces/Chart'
import { useRecoilState } from "recoil"
import { IUserAuthInfo, authState } from "../../states/authStates"
import BillingAPI, { IBilling } from "../../model/Billing"
import Utils from '../../utils'
import DateRangePicker from "../components/ui/DateRangePicker"
import BarChart from "../components/chart/BarChart"
import NoData from '../components/NoData'
import Select from "../components/ui/Select"
import { IUserInfo, userInfoState } from "../../states/userInfoState"
import Table , { CHECKBOX_TYPE, ITableHeader,ITableFooter, TABLE_CELL_TYPE }from '../components/ui/Table'
import PopupController from '../../controller/PopupController'
import { TIME_PICKER_TYPE } from '../components/ui/TimePicker'
import PageEvent from '../../events/PageEvent'
import { RESOURCE_TYPE } from '../../model/Workspace'

interface IBlock {
  blockType:string
  fee:number
  numBlock:number
  workingTime:number
}

interface IUsuage {
  resourceGroup:string
  name:string
  resourceInfo:string
  period:string
  totalWorkingTime:string
  fee?:number
  style?:CSSProperties
  ellipsisStyleName?:CSSProperties
  ellipsisStyleInfo?:CSSProperties
}

interface IBillingProps {
}

interface IBillingState {
  range:{
    from:string
    to:string
  },
  limit:{
    from:string
    to:string
  },
  page:number
  totalArticle:number
  tableTotal:ITableFooter[]
  tableTotal2:ITableFooter[]
}

interface IBillingTotalData {
  categories:string[]
  chartData:IChartSeries[]
}

interface IBillingMonthlyData {
  total:{
    count:number
    time:number
    gpuTotalTime:number
    gpuTotalTimeHour:number
    gpuTotalTimeMin:number
    cpuTotalTime:number
    cpuTotalTimeHour:number
    cpuTotalTimeMin:number
    fee:number
  },
  gpuList:IBlock[],
  tableItems:IUsuage[]
}

const Billing = (props:IBillingProps) => {
  const popupController = PopupController.getInstance()  
  const [ authInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  let startTimestamp:number = userInfo?.startTimestamp || 0
  let startFrom:string = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
  let initialFrom:string = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const [ state, setState ] = useState<IBillingState>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: startFrom,
      to: defaultTo
    },
    page: 1,
    totalArticle: 1,
    tableTotal:[],
    tableTotal2:[],
  })

  const [ monthList, setMonthList ] = useState<ISelectOption[]>([])
  const [ month, setMonth ] = useState<ISelectOption>({ label: dayjs().format('YYYY년 M월'), value: dayjs().format('YYYY-MM') })

  const [ totalData, setTotalData ] = useState<IBillingTotalData>({
    categories:[],
    chartData: []
  })

  const [ monthlyData, setMonthlyData ] = useState<IBillingMonthlyData>({
    total: {
      count: 0,
      time: 0,
      gpuTotalTime: 0,
      gpuTotalTimeHour: 0,
      gpuTotalTimeMin: 0,
      cpuTotalTime: 0,
      cpuTotalTimeHour: 0,
      cpuTotalTimeMin: 0,
      fee: 0
    },
    gpuList: [],
    tableItems:[]
  })

  const tableColWidth = [265, 250, 362, 220, 220]
  const headerLabel:ITableHeader[] = [
    { label: '리소스 그룹', key: 'resourceGroup', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '리소스 타입 / 개수', key: 'resourceInfo', type: TABLE_CELL_TYPE.HTML, sort: false },
    { label: '사용 기간', key: 'period', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey:'style' },
    { label: '총 사용 시간', key: 'totalWorkingTime', type: TABLE_CELL_TYPE.HTML, sort: false },
    // { label: '요금', key: 'fee', type: TABLE_CELL_TYPE.NUMBER, sort: false }
  ]

  useEffect(() => {
    getMonthlyData(month)

    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return () => {
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
  },[])

  useEffect(() => {
    // Layout에서 userInfo 정보 세팅이 끝나면 Init 진행
    if(userInfo?.startTimestamp) {
      Init(userInfo?.startTimestamp)
    }
  }, [userInfo?.startTimestamp])
  const Init = (startTimestamp:number) => {
    // monthList 계산 (월별 데이터 select 구성)
    const startMonth = dayjs.unix(startTimestamp).format('YYYY-MM')
    const diff = dayjs().diff(startMonth, 'month')
    let i:number,
      monthList:ISelectOption[] = []
    for (i = 0; i <= diff; i++) {
      monthList.push({
        label: dayjs().add(-i, 'month').format('YYYY년 M월'),
        value: dayjs().add(-i, 'month').format('YYYY-MM')
      })
    }

    // 그래프용 날짜 계산
    startFrom = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
    initialFrom = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
    initialTo = dayjs().format('YYYY-MM-DD HH:mm')
    defaultFrom = initialFrom
    defaultTo = initialTo
 
    setState({
      ...state,
      range: {
        from: defaultFrom,
        to: defaultTo
      },
      limit: {
        from: startFrom,
        to: defaultTo
      }
    })
    setMonthList(monthList)

    // 그래프 데이터 호출
    getTotalData({ from: defaultFrom, to: defaultTo })

    // 월별 데이터는 가장 최신 월의 데이터를 바로 가져오기 때문에 따로 호출해줄 필요는 없음...
  }

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const getMonthlyData = async (newMonth:ISelectOption): Promise<void> => {
    try {
      const start = dayjs(`${newMonth.value}-01`).unix()
      const end = dayjs(`${newMonth.value}-01`).add(1, 'month').unix()
      let data = await BillingAPI.getMontlyData(start, end)
      if (data) {
        let detailList:IUsuage[] = [],
            detail:IUsuage,
            i:number, item:any,
            totalCount:number = 0,
            totalTime:number = 0
            //totalFee:number = 0
        if (data.userObj.length) {
          for (i = 0; i < data.userObj[0].object.length; i++) {
            item = data.userObj[0].object[i]
            detail = {
              resourceGroup: item.rgroup,
              name: item.name,
              resourceInfo: item.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
              `${item.resourceBlock.type} / <b>${item.resourceBlock.gpuNum} 개</b>` : 
              `${item.resourceBlock.type}, CPU: <b>${item.resourceBlock.cpu} 코어</b>, MEM: <b>${item.resourceBlock.mem} ${item.resourceBlock.memUnit}</b>`,
              period: `${dayjs(item.startedAt*1000).format('YYYY-MM-DD HH:mm')} ~\n ${item.deletedAt !== null ? dayjs(item.deletedAt*1000).format('YYYY-MM-DD HH:mm') : dayjs().format('YYYY-MM-DD HH:mm')+' (가동중)'}`,
              totalWorkingTime: Math.floor(item.usage/60) > 0 ? `${Utils.numberMark(Math.floor(item.usage/60))} 시간 ${Utils.numberMark(item.usage%60)} 분` : `${Utils.numberMark(item.usage)} 분`,
              //fee: item.fee,
              style: { textAlign:'center', whiteSpace:'pre-line' },
              ellipsisStyleName: { width: '220px' },
              ellipsisStyleInfo: { width: '340px' }
            }
            totalCount = totalCount + item.resourceBlock.gpuNum
            totalTime = totalTime + item.usage
            //totalFee = totalFee + item.fee
            detailList.push(detail)
          }
        }
  
        const gpuTotalTimeHour = Math.floor(data.gpuUsage/60),
        gpuTotalTimeMin = data.gpuUsage%60,
        cpuTotalTimeHour = Math.floor(data.cpuUsage/60),
        cpuTotalTimeMin = data.cpuUsage%60
  
        setMonthlyData({
          ...monthlyData,
          total: {
            count: data.numObj,
            time: data.totalTime,
            gpuTotalTime: data.gpuUsage,
            gpuTotalTimeHour: gpuTotalTimeHour,
            gpuTotalTimeMin: gpuTotalTimeMin,
            cpuTotalTime: data.cpuUsage,
            cpuTotalTimeHour: cpuTotalTimeHour,
            cpuTotalTimeMin: cpuTotalTimeMin,
            fee: data.totalFee
          },
          gpuList: data.blockList,
          tableItems: detailList,
        })
        
        if (data.userObj.length) {
          setState({
            ...state,
            tableTotal: data.gpuUsage !== null ? [
              { label: 'GPU 리소스 블록 사용 합계' },
              { label: '' },
              { label: '' + Utils.numberMark(totalCount), unit: '개' },
              { label: '' },
              gpuTotalTimeHour > 0 ?
              {label: '' + Utils.numberMark(gpuTotalTimeHour), unit: ' 시간 ', label2: '' + Utils.numberMark(gpuTotalTimeMin), unit2: ' 분', color: '#2c78ff' } :
              {label: '' + Utils.numberMark(data.gpuUsage), unit: ' 분', color: '#2c78ff' },
            ] : [],
            tableTotal2: data.cpuUsage !== null ? [
              { label: 'CPU 리소스 사용 합계' },
              { label: '' },
              { label: '-', unit: '' },
              { label: '' },
              cpuTotalTimeHour > 0 ?
              { label: '' + Utils.numberMark(cpuTotalTimeHour), unit: ' 시간 ', label2: '' + Utils.numberMark(cpuTotalTimeMin), unit2: ' 분', color: '#2c78ff' } :
              { label: '' + Utils.numberMark(data.cpuUsage), unit: ' 분', color: '#2c78ff' },
            ] : []
          })
        }
      }
    } catch (error: any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 8e88d5')
    }
  }

  const getTotalData = async (newRange:any):Promise<void> => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()
      let data = await BillingAPI.getTotalData(FROM, TO)
      if (data) {
        const chartData = parseChartData(data.total)
        setTotalData({
          ...totalData,
          categories: chartData.categories,
          chartData: chartData.serise
        })
      }
    } catch (error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 7998e1')
    }
  }

  const parseChartData = (data:IBilling[]) => {
    let categories:string[] = []
    let seriseData:number[] = []
    let i:number, item:IBilling
    for (i = 0; i < data.length; i++) {
      item = data[i]
      categories.push(`${item.month}월`)
      seriseData.push(item.totalUsage)
    }
    return {
      categories: categories,
      serise: [{
        name: '',
        data: seriseData,
        showInLegend: false,
        color: '#BECC23'
      }]
    }
  }

  const onChangeDatePicker = (range:any) => {
    setTotalData({
      ...totalData,
      categories: [],
      chartData: []
    })
    setState({
      ...state,
      range: {
        from: range.from,
        to: range.to
      }
    })
    getTotalData(range)
  }

  const getMonthlyDataExcel = async () => {
    if (authInfo) {
      const start = dayjs(`${month.value}-01`).unix()
      const end = dayjs(`${month.value}-01`).add(1, 'month').unix()
      const filename = month.value.replace('-', '')+'-AI Pub DEV_download_'+dayjs().format('YYYYMMDDHHmm')+'.xlsx'
      
      try {
        BillingAPI.download(start, end, filename)
      } catch (error:any) {
        popupController.confirm('파일 다운로드에 실패하였습니다.')
      }
    }
  }

  const onChangeSelect = (data:ISelectOption) => {
    if(data.label !== '' && data.label !== month.label) {
      setMonth(data)
      getMonthlyData(data)  
    }
  }

  return (
    <BillingDetailFragment>
      <h2 className="pageTitle">{userInfo?.name}님의 리소스 사용 내역</h2>
        <DateRangePicker changeMessageHideFlag={true} initial={{from: initialFrom, to: initialTo}} 
          default={{from: defaultFrom, to: defaultTo}} limit={state.limit} 
          data={state.range} onChange={onChangeDatePicker} type={TIME_PICKER_TYPE.NONE} />
        <p className="unit"></p>
        {
          totalData.chartData.length ? 
          <BarChart unit="분" height={320} min={0} data={totalData.chartData} categories={totalData.categories} /* totalLabel="총 사용 시간" */ hideTotalFlag={true} 
          toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">\u25CF</span> ${this.x}&nbsp;&nbsp;<b><span style="color:#2C78FF">${this.y?.toLocaleString('ko-KR')}</span> 분</b><br/>`
          }} /> :
          <NoData height={320} />
        }
        <div className="divider"></div>
        <div className="detailTitle">
          <div>
            <Select option={monthList} selected={month} onChange={onChangeSelect} />
            <h3 className="title">리소스 사용 내역</h3>
          </div>
          <button className="btn outline pre" onClick={getMonthlyDataExcel}><img src="/images/excel.png" alt='excel' />다운로드</button>
        </div>
        <div className={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? 'summary' : 'summary short'}>
          <dl>
            <dt>생성된 워크스페이스 개수</dt>
            <dd style={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? { marginTop: '37px' } : { marginTop: '12px' }}><span>{Utils.numberMark(monthlyData.total.count)}</span> 개</dd>
          </dl>
          <dl className={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? '' : 'short'}>
          <dt>총 사용 시간</dt>
          <dd style={{ marginTop: '12px' }}>
            {monthlyData.total.gpuTotalTime !== null ?
              <div>GPU : <span>{Utils.numberMark(monthlyData.total.gpuTotalTimeHour)}</span> 시간 <span>{Utils.numberMark(monthlyData.total.gpuTotalTimeMin)}</span> 분</div>
              : false }
            { monthlyData.total.cpuTotalTime !== null ?
            <div>CPU : <span>{Utils.numberMark(monthlyData.total.cpuTotalTimeHour)}</span> 시간 <span>{Utils.numberMark(monthlyData.total.cpuTotalTimeMin)}</span> 분</div>
              : false }
          </dd>
          </dl>
        </div>
        {/* <h4 className="title">{dayjs.utc(`${month.value}-01`).format('YYYY년 MM월')} 워크스페이스 / Job 사용 명세</h4> */}
        <Table colWidth={tableColWidth} 
               headerLabel={headerLabel} 
               tableTotal={state.tableTotal}
               tableTotal2={state.tableTotal2}
               items={monthlyData.tableItems} 
               noData="데이터가 없습니다." //워크스페이스 / Job 이용내역이 없습니다.
               checkBoxType={CHECKBOX_TYPE.NONE} />
    </BillingDetailFragment>
  )
}

const BillingDetailFragment = styled.section`
  .pageTitle { margin-bottom:43px; }
  .unit { margin:30px 0 40px; font-size:14px; text-align:right; }
  .detailTitle { display:flex; align-items:center;justify-content:space-between; }
  .detailTitle > div { display:flex; align-items:center; }
  .detailTitle p { border:none; color:#202020; font-size:24px; font-weight:bold; }
  .title { font-size:18px; font-weight:bold; }
  .title > div { margin-left:10px; }
  .summary { display:flex; height:185px; align-items:center; margin:30px 0 50px; background:#f5f5f5; }
  .summary.short { display:flex; height:128px; align-items:center; margin:30px 0 50px; background:#f5f5f5; }
  .summary dl { position:relative; flex:1; text-align:center; color:#303030; }
  .summary dl + dl:before { content:''; position:absolute; left:0; top:46px; width:1px; height:93px; margin-top:-24px; background:#a7a7a7; }
  .summary dl + dl.short:before { content:''; position:absolute; left:0; top:46px; width:1px; height:47px; margin-top:-24px; background:#a7a7a7; }
  .summary dd { margin-top:15px; font-size:32px; font-weight:bold; }
  .summary dd span { color:#2c78ff; }
  .summary dd b { font-weight:bold; }
  .summary > dl > dd > div + div { margin-top:12px; }

  .gpuBox { margin:10px -20px 64px; color:#303030; font-size: 0 }
  .gpuBox .inner { display:inline-block; width:25%; padding:20px; box-sizing:border-box; }
  .gpuBox .inner > div { border:1px solid #dfdfdf; }
  .gpuBox .titleBox { padding:15px 20px 10px; border-bottom:1px solid #dfdfdf; }
  .gpuBox .titleBox .boxTitle { font-size:16px; }
  .gpuBox .contentBox { padding:10px 20px 15px; }
  .gpuBox .detail { font-size:14px; }
  .gpuBox .dl { display:flex; align-items:center; justify-content:space-between; font-size:14px; }
  .gpuBox .dl + .dl { margin-top:10px; }
  .gpuBox .boxTitle,
  .gpuBox .dt,
  .gpuBox b { font-weight:bold; }
  .gpuBox b span { color:#2c78ff; }
  table { margin-top:28px; }
  table .col2,
  table .col3,
  table .col4 { text-align:center; }
`

export default Billing