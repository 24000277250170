import { useEffect, useState } from 'react'
import styled from 'styled-components'

import SchedulerEvent from '../../../events/SchedulerEvent'
import TableEvent from '../../../events/TableEvent'
import { ISettings, changeModeState, settingState } from '../../../states/settingState'
import { useRecoilState } from 'recoil'

interface IPriorityChangeProps {
  objName:string
  first:boolean
  end:boolean
}

interface IPriorityChangeState {
  schedulerFlag:boolean
  changeModeFlag:boolean
}

const PriorityChange = (props:IPriorityChangeProps) => {
  const [ settings ] = useRecoilState<ISettings|null>(settingState)
  const [ changeModeFlag ] = useRecoilState<boolean>(changeModeState)

  const [ state, setState ] = useState<IPriorityChangeState>({
    schedulerFlag: false,
    changeModeFlag: false
  })

  useEffect(() => {
    setState({
      schedulerFlag: settings?.schedulerFlag || false,
      changeModeFlag: changeModeFlag ||false
    })

    window.addEventListener(SchedulerEvent.UPDATED, schedulerHandler)
    window.addEventListener(SchedulerEvent.EDIT_START, schedulerHandler)
    window.addEventListener(SchedulerEvent.EDIT_END, schedulerHandler)
    window.addEventListener(TableEvent.PRIORITY_MODE_FLAG_CHANGED, changeModeFlagHandler)

    return() => {
      window.removeEventListener(SchedulerEvent.UPDATED, schedulerHandler)
      window.removeEventListener(SchedulerEvent.EDIT_START, schedulerHandler)
      window.removeEventListener(SchedulerEvent.EDIT_END, schedulerHandler)
      window.removeEventListener(TableEvent.PRIORITY_MODE_FLAG_CHANGED, changeModeFlagHandler)
    }
  }, [])

  const schedulerHandler = async (e:SchedulerEvent):Promise<void> => {
    if (e.type === SchedulerEvent.EDIT_START) {
      setState({
        ...state,
        schedulerFlag: true
      })
    } else {
      setState({
        ...state,
        schedulerFlag: settings?.schedulerFlag || false
      })
    }
  }

  const changeModeFlagHandler = (e:TableEvent) => {
    setState({
      ...state,
      changeModeFlag: e.payload.modeFlag
    })
  }

  const priorityUpHandler = () => {
    let e:TableEvent = new TableEvent(TableEvent.CHANGE_ORDER)
    e.payload = {
      objName: props.objName,
      direction: -1
    }
    window.dispatchEvent(e)
  }

  const priorityDownHandler = () => {
    let e:TableEvent = new TableEvent(TableEvent.CHANGE_ORDER)
    e.payload = {
      objName: props.objName,
      direction: 1
    }
    window.dispatchEvent(e)
  }

  const priorityOffHandler = () => {
    let e:TableEvent = new TableEvent(TableEvent.ACTION)
    e.payload = {
      action: 'priorityOff',
      objName: props.objName
    }
    window.dispatchEvent(e)
  }

  return (
    <PriorityChangeFragment className={state.changeModeFlag ? 'active' : ''}>
      <button className={props.end ? 'arrow btnDown off' : 'arrow btnDown'} onClick={priorityDownHandler}>↓</button>
      <button className={props.first ? 'arrow btnUp off' : 'arrow btnUp'} onClick={priorityUpHandler}>↑</button>
      <button className={"btn text "+(state.changeModeFlag ? 'outline' : 'off')} onClick={priorityOffHandler}>우선순위 내리기</button>
    </PriorityChangeFragment>
  )
}

const PriorityChangeFragment = styled.div`
  text-align:center;
  button.arrow{width:24px;height:24px;font-size:0;padding:0;line-height:0;margin: 0 5px;background: url(/images/bi-arrow-up-square.png) no-repeat center / 100%;}
  button.arrow.btnDown{transform:rotate(180deg)}
  button.text { width:96px;height:24px;font-size:12px;margin-top:5px;padding:0;line-height:0 }
  button.off { color:#fff; pointer-events:none; }
  &.active button.arrow:not(.off){background-image: url(/images/bi-arrow-up-square-on.png)}
  &.active button.arrow:not(.off):hover{background-image: url(/images/bi_arrow-up-square-fill.png)}
`

export default PriorityChange