import { useEffect } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import PageEvent from '../../events/PageEvent'
import TableEvent from "../../events/TableEvent"

let flag = true

const NavigationDev = () => {

  const getStatus = () =>{
    if ( flag === true ){
      return 'current'
    }
    else {
      return ''
    }
  }

  useEffect(() => {
    document.addEventListener(TableEvent.RECREATE_WORKSPACE,changeStatusFalse)
    return () => {
      document.removeEventListener(TableEvent.RECREATE_WORKSPACE,changeStatusFalse)
    }
  },[])

  const changeStatusFalse = () => {
    flag = false
    return flag
  }

  const changeStatusTrue = () => {
    const current = (window.location.pathname).split('/')
    
    if(current[2] !='new' && current[1]==='workspace'){
      return 'current'
    }else {
      return ''
    }
    
  }

  const moveToSamePage = () => {
    let e = new PageEvent(PageEvent.REFRESH_PAGE_FORM)
    flag = true
    window.dispatchEvent(e)
  }

  return (
    <NavigationFragment>
      <h1><NavLink to="/workspaces"><img src="/images/logo.png" alt='AIPub' /></NavLink></h1>
      <nav>
        <h2 className="hide">메뉴</h2>
        <NavLink to="/alert" className={isActive => isActive.isActive ? 'current' : ''}><img src="/images/nav-alert.png" alt="+"/>통합 알림 센터</NavLink>
        <NavLink to="/workspace/new" className={isActive => isActive.isActive ? 'current' : ''} onClick={moveToSamePage}><img src="/images/nav-add.png" alt="+"/>워크스페이스 신청</NavLink>
        {/* <a className="navParent"><img src="/images/nav-add.png" alt="+"/>신청</a> */}
        {/* <NavLink to="/workspace/new" className={isActive => isActive.isActive ? getStatus()+' navChild' : 'navChild'} onClick={onClickSubmit}><div className='blankIcon'></div>워크스페이스</NavLink> */}
        {/* <NavLink to="/job/new" className={isActive => isActive.isActive ? getStatus()+' navChild' : 'navChild'} onClick={onClickSubmit}><div className='blankIcon'></div>Job</NavLink> */}
        <NavLink to="/workspaces" className={isActive => isActive.isActive ? 'current': changeStatusTrue()}><img src="/images/nav-list.png" alt="list"/>워크스페이스 목록</NavLink>
        <NavLink to="/images" className={isActive => isActive.isActive ? 'current' : ''}><img src="/images/nav-image.png" alt="+"/>이미지 목록</NavLink>
        <NavLink to="/billing" className={isActive => isActive.isActive ? 'current' : ''}><img src="/images/nav-billing.png" alt="+"/>사용 내역</NavLink>
        <div className="depth">
          {/*<p>워크스페이스</p>*/}
        </div>
      </nav>
      <div className="outlink">
        <h3>Powered by</h3>
        <a href="https://jupyter.org/" target="_blank" rel="noreferrer"><img src="/images/jupyter-notebook.png" alt="Jupyter Notebook icon"/>Jupyter Notebook</a>
        <a href="https://tensorboard.dev/" target="_blank" rel="noreferrer"><img src="/images/tensorboard.png" alt="Tensorboard icon"/>Tensorboard</a>
      </div>
      {/* <div className="outlink" style={{marginTop:'-20px'}}>
        <a href="/Ten_AI Pub_서비스 매뉴얼_1.1_사용자용.pdf" download={"Ten_AI Pub_서비스 매뉴얼_1.1_사용자용.pdf"}><img src="/images/nav-menual-download.png" alt="Menual Download icon"/>매뉴얼 다운로드</a>
      </div> */}
    </NavigationFragment>
  )
}

const NavigationFragment = styled.aside`
  flex:none; width:234px; background:#f5f5f5;

  h1{ height:60px; background:#f5f5f5; font-size:0; }
  h1 img { margin:17px 30px; width:84px; }
  nav a { display:flex; align-items:center; gap:10px; height:40px; margin-top:15px; padding:0 0 0 30px; color:#303030; font-weight:bold; transition:all 0.3s; font-size:14px; }
  nav a:hover { background:#ebebeb; }
  nav a.current { background:rgba(44, 120, 255, 0.25); }
  nav .depth p { margin-top:15px; padding:0 30px; line-height:40px; color:#868686; font-weight:bold; }
  nav .depth a { margin-top:0; }
  nav a img { width:13px; }

  div.outlink { margin:30px; padding:10px; border:1px dashed #ccc; font-size:12px; color:#868686; }
  div.outlink h3 { margin-bottom:8px; font-weight:normal; font-size:12px; }
  div.outlink a { display:block; color:#868686; }
  div.outlink a+a { margin-top:5px; }
  div.outlink a img { display:inline-block; height:11px; margin-right:5px; vertical-align:middle; }

  // 신청 메뉴 (지금 사용하지 않아 주석처리 되어있음)
  .navParent:hover { background:#f5f5f5; }
  .navChild { margin-top:0%; }
  .blankIcon { width:13px; }
`

export default NavigationDev