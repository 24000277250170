import React, { useEffect, useState } from 'react'
import styled, { ThemeConsumer } from 'styled-components'

import Tooltip from "../ui/Tooltip"
import Toggle from "../ui/Toggle"
import Select from "../ui/Select"
import ISelectOption from "../../../interfaces/SelectOption"
import MonitoringAPI, { IMigData } from "../../../model/Monitoring"
import ModalEvent, { MODAL_TYPE } from "../../../events/ModalEvent"
import PopupController from '../../../controller/PopupController'


interface IMigPopupProps {
  onClose:Function
  onSelected:Function
  groupName:string
}

interface IMigPopupState {
  tableItems:IMigData[]
  objCount:number
}

const MigPopup = (props:IMigPopupProps) => {
  const [ state, setState ] = useState<IMigPopupState>({
    tableItems: [],
    objCount:0
  })
  // const migNum:ISelectOption[] = []
  const [ migNumList, setMigNumList ] = useState<ISelectOption[]>([])
  const popupController = PopupController.getInstance()

  useEffect(()=>{
    let list = []
    for (let i = 2; i < 8; i++) {
      list.push({
        label: String(i),
        value: String(i),
      })
    }
    setMigNumList(list)
    getData()

    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return() => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  const getData = async () => {
    try {
      if (props.groupName) {
        const items = await MonitoringAPI.getMigData(props.groupName)
        if (items.status) {
          closeHandler()
        } else {
          setState({
            ...state,
            tableItems: items.migSettingList,
            objCount: items.objCount
          })
        }
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - eb84e0')
    }
  }

  const inputHandler = (target:string, value:any, idx:number = 0) => {
    const items = state.tableItems
    switch(target) {
      case 'isOnMig':
        items[idx].isOnMig = value
        items[idx].numMig = value ? 2 : 1
        setState({
          ...state,
          tableItems: items
        })
        break
      case 'numMig':
        items[idx].numMig = value !== null ? value : 1
        setState({
          ...state,
          tableItems: items
        })
        break
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = async () => {
    if (props.onSelected) props.onSelected(props.groupName, state.tableItems, state.objCount)
    // this.closeHandler()
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'setMigData':
        closeHandler()
        break
    }
  }

  return(
    <MigPopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
      <h2 className="pageTitle">
        MIG 설정
        {/* <Tooltip des="각 GPU 별로 MIG(Multi-Instance GPU) 설정이 가능합니다. 1~7개로 분할 가능합니다. 각 GPU 별 MIG 분할 개수 설정 후 적용 시 노드를 재시동합니다. " /> */}
      </h2>
      <p className="pageTip"><img src='/images/star.png' alt='important' className="importantImg" /> MIG 설정을 위해 해당 노드에서 운영 중인 워크스페이스 삭제 후 MIG를 적용해 주세요. 운영 중인 워크스페이스가 있는 경우 MIG 설정 시, 회수 처리됩니다.</p>
      <div className="scrollWrap">
        {
          state.tableItems.map((eachGpu, idx) => {
            return (<div className="eachMig" key={idx}>
              <div style={{ 'flex': 5 }}>
                <span className='title'>GPU {eachGpu.gpuIdx} : {eachGpu.gpuName}</span>
              </div>
              <div style={{ 'flex': 0.8 }}>
                <span className='title'>MIG 설정</span>
              </div>
              <div style={{ 'flex': 1, 'padding': '3px 0' }}>
                <Toggle id={'migFlag' + idx} checked={eachGpu.isOnMig} onChange={(checked: boolean) => {
                  inputHandler('isOnMig', checked, idx)
                }} />
              </div>
              <div style={{ 'flex': 1 }}>
                <Select option={migNumList} disabled={!eachGpu.isOnMig}
                  selected={ eachGpu.numMig !== null ? { label: String(eachGpu.numMig), value: String(eachGpu.numMig) } : { label: '1', value: '1'} } 
                  onChange={(data: ISelectOption) => {
                    inputHandler('numMig', data.value, idx)
                  }}></Select>
              </div>
            </div>)
          })
        }
      </div>
      <div className="btnWrap">
        <button className="btn grey" onClick={closeHandler}>취소</button>
        <button className="btn blue" onClick={submitHandler}>설정하기</button>
      </div>
    </MigPopupFragment>
  )
}

const MigPopupFragment = styled.div`
  display:flex;flex-direction:column;width:760px;height:560px;padding: 50px 50px 20px;box-sizing:border-box;
  .pageTitle{flex:none; margin-bottom:0px;}
  p.pageTip {margin-top:10px;font-size:14px}
  .scrollWrap{overflow:auto;flex:1;margin-top:20px;}
  .btnWrap{flex:none;display:flex;justify-content:center;gap:20px;margin-top:15px;}
  .btnWrap .btn{width:188px;height:40px;line-height:33px;}
  .noData {text-align:center;color:#A7A7A7;font-size:20px; display:flex; justify-content:center; align-items:center;height:100%; margin-top:-30px;}
  .col2 {text-align:center;}
  .eachMig {display:flex;align-content:center;padding:15px 0;border-bottom:1px solid #EBEBEB}
  .eachMig div span.title {display:inline-block;margin:9px 0}
  
  .blocked p {border-color:#A7A7A7;color:#A7A7A7;pointer-events:none}
  .importantImg {width:12px; height:12px; margin-right:3px;}
`

export default MigPopup