import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PopupController from '../../controller/PopupController'
import Select, { SELECT_SIZE } from '../components/uiv2/Select'
import ISelectOption from '../../interfaces/SelectOption'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../components/uiv2/Button'
import Table, { CHECKBOX_TYPE, TABLE_ALIGN, TABLE_CELL_TYPE } from '../components/uiv2/Table'
import Pagenation from '../components/uiv2/Pagenation'
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'
import { IResourceGroup, ResourceGroup } from '../../model/ResourceGroup'
import { IMAGE_COMMIT_STATUS, Image } from '../../model/Image'
import { SORT_ORDER } from '../../model/BaseDataType'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import TableEvent from '../../events/TableEvent'

interface IImageListProps {
}

interface IImageListState {
  reg:string

  //개수
  totalArticle:number

  //리소스 그룹
  resourceGroupList:IResourceGroup[]
  resourceGroupSelectList:ISelectOption[]
  // resourceGroupSelected:ISelectOption|undefined

  //테이블
  page:number
  items:any[]
  selected:any[]
}

const ImageList = (props:IImageListProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const popupController = PopupController.getInstance()
  const ITEM_PER_PAGE = 50

  const tableHeadData = [
    {
      label: 'No',
      key: 'tableIdx',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: false,
      sort: false,
      align: TABLE_ALIGN.CENTER
    }, {
      label: '이미지 이름',
      key: 'rep',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: true,
      sort: SORT_ORDER.ASC,
      align: TABLE_ALIGN.LEFT
    }, {
      label: '태그',
      key: 'tag',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: false,
      sort: false,
      align: TABLE_ALIGN.LEFT
    }, {
      label: 'Image ID',
      key: 'id',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: false,
      sort: false,
      align: TABLE_ALIGN.CENTER
    }
  ]

  const tableRef = useRef<any>()

  const [ resourceGroup, _setRgroup ] = useState<ISelectOption>()
  const rgroupRef = useRef(resourceGroup)
  const setRgroup = (data:any) => {
    rgroupRef.current = data
    _setRgroup(data)
  }
  const [ state, _setState ] = useState<IImageListState>({
    reg: '',

    //개수
    totalArticle: 0,

    //리소스 그룹
    resourceGroupList: [],
    resourceGroupSelectList: [],
    // resourceGroupSelected: undefined,

    //테이블
    page: 1,
    items: [],
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getRgroupList()

    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    if(resourceGroup !== undefined) {
      getData()
    }
  }, [resourceGroup])

  const eventControl = (e:Event) => {
    getData()
  }

  const getRgroupList = async () => {
    try {
      const response = await ResourceGroup.getRgroupList(authInfo.userNo)
      if(response) {
        let resourceGroupSelectList:ISelectOption[] = []
        for (let key in response.rgroup) {
          if (response.rgroup[key].isCommon) {
            resourceGroupSelectList.push({
              fakeLabel: '기본 리소스 그룹',
              label: response.rgroup[key].name,
              value: key
            })
          } else {
            resourceGroupSelectList.push({
              label: response.rgroup[key].name,
              value: key
            })
          }
        }
        setState({
          ...state,
          resourceGroupList: response.rgroup,
          resourceGroupSelectList: resourceGroupSelectList,
          //resourceGroupSelected: resourceGroupSelectList.length > 0 ? resourceGroupSelectList[0] : undefined
        })
        setRgroup(resourceGroupSelectList.length > 0 ? resourceGroupSelectList[0] : undefined)
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e16df0')
    }
  }

  const getData = async (page?:number) => {
    if(rgroupRef.current === undefined) return
    try {
      const isDefault = state.resourceGroupList !== undefined && rgroupRef.current?.fakeLabel !== undefined
      const namespace = isDefault ? authInfo?.id : rgroupRef.current?.label || ''
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      const pageNum = page ? page : stateRef.current.page
      const data = await Image.getList(namespace, pageNum, ITEM_PER_PAGE, sortOrder, isDefault ? 0 : 1)
      if (data) {
        for (let idx in data.imageList) {
          let eachItem = data.imageList[idx]
          if (eachItem.commitInfo === null) {
            eachItem.tableCommitFlag = false
          } else {
            eachItem.tableCommitFlag = true
            if (eachItem.commitInfo.status !== undefined) {
              switch (eachItem.commitInfo.status) {
                case IMAGE_COMMIT_STATUS.PROGRESS:
                case IMAGE_COMMIT_STATUS.PENDING:
                  eachItem.id = '커밋 중'
                  break
                case IMAGE_COMMIT_STATUS.FAILED:
                  eachItem.id = '커밋 실패'
                  break
              }
            }
          }
          eachItem.tableIdx = (Number(idx)+1)+(pageNum-1)*ITEM_PER_PAGE //Number(idx)+1
        }
        setState({
          ...stateRef.current,
          totalArticle: data.total,
          reg: data.reg === null ? '' : data.reg,
          items: data.imageList,
          page: page ? page : stateRef.current.page
        })
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e9c642')
    }
  }

  const onChangeSelectRgroup = (rgroup: ISelectOption) => {
    if(rgroup.label !== ''){
      setRgroup(rgroup)
    }
  }

  const navigateToPage = (page:number):void => {
    if(page === stateRef.current.page) return
    getData(page)
  }

  const selectboxCheckHandler = (selectedIdx:number[]):void => {
    if (state.items && selectedIdx && selectedIdx.length > 0){
      let selectedArray:any[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.items[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const removeHandler = () => {
    try {
      let targetIdArray:any[] = []
      const items = state.items
      const selectedIdx = tableRef.current?.selected
      if (selectedIdx.length === 0) return
      if (items && selectedIdx && selectedIdx.length > 0) {
        for (let eachIdx of selectedIdx) {
          targetIdArray.push({
            rep: items[Number(eachIdx)].rep,
            tag: items[Number(eachIdx)].tag
          })
        }
        const rgroup = rgroupRef.current?.fakeLabel ? rgroupRef.current?.fakeLabel : rgroupRef.current?.label
        let message = ''
        if (targetIdArray.length > 1) {
          message = `${rgroup} 리소스 그룹의 ${targetIdArray[0].rep}:${targetIdArray[0].tag} 외 ${targetIdArray.length - 1}개 이미지가 삭제됩니다. 진행하시겠습니까?`
        }
        else {
          message = `${rgroup} 리소스 그룹의 ${targetIdArray[0].rep}:${targetIdArray[0].tag} 이미지가 삭제됩니다. 진행하시겠습니까?`
        }

        popupController.dialouge(message, 'deleteImage', targetIdArray, '확인', '취소')
        setState({
          ...state,
          deleteItems: targetIdArray
        })
      } else {
        popupController.confirm(`선택된 항목이 없습니다.`)
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteImage':
        try {
          const isDefault = state.resourceGroupList !== undefined && rgroupRef.current?.fakeLabel !== undefined
          const deleteItems = e.payload.key
          const type = isDefault ? 0 : 1 //0:기본리소스그룹 이미지, 1:리소스그룹이미지
          const name = isDefault ? authInfo.id : rgroupRef.current?.label
          const response = await Image.deleteImages(deleteItems, name || '', type)
          const rgroup = rgroupRef.current?.fakeLabel ? rgroupRef.current?.fakeLabel : rgroupRef.current?.label
          let message:string = ''
          if(!('failedCount' in response)) {
            if (deleteItems && deleteItems.length > 0) {
              if (deleteItems.length === 1) {
                message = `${rgroup} 리소스 그룹의 ${deleteItems[0].rep}:${deleteItems[0].tag} 이미지가 삭제되었습니다.`
              } else {
                message = `${rgroup} 리소스 그룹의 ${deleteItems[0].rep}:${deleteItems[0].tag} 외 ${deleteItems.length - 1}개의 이미지가 삭제되었습니다.`
              }
              let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
            }  
          } else {
            // failed가 있는 상태
            if(deleteItems.length > 1) {
              message = `${rgroup} 리소스 그룹의 이미지 ${deleteItems.length}개 삭제 과정 중 ${response.failedCount}개 항목 삭제에 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.deletedCount}개 / 실패 ${response.failedCount}개`
            } else {
              message = `${rgroup} 리소스 그룹의 ${deleteItems[0].rep}:${deleteItems[0].tag} 이미지 삭제에 실패하였습니다. 다시 시도해 주세요.`
            }
            popupController.confirm(message)
          }

          // 삭제 후 갱신
          tableRef.current?.setSelected([])
          getData()
        } catch(error){
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - c75b18')
        }
        break
    }
  }

  return (
    <PageFragment>
      <div className='pageTitle' style={{display:'flex', gap:'16px'}}>
        <div>이미지 목록</div>
        <div className='dividerVertical'></div>
        <div className='count'><span>{state.totalArticle}</span>개</div>
      </div>
      <div style={{display:'flex', alignItems:'start', justifyContent:'space-between', marginBottom:'12px'}}>
        <div style={{display:'flex', gap:'12px', width:'1000px' }}>
          <Select
            option={state.resourceGroupSelectList}
            selected={resourceGroup}
            onChange={onChangeSelectRgroup} 
            width='400px'
            size={SELECT_SIZE.SMALL}
            disabled={state.resourceGroupSelectList.length <= 1} />
          <div className='registryBox'><span style={{color:'#646469'}}>이미지 레지스트리</span><span style={{marginLeft:'12px', fontWeight:'600'}}>{state.reg ? state.reg : '-'}</span></div>
        </div>
        <div>
          <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={removeHandler} disabled={tableRef.current?.selected.length === 0}>선택 항목 삭제</Button>
        </div>
      </div>
      <Table checkBoxType={CHECKBOX_TYPE.USE_ALL} colWidth={[42, 50, 780, 240, 200]} ref={tableRef}
        headerLabel={tableHeadData}
        items = {state.items}
        noData={state.reg ? '데이터가 없습니다.' : '리소스 그룹의 이미지 레지스트리가 없어 이미지 확인이 불가합니다. 관리자에게 문의해 주세요.'}
        onSelect={selectboxCheckHandler} />
      { state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={ITEM_PER_PAGE} onChange={(newPage: number) => navigateToPage(newPage)} /> : false }
    </PageFragment>
  )
}

const PageFragment = styled.div`
color:#333333;
.pageTitle{font-size:24px; line-height:28.8px; font-weight:700; color:#1A1A1A; margin-bottom:49.5px;}
.pageTitle .dividerVertical{border-left:1px solid #D5D5DA; height:20px;}
.pageTitle .count{font-size:18px; line-height:21.6px; font-weight:400; color:#878791;}
.pageTitle .count span{font-weight:700; color:#4B82FF;}
.registryBox{height:16px; padding:8px 12px; background-color:#F7F8FB; border-radius:6px; font-size:13px; line-height:15.51px; font-weight:400;}

//table
table tbody td{padding:11.5px 12px;}
.col0{color:#3333334D;}

//select
.selectBox.disable{color:rgba(51, 51, 51, 1);}
`
  
export default ImageList
