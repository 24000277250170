export interface IBaseDataType {
  [propsName:string]:any
}

export type ObjType = {
  [index: string]:string
}

export interface keyValue {
  key:string,
  value:any
}

export enum SORT_ORDER {
  ASC = 0,
  DESC = 1
}

export enum IMAGE_TYPE {
  COMMON = 0,
  RGROUP = 1,
  USER = 2
}