import React, { useEffect, useRef, useState } from 'react'
import { NavigateFunction, Params, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { IWorkspaceListItem, RESOURCE_TYPE, WORKSPACE_LIST_ORDER_BY, WORKSPACE_STATUS, Workspace } from '../../model/Workspace'
import { IChartSeries } from '../../interfaces/Chart'
import ISelectOption from '../../interfaces/SelectOption'
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../components/ui/Table'
import { ObjType, SORT_ORDER } from '../../model/BaseDataType'
import NoData from '../components/NoData'
import PageEvent from '../../events/PageEvent'
import Monitoring from '../../model/Monitoring'
import PopupController from '../../controller/PopupController'
import ModalEvent from '../../events/ModalEvent'
import Pagenation from '../components/ui/Pagenation'
import Select from '../components/ui/Select'
import LineChart from '../components/chart/LineChart'
import DateRangePicker from '../components/ui/DateRangePicker'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import WorkspaceAdminStatus from '../components/table/WorkspaceAdminStatus'
import UserInfoPopup from '../components/modal/sub/UserInfoPopup'
import BlockPopup from '../components/modal/sub/BlockPopup'
import TableEvent from '../../events/TableEvent'
import SelectMulti from '../components/ui/SelectMulti'
import { ResourceGroup } from '../../model/ResourceGroup'
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'
import ToastEvent from '../../events/ToastEvent'
import { ERROR_TYPE } from '../../interfaces/Error'

enum FILTER {
  ALL = '전체 목록',
  STATUS = '상태별 목록',
  RGROUP = '리소스 그룹별 목록',
  NODE = '노드별 목록',
  WSJOB = '워크스페이스 / Job 선택'
}

enum FILTER_CHART {
  USER = '0',
  RGROUP = '1'
}

interface IWorkspaceListProps {
  navigate?:NavigateFunction
  params?:Params
}

interface IWorkspaceListState {
  page:number
  totalArticle:number
  tableItems:IWorkspaceListItem[]
  range:{
    from:string
    to:string
  }
  // chartData:IChartSeries[]
  refreshFlag:boolean
  nodeFilter:any
  itemNumber:number
  selected:IWorkspaceListItem[]
}

interface IFilterState {
  filter1:ISelectOption
  filterList2:ISelectOption[]
  filterList2Title:string
  filter2:ISelectOption[]
  selectedArray:any[]
}

const WorkspaceList = (props:IWorkspaceListProps) => {
  const [ authInfo ] = useRecoilState(authState)

  const params = useParams()
  const [ searchParams ] = useSearchParams()
  const popupController = PopupController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [52, 190, 200, 154, 146, 193, 230, 153]
  const headerLabel:ITableHeader[] = [
    { label: '리소스 그룹', key: 'tableResourceGroup', type: TABLE_CELL_TYPE.STRING, sort: true, orderFlag: true },
    { label: '이름', key: 'fakeName', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'name'},
    { label: '운영자', key: 'tableUserInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'user' },
    { label: '신청 시간', key: 'tableCreationTimestamp', type: TABLE_CELL_TYPE.STRING, sort: true, default: '-' },
    { label: '가동 시간', key: 'tableOperatingTime', type: TABLE_CELL_TYPE.STRING, sort: true, default: '-' },
    { label: '리소스 타입/개수', key: 'tableResourceType', type: TABLE_CELL_TYPE.FLEXBOX, sort: false /* sortKey: 'resourceBlock' */ },
    { label: '상태', key: 'tableStatus', type: TABLE_CELL_TYPE.FLEXBOX, sort: SORT_ORDER.ASC, sortKey: 'status' }
  ]

  const defaultFrom:string = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  const defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  const initialFrom:string = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')

  const statusLabel:ObjType = {
    [WORKSPACE_STATUS.ERROR]: '에러',
    [WORKSPACE_STATUS.FAILED]: '생성 실패',
    [WORKSPACE_STATUS.PENDING]: '생성 대기',
    [WORKSPACE_STATUS.CANCELED]: '신청 취소',
    [WORKSPACE_STATUS.RUNNING]: '생성됨',
    [WORKSPACE_STATUS.RETURNED]: '반납 완료',
    [WORKSPACE_STATUS.RECALLED]: '회수됨',
    [WORKSPACE_STATUS.COMPLETED]: 'Job 완료'
  }
  const filterList = [
    {label: '전체 목록', value: FILTER.ALL},
    {label: '상태별 목록', value: FILTER.STATUS},
    {label: '리소스 그룹별 목록', value: FILTER.RGROUP},
    {label: '노드별 목록', value: FILTER.NODE},
    // {label: '워크스페이스 / Job 선택', value: FILTER.WSJOB}, // Job을 사용하지 않아서 잠시 숨김 처리
  ]
  const filterChart = [
    { label: '사용자 계정별 GPU 할당률', value: FILTER_CHART.USER },
    { label: '리소스 그룹별 GPU 할당률', value: FILTER_CHART.RGROUP }
  ]
  const [ filterState, _setFilterState ] = useState<IFilterState>({
    filter1: {label: '전체 목록', value: FILTER.ALL},
    filterList2: [],
    filterList2Title: '',
    filter2: [],
    selectedArray: []
  })
  const filterStateRef = useRef(filterState)
  const setFilterState = (data:any) => {
    filterStateRef.current = data
    _setFilterState(data)
  }

  const [ chartSelect, setChartSelect ] = useState<ISelectOption>({
    label : '사용자 계정별 GPU 할당률', value : FILTER_CHART.USER
  })
  const [ chartData, setChartData ] = useState<IChartSeries[]>([])

  const [ nodeFlag, setNodeFlag ] = useState<boolean>(false)

  const [ state, _setState ] = useState<IWorkspaceListState>({
    totalArticle: 0,
    page: (params.page ? Number(params.page) : 1),
    tableItems: [],
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    // chartData: [],
    refreshFlag: false,
    nodeFilter: '',
    itemNumber: 0,
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if(searchParams.get('node') !== null) {
      // 1. 1차 필터를 노드로 바꾸기
      // 2. 2차 필터를 해당 노드값으로 바꾸기
      // 3. getData
      setNodeFlag(true)
      InitNodePageData()
    }
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    window.addEventListener(TableEvent.REFRESH, eventControl)
    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      window.removeEventListener(TableEvent.REFRESH, eventControl)
    }
  }, [])

  useEffect(() => {
    // 노드 필터 로딩할 때 사용
    if(searchParams.get('node') !== null && filterState.filter1?.value !== FILTER.ALL) {
      getData(state.page, filterState.filter1, filterState.selectedArray)
    }
  }, [filterState.selectedArray])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    if(searchParams.get('node') !== null) { return }
    getData(stateRef.current.page, filterStateRef.current.filter1, filterStateRef.current.selectedArray)
  }

  const getData = async (page?:number, filter1?:ISelectOption, filter2Array?:any[]):Promise<void> => {
    let sortKey:WORKSPACE_LIST_ORDER_BY
    switch(tableRef.current?.sortKey) {
      case 'name':
        sortKey = WORKSPACE_LIST_ORDER_BY.NAME
        break
      case 'user':
        sortKey = WORKSPACE_LIST_ORDER_BY.USER
        break
      case 'tableCreationTimestamp':
        sortKey = WORKSPACE_LIST_ORDER_BY.CREATION_TIMESTAMP
        break
      case 'tableOperatingTime':
        sortKey = WORKSPACE_LIST_ORDER_BY.START_TIMESTAMP
        break
      case 'tableResourceGroup':
        sortKey = WORKSPACE_LIST_ORDER_BY.RESOURCE_GROUP_NAME
        break
      case 'resourceBlock':
        sortKey = WORKSPACE_LIST_ORDER_BY.RESOURCE_TYPE
        break
      case 'status':
      default:
        sortKey = WORKSPACE_LIST_ORDER_BY.STATUS
        break
    }
    const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)

    let nodeFilter:any = undefined, rgroupFilter:any = undefined, statusFilter:any = undefined, isJob:any = undefined
    const filter1Value = filter1 ? filter1.value : filterStateRef.current.filter1?.value
    const filter2ArrayValue = filter2Array ? filter2Array : filterStateRef.current.selectedArray
    if (filter2ArrayValue && filter2ArrayValue?.length > 0) {
      switch (filter1Value) {
        case FILTER.NODE:
          nodeFilter = []
          nodeFilter = nodeFilter.concat(filter2ArrayValue)
          break
        case FILTER.RGROUP:
          rgroupFilter = []
          rgroupFilter = rgroupFilter.concat(filter2ArrayValue)
          break
        case FILTER.STATUS:
          statusFilter = []
          for(let eachStatus of filter2ArrayValue) {
            switch(eachStatus) {
              case WORKSPACE_STATUS.CANCELED:
                statusFilter.push(WORKSPACE_STATUS.CANCELED)
                statusFilter.push(WORKSPACE_STATUS.ADMIN_CANCELED)
                statusFilter.push(WORKSPACE_STATUS.SYSTEM_CANCELED)
                break
              case WORKSPACE_STATUS.RECALLED:
                statusFilter.push(WORKSPACE_STATUS.RECALLED)
                statusFilter.push(WORKSPACE_STATUS.SYSTEM_RECALLED)
                break
              case WORKSPACE_STATUS.PENDING:
                statusFilter.push(WORKSPACE_STATUS.PENDING)
                statusFilter.push(WORKSPACE_STATUS.PRE_QUEUE)
                break
              case WORKSPACE_STATUS.RUNNING:
                statusFilter.push(WORKSPACE_STATUS.RUNNING)
                statusFilter.push(WORKSPACE_STATUS.CREATING)
                break
              case WORKSPACE_STATUS.RETURNED: //반납은 사용자만 해서 상태 하나임
              case WORKSPACE_STATUS.ERROR:
              case WORKSPACE_STATUS.FAILED:
              case WORKSPACE_STATUS.COMPLETED:
              default:
                statusFilter.push(Number(eachStatus))
                break
            }
          }
          break
        case FILTER.WSJOB:
          if (filter2ArrayValue?.length === 1) {
            if (filter2ArrayValue[0] === 0) {
              isJob = false
            }
            else {
              isJob = true
            }
          }
          break
        default:
          break
      }
    }

    try {
      const workspaceList = await Workspace.getListAdmin(authInfo.userNo, sortKey, sortOrder, page ? page : stateRef.current.page, 50, nodeFilter, rgroupFilter, statusFilter, isJob)

      if (workspaceList) {
        for (let eachLine of workspaceList.workspaces) {
          eachLine.tableResourceGroup = eachLine.detail.rgroup
          if(eachLine.detail.isCommon === true) {
            eachLine.tableResourceGroup = '기본 리소스 그룹'
          }
          if (eachLine.detail.statusId === WORKSPACE_STATUS.ERROR) {
            eachLine.fakeName = '⚠︎ ' + eachLine.name
          } else {
            eachLine.fakeName = eachLine.name
          }
          // 운영자
          eachLine.tableUserInfo = [
            [(<div style={{ width: '190px' }}>
              <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.user.name ? eachLine.user.name : '-'}</EllipsisToolTip>
            </div>)],
            [(<div style={{ width: '190px' }}>
              <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.userId ? eachLine.userId : '-'}</EllipsisToolTip>
            </div>)],
            [eachLine.user.name && eachLine.userId ?
              (<div style={{ width:'190px', display:'flex', justifyContent:'start'}}>
                <UserInfoPopup data={eachLine.user} />
              </div>) : false
            ]
          ]
          // 신청 시간
          eachLine.tableCreationTimestamp = (typeof(eachLine.createdAt) === 'number' ? dayjs(eachLine.createdAt*1000).format('YYYY/MM/DD\nHH:mm:ss') : eachLine.createdAt)
          // 가동 시간
          switch(eachLine.detail.statusId) {
            case WORKSPACE_STATUS.FAILED:
              eachLine.tableOperatingTime = '-'
              break
            case WORKSPACE_STATUS.PENDING:
              eachLine.tableOperatingTime = eachLine.priority === null ? '생성 대기중\n(우선 순위 미포함)' : `생성 대기중\n(우선 순위 ${eachLine.priority}번째)`
              break
            case WORKSPACE_STATUS.CANCELED:
              eachLine.tableOperatingTime = '신청 취소'
              break
            case WORKSPACE_STATUS.ADMIN_CANCELED:
              eachLine.tableOperatingTime = '관리자에 의한 신청 취소'
              break
            case WORKSPACE_STATUS.SYSTEM_CANCELED:
              eachLine.tableOperatingTime = '시스템 자동 신청 취소'
              break
            case WORKSPACE_STATUS.PRE_QUEUE:
              eachLine.tableOperatingTime = '생성 대기 관리자 확인 중'
              break
            case WORKSPACE_STATUS.CREATING:
              eachLine.tableOperatingTime = '생성 중'
              break
            case WORKSPACE_STATUS.RUNNING:
              eachLine.tableOperatingTime = (typeof(eachLine.startedAt) === 'number' ? dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss') : eachLine.startedAt) + ' ~ \n(가동중)'
              break
            default:
              eachLine.tableOperatingTime = (typeof(eachLine.startedAt) === 'number' ? dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss') : eachLine.startedAt) + ' ~ \n' + (typeof(eachLine.deletedAt) === 'number' ? dayjs(eachLine.deletedAt*1000).format('YYYY/MM/DD HH:mm:ss') : eachLine.deletedAt)
              break
          }
          // 리소스 타입
          eachLine.tableResourceType = eachLine.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ?            
            [//gpu인경우
              [(<div>{eachLine.resourceBlock.type + ' : '}<div className='resourceNumBox'>{eachLine.resourceBlock.gpuNum + ' 개'}</div></div>)],
              eachLine.detail.isMultiTraining && Config.env.REACT_APP_MULTI_NODE === 'true' ? //멀티 노드 학습인경우
                [(<div style={{ display:'flex', gap:'10px', marginTop:'10px' }}><BlockPopup blockData={eachLine.resourceBlock} /><img src="/images/multi-training.svg" alt="multi-traning" /></div>)] :
                [(<div><BlockPopup blockData={eachLine.resourceBlock} /></div>)]
            ]
             :
            [//cpu인경우
              [(<div>{eachLine.resourceBlock.type}</div>)],
              [(<div>{'CPU : ' + eachLine.resourceBlock.cpu + ' 코어'}</div>)],
              [(<div>{'MEM : ' + eachLine.resourceBlock.mem + ' ' + eachLine.resourceBlock.memUnit}</div>)]
            ]
          // 상태
          eachLine.tableStatus = [
            [(<WorkspaceAdminStatus 
              userid={eachLine.userId} namespace={eachLine.namespace} id={eachLine.id} status={eachLine.detail.statusId} label={statusLabel[eachLine.detail.statusId]} name={eachLine.fakeName}
              userInfo={eachLine.user}
              creationTimestamp={eachLine.createdAt} startTimestamp={eachLine.startedAt} endTimestamp={eachLine.deletedAt} statusMessage={eachLine.detail.statusMsg} isJob={eachLine.detail.isJob}
            />)]
          ]

          //rowClassName - 글자 색상 처리
          switch (eachLine.detail.statusId) {
            case WORKSPACE_STATUS.ERROR: // 에러 표기
              eachLine.rowClassName = 'warn'
              break
            case WORKSPACE_STATUS.FAILED:
            case WORKSPACE_STATUS.PENDING:
            case WORKSPACE_STATUS.CANCELED:
            case WORKSPACE_STATUS.RETURNED:
            case WORKSPACE_STATUS.RECALLED:
            case WORKSPACE_STATUS.COMPLETED:
            case WORKSPACE_STATUS.ADMIN_CANCELED:
            case WORKSPACE_STATUS.CREATING:
            case WORKSPACE_STATUS.PRE_QUEUE:
            case WORKSPACE_STATUS.SYSTEM_CANCELED:
            case WORKSPACE_STATUS.SYSTEM_RECALLED:
              eachLine.rowClassName = 'fail' //딤 처리
              break
            case WORKSPACE_STATUS.RUNNING:
            default:
              eachLine.rowClassName = ''
              break
          }
        }
        setState({
          ...state,
          page: page ? page : stateRef.current.page,
          totalArticle: workspaceList.total,
          tableItems: workspaceList.workspaces,
          itemNumber: workspaceList.workspaces.length
          // nodeFilter: selectOption ? selectOption.value : state.nodeFilter,
        })
        // getNodeList(workspaceList.nodeList)

        for (let eachLine of workspaceList.workspaces) {
          eachLine.style3 = {width:'120px'}
        }
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - f3bc6c')
    }
  }

  const InitNodePageData = async () => {
    const node:string = searchParams.get('node') as string
    let filterList2:any = []
    const filterTitle = '노드 선택'
    let selectedArray:any[] = []
    let filter2:ISelectOption[] = []

    try {
      let result = await ResourceGroup.getAllNodes()
      if(result) {
        for(let idx in result.nodeList) {
          filterList2.push({ label: result.nodeList[idx], value: idx })
          if(result.nodeList[idx] === node) {
            filter2.push({ label: result.nodeList[idx], value: idx })
            selectedArray.push(result.nodeList[idx])
          }
        }
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 0c0a1b')
    }

    setFilterState({
      filter1: {label: '노드별 목록', value: FILTER.NODE},
      filterList2: filterList2,
      filterList2Title: filterTitle,
      filter2: filter2,
      selectedArray: selectedArray
    })
  }

  const onChangeFilter = async (data:ISelectOption) => {
    let filterList2:any = []
    let filterTitle:string = ''

    switch(data.value) {
      case FILTER.NODE:
        filterTitle = '노드 선택'
        try {
          let result = await ResourceGroup.getAllNodes()
          if(result) {
            for(let idx in result.nodeList) {
              filterList2.push({ label: result.nodeList[idx], value: idx })
            }
          }
        } catch(error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 0c0a1b')
        }
        break
      case FILTER.RGROUP:
        filterTitle = '리소스 그룹 선택'
        try {
          let result = await ResourceGroup.getGroupList(SORT_ORDER.ASC)
          if (result) {
            for (let idx in result.rgroup) {
              if (result.rgroup[idx].isCommon) {
                filterList2.push({ fakeLabel: '기본 리소스 그룹', label: result.rgroup[idx].name, value: idx })
              } else {
                filterList2.push({ label: result.rgroup[idx].name, value: idx })
              }
            }
          }
        } catch (error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - c62f98')
        }
        break
      case FILTER.STATUS:
        //상태 리스트
        filterTitle = '상태 선택'
        filterList2 = [
          {label: '가동중', value: WORKSPACE_STATUS.RUNNING},
          {label: '장애', value: WORKSPACE_STATUS.ERROR},
          {label: '생성 실패', value: WORKSPACE_STATUS.FAILED},
          {label: '신청 취소', value: WORKSPACE_STATUS.CANCELED},
          {label: '생성 대기', value: WORKSPACE_STATUS.PENDING},
          {label: '반납', value: WORKSPACE_STATUS.RETURNED},
          {label: '회수', value: WORKSPACE_STATUS.RECALLED},
          //{label: '실행완료 (Job)', value: WORKSPACE_STATUS.COMPLETED}
        ]
        break
      case FILTER.WSJOB:
        //워크스페이스 / Job
        filterTitle = '워크스페이스 / Job 선택'
        filterList2 = [
          {label: '워크스페이스', value: 0}, // value는 나중에 api보고 변경
          {label: 'Job', value: 1}
        ]
        break
      default:
        break
    }

    if(data.value === FILTER.ALL && searchParams.get('node') === null && filterState.filter1?.value !== FILTER.ALL) {
      setFilterState({
        ...filterState,
        filter1: data
      })
      getData(state.page, data, [])
    }
    else {
      if (nodeFlag) { 
        // 모니터링 페이지에서 온 경우 (1회성)
        setNodeFlag(false)
        scrollToList()
        return 
      } else {
        // 일반 경우
        setFilterState({
          ...filterState,
          filter1: data,
          filterList2: filterList2,
          filterList2Title: filterTitle,
          filter2: [] //초기화
        })  
      }
    }
  }

  const onChangeFilter2 = (selected:any[]) => {
    if(selected.length === 0) return
    let selectedArray:any[] = []
    switch(filterState.filter1?.value){
      case FILTER.NODE:
      case FILTER.RGROUP:
        for (let idx in filterState.filterList2) {
          const index = selected.lastIndexOf(Number(filterState.filterList2[idx].value))
          if (index !== -1) {
            selectedArray.push(filterState.filterList2[idx].label)
          }
        }
        break
      case FILTER.STATUS:
        selectedArray = selectedArray.concat(selected)
        break
      case FILTER.WSJOB:
        selectedArray = selectedArray.concat(selected)
        break
      default:
        break
    }
    setFilterState({
      ...filterState,
      selectedArray: selectedArray
    })
    if (nodeFlag === false) {
      getData(state.page, filterState.filter1, selectedArray)
    }
  }

  const onChangeChartSelect = (data:ISelectOption) => {
    if(data.label !== '') {
      setChartSelect(data)
      getChartData(data)
    }
  }

  const getChartData = async (chartSelection?:ISelectOption) => {
    try {
      const FROM = dayjs(state.range.from).unix()
      const TO = dayjs(state.range.to).unix()

      const data = await Monitoring.getGpuUsage(FROM, TO, chartSelection ? Number(chartSelection.value) : Number(chartSelect?.value))

      if (data) {
        setChartData(parseChartData(data.gpuAlloc))
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 959ce9')
    }
  }

  // const getNodeList = async (nodeList:any[]) => {
  //   const data:any = nodeList //state.nodeList
  //   if(data) {
  //     let nodeArr:ISelectOption[] = []
  //     nodeArr.push({
  //       label:'전체 보기',
  //       value:''
  //     })
  //     for(let i=0; i < data.length; i++) {
  //         nodeArr.push({
  //           label : data[i],
  //           value : data[i]
  //         })
  //     }
      
  //     setNodeList(nodeArr)
  //   } else {
  //     popupController.confirm('Node가 없습니다.')
  //     setNodeList([])
  //   }
  // }
  
  const parseChartData = (data:any):IChartSeries[] => {
    let i:number, item:any, j:number, jtem:any
    let chartData:IChartSeries[] = []
    let dataList:any = []
    for (i = 0; i < data.length; i++) {
      item = data[i]
      dataList = []
      for (j = 0; j < item.data.length; j++) {
        jtem = item.data[j]
        dataList.push([new Date(dayjs.unix(jtem.timestamp).utc().format()).getTime(), jtem.value])
      }
      chartData.push({
        name: item.isCommon === true ? '기본 리소스 그룹' : item.label,
        data: dataList
      })
    }
    return chartData
  }

  /* const newWorkspaceButtonHandler = ():void => {
    if (props.navigate) props.navigate('/workspace/new')
  } */

  const navigateToPage = (page:number):void => {
    if(page === state.page) return
    // setState({
    //   ...state,
    //   page:page
    // })
    getData(page, filterState.filter1, filterState.selectedArray)
  }

  const removeWorkspaceHandler = async ():Promise<void> => {
    let targetIdNameArray:any[] = []
    let runningNameArray:string[] = []
    let stoppedNameArray:string[] = []
    //let canceledNameArray:string[] = []
    let pendingNameArray:string[] = []
    const items = state.tableItems //tableRef.current?.items
    const selectedIdx = tableRef.current?.selected
    
    let e:ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    e.payload = {}
    if (items && selectedIdx && selectedIdx.length > 0) {
      for (let eachIdx of selectedIdx) {
        let eachItem = items[Number(eachIdx)]
        targetIdNameArray.push({
          id:eachItem.id,
          name:eachItem.name
        })
        switch(eachItem.detail.statusId) {
          case WORKSPACE_STATUS.RUNNING:
          case WORKSPACE_STATUS.CREATING:
            runningNameArray.push(eachItem.name)
            break
          case WORKSPACE_STATUS.PENDING:
          case WORKSPACE_STATUS.PRE_QUEUE:
            pendingNameArray.push(eachItem.name)
            break
          case WORKSPACE_STATUS.CANCELED:
          case WORKSPACE_STATUS.ADMIN_CANCELED:
          case WORKSPACE_STATUS.SYSTEM_CANCELED:
            // canceledNameArray.push(eachItem.id)
            // break
          //canceled과 stopped를 합하여 계산함
          case WORKSPACE_STATUS.ERROR:
          case WORKSPACE_STATUS.RETURNED:
          case WORKSPACE_STATUS.RECALLED:
          case WORKSPACE_STATUS.FAILED:
          case WORKSPACE_STATUS.COMPLETED:
          case WORKSPACE_STATUS.SYSTEM_RECALLED:
            stoppedNameArray.push(eachItem.name)
            break
        }
      }
      /* targetIdNameArray.push({
        id:243234234, 
        name:'없는 워크스페이스1'
      }, {
        id:243234233, 
        name:'없는 워크스페이스2'
      }) */
      if (runningNameArray.length === 0) {
        let message:string = ''
        if (pendingNameArray.length > 0) {
          if(stoppedNameArray.length===0 &&pendingNameArray.length===1) {
            message =`${pendingNameArray[0]} 이(가) 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (stoppedNameArray.length>=0 && pendingNameArray.length >= 1) {
            message=`${pendingNameArray.length}개의 생성 대기 중 항목이 포함 되어 있습니다. 삭제 시 생성 대기중 항목은 신청이 취소됩니다. 진행하시겠습니까? `
          }
        } else if (pendingNameArray.length === 0) {
          if(stoppedNameArray.length === 1) {
            message=`${stoppedNameArray[0]} 이(가) 삭제됩니다. 진행하시겠습니까? `
          } else if(stoppedNameArray.length > 1) {
            message=`${stoppedNameArray[0]} 외 ${stoppedNameArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          }
        }
        let payload = {
          targetIdNameArray: targetIdNameArray,
          runningNameArray: runningNameArray,
          pendingNameArray: pendingNameArray,
          stoppedNameArray: stoppedNameArray
        }
        popupController.dialouge(message, 'deleteWsAdmin', payload, '확인', '취소')
      } else if (runningNameArray.length === 1) {
        popupController.confirm(`${runningNameArray[0]} 을(를) 회수한 후 삭제 가능합니다.`)
      } else {
        popupController.confirm(`${runningNameArray.length}개의 운영 중 항목이 포함 되어 있습니다. 이를 모두 회수한 후 삭제 가능합니다.`)
      }
    } else {
      popupController.confirm(`선택된 항목이 없습니다.`)
    }
  }
  
  const modalActionHandler = async (e:ModalEvent) => {
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
    const items = stateRef.current.tableItems //tableRef.current?.items
    const selectedIdx = tableRef.current?.selected
    switch (e.payload.action) {
      case 'deleteWsAdmin':
        try {
          const targetIdNameArray:any[] = e.payload.key.targetIdNameArray
          const pendingNameArray:string[] = e.payload.key.pendingNameArray
          const stoppedNameArray:string[] = e.payload.key.stoppedNameArray
          if (items && selectedIdx && selectedIdx.length > 0) {
            // 삭제
            const response = await Workspace.removeWorkspace(targetIdNameArray)

            // 노티
            let message:string = ''
            if (!response) { // 삭제 모두 성공
              if (pendingNameArray.length > 0) {
                if (stoppedNameArray.length === 0 && pendingNameArray.length === 1) {
                  message = `${pendingNameArray[0]} 이(가) 삭제 및 신청 취소되었습니다. `
                } else if (stoppedNameArray.length >= 0 && pendingNameArray.length >= 1) {
                  message = `생성 대기 중 항목 포함 ${stoppedNameArray.length + pendingNameArray.length}개의 항목이 삭제 및 신청 취소되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                }
              } else if (pendingNameArray.length === 0) {
                if (stoppedNameArray.length === 1) {
                  message = `${stoppedNameArray[0]} 이(가) 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                } else if (stoppedNameArray.length > 1) {
                  message = `${stoppedNameArray[0]} 외 ${stoppedNameArray.length - 1}개 항목이 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                }
              }
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
            } else { // 삭제 일부 실패
              message = `${targetIdNameArray.length}개 삭제 요청 중 ${response.data.failedCount}개 삭제를 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.data.deletedCount}개 / 실패 ${response.data.failedCount}개  `
              popupController.confirm(message)
            }

            // 삭제 후 데이터 갱신
            tableRef.current?.setSelected([])
            getData(stateRef.current.page)
            // navigateToPage(stateRef.current.page)
          }
        } catch(error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 1f54c0')
        }
        break
    }
  }

  const onChangeDatePicker = (range:any) => {
    setChartData([])
    setState({
      ...state,
      range: {
        from: range.from,
        to: range.to
      }
    })
    getChartData()
  }

  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IWorkspaceListItem[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  const scrollToList = () => {
    if (document.getElementsByClassName('contentWrap')[0]) {
      document.getElementsByClassName('contentWrap')[0].scrollTo(0, 680)
    }
  }

  return (
    <WorkspaceListFragment>
      <div className="titleArea bothEnd">
        <h2 className="pageTitle">
          워크스페이스 목록<span className="total"><b>{state.totalArticle}</b> 개</span>
          {/* <Tooltip des="최초 진입시에는 현재 시각으로부터 24시간 이전까지의 데이터가 보여집니다.<br/>
                          기간 설정 시 설정한 기간 동안 해당 사용자의 리소스 블록 활용률 확인이 가능합니다." /> */}
        </h2>
      </div>
      <DateRangePicker initial={{ from: initialFrom }} default={{ from: defaultFrom, to: defaultTo }} data={state.range} onChange={onChangeDatePicker} />
      {/* <h3 className="chartTitle">
        사용자 계정 별 블록 활용률
      </h3> */}
      <div style={{display:'flex', justifyContent:'center', margin:'30px 0 20px 0'}}>
        <div style={{width:'218px'}}>
          <Select option={filterChart} onChange={onChangeChartSelect} bold={true} />
        </div>
      </div>
      {
        chartData.length ?
          <LineChart unit="%" height={350} min={0} max={100} data={chartData} datetime={true} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} useTooltipHtml={true} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
          toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
            const date =  dayjs(this.x).format('MM/DD HH:mm')
            let result = `${date}<br>`
            this.points?.forEach((point:any) => {
              result += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} %</b><br/>`
            })
            return result
          }} /> :
          <NoData height={350} />
      }
      <div className="divider"></div>

      <div className="infoWrap">
        <div className="filterWrap">
          <div className="SelectWrap">
            {/* <Select option={nodeList} selected={selected} onChange={onChangeSelect} placeholder='전체 보기'></Select> */}
            <Select option={filterList} selected={filterState.filter1} onChange={onChangeFilter}></Select>
          </div>
          {
            filterState.filter1 && filterState.filter1.value !== FILTER.ALL ?
            <div className="SelectWrap">
            <SelectMulti option={filterState.filterList2} selected={filterState.filter2} placeholder={filterState.filterList2Title} onChange={onChangeFilter2} nodeFlag={nodeFlag} />
            </div> : false
          }
        </div>
        <div className="btnWrap">
          <button className={"btn outline " + (tableRef.current?.selected.length === 0 ? 'off' : '')} onClick={removeWorkspaceHandler}>선택 항목 삭제</button>
        </div>
      </div>
      <Table ref={tableRef} 
              colWidth={tableColWidth} 
              headerLabel={headerLabel} 
              items={state.tableItems} 
              noData="사용중인 워크스페이스가 없습니다."
              orderPrefix={(state.page-1)*50}
              checkBoxType={CHECKBOX_TYPE.USE_ALL}
              onUpdate={onUpdateStatus}
              onSelect={selectBoxCheckHandler}
              defaultSortOrder={SORT_ORDER.ASC} />
      { state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage:number) => navigateToPage(newPage)} />  : false 
      }
      
    </WorkspaceListFragment>
  )
}

const WorkspaceListFragment = styled.div`
  .col3,
  .col4,
  .col5,
  .col6{text-align:center;}
  .chartTitle{margin:30px 0 10px;font-size:14px;text-align:center;}
  
  .col0 div span { display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:5;overflow:hidden;max-height:5.8em;text-overflow:ellipsis; }
  .col4 span { display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:5;overflow:hidden;max-height:5.8em;text-overflow:ellipsis; }
  .col5 { padding:15px 15px; }
  .resourceNumBox { display:inline; font-weight:700; white-space:nowrap; }

  .filterWrap { display:flex; justify-content:start; align-items:center; gap:10px; }
  .SelectWrap { width:300px; }
  .SelectWrap p, .SelectWrap .selectP { min-width:220px;}
  .infoWrap { display:flex; justify-content:space-between; align-items:center; margin-bottom:30px; }

  .fail td { color:#a7a7a7; }
  .fail td img { opacity:1; }
  .fail td button img,
  .fail td a img { opacity:1; }
  .fail td.checkWrap img { opacity:1; }
  .warn td { color:#f30b0b; }
  .fail td .subModalPopupWrap *, .warn td .subModalPopupWrap *, .serviceError td .subModalPopupWrap * { color:#303030; }
  .fail td td, .fail td thead, .warn td td, .warn td thead, .serviceError td td, .serviceError td thead {color:#303030; }
`

export default WorkspaceList