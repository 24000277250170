import { atom } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'authInfo',
  storage: localStorage
})

export interface IUserAuthInfo {
  id:string
  userNo:number
  isOps:boolean
  isAdmin:boolean
  aToken:string
  rToken:string
  tokenExpire:number
  lastLoginTimestamp:number
  isPasswordExpired:boolean
  passwordInitialized:boolean
  lackOfAdminUserInfo:boolean
  multipleAccessRestriction:string
}

export const authState = atom<IUserAuthInfo>({
  key: 'authState',
  default: {
    id: '',
    userNo: -1,
    isOps: false,
    isAdmin: false,
    aToken: '',
    rToken: '',
    tokenExpire: -1,
    lastLoginTimestamp: -1,
    isPasswordExpired: false,
    passwordInitialized: false,
    lackOfAdminUserInfo: false,
    multipleAccessRestriction: ''
  },
  effects_UNSTABLE: [persistAtom]
})
