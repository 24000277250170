import React,{ useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { IWorkspaceStatus, WORKSPACE_STATUS, Workspace } from '../../../model/Workspace'

import Tooltip from '../../components/ui/Tooltip'
import dayjs from 'dayjs'
import PopupController from '../../../controller/PopupController'
import TableEvent from '../../../events/TableEvent'
import ModalEvent from '../../../events/ModalEvent'
import ToastEvent from '../../../events/ToastEvent'

interface IWorkspaceAdminStatusState {
  detailButtonFlag:boolean
  actionFunction:Function|null
  action:string
  className:string
  btnClass:string
}

const WorkspaceAdminStatus = (props:IWorkspaceStatus) => {
  const popupController = PopupController.getInstance()
  const [ optionalQuery, setOptionalQuery ] = useState('')
  const [ state, setState ] = useState<IWorkspaceAdminStatusState>({
    detailButtonFlag: false,
    actionFunction: null,
    action: '',
    className: '',
    btnClass: ''
  })
  //const propsRef = useRef(props) //addEventListener의 콜백함수에서 propsRef.current가 props초기값이랑 똑같이 나와서 사용x, 대신 state에 저장하는 방식으로 변경
  const [ propsInfo, _setPropsInfo ] = useState<any>({
    id: -1,
    name: ''
  })
  const propsInfoRef = useRef(propsInfo)
  const setPropsInfo = (data:any) => {
    propsInfoRef.current = data
    _setPropsInfo(data)
  }

  useEffect(() => {
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    let now = dayjs().unix()
    if (now - Number(props.startTimestamp) < 86400) {
      setOptionalQuery('?from=' + props.startTimestamp)
    }
  }, [props.startTimestamp])

  useEffect(() => {
    setPropsInfo({
      id: props.id,
      name: props.name
    })
  }, [props.id, props.name])
  useEffect(() => {
    switch (props.status) {
      case WORKSPACE_STATUS.ERROR: // 장애  (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: doNothing,
          action: '회수하기',
          className: '',
          btnClass: 'off'
        })
        break
      case WORKSPACE_STATUS.FAILED: // 생성 실패  (OK)
        setState({
          detailButtonFlag: false,
          actionFunction: doNothing,
          action: '회수하기',
          className: 'warn',
          btnClass: 'off'
        })
        break
      case WORKSPACE_STATUS.PENDING: // 생성 대기 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: cancelHandler,
          action: '신청 취소',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.CANCELED: // 생성 대기-취소 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: doNothing,
          action: '회수하기',
          className: 'off',
          btnClass: 'off'
        })
        break
      case WORKSPACE_STATUS.RUNNING: // 실행 중 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: returnHandler,
          action: '회수하기',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.RETURNED: // 사용자 반납 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: null,
          action: '반납 완료',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.RECALLED: // 관리자 회수 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: null,
          action: '회수됨',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.COMPLETED: // Job 완료 (예시 없음)
        setState({
          detailButtonFlag: true,
          actionFunction: null,
          action: 'Job 완료',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.ADMIN_CANCELED: // 관리자 취소 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: doNothing,
          action: '회수하기',
          className: 'off',
          btnClass: 'off'
        })
        break
      case WORKSPACE_STATUS.CREATING: // 생성중 (OK)
      setState({
        detailButtonFlag: true,
        actionFunction: doNothing,
        action: '회수하기',
        className: 'off',
        btnClass: 'off'
      })
        break
      case WORKSPACE_STATUS.PRE_QUEUE: // 대기열 등록 전 (OK)
      setState({
        detailButtonFlag: false,
        actionFunction: cancelHandler,
        action: '신청 취소',
        className: '',
        btnClass: ''
      })
        break
      case WORKSPACE_STATUS.SYSTEM_CANCELED: // 시스템 취소 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: doNothing,
          action: '회수하기',
          className: 'off',
          btnClass: 'off'
        })
        break
      case WORKSPACE_STATUS.SYSTEM_RECALLED: // 시스템 회수 (OK)
        setState({
          detailButtonFlag: true,
          actionFunction: null,
          action: '회수됨',
          className: '',
          btnClass: ''
        })
        break
    }
  }, [props.status])

  const cancelHandler = async (e:React.MouseEvent<HTMLButtonElement>):Promise<void> => {
    const workspaceId:string = propsInfoRef.current.name? propsInfoRef.current.name : ''
    const payload = {
      id: propsInfoRef.current.id,
      name: propsInfoRef.current.name,
    }
    popupController.dialouge(`${workspaceId} 의 신청을 취소합니다. 진행하시겠습니까?`, 'cancelWorkspace', payload, '확인', '취소')
  }
  const returnHandler = async (e:React.MouseEvent<HTMLButtonElement>):Promise<void> => {
    const workspaceId:string = propsInfoRef.current.name? propsInfoRef.current.name : ''
    const payload = {
      id: propsInfoRef.current.id,
      name: propsInfoRef.current.name,
    }
    popupController.dialouge(`${workspaceId} 을(를) 회수합니다. 진행하시겠습니까?`, 'returnWorkspace', payload, '확인', '취소')
  }

  const modalActionHandler = async (e:ModalEvent) => {
    if (e.payload.key.id !== propsInfoRef.current.id) {
      return false
    }
    let workspaceIdName:any[] = [] 
    workspaceIdName.push({id: propsInfoRef.current.id, name: propsInfoRef.current.name})
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)

    switch (e.payload.action) {
      case 'cancelWorkspace' :
        try {
          await Workspace.returnWorkspace(workspaceIdName)
          toastEvent.payload = { message: `${e.payload.key.name} 의 신청이 취소되었습니다.` }
          window.dispatchEvent(toastEvent)
          window.dispatchEvent(new Event(TableEvent.CHANGE_SORT_ORDER))
          let event:TableEvent = new TableEvent(TableEvent.REFRESH)
          window.dispatchEvent(event)      
        } catch (error) {
          e.payload = { message: error }
          window.dispatchEvent(toastEvent)
        }
        break
      case 'returnWorkspace' :
        try {
          await Workspace.returnWorkspace(workspaceIdName)
          toastEvent.payload = { message: `${e.payload.key.name} 이(가) 회수되었습니다.` }
          window.dispatchEvent(toastEvent)
          window.dispatchEvent(new Event(TableEvent.CHANGE_SORT_ORDER))
          let event:TableEvent = new TableEvent(TableEvent.REFRESH)
          window.dispatchEvent(event)
        } catch (error) {
          toastEvent.payload = { message: error }
          window.dispatchEvent(toastEvent)
        }
        break
    }
  }

  const doNothing = () => {
    return false
  }

  return (
    <WorkspaceStatusFragment>
      <div className={state.className}>
        { state.detailButtonFlag ? 
          state.className === 'off' ?
          <span>상세 보기<img src="/images/arrow3.png" alt="go" className="arrow" /></span> :
          <Link to={'/workspace/'+props.id/* +'/'+props.namespace+'/'+props.userInfo?.no + optionalQuery */}>상세 보기<img src="/images/arrow3.png" alt="go" className="arrow" /></Link>
          : props.label 
        }
        { state.detailButtonFlag === false && state.className === 'warn' && props.statusMessage !== undefined ? 
          <Tooltip des={props.statusMessage} way="left" img="failIcon.png" width={14} />
        : false }
      </div>
      {
        state.actionFunction === null && state.action === '' ? '' :
        <div>
          { state.actionFunction !== null ? 
            <button className={`btnAction ${state.btnClass}`} onClick={ state.btnClass !== 'off' ? ()=>{if(state.actionFunction) state.actionFunction()} : ()=>{doNothing()} }>{state.action}</button> : 
            <div>
              <span className="text">{ state.action }</span>
              {/* { props.status === WORKSPACE_STATUS.RETURNED || props.status === WORKSPACE_STATUS.RECALLED  || props.status === WORKSPACE_STATUS.SYSTEM_RECALLED ?
                <Tooltip des={dayjs(Number(props.endTimestamp)*1000).format('YYYY-MM-DD HH:mm:ss')} way="left" img="group-257.png" width={14} /> : 
                false
              } */}
            </div>
          }
        </div>
      }
    </WorkspaceStatusFragment>
  )
}

const WorkspaceStatusFragment = styled.div`
  div{font-size:1em;color:#303030;}
  div.off{color:#a7a7a7;}
  div + div{margin-top:10px;}
  .warn{color:#f30b0b;}
  a{display:inline-block; font-size:1em; padding:0.2em 0.5em; color:#000;}
  .arrow{display:inline-block;width:4px;vertical-align:top;margin:4px 0 0 7px;}
  .btnAction{display:inline-block; font-size:1em; border:1px solid #000; padding:0.2em 0.5em; border-radius: 2px;}
  .btnAction.off{border-color:#a7a7a7;color:#a7a7a7;pointer-events:none}
  .text + div{margin-left: 5px;}
  .warn .btnTooltip img {margin-left:5px;}
`

export default WorkspaceAdminStatus