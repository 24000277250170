import { atom } from "recoil"

export interface ISettings {
  autoRefreshFlag:boolean
  loadingMaskFlag:boolean
  schedulerFlag:boolean
}

export const settingState = atom<ISettings>({
  key: 'settingState',
  default: {
    autoRefreshFlag: false,
    loadingMaskFlag: true,
    schedulerFlag: true
  },
})

export const changeModeState = atom<boolean>({
  key: 'changeModeState',
  default: false
})

// export const loadingApiCountState = atom<number>({ // 로딩마스크 띄울때는 0->1 될때만 한 번 / 치울때는 0되면 치우기 / 페이지 이동 시 0으로 초기화
//   key: 'loadingApiCountState',
//   default: 0
// })
