import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import { IGroupNode, IGroupNodeList } from "../../../model/ResourceGroup"
import PopupController from '../../../controller/PopupController'
import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../ui/Table'
import TableEvent from '../../../events/TableEvent'
import { ResourceGroup, IGroupNodeadd } from '../../../model/ResourceGroup'
import { SORT_ORDER } from '../../../model/BaseDataType'
import { ERROR_TYPE } from '../../../interfaces/Error'

interface INodeListPopupProps {
  onClose:Function
  onSelected:Function
  groupName:string
  firstselectedNode:IGroupNode[]
}

interface INodeListPopupState {
  selectedItems:IGroupNode[]
  tableItems:IGroupNodeList[]
  totalArticle:number
}

const NodeListPopup = (props:INodeListPopupProps) => {

  const tableRef = useRef<any>()
  const tableColWidth = [52, 201, 167, 140, 140]
  const headerLabel: ITableHeader[] = [
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.ASC, sortKey: 'name', orderFlag: true }, 
    { label: 'IP', key: 'ip', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'ip' },
    { label: '가동 중 항목', key: 'tableServiceCount', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'serviceCount', styleKey: 'paddingStyle' }, 
    { label: '상태', key: 'status', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'status', styleKey: 'paddingStyle' }
  ]

  const popupController = PopupController.getInstance()

  const [ state, _setState] = useState<INodeListPopupState>({
    selectedItems:[],
    tableItems:[],
    totalArticle:0
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getData()
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const eventControl = (e:Event) => {
    getData()
  }

  const selectBoxCheckHandler = (selectedIdx:IGroupNodeList[]):void => {
    if (state.tableItems && selectedIdx && state.tableItems.length > 0) {

      let selectedArray:IGroupNodeList[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedItems: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedItems: []
      })
    }
  }

  const getData = async () => {
    try {
      const data = await ResourceGroup.getGroupNode()
      if (data) {
        for (let eachLine of data.nodeList) {
          eachLine.tableServiceCount = eachLine.serviceCount + ' 개'
          eachLine.status = eachLine.status === true ? 'Ready' : 'Not Ready'
          eachLine.isWorking = eachLine.serviceCount > 0 ? true : false
        }
        setState({
          ...state,
          totalArticle: data.nodeCount ? data.nodeCount : 0,
          tableItems: data.nodeList
        })
      }
    }
    catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - ec70b8')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = async () => {
    const nodeName = []

    for (let idx in state.selectedItems) {
      nodeName.push(state.selectedItems[idx].name)
    }

    let payload:IGroupNodeadd = {
      nodeList: nodeName
    }

    let e:TableEvent = new TableEvent(TableEvent.GROUP_ADDED_NODE)
    e.payload = {
      nodeList: nodeName,
      groupName: props.groupName,
      firstselectedNode:state.selectedItems[0].name
    }
    window.dispatchEvent(e)
    
    if (props.onSelected) props.onSelected(payload)

    closeHandler()
  }

  return(
    <NodeListPopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
        <h2 className="pageTitle">
          노드 목록<span className="total"><b>{state.totalArticle}</b> 개</span>
        </h2>
        <div className="scrollWrap">
          {state.tableItems.length > 0 ?
            <>
              <Table ref={tableRef}
                colWidth={tableColWidth}
                headerLabel={headerLabel}
                items={state.tableItems}
                checkBoxType={CHECKBOX_TYPE.USE_ALL}
                checkBoxPreserveKeys={["isWorking"]}
                noData="노드 정보가 없습니다."
                defaultSortOrder={SORT_ORDER.ASC}
                onSelect={selectBoxCheckHandler}
              />
            </>
            : <>
              <div className="noData">노드 정보가 없습니다.</div>
            </>}
        </div>
        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          {state.selectedItems.length > 0 ?
            <button className="btn blue" onClick={submitHandler}>{state.selectedItems.length} 개 가져오기</button> :
            <button className="btn off"> 가져오기</button>
          }
        </div>
    </NodeListPopupFragment>
  )
}

const NodeListPopupFragment = styled.div`
  display:flex; flex-direction:column; width:800px; height:800px; padding: 50px 50px 20px; box-sizing:border-box;
  .pageTitle { flex:none; margin-bottom:0px; }
  .scrollWrap { overflow:auto; flex:1; margin-top:20px; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:15px; }
  .btnWrap .btn { width:188px; height:40px; line-height:33px; }
  .noData { text-align:center; color:#A7A7A7; font-size:20px; display:flex; justify-content:center; align-items:center;height:100%; margin-top:-30px; }
  .col1,
  .col2,
  .col3 { text-align:center; }
`

export default NodeListPopup