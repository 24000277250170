import styled from 'styled-components'

import ModalAction from './ModalAction'
import { IModalButton } from '../../../events/ModalEvent'
import { useEffect, useRef } from 'react'

interface IMessagePopupProps {
  message:string
  button?:IModalButton[]
  onClose:Function
}

const MessagePopup = (props:IMessagePopupProps) => {
  const closeHandler =() => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitRef = useRef<any>()

  useEffect(() => {
    if (submitRef.current) {
      submitRef.current.focus()
    }
  }, [])

  return (
    <MessagePopupFragment>
      <p>{props.message}</p>
      {
        props.button?.length ? 
        <ModalAction list={props.button} onClose={closeHandler} /> : 
        <div className="btnWrap">
          <button ref={submitRef} className="btn blue submitButton" onClick={closeHandler}>확인</button>
        </div>
      }
      
    </MessagePopupFragment>
  )
}

const MessagePopupFragment = styled.div`
  width: 560px;padding:50px 30px 30px;box-sizing:border-box;
  p {font-size:14px; word-break:break-all; white-space:pre-line;}
  .btnWrap{display:flex;align-items:center;justify-content:flex-end;gap:5px;margin-top:50px;}
  .btnWrap .btn{min-width:130px;}
`

export default MessagePopup