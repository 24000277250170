import ApiController from '../controller/ApiController'
import { ERROR_TYPE } from '../interfaces/Error'
import SchedulerEvent from '../events/SchedulerEvent'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface IWorkspacePriorityInsertInfo {
  id:number
  name:string
}

export class Priority {

  // 스케쥴러
  public static async getPriorityStatus() {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/scheduler/check/status', {})

      switch (response.status) {
        case 200:
          return !response.data.is_stop
        default:
          console.log('Priority.getPriorityStatus error>', response)
          throw new Error(ERROR_TYPE.ERROR)
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async startPriority() {
    const apiController = ApiController.getInstance()
    const response = await apiController.patch('/scheduler/start', {})

    switch (response.status) {
      case 200:
        window.dispatchEvent(new SchedulerEvent(SchedulerEvent.UPDATE))
        return response.data
      default:
        console.log('Priority.startPriority error>', response)
        throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async stopPriority() {
    const apiController = ApiController.getInstance()
    const response = await apiController.patch('/scheduler/stop', {})

    switch (response.status) {
      case 200:
        window.dispatchEvent(new SchedulerEvent(SchedulerEvent.UPDATE))
        return response.data
      default:
        console.log('Priority.stopPriority error>', response)
        throw new Error(ERROR_TYPE.ERROR)
    }
  }


  // 대기열
  public static async getQueueList(rgroup:string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/scheduler/'+rgroup, {})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Priority.getPriorityStatus error>', response)
          throw new Error(ERROR_TYPE.ERROR)
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async updateQueueList(rgroup:string, payload:any) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.patch('/scheduler/queue/'+rgroup, snakecaseKeys(payload, {deep: true}))
      switch(response.status) {
        case 200:
          return true
        default:
          console.log('Priority.updateQueueList error>', response)
          return response
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async insertPriority(rgroup:string, items:IWorkspacePriorityInsertInfo[]) {
    try {
      const apiController = ApiController.getInstance()
  
      const response = await apiController.patch('/scheduler/prequeue/'+rgroup, snakecaseKeys(items))
      switch(response.status) {
        case 200:
          return true
        default:
          console.log('Priority.insertPriority error>', response)
          return response
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
}