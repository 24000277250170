import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import ApiEvent from '../events/ApiEvent'
import CryptoJS from 'crypto-js'
import { promiseGetRecoil } from 'recoil-outside'
import { ISettings, settingState } from '../states/settingState'
import { delay } from 'lodash'
import Config from '../Config'

export enum AUTH_RESULT {
  OKAY = 200,
  INVAILD_INFO = 400,
  NOT_AUTHORIZED = 401,
  QUEUED = 499
}

interface requestHeader extends RawAxiosRequestHeaders {
  'user-id'?:string
  namespace?:string
  Authorization:string
}

export default class ApiController extends EventTarget {
  private static instance:ApiController
  // /* (http) */ private ENDPOINT = Config.env.REACT_APP_REST_PROTOCOL+'://'+Config.env.REACT_APP_BASE_URL+":"+String(Config.env.REACT_APP_REST_PORT)+'/api/v2'
  // /* (https) */ private ENDPOINT = Config.env.REACT_APP_REST_PROTOCOL+'://'+Config.env.REACT_APP_BASE_URL+'/api/v2'
  private ENDPOINT = Config.env.REACT_APP_LDAP === 'true' ? 
  Config.env.REACT_APP_REST_PROTOCOL+'://'+Config.env.REACT_APP_LDAP_BASE_URL+'/api/v2' :
  Config.env.REACT_APP_REST_PROTOCOL+'://'+Config.env.REACT_APP_BASE_URL+'/api/v2'

  private API:AxiosInstance

  private maskFlag:boolean = true

  private tokenExpireIn?:number
  private refreshEndpoint?:string
  private tokenLifespan?:number
  private _requestHeader?:requestHeader
  private refreshStateFlag:boolean = false
  
  public static getInstance() {
    if (!ApiController.instance) {
      ApiController.instance = new ApiController()
    }
    return ApiController.instance
  }

  private constructor() {
    super()

    this.API = axios.create({
      baseURL: this.ENDPOINT,
      timeout: 30000,
      headers: { 'Content-Type': 'application/json' }
    })

    this.API.interceptors.request.use(this.requestInterceptor, this.handleError)
    this.API.interceptors.response.use(this.responseInterceptor, this.handleError)
  }

  public set useMaskFlag(value:boolean) {
    this.maskFlag = value
  }

  public get useMaskFlag():boolean {
    return this.maskFlag
  }

  private set requestHeader(value:requestHeader) {
    this._requestHeader = value
    let hash = CryptoJS.AES.encrypt(JSON.stringify(value), 'header').toString()
    localStorage.setItem('requestHeader', hash)
  }
  
  public get requestHeader():requestHeader {
    if (this._requestHeader === undefined) {
      let hash = localStorage.getItem('requestHeader')
      if (hash !== null) {
        this._requestHeader = JSON.parse(CryptoJS.AES.decrypt(hash, 'header').toString(CryptoJS.enc.Utf8))
      }
    }
    return this._requestHeader || { Authorization: '' }
  }

  private requestInterceptor = async(config:InternalAxiosRequestConfig):Promise<InternalAxiosRequestConfig> => {
    const now = new Date().getTime()

    if (!this.tokenExpireIn) {
      let hash = localStorage.getItem('refreshHash')
      if (hash !== null) {
        let hashInfo = JSON.parse(CryptoJS.AES.decrypt(hash, 'header').toString(CryptoJS.enc.Utf8))
        this.tokenExpireIn = hashInfo.tokenExpireIn
        this.refreshEndpoint = hashInfo.refreshEndpoint
      }
    }

    if (this.tokenExpireIn && now > this.tokenExpireIn && config.url !== this.refreshEndpoint) {
      // console.log('check refreshStateFlag', this.refreshStateFlag, this.tokenExpireIn)
      if (this.refreshStateFlag === false) {
        let authResult:AUTH_RESULT = await this.refreshToken()
        if (authResult !== AUTH_RESULT.OKAY) console.log('ERROR ON TOKEN REFRESH >>', authResult)
      } else {
        // console.log(config.url, 'QUEUED FOR TOKEN REFRESH >>', this.tokenExpireIn)
      }
      return this.queueRequest(config)
    }

    if (this.maskFlag === true) this.dispatchEvent(new ApiEvent(ApiEvent.LOAD_START))
    return config
  }

  private responseInterceptor = async(response:AxiosResponse):Promise<AxiosResponse> => {
    if (this.maskFlag === true) this.dispatchEvent(new ApiEvent(ApiEvent.LOAD_END))
    if(!response.data) {
      response.data = {}
    }
    response.data.status = response.status
    return response.data
  }

  private handleError = async(error:AxiosError):Promise<any> => {
    const originalRequest:InternalAxiosRequestConfig|undefined = error.config
    this.dispatchEvent(new ApiEvent(ApiEvent.LOAD_END))

    if (originalRequest !== undefined) {
      //console.log('originalRequesturl>',originalRequest.url)
      if (originalRequest.url === this.refreshEndpoint) {
        switch (error.response?.status) {
          case 400:
          case 401:
            // TOKEN REFRESH에서 400/401이 나왔음.
            this.dispatchEvent(new ApiEvent(ApiEvent.AUTH_EXPIRED))
            this.clearAuth()
            return Promise.reject(error)
          case 500:
            // DOES NOTHING
            break
        }
      } else if (originalRequest.url === '/users') {
        return Promise.reject(error)
      } else {
        switch (error.response?.status) {
          case 401:
            if (this.refreshEndpoint === undefined || Config.env.REACT_APP_REST_PROTOCOL === 'http') {
              // TOKEN REFRESH ENDPOINT가 없음 or PROTOCOL이 http라 REFRESH를 못시킴.
              this.dispatchEvent(new ApiEvent(ApiEvent.AUTH_EXPIRED))
              this.clearAuth()
              return Promise.reject(error)
            } else {
              // 토큰 갱신하고 다시 호출
              let authResult:AUTH_RESULT = await this.refreshToken()
              if (authResult === AUTH_RESULT.OKAY) {
                originalRequest.headers.Authorization = this.requestHeader.Authorization
                return axios(originalRequest)
              } else if (authResult === AUTH_RESULT.QUEUED) {
                // 다른 프로세스에서 토큰 갱신중이면 잠시 후 다시 호출
                return this.queueRequest(originalRequest)
              } else {
                console.log('AUTH_RESULT', authResult)
                return Promise.reject(error)
              }
            }
          case 403:
            this.dispatchEvent(new ApiEvent(ApiEvent.AUTH_EXPIRED))
            this.clearAuth()
            return Promise.reject(error)
          case 400:
          case 422:
            return Promise.reject(error)
        }
      }
    }

    return Promise.reject(error)
  }

  private queueRequest = (originalRequest:InternalAxiosRequestConfig<any>):Promise<any> => {
    return new Promise(resolve => setTimeout(() => {
      // console.log(originalRequest.url, 'WATING TOKEN UPDATED' ,originalRequest.headers.Authorization !== this.requestHeader.Authorization)
      if (originalRequest.headers.Authorization !== this.requestHeader.Authorization) {
        originalRequest.headers.Authorization = this.requestHeader.Authorization
        resolve(originalRequest)
      } else {
        this.queueRequest(originalRequest)
      }
    }, 1000))
  }

  public getAuth = async(endpoint:string, credentials:object, refreshEndpoint?:string):Promise<AxiosResponse> => {
    try {
      const response = await this.post(endpoint, credentials, true)
      this.requestHeader = {
        'user-id': response.data.id,
        namespace: response.data.namespace,
        Authorization: `Bearer ${response.data.a_token}`
      }
      let tokenLifeSpan:number = (response.data.token_expire-5)*1000
      if (tokenLifeSpan && refreshEndpoint) {
        this.tokenLifespan = tokenLifeSpan
        this.tokenExpireIn = new Date().getTime() + this.tokenLifespan
        this.refreshEndpoint = refreshEndpoint
        
        let hash = CryptoJS.AES.encrypt(JSON.stringify({
          tokenExpireIn: this.tokenExpireIn,
          refreshEndpoint: this.refreshEndpoint
        }), 'header').toString()
        localStorage.setItem('refreshHash', hash)

        // await this.refreshToken()
      }

      return response
    } catch(error:any) {
      console.log(error.response)
      // return error.response.status
      return error.response
    }
  }

  public clearAuth = () => {
    localStorage.removeItem('requestHeader')
    localStorage.removeItem('refreshHash')
    delete this._requestHeader
  }

  private refreshToken = async():Promise<AUTH_RESULT> => {
    if (this.refreshStateFlag === false) {
      this.refreshStateFlag = true
      if (this.refreshEndpoint) {
        try {
          const response = await this.post(this.refreshEndpoint, undefined, true)
          
          let tokenLifeSpan:number = (response.data.token_expire-5)*1000
          if (tokenLifeSpan) {
            this.tokenLifespan = tokenLifeSpan
            this.tokenExpireIn = new Date().getTime() + this.tokenLifespan
            
            let hash = CryptoJS.AES.encrypt(JSON.stringify({
              tokenExpireIn: this.tokenExpireIn,
              refreshEndpoint: this.refreshEndpoint
            }), 'header').toString()
            localStorage.setItem('refreshHash', hash)
          }
          this.requestHeader = {
            'user-id': response.data.id,
            namespace: response.data.namespace,
            Authorization: `Bearer ${response.data.a_token}`
          }
          this.refreshStateFlag = false
          return AUTH_RESULT.OKAY
        } catch(error:any) {
          this.refreshStateFlag = false
          return error.response.status
        }
      } else {
        this.refreshStateFlag = false
        return AUTH_RESULT.INVAILD_INFO
      }
    } else {
      return AUTH_RESULT.QUEUED
    }
  }

  public getBlob = async(endpoint:string, data:object, useCredentials:boolean=false):Promise<any> => {
    const settings:ISettings = await promiseGetRecoil(settingState)
    try {
      if (settings.loadingMaskFlag === false) this.useMaskFlag = false
      const response = await this.API.get<Blob>(endpoint, {
        headers: this.requestHeader, 
        responseType: 'blob',
        withCredentials: useCredentials,
        params: data
      })
      if (settings.loadingMaskFlag === false) this.useMaskFlag = true
      return response
    } catch(error:any) {
      throw error
    }
  }

  public get = async(endpoint:string, data:object, useCredentials:boolean=false):Promise<AxiosResponse> => {
    // const requestConfig:AxiosRequestConfig = await this.getConfig(header)
    // requestConfig.params = data
    const settings:ISettings = await promiseGetRecoil(settingState)
    try {
      if (settings.loadingMaskFlag === false) this.useMaskFlag = false
      const response = await this.API.get(endpoint, {
        headers: this.requestHeader,
        withCredentials: useCredentials,
        params: data
      })
      if (settings.loadingMaskFlag === false) this.useMaskFlag = true
      return response
    } catch(error:any) {
      throw error
    }
  }

  public post = async(endpoint:string, data?:object, useCredentials:boolean=false):Promise<AxiosResponse> => {
    try {
      const response = await this.API.post(endpoint, data ? JSON.stringify(data) : undefined, {
        headers: this.requestHeader,
        withCredentials: useCredentials
      })
      return response
    } catch(error:any) {
      console.log(error)
      throw error
    }
  }

  public put = async(endpoint:string, data:object, useCredentials:boolean=false):Promise<AxiosResponse> => {
    try {
      const response = await this.API.put(endpoint, JSON.stringify(data), {
        headers: this.requestHeader,
        withCredentials: useCredentials
      })
      return response
    } catch(error:any) {
      throw error
    }
  }

  public patch = async(endpoint:string, data:object, useCredentials:boolean=false):Promise<AxiosResponse> => {
    try {
      const response = await this.API.patch(endpoint, JSON.stringify(data), {
        headers: this.requestHeader,
        withCredentials: useCredentials
      })
      return response
    } catch(error:any) {
      throw error
    }
  }

  public del = async(endpoint:string, data:object, useCredentials:boolean=false):Promise<AxiosResponse> => {
    try {
      const response = await this.API.delete(endpoint, {
        headers: this.requestHeader,
        withCredentials: useCredentials,
        data: JSON.stringify(data)
      })
      return response
    } catch(error:any) {
      throw error
    }
  }
}
