import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import { IGpu } from "../../../../model/Monitoring"


interface IGPUInfoPopupProps {
  // title?:string
  isParted:boolean
  count:number
  data:IGpu[]
}

interface IGPUInfoPopupState {
  isOpen: boolean
}

const GPUInfoPopup = (props: IGPUInfoPopupProps) => {
  const [state, setState] = useState<IGPUInfoPopupState>({
    isOpen: false
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen: !state.isOpen })
  }

  return (
    <GPUInfoPopupFragment>
      <div className={"normalWrap"}>
        <button onClick={onBtnClick} ref={modalBtnRef} className={"small"}>
          {state.isOpen && state.isOpen === true ?
            <img src='/images-v2/table-more-small.svg' alt='more' className='moreButton' />
            : <img src='/images-v2/table-more-small.svg' alt='more' className='moreButton' />}
        </button>
      </div>
      {
        state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className={"commonModal"}>
              <div className='titleWrap'>
                <div style={{ padding: '3.5px 0' }}>
                  {props.isParted ?
                    <>전체 GPU 모델명 및 분할 설정 정보&nbsp;<span>[ 분할 총 {props.count}개 ]</span></>
                    : <>전체 GPU 모델명&nbsp;<span>[ 총 {props.count}개 ]</span></>}
                </div>
              </div>
              <div className="scrollWrap">
                <table>
                  <tbody>
                    {
                      props.data.map((gpu, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {gpu.isMig ?
                                <>
                                  <div className='bottomLine'>
                                    <span className='gpuIdx'>GPU {gpu.idx}</span><span className='gpuInfo'>{gpu.name}</span>&nbsp;{gpu.isMig ? (<span className="mig">[MIG]</span>) : ''}
                                  </div>
                                  {
                                    gpu.detail.map((eachMig, idx2) => {
                                      return (<div key={idx2} className='bottomLine detail'>
                                      <img src='/images-v2/corner-down-right.svg' alt='corner-down-right'></img>{eachMig.name}&nbsp;/&nbsp;<span>{eachMig.total}개</span>
                                      </div>)
                                    })
                                  }
                                </> :
                                <div className='bottomLine'>
                                  <span className='gpuIdx'>GPU {gpu.idx}</span><span className='gpuInfo'>{gpu.name}</span>&nbsp;{gpu.isMig ? (<span className="mig">[MIG]</span>) : ''}
                                </div>}
                            </td>
                          </tr>)
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          : false
      }
    </GPUInfoPopupFragment>
  )
}

const GPUInfoPopupFragment = styled.div`
button.small {width:14px; height:14px; display:block;}
img.moreButton {width:14px;height:14px; padding-top:0px; display:block;}

.subModalPopupWrap {position:relative;}
.subModalPopupWrap .commonModal{position:absolute;margin-top:6px;width:329px;/* max-height:230px; */overflow-y:auto;flex-direction:column;box-sizing:border-box;padding:11px 15px 15px 15px;border-radius:6px;border:solid 1px #646469;background:#fff;z-index:201;box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50);}
.subModalPopupWrap .commonModal p {font-size:14px;line-height:16px; text-align: left;}
.subModalPopupWrap .commonModal .modalTitle{margin-bottom:10px;font-weight:bold;}
.subModalPopupWrap .commonModal .content + .content {margin-top:5px;}
.subModalPopupWrap {right:315px;}

.subModalPopupWrap .commonModal .titleWrap{ height:24px; display:flex; justify-content:space-between; align-items:center; font-size:14px; line-height:16.71px; font-weight:700; margin-bottom:12px;}
.titleWrap span{font-weight:400;}
.scrollWrap{max-height:164px; overflow-y:auto;border-top:1px solid #E8E8EE;border-bottom:1px solid #E8E8EE;}


.subModalPopupWrap .commonModal table{width:100%; border-collapse:collapse; font-size:14px; line-height:38px;}
.subModalPopupWrap .commonModal table td .bottomLine{border-bottom: 1px solid #E8E8EE; line-height:normal; padding:8px 12px; font-size:13px; font-weight:400;}
.subModalPopupWrap .commonModal table tr:last-child td .bottomLine:last-child{border-bottom:0;}
.subModalPopupWrap .commonModal table td .bottomLine span.gpuIdx{color: #4B82FF; font-weight:500;}
.subModalPopupWrap .commonModal table td{text-align:left;}
.subModalPopupWrap .commonModal table td .bottomLine.detail{color:#878791;}

.subModalPopupWrap .commonModal table td .bottomLine span.gpuInfo {margin-left:10px;}
//.subModalPopupWrap .commonModal table td span.mig {display:inline-block;padding:0 3px;font-size:12px;background-color:#E1F5FF;border-radius:2px;}

/* 상위 테이블 css에 영향받는 요소들 재설정 */
.subModalPopupWrap .commonModal table thead th{background-color:white; font-weight:bold;}
.subModalPopupWrap .commonModal table td{padding: 0; border-right:0;}

`

export default GPUInfoPopup