import { promiseGetRecoil, promiseSetRecoil } from 'recoil-outside'
import { ISettings, settingState } from '../states/settingState'
import { useEffect, useRef } from "react"

export default {
  numberMark (number:number) {
    if (number > 999) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return number
    }
  },
  parseTelNo (data:string) {
    return data ? data.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : data
  },

  async setLoadingMask(value:boolean) { // 미사용 ==> 자동새로고침으로 인해 필요함 굳이 이위치가 아니더라도 필요할 수 있음
    let settings:ISettings = await promiseGetRecoil(settingState)
    settings = {
      ...settings,
      loadingMaskFlag: value
    }
    // console.log(settings)
    await promiseSetRecoil(settingState, settings)
  },

  /*
  preWorkForOnChange 함수의 용도 : 
  페이지 로딩 후, input 있는 값을 [마우스 드래그] 혹은 [ctrl+a]로 전체 선택 후 백스페이스를 눌러 지우는 첫 번째 작업이 일어났을 때, 리액트 onChange에서 값이 변경되었음을 인식하지 못하는 이슈가 있다.
  해당 이슈의 해결을 위해, input창에 Focus가 갈 때 input의 value값을 한 번 변경해줘서 onChange가 제대로 인식할 수 있도록 사전작업을 해주기 위함이다.
  */
  preWorkForOnChange (event:any) {
    event.target.value += "";
  },

  /* 용도 : useEffect에서 prev값을 사용하기 위함 */
  usePrevState(state:any) {
    const ref = useRef(state)
    useEffect(() => {
      ref.current = state
    }, [state])
    return ref.current
  },

  /* 용도 : 차트(하이차트) 툴팁에서 byte 단위 변환 시 사용 - network I/O 차트 */
  byteTransform(bytes:number, digits?:number) { // digits:소수점 자리수
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    if (bytes === 0) { // byte=0 인 경우, 계산하지 않고 리턴 (로그 0은 무한대라서...)
      return {
        value: bytes,
        size: sizes[0]
      }
    }
    const isNegative = bytes < 0
    if (isNegative) bytes *= -1 // 음수인 경우, 계산 전에 양수로 변경
    const i = Number(Math.floor(Math.log(bytes)/Math.log(1024)))
    if (i === 0) { // i=0 인 경우, byte값 그대로 리턴 (digits를 1 이상 준 경우, 단위가 byte 그대로면 소수점 안나오도록 하기 위함)
      return {
        value: bytes*(isNegative ? -1 : 1),
        size: sizes[i]
      }  
    } else { // 일반 결과 리턴
      return {
        value: (bytes/(Math.pow(1024, i))*(isNegative ? -1 : 1)).toFixed(digits ? digits : 0),
        size: sizes[i]
      }  
    }
  },

  /* 용도 : 비밀번호 확인 시 사용 - 연속된 문자, 숫자 체크(3자리) */
  pwContinue(pw:string) {
    var cnt = 0
    var cnt2 = 0
    var tmp = ""
    var tmp2 = ""
    var tmp3 = ""
    for (let i = 0; i < pw.length; i++) {
      tmp = pw.charAt(i);
      tmp2 = pw.charAt(i + 1);
      tmp3 = pw.charAt(i + 2);
     
      if (tmp.charCodeAt(0) - tmp2.charCodeAt(0) == 1 && tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == 1) {
        cnt = cnt + 1;
      }
      if (tmp.charCodeAt(0) - tmp2.charCodeAt(0) == -1 && tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == -1) {
        cnt2 = cnt2 + 1;
      }
    }
    if (cnt > 0 || cnt2 > 0) {
      return true;
    } else {
      return false;
    }
  }
}
