import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

interface ICheckboxProps {
  id:string
  checked:boolean
  label?:string
  disabled?:boolean
  onChange?:(id:string, checked:boolean) => void
}

const CheckBox = forwardRef<any, ICheckboxProps>((props:ICheckboxProps, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValue:() => {
        return checked
      },
      setValue:(value:boolean) => {
        setChecked(value)
      }
    }
  })

  const [ checked, setChecked ] = useState<boolean>(props.checked)

  useEffect(() => {
    if (props.checked !== checked) setChecked(props.checked)
  }, [props.disabled, props.checked])

  return (
      <CheckBoxFragment ref={ref}>
        <input className="hide" type="checkbox" id={props.id} checked={checked} disabled={props.disabled || false} onChange={(e) => {
          setChecked(!checked)
          if (props.onChange) props.onChange(props.id, !checked)
        }} />
        <label htmlFor={props.id} className={props.disabled ? 'disabled' : ''}>
          <span className="box"></span>
          {props.label ? <span className={"text "+(props.disabled ? 'disabled' : '')}>{props.label}</span> : false}
        </label>
      </CheckBoxFragment>
  )
})

const CheckBoxFragment = styled.div`
  position:relative;
  input { position:absolute; left:0; top:0; width:0; height:0; opacity:0; }
  label { display:flex; align-items:center; gap:8px; padding:0 12px; color: #333;}
  label .box { display:block; width:18px; height:18px; background: url('/images-v2/Checkbox.png') no-repeat center / 100%; }
  label.disabled .box {background-image: url('/images-v2/Checkbox-off.png');}
  label .text { font-size:14px; }
  label .text.disabled { opacity: 0.3; }
  input:checked + label .box {background-image: url('/images-v2/Checkbox-on.png');}
  input:checked + label.disabled .box {background-image: url('/images-v2/Checkbox-on.png');opacity: 0.3;}
`
export default CheckBox