export default class PageEvent extends Event {
  public static REFRESH:string = 'refresh'
  public static SHOW_REFRESH:string = 'showRefresh'
  public static HIDE_REFRESH:string = 'hideRefresh'
  public static STOP_REFRESH:string = 'stopRefresh'
  public static REFRESH_LIMIT:string = 'refreshlimit'
  public static REFRESH_PAGE_FORM:string = 'refreshpageform'
  public static REFRESH_PAGE_MYPAGE:string = 'refreshpagemypage'
  public static LAYOUT_INIT_FINISHED:string = 'layoutinitfinished'
  public static GET_MY_INFO:string = 'getmyinfo'
  public static START_LOADING_MASK:string = 'startloadingmask'
  public static STOP_LOADING_MASK:string = 'stoploadingmask'
  
  public payload:any = {}
}