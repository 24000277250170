import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import Highcharts from 'highcharts/highcharts.js'

import { RecoilRoot } from 'recoil'
import RecoilOutside from "recoil-outside"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import './assets/css/index.css'
import App from './App'
import Config from './Config'

dayjs.extend(utc)
Highcharts.setOptions({
  time: {
    timezoneOffset: -(9 * 60)
  },
  accessibility: {
    enabled: false
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const getEnv = () => {
  let env:any = process.env
  axios.get('/env.js').then((response:AxiosResponse) => {
    for (let eachEnv of response.data.split(/\n/)) {
      let key = eachEnv.split('=')[0]
      let value = eachEnv.split('=')[1]
      if (key && value) {
        env[key] = value.replace(/['"]/g, '')
        if (env[key] === 'true') env[key] = true
        if (env[key] === 'false') env[key] = false
        if (typeof(env[key]) === 'string' && isNaN(Number(env[key])) === false) env[key] = Number(env[key])
      }
    }
    Config.env = env
    axios.get('/build_date.txt').then((buildResponse:AxiosResponse) => {
      Config.env.build = buildResponse.data
      root.render(
        <RecoilRoot>
          <RecoilOutside />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      )
    })
  })
}

getEnv()
