import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import styled from "styled-components"
import { uniqueId } from "lodash"

import TableEvent from "../../../events/TableEvent"
import { IBaseDataType, SORT_ORDER } from "../../../model/BaseDataType"
import CheckBox from "./CheckBox"
import RadioBox from "./RadioBox"

interface ITableProps {
  ref:React.ForwardedRef<JSX.Element>
  name?:string
  checkBoxType:CHECKBOX_TYPE
  colWidth:(number|null)[]
  headerLabel:ITableHeader[]
  items:IBaseDataType[]
  noData:string
  tableTotal?:ITableFooter[]
  tableTotal2?:ITableFooter[]
  onSelect?:Function
  onUpdate?:Function
  orderPrefix?:number
  preselectedKey?:string
  checkBoxPreserveKeys?:string[]
  defaultSortOrder?:SORT_ORDER
  defaultSelect?:boolean
}

export enum CHECKBOX_TYPE {
  USE_SINGLE = 'use checkboxes and check single button (radio)',
  USE_ALL = 'use checkboxes and check all button',
  USE = 'use checkboxes but no check all button',
  NONE = 'not use checkbox',
  DISABLE = 'disable checkbox'
}

export enum TABLE_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export interface ITableHeader {
  label:string,
  key:string,
  type:TABLE_CELL_TYPE,
  sort:boolean|SORT_ORDER,
  sortKey?:string,
  rowSpanKey?:string,
  styleKey?:string,
  default?:string,
  orderFlag?:boolean,
  preProcesser?:Function
  align?:TABLE_ALIGN
  ellipsis?:number
}

export interface ITableFooter {
  label:string
  unit?:string
  label2?:string
  unit2?:string
  color?:string
}

export enum TABLE_CELL_TYPE {
  STRING = 'string',
  HTML = 'html',
  FLEXBOX = 'flex'
}

const Table = forwardRef<any, ITableProps>((props:ITableProps, ref) => {
  const tableName = props.name || uniqueId('table')

  const [ items, setItems ] = useState<IBaseDataType[]>(props.items)
  const [ selected, setSelected ] = useState<(string|number)[]>(props.defaultSelect ? [0]:[])
  const [ preSelected, setPreSelected ] = useState<(string|number)[]>([])
  const [ disabledRowLength, setDisabledRowLength ] = useState<number>(0)
  const [ sortKey, setSortKey ] = useState<string>()
  const [ sortOrder, setSortOrder ] = useState<SORT_ORDER>()

  let defaultSortOrder:SORT_ORDER|undefined
  if (props.defaultSortOrder !== undefined) defaultSortOrder = props.defaultSortOrder

  useEffect(() => {
    for (let eachLabel of props.headerLabel) {
      if (eachLabel.sort === SORT_ORDER.ASC || eachLabel.sort === SORT_ORDER.DESC) {
        if (defaultSortOrder === undefined) defaultSortOrder = eachLabel.sort
        setSortKey(eachLabel.sortKey ? eachLabel.sortKey : eachLabel.key)
        setSortOrder(eachLabel.sort)
      }
    }
  }, [])

  useEffect(() => {
    let items = props.items
    let disabledRow:number = 0
    let preSelected = []
    if (props.checkBoxPreserveKeys && items) {
      for (let eachRow of items) {
        for (let eachKey of props.checkBoxPreserveKeys) {
          if (eachRow[eachKey] === true) {
            disabledRow++
            break
          }
        }
      } 
    }
    if (props.preselectedKey && items) {
      for (let key in items) {
        let eachRow = items[key]
        if (eachRow[props.preselectedKey] === true) {
          preSelected.push(Number(key))
        }
      } 
    }

    setPreSelected(preSelected)
    setDisabledRowLength(disabledRow)
    setItems(items)
    setSelected(props.preselectedKey && preSelected.length>0 ? preSelected.concat() : (props.defaultSelect ? [0] : []))
  }, [props.items])

  useEffect(() => {
    let e:TableEvent = new TableEvent(TableEvent.CHANGE_SORT_ORDER)
    e.payload = { target: tableName }
    window.dispatchEvent(e)
  }, [sortKey, sortOrder])

  useEffect(() => {
    if (props.onSelect) props.onSelect(selected)
  }, [selected])

  useImperativeHandle(ref, () => {
    return {
      selected: selected,
      setSelected: setSelected,
      sortKey: sortKey,
      sortOrder: sortOrder
    }
  })

  const sortHandler = (e:React.MouseEvent) => {
    const dataSet = (e.currentTarget as HTMLElement).dataset
    if (dataSet.sortable === 'true') {
      if (dataSet.key !== sortKey && dataSet.sortkey !== sortKey) {
        setSortKey(dataSet.sortkey ? dataSet.sortkey : dataSet.key)
        setSortOrder(defaultSortOrder)
      } else {
        setSortOrder(sortOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC)
      }
    }
  }

  const checkBoxHandler = (id:string, checked:boolean) => {
    if (items) {
      let currentSelected = selected.concat()
      if (id === 'checkAll_'+tableName) {
        currentSelected = []

        for (let i = 0; i < items.length; i++) {
          const eachRow = items[i]
          let disabled:boolean = false
          if (props.checkBoxPreserveKeys) {
            for (let eachKey of props.checkBoxPreserveKeys) {
              if (eachRow[eachKey] === true) {
                disabled = true
                break
              }
            }
          }
          if (checked === true && (disabled === false || preSelected.indexOf(i) !== -1)) currentSelected.push(i)
          if (checked === false && disabled === true && preSelected.indexOf(i) !== -1) currentSelected.push(i)
        }

        // if (checked === false || selected.length !== items.length) {
        //   for (let i = 0; i < items.length; i++) {
        //     const eachRow = items[i]
        //     let disabled:boolean = false
        //     if (props.checkBoxPreserveKeys) {
        //       for (let eachKey of props.checkBoxPreserveKeys) {
        //         if (eachRow[eachKey] === true) {
        //           disabled = true
        //           break
        //         }
        //       }
        //     }
        //     if (checked === true && (disabled === false || preSelected.indexOf(i) !== -1)) currentSelected.push(i)
        //     if (checked === false && preSelected.indexOf(i) !== -1) currentSelected.push(i)
        //   }
        // }

      } else {
        let idx = Number(id.split('_').pop())
        if (currentSelected.indexOf(idx) === -1 && checked === true) {
          currentSelected.push(idx)
          currentSelected.sort()
        } else {
          currentSelected = currentSelected.filter((each:any) => each !== idx)
        }
      }

      setSelected(currentSelected.sort((a:number, b:number) => a - b))
    }
  }
  
  const radioHandler = async(id:string) => { 
    setSelected([Number(id.replace('check_'+tableName+'_', ''))])
  }

  const getPreserveKeysClassName = (rowIdx:number) => {
    let className:string = ''
    if(props.checkBoxPreserveKeys) {
      for (let eachKey of props.checkBoxPreserveKeys) {
        if(items[rowIdx][eachKey] === true)
        {
          className += eachKey+' '
        }
      }
    }
    return className
  }
  const getPreserveKeysDisableFlag = (rowIdx:number) => {
    let disable:boolean = false
    if(props.checkBoxPreserveKeys) {
      for (let eachKey of props.checkBoxPreserveKeys) {
        if(items[rowIdx][eachKey] === true)
        {
          disable = true
          break
        }
      }
    }
    return disable
  }

  const renderText = (ellipsis:number|undefined, text:string) => {
    return ellipsis !== undefined ? <p className="ellipsis" style={{WebkitLineClamp: ellipsis}}>{text}</p> : text
  }

  return (
    <TableFragment ref={ref}>
      <colgroup>
        {
          props.colWidth.map((eachWidth:number, colIdx:number) => 
            eachWidth ? <col key={colIdx} width={eachWidth === null ? 'auto' : eachWidth+'px'}/> : <col key={colIdx} />
          )
        }
      </colgroup>
      <thead>
        <tr>
        { props.checkBoxType === CHECKBOX_TYPE.USE_ALL ? 
          <th className="checkWrap">
            <CheckBox id={"checkAll_"+tableName} 
                      checked={items ? (items.length-disabledRowLength > 0 && selected.length-disabledRowLength === items.length-disabledRowLength) : false} 
                      onChange={(id, checked) => {checkBoxHandler(id, checked)}}/>
          </th> : false 
        }
        { props.checkBoxType === CHECKBOX_TYPE.USE ? <th className="checkWrap"></th> : false }
        { props.checkBoxType === CHECKBOX_TYPE.USE_SINGLE ? <th></th> : false }
        { props.checkBoxType === CHECKBOX_TYPE.DISABLE ? <th>
          <CheckBox id={"checkAll_"+tableName} 
                    checked={items ? (items.length-disabledRowLength > 0 && selected.length-disabledRowLength === items.length-disabledRowLength) : false} 
                    onChange={(id, checked) => {checkBoxHandler(id, checked)}}
                    disabled={true} />
          </th> : false }
        {
          props.headerLabel.map((eachProps:ITableHeader, headerIdx:number) => {
            let sortFlag:boolean|SORT_ORDER = eachProps.sort
            if (eachProps.default === undefined) eachProps.default = ''
            return (
              <th key={eachProps.key} data-key={eachProps.key} 
                  data-sortable={sortFlag !== false} data-sortkey={eachProps.sortKey}
                  onClick={sortHandler} 
                  className={(sortFlag === false ? '' : 'clickable ')+
                              ((sortKey === eachProps.key || sortKey === eachProps.sortKey) ? 'active' : '')
                              }>
                <div>
                  { eachProps.label }
                  { sortFlag === true || sortFlag === SORT_ORDER.ASC || sortFlag === SORT_ORDER.DESC ?
                    <span className="order">
                      <img src="/images-v2/sort_up.svg" alt="order asc" className={sortOrder === SORT_ORDER.ASC ? 'on' : ''} />
                      <img src="/images-v2/sort_down.svg" alt="order desc" className={sortOrder === SORT_ORDER.DESC ? 'on' : ''} />
                    </span> : false
                  }
                </div>
              </th>
            )
          })
        }
        </tr>
      </thead>
      <tbody>
      { items && items.length > 0 ? (
        <React.Fragment>
          { items.map((eachRow:IBaseDataType, rowIdx:number) => 
            <tr key={rowIdx} 
                className={(eachRow.rowClassName ? eachRow.rowClassName+' ' : '')+(getPreserveKeysClassName(rowIdx)) /* + (eachRow.changedFlag ? ' changed' : '') */ + (selected.indexOf(rowIdx) !== -1 ? ' selected' : '')} 
                data-priority={eachRow.priority ? eachRow.priority+''+(rowIdx+1) : ''}>
              { props.checkBoxType === CHECKBOX_TYPE.USE || props.checkBoxType === CHECKBOX_TYPE.USE_ALL || props.checkBoxType === CHECKBOX_TYPE.DISABLE ? 
                <td className="checkWrap"><CheckBox id={'check_'+tableName+'_'+rowIdx} checked={selected.indexOf(rowIdx) !== -1} 
                    disabled={props.checkBoxType === CHECKBOX_TYPE.DISABLE ? true : (getPreserveKeysDisableFlag(rowIdx))}
                    onChange={(id, checked) => {checkBoxHandler(id, checked)}} />
                </td> : false 
              }
              { props.checkBoxType === CHECKBOX_TYPE.USE_SINGLE ? 
                <td className="checkWrap"><RadioBox name={tableName} id={'check_'+tableName+'_'+rowIdx} checked={selected.indexOf(rowIdx) !== -1} 
                    onChange={(id) => {radioHandler(id)}} />
                </td> : false 
              }
              {
                props.headerLabel.map((eachProps:ITableHeader, colIdx:number) => {
                  let data = eachRow[eachProps.key]
                  let rowSpan:number = 1
                  let style:React.CSSProperties = {}
                  let styleClass:string = ''
                  //console.log(data)
                  if (eachProps.rowSpanKey) {
                    rowSpan = eachRow[eachProps.rowSpanKey]
                  }
                  if (eachProps.styleKey) {
                    style = eachRow[eachProps.styleKey]
                  }
                  if (eachProps.align) {
                    style.textAlign = eachProps.align
                  }
                  if (data === null && eachProps.type !== TABLE_CELL_TYPE.STRING || data === undefined && eachProps.type !== TABLE_CELL_TYPE.STRING) {//*1 undefined처리 추가함
                    return false
                  } else {
                    if (eachProps.preProcesser) data = eachProps.preProcesser(data)
                    let child = (<React.Fragment />)
                    switch(eachProps.type) {
                      case TABLE_CELL_TYPE.STRING:
                        child = (<>
                          { renderText(eachProps.ellipsis, (data || eachProps.default)) }
                          {/* { eachProps.orderFlag ? <div className="ordered">
                            <div className="orderNumber">{(props.orderPrefix || 0)+rowIdx+1}.</div>
                            { renderText(eachProps.ellipsis, (data || eachProps.default)) }
                          </div> : renderText(eachProps.ellipsis, (data || eachProps.default)) } */}
                        </>)
                        break
                      case TABLE_CELL_TYPE.HTML:
                        child = (<div dangerouslySetInnerHTML={ {__html: data || eachProps.default } } />)
                        break
                      case TABLE_CELL_TYPE.FLEXBOX:   // FlexBox로 셀안에 들어가는 데이터의 경우 반드시 2차원배열의 형태여야 함
                        let buffer:JSX.Element[] = []
                        if (data.length) data.map((eachBoxRow:any[], cellRowIdx:number) => { 
                          //* 2. 주석 처리된 data 여부 확인을 함께 진행해서 출력하거나 혹은 *1에서와 같이 처리하는 두가지 방식으로 출력 오류 해결
                          if (eachBoxRow.length) eachBoxRow.map((eachCell:any, cellColIdx:number) => {
                            buffer.push(<div key={'cellBox'+(cellRowIdx*100)+cellColIdx}>{eachCell}</div>)
                          })
                          if (cellRowIdx !== data.length) buffer.push(<div key={cellRowIdx+'br'} className="lineBreak"></div>)
                        })
                        child = (<>
                        {eachProps.orderFlag ? <div className="ordered">
                          <div className="orderNumber">{(props.orderPrefix || 0)+rowIdx+1}.</div>
                          <div className="flexbox">{buffer}</div>
                          </div> : <div className="flexbox">{buffer}</div>
                          }
                        </>
                        )
                        break
                      default:
                        child = (<React.Fragment>{eachProps.type}</React.Fragment>)
                        break
                    }
                    return (<td key={colIdx} rowSpan={rowSpan} style={style} className={'col'+colIdx+' '+styleClass}>{child}</td>)
                  }
                })
              }
            </tr>
          )}
        </React.Fragment>
      ) : (
        <tr>
          <td className="noData" colSpan={props.checkBoxType !== CHECKBOX_TYPE.NONE ? props.headerLabel.length+1 : props.headerLabel.length}>{props.noData}</td>
        </tr>
      )}
      </tbody>
      <tfoot>
        {
          props.tableTotal ? <tr>
            {
              props.tableTotal.map((eachProps:ITableFooter, footerIdx:number) => (
                <td key={footerIdx}>
                  <b style={{color: eachProps.color ? eachProps.color : ''}}>{eachProps.label} </b>
                  {eachProps?.unit}
                  {eachProps.label2 ? <><b style={{color: eachProps.color ? eachProps.color : ''}}>{eachProps.label2} </b>{eachProps?.unit2}</> : false}
                </td>
              ))
            }
          </tr> : false
        }
        {
          props.tableTotal2 ? <tr>
            {
              props.tableTotal2.map((eachProps:ITableFooter, footerIdx:number) => (
                <td key={footerIdx}>
                  <b style={{color: eachProps.color ? eachProps.color : ''}}>{eachProps.label} </b>
                  {eachProps?.unit}
                  {eachProps.label2 ? <><b style={{color: eachProps.color ? eachProps.color : ''}}>{eachProps.label2} </b>{eachProps?.unit2}</> : false}
                </td>
              ))
            }
          </tr> : false
        }
      </tfoot>
    </TableFragment>
  )
})

const TableFragment = styled.table`
  width: 100%;border-collapse: collapse;border-top:2px solid #333;color: #333;font-size: 14px;font-weight: 400;
  thead{border-bottom:1px solid #B4B4BE;}
  thead th {height: 30px;padding:0;font-weight:inherit;line-height:18px;color: #646469;}
  thead th + th{border-left:1px solid #E8E8EE;}
  thead th img {display:inline-block;width:18px;margin-left:5px;vertical-align:sub;}
  thead th img.reverse {transform:rotate(180deg)}
  /* tbody tr {pointer-events:none} */
  tbody tr.changed td {background:#FAFAFB}
  tbody tr.new td{font-weight:bold;}
  tbody tr.error > td{color: #FF0000;}
  tbody tr.fail td{color: #E8E8EE;}
  tbody tr.deactivate td{color: #C1C1C1;}
  tbody tr:hover td {background:#fafafa}
  tbody tr:hover td table tr td {background:none;}
  tbody td {padding:8px 12px;background: #fff;border-bottom: 1px solid #ebebeb;border-right:1px solid #ebebeb;word-break:break-all;white-space:pre-line;}
  tbody td:last-child {border-right:none;}
  tbody td p.ellipsis{position:relative;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;white-space: pre-wrap;}
  tbody td .btnWrap{gap: 6px;}
  tfoot td {height: 35px;padding:0;background: #f5f5f5;line-height:18px;text-align:center;font-weight: bold;border: 1px solid #ebebeb; border-left: none;}
  tfoot td:last-child{border-right:none;}
  tfoot td b {font-weight: bold;}
  .checkWrap {position: relative;padding: 0;}
  .selected td {background: rgba(255, 208, 40, 0.06);}
  .clickable > div {display:flex;align-items:center;justify-content:center;cursor:pointer;}
  .clickable .order{display:flex;flex-direction:column;}
  .clickable .order img{width:10px;}
  .clickable.active .order img.on{filter: brightness(0) saturate(100%) invert(13%) sepia(1%) saturate(4%) hue-rotate(321deg) brightness(99%) contrast(81%);}
 
  .ordered {display:flex;align-items:center;height:100%;gap:13px}
  .ordered > div {display:inline-flex}
  .ordered > div.orderNumber { word-break:keep-all }
  
  .flexbox { display:flex; flex-wrap:wrap; justify-content:space-evenly;}
  .flexbox > div.lineBreak { flex-basis:100%; }
  .flexbox > div.lineBreak + div{ margin-top:6px; }

  .noData {height:24px;text-align:center;color:#333333;font-size:14px;box-sizing: border-box;}

  td > * + *{margin-top:6px;}
` 

export default Table