import React, { Component, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { IResourceUnitSpec } from '../../../../model/Workspace'

interface IBlockPopupProps {
  blockData: IResourceUnitSpec
}
interface IBlockPopupState {
  isOpen: boolean
}

const BlockPopup = (props: IBlockPopupProps) => {
  const [state, setState] = useState<IBlockPopupState>({
    isOpen: false
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen: !state.isOpen })
  }

  return (
    <BlockPopupFragment>
      <div style={{margin:'0'}}>
        <button onClick={() => { onBtnClick() }} ref={modalBtnRef}>
          {state.isOpen === true ?
            <img src='/images/buttonMoreRounded.svg' alt='more' className='moreIconButton' />
            : <img src='/images/buttonMoreRounded_1.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      {
        state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className="commonModal">
              <p className="modalTitle">리소스 정보</p>
              <p className="content">CPU : {props.blockData.cpu} 코어</p>
              <p className="content">MEM : {props.blockData.mem} {props.blockData.memUnit}</p>
              <p className="content">GPU : {props.blockData.gpuPer} %</p>
              <p className="content">GPU mem : {props.blockData.gpuMem} {props.blockData.gpuMemUnit}</p>
            </div>
          </div>
          : false
      }
    </BlockPopupFragment>
  )
}

const BlockPopupFragment = styled.div`
.subModalPopupWrap {position:relative;}
button {margin:4px 0 0 0;}
img {display:block;}
img.moreButton { width:17px;height:18px;vertical-align:bottom; padding-top:4px;}
.subModalPopupWrap .commonModal .btnClose{position:absolute;right:0;top:0;width:50px;height:50px;background:url(/images/ico-close2.png) no-repeat center / 14px;}
.subModalPopupWrap .commonModal{position:absolute;left:0;top:10px;width:200px;flex-direction:column;box-sizing:border-box;padding:30px;border-radius: 2px;box-shadow: 3px 3px 3px 0px #DFDFDF;border: solid 1px #DFDFDF;background:#fff;z-index:997;}
.subModalPopupWrap .commonModal .btnClose{position:absolute;right:0;top:0;width:50px;height:50px;background:url(/images/ico-close2.png) no-repeat center / 14px;}
.subModalPopupWrap .commonModal p {font-size:14px;line-height:16px; text-align: left;}
.subModalPopupWrap .commonModal .modalTitle{margin-bottom:10px;font-weight:bold;}
.subModalPopupWrap .commonModal .content + .content {margin-top:5px;}
`

export default BlockPopup