import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../utils'
import dayjs from 'dayjs'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import ISelectOption from '../../interfaces/SelectOption'
import { IChartSeries } from '../../interfaces/Chart'
import Select from '../components/ui/Select'
import DateRangePicker from '../components/ui/DateRangePicker'
import NoData from '../components/NoData'
import BarChart from '../components/chart/BarChart'
import BillingAPI, { IBilling, IUserUsage } from '../../model/Billing'
import PopupController from '../../controller/PopupController'
import { useRecoilState } from 'recoil'
import { authState, IUserAuthInfo } from '../../states/authStates'
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../components/ui/Table'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import { TIME_PICKER_TYPE } from '../components/ui/TimePicker'
import UserInfoPopup from '../components/modal/sub/UserInfoPopup'
import PageEvent from '../../events/PageEvent'
import { RESOURCE_TYPE } from '../../model/Workspace'

enum BILLING_TYPE {
  USER = 'user',
  RGROUP = 'rgroup'
}

interface IBlock {
  blockType:string
  fee:number
  numBlock:number
  workingTime:number
}

interface IUsuage {
  resourceGroup:any
  name:string
  userInfo:any //IUserInfo|null
  resourceInfo:string
  period:string
  totalWorkingTime:string
  rowSpan?:number
  style1?:CSSProperties
  style2?:CSSProperties
  // fee:number
}

interface IUserBillingInfo {
  no:number
  name:string
  data:(number|null)[]
  color?:string
  showInLegend?:boolean
}

interface IBillingTotalData {
  categories:string[]
  chartData:IChartSeries[]
}
interface IBillingMonthlyData {
  total:{
    count:number
    time:number
    gpuTotalTime:number
    gpuTotalTimeHour:number
    gpuTotalTimeMin:number
    cpuTotalTime:number
    cpuTotalTimeHour:number
    cpuTotalTimeMin:number
    fee:number
  }
  gpuList:IBlock[]
  tableItems:IUsuage[]
}

interface IBillingProps {
}

interface IBillingState {
  authInfo?:IUserAuthInfo
  range:{
    from:string
    to:string
  }
  limit:{
    from:string
    to:string
  }
  month:ISelectOption
  page:number
  totalArticle:number
  monthList:ISelectOption[]
}

const Billing = (props:IBillingProps) => {
  const popupController = PopupController.getInstance()
  const [ authInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  // const startTimestamp:number = JSON.parse(window.localStorage.getItem('tenAuthInfo') || '{}').loginState.startTimestamp
  let startTimestamp:number = userInfo?.startTimestamp || 0
  let startFrom:string = dayjs(startTimestamp*1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
  let initialFrom:string = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const [ tab, _setTab ] = useState<BILLING_TYPE>(BILLING_TYPE.USER)
  const tabRef = useRef(tab)
  const setTab = (data:any) => {
    tabRef.current = data
    _setTab(data)
  }

  const [ totalData, setTotalData ] = useState<IBillingTotalData>({
    categories: [],
    chartData: [],
  })
  const [ monthlyDataOrigin, _setMonthlyDataOrigin ] = useState<any>(undefined)
  const monthlyDataOriginRef = useRef(monthlyDataOrigin)
  const setMonthlyDataOrigin = (data:any) => {
    monthlyDataOriginRef.current = data
    _setMonthlyDataOrigin(data)
  }
  const [ monthlyData, setMonthlyData ] = useState<IBillingMonthlyData>({
    total: {
      count: 0,
      time: 0,
      gpuTotalTime: 0,
      gpuTotalTimeHour: 0,
      gpuTotalTimeMin: 0,
      cpuTotalTime: 0,
      cpuTotalTimeHour: 0,
      cpuTotalTimeMin: 0,
      fee: 0
    },
    gpuList: [],
    tableItems: [],
  })
  const [ state, setState ] = useState<IBillingState>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: startFrom,
      to: defaultTo
    },
    month: { label: dayjs().format('YYYY년 M월'), value: dayjs().format('YYYY-MM') },
    page: 1,
    totalArticle: 1,
    monthList: []
  })

  const tableColWidth = [200, 200, 166, 320, 230, 200]
  const headerLabelUser:ITableHeader[] = [
    { label: '사용자 정보', key: 'userInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, rowSpanKey: 'rowSpan', styleKey: 'style1' },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey: 'style1' },
    { label: '리소스 그룹', key: 'resourceGroup', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey: 'style1' },
    { label: '리소스 타입 / 개수', key: 'resourceInfo', type: TABLE_CELL_TYPE.HTML, sort: false, styleKey: 'style1' },
    { label: '사용 기간', key: 'period', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey: 'style2' },
    { label: '총 사용 시간', key: 'totalWorkingTime', type: TABLE_CELL_TYPE.HTML, sort: false, styleKey: 'style2' },
  ]
  const headerLabelRgroup:ITableHeader[] = [
    { label: '리소스 그룹', key: 'resourceGroup', type: TABLE_CELL_TYPE.HTML, sort: false, rowSpanKey: 'rowSpan', styleKey: 'style1' },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey: 'style1' },
    { label: '운영자', key: 'userInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, styleKey: 'style1' },
    { label: '리소스 타입 / 개수', key: 'resourceInfo', type: TABLE_CELL_TYPE.HTML, sort: false, styleKey: 'style1' },
    { label: '사용 기간', key: 'period', type: TABLE_CELL_TYPE.STRING, sort: false, styleKey: 'style2' },
    { label: '총 사용 시간', key: 'totalWorkingTime', type: TABLE_CELL_TYPE.HTML, sort: false, styleKey: 'style2' },
  ]

  useEffect(() => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return () => {
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
  }, [])

  useEffect(() => {
    // Layout에서 userInfo 정보 세팅이 끝나면 Init 진행
    if(userInfo?.startTimestamp) {
      Init(userInfo?.startTimestamp)
    }
  }, [userInfo?.startTimestamp])
  const Init = (startTimestamp:number) => {
    // monthList 계산 (월별 데이터 select 구성)
    const startMonth = dayjs.unix(startTimestamp).format('YYYY-MM')
    const diff = dayjs().diff(startMonth, 'month')
    let i: number,
      monthList: ISelectOption[] = []
    for (i = 0; i <= diff; i++) {
      monthList.push({
        label: dayjs().add(-i, 'month').format('YYYY년 M월'),
        value: dayjs().add(-i, 'month').format('YYYY-MM')
      })
    }

    // 그래프용 날짜 계산
    startFrom = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
    initialFrom = dayjs().subtract(5, 'month').unix() < startTimestamp ? startFrom : dayjs().subtract(5, 'month').format('YYYY-MM-DD HH:mm')
    initialTo = dayjs().format('YYYY-MM-DD HH:mm')
    defaultFrom = initialFrom
    defaultTo = initialTo

    setState({
      ...state,
      range: {
        from: defaultFrom,
        to: defaultTo
      },
      limit: {
        from: startFrom,
        to: defaultTo
      },
      monthList: monthList
    })

    // 그래프 데이터 호출
    getTotalData({ from: defaultFrom, to: defaultTo })

    // 월별 데이터는 가장 최신 월의 데이터를 바로 가져오기 때문에 따로 호출해줄 필요는 없음...
  }

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const tabHandler = (target:BILLING_TYPE = BILLING_TYPE.USER) => {
    setTab(target)
    changeMonthlyData(monthlyDataOriginRef.current)
  }

  const getMonthlyData = async (newMonth:ISelectOption): Promise<void> => {
    try {
      const start = dayjs(`${newMonth.value}-01`).unix()
      const end = dayjs(`${newMonth.value}-01`).add(1, 'month').unix()
      let data = await BillingAPI.getMontlyData(start, end)
      if (data) {
        setMonthlyDataOrigin(data)
        changeMonthlyData(data)
      }
    } catch (error: any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 8e88d5')
    }
  }
  const changeMonthlyData = (data:any) => {
    if(!data) return

    let detailList: IUsuage[] = [],
      detail: IUsuage,
      i: number, item: any, j: number, jtem: any,
      totalCount: number = 0,
      totalGpuTime: number = 0,
      totalCpuTime: number = 0
    // totalFee:number = 0

    if (tabRef.current === BILLING_TYPE.USER && data.userObj.length) {
      for (i = 0; i < data.userObj.length; i++) {
        item = data.userObj[i]
        totalCount = 0
        totalGpuTime = 0
        totalCpuTime = 0
        for (j = 0; j < item.object.length; j++) {
          jtem = item.object[j]
          detail = {
            userInfo: j === 0 ?
              [
                [(<div style={{ display: 'flex' }}>
                  <div style={{ width: '120px', textAlign: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{item.name ? item.name : '(이름 없음)'}</EllipsisToolTip></div>
                </div>)],
                [(<div style={{ display: 'flex', fontWeight: 'bold', color: '#2c78ff' }}>
                  <div style={{ width: '120px', textAlign: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{item.userId ? item.userId : '(ID 없음)'}</EllipsisToolTip></div>
                </div>)],
                [(<div style={{ display: 'flex' }}>
                  <div style={{ width: '120px', textAlign: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{item.department ? item.department : '(부서 없음)'}</EllipsisToolTip></div>
                </div>)],
                [(<div style={{ display: 'flex' }}>
                  <div style={{ width: '120px', textAlign: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{item.phoneNumber ? Utils.parseTelNo(item.phoneNumber) : '(전화번호 없음)'}</EllipsisToolTip></div>
                </div>)],
                [(<div style={{ display: 'flex' }}>
                  <div style={{ width: '120px', textAlign: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{item.email ? item.email : '(이메일 없음)'}</EllipsisToolTip></div>
                </div>)]
              ]
              : null,
            resourceGroup: jtem.rgroup,
            name: jtem.name,
            resourceInfo: jtem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
            `${jtem.resourceBlock.type} / <b>${jtem.resourceBlock.gpuNum} 개</b>` : 
            `${jtem.resourceBlock.type}, CPU: <b>${jtem.resourceBlock.cpu} 코어</b>, MEM: <b>${jtem.resourceBlock.mem} ${jtem.resourceBlock.memUnit}</b>`,
            period: `${dayjs(jtem.startedAt * 1000).format('YYYY-MM-DD HH:mm')} ~\n ${jtem.deletedAt !== null ? dayjs(jtem.deletedAt * 1000).format('YYYY-MM-DD HH:mm') : dayjs().format('YYYY-MM-DD HH:mm') + ' (가동중)'}`,
            totalWorkingTime: Math.floor(jtem.usage / 60) > 0 ? `${Utils.numberMark(Math.floor(jtem.usage / 60))} 시간 ${Utils.numberMark(jtem.usage % 60)} 분` : `${Utils.numberMark(jtem.usage)} 분`,
            rowSpan: j === 0 ? item.object.length + (data.gpuUsage !== null ? 1 : 0) + (data.cpuUsage !== null ? 1 : 0) : 1,
            style2: { textAlign: 'center', whiteSpace: 'pre-line' }
          }
          totalCount = totalCount + jtem.resourceBlock.gpuNum
          if (jtem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU) {
            totalGpuTime = totalGpuTime + jtem.usage
          }
          else {
            totalCpuTime = totalCpuTime + jtem.usage
          }
          // totalFee = totalFee + jtem.fee
          detailList.push(detail)
        }

        let gpuTotalTimeHour = Math.floor(totalGpuTime / 60),
        gpuTotalTimeMin = totalGpuTime % 60,
        cpuTotalTimeHour = Math.floor(totalCpuTime / 60),
        cpuTotalTimeMin = totalCpuTime % 60

        if(data.gpuUsage !== null) {
          detailList.push({
            resourceGroup: '',
            userInfo: null,
            name: 'GPU 리소스 블록 사용 소계',
            resourceInfo: `${Utils.numberMark(totalCount)}개`,
            period: '',
            totalWorkingTime: gpuTotalTimeHour > 0 ? `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeHour)}</b> 시간 <b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeMin)}</b> 분` : `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeMin)}</b> 분`,
            style1: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' },
            style2: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' }
          })  
        }
        if(data.cpuUsage !== null) {
          detailList.push({
            resourceGroup: '',
            userInfo: null,
            name: 'CPU 리소스 사용 소계',
            resourceInfo: '-',
            period: '',
            totalWorkingTime: cpuTotalTimeHour > 0 ? `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeHour)}</b> 시간 <b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeMin)}</b> 분` : `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeMin)}</b> 분`,
            style1: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' },
            style2: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' }
          })
        }
      }
    }
    else if (tabRef.current === BILLING_TYPE.RGROUP && data.rgroupObj) {
      for (let i in data.rgroupObj) {
        item = data.rgroupObj[i]
        totalCount = 0
        totalGpuTime = 0
        totalCpuTime = 0
        for (j = 0; j < item.length; j++) {
          jtem = item[j]
          detail = {
            resourceGroup: j === 0 ? `${jtem.rgroup}` : null,
            name: jtem.name,
            userInfo: [
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{jtem.user.name ? jtem.user.name : '(이름 없음)'}</EllipsisToolTip></div>)],
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{jtem.user.userId ? jtem.user.userId : '(ID 없음)'}</EllipsisToolTip></div>)],
              [jtem.user.name && jtem.user.userId ?
                (<div style={{ width: '125px', display: 'flex', justifyContent: 'start', marginTop: '6px' }}>
                  <UserInfoPopup data={jtem.user} />
                </div>) : false],
            ],
            resourceInfo: jtem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
            `${jtem.resourceBlock.type} / <b>${jtem.resourceBlock.gpuNum} 개</b>` : 
            `${jtem.resourceBlock.type}, CPU: <b>${jtem.resourceBlock.cpu} 코어</b>, MEM: <b>${jtem.resourceBlock.mem} ${jtem.resourceBlock.memUnit}</b>`,
            period: `${dayjs(jtem.startedAt * 1000).format('YYYY-MM-DD HH:mm')} ~\n ${jtem.deletedAt !== null ? dayjs(jtem.deletedAt * 1000).format('YYYY-MM-DD HH:mm') : dayjs().format('YYYY-MM-DD HH:mm') + ' (가동중)'}`,
            totalWorkingTime: Math.floor(jtem.usage / 60) > 0 ? `${Utils.numberMark(Math.floor(jtem.usage / 60))} 시간 ${Utils.numberMark(jtem.usage % 60)} 분` : `${Utils.numberMark(jtem.usage)} 분`,
            rowSpan: j === 0 ? Number(item.length) + (data.gpuUsage !== null ? 1 : 0) + (data.cpuUsage !== null ? 1 : 0) : 1,
            style2: { textAlign: 'center', whiteSpace: 'pre-line' }
          }
          totalCount = totalCount + jtem.resourceBlock.gpuNum
          if (jtem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU) {
            totalGpuTime = totalGpuTime + jtem.usage
          }
          else {
            totalCpuTime = totalCpuTime + jtem.usage
          }
          // totalFee = totalFee + jtem.fee
          detailList.push(detail)
        }
        let gpuTotalTimeHour = Math.floor(totalGpuTime / 60),
            gpuTotalTimeMin = totalGpuTime % 60,
            cpuTotalTimeHour = Math.floor(totalCpuTime / 60),
            cpuTotalTimeMin = totalCpuTime % 60

        if (data.gpuUsage !== null) {
          detailList.push({
            resourceGroup: null,
            name: 'GPU 리소스 블록 사용 소계',
            userInfo: [[null]],
            resourceInfo: `${Utils.numberMark(totalCount)}개`,
            period: '',
            totalWorkingTime: gpuTotalTimeHour > 0 ? `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeHour)}</b> 시간 <b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeMin)}</b> 분` : `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(gpuTotalTimeMin)}</b> 분`,
            style1: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' },
            style2: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' }
          })
        }
        if (data.cpuUsage !== null) {
          detailList.push({
            resourceGroup: null,
            name: 'CPU 리소스 사용 소계',
            userInfo: [[null]],
            resourceInfo: '-',
            period: '',
            totalWorkingTime: cpuTotalTimeHour > 0 ? `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeHour)}</b> 시간 <b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeMin)}</b> 분` : `<b style="color: #2c78ff;font-weight:bold;">${Utils.numberMark(cpuTotalTimeMin)}</b> 분`,
            style1: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' },
            style2: { backgroundColor: '#F5F5F5', fontWeight: 'bold', textAlign: 'center', padding: '9px 20px' }
          })
        }
      }
    }
    //////////////////////////////////////
    setMonthlyData({
      ...monthlyData,
      total: {
        count: data.numObj,
        time: data.totalTime,
        gpuTotalTime: data.gpuUsage,
        gpuTotalTimeHour: Math.floor(data.gpuUsage / 60),
        gpuTotalTimeMin: data.gpuUsage % 60,
        cpuTotalTime: data.cpuUsage,
        cpuTotalTimeHour: Math.floor(data.cpuUsage / 60),
        cpuTotalTimeMin: data.cpuUsage % 60,
        fee: data.totalFee
      },
      gpuList: data.blockList,
      tableItems: detailList
    })
  }

  const getTotalData = async (newRange:any): Promise<void> => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()
      let data = await BillingAPI.getTotalData(FROM, TO)
      if (data) {
        const chartData = parseChartData(data.total)
        setTotalData({
          ...totalData,
          categories: chartData.categories,
          chartData: chartData.serise
        })
      }
    } catch (error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 7998e1')
    }
  }

  const parseChartData = (data:IBilling[]) => {
    const COLORS:string[] = ['#BECC23', '#E68C4F', '#0F8D62', '#583577', '#B48E04', '#677FA4', '#CDAC80', '#502BBB', '#7C2A03', '#025F5C', '#590608', '#848614', '#B8718A', '#0A6899', '#B83A40', '#013A50', '#F45649', '#072D9F', '#1293E9', '#E2589F', '#039CA4', '#AA80E9', '#7DB498']
    let categories:string[] = []
    let seriseData:IUserBillingInfo[] = []
    let i:number, j:number, item:IBilling

    for (i = 0; i < data.length; i++) {
      item = data[i]
      categories.push(`${item.month}월`)
      for (j = 0; j < item.userUsage.length; j++) {
        let userItem:IUserUsage = item.userUsage[j]
        let addr = seriseData.findIndex(element => element.name === userItem.id && element.no === userItem.no)
        if (addr === -1) {
          seriseData.push({
            no: userItem.no,
            name: userItem.id,
            data: Array(data.length).fill(null, 0, data.length),
            color: COLORS[seriseData.length % COLORS.length],
            showInLegend: true
          })
          addr = seriseData.length-1
        }
      }
    }

    for (i = 0; i < data.length; i++) {
      item = data[i]
      for (j = 0; j < item.userUsage.length; j++) {
        let userItem:IUserUsage = item.userUsage[j]
        let addr = seriseData.findIndex(element => element.name === userItem.id && element.no === userItem.no)
        seriseData[addr].data[i] = userItem.usage
      }
    }

    return {
      categories: categories,
      serise: seriseData
    }
  }

  const onChangeDatePicker = (range:any) => {
    setTotalData({
      ...totalData,
      categories: [],
      chartData: [],
    })
    setState({
      ...state,
      range: {
        from: range.from,
        to: range.to
      }
    })
    getTotalData(range)
  }

  const getMonthlyDataExcel = async () => {
    if (authInfo) {
      const start = dayjs(`${state.month.value}-01`).unix()
      const end = dayjs(`${state.month.value}-01`).add(1, 'month').unix()
      const filename = state.month.value.replace('-', '')+'-AI Pub DEV_download_'+dayjs().format('YYYYMMDDHHmm')+'.xlsx'

      try {
        BillingAPI.download(start, end, filename)
      } catch (error:any) {
        popupController.confirm('파일 다운로드에 실패하였습니다.')
      }
    }
  }

  const onChangeSelect = (data:ISelectOption) => {
    setState({
      ...state,
      month: data
    })
    getMonthlyData(data)
  }

  return (
    <BillingFragment>
      <h2 className="pageTitle">리소스 사용 내역</h2>
        <DateRangePicker changeMessageHideFlag={true} initial={{from: initialFrom, to: initialTo}} 
          default={{from: defaultFrom, to: defaultTo}} limit={state.limit} 
          data={state.range} onChange={onChangeDatePicker} type={TIME_PICKER_TYPE.NONE} />
        <p className="unit">{/*단위: 분*/}</p>
        {
          totalData.chartData.length ? 
          <BarChart unit="분" totalLabel="총 사용 시간" height={400} min={0} data={totalData.chartData} categories={totalData.categories} />
          : <NoData height={400} />
        }
        <div className="divider"></div>

        <div className="pageTitle" style={{marginBottom:'20px'}}>
          <div className="tabWrap">
            <div className="tabBox">
              <button className={tab === BILLING_TYPE.USER ? 'on' : ''}
                onClick={() => {tabHandler(BILLING_TYPE.USER)}} >계정별 리소스 사용 내역</button>
            </div>
            <div className="tabBox">
              <button className={tab === BILLING_TYPE.RGROUP ? 'on' : ''}
                onClick={() => {tabHandler(BILLING_TYPE.RGROUP)}} >리소스 그룹별 리소스 사용 내역</button>
            </div>
          </div>
        </div>

        <div className="detailTitle">
          <div>
            <Select option={state.monthList} selected={state.month} onChange={onChangeSelect} />
            <h3 className="title">리소스 사용내역</h3>
          </div>
          <button className="btn outline pre" onClick={getMonthlyDataExcel}><img src="/images/excel.png" alt='excel' />다운로드</button>
        </div>
        <div className={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? 'summary' : 'summary short'}>
          <dl>
            <dt>생성된 워크스페이스 개수</dt>
            <dd style={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? { marginTop: '37px' } : { marginTop: '12px' }}><span>{Utils.numberMark(monthlyData.total.count)}</span> 개</dd>
          </dl>
          <dl className={monthlyData.total.gpuTotalTime !== null && monthlyData.total.cpuTotalTime !== null ? '' : 'short'}>
          <dt>총 사용 시간</dt>
          <dd style={{ marginTop: '12px' }}>
            { monthlyData.total.gpuTotalTime !== null ? 
              <div>GPU : <span>{Utils.numberMark(monthlyData.total.gpuTotalTimeHour)}</span> 시간 <span>{Utils.numberMark(monthlyData.total.gpuTotalTimeMin)}</span> 분</div>
              : false}
            { monthlyData.total.cpuTotalTime !== null ?
              <div>CPU : <span>{Utils.numberMark(monthlyData.total.cpuTotalTimeHour)}</span> 시간 <span>{Utils.numberMark(monthlyData.total.cpuTotalTimeMin)}</span> 분</div>
            : false}
          </dd>
          </dl>
        </div>
      {tabRef.current === BILLING_TYPE.USER ?
        <Table colWidth={tableColWidth}
          headerLabel={headerLabelUser}
          items={monthlyData.tableItems}
          noData="데이터가 없습니다."
          checkBoxType={CHECKBOX_TYPE.NONE} /> :
        <Table colWidth={tableColWidth}
          headerLabel={headerLabelRgroup}
          items={monthlyData.tableItems}
          noData="데이터가 없습니다."
          checkBoxType={CHECKBOX_TYPE.NONE} />
      }
    </BillingFragment>
  )
}

const BillingFragment = styled.div`
  p { padding: 0px 15px 0px 0px; }

  .pageTitle{margin-bottom:43px;}
  .unit{margin:30px 0 40px;font-size:14px;text-align:right;}

  .tabWrap{display:flex;}
  .tabWrap button{color:#ccc;}
  .tabWrap button.on{color:#303030;}
  .tabBox{display:flex; align-items:center;}
  .tabBox + .tabBox:before{content:'';display:inline-block;width:2px;height:26px;margin:0 16px;background:#ccc;vertical-align:middle;}
  .tabBox > button{font-size:24px; font-weight:500;}

  .detailTitle{display:flex;align-items:center;justify-content:space-between;}
  .detailTitle > div{display:flex;align-items:center;}
  .detailTitle p{border:none;color: #202020;font-size:24px;font-weight:bold;}

  .title{font-size:18px;font-weight:bold;}
  .title > div{margin-left:10px;}

  .summary { display:flex; height:185px; align-items:center; margin:30px 0 50px; background:#f5f5f5; }
  .summary.short { display:flex; height:128px; align-items:center; margin:30px 0 50px; background:#f5f5f5; }
  .summary dl { position:relative; flex:1; text-align:center; color:#303030; }
  .summary dl + dl:before { content:''; position:absolute; left:0; top:46px; width:1px; height:93px; margin-top:-24px; background:#a7a7a7; }
  .summary dl + dl.short:before { content:''; position:absolute; left:0; top:46px; width:1px; height:47px; margin-top:-24px; background:#a7a7a7; }
  .summary dd { margin-top:15px; font-size:32px; font-weight:bold; }
  .summary dd span { color:#2c78ff; }
  .summary dd b { font-weight:bold; }
  .summary > dl > dd > div + div { margin-top:12px; }

  .gpuBox{margin:10px -20px 64px;color:#303030;}
  .gpuBox .inner{display:inline-block;width:25%;padding:20px;box-sizing:border-box;}
  .gpuBox .inner > div{border:1px solid #dfdfdf;}
  .gpuBox .titleBox{padding:15px 20px 10px;border-bottom:1px solid #dfdfdf;}
  .gpuBox .contentBox{padding:10px 20px 15px;}
  .gpuBox .detail{font-size:14px;}
  .gpuBox .dl{display:flex;align-items:center;justify-content:space-between;font-size:14px;}
  .gpuBox .dl + .dl{margin-top:10px}
  .gpuBox .boxTitle,
  .gpuBox .dt,
  .gpuBox b{font-weight:bold;}
  .gpuBox b span{color:#2c78ff;}

  .col2 {text-align:start;}

  table{margin-top:12px;}
`

export default Billing