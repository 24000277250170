const env:any = {};
export default {
  env: env,
  ellipsisTipOptions: {
    effect: 'solid',
    place: 'top',
    multiline: true,
    backgroundColor: '#616161',
    arrowColor: 'transparent',
    className: 'ellipsisTip',
    overridePosition: (pos:any)=>{ return {top:pos.top+20, left:pos.left} }
  },
  ellipsisTipOptions2: {
    effect: 'solid',
    place: 'top',
    backgroundColor: '#616161',
    arrowColor: 'transparent',
    className: 'ellipsisTip2',
    overridePosition: (pos:any)=>{ return {top:pos.top+20, left:pos.left} }
  },
  ellipsisTipOptions3: {
    effect: 'solid',
    place: 'top',
    multiline: true,
    backgroundColor: '#616161',
    arrowColor: 'transparent',
    className: 'ellipsisTip',
    overridePosition: (pos:any)=>{ return {top:pos.top, left:pos.left+470} }
  },
  ellipsisTipOptionsOps: {
    effect: 'solid',
    // effect: 'float',
    place: 'bottom',
    multiline: true,
    backgroundColor: '#FFFFFF',
    textColor: '#333333',
    arrowColor: 'transparent',
    className: 'ellipsisTipOps',
    //border: true,
    //borderColor: '#333333',
    overridePosition: (pos:any)=>{ return {top:pos.top, left:pos.left} }
  },
}

