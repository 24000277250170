import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import lodash from 'lodash'
import { IQueueItem } from '../../model/Monitoring'
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../components/ui/Table'
import { changeModeState, settingState } from '../../states/settingState'
import { ISettings } from '../../states/settingState'
import { useRecoilState } from 'recoil'
import PageEvent from '../../events/PageEvent'
import TableEvent from '../../events/TableEvent'
import ModalEvent, { MODAL_TYPE } from '../../events/ModalEvent'
import SchedulerEvent from '../../events/SchedulerEvent'
import { IWorkspaceListItem, RESOURCE_TYPE, Workspace } from '../../model/Workspace'
import { SORT_ORDER } from '../../model/BaseDataType'
import PopupController from '../../controller/PopupController'
import dayjs from 'dayjs'
import PriorityChange from '../components/table/PriorityChange'
import PriorityUp from '../components/table/PriorityUp'
import { IWorkspacePriorityInsertInfo, Priority } from '../../model/Priority'
import { IResourceGroup, ResourceGroup } from '../../model/ResourceGroup'
import ISelectOption from '../../interfaces/SelectOption'
import Select from '../components/ui/Select'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import UserInfoPopup from '../components/modal/sub/UserInfoPopup'
import ToastEvent from '../../events/ToastEvent'
import { useSearchParams } from 'react-router-dom'

interface IQueueProps {
}

interface IQueueState {
  page:number
  totalArticle:number
  priorityItems:IQueueItem[]
  pendingItems:IQueueItem[]
  selectedPriority:IQueueItem[]
  selectedPending:IQueueItem[]
}

interface IResourceGroupState {
  //리소스 그룹
  resourceGroupList:IResourceGroup[]
  resourceGroupSelectList:ISelectOption[]
  resourceGroup?:ISelectOption //선택된 리소스 그룹
}

const Queue = (props:IQueueProps) => {
  const [ settingInfo ] = useRecoilState<ISettings|null>(settingState)
  const [ searchParams ] = useSearchParams()
  const popupController = PopupController.getInstance()

  const selectRgroupRef = useRef<any>()
  const priorityTableRef = useRef<any>()
  const pendingTableRef = useRef<any>()

  const tableColWidth = [52, 204, 346, 140, 450, 128]

  const headerLabel1:ITableHeader[] = [
    { label: '운영자', key: 'tableUserInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '신청 시간', key: 'tableCreationTimestamp', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '리소스 타입 / 개수', key: 'tableResourceType', type: TABLE_CELL_TYPE.HTML, sort: false },
    { label: '순서 변경', key: 'tableObjInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false }
  ]
  const headerLabel2:ITableHeader[] = [
    { label: '운영자', key: 'tableUserInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '신청 시간', key: 'tableCreationTimestamp', type: TABLE_CELL_TYPE.STRING, sort: false },
    { label: '리소스 타입 / 개수', key: 'tableResourceType', type: TABLE_CELL_TYPE.HTML, sort: false },
    { label: '우선순위 올리기', key: 'tableObjInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false }
  ]

  const [ rgroupList, _setRgroupList ] = useState<IResourceGroupState>({
    resourceGroupList: [],
    resourceGroupSelectList: [],
  })
  const rgroupListRef = useRef(rgroupList)
  const setRgroupList = (data: any) => {
    rgroupListRef.current = data
    _setRgroupList(data)
  }

  const [ changeModeFlag, _setChangeModeFlag ] = useRecoilState<boolean>(changeModeState)
  const changeModeFlagRef = useRef(changeModeFlag)
  const setChangeModeFlag = (data: any) => {
    changeModeFlagRef.current = data
    _setChangeModeFlag(data)
  }

  const [ tempArray, _setTempArray ] = useState<any>({ //임시로 순서 보관하는 용도
    priorityItemsTempArray : [],
    pendingItemsTempArray : []
  })
  const tempArrayRef = useRef(tempArray)
  const setTempArray = (data: any) => {
    tempArrayRef.current = data
    _setTempArray(data)
  }

  const [ state, _setState ] = useState<IQueueState>({
    totalArticle: 0,
    page: 1,
    priorityItems: [],
    pendingItems: [],
    selectedPriority: [],
    selectedPending: []
  })
  const stateRef = useRef(state)
  const setState = (data: any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    setChangeModeFlag(false)
    getRGroupList()

    // window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    window.addEventListener(TableEvent.CHANGE_ORDER, changePriorityHandler)
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    window.addEventListener(TableEvent.ACTION, tableActionHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return() => {
      setChangeModeFlag(false)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      // window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
      window.removeEventListener(TableEvent.CHANGE_ORDER, changePriorityHandler)
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      window.removeEventListener(TableEvent.ACTION, tableActionHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    let e:TableEvent = new TableEvent(TableEvent.PRIORITY_MODE_FLAG_CHANGED)
    e.payload = {
      modeFlag: changeModeFlag
    }
    window.dispatchEvent(e)
    // 갱신 & 초기화
    pendingTableRef.current?.setSelected([])
    priorityTableRef.current?.setSelected([])
  }, [changeModeFlag])

  useEffect(() => {
    if (changeModeFlagRef.current === true) {
      modeChangeHandler()
    }
  }, [settingInfo?.schedulerFlag])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    if(rgroupListRef.current.resourceGroup !== undefined && rgroupListRef.current.resourceGroup.label !== '') {
      getData(rgroupListRef.current.resourceGroup?.label)
    }
  }

  /* const autoRefreshHandler = async () => {
    // await utils.setLoadingMask(false)
    // if (this.state.changeModeFlag === false && this.pendingTableRef.current?.state.selected.length === 0) {
    //   await this.getData()
    // }
    // await utils.setLoadingMask(true)
  } */

  const getRGroupList = async () => {
    try {
      const rgroup:string = searchParams.get('rgroup') as string
      const result = await ResourceGroup.getGroupList(SORT_ORDER.ASC)
      let resourceGroupSelectList:ISelectOption[] = []
      let resourceGroupSelected:ISelectOption = {label:'', value:''}
  
      if (result) {
        for (let idx in result.rgroup) {
          if (result.rgroup[idx].isCommon) {
            resourceGroupSelectList.push({ fakeLabel: '기본 리소스 그룹', label: result.rgroup[idx].name, value: idx })
            if(rgroup === null) {
              resourceGroupSelected = ({ fakeLabel: '기본 리소스 그룹', label: result.rgroup[idx].name, value: idx })
            }
          } else {
            resourceGroupSelectList.push({ label: result.rgroup[idx].name, value: idx })
            if(rgroup === result.rgroup[idx].name) {
              resourceGroupSelected = { label: result.rgroup[idx].name, value: idx }
            }
          }
        }
      }
      setRgroupList({
        ...rgroupList,
        resourceGroupList: result.rgroup,
        resourceGroupSelectList: resourceGroupSelectList,
        resourceGroup: resourceGroupSelected
      })  
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c62f98')
    }
  }

  const onChangeSelectRgroup = (rgroup:ISelectOption) => {
    setRgroupList({
      ...rgroupList,
      resourceGroup: rgroup
    })
    if(rgroup.label !== '') {
      getData(rgroup.label)
    }
  }

  const getData = async (rgroup?:string) => {
    try {
      const rgroupName = rgroup ? rgroup : rgroupListRef.current.resourceGroup?.label || ''
      const data = await Priority.getQueueList(rgroupName)
      if (data) {
          for (let key in data.priorityQueue) {
            let eachItem:any = data.priorityQueue[key] //IQueueItem
            eachItem.tableUserInfo = [
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachItem.user.name ? eachItem.user.name : '(이름 없음)'}</EllipsisToolTip></div>)],
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachItem.user.userId ? eachItem.user.userId : '(ID 없음)'}</EllipsisToolTip></div>)],
              [eachItem.user.name && eachItem.user.userId ?
                (<div style={{ width: '125px', display: 'flex', justifyContent: 'start', marginTop: '6px' }}>
                  <UserInfoPopup data={eachItem.user} />
                </div>) : false],
            ]
            eachItem.tableResourceType = eachItem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
            `${eachItem.resourceBlock.type}: <b>${eachItem.resourceBlock.gpuNum}개</b>` :
            `${eachItem.resourceBlock.type}, CPU: <b>${eachItem.resourceBlock.cpu}코어</b>, MEM: <b>${eachItem.resourceBlock.mem}${eachItem.resourceBlock.memUnit}</b>`
            eachItem.tableCreationTimestamp = typeof(eachItem.createdAt) === 'number' ? dayjs(eachItem.createdAt*1000).format('YYYY/MM/DD\nHH:mm') : eachItem.createdAt
            eachItem.tableObjInfo = [
              [(<PriorityChange first={Boolean(Number(key) === 0)} end={Boolean(Number(key) === state.priorityItems.length-1 || Number(key) === data.priorityQueue.length-1)} objName={eachItem.name} />)]
            ]
            eachItem.priority = Number(key)+1
          }
          for (let key in data.pendingQueue) {
            let eachItem:any = data.pendingQueue[key] //IQueueItem
            eachItem.tableUserInfo = [
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachItem.user.name ? eachItem.user.name : '(이름 없음)'}</EllipsisToolTip></div>)],
              [(<div style={{ width: '125px', display: 'flex', justifyContent: 'start' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachItem.user.userId ? eachItem.user.userId : '(ID 없음)'}</EllipsisToolTip></div>)],
              [eachItem.user.name && eachItem.user.userId ?
                (<div style={{ width: '125px', display: 'flex', justifyContent: 'start', marginTop: '6px' }}>
                  <UserInfoPopup data={eachItem.user} />
                </div>) : false],
            ]
            eachItem.tableResourceType = eachItem.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
            `${eachItem.resourceBlock.type}: <b>${eachItem.resourceBlock.gpuNum}개</b>` :
            `${eachItem.resourceBlock.type}, CPU: <b>${eachItem.resourceBlock.cpu}코어</b>, MEM: <b>${eachItem.resourceBlock.mem}${eachItem.resourceBlock.memUnit}</b>`
            eachItem.tableCreationTimestamp = typeof(eachItem.createdAt) === 'number' ? dayjs(eachItem.createdAt*1000).format('YYYY/MM/DD\nHH:mm') : eachItem.createdAt
            eachItem.tableObjInfo = [
              [(<PriorityUp objName={eachItem.name} />)]
            ]
            eachItem.priority = null
          }
          
        setState({
          ...state,
          priorityItems: data.priorityQueue,
          pendingItems: data.pendingQueue
        })
      } else {
        setState({
          ...state,
          priorityItems: [],
          pendingItems: []
        })
      }
    } catch(err) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6ea69b')
    }
  }

  /* const parseData = (data:IQueueItem[]) => {
    let i:number, item:IQueueItem
    for (i = 0; i < data.length; i++) {
      item = data[i]
      item.userNode = `<b style="color: #2c78ff;font-weight:bold;">${item.userId}</b><br>${item.userName} / ${item.department || '-'}`
      item.resourceType = item.gpuType
      if (item.numGpu) {
        item.numResource = `GPU: ${item.numGpu}개`
      } else {
        if (item.gpuType) {
          let infoArray = item.gpuType.toUpperCase().split(',')
          item.numResource = infoArray[1].replace(':', ': ').replace('.0', '')+'개\n'+infoArray[2].replace(':', ': ')  
        }
      }
    }
    return data
  } */

  const modeChangeHandler = ():void => {
    if (changeModeFlagRef.current === false) {
      setTempArray({
        priorityItemsTempArray: lodash.cloneDeep(stateRef.current.priorityItems),
        pendingItemsTempArray: lodash.cloneDeep(stateRef.current.pendingItems)
      })
      setChangeModeFlag(true)
      window.dispatchEvent(new SchedulerEvent(SchedulerEvent.EDIT_START))
    } else {
      setChangeModeFlag(false)
      setState({
        ...state,
        priorityItems: tempArrayRef.current.priorityItemsTempArray,
        pendingItems: tempArrayRef.current.pendingItemsTempArray
      })
      window.dispatchEvent(new SchedulerEvent(SchedulerEvent.EDIT_END))
    }
  }

  const changePriorityHandler = (e:TableEvent):void => {
    if (changeModeFlagRef.current === true) {
      const idx = stateRef.current.priorityItems.findIndex(element => element.name === e.payload.objName)
      let target:IQueueItem = stateRef.current.priorityItems[idx]
      let a1:IQueueItem[] = stateRef.current.priorityItems.slice(0, idx)
      let a2:IQueueItem[] = stateRef.current.priorityItems.slice(idx+1)
      let a3:IQueueItem[] = a1.concat(a2)
      if (e.payload.direction === -1) { //위로 이동
        a3.splice(Math.max(0, idx-1), 0, target)
      } else if (e.payload.direction === 1) { //아래로 이동
        a3.splice(Math.min(a3.length, idx+1), 0, target)
      }
      a3 = setPriorityChangeButton(a3)
      setState({
        ...state,
        priorityItems:a3,
        pendingItems:stateRef.current.pendingItems
      })
    } else {
      // DOES NOTHING
    }
  }

  const setPriorityChangeButton = (priorityTableItems:IQueueItem[]):IQueueItem[] => {
    for(let idx in priorityTableItems) {
      let eachItem:any = priorityTableItems[idx]
      eachItem.tableObjInfo = [
        [(<PriorityChange first={Boolean(Number(idx) === 0)} end={Boolean(Number(idx) === priorityTableItems.length-1)} objName={eachItem.name} />)]
      ]
    }
    return priorityTableItems
  }

  const setPriorityUpButton = (pendingTableItems:IQueueItem[]):IQueueItem[] => {
    for(let idx in pendingTableItems) {
      let eachItem:any = pendingTableItems[idx]
      eachItem.tableObjInfo = [
        [(<PriorityUp objName={eachItem.name} />)]
      ]
    }
    return pendingTableItems
  }

  const startButtonHandler = ():void => {
    popupController.dialouge(`우선순위 편집모드로 전환합니다. 진행하시겠습니까?`, 'startPriorityEdit', 'startPriorityEdit', '확인', '취소')
  }

  const cancelButtonHandler = ():void => {
    popupController.dialouge(`우선순위 편집 내용을 취소합니다. 진행하시겠습니까?`, 'cancelPriorityEdit', 'cancelPriorityEdit', '확인', '취소')
  }

  const savePriorityHandler = ():void => {
    let changedItemArray:string[] = []
    for (let key in state.priorityItems) {
      let eachItem:IQueueItem = state.priorityItems[key]
      if (eachItem.priority !== Number(key)+1) {
        changedItemArray.push(eachItem.name)
      }
    }
    for (let key in state.pendingItems) {
      let eachItem:IQueueItem = state.pendingItems[key]
      if (eachItem.priority !== null) {
        changedItemArray.push(eachItem.name)
      }
    }

    if (changedItemArray.length > 0) {
      if(changedItemArray.length === 1) {
        popupController.dialouge(`${changedItemArray[0]} 의 우선 순위 변경을 적용합니다. 진행하시겠습니까?
        우선순위 편집완료 후 스케줄러를 재개해야 워크스페이스가 생성됩니다.`,
        'savePriorityEdit', 'savePriorityEdit', '확인', '취소') 
      }
      else {
        popupController.dialouge(`${changedItemArray[0]} 외 ${changedItemArray.length-1}개의 우선 순위 변경을 적용합니다. 진행하시겠습니까?
        우선순위 편집완료 후 스케줄러를 재개해야 워크스페이스가 생성됩니다.`,
        'savePriorityEdit', 'savePriorityEdit', '확인', '취소') 
      }
    } else {
      modeChangeHandler()
    }
  }

  const tableActionHandler = async (e:TableEvent) => {
    let priorityItems:IQueueItem[] = stateRef.current.priorityItems
    let pendingItems:IQueueItem[] = stateRef.current.pendingItems
    let target:any
    let idx
    
    switch (e.payload.action) {
      case 'priorityOff':
        idx = priorityItems.findIndex(element => element.name === e.payload.objName)
        target = priorityItems[idx]
        priorityItems.splice(idx, 1)
        pendingItems.push(target)
        break
      case 'priorityUp':
        idx = pendingItems.findIndex(element => element.name === e.payload.objName)
        target = pendingItems[idx]
        pendingItems.splice(idx, 1)
        priorityItems.unshift(target)
        break
    }

    priorityItems = setPriorityChangeButton(priorityItems)
    pendingItems = setPriorityUpButton(pendingItems)
 
    setState({
      ...state,
      priorityItems: priorityItems,
      pendingItems: pendingItems
    })
  }

  const removeQueueHandler = async (priorityFlag:boolean=false):Promise<void> => {
    let items:any
    let selectedIdx:any
    let targetItemArray:any[] = []
    let targetIdNameArray:any[] = []
    
    if (priorityFlag) {
      items = stateRef.current.priorityItems //priorityTableRef.current?.state.items
      selectedIdx = priorityTableRef.current?.selected
    } else {
      items = stateRef.current.pendingItems //pendingTableRef.current?.state.items
      selectedIdx = pendingTableRef.current?.selected
    }

    if (items && selectedIdx && selectedIdx.length > 0) {
      for (let eachIdx of selectedIdx) {
        targetItemArray.push(items[Number(eachIdx)])
        targetIdNameArray.push({
          id: items[Number(eachIdx)].id, 
          name: items[Number(eachIdx)].name
        })
      }
      // targetIdNameArray.push({
      //   id:243234234, 
      //   name:'없는 워크스페이스1'
      // }, {
      //   id:243234233, 
      //   name:'없는 워크스페이스2'
      // })
    }
    
    if (targetItemArray.length > 0) {
      let message = ''
      if (priorityFlag) {
        if (targetItemArray.length === 1) {
          message = `${targetItemArray[0].name} 이(가) 우선순위 목록에서 삭제되며 신청이 취소됩니다. 진행하시겠습니까?`
        } else {          
          message = `${targetItemArray[0].name} 외 ${targetItemArray.length-1}개 항목이 우선순위 목록에서 삭제되며 신청이 취소됩니다. 진행하시겠습니까?`
        }  
      }
      else {
        if (targetItemArray.length === 1) {
          message = `${targetItemArray[0].name} 이(가) 대기목록에서 삭제되며 신청이 취소됩니다. 진행하시겠습니까?`
        } else {
          message = `${targetItemArray[0].name} 외 ${targetItemArray.length-1}개 항목이 대기목록에서 삭제되며 신청이 취소됩니다. 진행하시겠습니까?`
        }
      }
      popupController.dialouge(message,
        priorityFlag ? 'deletePriority' : 'deleteQueue', {
          targetIdNameArray: targetIdNameArray,
          priorityFlag: priorityFlag,
          targetItemArray: targetItemArray
        }, '확인', '취소')
    } else {
      popupController.confirm(`선택된 항목이 없습니다.`)
    }
  }

  const openWaitngQueue = async () => {
    popupController.waitingQueue(
      async (selectedResource:any[]) => {
      let message = ''
      if (selectedResource.length === 1) {
        message = `${selectedResource[0].name} 을(를) 대기 목록에 추가합니다. 진행하시겠습니까?`
      } else {
        message = `${selectedResource[0].name} 외 ${selectedResource.length-1}개 항목을 대기 목록에 추가합니다. 진행하시겠습니까?`
      }
      popupController.dialouge(message, 'insertPriority', JSON.stringify(selectedResource), '확인', '취소')
    }, 
    rgroupListRef.current.resourceGroup?.label || 'common')
  }
  
  const modalActionHandler = async (e:ModalEvent) => {
    let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)

    switch (e.payload.action) {
      case 'deleteQueue':
      case 'deletePriority':
        try {
          // 삭제
          const response = await Workspace.removeWorkspace(e.payload.key.targetIdNameArray, false, e.payload.key.priorityFlag)

          // 결과 토스트 메시지 표출
          const targetIdNameArray:any[] = e.payload.key.targetIdNameArray
          const targetItemArray = e.payload.key.targetItemArray
          const priorityFlag = e.payload.key.priorityFlag
          let message = ''
          if (!response) { // 삭제 모두 성공
            if (targetItemArray.length > 0) {
              if (priorityFlag) {
                if (targetItemArray.length === 1) {
                  message = `${targetItemArray[0].name} 이(가) 우선순위 목록에서 삭제 및 신청이 취소되었습니다.`
                } else {
                  message = `${targetItemArray[0].name} 외 ${targetItemArray.length - 1}개 항목이 우선순위 목록에서 삭제 및 신청이 취소되었습니다.`
                }
              }
              else {
                if (targetItemArray.length === 1) {
                  message = `${targetItemArray[0].name} 이(가) 대기목록에서 삭제 및 신청이 취소되었습니다.`
                } else {
                  message = `${targetItemArray[0].name} 외 ${targetItemArray.length - 1}개 항목이 대기목록에서 삭제 및 신청이 취소되었습니다.`
                }
              }
            }
            toastEvent.payload = { message: message }
            window.dispatchEvent(toastEvent)
          } else { // 삭제 일부 실패
            if (priorityFlag) {
              message = `우선 순위 목록에서 ${targetIdNameArray.length}개 삭제 요청 중 ${response.data.failedCount}개 삭제를 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.data.deletedCount}개 / 실패 ${response.data.failedCount}개  `
            } else {
              message = `대기 목록에서 ${targetIdNameArray.length}개 삭제 요청 중 ${response.data.failedCount}개 삭제를 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.data.deletedCount}개 / 실패 ${response.data.failedCount}개  `
            }
            popupController.confirm(message)
          }

          // 갱신 & 초기화
          pendingTableRef.current?.setSelected([])
          priorityTableRef.current?.setSelected([])
          getData()
        } catch (e) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 1f54c0')
        }
        break
      case 'insertPriority':
        try {
          let selectedResource:IWorkspacePriorityInsertInfo[] = JSON.parse(e.payload.key)
          await Priority.insertPriority(rgroupListRef.current.resourceGroup?.label || 'common', selectedResource);
          let message = ''
          if (selectedResource.length === 1) {
            message = `${selectedResource[0].name} 이(가) 대기 목록에 추가되었습니다.`
          } else {
            message = `${selectedResource[0].name} 외 ${selectedResource.length-1}개 항목이 대기 목록에 추가되었습니다.`
          }
          toastEvent.payload = { message: message }
          window.dispatchEvent(toastEvent)
          getData();
        } catch(e) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 50c0d1')
        }
        break
      case 'startPriorityEdit':
        modeChangeHandler()
        break
      case 'cancelPriorityEdit':
        modeChangeHandler()
        break
      case 'savePriorityEdit':
        try {
          let priorityNameArray:string[] = [], pendingNameArray:string[] = []
          for (let key in stateRef.current.priorityItems) {
            let eachItem:IQueueItem = stateRef.current.priorityItems[key]
            priorityNameArray.push(eachItem.name)
          }
          for (let key in stateRef.current.pendingItems) {
            let eachItem:IQueueItem = stateRef.current.pendingItems[key]
            pendingNameArray.push(eachItem.name)
          }
          let queue = {
            priorityQueue: priorityNameArray,
            pendingQueue: pendingNameArray
          }
          await Priority.updateQueueList(rgroupListRef.current.resourceGroup?.label || '', queue)
          setChangeModeFlag(false)
          window.dispatchEvent(new SchedulerEvent(SchedulerEvent.EDIT_END))
          getData()
        } catch(error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 8a2468')
        }
        break
    }
  }

  const selectBoxCheckHandlerPriority = (selectedIdx:number[]):void => {
    if (state.priorityItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:any[] = []

      for (let idx in selectedIdx) {
        selectedArray.push(state.priorityItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedPriority: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedPriority: []
      })
    }
  }
  const selectBoxCheckHandlerPending = (selectedIdx:number[]):void => {
    if (state.pendingItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:any[] = []

      for (let idx in selectedIdx) {
        selectedArray.push(state.pendingItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedPending: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedPending: []
      })
    }
  }

  return (
    <QueueFragment>
      <div className="titleArea bothEnd" style={{marginBottom:'20px'}}>
        <h2 className="pageTitle">
          대기열 우선 순위 관리
          {/* <Tooltip des="스케줄러를 멈춘 뒤 우선순위 편집시작 버튼이 활성화 됩니다. 우선순위 편집시작 버튼을 클릭하면 생성 대기중인 워크스페이스의 대기열을 관리 할 수 있습니다." /> */}
        </h2>
        <div className="btnWrap">
          {
            changeModeFlag === true ?
              <>
                <button className="btn orangeOutline" onClick={cancelButtonHandler}>우선순위 편집취소</button>
                <button className="btn orange" onClick={savePriorityHandler}>우선순위 편집완료</button>
              </> :
              <button className={"btn " + (settingInfo?.schedulerFlag === false ? "blue" : "off")} onClick={startButtonHandler}>우선순위 편집시작</button>
          }
        </div>
        <p><img src='/images/star.png' alt='important' className="importantImg" /> 좌측 상단의 스케줄러 멈춤 버튼을 클릭하여 스케줄러 멈춤 상태가 되어야 대기열 우선순위 편집이 가능합니다.</p>
      </div>
        
      <div style={{width:'499px', margin:'30px 0'}}>
        <Select selectRef={selectRgroupRef} option={rgroupList.resourceGroupSelectList} /* placeholder="리소스 그룹을 선택해 주세요." */
          selected={rgroupList.resourceGroup}
          onChange={onChangeSelectRgroup}
          disabled={changeModeFlag}
        />
      </div>

      <div className="titleArea2 bothEnd" style={{ marginTop: '20px' }}>
        <h3>우선 순위 목록{/* <Tooltip des="생성 대기중인 워크스페이스 또는 Job 중 가장 우선적으로 생성될 워크스페이스/Job 목록입니다.<br>우선순위 편집 시작 버튼을 클릭하여, 우선순위 편집모드에 진입 후 대기 순서를 변경할 수 있습니다.<br>순서 변경 아이콘을 클릭하여 우선순위 변경 후 우선 순위 편집 완료 버튼을 클릭하면 변경된 우선순위가 적용됩니다. 우선순위 편집완료 후 스케줄러를 재개해야 워크스페이스/Job이 생성됩니다." /> */}</h3>
        <div className="btnWrap">
          <button className={"btn outline " + (priorityTableRef.current?.selected.length === 0 || changeModeFlag === true? 'off' : '')} onClick={() => { removeQueueHandler(true) }}>선택 항목 삭제</button>
        </div>
      </div>
      <div className='priorityItems'>
        <Table ref={priorityTableRef} name="priorityItems"
          colWidth={tableColWidth}
          headerLabel={headerLabel1}
          noData="우선 순위에 대기중인 워크스페이스가 없습니다."
          items={state.priorityItems}
          checkBoxType={changeModeFlag ? CHECKBOX_TYPE.DISABLE : CHECKBOX_TYPE.USE_ALL}
          // changeModeFlag={changeModeFlag}
          onSelect={selectBoxCheckHandlerPriority}
          />
      </div>

      <div className="titleArea2 bothEnd">
        <h3>대기 목록{/* <Tooltip des="생성 대기중인 워크스페이스 또는 Job 목록입니다. <br>우선순위 올리기 버튼을 클릭하면 해당 워크스페이스는 우선순위 목록에 추가되며, 다른 대기항목 보다 우선적으로 워크스페이스가 생성됩니다.<br>우선순위 올리기를 한 항목은 우선순위 목록의 최상단에 위치하게됩니다." /> */}</h3>
        <div className="btnWrap">
          <button className={"btn outline " + (pendingTableRef.current?.selected.length === 0 || changeModeFlag === true? 'off' : '')} onClick={() => { removeQueueHandler(false) }}>선택 항목 삭제</button>
          <button className={"btn outline "+(changeModeFlag === true ? 'off' : '')} onClick={openWaitngQueue}>승인 대기 목록</button>
        </div>
      </div>
      <Table ref={pendingTableRef} name="pendingItems"
        colWidth={tableColWidth}
        headerLabel={headerLabel2}
        items={state.pendingItems}
        noData="생성 대기중인 워크스페이스가 없습니다."
        checkBoxType={changeModeFlag ? CHECKBOX_TYPE.DISABLE : CHECKBOX_TYPE.USE_ALL}
        onSelect={selectBoxCheckHandlerPending}
        />
    </QueueFragment>
  )
}

const QueueFragment = styled.div`
.titleArea p {margin-top:5px;}
.titleArea2{margin:40px 0 24px;}
.titleArea2 h3{font-size: 18px;font-weight:bold;}
.titleArea2 h3 > div{margin-left:10px;}
.titleArea3{margin:12px 0 24px;}
table td.col2{word-break:keep-all;}
table td.col2,
table td.col3,
table td.col4{text-align:center;}
table td.col5{padding:0 16px;}
.priorityItems table .selected td {background-color:rgba(247, 125, 0, 0.1) !important;}
.priorityItems table input:checked + label .box {background:#F77D00;border-color:#F77D00}
.importantImg {width:12px; height:12px; margin-right:1px;}
`

export default Queue