import { useState, useRef, useEffect } from "react"
import styled from 'styled-components'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../uiv2/Button"
import { IEnvironment, IVolume, Service } from "../../../model/Service"
import PopupController from "../../../controller/PopupController"
import SubPopupList from "./sub/SubPopupList"
import dayjs from "dayjs"
import SubPopupTable from "./sub/SubPopupTable"
import { IResourceBlock } from "../../../model/Workspace"
import { SUBPOPUP_TYPE } from "../modal/sub/SubPopup"

interface IVersionDetailPopupProps {
  onClose:Function
  name:string
  namespace:string
}

interface IVersionDetailPopupState {
  image:string
  imageDigest:string
  createdAt:number
  command:string[]
  env:IEnvironment[]
  args:string[]
  volumes:IVolume[]
  resourceBlock:IResourceBlock
  replicas:number
  readyReplicas:number
  tag:string
}

const VersionDetailPopup = (props:IVersionDetailPopupProps) => {
  const popupController = PopupController.getInstance()

  const [ state, setState ] = useState<IVersionDetailPopupState>({
    image: '',
    imageDigest: '',
    createdAt: 0,
    command: [],
    env: [],
    args: [],
    volumes: [],
    resourceBlock: {
      cpu: 0,
      gpuMem: null,
      gpuMemUnit: null,
      gpuNum: null,
      gpuPer: null,
      isPartedGpu: false,
      mem: 0,
      memUnit: '',
      type: '',
      realType: null
    },
    replicas: 0,
    readyReplicas: 0,
    tag: ''
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const response = await Service.getVersionDetail(props.name, props.namespace)
      if (response) {
        setState(response)
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 92a885')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <VersionDetailPopupFragment>
      <div className="imageInfoRect">
        <div>이미지 이름: {state.image}:{state.tag}</div>
        <div>이미지 ID: {state.imageDigest}</div>
      </div>
      <div className="infoWrap">
        <div style={{ marginBottom: '32px' }}>{/* 버전 생성 일시 */}
          <div style={{ height: '24px', padding: '2.5px 0', fontSize: '16px', lineHeight: '19.2px', fontWeight: '600', marginBottom: '16px' }}>버전 생성 일시</div>
          <div style={{ fontSize: '32px', lineHeight: '38.4px', fontWeight: '700' }}>{dayjs(state.createdAt*1000).format('YYYY/MM/DD HH:mm:ss')}</div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>{/* 서비스 정보 */}
            <div className='bothBox'>
              <div className='serviceInfoBox'>
                <div className='infoTitle'>이미지 이름</div>
                <div className='infoDetail'>{state.image}:{state.tag}</div>                
              </div>
              <div className='serviceInfoBox'>
                <div className='infoTitle' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}> Command {state.command.length > 1 ? <SubPopupList title={'Command'} datas={state.command} /> : false}</div>
                <div className='infoDetail'>{state.command.length > 0 ? state.command[0] : '-'}</div>  
              </div>
            </div>
            <div className='bothBox'>
              <div className='serviceInfoBox'>
                <div className='infoTitle' style={{ display: 'flex', alignItems: 'center', gap: '8px' }} >Environment {state.env.length > 1 ? <SubPopupList title={'Environment'} datas={state.env} type={SUBPOPUP_TYPE.ENVIRONMENT} /> : false}</div>
                <div className='infoDetail'>{state.env.length > 0 ? `${state.env[0].key}=${state.env[0].value}` : '-'}</div>                
              </div>
              <div className='serviceInfoBox'>
                <div className='infoTitle' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>Argument {state.env.length > 1 ? <SubPopupList title={'Argument'} datas={state.args} /> : false}</div>
                <div className='infoDetail'>{state.args.length > 0 ? state.args[0] : '-'}</div>  
              </div>
            </div>
            <div className='bothBox'>
              <div style={{ width: '320px', display: 'flex', gap: '12px'}}>
                <div className='infoTitle'>데이터 볼륨</div>
                <div className='infoDetail'>{state.volumes.length > 0 ? state.volumes[0].pvc : '-'}</div>
              </div>
              <div style={{ width: '320px', display: 'flex', gap: '12px', justifyContent: 'start' }}>
                <div className='infoTitle'>마운트 경로</div>
                <div className='infoDetail' style={{ width: '220px' }}>{state.volumes.length > 0 ? state.volumes[0].mountPath : '-'}</div>
                {state.volumes.length > 1 ? <SubPopupTable title={'데이터 볼륨 및 마운트 경로'} tableHeaders={['데이터 볼륨', '마운트 경로']} datas={state.volumes} /> : <div></div>}
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div>{/* 리소스 정보 */}
            <div style={{ display: 'flex', gap: '12px', marginBottom: '24px' }}>
              <div className='infoTitle'>리소스 타입</div>
              <div style={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: '700', alignContent: 'center' }}>{state.resourceBlock.type === 'cpu' ? 'CPU resource' : state.resourceBlock.type}</div>
            </div>
            <div className='bothBox'>
              <div style={{ width: '320px', height: '82px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>CPU</div>
                  <div className='infoDetail'>{state.resourceBlock.cpu} 개</div>
                </div>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>MEM</div>
                  <div className='infoDetail'>{state.resourceBlock.mem} {state.resourceBlock.memUnit}</div>
                </div>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>GPU</div>
                  <div className='infoDetail'>{state.resourceBlock.gpuPer !== null ? `${state.resourceBlock.gpuPer} %` : '-'}</div>
                </div>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>GPU MEM</div>
                  <div className='infoDetail'>{state.resourceBlock.gpuMem !== null ? state.resourceBlock.gpuMem : '-'} {state.resourceBlock.gpuMemUnit !== null ? state.resourceBlock.gpuMemUnit : false}</div>
                </div>
              </div>
              <div style={{ width: '320px', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>GPU 리소스 블록 개수</div>
                  <div className='infoDetail'>{state.resourceBlock.gpuNum !== null ? `${state.resourceBlock.gpuNum} 개` : '-'}</div>
                </div>
                <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>레플리카 개수</div>
                  <div className='infoDetail'>{state.readyReplicas} 개 / {state.replicas} 개</div>
                </div>
                {/* <div style={{ display: 'flex', gap: '12px' }}>
                  <div className='infoTitle'>오토스케일</div>
                  <div><img src="/images-v2/table-more-small.svg" alt="more" /></div>
                  <div style={{display:'flex', gap:'6px'}}>
                    <img src="/images-v2/table-autoscale-small.svg" alt="autoscale" />
                    <div style={{ color: '#4B82FF', fontWeight: '600', borderBottom:'1px solid #4B82FF' }}>켜짐</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btnWrap">
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={closeHandler}>확인</Button>
      </div>
    </VersionDetailPopupFragment>
  )
}

const VersionDetailPopupFragment = styled.div`
  display:flex; flex-direction:column; width:800px; height:calc(100vh - 186px); max-height:661px; padding:24px 32px; box-sizing:border-box; font-size:13px; line-height:15.6px;
  .imageInfoRect{font-size:18px; line-height:21.6px; font-weight:700; margin-bottom:24px;}
  .imageInfoRect div{height:22px; margin:5px 0;}
  .infoWrap{width:672px; height:461px; border-radius:6px; border:1px solid #878791; padding:20px 32px;}
	.btnWrap{flex:none; display:flex; justify-content:end; gap:12px; margin-top:24px;}
  .divider{height:1px; margin:24px 0 !important; background: #E8E8EE;}
  .bothBox{display:flex; gap:32px; align-items:'start'}
  .serviceInfoBox{width:320px;height:38px;display:flex;flex-direction:column;justify-content:space-between;}
  img{display:block;}
  .infoTitle{font-weight:500;}
  .infoDetail{font-weight:400px; color:#878791;}
`

export default VersionDetailPopup