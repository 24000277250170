import { Component, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { NavigateFunction, Params } from 'react-router-dom'
import { IPortMap } from '../../../model/Workspace'
import { IUserAuthInfo } from '../../../states/authStates'
import PopupController from '../../../controller/PopupController'
import ModalEvent from '../../../events/ModalEvent'
import Toggle from '../ui/Toggle'
import { Ports } from '../../../model/Ports'
import ToastEvent from '../../../events/ToastEvent'
import InputBox, { TEXT_INPUT_TYPE } from '../ui/InputBox'

interface IPortEditPopupProps {
  navigate?:NavigateFunction
  params?:Params
  onClose:Function
  onSelected:Function
  id:number
  name:string
  namespace:string
  ports:IPortMap[]
}

interface IPortEditPopupState {
  userInfo?:IUserAuthInfo
  portMap:IPortMap[]
  portError:IPortMap[]
}

const PortEditPopup = (props:IPortEditPopupProps) => {
  const autoFlagRef = useRef<any>()
  const submitRef = useRef<any>()

  const popupController = PopupController.getInstance()
  const [ state, _setState ] = useState<IPortEditPopupState>({
    portMap: [],
    portError: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getData = async () => {
    try {
      if (props.namespace && props.name) {
        const response = await Ports.getPorts(props.id)
        if (response) {
          if (response.ports && response.ports.length > 0) {
            const data: IPortMap[] = []
            const portError: IPortMap[] = []
            for (let i = 0; i < response.ports.length; i++) {
              data.push({
                autoFlag: Number(response.ports[i].external) === 0,
                internal: response.ports[i].internal,
                external: response.ports[i].external
              })
              portError.push({
                autoFlag: false,
                internal: '',
                external: ''
              })
            }
            setState({
              portMap: data,
              portError: portError
            })
          }
          else {
            addPortHandler()
          }
        }
      } else {
        addPortHandler()
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 4e5684')
    }
  }

  const inputHandler = (target:string, value:any, idx:number = 0) => {
    const newPortMap = state.portMap.concat([])
    const newPortError = state.portError.concat([])
    let error: boolean = false
    let ports = state.portMap
    const portMap = state.portMap.concat([])

    switch (target) {
      case 'internal':
        portMap[idx].internal = value
        for (let jdx in portMap) {
          let internalCheck:any = portMap.filter(e => Number(e.internal) === Number(portMap[jdx].internal) && e.internal !== '')
          if (idx !== Number(jdx) && newPortError[jdx].internal !== '' && newPortError[jdx].internal !== '내부포트 필드 내 중복 입력된 값이 있습니다.') {
          } else {
            if (internalCheck.length > 1) {
              newPortError[jdx].internal = '내부포트 필드 내 중복 입력된 값이 있습니다.'
            } else if (Number(portMap[jdx].internal) === 22 || (Number(portMap[jdx].internal) === 8888)) {
              newPortError[jdx].internal = '사용 불가한 내부 포트입니다.'
            } else {
              newPortError[jdx].internal = ''
            }
          }
        }
        if (isNaN(Number(value)) === true || value > 65535 || value < 1) {
          newPortError[idx].internal = '65535 이하 숫자로 입력해 주세요.'
        }
        if (!value) {
          newPortError[idx].internal = ''
        }
        newPortMap[idx].internal = String(value)
        setState({
          portMap: newPortMap,
          portError: newPortError
        })
        break
      case 'external':
        portMap[idx].external = value
        for (let jdx in portMap) {
          let externalCheck:any = portMap.filter(e => Number(e.external) === Number(portMap[jdx].external) && e.external !== '')
          if (idx !== Number(jdx) && newPortError[jdx].external !== '' && newPortError[jdx].external !== '외부포트 필드 내 중복 입력된 값이 있습니다.') {
          } else {
            if (externalCheck.length > 1) {
              newPortError[jdx].external = '외부포트 필드 내 중복 입력된 값이 있습니다.'
            } else {
              newPortError[jdx].external = ''
            } 
          }
        }
        if (isNaN(Number(value)) === true || value > 32767 || value < 30000) {
          newPortError[idx].external = '30000~32767 사이의 숫자로 입력해 주세요.'
        }
        if (!value) {
          newPortError[idx].external = ''
        }
        newPortMap[idx].external = String(value)
        setState({
          portMap: newPortMap,
          portError: newPortError
        })
        break
      case 'autoFlag':
        newPortMap[idx].external = ''
        newPortError[idx].external = ''
        newPortMap[idx].autoFlag = value
        if (value === true) {
        } else {
          const portMap = state.portMap.concat([])
          portMap[idx].external = ''
          for (let jdx in portMap) {
            let externalCheck:any = portMap.filter(e => Number(e.external) === Number(portMap[jdx].external) && e.external !== '' && e.internal !== '')
            if (idx !== Number(jdx) && newPortError[jdx].external !== '' && newPortError[jdx].external !== '외부포트 필드 내 중복 입력된 값이 있습니다.') {
            } else {
              if (externalCheck.length > 1) {
                newPortError[jdx].external = '외부포트 필드 내 중복 입력된 값이 있습니다.'
              } else {
                newPortError[jdx].external = ''
              } 
            }
          }
        }
        setState({
          portMap: newPortMap,
          portError: newPortError
        })
        break
    }
  }

  const addPortHandler = () => {
    setState({
      portMap: state.portMap.concat({
        autoFlag: false,
        internal: '',
        external: ''
      }),
      portError: state.portError.concat({
        autoFlag: false,
        internal: '',
        external: ''
      })
    })
  }

  const removePortHandler = (idx: number) => {
    if (idx === 0 && state.portMap.length === 1) {
      // DOES NOTHING!!
    } else {
      const newPortMap = state.portMap.concat([])
      const newPortError = state.portError.concat([])
      newPortMap.splice(idx, 1)
      newPortError.splice(idx, 1)

      for (let jdx in newPortMap) {
        let internalCheck:any = newPortMap.filter(e => Number(e.internal) === Number(newPortMap[jdx].internal) && e.internal !=='' )
        if(idx !== Number(jdx) && newPortError[jdx].internal !== '' && newPortError[jdx].internal !== '내부포트 필드 내 중복 입력된 값이 있습니다.') {
        } else {
          if (internalCheck.length > 1) {
            newPortError[jdx].internal = '내부포트 필드 내 중복 입력된 값이 있습니다.'
          } else {
            newPortError[jdx].internal = ''
          }  
        }
      }

      for (let jdx in newPortMap) {
        let externalCheck:any = newPortMap.filter(e => Number(e.external) === Number(newPortMap[jdx].external) && e.internal !== '')
        if (idx !== Number(jdx) && newPortError[jdx].external !== '' && newPortError[jdx].external !== '외부포트 필드 내 중복 입력된 값이 있습니다.') {
        } else {
          if (externalCheck.length > 1) {
            newPortError[jdx].external = '외부포트 필드 내 중복 입력된 값이 있습니다.'
          } else {
            newPortError[jdx].external = ''
          } 
        }
      }

      setState({
        portMap: newPortMap,
        portError: newPortError
      })
    }
  }

  const cancelHandler = () => {
    closeHandler()
  }

  const closeHandler = () => {
    if (props.onClose) {
      let e: ModalEvent = new ModalEvent(ModalEvent.ACTION_MODAL)
      e.payload = {
        key: 'closePortPopup',
        action: 'closePortPopup'
      }
      popupController.dispatchEvent(e)
      props.onClose()
    }
  }

  const submitHandler = async () => {
    const editFlag: boolean = props.ports && props.ports.length > 0
    // const oldPortMap = props.ports.concat([])
    const newPortError = state.portError.concat([])
    let blankErrorFlag: boolean = false
    let validationErrorFlag: boolean = false
    let popupEvent: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)

    //let ports =  JSON.parse(JSON.stringify(state.portMap))
    let ports = state.portMap.concat([])

    for (let idx in ports) {
      let eachPort:IPortMap = ports[idx]
      newPortError[idx].internal = ''
      newPortError[idx].external = ''
      if (eachPort.internal === '' && eachPort.external !== '') {
        //console.log('내부포트 입력 안해줌!', idx)
        newPortError[idx].internal = '내부 포트를 함께 입력해 주세요.'
        blankErrorFlag = true
      }
      if (isNaN(Number(eachPort.internal)) === true || (eachPort.internal !== '' && (Number(eachPort.internal) > 65535 || Number(eachPort.internal) < 1))) {
        //console.log('내부포트 입력 범위 밖!', idx)
        newPortError[idx].internal = '65535 이하 숫자로 입력해 주세요.'
        validationErrorFlag = true
      }
      if (eachPort.external === '' && eachPort.autoFlag === false && eachPort.internal !== '') {
        //console.log('외부포트 입력 안해줌!', idx)
        newPortError[idx].external = '외부 포트를 함께 입력해 주세요.'
        blankErrorFlag = true
      }
      if (eachPort.autoFlag === false && (isNaN(Number(eachPort.external)) === true || (eachPort.external !== '' && (Number(eachPort.external) > 32767 || Number(eachPort.external) < 30000)))) {
        //console.log('외부포트 입력 범위 밖!', idx)
        newPortError[idx].external = '30000~32767 사이의 숫자로 입력해 주세요.'
        validationErrorFlag = true
      }

      if (eachPort.internal !== '') {
        //내부 서비스에서 내부 포트 중복 체크
        let internalCheck: any = ports.filter(e => Number(e.internal) === Number(eachPort.internal))
        if (internalCheck.length > 1) {
          newPortError[idx].internal = '내부포트 필드 내 중복 입력된 값이 있습니다.'
          validationErrorFlag = true
        } else if(Number(eachPort.internal) === 22 || (Number(eachPort.internal) === 8888)) {
          newPortError[idx].internal = '사용 불가한 내부 포트입니다.'
          validationErrorFlag = true
        }
      }
      if (eachPort.autoFlag === false && eachPort.external !== '') {
        //내부 서비스에서 외부 포트 중복 체크
        let externalCheck: any = ports.filter(e => Number(e.external) === Number(eachPort.external))
        if (externalCheck.length > 1) {
          newPortError[idx].external = '외부포트 필드 내 중복 입력된 값이 있습니다.'
          validationErrorFlag = true
        }
      }
      if (eachPort.autoFlag === false && eachPort.internal !== '' && eachPort.external !== '') {
        //외부 서비스에서 외부 포트 중복 체크
        try {
          const data = await Ports.checkDuplicatePort(Number(eachPort.external),props.id)
          if (data.result === true) {
            newPortError[idx].external = '다른 워크스페이스 또는 Job에서 사용 중인 외부포트입니다.'
            validationErrorFlag = true
          }
        } catch(error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 4426f3')
        }
      }
      if (eachPort.autoFlag === true && eachPort.external === '') {
        eachPort.external = 0
      }
      setState({
        ...state,
        portError: newPortError
      })
    }

    // 빈 element는 제거 (현재 autoflag는 true로 설정해서 체크가 되어도 빈 element로 취급 되어 제거됨. 친절하진않음. autoflag가 true일 때 값이 입력된 것으로 판단할지 체크 필요 = 입력된 것으로 판단된다면 내부포트 오류가 떠야 함)
    let resultPort = ports.filter(e => (e.internal !== '' && e.external !== ''))
    ports = resultPort

    // if (ports.length === 0) {
    //   return false
    // }

    if (blankErrorFlag === true) {
      //popupEvent.payload = { message: '입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.' }
      //window.dispatchEvent(popupEvent)
      return false
    }
    if (validationErrorFlag === true) {
      //popupEvent.payload = { message: '잘못된 값이 입력되었습니다. 해당 필드를 확인해 주세요.' }
      //window.dispatchEvent(popupEvent)
      return false
    }

    let result
    let errorMessage = ''
    if (editFlag) {
      try {
        result = await Ports.update(props.id, ports)
        errorMessage = `${props.name}의 포트 변경이 실패하였습니다.`  
      } catch(error) {
        popupController.confirm(errorMessage)
      }
    } else {
      try {
        result = await Ports.create(props.id, ports)
        errorMessage = `${props.name}의 포트 설정이 실패하였습니다.`
      } catch(error) {
        popupController.confirm(errorMessage)
      }
    }
    if ( result.status === 200 && result.message === "포트 변경 완료" ) {
      let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
      let message = `${props.name}의 포트를 변경하였습니다.`
      toastEvent.payload = { message: message }
      window.dispatchEvent(toastEvent)
      closeHandler()
    } else if (result.status === 200) {
      let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
      let message = `${props.name}의 포트를 생성하였습니다.`
      toastEvent.payload = { message: message }
      window.dispatchEvent(toastEvent)
      closeHandler()
    } else {
      //popupController.confirm(errorMessage)
      closeHandler()
    }
  }

  let editFlag: boolean = props.ports && props.ports.length > 0

  return (
    <PortEditPopupFragment>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
        <button onClick={closeHandler} className="btnClose">닫기</button>
        <div className="pageTitle inputBox inputBoxTitle">
          포트 설정
         </div>
        <div className="scrollWrap">
          <div className="inputWrap">
            {state.portMap.map((eachPort:IPortMap, idx:number) => {
              return (
                <div className="inputBox portWrap innerLabel" key={idx} style={{ alignItems: 'start' }}>
                  <div className="innerGap">
                    <span>내부</span>
                    <div className="portBox">
                      <InputBox id='internal' type={TEXT_INPUT_TYPE.TEXT} value={eachPort.internal} idx={idx} onChange={inputHandler} warning={state.portError[idx].internal + ''} />
                    </div>
                  </div>
                  <div className="innerGap">
                    <span>외부</span>
                    <div className="portBox">
                      <InputBox id='external' type={TEXT_INPUT_TYPE.TEXT} readonly={eachPort.autoFlag} value={eachPort.external} idx={idx} onChange={inputHandler} warning={state.portError[idx].external + ''} />
                    </div>
                    <span>자동 할당</span>
                    <Toggle /* ref={autoFlagRef} */ id={'autoPortFlag' + idx} checked={eachPort.autoFlag} onChange={(checked:boolean) => { inputHandler('autoFlag', checked, idx) }} />
                  </div>
                  <div className="addBtnWrap">
                    {
                      state.portMap.length === 1 ? false : <img src='/images/buttonMinus.png' alt='삭제' onClick={() => removePortHandler(idx)} style={{ paddingRight: '5px' }} />
                    }
                    {
                      idx === state.portMap.length - 1 ? <img src='/images/buttonPlus.png' alt='추가' onClick={addPortHandler} /> : <img src='/images/buttonPlus.png' alt='추가' className='disabled' />
                    }
                  </div>
                </div>)
            })}
          </div>
        </div>
        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          <button className="btn blue" onClick={(e) => { submitHandler() }}>{editFlag ? '변경하기' : '설정하기'}</button>
        </div>
      </form>
    </PortEditPopupFragment>
  )
}

const PortEditPopupFragment = styled.div`
  .formWrap { display:flex; flex-direction:column; width:869px; height:calc(100vh - 186px); min-height:571px; padding:50px 50px 20px; box-sizing:border-box; }
  .serviceTypeTitleBox { display:flex; gap:5px; margin-bottom:10px; }
  .pageTitle { flex:none; margin-bottom:0px; }
  p { font-size:14px; }
  .scrollWrap { overflow:auto; flex:1; margin-top:50px; } /* min-height:370px; */
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:20px; }
  .btnWrap .btn { width:188px; height:40px; line-height:40px; }
  .logBox { width:100%; height:100%; background-color:#EBEBEB; font-size:14px; }
  .logBox div { padding: 20px; word-break:break-all; }

  .inputGroup { margin-left:10px; }
  .inputBox + .inputBox { margin-top: 30px; }
  .inputBox.inputBoxTitle:not(:first-of-type) { padding-top:20px; margin-top:0px; }
  .inputBox.inputBoxTitle + .inputBox { margin-top: 7px; }

  form .inputBox {gap:30px; align-items:start;}
  form .inputBox .input { width:180px; max-width:182px; }
  form .inputBox input { width:180px; }
  .inputBox .btn { width:79px; white-space:pre; }
  .inputBox { font-size:14px; }
  .inputBoxTitle { font-size:24px; }
  .label, .inputBoxTitle { font-weight:500; }
  .message { font-size:12px; margin:5px 0 0 0; }
  img.disabled { opacity:0; visibility:hidden; }
  button.disabled { opacity:0.5; pointer-events:none; }

  .portWrap { width:769px; } //margin-bottom:30px;
  .portWrap .input { width:240px; }
  .portWrap > div > span { margin-top:10px; }
  .portWrap .portBox .message { width:165px; }
  .innerGap { display:flex; gap:10px; }
  .addBtnWrap { display:flex; gap:5px; }
`

export default PortEditPopup