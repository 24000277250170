import { createRef, useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import lodash from 'lodash'
import PopupController from "../../../controller/PopupController"
import ToastEvent from "../../../events/ToastEvent"
import { Ports, IPort } from "../../../model/Ports"
import { IServiceItem } from "../../../model/Service"
import Button, { BUTTON_COLOR } from "../uiv2/Button"
import InputBox, { TEXT_INPUT_RULE, TEXT_INPUT_TYPE } from "../uiv2/InputBox"
import Toggle from "../uiv2/Toggle"
import Form from "../uiv2/Form"
import regularExpression from "../../../utils/regularExpression"
import { delay, uniqueId } from "lodash"

interface IPortError {
  errorText1:string
  errorText2:string
}

interface IPortFormPopupProps {
  onSelected:Function
  onClose:Function
  data:IServiceItem
}

const PortFormPopup = (props:IPortFormPopupProps) => {
  const RegExp = regularExpression.regExp

  let refId:string[] = []
  for (let i = 0; i <= 999; i++) {
    refId.push(uniqueId())
  }
  let elementsRef = refId.map(() => createRef())

  const popupController = PopupController.getInstance()
  const setSubmitAvaliable = () => {
    let portError = checkDuplicatedPort(formList)
    setFormError(portError)
  }
  const defaultForm:IPort = {
    internal: '',
    external: '',
    autoFlag: false
  }
  const [ formList, setFormList ] = useState<IPort[]>([defaultForm]) 
  useEffect(() => {
    setSubmitAvaliable()
  }, [formList])

  const defaultError:IPortError = {
    errorText1: '',
    errorText2: ''
  }
  const [ formError, _setFormError ] = useState<IPortError[]>([defaultError]) 
  const setFormError = (newFormError:IPortError[]) => {
    let validate = true

    for (let key in newFormError) {
      let eachError = newFormError[key]
      if (eachError.errorText1 !== '' || eachError.errorText2 !== '') validate = false
      
      let refObj1:any = elementsRef[key].current
      let refObj2:any = elementsRef[300+Number(key)].current
      if (refObj1 !== null && refObj2 !== null) {
        delay(refObj1.setErrorMessage, 10, eachError.errorText1)
        delay(refObj2.setErrorMessage, 10, eachError.errorText2)
      }
    }
    
    _setFormError(newFormError)
    return validate
  }

  const formRef = useRef<any>()
  
  useEffect(() => {
    if (props.data) {
      const formList:IPort[] = []
      if (props.data.ports.length === 0) {
        formList.push({
          internal: '',
          external: '',
          autoFlag: false
        })
      } else {
        for (const item of props.data.ports) {
          formList.push({
            internal: String(item.internal),
            external: String(item.external),
            autoFlag: false
          })
        }
      }
      setFormList(formList)
    }
  }, [])

  const closeHandler = () => {
    if (props.onClose) props.onClose()
  }

  const listHandler = (type:string, idx?:number) => {
    let newList = lodash.cloneDeep(formList)
    switch (type) {
      case 'add':
        newList = newList.concat(defaultForm)
        break
      case 'remove':
        if (idx !== undefined) newList.splice(idx, 1)
        break
    }

    setFormList(newList)
    let portError = checkDuplicatedPort(newList)
    setFormError(portError)
  }

  const setValueHandler = (idx:number, key:string, data:number|string|boolean) => {
    let payload = formList.concat()
    if (payload) {
      if (!payload[idx]) payload[idx] = defaultForm
      if (key === 'autoFlag') payload[idx].external = ''
      let addr:any = payload[idx]
      addr[key] = data
      setFormList(payload)
    } else {
      setSubmitAvaliable()
    }
  }

  const checkUsedExternalPort = async (port:number) => {
    try {
      const response = await Ports.checkDuplicateOpsPort(port, props.data.id)
      return response.result ? '다른 서비스에서 사용 중인 외부 포트입니다.' : ''
    } catch (e) {
      return '외부 포트 사용여부 확인 실패했습니다.'
    }
  }

  const checkDuplicatedPort = (payload:IPort[]):IPortError[] => {
    let error = formRef.current?.serialize(true)
    const result:IPortError[] = []
    const internalPorts = payload.map(function(item:IPort) {return item.internal})
    const externalPorts = payload.map(function(item:IPort) {return item.external})

    for (const key in payload) {
      let port = payload[key]
      let each:IPortError = {
        errorText1: error.port[key] ? error.port[key].internal : '',
        errorText2: error.port[key] ? error.port[key].external : ''
      }
      if (port.internal && port.internal !== '' && internalPorts.indexOf(port.internal, internalPorts.indexOf(port.internal)+1) > -1) {
        each.errorText1 = each.errorText1 || '내부포트 필드 내 중복 입력된 값이 있습니다.'
      }
      if (port.external && port.external !== '' && port.autoFlag === false && externalPorts.indexOf(port.external, externalPorts.indexOf(port.external)+1) > -1) {
        each.errorText2 =  each.errorText2 || '외부포트 필드 내 중복 입력된 값이 있습니다.'
      }
      result.push(each)
    }

    return result
  }

  const submitHandler = async () => {
    let payload = formRef.current?.serialize()
    let portError = checkDuplicatedPort(payload.port)
    let validate = setFormError(portError)
    if (validate === false || payload === false || payload.port.length !== portError.length) {
      return false
    }
    payload = payload.port
    for (let key = 0; key < payload.length; key++) {
      let eachPort = payload[key]
      payload[key] = {
        internal: Number(eachPort.internal),
        external: eachPort.autoFlag ? 0 : Number(eachPort.external)
      }
      if (isNaN(payload[key].internal) === true && isNaN(payload[key].external) === false) portError[key].errorText1 = '내부 포트를 함께 입력해 주세요.'
      if (isNaN(payload[key].internal) === false && isNaN(payload[key].external) === true && eachPort.autoFlag === false) portError[key].errorText2 = '외부 포트를 함께 입력해 주세요.'
      if (isNaN(payload[key].external) === false && eachPort.autoFlag === false) portError[key].errorText2 = await checkUsedExternalPort(eachPort.external)
    }
    
    if (validate === true) {
      payload = payload.filter((each:any) => { return isNaN(each.internal) === false && isNaN(each.external) === false })
      try {
        if (props.data.ports.length === 0 && payload.length === 0) {
          closeHandler()
          return false
        }
        let message
        if (props.data.ports.length > 0) {
          const result = await Ports.updateOps(props.data.id, payload)
          message = `${props.data.name} 서비스의 포트를 변경하였습니다.` 
        } else {
          const result = await Ports.createOps(props.data.id, payload)
          message = `${props.data.name} 서비스의 포트를 생성하였습니다.`
        }
        let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
            toastEvent.payload = { message: message }
            window.dispatchEvent(toastEvent)
        if (props.onSelected) props.onSelected()
        closeHandler()
      } catch {
        if (props.data.ports.length > 0) {
          popupController.confirm(`${props.data.name} 서비스의 포트 생성에 실패하였습니다.` )
        } else {
          popupController.confirm(`${props.data.name} 서비스의 포트 변경에 실패하였습니다.` )
        }
      }
    }
  }

  return (
    <PortFormPopupFragment>
      <div className="pageTitle">포트 설정</div>
      <div className="scrollWrap">
        <div className="formRow">
          <label htmlFor="">내부</label>
          <label htmlFor="">외부</label>
          <label htmlFor="">자동할당</label>
          <label htmlFor=""></label>
        </div>
        <Form ref={formRef} className="formWrap">
          { formList.map((form:IPort, idx:number) => {
            return <div className="formRow" key={idx}>
            <InputBox id={`port-internal${idx}`} ref={elementsRef[idx]} type={TEXT_INPUT_TYPE.TEL} 
              value={form.internal} errorText={formError[idx] ? formError[idx].errorText1 : ''}
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                ref: elementsRef[300+idx],
                invalidateMessage: '내부 포트를 입력해주세요.',
                refsInvalidateMessage: '외부 포트를 입력해주세요.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP,
                rule: RegExp.NUMBER_ONLY,
                invalidateMessage: `65535 이하 숫자로 입력해 주세요.`
              }, {
                basis: TEXT_INPUT_RULE.GREATER_THEN,
                rule: 0,
                invalidateMessage: `65535 이하 숫자로 입력해 주세요.`
              }, {
                basis: TEXT_INPUT_RULE.LESS_THEN,
                rule: 65536,
                invalidateMessage: `65535 이하 숫자로 입력해 주세요.`
              }]}
              onChange={(id:string, value:number|string) => {
                setValueHandler(idx, 'internal', value)
              }} />
            <InputBox id={`port-external${idx}`} ref={elementsRef[300+idx]} type={TEXT_INPUT_TYPE.TEL} 
              value={form.external} errorText={formError[idx] ? formError[idx].errorText2 : ''} disabled={form.autoFlag}
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS,
                ref: elementsRef[idx],
                invalidateMessage: '외부 포트를 입력해주세요.',
                refsInvalidateMessage: '내부 포트를 입력해주세요.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP,
                rule: RegExp.NUMBER_ONLY,
                invalidateMessage: `30000 ~ 32767 사이의 숫자로 입력해 주세요.`
              }, {
                basis: TEXT_INPUT_RULE.GREATER_THEN,
                rule: 29999,
                invalidateMessage: `30000 ~ 32767 사이의 숫자로 입력해 주세요.`
              }, {
                basis: TEXT_INPUT_RULE.LESS_THEN,
                rule: 32768,
                invalidateMessage: `30000 ~ 32767 사이의 숫자로 입력해 주세요.`
              }]}
              onChange={(id:string, value:number|string) => {
                setValueHandler(idx, 'external', value)
              }} />
            <Toggle id={`port-autoFlag${idx}`} ref={elementsRef[600+idx]} checked={form.autoFlag} onChange={() => setValueHandler(idx, 'autoFlag', !form.autoFlag)} />
            { idx === formList.length -1 ? 
              <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => listHandler('add')}><img src="/images-v2/button-plus.svg" alt="add" /></Button> :
              <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => listHandler('remove', idx)}><img src="/images-v2/button-trash.svg" alt="remove" /></Button>
            }
          </div>
          })}
        </Form>
      </div>
      <div className="btnWrap">
        <Button color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={closeHandler}>취소</Button>
        <Button color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>적용하기</Button>
      </div>
    </PortFormPopupFragment>
  )
}

const PortFormPopupFragment = styled.div`
  display:flex; flex-direction:column; width:800px; height:calc(100vh - 186px); max-height:639px; padding:24px 32px 24px 32px; box-sizing:border-box;
  button.normal {min-width:40px}
  .pageTitle{font-size:24px; line-height:28.64px; font-weight:700;}
  .scrollWrap{overflow-y:auto;flex:1;margin-top:24px; padding-right:0;}
	.btnWrap{flex:none;display:flex;justify-content:end;gap:12px;margin-top:24px;padding-right:0;}
  .formRow{display: flex;align-items:flex-start;gap:24px;width:100%;}
  .formRow > :first-child{flex:1;}
  .formRow > :nth-child(2){flex:1;}
  .formRow > :nth-child(3){flex:none;width:56px;}
  .formRow > :nth-child(4){flex:none;width:40px;}
  .formRow + .formRow{margin-top:8px;}
  .formRow > :nth-child(3) label.normal{margin-top: 5px;}
  .formRow label{margin-bottom:8px;font-size:13px;color:#878791;font-weight:400;}
`

export default PortFormPopup