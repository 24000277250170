import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { ILoginResult, User } from '../model/User'

import ModalEvent from '../events/ModalEvent'
import PopupController from '../controller/PopupController'
import { ERROR_TYPE } from '../interfaces/Error'
import { useRecoilState } from 'recoil'
import { userInfoState, IUserInfo } from '../states/userInfoState'
import InputBox, { TEXT_INPUT_TYPE, TEXT_INPUT_RULE } from './components/uiv2/InputBox'
import { useRef } from 'react'
import { LOGIN_RESULT } from '../model/User'
import Form from './components/uiv2/Form'
import Button, { BUTTON_SIZE, BUTTON_COLOR } from './components/uiv2/Button'

interface VerificationOpsProps {
  //navigate?:NavigateFunction
  onPwPass:Function
}

interface VerificationOpsState {
  currentPw:string
  currentPwError:string
  error: {
    currentPw:string
  }
}

const VerificationOps = (props:VerificationOpsProps) => {
  const user = User()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  const popupController = PopupController.getInstance()
  const navigate = useNavigate()
  const formRef = useRef<any>()

  const [ state, _setState ] = useState<VerificationOpsState>({
    currentPw: '',
    currentPwError:'',
    error: {
      currentPw: ''
    }
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    document.addEventListener('keydown', preventEnterKey)
    return () => {
      document.removeEventListener('keydown', preventEnterKey)
    }
  }, [])

  const preventEnterKey = (e:any) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }
  }


  const inputHandler = (target:string, value:any) => {
    let errorText:string = ''

    switch (target) {
      case 'currentPw':
        if (value === '') {
          errorText = '필수 입력 항목입니다.'
        } 
        setState({
          ...state,
          currentPw: value,
          currentPwError: errorText
        })
        break
    }
  }

  const submitHandlerPw = async (e:any) => {
    //e.preventDefault()
    try {
      let blankErrorFlag:boolean = false
      let currentPwError:string = state.currentPwError
      if (state.currentPw === '') {
        blankErrorFlag = true
        setState({
          ...state,
          currentPwError:'필수 입력 항목입니다.'
        })
      }

      if(blankErrorFlag === true) {
        setState({
          ...state,
          currentPwError: currentPwError,
        })
      }

      if(blankErrorFlag === true || stateRef.current.currentPw === '') {
        setState({
          ...state,
          currentPwError: '비밀번호를 입력해 주세요.'
        })
      } else {
        const response = await user.checkPw(userInfo?.userId? userInfo.userId : '',state.currentPw)
        switch (response && response.status) {
          case LOGIN_RESULT.OKAY:
            props.onPwPass()
            break
          case LOGIN_RESULT.INVAILD_INFO:
          default:
            setState({
              ...state,
              currentPwError: '사용중인 비밀번호와 일치하지 않습니다.'
            })
            break
        }
      }
    } catch(e){
      if ((e as Error).message === ERROR_TYPE.WRONGPW) {
        setState({
          ...state,
          currentPwError: '사용중인 비밀번호와 일치하지 않습니다.'
        })
      } else {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 86a879')
        return
      }
    }
  }

  return (
    <VerificationOpsFragment>
      <div className="formWrap">
        <div className="titleWrap">
          <h1>비밀번호 재확인</h1>
          <div>회원님의 개인정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 확인해 주세요.</div>
        </div>
        <Form ref={formRef}>
          <section>
            <div className="inputWrap">
              <label className="label">아이디</label>
              <InputBox id="currentPw" type={TEXT_INPUT_TYPE.TEXT} readonly={true} placeholder='현재 사용중인 아이디' value={userInfo?.userId} />
            </div>
          </section>
          <section>
            <div className="inputWrap">
              <label className="label">비밀번호 <span className="requiredMark">•</span></label>
              <InputBox id="currentPw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder='현재 사용 중인 비밀번호를 입력해 주세요.' value={state.currentPw} errorText={state.currentPwError} 
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED,
                invalidateMessage: '비밀번호를 입력해 주세요.'
              }]}
              onChange={(id:any, value:any) => {
                setState({
                  ...state,
                  currentPw: value
                })
              }} />
            </div>
          </section>
          <section style={{ marginTop: '80px', flexFlow: 'row-reverse', gap: '12px' }}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandlerPw}>확인하기</Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={(e: any) => {
              setState({ ...state, pwEdit: false, currentPw: '', pw: '', confirmPw: '', currentPwError: '', pwError: '', confirmPwError: '' })
              let postUrl = sessionStorage.getItem('url')
              if (!postUrl) { postUrl = '/' }
              navigate(postUrl)
            }}>취소</Button>
          </section>
        </Form>
      </div>
    </VerificationOpsFragment>
  )
}

const VerificationOpsFragment = styled.div`
.formWrap {
  width:644px; min-height:800px; padding:0 334px;
  .titleWrap { align-items:center; gap:10px; margin-bottom:48px; color:#1A1A1A; }
  .titleWrap h1 { font-size:24px; margin-bottom:12px;}
  .titleWrap div { font-size:13px; line-height:20px; color:#878791; font-weight:400; }
  section + section { margin-top:24px; }
  .inputWrap { flex: 1 1 0%; }
  .inputWrap label.label { padding:0 0 8px 0 }
  .requiredMark { position:relative;top:-4px;color:#F00;font-size:13px;font-weight:700 }
}
input:focus-visible {border-color:#ff0000;}
`
export default VerificationOps