import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// import Tooltip from '../ui/Tooltip'

import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from "../ui/Table"
import { Image, IImageListItem, IImageItem } from '../../../model/Image'
import { IBaseDataType, SORT_ORDER } from '../../../model/BaseDataType'
import { ERROR_TYPE } from '../../../interfaces/Error'
import PopupController from '../../../controller/PopupController'
import TableEvent from '../../../events/TableEvent'

interface IImagePopupProps {
  onClose:Function
  onSelected:Function
  rgroup:string
  preSelectedReg?:string
  preSelectedImage?:IImageListItem
  jupyterFlag?:boolean
  isCommon?:boolean
}
interface IImagePopupState {
  tab:IMAGE_POPUP_TYPE
  commonImageList:any[]
  typeImageList:any[]
  selectedItem:IBaseDataType
  registry?:string
}

enum IMAGE_POPUP_TYPE {
  PUBLIC = 'public',
  USER = 'user',
  RGROUP = 'rgroup'
}

const ImagePopup = (props: IImagePopupProps) => {
  const popupController = PopupController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [52, 359, 164, 422]
  const headerLabel: ITableHeader[] = [
    {
      label: '이미지 이름',
      key: 'rep',
      type: TABLE_CELL_TYPE.STRING,
      sort: SORT_ORDER.ASC,
      styleKey: 'style',
      orderFlag: true
    }, {
      label: '태그',
      key: 'tag',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }, {
      label: 'Image ID',
      key: 'id',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }
  ]

  const [ commonCount, setCommonCount ] = useState<string>('0')
  const [ typeCount, setTypeCount ] = useState<string>('0')
  const [ emptyText, setEmptyText ] = useState<string>('등록된 이미지가 없습니다.')
  const [ state, _setState ] = useState<IImagePopupState>({
    registry: '',
    tab: IMAGE_POPUP_TYPE.PUBLIC,
    commonImageList: [],
    typeImageList: [],
    selectedItem: {}
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ tableItems, setTableItems ] = useState<IImageItem[]>([])
  useEffect(() => {
    // set preselectedItem
    let selectedIdx: number[] = []
    if (props.preSelectedReg && props.preSelectedImage) {
      if (props.preSelectedReg === state.registry) {
        let preRepository = props.preSelectedImage.split(':')[0]
        let preTag = props.preSelectedImage.split(':')[1]
        let list = state.tab === IMAGE_POPUP_TYPE.PUBLIC ? state.commonImageList : state.typeImageList
        let addIdx = list.findIndex((element: IImageItem) => element.rep === preRepository && element.tag === preTag)
        if (addIdx !== -1) {
          selectedIdx.push(addIdx)
        }
        tableRef.current?.setSelected(selectedIdx)
      }
    }
  }, [tableItems])
  
  useEffect(() => { // 탭 변경 시, 스크롤 초기화
    if (document.getElementsByClassName('scrollWrap')[0]) {
      document.getElementsByClassName('scrollWrap')[0].scrollTo(0, 0)
    }
  }, [state.tab])
  
  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    }
  }, [])

  const eventControl = (e:Event) => {
    getData(stateRef.current.tab)
  }

  const getData = async (tab?:IMAGE_POPUP_TYPE) => {
    try {
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      const data = await Image.getListPopup(props.rgroup, props.jupyterFlag || false, sortOrder, props.isCommon || false)
      const selectedTab = tab ? tab : state.tab

      if (data) {
        // set data
        let registry = selectedTab === IMAGE_POPUP_TYPE.PUBLIC ? data.common.reg : data.type.reg
        const commonImageList = data.common.imageList.filter((image:any) => image.commitInfo === null)
        const typeImageList = data.type.imageList.filter((image:any) => image.commitInfo === null)
        setState({
          ...state,
          registry: registry === null ? '' : registry,
          commonImageList: commonImageList,
          typeImageList: typeImageList,
          tab: selectedTab
        })
        
        if(registry === null) {
          setEmptyText('리소스 그룹의 이미지 레지스트리가 없어 이미지 확인이 불가합니다. \n관리자에게 문의해 주세요.')
        } else {
          setEmptyText('등록된 이미지가 없습니다.')
        }
        setCommonCount(commonImageList.length) //setCommonCount(data.common.total)
        // setTypeCount(selectedTab !== IMAGE_POPUP_TYPE.PUBLIC && registry === null ? '-' : data.type.total)
        setTypeCount(selectedTab !== IMAGE_POPUP_TYPE.PUBLIC && registry === null ? '-' : typeImageList.length)
        setTableItems(selectedTab === IMAGE_POPUP_TYPE.PUBLIC ? commonImageList : typeImageList)
      } else {
        setTableItems([])
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        //이미지 목록 불러오기 에러 남
        console.log(e)
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 925563')
      }
    }
  }

  const onSelectHandler = (item: IBaseDataType) => {
    // console.log('onSelectHandler', item)
    setState({
      ...state,
      selectedItem: item
    })
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    if (props.onSelected) props.onSelected(tableItems[state.selectedItem[0]], state.registry)
    closeHandler()
  }

  const tabHandler = (type:IMAGE_POPUP_TYPE) => {
    getData(type)
  }

  return (
    <ImagePopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
      { props.jupyterFlag ? 
        <h2 className="pageTitle">
          주피터노트북 이미지 목록
          <span className="total"><b>{commonCount}</b> 개</span>
          {/* <Tooltip des="한 번에 1 개만 가져오기 가능합니다." /> */}
        </h2>
       :
        <div className="tabWrap">
          <button className={state.tab === IMAGE_POPUP_TYPE.PUBLIC ? 'pageTitle on' : 'pageTitle'}
            onClick={() => tabHandler(IMAGE_POPUP_TYPE.PUBLIC)}>
            공용 이미지 목록
            <span className="total"><b>{commonCount}</b> 개</span>
          </button>
          {props.isCommon === true ? <>
            <button className={state.tab === IMAGE_POPUP_TYPE.USER ? 'pageTitle on' : 'pageTitle'}
              onClick={() => tabHandler(IMAGE_POPUP_TYPE.USER)}>
              사용자 정의 이미지 목록
              <span className="total"><b>{typeCount}</b> 개</span>
            </button>
          </> : <>
            <button className={state.tab === IMAGE_POPUP_TYPE.RGROUP ? 'pageTitle on' : 'pageTitle'}
              onClick={() => tabHandler(IMAGE_POPUP_TYPE.RGROUP)}>
              리소스 그룹 이미지 목록
              <span className="total"><b>{typeCount}</b> 개</span>
            </button>
          </>}
        </div>
      }
      
      { state.tab !== IMAGE_POPUP_TYPE.PUBLIC ?
        <div className="resourceGroupInfoBox">
          <div className="resourceGroupInfo">
            { state.tab === IMAGE_POPUP_TYPE.RGROUP ? <div>{props.rgroup}</div> : false }
            <div className={props.isCommon ? "" : "leftWall"}>이미지 레지스트리: {state.registry ? state.registry : '-'}</div>
          </div>
        </div>
        : <div className="devInterval"></div>}
      <div className="scrollWrap">
        <Table ref={tableRef}
          colWidth={tableColWidth}
          headerLabel={headerLabel}
          items={tableItems}
          checkBoxType={CHECKBOX_TYPE.USE_SINGLE}
          name="image"
          noData={emptyText}
          checkBoxPreserveKeys={['isDefault']}
          onSelect={onSelectHandler} />
      </div>
      <div className="btnWrap">
        <button className="btn grey" onClick={closeHandler}>취소</button>
        {tableItems && state.selectedItem && tableItems[state.selectedItem[0]] ? //&& tableItems[state.selectedItem[0]].repository
          <button className="btn blue" onClick={submitHandler}>가져오기</button> :
          <button className="btn off">가져오기</button>
        }
      </div>
    </ImagePopupFragment>
  )
}

const ImagePopupFragment = styled.div`
  display:flex;flex-direction:column;width: 1100px;height:calc(100vh - 186px);max-height:682px;padding: 50px 50px 20px;box-sizing:border-box;

  .tabWrap{display:flex;}
  .tabWrap button,
  .tabWrap button .total b{color:#ccc;}
  .tabWrap button.on{color:#303030;}
  .tabWrap button.on .total b{color:#217eff;}
  .tabWrap button + button:before{content:'';display:inline-block;width:2px;height:26px;margin:0 16px;background:#ccc;vertical-align:middle;}
  
  .pageTitle{flex:none;}
  .scrollWrap{overflow:auto;flex:1;}
  .btnWrap{flex:none;display:flex;justify-content:center;gap:20px;margin-top:20px;}
  .btnWrap .btn{width:188px;height:40px;line-height:40px;}
  tbody tr.isDefault td {background-color:#edf4f4}
  .col1{text-align:center;}
  .col2{text-align:center;}
  .devInterval{margin-bottom:60px;}
  .resourceGroupInfoBox{display:inline-flex; margin-top:19px; margin-bottom:10px;}
  .resourceGroupInfo{display:flex; justify-content:start; align-items:center; gap:10px; padding:7px 15px; background:#F9F9F9; border-radius:2px; font-size:14px; line-height:17px; font-weight:500;}
  .leftWall::before {content:''; display:inline-block; width:1px; height:10px; margin-right: 10px; background:#868686;}
`

export default ImagePopup