import { useEffect, useRef, useState } from 'react'
import { NavigateFunction, Params } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import dayjs from "dayjs"

import PopupController from '../../controller/PopupController'

import { SORT_ORDER } from "../../model/BaseDataType"
import { Service, IServiceItem, SERVICE_LIST_ORDER_BY, SERVICE_STATUS } from '../../model/Service'
import { NODE_TYPE } from '../../model/Workspace'

import TableEvent from "../../events/TableEvent"
import ModalEvent from "../../events/ModalEvent"
import ToastEvent from '../../events/ToastEvent'
import PageEvent from '../../events/PageEvent'

import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/uiv2/Table'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../components/uiv2/Button'
import PortPopup from "../components/modalv2/sub/PortPopup"
import Pagenation from '../components/uiv2/Pagenation'

import { authState } from '../../states/authStates'
import SubPopupTable from '../components/modalv2/sub/SubPopupTable'
import WebSocketController from '../../controller/WebSocketController'
import WebSocketEvent from '../../events/WebSocketEvent'

interface IServiceListProps {
  navigate?:NavigateFunction
  params?:Params
}

interface IServiceListState {
  page:number
  totalArticle:number
  tableItems:IServiceItem[]
  deleteItems:IServiceItem[]
  selectedItems?:IServiceItem[]
}

const ServiceList = (props:IServiceListProps) => {
  const tableRef = useRef<any>()
  const popupController = PopupController.getInstance()
  const webSocketController = WebSocketController.getInstance()

  const tableColWidth = [50, 130, 172, 185, 180, 220, 130, 117, 128]
  const headerLabel:ITableHeader[] = [
    { label: 'No', key: 'tableIdx', type: TABLE_CELL_TYPE.STRING, orderFlag: false, sort: false}, 
    { label: '리소스 그룹', key: 'rgroup', type: TABLE_CELL_TYPE.STRING, orderFlag: true, sort: true, sortKey: 'resourceGroupName'}, 
    { label: '서비스 분류', key: 'project', type: TABLE_CELL_TYPE.STRING, orderFlag: true, sort: true, sortKey: 'serviceType'}, 
    { label: '서비스 이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: true , sortKey: 'name' }, 
    { label: '운영기간', key: 'tableOperationTimestamp', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey:'operationPeriod' }, 
    { label: '포트정보', key: 'portInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false }, 
    { label: '리소스 타입', key: 'tableResourceType', type: TABLE_CELL_TYPE.HTML, sort: false, sortKey: 'resourceType' }, 
    { label: '레플리카 개수', key: 'tableReplicasCount', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'replicasCount' }, 
    { label: '상태', key: 'statusCell', type: TABLE_CELL_TYPE.FLEXBOX, sort: SORT_ORDER.ASC, sortKey: 'status' }
  ]
  const [ authInfo ] = useRecoilState(authState)

  const [ state, _setState ] = useState<IServiceListState>({
    totalArticle: 0,
    page: (props.params?.page ? Number(props.params?.page) : 1),
    tableItems: [],
    deleteItems: [],
    selectedItems: []
  })

  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  
  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    webSocketController.addEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)

    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      webSocketController.removeEventListener(WebSocketEvent.WS_EVENT, webSocketActionHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    //getData(1)
  }, [])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    getData(1)
  }

  const getData = async(page?:number):Promise<void> => {
    page = page ? page : state.page

    const ITEM_PER_PAGE = 50
    let sortKey:SERVICE_LIST_ORDER_BY
    
    switch (tableRef.current?.sortKey) {
      case 'resourceGroupName':
        sortKey = SERVICE_LIST_ORDER_BY.RESOURCE_GROUP_NAME
        break
      case 'serviceType':
        sortKey = SERVICE_LIST_ORDER_BY.SERVICE_TYPE
        break
      case 'name':
        sortKey = SERVICE_LIST_ORDER_BY.NAME
        break
      case 'operationPeriod':
        sortKey = SERVICE_LIST_ORDER_BY.OPERATION_PERIOD
        break
      case 'resourceType':
        sortKey = SERVICE_LIST_ORDER_BY.RESOURCE_TYPE
        break
      case 'replicasCount':
        sortKey = SERVICE_LIST_ORDER_BY.REPLICAS_COUNT
        break
      case 'status':
      default:
          sortKey = SERVICE_LIST_ORDER_BY.STATUS
        break
    }
    const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.DESC : tableRef.current?.sortOrder)
    try {
      const serviceList = await Service.getList(authInfo?.userNo ? authInfo.userNo : 0, sortKey, sortOrder, page, ITEM_PER_PAGE)
      // console.log(serviceList)
      
      if (serviceList) {
        let idx = 0
        for (let eachLine of serviceList.operations) {
          eachLine.tableIdx = (idx+1)+(page-1)*ITEM_PER_PAGE
          idx++
          
          eachLine.rgroup = eachLine.rgroup === 'common' ? '기본 리소스 그룹' : eachLine.rgroup
          eachLine.project = eachLine.project ? eachLine.project : '(No label)'

          //eachLine.tableOperationTimestamp
          switch(eachLine.statusId) {
            case SERVICE_STATUS.ERROR: //운영중 or -
            case SERVICE_STATUS.CAUTION: //운영중
            case SERVICE_STATUS.RUNNING: //운영중
              eachLine.tableOperationTimestamp = [
                [<p className="period">
                  {eachLine.startedAt ? <>{dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss')+' ~ '}<br /><span>( 운영중 )</span></> : <span>-</span>}
                </p>]
              ]
              break
            case SERVICE_STATUS.PENDING: //- or 제 3의 형태는 나중에 적용(시작->대기)
              eachLine.tableOperationTimestamp = [
                [<p className="period">
                  <span>-</span>
                </p>]
              ]
              break
            case SERVICE_STATUS.STOPPING: //시작~종료 or -(에러/대기->중지)
            case SERVICE_STATUS.STOPPED: //시작~종료 or -(에러/대기->중지)
              eachLine.tableOperationTimestamp = [
                [<p className="period">
                  {eachLine.startedAt ? <>{dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss')+' ~ '}<br /></> : <span>-</span>}
                  {eachLine.deletedAt && eachLine.startedAt ? <>{dayjs(eachLine.deletedAt * 1000).format('YYYY/MM/DD HH:mm:ss')}</> : false}
                </p>]
              ]
              break
            default:
              eachLine.tableOperationTimestamp = [
                [<p className="period">
                  <span>-</span>
                </p>]
              ]
              break
          }
          
          eachLine.portInfo = [
            [<p>접속주소: {eachLine.ip}</p>],
            [<p>내부: - / 외부: -</p>], 
            [<Button onClickButton={() => modalHandler('port', eachLine)} size={BUTTON_SIZE.SMALL} /* disabled={eachLine.statusId === SERVICE_STATUS.STOPPED} */>
              포트설정
            </Button>]
          ]
          if (eachLine.ports.length) {
            // const moreBtn =  eachLine.ports.length > 1 ? <PortPopup data={eachLine.ports} /> : false
            const moreBtn =  eachLine.ports.length > 1 ? <SubPopupTable title='포트 정보' datas={eachLine.ports} tableHeaders={['내부포트', '외부포트']} numbering={true} colWidth={[42, 130, 130]} width={334} /> : false
            eachLine.portInfo[1] = [<div className="portInfo">내부: {eachLine.ports[0].internal} / 외부: {eachLine.ports[0].external} {moreBtn}</div>]
          }

          eachLine.tableResourceType = eachLine.resourceBlock.type === NODE_TYPE.CPU ? 
          `CPU resource
          CPU: <b>${eachLine.resourceBlock.cpu} 코어</b>
          MEM: <b>${eachLine.resourceBlock.mem} ${eachLine.resourceBlock.memUnit}</b>` : // CPU
          `${eachLine.resourceBlock.type}: <b>${eachLine.resourceBlock.gpuNum} 개</b>` // GPU

          const replica = eachLine.replicas ? `${eachLine.replicas}개` : '0개' // 신청 레플리카 개수
          const activeReplica = eachLine.readyReplicas ? `${eachLine.readyReplicas} 개 / ` : '0 개 / ' // 활성 레플리카 개수
          eachLine.tableReplicasCount = [
            // [<img src="/images-v2/table-autoscale.png" alt="autoscale" />], // 현재 autoscale 기능 없음
            [<p>{eachLine.replicas ? activeReplica + replica : replica}</p>]
          ]
          
          eachLine.statusCell = [
            [<div className="btnWrap">
              <Button size={BUTTON_SIZE.SMALL} url={`/service/${eachLine.id}`}>상세보기</Button>
              <Button size={BUTTON_SIZE.SMALL} onClickButton={() => modalHandler('log', eachLine)}>로그</Button>
            </div>]
          ]

          switch (eachLine.statusId) {
            // case SERVICE_STATUS.RUNNING:
            case SERVICE_STATUS.ERROR:
              eachLine.statusCell.unshift([<p className="status">장애 발생 <img src="/images-v2/table-status.svg" alt="!" /></p>])
              eachLine.rowClassName = 'error'
              break
            case SERVICE_STATUS.CAUTION:
              eachLine.statusCell.unshift([<p className="status">주의 <img src="/images-v2/table-status.svg" alt="!" /></p>])
              eachLine.rowClassName = 'warn'
              break
            case SERVICE_STATUS.PENDING:
              eachLine.statusCell.unshift([<p className="status">생성 대기중</p>])
              eachLine.rowClassName = 'deactivate'
              break
            case SERVICE_STATUS.STOPPING:
              eachLine.statusCell.unshift([<p className="status">서비스 중지중</p>])
              eachLine.rowClassName = 'deactivate'
              break
            case SERVICE_STATUS.STOPPED:
              eachLine.statusCell.unshift([<p className="status">서비스 중지</p>])
              eachLine.rowClassName = 'deactivate'
              break
            default:
              eachLine.rowClassName = ''
              break
          }
        }
        setState({
          ...state,
          page: page ? page : stateRef.current.page,
          totalArticle: serviceList.total,
          tableItems: serviceList.operations
        })
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch(err) {
      console.log(err)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - ea9cdd')
    }
  }

  const navigateToPage = (page:number):void => {
    if(page === state.page) return
    /* setState({
      ...state,
      page: page
    }) */
    getData(page)
  }

  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IServiceItem[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedItems: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedItems: []
      })
    }
  }

  const modalHandler = (target:string, item:IServiceItem) => {
    switch (target) {
      case 'port':
        popupController.port(() => {
          getData()
        }, item)
        break
      case 'log':
        popupController.log(item)
        break
    }
  }

  const removeHandler = async ():Promise<void> => {
    let targetIdArray:number[] = []
    let targetNameArray:string[] = []
    let nonDeletableIdArray:number[] = []
    let noneDeletableNameArray:string[] = []
    let stoppedIdArray:number[] = []
    let stoppedNameArray:string[] = []
    const items = state.tableItems
    const selectedIdx = tableRef.current?.selected

    /* let e:ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    e.payload = {} */

    if (items && selectedIdx && selectedIdx.length > 0) {
      for (let eachIdx of selectedIdx) {
        let eachItem = items[Number(eachIdx)]
        targetIdArray.push(eachItem.id)
        targetNameArray.push(eachItem.name)
        // targetIdNameArray.push({
        //   id:eachItem.id,
        //   name:eachItem.name
        // })
        switch (eachItem.statusId) {
          case SERVICE_STATUS.RUNNING:
          case SERVICE_STATUS.PENDING:
          case SERVICE_STATUS.ERROR:
          case SERVICE_STATUS.STOPPING:
          case SERVICE_STATUS.CAUTION:
            nonDeletableIdArray.push(eachItem.id)
            noneDeletableNameArray.push(eachItem.name)
            break
          case SERVICE_STATUS.STOPPED:
            stoppedIdArray.push(eachItem.id)
            stoppedNameArray.push(eachItem.name)
            break
        }
      }
      if (nonDeletableIdArray.length === 0) {
        let message:string = ''
        if(stoppedNameArray.length === 1) {
          message=`${stoppedNameArray[0]} 서비스가 삭제됩니다. 진행하시겠습니까? `
        } else if(stoppedNameArray.length > 1) {
          message=`${stoppedNameArray[0]}외 ${stoppedNameArray.length-1}개 서비스가 삭제됩니다. 진행하시겠습니까?`
        } 
        let payload = {
          targetIdArray: targetIdArray,
          targetNameArray: targetNameArray,
          stoppedNameArray: stoppedNameArray
        }
        popupController.dialouge(message, 'deleteServiceDev', payload, '확인', '취소')
      } else if (targetIdArray.length === 1 && nonDeletableIdArray.length === 1) {
        popupController.confirm(`${noneDeletableNameArray[0]} 서비스를 중지한 후 삭제 가능합니다.`)
      } else {
        popupController.confirm(`${nonDeletableIdArray.length} 개의 삭제 불가능한 항목이 포함 되어 있습니다. 서비스를 중지한 후 삭제 가능합니다.`)
      }
    } else {
      popupController.confirm(`선택된 항목이 없습니다.`)
    }
    /* popupController.dispatchEvent(e) */
  }

  const webSocketActionHandler = (e:WebSocketEvent) => {
    switch (e.payload.action) {
      case 'servicereload':
        getData(1)
        break
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    const items = state.tableItems
    const selectedIdx = tableRef.current?.selected
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)

    switch (e.payload.action) {
      case 'deleteServiceDev':
        try {
          const targetIdArray:number[] = e.payload.key.targetIdArray
          const targetNameArray:string[] = e.payload.key.targetNameArray
          const stoppedNameArray:string[] = e.payload.key.stoppedNameArray  //deletable
          // targetIdNameArray.push({id:41234123, name: "없는워크스페이스"},{id: 4111111, name: "에러워크스페이스"}) //에러테스트용이었음

          if (items && selectedIdx && selectedIdx.length > 0) {
            const response = await Service.removeItem(targetIdArray)
            let message:string = ''
            if (!response) {
              if (stoppedNameArray.length === 1) {
                message = `${targetNameArray[0]} 서비스가 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 삭제해 주세요.`
              } else if (stoppedNameArray.length > 1) {
                message = `${targetNameArray[0]} 외 ${targetNameArray.length - 1}개 서비스가 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
              }
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
            } else {
              //failed가 있는 상태
              if(targetIdArray.length === 1) {
                message = `${targetNameArray[0]}서비스 삭제에 실패하였습니다. 다시 시도해 주세요.`
              } else if (targetIdArray.length > 1) {
                message = `서비스 ${targetNameArray.length}개 삭제 과정 중 ${response.data.failedCount}개 항목 삭제에 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.data.deletedCount}개 / 실패 ${response.data.failedCount}개`
              }
              popupController.confirm(message)
            }

            tableRef.current?.setSelected([])
            getData()
            navigateToPage(state.page)
          }

        } catch (error:any) {
          //400이외의 ERROR만 발생시 뜸
          popupController.confirm('에러가 발생했습니다.\n에러코드 - baed7c')
        }
        break
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  return (
    <PageFragment>
      {/* <div className="summaryWrap">
        <section className="onService">
          <h2>운영중 서비스</h2>
          <div>
            <dl className="active">
              <dt>활성</dt>
              <dd>0</dd>
            </dl>
            <dl className="error">
              <dt>장애</dt>
              <dd>0</dd>
            </dl>
            <dl className="deactivate">
              <dt>비활성</dt>
              <dd>0</dd>
            </dl>
          </div>
        </section>
        <section>
          <h2>GPU 사용 상위 서비스</h2>
        </section>
        <section className="resourceBlock">
          <h2>8월 리소스 블록 타입 별 평균 사용률</h2>
          <div className="titleWrap">
            <h3>전체 리소스 블록 타입 평균</h3>
            <span className="count">0%</span>
          </div>
          <ul>
            <li>
              <span className="blockTitle">1 nvidia-a100-sxm4-40gb 블록</span>
              <span className="count">0%</span>
            </li>
            <li>
              <span className="blockTitle">1 nvidia-a100-sxm4-40gb 블록 vidia-a100-sxm4-40gb 블록 vidia-a100-sxm4-40gb 블록</span>
              <span className="count">0%</span>
            </li>
            <li>
              <span className="blockTitle">1 nvidia-a100-sxm4-40gb 블록</span>
              <span className="count">0%</span>
            </li>
          </ul>
        </section>
      </div> */}
      <div>
        <div className='pageTitle' style={{ display:'flex', flexGrow:1, gap:'16px' }}>
          <div>서비스 목록</div>
          <div className='dividerVertical'></div>
          <div className='count'><span>{state.totalArticle}</span>개</div>
        </div>
        <div className='buttonWrap'>
          {/*<Button color={BUTTON_COLOR.OUTLINE_DEFAULT} disabled={state.selectedItems === undefined || !state.selectedItems.length} onClickButton={removeHandler}>선택 항목 삭제</Button>
          <span className="bar" />*/}
          <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_SECONDARY} url="/service/new">서비스 생성</Button>
        </div>
      </div>
      <Table ref={tableRef}
        colWidth={tableColWidth}
        headerLabel={headerLabel} 
        items={state.tableItems}
        noData="데이터가 없습니다."
        orderPrefix={(state.page - 1) * 50}
        onUpdate={onUpdateStatus}
        onSelect={selectBoxCheckHandler}
        checkBoxType={CHECKBOX_TYPE.NONE}
        defaultSortOrder={SORT_ORDER.ASC}/>
      {state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage: number) => navigateToPage(newPage)} /> : false
      }
    </PageFragment>
  )
}


const PageFragment = styled.div`
.summaryWrap{display:flex; gap: 24px;margin-bottom: 56px;}
.summaryWrap section{flex:1;width:calc(33.33% - 16px);padding: 24px;border:1px solid #D5D5DA;border-radius:6px;box-sizing:border-box;}
.summaryWrap h2{margin-bottom:24px;font-size: 14px;color: #1A1A1A;font-weight: 600;}
.summaryWrap .onService{display:flex;flex-direction:column;}
.summaryWrap .onService div{flex:1;display:flex;gap: 16px;}
.summaryWrap .onService dl{flex:1;display:flex;align-items:center;justify-content:center;flex-direction:column;gap:16px;border-radius:6px;}
.summaryWrap .onService dl.active{background: rgba(75, 130, 255, 0.06);}
.summaryWrap .onService dl.error{background: rgba(255, 0, 0, 0.06);}
.summaryWrap .onService dl.deactivate{background: rgba(135, 135, 145, 0.06);}
.summaryWrap .onService dt{font-size: 12px;font-weight: 600;color:#333;}
.summaryWrap .onService dd{font-size: 18px;font-weight: 700;}
.summaryWrap .onService dl.active dd{color: #4B82FF;}
.summaryWrap .onService dl.error dd{color: #F00;}
.summaryWrap .onService dl.deactivate dd{color: #C1C1C1;}
.summaryWrap .resourceBlock .titleWrap{display:flex;align-items:center;justify-content: space-between;padding-bottom:12px;border-bottom:1px solid #E8E8EE;}
.summaryWrap .resourceBlock h3{font-size: 13px;font-weight: 600;color: #333;}
.summaryWrap .resourceBlock li{display:flex;align-items:center;justify-content: space-between;gap:24px;margin-top:12px;font-size: 13px;font-weight: 400;color: #646469;}
.summaryWrap .resourceBlock .count{flex:none;}
.summaryWrap .resourceBlock .blockTitle{flex:1;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.pageTitle{font-size:24px; line-height:28.8px; font-weight:700; color:#1A1A1A; margin-bottom:48px;}
.pageTitle .dividerVertical{border-left:1px solid #D5D5DA; height:20px;}
.pageTitle .count{font-size:18px; line-height:21.6px; font-weight:400; color:#878791;}
.pageTitle .count span{font-weight:700; color:#4B82FF;}
.buttonWrap{display:flex; justify-content:flex-end; align-items:center; margin-bottom:12px;}

td p.period{text-align: center}
td p.period span{display:inline-block;margin-top:6px;}
td .portInfo{display:flex;align-items:center;justify-content: space-between;}
td.col5 .flexbox{flex-direction:column;justify-content:flex-start;}
td.col5 .flexbox > div{flex:1;}
td.col5 .flexbox button.small{margin-top:6px;}
td.col7 img{width: 16px;margin-left:4px;}
tr.fail td .status{margin-bottom:6px;color: #333;}
tr.warn td .status img{filter: brightness(0) saturate(100%) invert(89%) sepia(61%) saturate(864%) hue-rotate(333deg) brightness(100%) contrast(101%);}
td .status img{display:inline-block;margin-bottom:1px;vertical-align:middle;width:14px;}
tr.deactivate td.col8 {color: #333;}

//small button
.small {padding:0 6px;}

.col0 {text-align:center;}
`

export default ServiceList