import { useResetRecoilState, useSetRecoilState, useRecoilState } from "recoil"

import ApiController, { AUTH_RESULT } from "../controller/ApiController"
import { userInfoState, IUserInfo } from "../states/userInfoState"
import { authState } from "../states/authStates"
import { ERROR_TYPE } from '../interfaces/Error'

import camelcaseKeys from 'camelcase-keys'
import BillingAPI from "./Billing"
import { IBaseDataType, SORT_ORDER } from "./BaseDataType"
import snakecaseKeys from "snakecase-keys"
import { promiseSetRecoil } from 'recoil-outside'
import { useNavigate } from "react-router"
import CryptoJS from 'crypto-js'
import Config from "../Config"

export enum LOGIN_RESULT {
  OKAY = 200,
  INVAILD_INFO = 400,
  NOT_AUTHORIZED = 401,
  INVALID_ID = 402,
  INVALID_PW = 403,
  ACCOUNT_DORMANT = 409,
  ACCOUNT_LOCKOUT = 423,
}

export enum REFRESH_TOKEN_RESULT {
  OKAY = 200,
  INVAILD_INFO = 200,
  EXPIRED = 401
}

export enum PERMISSION_STATUS {
  NOTPERMITTED = 0,
  PERMITTED = 1
  /* PERMITTED = 0,
  DENIED = 1,
  PENDING = 2 */
}

export enum USER_LIST_ORDER_BY {
  NAME = 1,
  DEPARTMENT = 2,
  CREATION_TIMESTAMP = 5,
  IS_CONFIRM = 6,
  ID = 0
}

export interface IUserDetail {
  userNo?:number|null
  userId:string
  pw?:string|null
  name?:string
  phoneNumber?:string
  email?:string
  department?:string
  permissionStatus?:PERMISSION_STATUS
  wsCount?:number
  resourceLimit?:IResourceLimit
  passwordInitialized?:boolean
  creationTimestamp?:number //이제는여기서안불러올텐데
  telandEmail?:any
  selected?:boolean
  orgPw?:string
  pwIv?:string
  orgPwIv?:string
  additionalInfo?:string
}

export interface IUserDetailGroup {
  userNo:number
  creationTimestamp?:number
  department:string
  phoneNumber:string
  userId:string
  email:string
  name:string
  permissionStatus?:PERMISSION_STATUS
  userInfo?:any
  pw?:string|null
}

export interface IResourceLimit {
  isGpuResourceBlocksEnabled:boolean
  totalGpuResourceBlocks:number
  isWorkspaceAutoReclaimEnabled:boolean
  workspaceAutoReclaimHours:number
  isAdminApproval:boolean  
  usedGpuResourceBlocks:number
  runningGpuResourceBlocks:number
  pendingGpuResourceBlocks:number
  userNo:number|string
  userId:string
}

export interface ILoginResult {
  status:LOGIN_RESULT
  failedPasswordAttempts?:number
  data?:any
}


export const User = () => {
  // const [ authInfo, setAuthInfo ] = useRecoilState(authState)

  const apiController = ApiController.getInstance()
  const setAuthInfo = useSetRecoilState(authState)
  const setUserInfo = useSetRecoilState(userInfoState)
  const resetAuthInfo = useResetRecoilState(authState)
  const navigate = useNavigate()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  let prevUserInfo = userInfo ? userInfo : {
    creationTimestamp: null,
    department: '',
    phoneNumber: '',
    userId: '',
    email: '',
    name: '',
    userNo: -1,
    permissionStatus: null,
    pw: null,
    resourceLimit: null,
    wsCount: null,
    startTimestamp: 0,
    additionalInfo:''
  }

  return {
    login: login,
    logout: logout,
    checkUserId: checkUserId,
    getMyInfo: getMyInfo,
    checkPw: checkPw,
    getList: getList,
    deleteUser: deleteUser,
    join: join,
    userResourceLimit: userResourceLimit,
    resetPassword: resetPassword,
    pwEditPass: pwEditPass,
    updateUser: updateUser,
    putMyInfo: putMyInfo,
    getUserListPermitted: getUserListPermitted,
    resetDormancy: resetDormancy
  }

  async function login(id:string, pw:string):Promise<ILoginResult> {
    try {
      const tokenLifespan = 290000
      const tokenExpireIn = new Date().getTime() + tokenLifespan

      const response = Config.env.REACT_APP_MODE === 'OPS' ? 
      await apiController.getAuth('/users/login', {
        id: id,
        pw: pw,
      }, '/users/refresh') :
      await apiController.getAuth('/users/login', {
        id: id,
        pw: pw,
      }, '/users/refresh')

      let result:any = {status: response.status}
      switch (response.status) {
        case LOGIN_RESULT.OKAY:
          setAuthInfo({
            id: response.data.user_id,
            userNo: response.data.user_no,
            isAdmin: response.data.is_admin,
            isOps: (Config.env.REACT_APP_MODE === 'OPS'),
            aToken: response.data.a_token,
            rToken: response.data.r_token,
            tokenExpire: response.data.token_expire*1000,
            isPasswordExpired: response.data.is_password_expired,
            lastLoginTimestamp: response.data.last_login_timestamp,
            passwordInitialized: response.data.password_initialized,
            lackOfAdminUserInfo: response.data.is_admin ? true : false, //로그인 시 1회만 작동하기 위해 true로 설정 (1회 작동 후 false로 변경 예정)
            multipleAccessRestriction: response.data.multiple_access_restriction
          })
          break
        case LOGIN_RESULT.INVALID_PW: //dev
          result.failedPasswordAttempts = response.data.data && response.data.data !== null ? response.data.data.failed_password_attempts : null
          break
        case LOGIN_RESULT.NOT_AUTHORIZED: //dev, ops
          result.data = response.data.data && response.data.data !== null ? camelcaseKeys(response.data.data, {deep:true}) : null
          break
        case LOGIN_RESULT.INVAILD_INFO: //ops, dev(only admin)
        case LOGIN_RESULT.INVALID_ID: //dev
        case LOGIN_RESULT.ACCOUNT_LOCKOUT: //dev
        default:
          // do nothing
      }
      return result
    } catch(error:any) {
      console.log('User.login error', error)
      return {status:LOGIN_RESULT.INVAILD_INFO}
    }
  }

  async function logout(opsFlag?:boolean) {
    navigate('/logout')
    await apiController.clearAuth()
    resetAuthInfo()
    window.location.href = '/'
  }

  async function checkUserId(userId:string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/users/check-id',{
        user_id: userId
      })
      switch(response.status) {
        case 200:
          return response.data.is_exist
        default:
          console.log('User.checkUserId error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function join(data:IUserDetail) {
    try {
      const apiController = ApiController.getInstance()
      // console.log('join,data>',data)
      const response = await apiController.post('/users',snakecaseKeys(data, { deep: true }))
      switch(response.status) {
        case 201:
          return response.data
        default:
          console.log('User.join error>', response)
          break
      }
    } catch (error:any) {
      // console.log('join Error>', error)
      switch (error.response.status) {
        case 409:
          throw new Error(ERROR_TYPE.DUPLICATED)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  async function getMyInfo(userNo:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/users/me',{
        user_no: userNo
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep:true})
        default:
          console.log('User.getMyInfo error>', response)
          break
      }
    } catch (error:any) {
      switch (error.response.status) {
        case 404:
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  async function putMyInfo(data:IUserDetail) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.put('/users', snakecaseKeys(data, {deep: true}))
      switch(response.status) {
        case 200 :
          setUserInfo({
            userNo: prevUserInfo.userNo,
            userId: prevUserInfo.userId,
            pw: null, //data.pw ? data.pw : prevUserInfo.pw,
            name: data.name? data.name : prevUserInfo.name,
            phoneNumber: data.phoneNumber !== undefined && data.phoneNumber !== null? data.phoneNumber : prevUserInfo.phoneNumber,
            email: data.email? data.email : prevUserInfo.email,
            department: data.department !== undefined && data.department !== null? data.department : prevUserInfo.department,
            additionalInfo: data.additionalInfo? data.additionalInfo : prevUserInfo.additionalInfo,
            permissionStatus: prevUserInfo.permissionStatus,
            creationTimestamp: prevUserInfo.creationTimestamp,
            resourceLimit: prevUserInfo.resourceLimit,
            wsCount: prevUserInfo.wsCount,
            startTimestamp: prevUserInfo.startTimestamp,
            // passwordInitialized:response.,
            // isDormacy:,
            // isPasswordExpired:
          })
          return camelcaseKeys(response, {deep: true})
      }
    } catch (error:any) {
      switch (error.response.status) {
        case 401:
          throw new Error(ERROR_TYPE.WRONGPW)
        case 409:
          throw new Error(ERROR_TYPE.DUPLICATED)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }  
    }

  }

  async function checkPw(id:string, pw:string) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.post('/users/check-pw', {
        id: id,
        pw: pw,
      })
      switch (response.status) {
        case LOGIN_RESULT.OKAY:
          return camelcaseKeys(response, { deep: true })
        case LOGIN_RESULT.INVAILD_INFO:
        default:
          console.log('User.checkPw error>', response)
          break
      }
    } catch (error: any) {
      switch (error.response.status) {
        case 400:
          throw new Error(ERROR_TYPE.WRONGPW)
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }  
    }
  }

  async function pwEditPass(userNo:number) {
    //60일경과후에도 비밀번호 변경 안하겠다는 사용자
    try{
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/users/reset-pw-expiry',{
        user_no:userNo
      })
      switch (response.status) {
        case 200:
          return response.status
        default:
          console.log('User.pwEditPass error>', response)
          break
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function getList(page:number=1, size:number=50, orderBy:USER_LIST_ORDER_BY, sortOrder:SORT_ORDER) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/users', {
        page: page,
        size: size,
        sort_by: sortOrder,
        order_by: orderBy
      })
      switch(response.status) {
        case 200:
          // return {
          //   list: camelcaseKeys(response.data.user_list,{deep:true}),
          //   total: response.data.total
          // }
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('User.getList error>', response)
          break
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function deleteUser(userArray:any[]) {
    try {
      // console.log('deleteUser', userArray, {
      //   user_no_list: userArray
      // })
      const apiController = ApiController.getInstance()
      const response = await apiController.del('/users',userArray)
  
      switch(response.status) {
        case 200:
          return response.data
        default:
          console.log('User.deleteUser error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function userResourceLimit(user_no:number, data:IResourceLimit) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.post('/users/limit-resource', {
        user_no,
        is_gpu_resource_blocks_enabled: data.isGpuResourceBlocksEnabled,
        total_gpu_resource_blocks: data.totalGpuResourceBlocks,
        is_workspace_auto_reclaim_enabled: data.isWorkspaceAutoReclaimEnabled,
        workspace_auto_reclaim_hours: data.workspaceAutoReclaimHours,
        is_admin_approval: data.isAdminApproval
      })
  
      switch(response.status) {
        case 200:
          return response
        case 422:
          console.log('User.userResourceLimit validate error>',response)
          return response
        default:
          console.log('User.userResourceLimit error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function resetPassword(data:IBaseDataType) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.put('/users/reset', {
        user_id: data.userId,
        user_no: data.userNo
      })

      switch(response.status) {
        case 200:
          return response.data
        default:
          console.log('User.resetPassword error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function updateUser(userNo:number, status:boolean) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/users', {
        user_no: userNo,
        is_confirm: status
      })
  
      switch(response.status) {
        case 200:
          return response.data
        default:
          console.log('User.updateUser error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function getUserListPermitted (orderBy:USER_LIST_ORDER_BY, sortOrder:SORT_ORDER, permission:true) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/users', {
        order_by: orderBy,
        sort_by: sortOrder,
        permission: permission
      })

      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('User.getUserListPermitted error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  async function resetDormancy (userNo:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/users/reset-dormancy', {
        user_no: userNo
      })

      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('User.resetDormancy error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
  
  /*async function getConfig(additionalHeader?:object):Promise<AxiosRequestConfig> {
    const now = new Date().getTime()
    const tokenExpire = authInfo.id !== '' ? now + 10 : authInfo.tokenExpire
    const result:AxiosRequestConfig = { 
      withCredentials: false,
      headers: {
        ...additionalHeader
      }
    }

    if (now > tokenExpire) {
      let refreshStatus:number = await _updateToken()
      if (refreshStatus !== 200) {
        return result
      }
    }
    if (authInfo) {
      result.headers = {
      }
      return result
    } else {
      return result
    }
  }*/
}

