import React, { Component } from 'react'
import styled from 'styled-components'

interface IPagenationProps {
  defaultPage:number
  totalArticle:number
  perPage:number
  onChange?:Function
}

interface IPagenationState {
  currentPage:number,
  totalPage:number,
  pageNumberArray:number[]
}

class Pagenation extends Component<IPagenationProps, IPagenationState> {
  public state:IPagenationState = {
    currentPage: this.props.defaultPage,
    totalPage: Math.ceil(this.props.totalArticle/this.props.perPage),
    pageNumberArray: []
  }

  /*constructor(props:any) {
    super(props)
  }*/
  
  componentDidMount() {
    this.reset()
  }

  componentDidUpdate(prev:any) {
    if (prev.totalArticle !== this.props.totalArticle) {
      this.setState({
        totalPage:Math.ceil(this.props.totalArticle/this.props.perPage)
      }, () => {
        this.goPageHandler(1)
      })
    }
  }
  
  private goPageHandler(target:number) {
    this.setState({
      currentPage:target
    }, () => {
      this.reset()
      if (this.props.onChange) this.props.onChange(target)
    })
  }

  private reset() {
    const START_IDX = 1+(10*(Math.ceil(this.state.currentPage/10)-1))
    const END_IDX = Math.min(START_IDX+9, this.state.totalPage)
    let newPageNumberArray:number[] = []

    for (let idx:number = START_IDX; idx <= END_IDX; idx++) {
      newPageNumberArray.push(idx)
    }
    this.setState({
      pageNumberArray: newPageNumberArray
    })
  }

  public render():React.ReactElement {
    return (
      <PagenationFragment>
        {
          this.state.totalPage > 10 && this.state.pageNumberArray[0] > 1 ? <>
            <button onClick={() => this.goPageHandler(1)} className="btnNav">1</button>
            <button onClick={() => this.goPageHandler(this.state.pageNumberArray[0]-1)} className="btnNav">&lt;</button>
          </> : false
        }
        {
          this.state.pageNumberArray.map((value:number) => {
            return (<button key={value} onClick={() => this.goPageHandler(value)} className={"btnNo "+(value === this.state.currentPage ? 'current' : '')}>{value}</button>)
          })
        }
        {
          this.state.totalPage > 10 && this.state.pageNumberArray[this.state.pageNumberArray.length-1] < this.state.totalPage ? <>
            <button onClick={() => this.goPageHandler(this.state.pageNumberArray[this.state.pageNumberArray.length-1]+1)} className="btnNav">&gt;</button>
            <button onClick={() => this.goPageHandler(this.state.totalPage)} className="btnNav">{this.state.totalPage}</button>
          </> : false
        }
      </PagenationFragment>
    )
  }
}

const PagenationFragment = styled.div`
  display: flex;align-items: center;justify-content: center;margin-top: 60px;
  button{width: 35px;height: 35px;cursor: pointer;}
  .btnNav{font-weight: bold;}
  .btnNo{font-size: 14px;color: #616161;}
  .btnNo:hover{background: #F5F5F5;}
  .btnNo.current{background: #f1f6fc;font-weight: bold;}
`

export default Pagenation