import React, { Component, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// import { IUserInfo } from '../../../../model/Services'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../../../Config"
import Utils from '../../../../utils'

export interface IUserInfo {
  department: string
  email: string
  phoneNumber: string
  userId: string
  name: string
}

interface IUserInfoPopupProps {
  data:IUserInfo
}
interface IUserInfoPopupState {
  isOpen:boolean
}

const UserInfoPopup = (props:IUserInfoPopupProps) => {
  const [ state, setState ] = useState<IUserInfoPopupState>({
    isOpen: false
  })
  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return() => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
        closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen:!state.isOpen })
  }

  return (
    <BlockPopupFragment>
      <div className={"normalWrap"}>
        <button onClick={onBtnClick} ref={modalBtnRef}>
          {state.isOpen && state.isOpen === true ?
            <img src='/images/buttonMoreRounded.svg' alt='more' className='moreIconButton' />
            : <img src='/images/buttonMoreRounded_1.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      {
        state.isOpen && state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className="commonModal">
              <p className="modalTitle">운영자 정보</p>
              <div className="content">
                이름 :&nbsp;
                <div>{props.data.name ? <EllipsisToolTip options={Config.ellipsisTipOptions}>{props.data.name}</EllipsisToolTip> : '-'}</div>
              </div>
              <div className="content bold">
                아이디 :&nbsp;
                <div>{props.data.userId ? <EllipsisToolTip options={Config.ellipsisTipOptions}>{props.data.userId}</EllipsisToolTip> : '-'}</div>
              </div>
              <div className="content">
                부서 :&nbsp;
                <div>{props.data.department ? <EllipsisToolTip options={Config.ellipsisTipOptions}>{props.data.department}</EllipsisToolTip> : '-'}</div>
              </div>
              <div className="content">
                연락처 :&nbsp;
                <div>{props.data.phoneNumber ? <EllipsisToolTip options={Config.ellipsisTipOptions}>{Utils.parseTelNo(props.data.phoneNumber)}</EllipsisToolTip> : '-'}</div>
              </div>
              <div className="content">
                이메일 :&nbsp;
                <div>{props.data.email ? <EllipsisToolTip options={Config.ellipsisTipOptions}>{props.data.email}</EllipsisToolTip> : '-'}</div>
              </div>
            </div>
          </div>
          : false
      }
    </BlockPopupFragment>
  )
}

const BlockPopupFragment = styled.div`
.subModalPopupWrap {position:relative; align-items:center; width:0px !important; height:0px; font-size:14px;}
//.subModalPopupWrap .commonModal {position:absolute;width:400px;max-height:250px;overflow-y:auto;flex-direction:column;box-sizing:border-box;padding:5px 15px 5px 15px;border-radius: 2px;box-shadow: 3px 3px 3px 0px #DFDFDF;border: solid 1px #DFDFDF;background:#fff;}
.subModalPopupWrap .commonModal {position:absolute;left:0;top:10px;width:300px; flex-direction:column;box-sizing:border-box;padding:30px;border-radius: 2px;box-shadow: 3px 3px 3px 0px #DFDFDF;border: solid 1px #DFDFDF;background:#fff;z-index:997;}
button {margin-top:4px;}
.subModalPopupWrap img.moreButton { width:17px;height:18px;vertical-align:bottom; padding-top:4px;}
.subModalPopupWrap .commonModal .btnClose{position:absolute;right:0;top:0;width:50px;height:50px;background:url(/images/ico-close2.png) no-repeat center / 14px;}
.subModalPopupWrap .commonModal p {font-size:14px;line-height:16px; text-align: left;}
.subModalPopupWrap .commonModal .modalTitle{margin-bottom:10px;font-weight:bold;}
.subModalPopupWrap .commonModal .content{display:flex;}
.subModalPopupWrap .commonModal .content>div {width:190px;}
.subModalPopupWrap .commonModal .content + .content {margin-top:5px;}
.subModalPopupWrap .commonModal .content.bold {font-weight:bold;}
`

export default UserInfoPopup