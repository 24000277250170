import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'

export enum TOGGLE_SIZE {
  LARGE = 'normal',
  SMALL = 'small'
}

interface IToggleProps {
  id:string
  checked:boolean
  size?:TOGGLE_SIZE
  disabled?:boolean
  onChange:(checked:boolean) => void
}

interface IToggleState {
  checked:boolean
}

const defaultProps = {
  size: TOGGLE_SIZE.LARGE,
}

const Toggle = forwardRef<any, IToggleProps>((props:IToggleProps, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return state.checked
      },
      validate: () => {
        return true
      },
      getErrorMessage: () => {
        return ''
      }
    }
  })
  const [state , setState] = useState<IToggleState>({
    checked: props.checked || false
  })

  const prevState = Utils.usePrevState(state)

  useEffect(() => {
    if (prevState.checked !== props.checked) {
      setState({
        ...state,
        checked: props.checked
      })
    } else if (prevState.checked !== state.checked) {

    }
  }, [])

  useEffect(() => {
    if (props.checked !== state.checked) {
      setState({
        ...state,
        checked: props.checked
      })
    }
  }, [props.checked])

  return (
    <ToggleFragment>
      <input className="hide" type="checkbox" id={props.id} checked={state.checked} disabled={props.disabled} onChange={() => {
        setState({
          ...state,
          checked: !state.checked
        })
        props.onChange(!state.checked)
      }}/>
      <label htmlFor={props.id} className={props.size + (state.checked ? ' on' : '')}>
        <span className="dot"/>
      </label>
    </ToggleFragment>
  )
})

Toggle.defaultProps = defaultProps

const ToggleFragment = styled.div`
  position:relative;
  label { display:block; position:relative; width:56px; height:28px; background:#EAEAEA; border-radius:14px; box-sizing:border-box; }
  input:disabled + label{background: #F5F5F5;}
  label .dot { position:absolute; left:4px; top:4px; width:20px; height:20px; background:#fff; border-radius:50%; transition:all 0.3s; }
  label.on { background:#FFDB20; }
  label.on .dot { left:32px; }
  label.small{width:40px;height: 20px;}
  label.small .dot{width: 12px;height:12px;}
  label.small.on .dot{left:24px;}
`
export default Toggle