import { useEffect, useRef, useState } from "react"
import PopupController from "../../../controller/PopupController"
import styled from "styled-components"
import { IWorkspaceListItem, RESOURCE_TYPE, WORKSPACE_LIST_ORDER_BY, WORKSPACE_STATUS, Workspace } from "../../../model/Workspace"
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from "../ui/Table"
import { IBaseDataType, SORT_ORDER } from "../../../model/BaseDataType"
import TableEvent from "../../../events/TableEvent"
import { useRecoilState } from "recoil"
import { authState } from "../../../states/authStates"
import EllipsisToolTip from "ellipsis-tooltip-react-chan"
import Config from "../../../Config"
import UserInfoPopup from "./sub/UserInfoPopup"
import dayjs from "dayjs"
import BlockPopup from "./sub/BlockPopup"
import { IWorkspacePriorityInsertInfo } from "../../../model/Priority"
import ModalEvent from "../../../events/ModalEvent"



interface IWaitingQueuePopupProps {
  onClose:Function
  onSelected:Function
  resourceGroup:string
}

interface IWaitingQueuePopupState {
  selected:IBaseDataType
  tableItems:IWorkspaceListItem[]
}

const WaitingQueuePopup = (props: IWaitingQueuePopupProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const popupController = PopupController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [52, 156, 261, 163, 363]
  const headerLabel: ITableHeader[] = [
    { label: '계정 정보', key: 'tableUserInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false},
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: false},
    { label: '신청 시간', key: 'tableCreationTimestamp', type: TABLE_CELL_TYPE.STRING, sort: false}, 
    { label: '리소스 타입 / 개수', key: 'tableResourceType', type: TABLE_CELL_TYPE.HTML, sort: false }
  ]

  const [state, setState] = useState<IWaitingQueuePopupState>({
    selected: [], 
    tableItems: []
  })

  useEffect(() => {
    getData()
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  const eventControl = (e:Event) => {
    getData()
  }

  const getData = async () => {
    try {
      const data = await Workspace.getListAdmin(authInfo.userNo, WORKSPACE_LIST_ORDER_BY.CREATION_TIMESTAMP, SORT_ORDER.ASC, 1, 50, undefined, [props.resourceGroup], [WORKSPACE_STATUS.PRE_QUEUE], undefined)
      if(data) {
        for(let eachLine of data.workspaces) {
          // 운영자
          eachLine.tableUserInfo = [
            [(<div style={{ width: '190px' }}>
              <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.user.name ? eachLine.user.name : '-'}</EllipsisToolTip>
            </div>)],
            [(<div style={{ width: '190px' }}>
              <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.userId ? eachLine.userId : '-'}</EllipsisToolTip>
            </div>)],
            [eachLine.user.name && eachLine.userId ?
              (<div style={{ width:'190px', display:'flex', justifyContent:'start'}}>
                <UserInfoPopup data={eachLine.user} />
              </div>) : false
            ]
          ]
          // 신청 시간
          eachLine.tableCreationTimestamp = (typeof(eachLine.createdAt) === 'number' ? dayjs(eachLine.createdAt*1000).format('YYYY/MM/DD\nHH:mm:ss') : eachLine.createdAt)
          // 리소스 타입
          eachLine.tableResourceType = eachLine.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
            `${eachLine.resourceBlock.type}: <b>${eachLine.resourceBlock.gpuNum}개</b>` :
            `${eachLine.resourceBlock.type}, CPU: <b>${eachLine.resourceBlock.cpu}코어</b>, MEM: <b>${eachLine.resourceBlock.mem}${eachLine.resourceBlock.memUnit}</b>`
        }

        setState({
          ...state,
          tableItems: data.workspaces,
        })
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - f3bc6c')
    }
  }

  const onSelectHandler = (item:IBaseDataType) => {
    setState({
      ...state,
      selected: item
    })
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = async () => {
    let selectedItems:IWorkspacePriorityInsertInfo[] = []
    for(let idx in state.selected) {
      let item = state.tableItems[state.selected[idx]]
      selectedItems.push({
        id: Number(item.id),
        name: item.name
      })
    }
    // this.closeHandler()
    if (props.onSelected) props.onSelected(selectedItems)
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'insertPriority':
        closeHandler()
        break
    }
  }

  return (
    <WaitingQueuePopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
        <h2 className="pageTitle">
          승인 대기 중 워크스페이스 목록<span className="total"><b>{state.tableItems.length}</b>개</span>
        </h2>
        <div className="scrollWrap">
          <Table ref={tableRef}
            colWidth={tableColWidth}
            headerLabel={headerLabel}
            items={state.tableItems}
            noData="승인 대기 중인 워크스페이스가 없습니다."
            checkBoxType={CHECKBOX_TYPE.USE_ALL}
            defaultSortOrder={SORT_ORDER.ASC}
            onSelect={onSelectHandler}
          />
        </div>
        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          {state.selected.length > 0 ?
            <button className="btn blue" onClick={submitHandler}>대기열에 추가하기</button> :
            <button className="btn off">대기열에 추가하기</button>
          }
        </div>
    </WaitingQueuePopupFragment>
  )
}

const WaitingQueuePopupFragment = styled.div`
  display:flex; flex-direction:column; width:1100px; height:calc(100vh - 186px); max-height:930px; padding: 48px 50px 20px 50px; box-sizing:border-box;
  .pageTitle { flex:none; margin-bottom:50px; }
  .scrollWrap { overflow:auto; flex:1; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:20px; }
  .btnWrap .btn { width:253.12px; height:40px; line-height:33px; }
  table td.col2, table td.col3{text-align:center;}
`

export default WaitingQueuePopup