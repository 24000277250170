import { useEffect, useState } from 'react'
import styled from 'styled-components'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../../Config"

import CheckBox from './CheckBox'
import { IResourceUnit } from "../../../model/Workspace"
import utils from '../../../utils'

/*
  리소스 블록(ResourceBlock)이라는 말 보다는 리소스 단위(ResourceUnit)라는 표현 방식으로 바꾸면 논리상 맞을 것 같습니다.
  ex) enum ResourceBlockType -> ResourceUnitType
*/


export enum ResourceUnitType { //ex) ResourceBlockType -> ResourceUnitType
  BLOCKTYPE_DEV = 'blockTypeDev', //dev gpu  //ex) blockTypeDev -> UnitTypeDev
  BLOCKTYPE_OPS = 'blockTypeOps', //ops gpu
  BLOCKTYPE_CPU = 'blockTypeCpu' //dev cpu
}

interface IResourceUnitProps {
  selected:boolean
  index:number
  data:IResourceUnit
  onSelect?:Function
  type?:ResourceUnitType
}

interface IResourceUnitState {
  id:number
  blockName:string
  blockCount:number
  blockCountRemain:number
  gpuName:string
  cpuData:string
  ramData:string
  gpuPer:string
  gpuMemory:string
  selected:boolean
  type:ResourceUnitType
}

const ResourceUnit = (props:IResourceUnitProps) => {
  const [ state, setState ] = useState<IResourceUnitState>({
    id: props.data.resourceBlockSpec.id,
    blockName: props.data.resourceBlockSpec.type,
    blockCount: props.data.numBlock,
    blockCountRemain: props.data.freeNumBlock || 0,
    gpuName: props.data.resourceBlockSpec.type,
    cpuData: String(props.data.resourceBlockSpec.cpu),
    ramData: props.data.resourceBlockSpec.mem+props.data.resourceBlockSpec.memUnit,
    gpuPer: props.data.resourceBlockSpec.gpuPer+'%',
    gpuMemory: props.data.resourceBlockSpec.gpuMem+props.data.resourceBlockSpec.gpuMemUnit,
    selected: (props.selected ? props.selected: false),
    type: (props.type? props.type: ResourceUnitType.BLOCKTYPE_DEV)
  })

  const prevState = utils.usePrevState(state)

  useEffect(() => {
    if(props.type){
      setState({
        ...state,
        type : props.type
      })
    }
  }, [])

  useEffect(() => {
    if (prevState.selected !== props.selected) {
      setState({
        ...state,
        selected: props.selected
      })
    }
    if (prevState.data !== props.data) {
      setResourceUnitData()
    }
  }, [prevState.selected, props.selected, prevState.data, props.data])

  const setResourceUnitData = () => {
    setState({
      id: props.data.resourceBlockSpec.id,
      blockName: props.data.resourceBlockSpec.type,
      blockCount: props.data.totalNumBlock || 0,
      blockCountRemain: props.data.freeNumBlock || 0,
      gpuName: props.data.resourceBlockSpec.type,
      cpuData: props.data.resourceBlockSpec.cpu + ' 코어',
      ramData: props.data.resourceBlockSpec.mem + ' ' + props.data.resourceBlockSpec.memUnit,
      gpuPer: props.data.resourceBlockSpec.gpuPer + ' %',
      gpuMemory: props.data.resourceBlockSpec.gpuMem + ' ' + props.data.resourceBlockSpec.gpuMemUnit,
      selected: (props.selected ? props.selected: false),
      type: (props.type? props.type: ResourceUnitType.BLOCKTYPE_DEV)
    })
  }

  return (
    <ResourceUnitFragment>
      <div className="resourceBox">
        {
        <div className="inner">
          <div className={state.selected ? 'selected' : ''}>
            <div className="titleBox">
              {state.type === ResourceUnitType.BLOCKTYPE_CPU ?
                <>
                  <div className="titleBoxWrap">
                    <p className="boxTitle">CPU node {state.id}</p>
                    <CheckBox id={"selected" + props.index} checked={state.selected} onChange={(id: string, checked: boolean) => {
                      setState({ 
                        ...state,
                        selected: checked 
                      })
                      if (props.onSelect) props.onSelect(state.id, checked)
                    }} />
                  </div>
                  <div className="detail">
                    <div className='tooltipWrap' style={{ width: '260px' }}>
                      <EllipsisToolTip options={Config.ellipsisTipOptions}>{}</EllipsisToolTip>
                    </div>
                  </div>
                </>
                :
                <>
                  {state.type === ResourceUnitType.BLOCKTYPE_DEV ?
                    <>
                      <div className="titleBoxWrap">
                        <p className="boxTitle">블록타입{props.index+1} [총 {state.blockCount} 개]</p>
                        <CheckBox id={"selected" + props.index} checked={state.selected} onChange={(id: string, checked: boolean) => {
                          setState({ 
                            ...state,
                            selected: checked 
                          })
                          if (props.onSelect) props.onSelect(state.id, checked)
                        }} />
                      </div>
                      <div className="detail">
                        <div className='tooltipWrap' style={{ width: '190px' }}>
                          <EllipsisToolTip options={Config.ellipsisTipOptions}>{state.gpuName}</EllipsisToolTip>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ position: 'absolute', top: '0px', right: '-20px' }}>
                          <CheckBox id={"selected" + props.index} checked={state.selected} onChange={(id: string, checked: boolean) => {
                            setState({
                              ...state,
                              selected: checked 
                            })
                            if (props.onSelect) props.onSelect(state.id, checked)
                          }} />
                        </div>
                      </div>
                      <div className="titleBoxWrapRow">
                        <p className="boxTitle"> [잔여 {state.blockCountRemain}개 / 총 {state.blockCount}개]</p>
                        <div className="detail">
                          <div className='blockTypeWrap'>
                            타입 {state.id}: {state.gpuName}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
              }
            </div>
            <div className={state.type === ResourceUnitType.BLOCKTYPE_OPS? "contentBoxOps" : "contentBox"}>
              <p className="dl">
                <span className="dt">CPU: </span>
                <span className="dd">{state.type === ResourceUnitType.BLOCKTYPE_CPU ? '최대 ' : false}{state.cpuData}</span>
              </p>
              <p className="dl">
                <span className="dt">MEM: </span>
                <span className="dd">{state.type === ResourceUnitType.BLOCKTYPE_CPU ? '최대 ' : false}{state.ramData}</span>
              </p>
              <p className="dl">
                <span className="dt">GPU: </span>
                <span className="dd">{state.type === ResourceUnitType.BLOCKTYPE_CPU ? '-' : state.gpuPer}</span>
              </p>
              <p className="dl">
                <span className="dt">GPU mem: </span>
                <span className="dd">{state.type === ResourceUnitType.BLOCKTYPE_CPU ? '-' : state.gpuMemory}</span>
              </p>
            </div>
          </div>
        </div>
        }
      </div>
    </ResourceUnitFragment>
  )
}

const ResourceUnitFragment = styled.div`
  .resourceBox{color:#303030;font-size: 0}
  .resourceBox .inner{display:inline-block; width:220px; box-sizing:border-box;}
  .resourceBox .inner > div{border:1px solid #dfdfdf;}
  .resourceBox .titleBox{ padding:10px 15px 10px;border-bottom:1px solid #dfdfdf;}
  .resourceBox .titleBox .boxTitle {font-size:14px}
  .resourceBox .contentBox{padding:10px 15px; line-height:14px; min-height:86px;}
  .resourceBox .contentBoxOps{padding:10px 15px; line-height:14px;}
  .resourceBox .detail{font-size:14px;}
  .resourceBox .dl{align-items:center;justify-content:space-between;font-size:14px;}
  .resourceBox .contentBox .dl + .dl{margin-top:10px}
  .resourceBox .contentBoxOps .dl + .dl{margin-top:5px;}
  .resourceBox .boxTitle,
  .resourceBox .dt,
  .resourceBox b{font-weight:normal;}
  .resourceBox b span{color:#2c78ff;}
  /* .resourceBox + .resourceBox {margin-left: 5px;} */
  .resourceBox .inner .selected {background-color: #E1F5FF;}
  .resourceBox .dl .dd {word-break:break-all;}

  .titleBoxWrap {display:flex; justify-content:space-between;}
  .titleBoxWrapRow {flex-direction:row; justify-content:space-between; gap:7px;}
  .titleBoxWrap label, .titleBoxWrapRow label {padding-right:0px;}

  .blockTypeWrap {width: 180px; height:34px; font-weight:600; margin-top:7px; overflow:hidden; white-space: pre-wrap;}
`

export default ResourceUnit