import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Table ,{ CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE} from "../ui/Table"
import TableEvent from "../../../events/TableEvent"
import { SORT_ORDER } from "../../../model/BaseDataType"
import { NavigateFunction } from 'react-router-dom'
import { IUserDetailGroup, USER_LIST_ORDER_BY, User } from "../../../model/User"
import { IResourceUseradd } from "../../../model/ResourceGroup"
import PopupController from '../../../controller/PopupController'


interface IUserListPopupProps {
  navigate?:NavigateFunction
  onClose:Function
  onSelected:Function
  groupName:string
  preSelectedUser:IUserDetailGroup[]
}

interface IUserListPopupState {
  tableItems:IUserDetailGroup[]
  page:number //임시
  totalArticle:number
}

interface IPreSelectedState {
  selectedItems:IUserDetailGroup[]
  preSelected:number[]
}

const UserListPopup = (props:IUserListPopupProps) => {
  const tableRef = useRef<any>()
  const tableColWidth = [52, 150, 140, 160, 258]
  const headerLabel: ITableHeader[] = [
    { label: '아이디', key: 'userId', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'userId' },
    { label: '이름', key: 'name', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.ASC, sortKey: 'name' },
    { label: '부서', key: 'department', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'department', default: '-'}, 
    { label: '이메일', key: 'email', type: TABLE_CELL_TYPE.STRING, sort: false }
  ]

  const popupController = PopupController.getInstance()

  const [state, _setState] = useState<IUserListPopupState>({
    tableItems: [],
    totalArticle: 0,
    page: 1
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data) 
  }

  const [prestate, setPreState] = useState<IPreSelectedState>({
    selectedItems: [],
    preSelected: []
  })

  const user = User()

  const [tableItems, setTableItems] = useState<IUserDetailGroup[]>([])

  useEffect(() => {
    getData()
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    }
  }, [])

  useEffect(() => {
    let selectedIdx: number[] = []
    let tableuserNo:number[] = []
    let preuserNo:number[] = []
    if(props.preSelectedUser) {
      for (let idx in tableItems) {
        tableuserNo.push(tableItems[idx].userNo)
      }
      for (let idx in props.preSelectedUser) {
        preuserNo.push((props.preSelectedUser[idx] as any).userNo)
      }
      tableuserNo.map((item,index) =>{
        if (tableuserNo.filter(x => preuserNo.includes(x)).includes(item)) {
          selectedIdx.push(index)
        }
      })
      tableRef.current?.setSelected(selectedIdx)
    }
  },[tableItems])

  const selectBoxCheckHandler = (selectedIdx: IUserDetailGroup[]): void => {
    if (tableItems && selectedIdx && tableItems.length > 0) {
      let selectedArray: IUserDetailGroup[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(tableItems[Number(selectedIdx[idx])])
      }
      setPreState({
        ...prestate,
        selectedItems: selectedArray,
      })
    } else {
      setPreState({
        ...prestate,
        selectedItems: []
      })
    }
  }

  const eventControl = (e:Event) => {
    getData()
  }

  const getData = async () => {
    try {
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      let orderBy

      switch (tableRef.current?.sortKey) {
        case 'name':
        default:
          orderBy = USER_LIST_ORDER_BY.NAME
          break
        case 'userId':
          orderBy = USER_LIST_ORDER_BY.ID
          break
        case 'department':
          orderBy = USER_LIST_ORDER_BY.DEPARTMENT
          break
      }

      const data = await user.getUserListPermitted(orderBy, sortOrder, true)

      if (data) {

        if(data.error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 4412a6')
        }

        let selectedNo:number[] = []
        let selectedIdx:any[] = []

        if (props.preSelectedUser) {
          for (let eachSelect of props.preSelectedUser) {
            selectedNo.push((eachSelect as any).userNo)
          }
        }
        for (let i = 0; i < data.userList.length; i++) {
          let userInfo = data.userList[i]
          if (selectedNo.indexOf(userInfo.userNo) != -1) {
            selectedIdx.push(i)
            userInfo.isPreselected = true
            userInfo.preSelectedFlag = true
          } else {
            userInfo.isPreselected = false
            userInfo.preSelectedFlag = false
          }
          for (let info in userInfo) {
            if (userInfo[info] === null) {
              userInfo[info] = ''
            }
          }
        }

        setState({
          ...state,
          totalArticle: data.total ? data.total : 0,
        })

        setTableItems(data.userList)

        setPreState({
          ...prestate,
          preSelected: selectedIdx.concat(),
          selectedItems: selectedIdx
        })
        /* props.preSelectedUser = selectedIdx */
        tableRef.current?.setSelected(selectedIdx)
      } else {
        setTableItems([])
      }
    } catch(err) {
      console.log(err)
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = async () => {
    const userNoList = []
    const userNameList = []
    const userIdList = []
    const removeUserNo = []
    let userIdlist:string[] = []
    let userNamelist:string[] = []
    let preuserId:string[] = []
    let preuserName:string[] = []
    for(let idx in props.preSelectedUser) {
      preuserName.push(props.preSelectedUser[idx].name)
      preuserId.push(props.preSelectedUser[idx].userId)
    }

    for (let idx in prestate.selectedItems) {
      let indexNumber = prestate.selectedItems[idx].userNo
      let userName = prestate.selectedItems[idx].name
      let userId = prestate.selectedItems[idx].userId
      userNamelist.push(userName)
      userIdlist.push(userId)
      if (prestate.preSelected.indexOf(indexNumber) == -1) {
        userNoList.push(indexNumber)
        userNameList.push(userName)
        userIdList.push(userId)
      } else {
        removeUserNo.push(indexNumber)
        userNameList.push(userName)
        userIdList.push(userId)
      }
    }
    let allnewId = userIdlist.filter(x => !preuserId.includes(x))
    let allnewName = userNamelist.filter(x => !preuserName.includes(x))

    //console.log(allnewName,allnewId)
    let payload:IResourceUseradd = {
      userList: userNoList,
      userNameList: userNameList,
      userIdList: userIdList, 
      isDelete: false,
      newUser: {
        firstuserId: allnewId[0],
        firstuserName: allnewName[0],
        totalUser: allnewName.length
      }
    }
    let e:TableEvent = new TableEvent(TableEvent.GROUP_ADDED_USER)
    e.payload = {
      userList: userNoList,
      isDelete: false,
      groupName: props.groupName,
      firstselectedUser: prestate.selectedItems[0]
    }
    window.dispatchEvent(e)
    if (props.onSelected) props.onSelected(payload)
    closeHandler()
  }

  return (
    <UserListPopupFragment>
      <button onClick={closeHandler} className="btnClose">닫기</button>
      <h2 className="pageTitle">
        사용자 목록<span className="total"><b>{state.totalArticle}</b> 명</span>
      </h2>
      <div className="scrollWrap">
        <Table ref={tableRef}
          colWidth={tableColWidth}
          headerLabel={headerLabel}
          items={tableItems}
          name="userList"
          checkBoxType={CHECKBOX_TYPE.USE_ALL}
          preselectedKey={'isPreselected'}
          checkBoxPreserveKeys={['preSelectedFlag']}
          noData="사용자 정보가 없습니다."
          defaultSortOrder={SORT_ORDER.ASC}
          onSelect={selectBoxCheckHandler}
        />
      </div>
      <div className="btnWrap">
        <button className="btn grey" onClick={closeHandler}>취소</button>
        {prestate.selectedItems.length > 0 ?
          <button className="btn blue" onClick={submitHandler}> {(prestate.selectedItems.length - prestate.preSelected.length) > 0 ? (prestate.selectedItems.length - prestate.preSelected.length) + ' 개' : false} 가져오기</button> :
          <button className="btn off">가져오기</button>
        }
      </div>
    </UserListPopupFragment>
  )
}

const UserListPopupFragment = styled.div`
  display:flex; flex-direction:column; width:860px; height:800px; padding: 50px 50px 20px; box-sizing:border-box;
  .pageTitle { flex:none; margin-bottom:0px; }
  .scrollWrap { overflow:auto; flex:1; margin-top:20px; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:15px; }
  .btnWrap .btn { width:188px; height:40px; line-height:33px; }
  //.noData {text-align:center;color:#A7A7A7;font-size:20px; display:flex; justify-content:center; align-items:center;height:100%; margin-top:-30px;}
  tbody tr.preSelectedFlag td { color:#a7a7a7; }
`

export default UserListPopup