import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'

interface IToggleProps {
  id:string
  checked:boolean
  disabled?:boolean
  onChange:(checked:boolean) => void
}

interface IToggleState {
  checked:boolean
}

const Toggle = (props:IToggleProps) => {
  const [state , setState] = useState<IToggleState>({
    checked: props.checked || false
  })

  const prevState = Utils.usePrevState(state)

  useEffect(() => {
    if(prevState.checked !== props.checked) {
      setState({
        ...state,
        checked: props.checked
      })
    }else if (prevState.checked !== state.checked) {

    }
  },[])

  return (
    <ToggleFragment>
      <input className="hide" type="checkbox" id={props.id} checked={state.checked} onChange={() => {
        setState({
          ...state,
          checked: !state.checked
        })
        props.onChange(!state.checked)
      }}/>
      <label htmlFor={props.id} className={state.checked ? 'on' : ''}>
        <span className="dot"/>
        <span className="text">{state.checked ? 'ON' : 'OFF' }</span>
      </label>
    </ToggleFragment>
  )
}

const ToggleFragment = styled.div`
  position:relative;
  label { display:block; position:relative; width:63px; height:31px; background:#a7a7a7; border-radius:16px; box-sizing:border-box; }
  label .dot { position:absolute; left:3px; top:3px; width:25px; height:25px; background:#fff; border-radius:50%; transition:all 0.3s; }
  label .text { position:relative; top:6px; left:32px; font-size:12px; color:#fff; font-weight:bold; }
  label.on { background:#2c78ff; }
  label.on .dot { left:35px; }
  label.on .text { left:12px; }
`
export default Toggle