import { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavigationDevAdmin = () => {
  const [ depth2Open, setDepth2Open ] = useState<boolean>(false)

  const depth2Toggle = () => {
    setDepth2Open(!depth2Open)
  }
  
  const setClass = (url:string) => {
    let depth1Class = depth2Open ? 'depth1 open' : 'depth1'
    if (window.location.pathname === url) depth1Class += ' current'
    return depth1Class
  }
  return(
    <NavigationFragment>
      <nav>
        <h2 className="hide">관리자 메뉴</h2>
        <div className={setClass('/alert')}><NavLink to="/alert"><img src="/images-v2/lnb-noti.svg" alt="통합 알림 센터"/><span className='clickArea'>통합 알림 센터</span></NavLink></div>
        <div className={setClass('/monitoring')}><NavLink to="/monitoring"><img src="/images-v2/lnb-notes2.svg" alt="GPU 및 서버 모니터링"/><span className='clickArea'>GPU 및 서버 모니터링</span></NavLink></div>
        <div className={setClass('/users')}><NavLink to="/users"><img src="/images-v2/lnb-person.svg" alt="사용자 계정 관리"/><span className='clickArea'>사용자 계정 관리</span></NavLink></div>
      </nav>
    </NavigationFragment>
  )  
}

const NavigationFragment = styled.aside`
  flex:none; width:234px;border-right: 1px solid #E8E8EE; transition: all 0.3s;background: #fff;z-index:2;
  nav{ width:234px; margin-top:24px}
  nav a { display:flex; align-items:center; height:56px; color:#333; font-weight:500; transition:all 0.3s; font-size:14px; transition: width 0.3s;}
  nav .depth1{display:flex;justify-content: space-between;padding:0 16px 0 25px;cursor: pointer;}
  nav .depth1.open button img{transform: rotate(180deg);}
  nav .depth1 a{display:flex;align-items:center;gap:13px;}
  nav .depth1 button img{width:16px;transform: rotate(0);}
  nav a img{transition: all 0.3s;}
  nav .depth1:hover a,
  nav .depth2 a:hover{color:#4B82FF;}
  nav .depth1:hover img,
  nav .depth2 a:hover img,
  nav .depth1:hover img {filter: invert(43%) sepia(95%) saturate(2194%) hue-rotate(208deg) brightness(103%) contrast(105%);}
  nav .depth1.current {background: #4B82FF;color:#fff;}
  nav .depth1.current a{color:#fff;}
  nav .depth1.current img {filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
  nav a img {width:18px;}
  nav .depth2{padding-bottom:8px;background: #F7F8FB;}
  nav .depth2 a{gap:16px;padding:0 28px;transition: all 0.3s;}
  nav .depth2 a img{width:12px;height:12px;}
  .clickArea { width:176px; height:17px; padding:20px 0; }

  @media (max-width: 1671px) {
   overflow:hidden;width:48px;position: fixed;top:56px;left:0;bottom:0;
   :hover{width:234px;}
   nav .depth1{padding:0 15px;gap: 15px;}
   nav .depth1 a{font-size:0;}
   nav .depth2{overflow:hidden;height: 0;}
   nav .depth2 a{gap:16px;padding:0 16px;}
   :hover .depth2{height:initial;}
   :hover nav a{font-size:14px;} 
  }
`

export default NavigationDevAdmin