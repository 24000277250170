import styled from "styled-components"
import { IGpu } from "../../../model/Monitoring"
import EllipsisToolTip from "ellipsis-tooltip-react-chan"
import Tooltip from "../ui/Tooltip"
import { Link } from "react-router-dom"
import Config from "../../../Config"
import BarChart, { YAXIS_TYPE } from "../chart/BarChart"
import { useEffect, useState } from "react"
import LineChart from "../chart/LineChart"
import GPUInfoPopup from "../modalv2/sub/GPUInfoPopup"
import NodeTooltip, { INodeTooltip } from "./NodeTooltip"


export enum SERVER_STATUS {
  NORMAL = 0,
  CONCERN = 1,
  CRITICAL = 2
}
export enum MONITORING_TYPE {
  MASTER = 0,
  STORAGE = 1,
  WORKER = 2
}

export interface IGPUType {
  isParted:boolean
  gpuList:IGpu[]
  total:number
}

export interface INode {
  name:string
  nodeResource: {
    isRunning:boolean
    cpu:number
    gpu: {
      allocatedGpu:number[]
      totalGpu:number[]
      usedGpu:number[]
    }
    canMig:boolean
    isInitMig:boolean
    memory:number
    storage:number
  },
  gpuType:IGPUType
  statusTotal:SERVER_STATUS
  statusCpu:SERVER_STATUS
  statusStorage:SERVER_STATUS
  statusMemory:SERVER_STATUS
  isInitMig:boolean
  canMig:boolean
  isParted:boolean
  popupOpenFlag:boolean
}

export interface IStorage {
  name:string
  status:SERVER_STATUS
  storageRate:number
  url?:string
}

interface INodeProps {
  idx:number
  node:any //INode
  settingModeFlag:boolean
  title:string
  type:MONITORING_TYPE
  categories?:string[]
}

interface INodeState {
  status:SERVER_STATUS
  //tempList:IGpu[]
}

const Node = (props:INodeProps) => {
  const idx = props.idx
  const node = props.node
  const type = props.type

  const [ state, setState ] = useState<INodeState>({
    status:SERVER_STATUS.NORMAL,
    //tempList: []
  })

  useEffect(() => {
    // let list:IGpu[] = []
    // if(props.node.gpuType) {
    //   list = list.concat(props.node.gpuType.gpuList)
    //   list.push({idx:2, isBlock:false, isMig:true, name:'bonus', detail:[{name:'zzz', total:3}]})
    //   list.push({idx:3, isBlock:false, isMig:true, name:'bonus', detail:[{name:'zzz', total:3}]})
    // }

    setState({
      ...state,
      status: type === MONITORING_TYPE.STORAGE ? node.status : node.statusTotal,
      //tempList: list
    })
  }, [])

  const renderTooltipDes = (data:any, type:MONITORING_TYPE) : INodeTooltip[] => {
    let tooltip:INodeTooltip[] = []
    let des:string[] = []
    let node:INode, storage:IStorage
    switch(type){
      case MONITORING_TYPE.MASTER:
        node = data
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고
          if(node.nodeResource.isRunning === false){
            tooltip.push({status:SERVER_STATUS.CRITICAL, des: ['시스템 장애가 발생했습니다.\n'] })
          }
          else {
            //경고
            des = []
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des.push(`[CPU] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n`) }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des.push(`[Storage] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 신속하게 확보해 주세요.\n`) }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des.push(`[Memory] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n`) }
            if(des.length > 0){ tooltip.push({status:SERVER_STATUS.CRITICAL, des: des.concat()}) }
            
            des = []
            //주의
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des.push(`[CPU] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n`) }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des.push(`[Storage] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 확보해 주세요.\n`) }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des.push(`[Memory] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n`) }
            if(des.length > 0){ tooltip.push({status:SERVER_STATUS.CONCERN, des: des.concat()}) }
          }
        }
        break;
      case MONITORING_TYPE.WORKER:
        node = data
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고
          if(node.nodeResource.isRunning === false){
            tooltip.push({status:SERVER_STATUS.CRITICAL, des: ['시스템 장애가 발생했습니다.\n']})
          }
          else {
            //경고
            des = []
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des.push(`[CPU] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des.push(`[Storage] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des.push(`[Memory] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(des.length > 0){ tooltip.push({status:SERVER_STATUS.CRITICAL, des: des.concat()}) }

            //주의
            des = []
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des.push(`[CPU] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des.push(`[Storage] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des.push(`[Memory] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 서비스의 정상 사용이 불가할 수 있습니다.\n`) }
            if(des.length > 0){ tooltip.push({status:SERVER_STATUS.CONCERN, des: des.concat()}) }
          }
        }
        break;
      case MONITORING_TYPE.STORAGE:
        storage = data
        if(storage.status === SERVER_STATUS.CRITICAL){
          tooltip.push({status: SERVER_STATUS.CRITICAL, des: [`[Storage] 스토리지 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 신속하게 확보해 주세요.`]})
        }
        else if(storage.status === SERVER_STATUS.CONCERN){
          tooltip.push({status: SERVER_STATUS.CONCERN, des: [`[Storage] 스토리지 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 확보해 주세요`]})
        }
        break;
      default:
    }
    return tooltip
  }

  const renderTooltipImg = (status:SERVER_STATUS) => {
    let img:string = ''
    switch(status){
      case SERVER_STATUS.CRITICAL:
        img = 'alert-error.svg'
        break;
      case SERVER_STATUS.CONCERN:
        img = 'alert-warn.svg'
        break;
      case SERVER_STATUS.NORMAL:
      default:
        img = ''
    }
    return img
  }

  const getColorClass = (status:SERVER_STATUS):string => {
    let result:string = ''

    if (status === SERVER_STATUS.CRITICAL) {
      result = 'warn'
    } else if (status === SERVER_STATUS.CONCERN) {
      result = 'noti'
    } else {
      result = 'okay'
    }
    return result
  }


  return (
    <NodeFragment>
      {idx === 0 ? <p className="serverStyle">[{props.title}]</p> : false} {/* <p className="serverStyle"></p> */} 
      <div className={`server status${state.status}`}>
        <div className="titleWrap">
          <div className="title">
            {
              state.status === SERVER_STATUS.NORMAL ?
                false :
                <div style={{ marginRight: '6px' }}>
                  {/* <Tooltip des={renderTooltipDes(node, type)} width={14} img={renderTooltipImg(state.status)} /> */}
                  <NodeTooltip tooltip={renderTooltipDes(node, type)} img={renderTooltipImg(state.status)} />
                </div>
            }
            <span className="text">
              <>{`${idx + 1}.`}</>&nbsp;
              <div className="ellipsis">
                <EllipsisToolTip options={Config.ellipsisTipOptions2}>{`${node.name}`}</EllipsisToolTip>
              </div>
            </span>
            {node.nodeResource && node.nodeResource.isRunning ?
              <Link to={'/monitoring/' + node.name}><img src="/images-v2/more_button.svg" alt="" /></Link> :
              <div><img src="/images-v2/more_button_off.svg" alt="" /></div>}
          </div>
          {
            type !== MONITORING_TYPE.STORAGE ?
              <div className="type">
                {
                  (node.gpuType && node.gpuType.gpuList && node.gpuType.gpuList.length > 0) ? //node.gpuType.total - 분할 포함한 개수, node.gpuType.gpuList.length - 순수한 GPU> 개수
                    <> {/* GPU 노드 상태 */}
                      <div>
                        <div>총 {node.gpuType.gpuList.length}개</div>
                        <div className="gpuType" style={{ width: '200px' }}>
                          <EllipsisToolTip options={Config.ellipsisTipOptions2}>{node.gpuType.gpuList[0].name}</EllipsisToolTip>
                        </div>
                      </div>
                      <div>
                        {node.gpuType.gpuList.length > 0 ?
                          <GPUInfoPopup isParted={node.gpuType.isParted} count={node.gpuType.isParted ? node.gpuType.total : node.gpuType.gpuList.length} data={node.gpuType.gpuList} />
                          : false}
                      </div>
                    </> :
                    <>
                      <div>
                        <div>총 0개</div>
                        <div className="gpuType">
                          {node.nodeResource && node.nodeResource.isRunning === false ? 
                          // 장애 발생 상태
                          <>-</>
                          // CPU 노드 상태
                           : false}
                        </div>
                      </div>
                      <div></div>
                    </>
                }
              </div>
              : false
          }

        </div>
        <div className="content">
          {type !== MONITORING_TYPE.STORAGE ? //마스터노드, 워커노드일 때
            <>
              {node.nodeResource.isRunning === true ? //시스템 장애 없을 때
                <>
                  {node.nodeResource.isInitMig ? // mig 설정 중일 때는 gpuType을 받아올 수 없음!
                    <div className="settingsOverlay">
                      <p className="message">MIG 설정 중입니다.<br />수 초에서 수 분 이상 소요될 수 있습니다.</p>
                      {/* <LoadingAnimation /> */}
                    </div>
                    : // mig 설정 중이 아닐 때
                    <>
                      {props.settingModeFlag ? // [모드] 서버 설정 모드일 때,
                        <>
                          <div className="settingsOverlay">
                            <div className="buttonArea">
                              <Link className="btn outline" to={"/workspace?node=" + node.name} >노드별 서비스 관리</Link>
                              {node.nodeResource.canMig ? <button className="btn outline" /* onClick={() => { openMigSetting(node) }} */>MIG 설정</button> : false}
                            </div>
                          </div>
                        </>
                        :  // [모드] 모니터링 모드일 때
                        <>
                          <div className="detail">
                            <p>
                              <span className="dt">CPU</span>
                              <span><b className={getColorClass(node.statusCpu)}>{node.nodeResource.cpu}</b> %</span>
                            </p>
                            <p>
                              <span className="dt">Storage</span>
                              <span><b className={getColorClass(node.statusStorage)}>{node.nodeResource.storage}</b> %</span>
                            </p>
                            <p>
                              <span className="dt">Memory</span>
                              <span><b className={getColorClass(node.statusMemory)}>{node.nodeResource.memory}</b> %</span>
                            </p>
                          </div>
                          <div className="contentChartBox">
                            { (node.gpuType !== null && node.gpuType.total) || node.nodeResource.canMig ?
                              <div className="MidChartBox">
                                <div className="realChartBox">
                                  {/* <BarChart height={44} legend={false} yAxisType={YAXIS_TYPE.SINGLE} hideYAxisLabel={true}
                                  categories={props.categories} yAxisHeight={30} yAxisStyle={{color:'red',fontSize:7}} xAxisHeight={1} pointWidth={8}
                                  data={[{ name: '가동률 ', showInLegend: false, color: '#C9DAFF', data: node.nodeResource.gpu.allocatedGpu }]}
                                /> : */}
                                  <LineChart height={70} min={0} max={100} enableTooltip={false}
                                    categories={props.categories} hideYAxisLabel={true} xAxisLabelStyle={{ fontSize: '8.5px', color: '#878791' }} xAxisPosY={17}
                                    data={[
                                      { name: '가동률 ', showInLegend: false, color: '#BECC23', enableMouseTracking: false, data: node.nodeResource.gpu.allocatedGpu },
                                    ]} lineY={0} /></div>
                              </div>
                               :
                              <p className="noData">GPU가 없는 노드</p>
                            }
                          </div>
                        </>}
                    </>}
                </>
                : //시스템 장애 있을 때
                <>
                  <div className="detail">
                    <p>
                      <span className="dt">CPU</span>
                      <b className={'okay'}>{'-'}</b>
                    </p>
                    <p>
                      <span className="dt">Storage</span>
                      <b className={'okay'}>{'-'}</b>
                    </p>
                    <p>
                      <span className="dt">Memory</span>
                      <b className={'okay'}>{'-'}</b>
                    </p>
                  </div>
                  <div className="contentChartBox">
                    <p className="noData" style={{ color: '#f30b0b', backgroundColor: 'rgba(255, 0, 0, 0.04)' }}>시스템 장애 발생</p>
                  </div>
                </>
              }
            </>
            : // 스토리지
            <>
              <div className="storageBox">
                <p>사용률<span className={getColorClass(node.status)}>{node.storageRate}</span>%</p>
              </div>
            </>}
        </div>
      </div>
    </NodeFragment>
  )
}

const NodeFragment = styled.div`
  .server{width:310px;height:155px;border:1px solid #E8E8EE;box-sizing:border-box;border-radius:6px;padding:15px; display:flex;flex-direction:column;justify-content:space-between;}
  .server .title {display:flex;flex-wrap:wrap;align-items:center;}
  .server .title .text {display:flex;align-items:center;flex:1;overflow:hidden;font-weight:bold;font-size:14px;text-overflow:ellipsis;white-space:nowrap;}
  .server .title .text .ellipsis {display:flex;align-items:center;width:210px;height:16px;line-height:16px;}

  .type {display:flex;justify-content:space-between;font-size:12px;margin-top:8px;height:14px;}
  .type > div {display:flex;text-overflow:ellipsis;white-space:nowrap;}
  .type .gpuType {margin:0 13px 0 6px; color:#878791;font-weight:400;line-height:normal;}

  .content{display:flex; justify-content:space-between;}
  .content .detail{display:flex;align-items:center;justify-content:space-between;gap:16px;}
  .content p{font-size:10px;text-align:start;display:flex;flex-direction:column;gap:13px;}
  .content p b{font-size:16px;font-weight:700;line-height:normal;}
  .content .dt{display:block;color:#878791;}
  .content .contentChartBox{width:126px;height:44px;position:relative;}
  .content .contentChartBox .noData{width:100%;height:100%;background:#FAFAFB;text-align:center;line-height:44px;font-size:10px;font-weight:400;}
  .content .contentChartBox .MidChartBox{position:absolute; width:126px; height:70px;bottom:0px;overflow:hidden;}
  .content .contentChartBox .realChartBox{position:absolute;width:200px;height:70px;left:-58px;bottom:-17px;}

  .content .storageBox{width:278px;height:82px;background:#FAFAFB;display:flex;justify-content:center;align-items:center;}
  .content .storageBox p{font-size:12px;font-weight:400;line-height:normal; display:inline-block;}
  .content .storageBox p span{font-size:16px;font-weight:700;line-height:normal;margin:0 2px 0 12px;}

  .server .status{display:inline-block;width: 14px;height:12px;margin-right:10px;vertical-align:middle;}
  .server.status0 .status{background:url(/images/status.png) no-repeat center / 100%;}
  .server.status1 .status{background:url(/images/alert-warn.png) no-repeat center / 100%;}
  .server.status2 .status{width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}

  .okay {color:#333333}
  .noti {color:#ffa900}
  .warn {color:#f30b0b}
`

export default Node