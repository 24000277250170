export default class SchedulerEvent extends Event {
    public static START:string = 'startScheduler'
    public static STOP:string = 'stopScheduler'
    public static UPDATE:string = 'updateScheduler'
    public static UPDATED:string = 'updatedScheduler'

    public static EDIT_START:string = 'editStart'
    public static EDIT_END:string = 'editEnd'
    
    public payload:any = {}
  }