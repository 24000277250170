import React, { useEffect } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router'
import axios, { AxiosResponse } from 'axios'
import { useRecoilState, useSetRecoilState } from 'recoil'

import ApiController from "./controller/ApiController"
import ApiEvent from './events/ApiEvent'
import { ISettings, settingState } from './states/settingState'

import './assets/css/index.css'
// import Modal from './views/components/Modal'
// import ModalMessage from './views/components/ModalMessage'

import Blank from './views/Blank'
import LayoutDev from './views/LayoutDev'
import LayoutOps from './views/LayoutOps'
import LoginOps from './views/pagesOps/Login'
import LoginDev from './views/pagesDev/Login'
import LoginDevLdap from './views/pagesDev/LoginLdap'

import { authState } from './states/authStates'
import { IUserInfo, userInfoState } from './states/userInfoState'
import { IUserAuthInfo } from './states/authStates'

import AlertCenter from './views/pagesDev/AlertCenter'
import WorkspaceForm from './views/pagesDev/WorkspaceForm'
import JobForm from './views/pagesDev/JobForm'
import WorkspaceList from './views/pagesDev/WorkspaceList'
import WorkspaceDetail from './views/pagesDev/WorkspaceDetail'
import ImageList from "./views/pagesDev/ImageList"
import Billing from './views/pagesDev/Billing'
import Mypage from './views/pagesDev/Mypage'
import MypageLdap from './views/pagesDev/MypageLdap'

import AdminAlertCenter from './views/pagesDevAdmin/AlertCenter'
import Monitoring from './views/pagesDevAdmin/Monitoring'
import AdminUser from './views/pagesDevAdmin/User'
import AdminWorkspaceList from './views/pagesDevAdmin/WorkspaceList'
import AdminWorkspaceDetail from './views/pagesDevAdmin/WorkspaceDetail'
import ResourceGrouList from './views/pagesDevAdmin/GroupList'
import GroupDetail from "./views/pagesDevAdmin/GroupDetail"
import ServerDetail from './views/pagesDevAdmin/ServerDetail'
import Queue from './views/pagesDevAdmin/Queue'
import ImageListAdmin from "./views/pagesDevAdmin/ImageList"
import AdminBilling from './views/pagesDevAdmin/Billing'

import OpsAlertCenter from './views/pagesOps/AlertCenter'
import OpsServiceForm from './views/pagesOps/ServiceForm'
import OpsServiceList from './views/pagesOps/ServiceList'
import OpsServiceDetail from './views/pagesOps/ServiceDetail'
import OpsVersionList from './views/pagesOps/VersionList'
import OpsImageList from './views/pagesOps/ImageList'
import OpsMyapge from './views/MypageOps'
import OpsAlertCenterAdmin from './views/pagesOpsAdmin/AlertCenter'

import OpsAdminUsers from './views/pagesOpsAdmin/User'
import OpsAdminMonitoring from './views/pagesOpsAdmin/Monitoring'

import JoinDev from "./views/pagesDev/Join"
import JoinOps from "./views/pagesOps/Join"
import Toast from './views/components/ui/Toast'
import OpsToast from './views/components/uiv2/Toast'
import ScrollToTop from './views/components/ScrollToTop'
import NotFound from './views/NotFound'
import Logout from './views/Logout'
import Config from './Config'
import WaitingForJoin from './views/WaitingForJoin'

axios.interceptors.request.use((config) => {
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  return Promise.reject(error)
})

const App = () => {
  const apiController = ApiController.getInstance()

  const [ authInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  // console.log(authInfo, userInfo)

  const routes = [
    {
      path: '/404',
      element: <NotFound />
    }, {
      path: '*',
      element: <Navigate to='/404' />
    }, {
      path: '/',
      element: authInfo?.aToken ? 
      (Config.env.REACT_APP_MODE === 'OPS' ? <LayoutOps /> : <LayoutDev />) : 
      (Config.env.REACT_APP_MODE === 'OPS' ? 
          <LoginOps /> : 
          Config.env.REACT_APP_LDAP === 'true' ? <LoginDevLdap /> : <LoginDev />),
      children: (Config.env.REACT_APP_MODE === 'OPS' ? (
        (authInfo?.isAdmin === true ? [
          { path: '/', element: <Navigate to='/monitoring/' /> },
          { path: '/alert', element: <OpsAlertCenterAdmin /> },
          { path: '/monitoring/', element: <OpsAdminMonitoring /> },
          { path: '/users/', element: <OpsAdminUsers /> },
          { path: '/ops/users/:id/services/', element: <Blank /> },
          { path: '/mypage', element: <OpsMyapge />}
        ]: [
          { path: '/', element: <Navigate to='/services/' /> },
          { path: '/services/', element: <OpsServiceList /> },
          { path: '/alert', element: <OpsAlertCenter /> },
          { path: '/billing/', element: <Blank /> },
          { path: '/images/', element: <OpsImageList /> },
          { path: '/service/:id', element: <OpsServiceDetail /> },
          { path: '/service/:id/update', element: <OpsServiceForm /> },
          { path: '/service/:tempId/temporary', element: <OpsServiceForm /> },
          { path: '/service/:id/version/:page', element: <OpsVersionList /> },
          { path: '/service/:id/version', element: <OpsVersionList /> },
          { path: '/service/new/*', element: <OpsServiceForm /> },
          { path: '/mypage', element: <OpsMyapge />}
          //{ path: '/tempsavedlist', element: <Blank /> },
          //{ path: '/mypage', element: <Blank /> }
        ])
      ) : (
        (authInfo?.isAdmin ?
          [
            { path: '/', element: <Navigate to='/monitoring/' /> },
            { path: '/alert', element: <AdminAlertCenter /> },
            { path: '/monitoring/', element: <Monitoring mode="monitor" /> },
            { path: '/setting/', element: <Monitoring mode="setting" /> },
            { path: '/monitoring/:name', element: <ServerDetail /> },
            // { path: '/gpumonitoring', element: <GpuMonitoring /> },
            { path: '/workspace/', element: <AdminWorkspaceList /> },
            { path: '/workspace/:id', element: <AdminWorkspaceDetail /> },
            { path: '/billing', element: <AdminBilling /> },
            { path: '/rgroups/admin', element: <ResourceGrouList /> },
            /* { path: '/group/:name',element: <GroupDetail/>}, */
            { path: '/rgroups/admin/:name/detail', element: <GroupDetail /> }, //임시테스트용주소
            { path: '/queue/', element: <Queue /> },
            { path: '/user/', element: <AdminUser /> },
            { path: '/images/', element: <ImageListAdmin /> },
            { path: '/mypage', element: Config.env.REACT_APP_LDAP ==='true' ? <MypageLdap/> : <Mypage /> }
          ] : [
            { path: '/', element: <Navigate to='/workspaces/' /> },
            /* { path: '/', element: <Navigate to='/workspaces/' /> }, */
            { path: '/alert/', element: <AlertCenter /> },
            { path: '/billing', element: <Billing /> },
            { path: '/workspaces/', element: <WorkspaceList /> },
            { path: '/workspace/new/*', element: <WorkspaceForm /> },
            { path: '/job/new/*', element: <JobForm /> },
            { path: '/images', element: <ImageList /> },
            { path: '/workspace/:id', element: <WorkspaceDetail /> },
            { path: '/mypage', element: Config.env.REACT_APP_LDAP ==='true' ? <MypageLdap/> : <Mypage /> }
          ]
        )
      ))
    }, {
      path: '/join', element: authInfo?.aToken ? 
      <Navigate to='/' /> : 
      (Config.env.REACT_APP_MODE === 'DEV' ? <JoinDev /> : <JoinOps />)
    }, {
      path: '/logout', element: <Logout />
    }, {
      path: '/waitingforjoin', element: <WaitingForJoin />
    }
  ]

  const routing = useRoutes(routes)
  const location = useLocation()
  const updateSetting = useSetRecoilState<ISettings>(settingState)

  React.useEffect(() => {
    updateSetting((prev:ISettings) => {
      return {
        ...prev,
        autoRefreshFlag: false
      }
    })
  }, [location])

  React.useEffect(() => {
    apiController.addEventListener(ApiEvent.LOAD_START, (e:ApiEvent) => {
      e.stopPropagation()
    })
    apiController.addEventListener(ApiEvent.LOAD_END, (e:ApiEvent) => {
      e.stopPropagation()
    })
  }, [])

  return (
    <React.Fragment>
      {/* <ModalMessage />
      <Modal /> */}
      {Config.env.REACT_APP_MODE === 'OPS' ? <OpsToast /> : <Toast />}
      <ScrollToTop />
      {routing}
    </React.Fragment>
  );
}

export default App;
