import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { NavigateFunction, Params } from 'react-router-dom'
import regularExpression from '../../../utils/regularExpression'
import PopupController from '../../../controller/PopupController'
import ToastEvent from '../../../events/ToastEvent'
import InputBox, { TEXT_INPUT_TYPE } from "../ui/InputBox"
import { ResourceGroup } from "../../../model/ResourceGroup"
import ModalEvent from "../../../events/ModalEvent"

interface IGroupNamePopupProps { 
  navigate?:NavigateFunction
  params?:Params
  onClose:Function
  onSuccess:Function
}

interface IGroupNamePopupState { 
  resourceGroupName:string
  checkExistsFlag:boolean //check 했는지 여부
  nameError:string
  nameOK:string
}

interface IGroupNameCheck {
  canCheckExist:boolean //check 할 조건 달성 여부
}

const GroupNamePopup = (props:IGroupNamePopupProps) => { 
  const regExp = regularExpression.regExp //GRUPNAME으로쓸것
  const popupController = PopupController.getInstance()
  const [ state, setState ] = useState<IGroupNamePopupState>({
    resourceGroupName: '',
    checkExistsFlag: false,
    nameError: '',
    nameOK: ''
  })

  const [ namestate, setNameState ] = useState<IGroupNameCheck>({
    canCheckExist: false
  })

  const inputHandler = (target: string, value: any) => {
    switch (target) {
      case 'groupName':
        setNameState({
          ...namestate,
          canCheckExist: false
        })

        let message: string = ''
        if (value.length === 0) {
          message = ''
        } else if (regExp.WS_NAME_RULE.test(value) === false) {
          message = '영어 소문자, 숫자, 하이픈(-) 만 입력 가능하며, 이름의 시작은 소문자로 끝은 소문자 혹은 숫자로 입력해 주세요.'
        } else {
          setNameState({
            ...namestate,
            canCheckExist: true
          })
        }
        setState({
          ...state,
          checkExistsFlag: false,
          resourceGroupName: value,
          nameError: message,
          nameOK: ''
        })
        break
    }
  }

  const checkDuplicateHandler = async ():Promise<any> => {
    try {
      if (state.resourceGroupName.length > 0 && namestate.canCheckExist) {
        const isExist:any = await ResourceGroup.checkExists(state.resourceGroupName)
        if (isExist) {
          if (isExist.isExist === true) {
            popupController.confirm('사용중 또는 삭제된 리소스 그룹 이름입니다. 이름을 수정해 주세요.')
            setState({
              ...state,
              checkExistsFlag: true,
              nameError: '사용중이거나 삭제된 리소스 그룹 이름입니다. 삭제된 리소스 그룹 이름은 재사용 불가합니다.'
            })
            return false
          } else {
            popupController.confirm('사용 가능한 리소스 그룹 이름입니다.')
            setState({
              ...state,
              checkExistsFlag: true,
              nameError:''
            })
            return true
          }
        }
      } else {
        return false
      }
      return false
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 3abd6e')
      return false
    }
  }

  const submitHandler = async () => {//중복확인쪽 리셋되게 
    try {
      let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
      if (!state.checkExistsFlag) {
        setState({
          ...state,
          nameError: '리소스 그룹 이름의 중복 확인을 해 주세요.'
        })
        return
      }
  
      const result: any = await ResourceGroup.create(state.resourceGroupName)
      if (result) {
        if (result.error) {
          popupController.confirm(`${state.resourceGroupName} 리소스 그룹 생성을 실패하였습니다.`)
          return
        }
  
        if (result.result === true) {
          props.onSuccess()
          closeHandler()
          toastEvent.payload = { message: `${state.resourceGroupName} 리소스 그룹이 생성되었습니다. ` }
          window.dispatchEvent(toastEvent)
        } else {
          // AN ERROR OCCURRED --> Ws
        }
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 05a363')
    }
  }
  
  const closeHandler = () => {
    if (props.onClose) {
      let e:ModalEvent = new ModalEvent(ModalEvent.ACTION_MODAL)
      e.payload = {
        key: 'closeCreateResourceGroupPopup',
        action: 'closeCreateResourceGroupPopup'
      }
      popupController.dispatchEvent(e)

      props.onClose()
    }
  }

  
  return (
    //inputbox 교체해야해욧(임시메모)
    <GroupNamePopupFragment>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
        <button onClick={closeHandler} className="btnClose">닫기</button>
        <div className="pageTitle">
          리소스 그룹 이름{ /* <Tooltip des="리소스 그룹 이름을 정하면 리소스 그룹이 생성됩니다." /> */}
        </div>
        <div className="formGroup bothEnd">
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="groupName" type={TEXT_INPUT_TYPE.TEXT} maxLength={56} placeholder="소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요." value={state.resourceGroupName} warning={state.nameError} error={state.nameError !== ''} onChange={inputHandler} />
              <button className={"btn outline " + (state.resourceGroupName.trim() === '' ? "disabled" : false)}
                disabled={state.resourceGroupName.trim() === ''}
                onClick={() => checkDuplicateHandler()}>중복확인</button>
            </div>
          </div>
        </div>

        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          <button className={state.resourceGroupName === '' || namestate.canCheckExist === false ? "btn off" : "btn blue"} onClick={(e) => { submitHandler() }}>{'생성하기'}</button>
        </div>
      </form>
    </GroupNamePopupFragment>
  )
}


const GroupNamePopupFragment = styled.div`
  .formWrap { display:flex; flex-direction:column; width:756px; min-height:255px; padding: 50px 50px 20px; box-sizing:border-box; }
  .serviceTypeTitleBox { display:flex; gap:5px; margin-bottom:10px; }
  .pageTitle { flex:none; margin-bottom:30px; }
  p { font-size:14px; }
  .btnWrap { flex:none; display:flex; justify-content:center; gap:20px; margin-top:50px; }
  .btnWrap .btn { width:188px; height:40px; line-height:40px; }
  
  .inputGroup { margin-left:10px; }
  .inputWrap { flex: none; }
  .inputWrap p { width:480px; }
  .inputBox + .inputBox { margin-top: 30px; }
  .inputBox.inputBoxTitle:not(:first-of-type) { padding-top: 20px;  margin-top:0px; }
  .inputBox.inputBoxTitle + .inputBox { margin-top: 7px; }
  form .inputBox .input { width:578px;  max-width:578px; }
  form .inputBox input { width:512px; }
  .inputBox .btn { width: 79px;  white-space:pre; margin-left:5px; }
  .inputBox { font-size:14px; }
  .inputBoxTitle { font-size:14px; }
  .label, .inputBoxTitle { font-weight:500; }
  .message { font-size:12px; margin: 5px 0 0 0; }
  .messageOK { font-size:12px; margin: 5px 0 0 0;  color:#2c78ff; }
  img.disabled { opacity:0; visibility:hidden}
  button.disabled { opacity:0.5; pointer-events:none}
  .inputBox .stringLengthBox { position:relative; }
  .inputBox .stringLengthBox p { font-size:14px; position:absolute; bottom:10px; right:0px; width:60px; text-align:right; padding-right:15px; }
  .inputBox .numOfCharBox { width:560px; }
  .inputBox .numOfCharBox .input { padding-right:63px; }
`

export default GroupNamePopup