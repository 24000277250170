import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../../../Config'
import Utils from '../../../../utils'

interface IResourceViewPopupProps {
  data:any //IUserDetail
  userInfo:any
  isTableBtn?:boolean
  onLeft?:boolean
}
interface IResourceViewPopupState {
  isOpen:boolean
}

const ResourceViewPopup = (props:IResourceViewPopupProps) => {
  const [ state, setState ] = useState<IResourceViewPopupState>({
    isOpen:false
  })
  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return() => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ isOpen: false })
  }

  const clickOutsideHandler = (event:any) => {
    if(!modalBtnRef.current?.contains(event.target)
    && !modalRef.current?.contains(event.target) && state.isOpen){
      closeHandler()
    } 
  }

  const onBtnClick = () => {
    setState({ isOpen: !state.isOpen })
  }
  return(
    <ResourceViewPopupFragment>
        <div className={props.isTableBtn ? "tableWrap" : "normalWrap"}>
          <button onClick={onBtnClick} ref={modalBtnRef}>
            {state.isOpen && state.isOpen === true ?
              <img src='/images/buttonMoreRounded.svg' alt='more' className='moreIconButton' />
              : <img src='/images/buttonMoreRounded_1.svg' alt='more' className='moreIconButton' />}
          </button>
        </div>
        <div>
        {state.isOpen && state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className={props.onLeft ? "commonModal onLeft" : "commonModal onRight"} style={{ 'width': 288 }}>
              <table>
                <thead>
                  <tr>
                    <th style={{height:'17px', marginBottom:'10px'}}>
                      <div className="resourceSum">
                        <div className="resources">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{maxWidth:'60px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{props.userInfo.userId}</EllipsisToolTip></div>
                            <img className="separator" src="/images/vertical-separator.png" />
                            <div style={{maxWidth:'60px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{props.userInfo.name ? props.userInfo.name : '-'}</EllipsisToolTip></div>
                            <img className="separator" src="/images/vertical-separator.png" />
                            <div className="normalWeight"><EllipsisToolTip options={Config.ellipsisTipOptions}>{props.userInfo.department ? props.userInfo.department : '-'}</EllipsisToolTip></div>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="resourceLimitList">
                        <li><span className='liRight'>총 사용 가능 GPU 리소스 블록 개수</span></li>
                        <div className="data">ㄴ{props.data.isGpuResourceBlocksEnabled === true ? Utils.numberMark(Number(props.data.totalGpuResourceBlocks)) + ' 개' : <div className="noLimit">설정 없음</div>}</div>
                      </ul>
                      <ul className="resourceLimitList">
                        <li><span className='liRight'>워크스페이스 자동 회수 시간</span></li>
                        <div className="data">ㄴ{props.data.isWorkspaceAutoReclaimEnabled === true ? Utils.numberMark(Number(props.data.workspaceAutoReclaimHours)) + ' 시간 후' : <div className="noLimit">설정 없음</div>}</div>
                      </ul>
                      <ul className="resourceLimitList">
                        <li><span className='liRight'>관리자 승인 후 대기열 포함 여부</span></li>
                        <div className="data">ㄴ{props.data.isAdminApproval === true ? '승인 후 대기열 포함' : <div className="noLimit">자동 대기열 포함</div>}</div>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
            : false}
        </div>
      </ResourceViewPopupFragment>
  )
}

const ResourceViewPopupFragment = styled.div`
.tableWrap {height:100%; display:flex; flex-direction:row; justify-content:center; align-items:center;}
  .normalWrap {height:17px; width:17px;}

  .subModalPopupWrap { position:relative; align-items:center; z-index:997;}
  .subModalPopupWrap img.moreIconButton { width:17px !important;height:18px;vertical-align:bottom; padding-top:0px; padding-left:4px;}
  .subModalPopupWrap .commonModal { position:absolute; width:400px; max-height:250px; overflow-y:auto; flex-direction:column; box-sizing:border-box; padding:30px 30px 20px 30px; border-radius:2px; box-shadow:3px 3px 3px 0px #DFDFDF; border:solid 1px #DFDFDF; background:#fff; }
  .subModalPopupWrap .onRight { left:-20px;top:5px; }
  .subModalPopupWrap .onLeft { left:-300px;top:5px; }

  .subModalPopupWrap .commonModal p{font-size:14px;line-height:24px; vertical-align:middle;}
  .subModalPopupWrap .commonModal .modalTitle + modalTitle{margin-top:10px;}
  .subModalPopupWrap .commonModal .modalTitle{font-weight:bold;}
  .subModalPopupWrap .commonModal .modalTitle .content{font-weight:normal;}

  .subModalPopupWrap .commonModal table {width:100%; border-collapse:collapse; font-size:14px; line-height:20px; text-align:left;}
  .subModalPopupWrap .commonModal table thead tr th { display:flex; gap:5px; height:20px; margin-bottom:5px; }
  .subModalPopupWrap .commonModal table thead tr th img { width:1px; }
  .separator { height:10px; margin:4px 0px; margin-left:0; }
  .subModalPopupWrap .commonModal table thead tr { border-bottom:1px solid #DFDFDF; }
  .subModalPopupWrap .commonModal table tbody tr:last-child td { border-bottom: 0; }
  .subModalPopupWrap .commonModal table tbody td { border-bottom: 1px solid #DFDFDF; line-height:19px; padding-top:10px; padding-bottom:10px; }
  .subModalPopupWrap .commonModal table td { text-align:left; }
  //.subModalPopupWrap .commonModal table tbody {max-height:195px; overflow:auto;}

  /* 상위 테이블 css에 영향받는 요소들 재설정 */
  .subModalPopupWrap .commonModal table thead th { background-color:white; font-weight:bold; }
  .subModalPopupWrap .commonModal table td { padding: 0; border-right:0; }
  //.subModalPopupWrap .commonModal table tbody tr:hover td{background-color:#fafafa;}

  .textAlignStart { text-align:start !important; }
  .cellTitle { text-align:left; }

  .resourceLimitList { margin-left:15px; padding-right:10px; line-height:16.8px; }
  .liRight { margin-left:-6px; }
  .normalWeight { font-weight:400; width:70px; }
  .separator { height:10px; padding:2px 0px; margin:0 10px; }
  .resourceLimitList li { list-style-type:disc; word-break:break-all; margin-top:2px;}
  .resourceLimitList li:first { margin-top:10px; }
  .resourceLimitList li div { margin-top:2px; }
  .resourceLimitList li .gray { color:#CCCCCC; }
  .resourceLimitList .data { font-weight:bold; line-height:normal; margin:1px 0; }
  .noLimit { color:#A7A7A7; display:inline; font-weight:500; }
  .resourceSum{ display:flex; height:17px; }
  .resources { width:226px; }
`

export default ResourceViewPopup