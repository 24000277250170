import { ILoginResult, LOGIN_RESULT, User } from '../model/User'

import PopupController from '../controller/PopupController'

import Tooltip from "./components/ui/Tooltip"
import InputBox, { TEXT_INPUT_SHAPE, TEXT_INPUT_TYPE } from "./components/uiv2/InputBox"
import MultiInputBox, { VALUE_STATUS } from "./components/uiv2/MultiInputBox"
import Button, { BUTTON_SIZE, BUTTON_COLOR, BUTTON_LINK } from "./components/uiv2/Button"
import CheckBox from "./components/uiv2/CheckBox"
import RadioBox from "./components/uiv2/RadioBox"
import Toggle, { TOGGLE_SIZE } from "./components/uiv2/Toggle"
import Chip, { CHIP_COLOR, CHIP_TYPE } from "./components/uiv2/Chip"
import TimePicker from './components/ui/TimePicker'
import Select, { SELECT_SIZE } from './components/uiv2/Select'
import Table, { CHECKBOX_TYPE, TABLE_ALIGN, TABLE_CELL_TYPE } from './components/uiv2/Table'
import Pagenation from './components/uiv2/Pagenation'
import utils from '../utils'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../Config'
import { useRef } from 'react'
import SelectCheckable from './components/uiv2/SelectMulti'
import ISelectOption from '../interfaces/SelectOption'
import ServiceTypePopup from './components/modalv2/ServiceTypePopup'
import EmailPopup from './components/modalv2/EmailPopup'
import ImageListPopup from './components/modalv2/ImageLlistPopup'
import VolumeListPopup from './components/modalv2/VolumeListPopup'
import ToastEvent from '../events/ToastEvent'
import SubPopupTable from './components/modalv2/sub/SubPopupTable'
import SubPopupList from './components/modalv2/sub/SubPopupList'

const Blank = () => {
  const popupController = PopupController.getInstance()
  const user = User()

  const login = async() => {
    const loginResult:ILoginResult = await user.login('adminuser', 'adminuser1@')
    popupController.confirm(loginResult.toString())
  }

  const test = () => {
    // console.log(tableRef.current.selected)
  }

  const tableHeadData = [
    {
      label: '이름',
      key: 'name',
      type: TABLE_CELL_TYPE.STRING,
      sortKey: 'name',
      sort: true,
      orderFlag: true,
      preProcesser: (value:string) => {
        return (<>sir <span style={{ fontWeight:'bold' }}>{value}</span></>)
      }
    }, {
      label: '종류',
      key: 'type',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: true,
      sort: false,
      ellipsis: 2
    }, {
      label: '가격',
      key: 'price',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: false,
      sort: false,
      preProcesser: utils.numberMark,
      align: TABLE_ALIGN.CENTER
    }, {
      label: '이메일',
      key: 'email',
      type: TABLE_CELL_TYPE.STRING,
      orderFlag: false,
      sort: false,
      default: '-'
    }, {
      label: '박스',
      key: 'box',
      type: TABLE_CELL_TYPE.FLEXBOX,
      orderFlag: false,
      sort: false,
    }
  ]
  const tableBodyData = [
    {
      rowClassName: 'rowclass',
      name: '1234',
      type: '못하다 두기 청춘의 과실이 철환하였는가? 얼음이 공자는 어디 가치를 군영과 피가 가슴에 부패뿐이다. 붙잡아 청춘이 그들의 같지 구하기 위하여, 속잎나고, 소리다.이것은 피다. 산야에 시들어 것은 그림자는 철환하였는가?',
      price: 1231231234,
      box: [
        ['HELLO!!', 'HELLO!!', 'HELLO!!', 'HELLO!!'],
        [(<div style={{ width: '300px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>HAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHA</EllipsisToolTip></div>)],
        [(<button onClick={test}>123</button>), (<button>456</button>)]
      ]
    }, {
      rowClassName: '',
      name: '1231',
      type: '123123',
      price: 1231231234,
      box: [
        ['HELLO!!'],
        [(<div style={{ width: '300px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>HAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHAHA</EllipsisToolTip></div>)],
        [(<button>123</button>), (<button>456</button>)]
      ]
    }
  ]
  const tableRef = useRef<any>()

  return (
    <div>
      <div className="App">
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a className="App-link" rel="noopener noreferrer" onClick={login}>
          Learn React
        </a>
        <Tooltip des="툴팁테스트문구" />

      </div>
      {/* <div style={{width:'100px'}}><TimePicker value='' onChange={()=>{}}/></div> */}
      <Table checkBoxType={CHECKBOX_TYPE.USE_ALL} colWidth={[20, 200, 200, 200, null, 300]} ref={tableRef}
        headerLabel={tableHeadData} 
        items = {tableBodyData}
        noData={'데이터가 없습니다'} />
      <Pagenation defaultPage={3} totalArticle={25} perPage={10}/>
      <Pagenation defaultPage={1} totalArticle={258} perPage={10}/>
      <Pagenation defaultPage={13} totalArticle={258} perPage={10}/>
      <div style={{display: 'flex', gap: '20px', marginTop: '50px'}}>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <InputBox id="input1" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" autoCompleteValue={['aaa', 'bbb', 'ccc']} />
          <InputBox id="input2" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" width="200px" />
          <InputBox id="input3" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" shape={TEXT_INPUT_SHAPE.SMALL} search={true} />
          <InputBox id="input4" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" search={true} button="버튼" 
                    onClickButton={(id:string, value:string) => console.log(id, value)}
                    onClickSearch={(id:string, value:string) => console.log(id, value)}
          />
          <InputBox id="input5" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" maxLength={100} button="버튼" />
          <InputBox id="input6" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" unit="Text" />
        </div>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <InputBox id="input9" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" helpText="Helper message" />
          <InputBox id="input10" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" errorText="필수 선택 항목입니다" />
          <InputBox id="input7" type={TEXT_INPUT_TYPE.TEXT} value="Text Text Text" placeholder="Text" fill={true} />
          <InputBox id="input8" type={TEXT_INPUT_TYPE.TEXT} value="" placeholder="Text" fill={true} search={true} button="버튼" />
          <InputBox id="input11" type={TEXT_INPUT_TYPE.TEXT} value="disabled input" placeholder="Text" disabled={true} maxLength={100} button="버튼" />
        </div>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <Select
            option={[
              { label: 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz', value: '0' }, 
              { label: 'aaaaaaaaaaaaaa', value: '1' }, 
              { label: 'cc', value: '2' },
              { label: 'dddddd', value: '3'},
              { label: 'eeeeeeeee', value: '4'}
            ]}
            placeholder="선택해 주세요"
            // selected={}
            helpText="Helper message"
            onChange={(result:ISelectOption)=>{/* console.log('SelectCheckable Result : ', result) */}}/>
          <Select
            option={[
              { label: 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz', value: '0' }, 
              { label: 'aaaaaaaaaaaaaa', value: '1' }, 
              { label: 'cc', value: '2',error:true },
              { label: 'dddddd', value: '3'},
              { label: 'eeeeeeeee', value: '4'}
            ]}
            placeholder="선택해 주세요"
            errorText="필수 선택 항목입니다" 
            // selected={}
            onChange={(result:ISelectOption)=>{/* console.log('SelectCheckable Result : ', result) */}}/>
          <Select
            option={[
              { label: 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz', value: '0' }, 
              { label: 'aaaaaaaaaaaaaa', value: '1' }, 
              { label: 'cc', value: '2' },
              { label: 'dddddd', value: '3'},
              { label: 'eeeeeeeee', value: '4'}
            ]}
            placeholder="선택해 주세요"
            disabled={true}
            // selected={}
            onChange={(result:ISelectOption)=>{/* console.log('SelectCheckable Result : ', result) */}}/>
          <Select
            option={[
              { label: 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz', value: '0' }, 
              { label: 'aaaaaaaaaaaaaa', value: '1' }, 
              { label: 'cc', value: '2' },
              { label: 'dddddd', value: '3'},
              { label: 'eeeeeeeee', value: '4'}
            ]}
            placeholder="선택해 주세요"
            width="200px"
            size={SELECT_SIZE.SMALL}
            // selected={}
            onChange={(result:ISelectOption)=>{/* console.log('SelectCheckable Result : ', result) */}}/>
        </div>
      </div>
      <div style={{display: 'flex', gap: '20px', marginTop: '10px'}}>
        <p style={{flex: 2, color: '#333', fontSize: '14px', lineHeight: '1.4em', letterSpacing: 0}}>input 옵션 설명 (* 필수)<br />
          * id<br />
          * type: TEXT_INPUT_TYPE.TEXT / TEL / PASSWORD<br />
          size: TEXT_INPUT_SHAPE.NORMAL / SMALL (기본값 NORMAL)<br />
          placeholder: string<br />
          value: string|number<br />
          autoCompleteValue: string[]|number[] (onChange 시 리스트 업데이트 필요, focusOut 시 리스트 초기화)<br />
          disabled: boolean<br />
          helpText: string (하단 회색 텍스트)<br />
          errorText: string (하단 빨강 텍스트)<br />
          maxLength: number (value 길이 최대값)<br />
          width: string (가본값 100%)<br />
          idx: number (목록일경우)<br />
          unit: string|number (우측 단위 텍스트)<br />
          count: number (우측 텍스트)<br />
          fill: boolean (input 배경이 회색일경우 true)<br />
          onChange: Function (id, value, idx|null)<br />
          버튼 - button: string (버튼 텍스트)<br />
          버튼 - onClickButton: Function (id, value, idx|null)<br />
          검색 - search: boolean<br />
          검색 - onClickSearch: Function (id, value, idx|null)
        </p>
        <p style={{flex: 1, color: '#333', fontSize: '14px', lineHeight: '1.4em', letterSpacing: 0}}>select 옵션 설명 (* 필수)<br />
          * option: ISelectOption[]<br />
          size: SELECT_SIZE.NORMAL / SMALL (기본값 NORMAL)<br />
          ref: any<br />
          selected: ISelectOption<br />
          disabled: boolean<br />
          placeholder: string<br />
          width: string (가본값 100%)<br />
          helpText: string (하단 회색 텍스트)<br />
          errorText: string (하단 빨강 텍스트)<br />
          onChange: Function (ISelectOption)
        </p>
      </div>
      <div style={{display: 'flex', gap: '50px', marginTop: '50px'}}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_SECONDARY} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_SECONDARY} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_SECONDARY} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_SECONDARY} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_ERROR} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_ERROR} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_ERROR} onClickButton={() => console.log('hi')}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
            <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_ERROR} onClickButton={() => console.log('hi')} disabled={true}>버튼<img src="/images-v2/input-search.png" alt="search" /></Button>
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Button size={BUTTON_SIZE.SMALL} url="/" target={BUTTON_LINK.SELF}>내부 링크</Button>
            <Button size={BUTTON_SIZE.SMALL} url="/" target={BUTTON_LINK.BLANK}>외부 링크</Button>
            <Button size={BUTTON_SIZE.SMALL} onClickButton={() => console.log('hi')} disabled={true}>버튼</Button>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <CheckBox id="checkbox1" checked={false} label="Label" />
          <CheckBox id="checkbox2" checked={false} disabled={true} label="Label" />
          <CheckBox id="checkbox3" checked={true} label="Label" />
          <CheckBox id="checkbox4" checked={true} disabled={true} label="Label" />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <RadioBox id="radiobox1" checked={false} label="Label" />
          <RadioBox id="radiobox2" checked={false} disabled={true} label="Label" />
          <RadioBox id="radiobox3" checked={true} label="Label" />
          <RadioBox id="radiobox4" checked={true} disabled={true} label="Label" />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <div style={{display: 'flex', gap: '10px'}}>
            <Toggle id="test1" checked={true} onChange={() => { }} />
            <Toggle id="test2" checked={true} onChange={() => { }} size={TOGGLE_SIZE.SMALL} />
          </div>
          <div style={{display: 'flex', gap: '10px'}}>
            <Toggle id="test3" checked={false} disabled={true} onChange={() => { }} />
            <Toggle id="test4" checked={false} disabled={true} onChange={() => { }} size={TOGGLE_SIZE.SMALL} />
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <div style={{display: 'flex', gap: '10px'}}>
            <Chip value="chip" color={CHIP_COLOR.NORMAL} type={CHIP_TYPE.ROUND} />
            <Chip value="chip" color={CHIP_COLOR.WARNNING} type={CHIP_TYPE.ROUND} />
            <Chip value="chip" color={CHIP_COLOR.ERROR} type={CHIP_TYPE.ROUND} />
          </div>
          <Chip value={["chip", "chip", "chip"]} type={CHIP_TYPE.ROUND} delete={true} onClickDelete={() => console.log('hi')}/>
          <Chip value={["chip", "chip", "chip"]} type={CHIP_TYPE.ROUND} delete={true} color={CHIP_COLOR.ERROR} />
          <Chip value={["chip", "chip", "chip"]} type={CHIP_TYPE.ROUND} delete={true} color={CHIP_COLOR.FILL} />
          <Chip value={["chip", "chip", "chip"]} color={CHIP_COLOR.FILL} />
        </div>
      </div>
      <div style={{margin:'30px 0', display:'flex', gap:'10px'}}>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e:any) => {popupController.email(()=>{}, [])}}>이메일 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e:any) => {popupController.serviceType(()=>{}, '')}}>서비스 분류명 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e:any) => {popupController.imageOps(()=>{},false,'testuser1',true)}}>이미지 리스트 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e:any) => {popupController.volumeOps(()=>{},0, 'common')}}>볼륨</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e:any) => {popupController.versionDetail('name', 'namesapce')}}>버전 상세 팝업</Button>
      </div>
      <div style={{margin:'30px 0', display:'flex', gap:'10px'}}>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e: any) => { popupController.dialouge('하위에 속해진 모든 메뉴에 적용하시겠습니까?', 'dialouge', 'dialouge') }}>다이얼로그 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e: any) => { popupController.confirm('시스템 알림 내용이 보여질 영역입니다.')}}>컨펌 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e: any) => { popupController.confirm('시스템 알림 내용이 보여질 영역입니다.', '타이틀')}}>타이틀이 있는 컨펌 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e: any) => { popupController.confirm('시스템 알림 내용이 보여질 영역입니다. 시스템 알림 내용이 보여질 영역입니다.') }}>두 줄 컨펌 팝업</Button>
        <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={(e: any) => {
          let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          toastEvent.payload = { message: `토스트 팝업입니다.` }
          window.dispatchEvent(toastEvent)
        }}
        >토스트 팝업</Button>
      </div>
      <div style={{margin:'30px 0', display:'flex', flexDirection: 'column', gap:'10px', maxWidth: '600px'}}>
        <MultiInputBox id="input12" placeholder="알림을 받을 이메일 주소를 입력 하세요."
          onUpdate={(id:string, value:string|number) => alert('validate value: '+value)} 
          onClickButton={() => alert('open modal')} 
          onDeleteButton={(id:string, idx:number) => alert('delete idx: '+idx)} 
        />
        <MultiInputBox id="input12" valueList={[
            {value: ['10x', 'a@a.a'], status: VALUE_STATUS.NORMAL}, 
            {value: ['10x22', 'b@b.b'], status: VALUE_STATUS.NORMAL}, 
            {value: ['c@c.c'], status: VALUE_STATUS.ERROR}
          ]}
          errorText="형식에 맞지 않은 이메일 주소를 삭제해 주세요."
          onUpdate={(id:string, value:string|number) => alert('validate value: '+value)} 
          onClickButton={() => alert('open modal')} 
          onDeleteButton={(id:string, idx:number) => alert('delete idx: '+idx)} 
        />
        <p style={{flex: 2, color: '#333', fontSize: '14px', lineHeight: '1.4em', letterSpacing: 0}}>input 옵션 설명 (* 필수)<br />
          * id<br />
          placeholder: string<br />
          valueList: IMultiInputValue[]<br />
          helpText: string (하단 회색 텍스트)<br />
          errorText: string (하단 빨강 텍스트)<br />
          width: string (가본값 100%)<br />
          idx: number (목록일경우)<br />
          fill: boolean (input 배경이 회색일경우 true)<br />
          onUpdate: Function (id, value, idx|null)<br />
          onDeleteButton: Function (id, vdx(valueList index), idx|null)<br />
          onClickButton: Function (id, value, idx|null)<br />
        </p>
      </div>
      <div style={{margin:'30px 0', display:'flex', gap:'10px', justifyContent:'flex-end'}}>
        <SubPopupTable title={'전체 GPU 모델명'} tableHeaders={['abc', 'abcd']} datas={[{abc:'abababababababababababababababababababababab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abababababababababababababababababababababab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}]} colWidth={[42, 200, 200]} width={474}/>
        <SubPopupTable title={'전체 GPU 모델명'} tableHeaders={['abc', 'abcd']} datas={[{abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}, {abc:'abab', abcd:'abdc'}]} colWidth={[42, 200, 200]} />
        <SubPopupTable title={'전체 GPU 모델명'} tableHeaders={['abc']} datas={['abab', 'abab', 'abab', 'abab', 'abab']} />
      </div>
      <div style={{margin:'30px 0', display:'flex', gap:'10px', justifyContent:'flex-end'}}>
        <SubPopupList title={'타이틀'} datas={['abcd', 'abc']} />
        <SubPopupList title={'Command'} datas={['python.main.py', 'python.main.py', 'python.main.py', 'python.main.py', 'ifthestringislong-thewidthofpopupshouldfitit']} width={330} />
        <SubPopupList title={'Command'} datas={['python.main.py', 'python.main.py', 'python.main.py', 'python.main.py', 'ifthestringislong-thewidthofpopupshouldfitit', 'abc', 'abcd']} width={330} />
      </div>
    </div>
  );
}

export default Blank;
