import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ALERT_ORDER_BY, ALERT_STATUS, ALERT_TYPE, Alert, IAlertListItem } from '../../model/Alert'
import { SORT_ORDER } from '../../model/BaseDataType'
import PageEvent from '../../events/PageEvent'
import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/ui/Table'
import Pagenation from '../components/ui/Pagenation'
import PopupController from '../../controller/PopupController'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import dayjs from 'dayjs'
import TableEvent from '../../events/TableEvent'
import utils from '../../utils'

interface IAlertCenterPageProps {
}

interface IAlertCenterState {
  page:number
  totalArticle:number
  count: {
    obj:number
    infra:number
    user:number
  }
  tableItems:IAlertListItem[]
  deleteItems:string[]
  selected:IAlertListItem[]
}

const AlertCenter = (props:IAlertCenterPageProps) => {
  const popupController = PopupController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [52, 226, null, 228]
  const headerLabel:ITableHeader[] = [
    { label: '상태', key: 'tableAlertStatus', type: TABLE_CELL_TYPE.HTML, styleKey: 'style', sort: true, sortKey: 'level' },
    { label: '내용', key: 'msg', type: TABLE_CELL_TYPE.STRING, styleKey: 'style', sort: false },
    { label: '시간', key: 'tableAlertTimestamp', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.DESC, styleKey: 'style', sortKey: 'datetime' },
  ]

  const [ tab, _setTab ] = useState<ALERT_TYPE>(ALERT_TYPE.INFRA)
  const tabRef = useRef(tab)
  const setTab = (data:any) => {
    tabRef.current = data
    _setTab(data)
  }

  const [ state, _setState ] = useState<IAlertCenterState>({
    page: 1,
    totalArticle: 0,
    count: {
      obj: 0,
      infra: 0,
      user: 0
    },
    tableItems: [],
    deleteItems: [],
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const prevTab = utils.usePrevState(tab)

  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    //window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      //window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
  }, [])

  useEffect(() => {
    // tab이 변경되었을 때...
    if(prevTab !== tab){
      getData(1, tab) //무조건 1페이지로 이동
    }
  }, [tab])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    getData(state.page, tabRef.current)
  }

  // const autoRefreshHandler = async () => {
  //   // await utils.setLoadingMask(false)
  //   if (tableRef.current?.selected.length === 0) {
  //     await getData(stateRef.current.page, tabRef.current)
  //   }
  //   // await utils.setLoadingMask(true)
  // }

  const getStatus = (status:ALERT_STATUS) => {
    let text = ''
    switch(status) {
      case ALERT_STATUS.WARN:
        text = '경고'
        break
      case ALERT_STATUS.CONCERN:
        text = '주의'
        break
      case ALERT_STATUS.NOTI:
        text = '알림'
        break
    }
    return text
  }
  const getData = async (page:number, tab:ALERT_TYPE): Promise<void> => {
    try{
      tableRef.current?.setSelected([])
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.DESC : tableRef.current?.sortOrder)
      const sortBy = tableRef.current?.sortKey === 'level' ? ALERT_ORDER_BY.STATUS : ALERT_ORDER_BY.DATETIME
      let data = await Alert.getListAdmin(sortBy, sortOrder, tab, page, 50)
      if (data) {
        let total: number = 0
        switch (tab) {
          case ALERT_TYPE.WORKSPACE:
            total = data.objCount
            break
          case ALERT_TYPE.INFRA:
            total = data.hwCount
            break
          case ALERT_TYPE.USER:
            total = data.usrCount
            break
        }
        for (let eachLine of data.alertList) {
          if (eachLine.isRead === false) eachLine.style = { fontWeight: 'bold' }
          // status
          eachLine.tableAlertStatus = `<span class = 'alert${eachLine.level}'><i></i>${getStatus(eachLine.level)}</span>`
          // timestamp
          eachLine.tableAlertTimestamp = dayjs(eachLine.timestamp*1000).format('YYYY/MM/DD\nHH:mm:ss')
        }
        setState({
          ...state,
          page: page,
          tableItems: data.alertList,
          count: {
            obj: data.objCount,
            infra: data.hwCount,
            user: data.usrCount
          },
          totalArticle: total
        })
        window.dispatchEvent(new Event('refreshalarm'))
      }
    } catch(error:any) {
      console.log('Alert.getList error', error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - ecde69')
    }
  }

  const removeAlertHandler = async ():Promise<void> => {
    try {
      let targetIdArray:any[] = []
      const items = state.tableItems //tableRef.current?.items
      const selectedIdx = tableRef.current?.selected
  
      if (items && selectedIdx && selectedIdx.length > 0) {
        for (let eachIdx of selectedIdx) {
          targetIdArray.push(items[Number(eachIdx)].alertId)
        }
        popupController.dialouge(`${targetIdArray.length} 개의 알림이 삭제됩니다. 진행하시겠습니까?`, 'deleteAlert', targetIdArray, '확인', '취소')
  
        setState({
          ...state,
          deleteItems: targetIdArray
        })
      } else {
        popupController.confirm('선택된 항목이 없습니다.')
      }
    } catch(error:any) {
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const tabHandler = (target:ALERT_TYPE) => {
    setTab(target)
  }

  const navigateToPage = (newPage:number):void => {
    if(stateRef.current.page !== newPage) { //이전 페이지와 같으면 굳이 재호출하지 않음 (중복 호출 방지)
      getData(newPage, tabRef.current)
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteAlert':
        try {
          const deleteItems = e.payload.key
          await Alert.deleteList(deleteItems)
          let evt:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${deleteItems.length} 개의 알림이 삭제되었습니다.`}
          window.dispatchEvent(evt)

          // 삭제 후 화면 갱신
          tableRef.current?.setSelected([])
          getData(stateRef.current.page, tabRef.current)
        } catch(error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 8de9e4')
        }
        break
    }
  }

  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IAlertListItem[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData(state.page, tab)
  }

  return (
    <AlertCentreFragment>
      <div className="titleArea bothEnd">
        <h2 className="hide">통합 알람 센터</h2>
        <div className="tabWrap">
          <button className={tab === ALERT_TYPE.INFRA ? 'pageTitle on' : 'pageTitle'}
            onClick={(): void => tabHandler(ALERT_TYPE.INFRA)}>
            하드웨어 인프라 알림
            <span className="total"><b>{state.count.infra}</b> 개</span>
          </button>
          <button className={tab === ALERT_TYPE.WORKSPACE ? 'pageTitle on' : 'pageTitle'}
            onClick={(): void => tabHandler(ALERT_TYPE.WORKSPACE)}>
            워크스페이스 알림
            <span className="total"><b>{state.count.obj}</b> 개</span>
          </button>
          <button className={tab === ALERT_TYPE.USER ? 'pageTitle on' : 'pageTitle'}
            onClick={(): void => tabHandler(ALERT_TYPE.USER)}>
            사용자 알림
            <span className="total"><b>{state.count.user}</b> 개</span>
          </button>
        </div>
        <div className="btnWrap">
          <button className={"btn outline " + (tableRef.current?.selected.length === 0 ? 'off' : '')} onClick={removeAlertHandler}>선택 항목 삭제</button>
        </div>
      </div>
      <Table ref={tableRef}
        colWidth={tableColWidth}
        headerLabel={headerLabel}
        items={state.tableItems}
        noData="알림이 없습니다."
        checkBoxType={CHECKBOX_TYPE.USE_ALL}
        onUpdate={onUpdateStatus}
        onSelect={selectBoxCheckHandler}
        defaultSortOrder={SORT_ORDER.DESC} />
      {state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage: number) => { navigateToPage(newPage) }
        } /> : false
      }
    </AlertCentreFragment>
  )
}

const AlertCentreFragment = styled.div`
  //tab
  .tabWrap{display:flex;}
  .tabWrap button,
  .tabWrap button .total b{color:#ccc;}
  .tabWrap button.on{color:#303030;}
  .tabWrap button.on .total b{color:#217eff;}
  .tabWrap button + button:before{content:'';display:inline-block;width:2px;height:26px;margin:0 16px;background:#ccc;vertical-align:middle;}
  .tabWrap button .new{display:inline-block;width: 20px;height:20px;background: url(/images/new.png) no-repeat center / 100%;}

  // table
  table tbody td {padding:11px 20px}
  table .col0, .col2{text-align:center;}

  // alert status icons
  i {display:inline-block;width:12px;height:12px;margin-right:10px;vertical-align:middle;}
  .alert0 i {background:url(/images/alert.png) no-repeat center / 100%;}
  .alert1 i {background:url(/images/alert-warn.png) no-repeat center / 100%;}
  .alert2 i {width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}
`

export default AlertCenter