import React, { Component } from 'react'
import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import HighchartsReact from 'highcharts-react-official'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export interface IChartColorStops {
  warning:number
  error:number
}

interface IGaugeChartProps {
  data:number
  stops?:IChartColorStops
}

export default class GaugeChart extends Component<IGaugeChartProps> {
  private chartData:object = {
    chart: {
      type: 'solidgauge',
      height: 240,
      animation: false
    },
    title: {
      text: ''
    },
    tooltip: { enabled: false },
    pane: {
      center: ['50%', '65%'],
      size: '120%',
      startAngle: -110,
      endAngle: 110,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    yAxis: {
      stops: [
        [0, '#3BB000'], // green
        [this.props.stops?.warning ? this.props.stops?.warning-0.00001 : 0.59999, '#3BB000'], // green
        [this.props.stops?.warning ? this.props.stops?.warning : 0.6, '#FFD028'], // yellow
        [this.props.stops?.error ? this.props.stops?.error-0.00001 : 0.89999, '#FFD028'], // yellow
        [this.props.stops?.error ? this.props.stops?.error : 0.9, '#FF0000'], // red
        [1, '#FF0000'] // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      min: 0,
      max: 100,
      tickAmount: 2,
      labels: {
        enabled: false
      }
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -15,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: '',
        data: [Math.round(this.props.data*100)/100],
        dataLabels: {
          format: `<div style="text-align:center;font-size:18px;"><span style="font-size:20px">{y}</span> %</div>`
        }
      }
    ]
  }
  private chartComponent:any = React.createRef()

  componentDidUpdate(prev:any) {
    if (prev.data !== this.props.data) {
      this.chartComponent.current?.chart.update({
        series: [
          {
            name: '',
            data: [Math.round(this.props.data*100)/100],
            dataLabels: {
              format: `<div style="text-align:center;font-size:18px;"><span style="font-size:20px">{y}</span> %</div>`
            }
          }
        ]
      }, true, false, false)
    }
  }

  public render():React.ReactElement {
    return (
      <HighchartsReact highcharts={Highcharts} options={this.chartData} ref={this.chartComponent} />
    )
  }
}
