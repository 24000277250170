import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { User, IUserDetail } from '../../model/User'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import { useRecoilState } from 'recoil'
import InputBox, { TEXT_INPUT_TYPE } from "./ui/InputBox"
import PopupController from '../../controller/PopupController'
import regularExpression from "../../utils/regularExpression"

interface UserFormProps {
  navigate?:NavigateFunction
  onPwChange:Function
  onSubmit:Function
  isAdmin?:boolean
}

interface UserFormState {
  name:string
  nameError:string
  department:string
  departmentError:string
  phoneNumber:string
  phoneNumberError:string
  userId:string
  email:string
  emailError:string
  additionalInfo:string
}

const UserInfoEditLdap = (props: UserFormProps) => {
  const popupController = PopupController.getInstance()                                 
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)  
  const user = User()
  const navigate = useNavigate()

  const [ state, _setState ] = useState<UserFormState>({
    name: '',
    nameError: '',
    department: '',
    departmentError: '',
    phoneNumber: '',
    phoneNumberError: '',
    userId: '',
    email: '',
    emailError: '',
    additionalInfo:''
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if(userInfo?.userNo !== -1) {
      setState({
        ...state,
        name: userInfo?.name,
        userId: userInfo?.userId,
        department: userInfo?.department,
        phoneNumber: userInfo?.phoneNumber,
        email: userInfo?.email,
        additionalInfo: userInfo?.additionalInfo,
      })
    }
  }, [userInfo])
  
  const inputHandler = (target:string, value:any) => {
    let errorText:string = ''
    let errorcheck:boolean = false

    switch (target) {
      case 'name':
        errorText = ''
        if (value !== '') {
          if (!regularExpression.regExp.NAME_REG_EXP_START.test(value)||!regularExpression.regExp.NAME_REG_EXP.test(value)) {
            errorText = '한글, 영어 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
          }
        }
        setState({
          ...state,
          name: value,
          nameError: errorText,
        })
        break
      case 'department':
        errorcheck = value === '' ? false : value.match(regularExpression.regExp.DIV_REG_EXP) === null
        setState({
          ...state,
          department: value,
          departmentError: errorcheck ? '한글, 영문 대소문자, 숫자만 입력해 주세요.' : '',
        })
        break
      case 'email':
        if (value !== '') {
          if (value.match(regularExpression.regExp.MAIL_REG_EXP) === null) {
            errorText = '올바르지 않는 이메일 주소입니다.'
          }
        }
        setState({
          ...state,
          email: value,
          emailError: errorText
        })
        break
      case 'phoneNumber':
        errorcheck = value === '' ? false : regularExpression.regExp.HP_REG_EXP.test(value)
        setState({
          ...state,
          phoneNumber: value,
          phoneNumberError: errorcheck? '숫자만 입력해 주세요.' : '',
        })
      break
      case 'additionalInfo':
          setState({
            ...state,
            additionalInfo: value
          })
          break
    }
  }

  const validateInput = async (e:any) => {
    e.preventDefault()

    let blankErrorFlag:boolean = false
    let validationErrorFlag:boolean = false

    let phoneNumberError:string = state.phoneNumberError
    let nameError:string = state.nameError
    let emailError:string = state.emailError
    let departmentError:string = state.departmentError

    let payload:IUserDetail = {
      name: state.name,
      department: state.department,
      phoneNumber: state.phoneNumber,
      email: state.email,
      userId: state.userId
    }
    if (!payload.name) {
      nameError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if(state.nameError !== '') {
      validationErrorFlag = true
    }

    if (!payload.email) {
      emailError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if(state.emailError !== '') {
      validationErrorFlag = true
    }

    if (state.departmentError !== '') {
      validationErrorFlag = true
    }

    if (state.phoneNumberError !== '') {
      validationErrorFlag = true
    }

    if (blankErrorFlag === true || validationErrorFlag === true) {
      setState({
        ...state,
        nameError: nameError,
        emailError: emailError,
        departmentError: departmentError,
        phoneNumberError: phoneNumberError
      })
    }

    if (blankErrorFlag === true) {
      popupController.confirm('필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      return false
    } else if (blankErrorFlag === false && validationErrorFlag === true) {
      popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      return false
    } else if (validationErrorFlag === false && blankErrorFlag === false) {
      submitHandler()
    }
  }

  const submitHandler = () => {
    let payload
    if (props.isAdmin) {
      payload = {
        userNo: userInfo?.userNo,
        userId: state.userId,
        name: state.name,
        email: state.email,
      }
    } else {
      payload = {
        userNo: userInfo?.userNo,
        userId: state.userId,
        name: state.name,
        department: state.department,
        phoneNumber: state.phoneNumber,
        email: state.email,
        additionalInfo: state.additionalInfo
      }
    }
    props.onSubmit(payload)
  }

  const goBack = () => {
    let postUrl:string|null
    postUrl = sessionStorage.getItem('url')
    if (!postUrl) { postUrl = '/' }
    navigate(postUrl)
  }

  return (
    <UserFormFragment>
      { 
      props.isAdmin ?
        <div className="titleWrap">
          <h1>관리자 정보</h1>
        </div> :
        <div className="titleWrap">
          <h1>사용자 정보</h1>
        </div>
      }
      
      <form>
        { props.isAdmin ?
          <>
            <div className="formGroup">
              <label className="label">이름 </label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="name" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.name === '' ? '정보 없음' : state.name} placeholder="이름을 입력하세요." warning={state.nameError}/*  error={state.nameError != ''} */ onChange={inputHandler} />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="label">이메일 </label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="email" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.email === '' ? '정보 없음' : state.email} placeholder="연락 가능한 이메일 주소를 입력하세요." warning={state.emailError} onChange={inputHandler} />
                </div>
              </div>
            </div>
          </> : <>
            <div className="formGroup">
              <label className="label">이름 </label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="name" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.name === '' ? '정보 없음' : state.name} placeholder="이름을 입력하세요." warning={state.nameError}/*  error={state.nameError != ''} */ onChange={inputHandler} />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="label">부서</label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="department" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.department === '' ? '정보 없음' : state.department} placeholder="부서명을 입력하세요." warning={state.departmentError} onChange={inputHandler} />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="label">전화번호</label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="phoneNumber" type={TEXT_INPUT_TYPE.TEL} readonly={true} placeholder="연락 가능한 전화번호를 입력하세요." value={state.phoneNumber === '' ? '정보 없음' : state.phoneNumber}
                    warning={state.phoneNumberError} onChange={inputHandler} />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="label">이메일 </label>
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id="email" type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.email === '' ? '정보 없음' : state.email} placeholder="연락 가능한 이메일 주소를 입력하세요." warning={state.emailError} onChange={inputHandler} />
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="label">아이디 </label>
              <div className="inputWrap">
                <div className="inputBox">
                  <input type="text" className="input disable" readOnly value={state.userId} />
                </div>
              </div>
            </div>
          </>
        }
        <div className="btnWrap">
          <button className="btn blue" onClick={goBack}>확인</button>
        </div>
      </form>
    </UserFormFragment>
  )
}

const UserFormFragment = styled.div`
  max-width:830px; min-height:800px; width:100%; padding:0 50px;
  .titleWrap { display:flex; align-items:center; gap:10px; margin-bottom:40px; color:#303030; }
  .titleWrap h1 { font-size:24px; }
  .titleWrap p { font-size:14px; }
  .formGroup + .formGroup { margin-top:20px; }
  .label { font-weight:bold; }
  .label span { font-weight:normal; }
  .btn.blue { display:block; width:240px; margin:120px auto 0;}
  .btnWrap { display:flex; align-items:center; justify-content:center; gap:10px; margin-top:120px; justify-content:flex-end}
  .btnWrap .btn { width:240px;margin:0; }
  .inputBox button { width:96px; align-self: start; }
`
export default UserInfoEditLdap