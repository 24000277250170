import { useState, useRef, useEffect, forwardRef, useImperativeHandle, MutableRefObject } from 'react'
import styled from 'styled-components'
import Button, { BUTTON_SIZE } from './Button'
import { delay } from 'lodash'
import utils from '../../../utils'

export enum TEXT_INPUT_TYPE {
  TEXT = 'text',
  TEL = 'tel',
  PASSWORD = 'password'
}

export enum TEXT_INPUT_SHAPE {
  NORMAL = 'normal',
  SMALL = 'small',
  UNDERLINE = 'underline'
}

export enum TEXT_INPUT_RULE {
  REGEXP = 'regexp',
  REGEXP_NOT = 'regexpNot',
  REQUIRED = 'required',
  REQUIRED_IF_SOMETHING_EXISTS = 'requiredIfSomethingExists',
  LESS_THEN = 'lt',
  GREATER_THEN = 'gt',
  FUNCTION = 'function'
}

export enum TEXT_INPUT_RULE_LEVEL {
  ERROR = 'error',
  GUIDE = 'help'
}

export interface IInputBoxRule {
  basis:TEXT_INPUT_RULE
  rule?:RegExp|string|number|boolean|Function
  ref?:MutableRefObject<any>
  invalidateMessage:string
  refsInvalidateMessage?:string
  level?:TEXT_INPUT_RULE_LEVEL
}

interface IInputBoxProps {
  ref?:React.ForwardedRef<JSX.Element>
  id:string
  type:TEXT_INPUT_TYPE
  rules?:IInputBoxRule[]
  shape?:TEXT_INPUT_SHAPE
  placeholder?:string
  value?:string|number|null
  useSystemAutoComplete?:boolean
  autoCompleteValue?:number[]|string[]
  readonly?:boolean
  disabled?:boolean
  ignore?:boolean
  deleteButtonFlag?:boolean
  buttonDisabled?:boolean
  helpText?:string
  errorText?:string
  maxLength?:number
  width?:string
  idx?:number
  search?:boolean
  button?:string
  unit?:string|number
  count?:number
  fill?:boolean
  onChange?:Function
  onValidate?:Function
  onClickButton?:Function
  onClickSearch?:Function
  possibleRegExp?:RegExp
}

const defaultProps = {
  width: '100%',
  useSystemAutoComplete: true,
  shape: TEXT_INPUT_SHAPE.NORMAL, //shape로 변경
  deleteButtonFlag: false,
  ignore: false
}

interface IInputBoxState {
  errorText:string
  errorLevel:TEXT_INPUT_RULE_LEVEL
  focusInput:boolean
  autoCompleteValue:string[]|number[]
}

const InputBox = forwardRef<any, IInputBoxProps>((props:IInputBoxProps, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value
      },
      setErrorMessage: (errorMessage:string='', level:TEXT_INPUT_RULE_LEVEL=TEXT_INPUT_RULE_LEVEL.ERROR):boolean => {
        setState({ ...state, errorText: errorMessage })
        return errorMessage === ''
      },
      validate: (refCallFlag:boolean=false, ignoreBlank:boolean=false):boolean => {
        const error = setError(String(value), ignoreBlank, refCallFlag)
        if (error.invalidateMessage !== '' && !error.level) error.level = TEXT_INPUT_RULE_LEVEL.ERROR
        return error.invalidateMessage === '' && error.level !== TEXT_INPUT_RULE_LEVEL.ERROR
      },
      getErrorMessage: (refCallFlag:boolean=false, ignoreBlank:boolean=false):string => {
        const error = setError(String(value), ignoreBlank, refCallFlag)
        if (error.invalidateMessage !== '' && !error.level) error.level = TEXT_INPUT_RULE_LEVEL.ERROR
        return error.invalidateMessage
      }
    }
  })

  const [ value, setValue ] = useState<string|number>('')
  const [ state, _setState ] = useState<IInputBoxState>({
    errorText: '',
    errorLevel: TEXT_INPUT_RULE_LEVEL.ERROR,
    focusInput: false,
    autoCompleteValue: props.autoCompleteValue || []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const inputRef = useRef<any>()
  
  const [ inputClass, _setInputClass ] = useState<string>()
  const setInputClass = () => {
    let newInputClass:string = 'inputBox ' + props.shape
    if (state.errorText && state.errorLevel === TEXT_INPUT_RULE_LEVEL.ERROR) newInputClass += ' error'
    if (props.disabled) newInputClass += ' disable'
    if (props.readonly) newInputClass += ' readonly'
    if (props.ignore) newInputClass += ' ignore'
    if (props.unit) newInputClass += ' unit'
    if (props.fill) newInputClass += ' fill'
    if (state.focusInput) newInputClass += ' focus'
    
    _setInputClass(newInputClass)
  }
  useEffect(setInputClass, [props.shape, state.errorText, props.disabled, props.readonly, props.unit, props.fill, state.focusInput])

  useEffect(() => {
    if (props.value !== undefined && props.value !== null && value !== props.value) {
      setValue(props.value)
      setTimeout(() => {
        setError(String(props.value), true)
      }, 10)
    }
  }, [props.value])

  useEffect(() => {
    if (props.autoCompleteValue && props.autoCompleteValue.length === 1 && props.autoCompleteValue[0] === value) {
      setState({
        ...state,
        autoCompleteValue: []
      })
    } else {
      setState({
        ...state,
        autoCompleteValue: props.autoCompleteValue
      })
    }

  }, [props.autoCompleteValue])

  useEffect(() => {
    setState({ ...state, errorText: props.errorText })
  }, [props.errorText])

  const setError = (value:string, ignoreBlank:boolean=false, refCallFlag:boolean=false):IInputBoxRule => {
    const error = validate(value, ignoreBlank, refCallFlag)
    setState({ 
      ...state, 
      errorText: error.invalidateMessage,
      errorLevel: error.level ? error.level : TEXT_INPUT_RULE_LEVEL.ERROR
    })
    if (props.onValidate) props.onValidate(error)
    return error
  }

  const validate = (value:string, ignoreBlank:boolean=false, refCallFlag:boolean=false):IInputBoxRule => {
    let result:IInputBoxRule = {
      basis: TEXT_INPUT_RULE.REQUIRED,
      invalidateMessage: ''
    }
    if (props.rules) {
      for (let eachRule of props.rules) {
        switch (eachRule.basis) {
          case TEXT_INPUT_RULE.REGEXP:
            if (typeof(eachRule.rule) === 'number') break
            if (typeof(eachRule.rule) === 'boolean') break
            if (typeof(eachRule.rule) === 'function') break
            if (!result.invalidateMessage && value && eachRule.rule && RegExp(eachRule.rule).test(value) === false) result = eachRule
            break
          case TEXT_INPUT_RULE.REGEXP_NOT:
            if (typeof(eachRule.rule) === 'number') break
            if (typeof(eachRule.rule) === 'boolean') break
            if (typeof(eachRule.rule) === 'function') break
            if (!result.invalidateMessage && value && eachRule.rule && RegExp(eachRule.rule).test(value) === true) result = eachRule
            break
          case TEXT_INPUT_RULE.REQUIRED:
            if (eachRule.rule === false) break
            if (!result.invalidateMessage && value.trim().length === 0 && ignoreBlank === false) result = eachRule
            break
          case TEXT_INPUT_RULE.REQUIRED_IF_SOMETHING_EXISTS:
            if (eachRule.ref && eachRule.ref.current !== null && eachRule.ref.current.getValue && eachRule.ref.current.validate) {
              const targetValue = eachRule.ref.current.getValue()
              if ((!targetValue && !value) || (targetValue && value) || (targetValue && !value && ignoreBlank === true)) {
                let c = eachRule.ref.current
                delay(() => {
                  if (eachRule.ref && refCallFlag === false) c.validate(true, true)
                }, 10)
              } else if (!targetValue && value) {
                eachRule.ref.current.setErrorMessage(eachRule.refsInvalidateMessage)
              } else if (targetValue && !value) {
                result = eachRule
              }
            }
            break
          case TEXT_INPUT_RULE.LESS_THEN:
            if (!result.invalidateMessage && value && Number(value) >= Number(eachRule.rule)) result = eachRule
            break
          case TEXT_INPUT_RULE.GREATER_THEN:
            if (!result.invalidateMessage && value && Number(value) <= Number(eachRule.rule)) result = eachRule
            break
          case TEXT_INPUT_RULE.FUNCTION:
            if(eachRule.rule && typeof(eachRule.rule) === 'function') {
              let funcResult:boolean = eachRule.rule(value, eachRule)
              if (eachRule.ref && eachRule.ref.current !== null && eachRule.refsInvalidateMessage) {
                eachRule.ref.current.setErrorMessage(funcResult ? eachRule.refsInvalidateMessage : '')
              }
              if (!result.invalidateMessage && funcResult) {
                result = eachRule
              }
            }
            break
        }
      }
    }
    return result
  }

  const inputIdx = props.idx ? props.idx : null
  return (
    <InputBoxFragment style={{ width : props.width }} ref={ref}>
      <div className={inputClass}>
        <input type={props.type} id={props.id} ref={inputRef}
          placeholder={props.placeholder} 
          maxLength={props.maxLength}
          disabled={props.disabled} 
          readOnly={props.readonly}
          autoComplete={props.useSystemAutoComplete ? 'on' : 'off'}
          value={value}
          onFocus={() => {setState({ ...state, focusInput: true })}}
          onBlur={() => {setState({ ...state, focusInput: false })}}
          onChange={(e) => {
            setValue(e.target.value)
            setError(e.target.value, true)
            if (props.onChange) props.onChange(props.id, e.target.value, inputIdx)
          }}
          onKeyDown={(e) => {
            if (props.possibleRegExp && !props.possibleRegExp?.test(e.key) && e.key !== 'Backspace') {
              e.preventDefault()
              return
            }
          }}
          onKeyUp={(e) => {
            if(e.key === 'Enter') {
              inputRef.current.blur()
            }
          }}
           />
          {
            String(value).length && !props.disabled && props.deleteButtonFlag === true ? <button className="btnErase" onClick={(e) => {
              setValue('')
              setError('', true)
              if (props.onChange) props.onChange(props.id, '', inputIdx)
            }}>
              <img src="/images-v2/input-del.png" alt="delete" />
            </button> : false
          }
        {props.maxLength ? <p className="count"><span>{props.value !== undefined && props.value !== null ? String(value).length : '0'}</span> / {props.maxLength}</p> : false }
        {props.unit ? <p className="unit">{props.unit}</p> : false }
        {props.search ? <button className="btnSearch" onClick={(e) => {
          if (props.onClickSearch) props.onClickSearch(props.id, value, inputIdx)
        }}><img src={props.shape === TEXT_INPUT_SHAPE.NORMAL ? '/images-v2/input-search.png' : '/images-v2/input-search-s.png' } alt="search"/>
        </button> : false }
        {props.button ? <Button size={BUTTON_SIZE.SMALL} disabled={props.disabled || props.buttonDisabled} onClickButton={() => {
          if (props.onClickButton) props.onClickButton(props.id, value, inputIdx)
        }}>{props.button}</Button> : false }
      </div>
      {
        state.autoCompleteValue.length > 0 && value !== '' && state.focusInput === true ? 
          <div className="dropWrap">
            { state.autoCompleteValue.map((eachValue:string|number, idx:number) => 
              <button key={idx} onMouseDown={():void => {
                setValue(eachValue)
                if (props.onChange) props.onChange(props.id, eachValue, inputIdx)
              }} dangerouslySetInnerHTML={{__html: String(eachValue).replace(String(value), `<b>${value}</b>`)}} ></button>)}
          </div> : false
      }
      {state.errorText ? 
        <p className={"message " + state.errorLevel + (props.shape===TEXT_INPUT_SHAPE.UNDERLINE ? ' underline' : '')}>{state.errorText}</p> : 
        props.helpText ? 
          <p className="message help">{props.helpText}</p> : 
          false
      }
    </InputBoxFragment>
  )
})

InputBox.defaultProps = defaultProps

const InputBoxFragment = styled.div`
  position: relative;
  .inputBox{display:flex;align-items:center;gap:8px;padding: 8px 8px 8px 12px;box-sizing:border-box;border-radius: 6px;border: 1px solid #D5D5DA;color: #333;transition: all 0.2s;}
  .inputBox:hover:not(.disable, .error),
  .inputBox.focus:not(.disable, .error){border-color: #646469;}
  .inputBox.normal{height:40px;font-size: 14px;}
  .inputBox.normal .btnSearch img{width:24px;}
  .inputBox.small{height:32px;font-size: 13px;}
  .inputBox.small .count{font-size: 12px;}
  .inputBox.small .btnSearch img{width:14px;}
  .inputBox.underline{border:0; border-radius:0; border-bottom: 1px solid #D5D5DA; padding-left:0;}
  .inputBox.underline input::placeholder{color:#ADADAD;font-weight:400;font-size:14px;line-height:16.71px;}
  .inputBox.underline input {padding-block:0; padding-inline:0; outline:none;}
  .inputBox.unit input{text-align:right;}
  .inputBox.fill{background: #FAFAFB;}
  .inputBox.error{border-color:#FF0000;}

  .inputBox.ignore, 
  .inputBox.disable, 
  .inputBox.readonly {background:#FAFAFB;}

  .inputBox.ignore .btnText,
  .inputBox.ignore .btnSearch{opacity:0.2;}
  .inputBox.disable .btnText,
  .inputBox.disable .btnSearch{opacity:0.2;}

  .inputBox.ignore input,
  .inputBox.ignore .count,
  .inputBox.ignore .unit,
  .inputBox.disable input,
  .inputBox.disable .count,
  .inputBox.disable .unit{opacity:0.3;}

  .inputBox.ignore input,
  .inputBox.disable input,
  .inputBox.readonly input { pointer-events:none }

  .inputBox input{flex: 1;font-size: 14px;color: #333;background: transparent;}
  .inputBox button.small{flex:none;}
  .inputBox .count{padding-right:4px;font-size: 13px;color:#878791;}
  .inputBox .count span{color: #333;}
  .inputBox .unit{padding-right:4px;font-size: 14px;}
  .inputBox .btnErase img{width:24px;}
  .message{margin-top:8px;font-size: 12px;line-height: 20px;color: #878791;letter-spacing: 0;}
  .message.help{color: #878791;}
  .message.error{color: #FF0000;}
  .message.error.underline{margin:0;} //이 부분을 수정해야하는 때가 온다면(underline 형태를 로그인 외의 페이지에서 사용하게 된다면), 로그인에 사용되는 inputBox는 컴포넌트를 따로 만들거나, inputBox 내에 에러메시지를 띄우지 않는 형태를 추가해야합니다.
  .dropWrap { overflow:auto; position:absolute;top:46px;left:0; right:0; max-height:168px;padding:6px;border-radius: 6px;border:1px solid #646469; background:#fff;box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50);box-sizing:border-box;z-index:101;}
  .dropWrap button { display:block;width:100%; padding:12px; font-size:14px; text-align:left; word-break:break-all;transition: all 0.3s;color: #333;font-weight:400;}
  .dropWrap button:hover { background:#fafafa; }
`

export default InputBox