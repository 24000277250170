import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SUBPOPUP_TYPE } from '../../modal/sub/SubPopup'

export enum SUB_BUTTON_SIZE {
  SMALL = 'small',
  NORMAL = 'normal'
}

interface ISubPopupTableProps {
  title?:string
  tableHeaders:string[]
  datas:any[]
  colWidth?:number[]
  isTableBtn?:boolean
  closeBtn?:boolean
  numbering?:boolean

  width?:number
  padding?:string
  type?:SUBPOPUP_TYPE
  buttonSize?:SUB_BUTTON_SIZE

  colAlignCenter?:boolean
  onRight?:boolean
}
interface ISubPopupTableState {
  keys:string[],
  isOpen:boolean,
  width:number
}

const defaultProps = {
  buttonSize: SUB_BUTTON_SIZE.NORMAL,
}

const SubPopupTable = (props: ISubPopupTableProps) => {
  const [ state, setState ] = useState<ISubPopupTableState>({
    keys: [],
    isOpen: false,
    width: 0
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    let arr:any[] = []
    if (props.tableHeaders.length > 1 && props.datas.length > 0) {
      arr = Object.keys(props.datas[0])
    }
    setState({
      ...state,
      keys: arr,
      width: props.width ? props.width : 42+200*props.tableHeaders.length+32
    })
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen:!state.isOpen })
  }

  return (
    <SubPopupTableFragment>
      <div className={props.isTableBtn ? "tableWrap" : "normalWrap"}>
        <button onClick={onBtnClick} ref={modalBtnRef} className={props.buttonSize}>
          {state.isOpen && state.isOpen === true ?
            <img src='/images-v2/table-more-small.svg' alt='more' className='moreIconButton' />
            : <img src='/images-v2/table-more-small.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      <>
        {state.isOpen && state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className={"commonModal" + (props.onRight === true? " onRight" : " onLeft")} style={{ 'width': props.width ? props.width : state.width, 'padding': props.padding ? props.padding : '12px 16px' }}>
              {props.title ? 
                <div className='titleWrap'>
                  <div style={{padding:'3.5px 0'}}>{props.title}</div>
                  {props.closeBtn ? <div className='closeBtn' onClick={closeHandler}><img src="/images-v2/popup-exit-black.svg" alt="exit" /></div> : false}
                </div> : false}
              <div className="scrollWrap">
                <table>
                  {props.colWidth && props.colWidth.length > 0 ?
                    <colgroup>
                      {props.colWidth.map((col, index) =>
                        <col key={index} width={`${col}px`}></col>
                      )}
                    </colgroup>
                    :
                    <>
                      {props.tableHeaders.length > 1 ?
                        <colgroup>
                          <col width="42px"></col>
                          <col width="200px"></col>
                          <col width="200px"></col>
                        </colgroup>
                        : <colgroup>
                          <col width="42px"></col>
                        </colgroup>
                      }
                    </>
                  }
                  <thead><tr>
                    {
                      props.tableHeaders.length > 0 ?
                        <>
                          <th></th>
                          {props.tableHeaders.map((title: string, idx: number) => (
                            <th key={idx}>{title}</th>
                          ))}
                        </>
                        : <th>{props.tableHeaders[0]}</th>
                    }
                  </tr></thead>
                  <tbody>
                    {
                      props.datas && props.datas.map((data: any, idx: number) => (
                        <tr key={idx}>
                          {props.numbering === false ? false : <td className="textAlignCenter">{idx + 1}</td>}
                          {(() => {
                            switch (props.type) {
                              // case SUBPOPUP_TYPE.ENVIRONMENT:
                              //   return (<td className="textAlignStart">{data.key}={data.value}</td>)
                              case SUBPOPUP_TYPE.EMAIL:
                                return (<><td className="textAlignStart">{data.name ? <>{data.name}</> : <>(이름 없음)</>}</td><td>{data.email}</td></>)
                              // case SUBPOPUP_TYPE.NODE:
                              //   return (<td className="textAlignStart">{data.ip} / {data.name}</td>)
                              // case SUBPOPUP_TYPE.VOLUME:
                              //   return (<td className="textAlignStart">{data.name}</td>)
                              // case SUBPOPUP_TYPE.MODEL:
                              //   return (<td className="textAlignStart">{data.model}</td>)
                              // case SUBPOPUP_TYPE.MODELSIZE:
                              //   return (<td className="textAlignStart">{data.model} {data.size}</td>)
                              // case SUBPOPUP_TYPE.BLOCKTYPE:
                              //   return (<td className="textAlignStart">
                              //     <h3 style={{ marginBottom: '10px' }}>블록타입 {idx + 1}:{data.gpuType}</h3>
                              //     <div className="bothEnd">
                              //       <div className="half">
                              //         <div>CPU: {data.cpu} 개</div>
                              //         <div>RAM: {data.mem} {data.memUnit}</div>
                              //       </div>
                              //       <div className="half">
                              //         <div>GPU: {data.gpuPer} %</div>
                              //         <div>GPUmem: {data.gpuMem} {data.gpuMemUnit}</div>
                              //       </div>
                              //     </div>
                              //   </td>)
                              // case SUBPOPUP_TYPE.GPUSPEC:
                              //   return (<><td>{String(data.key).replace('GPU', 'GPU ')}:</td><td>{data.value}</td></>)
                              // case SUBPOPUP_TYPE.VOLUME_OPS:
                              //   return (<td className="textAlignStart">{data.name} / {data.size}</td>)
                              case SUBPOPUP_TYPE.NORMAL:
                              default:
                                return (<>
                                  {
                                    props.tableHeaders.length > 1 ?
                                      state.keys?.map((key: string, idx2: number) => (
                                        <td key={idx2} className={props.colAlignCenter === true ? 'textAlignCenter' : 'textAlignStart'}>{data[key]}</td>
                                      ))
                                      :
                                      <td className={props.colAlignCenter === true ? 'textAlignCenter' : 'textAlignStart'}>{data}</td>
                                  }
                                </>)
                            }
                          })()}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          : false}
      </>
    </SubPopupTableFragment>
  )
}

SubPopupTable.defaultProps = defaultProps

const SubPopupTableFragment = styled.div`
  color:#333333;
  .tableWrap {height:100%; display:flex; flex-direction:row; justify-content:center; align-items:center;}
  .normalWrap {}
  button.normal {width:16px; height:16px; display:block;}
  button.small {width:14px; height:14px; display:block;}
  button.norma img.moreIconButton {width:16px !important; height:16px; padding-top:0px; display:block;}
  button.small img.moreIconButton {width:14px !important; height:14px; padding-top:0px; display:block;}

  .subModalPopupWrap {position:relative; align-items:center; z-index:201;}
  .subModalPopupWrap .commonModal{position:absolute; margin-top:6px; max-height:350px; flex-direction:column;box-sizing:border-box;padding:12px 16px;border-radius:6px;box-shadow:0 12px 16px 0 #1B1D1F0D, 0 6px 12px 0 #1B1D1F0D;border:solid 1px #DFDFDF;background:#fff;}
  .subModalPopupWrap .onLeft{right:0px;}
  .subModalPopupWrap .onRight{left:0px;}
  .subModalPopupWrap .commonModal .titleWrap{ height:24px; display:flex; justify-content:space-between; align-items:center; font-size:14px; line-height:16.71px; font-weight:700; margin-bottom:12px;}
  .subModalPopupWrap .commonModal .titleWrap *{display:block;}
  .subModalPopupWrap .commonModal .titleWrap .closeBtn{cursor:pointer;}
  .subModalPopupWrap .commonModal p{font-size:14px;line-height:24px; vertical-align:middle;}
  .subModalPopupWrap .commonModal .modalTitle + .modalTitle{margin-top:10px;}
  .subModalPopupWrap .commonModal .modalTitle{font-weight:bold;}
  .subModalPopupWrap .commonModal .modalTitle .content{font-weight:normal;}
  .scrollWrap{max-height:236px; /* max-height:280px; */ overflow-y:auto;}

  .subModalPopupWrap .commonModal table{width:100%; border-collapse:collapse; border-top:1px solid #333; color:#333; font-size:14px; line-height:16.8px; font-weight:400;}
  .subModalPopupWrap .commonModal table thead{border-bottom:1px solid #B4B4BE;}
  .subModalPopupWrap .commonModal table thead th{height:30px;padding:0; font-size:13px; line-height:15.6px; font-weight:inherit; color:#646469;}
  .subModalPopupWrap .commonModal table thead th+th{border-left:1px solid #ebebeb;}
  .subModalPopupWrap .commonModal table tbody td{padding:11.5px 12px;background: #fff;border-bottom: 1px solid #ebebeb;word-break:break-all;white-space:pre-line;}
  .subModalPopupWrap .commonModal table tbody td{text-align:left;}
  .subModalPopupWrap .commonModal table tbody td+td{border-left:1px solid #ebebeb;}

  /* 상위 테이블 css에 영향받는 요소들 재설정 */
  .subModalPopupWrap .commonModal table thead th{background-color:white;}
  .subModalPopupWrap .commonModal table tr td{color:#333333;}
  .subModalPopupWrap .commonModal table td{padding: 0; border-right:0;}

  //.subModalPopupWrap .commonModal table tbody tr:hover td{background-color:#fafafa;}

  .textAlignStart {text-align:start !important;}
  .textAlignCenter {text-align:center !important;}

  .half {width:50%;}
`

export default SubPopupTable