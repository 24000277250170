import Lottie from "lottie-web"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

interface ILoadingAnimationProps {
}

interface ILoadingAnimationState {
}

const LoadingAnimation = (props: ILoadingAnimationProps) => {
  const [ state, setState] = useState<ILoadingAnimationState>({})

  const loadingAnimationRef = useRef<any>()

  useEffect(() => {
    Lottie.loadAnimation({
      container: loadingAnimationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../assets/loader_spinner.json')
    })
  }, [])

  return (
    <LoadingAnimationFragment>
      <div className='animation' ref={loadingAnimationRef}></div>
    </LoadingAnimationFragment>
  )
}

const LoadingAnimationFragment = styled.div`
.animation { position:relative;top:-60px;margin:0 auto;display:inline-block;width:180px;height:180px;display:flex;justify-content:space-between;align-items:center; }
`

export default LoadingAnimation
