import React, { Component } from 'react'
import Highcharts from 'highcharts/highcharts.js'
import HighchartsReact from 'highcharts-react-official'
import { IChartSeries } from '../../../interfaces/Chart'
import Hash from 'object-hash'

interface ILineChartProps {
  height:number
  categories?:string[]
  data:IChartSeries[]
  max?:number
  min?:number
  tickAmount?:number
  tickInterval?:number
  unit?:string
  datetime?:boolean
  legend?:boolean
  doubleY?:boolean
  lineY?:number
  title?:string
  subTitle?:string
  sharedTooltip?:boolean
  showTooltipUnit?:boolean
  useTooltipHtml?:boolean
  yAxisSingle?:boolean
  hideYAxisLabel?:boolean
  xAxisLabelStyle?:Object
  xAxisPosY?:number
  zoomType?:string
  toolTipFormat?:Function
  minX?:number //timestamp
  maxX?:number //timestamp
  format?:string
  enableTooltip?:boolean
}

Highcharts.setOptions({
  colors: ['#BECC23', '#E68C4F', '#0F8D62', '#583577', '#B48E04', '#677FA4', '#CDAC80', '#502BBB', '#7C2A03', '#025F5C', '#590608', '#848614', '#B8718A', '#0A6899', '#B83A40', '#013A50', '#F45649', '#072D9F', '#1293E9', '#E2589F', '#039CA4', '#AA80E9', '#7DB498'],
  lang: {
    thousandsSep: ','
  }
})

export default class LineChart extends Component<ILineChartProps> {
  private unit:string = ''
  private chartData:any = {
    chart: {
      here: this,
      type: 'line',
      zoomType: this.props.zoomType,
      height: this.props.height,
      spacingTop: this.props.unit ? 20 : 5,
      spacingLeft: 0,
      spacingRight: 0,
      marginLeft: 62,
      marginRight: this.props.doubleY ? 62 : 20,
      events: {
        render: function(this:any) {
          const props = this.userOptions.chart.here.props
          if (props.subTitle) {
            const label = this.renderer.label(props.subTitle, null, -2).add()
            label.attr({
              x: this.plotWidth + this.plotLeft - label.width,
              zIndex: 3
            });
          }
        }
      }
    },
    title: {
      text: this.props.title ? this.props.title : '',
      margin: 8,
      style: {
        fontSize: '12px',
      }
    },
    legend: {
      enabled: this.props.legend ? this.props.legend : false,
      maxHeight: 69 //this.props.legendMaxHeight ? this.props.legendMaxHeight : undefined
    },
    xAxis: {
      type: this.props.datetime ? 'datetime' : '',
      dateTimeLabelFormats: {
        day: '%m/%d',
      },
      categories: this.props.categories ? this.props.categories : null,
      lineWidth: 1,
      gridLineWidth: typeof this.props.lineY === 'number' ? this.props.lineY : 1,
      min: this.props.minX? this.props.minX: null,
      max: this.props.maxX? this.props.maxX: null,
      //width: '100%'
      labels: {
        style: this.props.xAxisLabelStyle ? this.props.xAxisLabelStyle: {},
        y: this.props.xAxisPosY ? this.props.xAxisPosY : null
      },
      lineColor: 'rgb(230, 230, 230)',
    },
    tooltip: {
      enabled: this.props.enableTooltip !== undefined ? this.props.enableTooltip : true,
      shared: this.props.sharedTooltip ? this.props.sharedTooltip : false,
      valueSuffix: this.props.showTooltipUnit ? this.props.unit : false,
      useHTML: this.props.useTooltipHtml ? this.props.useTooltipHtml : false,
      formatter: this.props.toolTipFormat,
      xDateFormat: '%m/%d %H:%M',
    },
    yAxis: this.props.yAxisSingle ?
    [{
      title: {
        text: this.props.unit ? `(${this.props.unit})` : null,
        align: 'high',
        offset: 0,
        rotation: 0,
        y: -10,
        x: -5
      },
      max: this.props.max ? this.props.max : null,
      min: this.props.min !== undefined ? this.props.min : null,
      floor: this.props.min !== undefined ? this.props.min : null,
      lineWidth: 1,
      tickInterval: this.props.tickInterval ? this.props.tickInterval : undefined,
      tickAmount: this.props.tickAmount ? this.props.tickAmount : 5,
      labels: {
        enabled: this.props.hideYAxisLabel ? null : true,
      },
      lineColor: 'rgb(230, 230, 230)',
    }] :
    [{
      title: {
        text: this.props.unit ? `(${this.props.unit})` : null,
        align: 'high',
        offset: 0,
        rotation: 0,
        y: -10,
        x: -5
      },
      max: this.props.max ? this.props.max : null,
      min: this.props.min !== undefined ? this.props.min : null,
      floor: this.props.min !== undefined ? this.props.min : null,
      lineWidth: 1,
      tickInterval: this.props.tickInterval ? this.props.tickInterval : undefined,
      tickAmount: this.props.tickAmount ? this.props.tickAmount : 5,
      labels:{
        enabled: this.props.hideYAxisLabel ? null : true,
        format:this.props.format? `{text}` + this.props.format : null
      },
      lineColor: 'rgb(230, 230, 230)',
    }, {
      title: {
        text: this.props.unit ? `(${this.props.unit})` : null,
        align: 'high',
        offset: 0,
        rotation: 0,
        y: -10,
        x: -5
      },
      linkedTo: this.props.doubleY ? 0 : null,
      max: this.props.max ? this.props.max : null,
      min: this.props.min !== undefined ? this.props.min : null,
      floor: this.props.min !== undefined ? this.props.min : null,
      lineWidth: 1,
      tickInterval: this.props.tickInterval ? this.props.tickInterval : undefined,
      tickAmount: this.props.tickAmount ? this.props.tickAmount : 5,
      opposite: true,
      labels:{
        enabled: this.props.hideYAxisLabel ? null : true,
        format:this.props.format? `{text}` + this.props.format : null
      },
      lineColor: 'rgb(230, 230, 230)',
    }],
    plotOptions: {
      series: {
        events: {
          legendItemClick: (e:Event) => {
            e.preventDefault()
            this.legendItemClick(e.target)
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    series: this.props.data
  }
  private chartComponent:any = React.createRef()
  
  constructor(props:any) {
    super(props)
    this.unit = this.props.unit ? this.props.unit : ''
    this.legendItemClick = this.legendItemClick.bind(this)
  }

  componentDidUpdate(prev:any) {
    //if (Hash.MD5(prev.data) !== Hash.MD5(this.props.data)) {  //이렇게 하면 데이터가 prev.data 값이랑 동일한 경우 카테고리 등이 바뀌지 않아서...
    if (Hash.MD5(prev) !== Hash.MD5(this.props)) {
      this.chartComponent.current?.chart.update({
        xAxis:{
          categories: this.props.categories ? this.props.categories : null,
          min: this.props.minX? this.props.minX: null,
          max: this.props.maxX? this.props.maxX: null
        },
        yAxis:{
          max: this.props.max ? this.props.max : null
        }
        //series: this.props.data
      }, true, false, true)

      // series가 변경될 경우 지우고 다시 추가
      while (this.chartComponent.current?.chart.series.length > 0){
        this.chartComponent.current?.chart.series[0].remove()
      }
      for (let idx in this.props.data){
        this.chartComponent.current?.chart.addSeries({name:this.props.data[idx].name, data:this.props.data[idx].data}) 
      }
    }
  }
  
  private legendItemClick(clickedItem:any) {
    let chart = this.chartComponent.current?.chart
    // (1) show가 2개 이상인가? 클릭된 아이템을 제외한 나머지 아이템 중 show인 아이템이 있는지 체크하기 위함
    let showCnt = 0
    chart.series.forEach((s:any) => {
      if(s.visible) {
        showCnt++
      }
    })

    // (2) 적용
    if(clickedItem.visible) {
      if(showCnt === chart.series.length || showCnt < 2) {
        // 나머지 모두 hide면 나머지 show, 나머지 모두 show면 hide
        chart.series.forEach((s: any) => {
          if (s !== clickedItem && s.visible) {
            s.hide()
          } else {
            s.show()
          }
        })
      } else { // 나머지 일부만 hide면 클릭된 아이템 hide
        chart.series.forEach((s:any) => {
          if(s === clickedItem) {
            s.hide()
          }
        })  
      }
    }
    else {      
      // 나머지는 건드리지 않고, 클릭된 아이템만 show
      chart.series.forEach((s:any) => {
        if(s === clickedItem) {
          s.show()
        }
      })
    }
  }

  public render():React.ReactElement {
    return (
      <HighchartsReact highcharts={Highcharts} options={this.chartData} ref={this.chartComponent} />
    )
  }
}
