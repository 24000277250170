import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { SERVER_STATUS } from "../../pagesDevAdmin/Monitoring"


export interface INodeTooltip {
  status:SERVER_STATUS
  des:string[]
}

interface INodeTooltipProps {
  tooltip:INodeTooltip[]
  img?:string
}

interface INodeTooltipState {
  visible:boolean
}

const NodeTooltip = (props:INodeTooltipProps) => {
  const [state, setState] = useState<INodeTooltipState>({
    visible:false
  })

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return() => {
      document.removeEventListener('mousedonw', clickOutsideHandler)
    }
  },[state.visible])

  const tooltipRef = useRef<any>()

  const clickOutsideHandler = (event:any) => {
    if(!tooltipRef.current?.contains(event.target) && state.visible) {
      setState({
        visible:false
      })
    }
  }

  const renderTooltipImg = (status:SERVER_STATUS) => {
    let img:string = ''
    switch(status){
      case SERVER_STATUS.CRITICAL:
        img = 'alert-error.svg'
        break;
      case SERVER_STATUS.CONCERN:
        img = 'alert-warn.svg'
        break;
      case SERVER_STATUS.NORMAL:
      default:
        img = ''
    }
    return img
  }


  return (
    <NodeTooltipFragment>
      <button ref={tooltipRef} onClick={(e) => {
        setState({...state, visible: !state.visible})
      }}> 
        <img className="tooltipIcon" src={`/images-v2/${props.img}`} alt="alert" />
      </button>
      { state.visible ? 
        <div className="des">
          { props.tooltip.length > 0 ? props.tooltip.map((tooltip, idx) => {
            return(<div key={idx} className="tooltopBox">
              <div className="tooltipTitle">
                <img className="tooltipIcon" src={`/images-v2/${renderTooltipImg(tooltip.status)}`} alt="alert" />
                <p>{tooltip.status === SERVER_STATUS.CRITICAL ? '경고' : '주의'}</p>
              </div>
              { tooltip.des.length > 0 ? tooltip.des.map((des, jdx) => {
                return(<div key={jdx} className="tooltipDes">
                <p>-</p>
                <p>{des}</p>
              </div>)
              }) : false}
            </div>)
          }) : false}
        </div>
       : false }
    </NodeTooltipFragment>
  )
}

const NodeTooltipFragment = styled.div`
position:relative; display:block;
button {width:14px;height:14px;}
.tooltipIcon{width:14px;height:14px;}
.des{ position:absolute; width:262px; padding:15px; background:#FFFFFF; border:solid 1px #646469; border-radius:6px; margin-top:5px; z-index:1; box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50);}
.des .tooltipTitle{display:flex;align-items:center; gap:6px; font-size:14px; line-height:16.71px; font-weight:600;}
.des .tooltipDes{display:flex;gap:6px;font-size:13px;line-height:20px;font-weight:400;margin-top:8px;}
.des .tooltopBox + .tooltopBox{margin-top:16px;}
`

export default NodeTooltip