import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { NavLink, Outlet, Link, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import NavigationOpsAdmin from './layout/NavigationOpsAdmin'
import NavigationOps from './layout/NavigationOps'
import { useRecoilState } from 'recoil'
import { IUserInfo, userInfoState } from '../states/userInfoState'
import { User } from '../model/User'
import { IUserAuthInfo, authState } from '../states/authStates'
import ApiController from '../controller/ApiController'
import ApiEvent from '../events/ApiEvent'
import ToastEvent from '../events/ToastEvent'
import PageEvent from '../events/PageEvent'
import WebSocketEvent from '../events/WebSocketEvent'
import PopupController from '../controller/PopupController'
import WebSocketController from '../controller/WebSocketController'
import BillingAPI from "../model/Billing"
import Select from './components/ui/Select'
import ISelectOption from '../interfaces/SelectOption'
import { ISettings, settingState } from '../states/settingState'
import SchedulerEvent from '../events/SchedulerEvent'
import { Priority } from '../model/Priority'
import ModalEvent from '../events/ModalEvent'

import lottie from 'lottie-web'
import { ALERT_ORDER_BY, ALERT_TYPE, Alert } from '../model/Alert'
import { SORT_ORDER } from '../model/BaseDataType'
import ModalMessage from './components/ModalMessage'
import Modal from './components/Modal'
import { ResourceGroup } from '../model/ResourceGroup'
import Config from '../Config'

interface ILayoutProps {
  // location:Location
}

interface ILayoutState {
  alertList:any[] //IAlertListItem[]
  alertPaging: {
    hasMore:boolean
    page:number
    total:number
  }
}

interface ISettingFlag {
  schedulerFlag:boolean
  loadingMaskFlag:boolean
  autoRefreshFlag:boolean
}

const Layout = (props:ILayoutProps) => {
  const [ authInfo, setAuthInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo, setUserInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  const [ settingInfo, setSettingInfo ] = useRecoilState<ISettings|null>(settingState)
  const settingInfoRef = useRef(settingInfo)

  const navigate = useNavigate()
  const user = User()
  const popupController = PopupController.getInstance()
  const apiController = ApiController.getInstance()
  const webSocketController = WebSocketController.getInstance()

  // const [ intervalTimer, setIntervalTimer ] = useState<any>(null) // 5초 새로고침 숨김처리
  const [ loadingFlag, setLoadingFlag ] = useState<boolean>(false)
  const [ refreshButtonShowFlag, setRefreshButtonShowFlag ] = useState<boolean>(false) // gnb 스타일링용 임시 false 처리 (당분간 사용 못함)
  const [ dropAlert, setDropAlert ] = useState<boolean>(false)
  const [ alertCount, setAlertCount ] = useState<number>(0)
  const [ dropMy, setDropMy ] = useState<boolean>(false)
  // const [ page, setPage ] = useState<number>(1)
  const [ loadingCount, _setLoadingCount ] = useState<number>(0)
  const loadingCountRef = useRef(loadingCount)
  const setLoadingCount = (data:any) => {
    loadingCountRef.current = data
    _setLoadingCount(data)
  }
  const [ state, _setState ] = useState<ILayoutState>({
    alertList: [],
    alertPaging: {
      page: 1,
      hasMore: true,
      total: -1
    }
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  const [ connectionInfo, setConnectionInfo ] = useState<any[]>([])

  const autoRefreshOptions:ISelectOption[] = [
    { label: '자동 Off', value: 'OFF' },
    { label: '5sec', value: 'ON' }
  ]

  const dropAlertBtnRef = useRef<any>()
  const dropAlertRef = useRef<any>()
  const dropMyBtnRef = useRef<any>()
  const dropMyRef = useRef<any>()
  const loadingAnimationRef = useRef<any>()

  let dmsTimeoutId:NodeJS.Timeout

  useEffect(() => {
    // webSocketController.open(Config.env.REACT_APP_SOCKET_PROTOCOL+"://"+Config.env.REACT_APP_BASE_URL+":"+Config.env.REACT_APP_SOCKET_PORT+"/ws/"+authInfo?.id)
    webSocketController.open(Config.env.REACT_APP_SOCKET_PROTOCOL+"://"+Config.env.REACT_APP_SOCKET_URL+"/ws/"+authInfo?.id)

    webSocketController.addEventListener(WebSocketEvent.WS_TOAST, toastEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_CONFIRM, confirmEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_CONFIRM_CUSTOM, confirmCustomEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_DISCONNECTED, websocketReconnectHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    apiController.addEventListener(ApiEvent.LOAD_START, pageLoadStartHandler)
    apiController.addEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)
    apiController.addEventListener(ApiEvent.AUTH_EXPIRED, logoutHandler)
    window.addEventListener('refreshalarm', updateAlertCounter)    
    // window.addEventListener(SchedulerEvent.START, schedulerHandler) //스케쥴러 관련 주석처리
    // window.addEventListener(SchedulerEvent.STOP, schedulerHandler) //스케쥴러 관련 주석처리
    // window.addEventListener(SchedulerEvent.UPDATE, schedulerHandler) //스케쥴러 관련 주석처리
    // window.addEventListener(PageEvent.SHOW_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
    // window.addEventListener(PageEvent.HIDE_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
    // window.addEventListener(PageEvent.STOP_REFRESH, stopRefreshHandler) //5초 새로고침 숨김처리
    document.addEventListener('mousedown', resetDmsHandler)
    resetDmsHandler()

    //schedulerHandler(new SchedulerEvent(SchedulerEvent.UPDATE)) //스케쥴러 관련 주석처리

    if (loadingAnimationRef.current !== null) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData:require('../assets/loader.json')
      })
    }

    getConnectionInfo()
    updateAlertCounter()
    // setIntervalTimer(setInterval(autoRefreshHandler, 5000)) // 5초 새로고침 숨김처리
    // window.dispatchEvent(new PageEvent(PageEvent.LAYOUT_INIT_FINISHED)) // 5초 새로고침 숨김처리

    return () => {
      webSocketController.removeEventListener(WebSocketEvent.WS_TOAST, toastEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_CONFIRM, confirmEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_CONFIRM_CUSTOM, confirmCustomEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_DISCONNECTED, websocketReconnectHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      apiController.removeEventListener(ApiEvent.LOAD_START, pageLoadStartHandler )
      apiController.removeEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)
      apiController.removeEventListener(ApiEvent.AUTH_EXPIRED, logoutHandler)
      window.removeEventListener('refreshalarm', updateAlertCounter)
      // window.removeEventListener(SchedulerEvent.START, schedulerHandler) //스케쥴러 관련 주석처리
      // window.removeEventListener(SchedulerEvent.STOP, schedulerHandler) //스케쥴러 관련 주석처리
      // window.removeEventListener(SchedulerEvent.UPDATE, schedulerHandler) //스케쥴러 관련 주석처리
      // window.removeEventListener(PageEvent.SHOW_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
      // window.removeEventListener(PageEvent.HIDE_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
      // window.removeEventListener(PageEvent.STOP_REFRESH, stopRefreshHandler) // 5초 새로고침 숨김처리
      // if (intervalTimer) setIntervalTimer(clearInterval(intervalTimer)) // 5초 새로고침 숨김처리
      document.removeEventListener('mousedown', resetDmsHandler)
    }
  }, [])

  useEffect(() => {
    if (authInfo?.userNo !== -1 && userInfo?.userNo === -1) {
      getMyInfo()
    }
    if (authInfo?.userNo !== -1 && !authInfo?.isAdmin && authInfo?.passwordInitialized === true || authInfo?.isPasswordExpired === true) {
      // 비밀번호 변경 화면으로 이동
      navigate('/mypage')
    }
  }, [authInfo])

  useEffect(() => {
    settingInfoRef.current = settingInfo
  }, [settingInfo])

  useEffect(() => { // 알림 버튼 껐다가 다시 켤 때, 초기화가 완전히 된 후 list 불러오도록 하기 위함!
    if (state.alertList.length === 0 && state.alertPaging.page === 1 && state.alertPaging.total !== 0 && dropAlert) {
      getAlertList()
    }
  }, [state.alertList])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [dropMy, dropAlert])
  
  const resetDmsHandler = () => {
    clearTimeout(dmsTimeoutId)
    dmsTimeoutId = setTimeout(logoutHandler, 1800000) // 30m
  }

  const clickOutsideHandler = (event:any) => {
    if (!dropMyBtnRef.current?.contains(event.target)
      && !dropMyRef.current?.contains(event.target) && dropMy) {
      setDropMy(false)
    }
    if (!dropAlertBtnRef.current?.contains(event.target)
      && !dropAlertRef.current?.contains(event.target) && dropAlert) {
      setDropAlert(false)
    }
  }

  const toastEventControl = (e:WebSocketEvent) => {
    let toastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
    toastEvent.payload = { message: e.payload.message }
    window.dispatchEvent(toastEvent)
  }

  const confirmEventControl = (e:WebSocketEvent) => {
    popupController.confirm(e.payload.message)
  }

  const confirmCustomEventControl = (e:WebSocketEvent) => {
    popupController.confirmCustom(e.payload.message, e.payload.action, e.payload.key)
  }

  const websocketReconnectHandler = () => {
    // console.log('ws reconnecting...')
    // webSocketController.open(Config.env.REACT_APP_SOCKET_PROTOCOL+"://"+Config.env.REACT_APP_SOCKET_URL+"/ws/"+authInfo?.id)
  }

  // const schedulerHandler = async (e:SchedulerEvent) => { //스케쥴러 관련 주석처리
  //   let priorityStatus
  //   switch(e.type) {
  //     case SchedulerEvent.START:
  //       priorityStatus = true
  //       break
  //     case SchedulerEvent.STOP:
  //       priorityStatus = false
  //       break
  //     case SchedulerEvent.UPDATE:
  //       try {
  //         priorityStatus = await Priority.getPriorityStatus()
  //       } catch(e) {
  //         popupController.confirm('에러가 발생했습니다.')
  //       }
  //       break
  //   }

  //   if (priorityStatus !== undefined) {
  //     setSettingInfo({
  //       autoRefreshFlag: settingInfo?.autoRefreshFlag || false,
  //       loadingMaskFlag: settingInfo?.loadingMaskFlag || true,
  //       schedulerFlag: priorityStatus
  //     })
  //     let e:SchedulerEvent = new SchedulerEvent(SchedulerEvent.UPDATED)
  //     e.payload = {schedulerFlag: priorityStatus}
  //     window.dispatchEvent(e)
  //   }
  // }

  const logoutHandler = ():void => {
    setDropMy(false)
    user.logout(false/* authInfo?.isOps */)
  }

  // my info
  const getMyInfo = async () => {
    if (authInfo?.userNo === -1) return
    try {
      const response:any = await user.getMyInfo(authInfo?.userNo || -1)
      let startTimestamp
      try {
        startTimestamp = await BillingAPI.getStartTimestamp()
      } catch(error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - e405d3')
      }
      setUserInfo({
        creationTimestamp: null,
        department: response.department !== null ? response.department : '',
        phoneNumber: response.phoneNumber !== null ? response.phoneNumber : '',
        userId: response.userId,
        email: response.email,
        name: response.name,
        userNo: response.userNo,
        permissionStatus: response.permissionStatus !== null ? response.permissionStatus : '',
        pw: null,
        resourceLimit: response.resourceLimit !== null ? response.resourceLimit : null,
        wsCount: response.wsCount !== null ? response.wsCount : null,
        startTimestamp: startTimestamp,
        additionalInfo: response.additionalInfo
      })

      // 관리자 로그인 시, 필수 입력 정보 없는 경우
      //if (authInfo?.isAdmin && (response.name === '' || response.email === '') && window.location.pathname !== '/mypage' && authInfo.lackOfAdminUserInfo){
      //  popupController.dialouge('시스템 이상 알림을 받기 위해 관리자의 이메일 등록이 필요합니다. 진행하시겠습니까?', 'gotomypage', 'gotomypage', '확인', '다음에 하기')
      //  setAuthInfo({
      //    ...authInfo,
      //    lackOfAdminUserInfo: false
      //  })
      //}
    } catch (error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6600cb')
    }
  }

  // alert
  const updateAlertCounter = async ():Promise<void> => {
    try {
      let alertInfo = await Alert.getAlertCount()
      if (alertInfo) {
        setAlertCount(alertInfo.numNewAlert)
      }  
    }
    catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 53e089')
    }
  }

  const loadMore = () => {
    // setState({
    //   ...state, 
    //   alertPaging: {
    //     ...state.alertPaging,
    //     page: state.alertPaging.page + 1
    //   }
    // })
    // updateAlertCounter()
    getAlertList(stateRef.current.alertPaging.page+1)
  }

  const getAlertList = async (page?:number):Promise<void> => {
    const to = Math.floor(new Date().getTime()/1000)
    const from = to-2630000
    const currentPage = page ? page : stateRef.current.alertPaging.page
    let data
    if(authInfo?.isAdmin) {
      try {
        // 아직 ops의 admin쪽 알람 리스트 api가 만들어지지 않아서 dev의 admin 알람 리스트 api 사용하는듯함 나중에 api, 에러코드 수정필요
        data = await Alert.getList(authInfo?.userNo || -1, from, to, currentPage, 50, ALERT_ORDER_BY.DATETIME, SORT_ORDER.DESC, [true, true, true], authInfo)
      } catch(error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - ecde69')
      }
    } else {
      try {
        data = await Alert.getList(authInfo?.userNo || -1, from, to, currentPage, 50, ALERT_ORDER_BY.DATETIME, SORT_ORDER.DESC, [true, true, true], authInfo)
      } catch(error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 8050f3')
      }
    }
    if (data) {
      let newList = state.alertList.concat(data.alertList)
      let total = authInfo?.isAdmin ? data.totalCount : data.totalCount
      // let newCount = 0
      // for (let i = 0;  i < newList.length;  i++) {
      //   if (!newList[i].isRead) newCount = newCount + 1
      // }
      setState({
        ...state,
        alertList: newList,
        alertPaging: {
          hasMore: (newList.length !== total),
          page: currentPage,
          total: total
        }
      })
      setLoadingFlag(false)
      updateAlertCounter()
    }
  }

  const pageLoadStartHandler = ():void => {
    if (loadingCountRef.current === 0){
      setLoadingFlag(true)
    }
    setLoadingCount(loadingCountRef.current+1)
  }

  const pageLoadEndHandler = ():void => {
    if (loadingCountRef.current-1 <= 0){
      setLoadingFlag(false)
    }
    setLoadingCount(loadingCountRef.current-1)
  }

  const movetoMypage = () => {
    setDropMy(false)

    if (window.location.pathname === '/mypage') {
      let e = new PageEvent(PageEvent.REFRESH_PAGE_MYPAGE)
      window.dispatchEvent(e)
      // window.location.reload()
    } else {
      sessionStorage.setItem('url',window.location.pathname)
      navigate('/mypage')
    }
  }

  const getConnectionInfo = async () => {
    if(authInfo?.userNo === -1) return
    try {
      const response = await ResourceGroup.getConnectionInfo(authInfo?.userNo || -1)
      if(response) {
        setConnectionInfo(response.rgroups)
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 4d20bf')
    }
  }

  // auto refresh
  const autoRefreshHandler = async () => {
    if (settingInfoRef.current?.autoRefreshFlag === true) {
      let event:PageEvent = new PageEvent(PageEvent.REFRESH)
      window.dispatchEvent(event)
    }
  }

  const refreshButtonShowHandler = (e:PageEvent) => {
    switch(e.type) {
      case PageEvent.SHOW_REFRESH:
        setRefreshButtonShowFlag(true)
        break
      case PageEvent.HIDE_REFRESH:
        setRefreshButtonShowFlag(false)
        break
    }
  }

  const refreshToggleHandler = async (e?:any, value?:boolean):Promise<void> => {
    setSettingInfo({
      autoRefreshFlag: value !== undefined ? value : !settingInfo?.autoRefreshFlag,
      loadingMaskFlag: settingInfo?.loadingMaskFlag || true,
      schedulerFlag: settingInfo?.schedulerFlag || false
    })
  }

  const stopRefreshHandler = ():void => {
    refreshToggleHandler('', false)
  }

  const refreshHandler = async () => {
    window.location.reload()
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'startPriority':
        try {
          await Priority.startPriority()
        } catch (error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 2af68c')
        }
        break
      case 'stopPriority':
        try {
          await Priority.stopPriority()
        } catch (error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 2c63a0')
        }
        break
      case 'logoutdeleteuser':
        logoutHandler()
        break
      case 'gotomypage':
        movetoMypage()
        break
    }
  }

  const alertLabel = ['일반', '주의', '경고']

  return(
    <LayoutFragment>
      <ModalMessage />
      <Modal />
      <section className={'loadingWrap ' + (loadingFlag === true ? '' : 'off')}>
        <div className='animation' ref={loadingAnimationRef}></div>
      </section>
      <header className={authInfo?.isAdmin ? 'top admin' : 'top'}>
        <div className="logoWrap">
          <h1><NavLink to="/services"><img src={authInfo?.isAdmin ? '/images-v2/admin-logo.png' : '/images-v2/logo.png'} alt='AIPub' /></NavLink></h1>
          { authInfo?.isAdmin === true ? <div className="progrss">
            <span className="bar" />
            { settingInfo?.schedulerFlag ? 
              <button onClick={() => { 
                popupController.dialouge('스케줄러 작동을 중지합니다. 스케줄러 중지 시 리소스 할당과 워크스페이스 생성이 불가능합니다. 진행하시겠습니까?', 
                'stopPriority', 'stopPriority', '확인', '취소')
              }} className="btnSchedule pause">
                스케줄러 멈춤
                <img src="/images-v2/pause.png" alt="play" />
              </button> :
              <div className="onSchedule">
                <button onClick={() => { 
                  popupController.dialouge('스케줄러 작동을 재개합니다. 진행하시겠습니까?', 
                  'startPriority', 'startPriority', '확인', '취소')
                }} className="btnSchedule">
                  스케줄러 재개
                  <img src="/images-v2/play.png" alt="play" />
                </button>
                <p>00:00</p>
              </div>
            }
          </div> : false }
        </div>

        <div className="btnWrap">
          <div className="btnGroup">
            {/* 리프레시 버튼 */
              refreshButtonShowFlag ? <>
              <button className="btnRefresh" onClick={refreshHandler}>
                새로고침
                <img src={authInfo?.isAdmin ? '/images-v2/admin-nav-refresh.png' : '/images-v2/nav-refresh.png'} alt="+"/>
              </button>
              <div className="inputWrap selectWrap timerSelect">
                <Select option={autoRefreshOptions} // empty="" 
                  selected={autoRefreshOptions[settingInfo?.autoRefreshFlag === true ? 1 : 0]} 
                  onChange={(selectedItem:ISelectOption) => { 
                    if (selectedItem.value === 'ON') {
                      refreshToggleHandler('', true)
                    } else {
                      refreshToggleHandler('', false)
                    }
                  }} />
              </div>
              </> : false
            }
          </div>
          <div className="btnGroup">

            {/* 알람 버튼 및 팝업 */}
            <div className="subWrap">
              <button className="btnIco alert" ref={dropAlertBtnRef} onClick={() => {
                if (dropAlert === false) {
                  setState({
                    ...state,
                    alertList: [],
                    alertPaging: {
                      ...state.alertPaging,
                      page: 1
                    }
                  })
                  // getAlertList() useEffect에서 대신
                }
                setDropAlert(!dropAlert)
              }}>
                <img src="/images-v2/nav-bell.png" alt="+" />
                alert
                {alertCount > 0 ? <span className="count">{alertCount > 99 ? '99+' : alertCount}</span> : false}
              </button>
              {dropAlert ?
                <div className="drop2" ref={dropAlertRef}>
                  <div className="dropHead">
                    <h3>알림</h3>
                    <Link className="btnMore" to="/alert" onClick={() => setDropAlert(false)}>
                      전체보기
                      <img src="/images-v2/chevron-right.png" alt=">" />
                    </Link>
                  </div>
                  {
                    state.alertList.length > 0 ?
                      <div className='scrollWrap' id="alertWrap" style={{height:'606px', marginTop:'24px', padding:'0 12px 0 18px', overflow:'auto'}}>
                        <InfiniteScroll className="listWrap"
                          scrollableTarget="alertWrap"
                          dataLength={state.alertList.length}
                          next={loadMore}
                          hasMore={state.alertPaging.hasMore}
                          loader={<h4 style={{color:'#303030'}}></h4>}
                          endMessage={
                            false
                            /* <p className="endMsg">&nbsp; </p> */
                          } >
                          {
                            state.alertList.map((alert, idx) => (
                              <div key={idx} className={alert.isRead === true ? 'alert read' : 'alert'}>
                                {
                                  idx === 0 ?
                                    <div className="date">
                                      <h4>
                                        {
                                        dayjs().format('YYYY.MM.DD') === dayjs.unix(Number(state.alertList[0].timestamp)).format('YYYY.MM.DD') ? 
                                          '오늘' :
                                          dayjs.unix(Number(state.alertList[0].timestamp)).format('YYYY.MM.DD')
                                        }
                                      </h4>
                                    </div> :
                                  dayjs.unix(Number(state.alertList[idx - 1].timestamp)).format('YYYY.MM.DD') !== dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD') ?
                                    <div className="date">
                                      <h4>{dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD')}</h4>
                                    </div> : false
                                }
                                <div className="msg">
                                  {/* <Link to="/alert"> */}
                                  <a>
                                    <div>
                                      <span className={`status status${alert.level}`}>{alertLabel[alert.level]}</span>
                                      <div>
                                        <p className="text">{alert.msg}</p>
                                        <p className="datetime">{dayjs.unix(Number(alert.timestamp)).format('HH:mm:ss')}</p>
                                      </div>
                                    </div>
                                    {/* <i className="arrow"></i> */}
                                    {/* </Link> */}
                                  </a>
                                </div>
                              </div>
                            ))
                          }
                        </InfiniteScroll>
                      </div> :
                      <div className="noDataAlert" /* style={{fontSize:'14px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', margin:'-30px 0'}} */>
                        데이터가 없습니다.
                      </div>
                  }
                  {/* <p className="toast">읽지 않은 알림 <img src="/images-v2/counter-w.png" alt="down arrow"/></p> */}
                </div> : false}
            </div>
            { // 마이메뉴
            authInfo?.isAdmin ?
              // 어드민
              <div className="subWrap">
                <button className="btnIco user" ref={dropMyBtnRef} onClick={(): void => { setDropMy(!dropMy) }}>
                  <img src="/images-v2/nav-m.png" alt="+" />
                </button>
                {dropMy ?
                  <div className="drop" ref={dropMyRef}>
                    <dl className="innerStart">
                      <dt>관리자 아이디</dt>
                      <dd>{authInfo?.id}</dd>
                    </dl>
                    <div className="innerEnd">
                    <button onClick={movetoMypage}><img src="/images-v2/nav-edit.png" alt="logout" />관리자 정보 변경</button>
                      <button onClick={logoutHandler}><img src="/images-v2/nav-log-in.png" alt="logout" />로그아웃</button>
                    </div>
                  </div> : false}
              </div> :
              
              // 사용자
              <div className="subWrap">
                <button className="btnIco user" ref={dropMyBtnRef} 
                  onClick={(): void => {
                    if (!dropMy) { getConnectionInfo() } //마이메뉴 열 때마다 connection info 받아옴
                    setDropMy(!dropMy)
                  }}>
                  <img src="/images-v2/nav-m.png" alt="+" />
                </button>
                {dropMy ?
                  <div className="drop" ref={dropMyRef}>
                    <dl className="innerStart">
                      <dt className="innerTitle">사용자 아이디</dt>
                      <dd>{authInfo?.id}</dd>
                    </dl>

                    <div className="scrollWrap">
                      {connectionInfo.map((info:any, idx:number) => (
                        <dl className="rgroupInfoBox" key={idx}>
                          {idx === 0 ? <div className="innerTitle">리소스 그룹 별 어셋 정보</div> : false}
                          <dt>{info.isCommon === true ? '기본 리소스 그룹' : info.rgroupName}</dt>
                          <dd>이미지 레지스트리: {info.reg}</dd>
                          <dd>볼륨 SSH: {info.ssh}</dd>
                          {info.volumes.map((vol:any, jdx:number) => (
                            <dd key={jdx}>ㄴ&nbsp;&nbsp;{`<${vol.volumeName}> <${vol.mountPath}>`}</dd>
                          ))}
                        </dl>
                      ))}
                    </div>

                    {/* 0518 이미지 레지스트리 및 스토리지 마운트 정보 제거
                    <dl className="inner">
                      <dt>이미지 레지스트리</dt>
                      { this.state.userInfo?.registry ?
                        <dd>{this.state.userInfo?.registry}</dd>
                        : <dd className="registryErrMsg">정보를 불러올 수 없습니다.</dd>}
                      <dt className="innerInterval">스토리지 마운트 정보</dt>
                      <dd>{this.state.userInfo?.mountPath}</dd>
                    </dl> */}

                    {/* 0322 리소스 사용 제한 정보 가림 
                    { this.state.userData?.resourceLimit && (this.state.userData?.resourceLimit?.maxGpu.isOn || this.state.userData?.resourceLimit?.autoReturnTime.isOn ||this.state.userData?.resourceLimit?.isAlwaysPending) ?
                      <dl className="inner">
                        <dt>총 사용 가능 GPU 리소스 블록 개수</dt>
                        {this.state.userData?.resourceLimit && this.state.userData?.resourceLimit?.maxGpu.isOn? <dd> ㄴ 총 {Utils.numberMark(this.state.userData.resourceLimit.maxGpu.resource||0)}개 중 {Utils.numberMark(this.state.userData.resourceLimit.usedGpu||0)}개 사용</dd> : <dd className="disabled">ㄴ 설정 없음</dd>}
                        <dt className="innerInterval">자동 회수 시간</dt>
                        {this.state.userData?.resourceLimit && this.state.userData?.resourceLimit?.autoReturnTime.isOn?<dd> ㄴ 생성시간 기준 {Utils.numberMark(this.state.userData.resourceLimit.autoReturnTime.resource||0)}시간 후 자동 회수</dd> : <dd className="disabled">ㄴ 설정 없음</dd>}
                        <dt className="innerInterval">워크스페이스/Job 신청 시 대기열 포함 여부</dt>
                        {this.state.userData?.resourceLimit && this.state.userData?.resourceLimit?.isAlwaysPending?<dd> ㄴ 관리자 확인 후 대기열에 포함</dd> : <dd className="disabled"> ㄴ 자동 대기열 포함</dd>}
                      </dl>
                    : false } */}

                    <div className="innerEnd">
                      <button onClick={movetoMypage}><img src="/images-v2/nav-edit.png" alt="edit" />사용자 정보 변경</button>
                      <button onClick={logoutHandler}><img src="/images-v2/nav-log-in.png" alt="logout" />로그아웃</button>
                    </div>
                  </div> : false}
              </div>
            }
          </div>
        </div>
      </header>
      {/* {authInfo?.isAdmin !== true || settingInfo?.schedulerFlag ? false :
        <p className="scheduleNote">&#x26A0;  스케줄러가 멈춰 있는 상태입니다. 워크스페이스 또는 Job 생성과 리소스 할당을 다시 동작하기 위해 스케줄러를 재개해야 합니다.</p>
      } */}
      <main>
        {authInfo?.isAdmin ? <NavigationOpsAdmin /> : <NavigationOps />}
        <section className="contentWrap">
          <div className="container">
            <Outlet />
          </div>
        </section>
      </main>
    </LayoutFragment>
  )
}

const LayoutFragment = styled.div`
position:relative; min-height:100vh; min-width:1440px; z-index:1; 
h1 img { height:32px; }
main { display:flex; }

input::placeholder { opacity:0.4 }

header.top{position:relative; display:flex; justify-content:space-between; align-items:center; height:55px; padding:0 16px 0 34px; z-index:997; border-bottom:1px solid #ebebeb; }
header.top .logoWrap{display:flex; align-items:center; gap:12px; }
header.top .progrss{display:flex; align-items:center; gap:8px; color:#f30b0b; font-weight:bold; }
header.top #circle{position:relative; }
header.top .progress-ring__circle{transition:0.35s stroke-dashoffset; transform:rotate(-90deg); transform-origin:50% 50%; }
header.top .progrss .count{position:absolute; top:4px; left:4px; width:26px; height:26px; border:3px solid #e8e8e8; border-radius:50%; text-align:center; font-size:13px; line-height:26px; z-index:-1; }
header.top .btnWrap{display:flex; align-items:center; gap:24px; }
header.top .btnWrap .btnGroup{display:flex; align-items:center; gap:16px; }
header.top .btnWrap .btnIco{width:32px; height:32px; background:#B4B4BE; border-radius:50%; font-size:0; }
header.top .btnWrap .btnIco.alert .count{top: 0; left: 23px; }
header.top .btnWrap .btnIco.alert img{width:18px; }
header.top .btnWrap .btnIco.user img{width:14px; }
header.top .count{position:absolute; top:10px; left:30px; padding:2px 3px; border-radius:8px; min-width:9px; background:#f30b0b; color:#fff; font-size:10px; }
header.top .subWrap{position:relative; display:inline-block; }
header.top .btnWrap .btnRefresh {position: relative; display:flex; align-items:center; gap:6px; }
header.top .btnWrap .btnRefresh img {width:16px }
header.top .drop { position:absolute;  top:38px; right:16px; width:280px; background:#fff; border-radius: 8px; z-index:1; font-size:14px; box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50); }
header.top .drop .innerStart {padding: 20px 24px;  border-bottom:1px solid #ebebeb; }
header.top .drop .scrollWrap {overflow:auto;  max-height:583px;  margin-right:5px; }
header.top .drop .scrollWrap dl.rgroupInfoBox {margin:24px 13px 24px 24px; }
header.top .drop .scrollWrap dl.rgroupInfoBox dt {font-size:14px;  font-weight:600; }
header.top .drop .scrollWrap dl.rgroupInfoBox dd {font-size:13px;  font-weight:400;  word-break:break-all; }
header.top .drop .innerEnd {padding: 8px 0;  border-top:1px solid #ebebeb; }
header.top .drop .innerTitle {color:#878791;  margin-bottom:8px; }
// header.top .drop .innerInterval{padding-top: 10px; }
header.top .drop dd { margin-top:8px;  font-weight:600; }
header.top .drop button,
header.top .drop a { display:flex; width:100%; height:32px; height:40px; align-items:center; gap:12px; padding:0 24px; box-sizing:border-box; font-size:14px; color:#303030; text-align:left; }
header.top .drop button img,
header.top .drop a img { width:16px; }

header.top .drop2 { overflow:auto; position:fixed; top:50px; right:64px; width:400px; height:700px; padding:20px 6px; border-radius: 8px; box-sizing:border-box; box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50); background:#fff; z-index:3; }
header.top .drop2 .btnClose{position:absolute; top:0; right:0; width:46px; height:46px; }
header.top .drop2 .btnClose img{display:block; width:16px; margin:0 auto; }
header.top .drop2 .dropHead{display:flex; height:30px; padding:0 24px; align-items:center; justify-content:space-between; }
header.top .drop2 .dropHead h3{font-size:24px; color: #303030; }
header.top .drop2 .dropHead .btnMore{display:flex; align-items:center; gap:6px; padding:0; font-size:14px; color: #333; font-weight: 400; }
header.top .drop2 .dropHead .btnMore img{width: 16px; }
header.top .listWrap .date{margin-top:36px; }
header.top .listWrap .alert:first-child .date{margin-top:0; }
header.top .listWrap .date h4{margin:12px 0; font-size:14px; color: #303030; }
header.top .listWrap a { display:flex; justify-content:space-between; align-items:center; padding:16px 0; gap: 40px; border-top:1px solid #dfdfdf; pointer-events:none}
header.top .listWrap a:hover .text{text-decoration: underline; cursor: pointer; }
header.top .listWrap a > div{display:flex; align-items:flex-start; gap:10px; }
header.top .listWrap .text{font-size:13px; color:#333; font-weight:700; line-height:20px; word-break:break-all; }
header.top .listWrap .datetime{margin-top:8px; font-size:12px; color:#33333366; }
header.top .listWrap .status{flex:none; width:40px; height:20px; box-sizing: border-box; font-size: 12px; text-align:center; line-height:18px; border-radius:10px; border:1px solid}
header.top .listWrap .status0{border-color: #B4B4BE; color: #646469; }
header.top .listWrap .status1{border-color: #FFD028; color: #D7A700; }
header.top .listWrap .status2{border-color: #F66; color: #FF0000; }
header.top .listWrap .arrow{flex:none; width:7px; height:17px; background:url(/images-v2/arrow2.png) no-repeat center / 100%; }
header.top .listWrap .toast{position:fixed; right:108px; bottom: 70px; padding:0 15px; background:rgba(78, 78, 78, 0.8);  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2); line-height:37px; color:#fff; font-size:14px; font-weight:bold; }
header.top .listWrap .toast img{display:inline-block; width:18px; vertical-align:middle; }
header.top .listWrap .endMsg{margin-top:30px; font-size:14px; color:#303030}
header.top .listWrap .alert.read .text{font-weight:normal;  word-break:break-all; }

.timerSelect{position:relative; }
.timerSelect:before{content:''; position:absolute; left:0; top:10px; width:1px; height:12px; background:#D5D5DA; z-index:1; }
.timerSelect p{padding:0 16px; border:none; background:transparent; color:#33333366; }
.timerSelect .ico{background-image:url(/images-v2/angle-down.png)}
.timerSelect .dropWrap{border-color: #333; }
.timerSelect .dropWrap button{color:#333; transition: all 0.3s}
.timerSelect .dropWrap button.selected{background:transparent}
.timerSelect .dropWrap button:hover{background:rgba(75, 130, 255, 0.06); }

header.top .btnSchedule{display:flex; align-items:center; gap:8px; padding: 0 8px; line-height:24px; border-radius: 3px; color:#fff; font-size:12px; font-weight:700; background:#F00; }
header.top .btnSchedule.pause{background:#646469; }
header.top .btnSchedule img{width:10px; }
header.top .onSchedule{display:flex; align-items:center; gap:12px; }
header.top .onSchedule p{color: #FF2D2D; font-size: 12px; font-weight: 600; }

header.top.admin{background:#333; border:none; }
header.top.admin .bar{width:1px; height:20px; background:#646469; }
header.top.admin .btnRefresh{color:#fff; }
header.top.admin .btnWrap .btnIco{background:#555; }
header.top.admin .timerSelect p{color:#ffffff66; }
header.top.admin .timerSelect .ico{background-image:url(/images-v2/admin-angle-down.png)}
.scheduleNote{background:#F00; font-size:12px; color:#fff; line-height:24px; text-align:center; }

// 로딩 마스크
 .loadingWrap { position:fixed; width:100%; height:100%; background-color:rgba(0,0,0,0.3); display:flex; justify-content:space-between; align-items:center; z-index:9999 }
 .loadingWrap.off { display:none }
 .loadingWrap .animation { position:relative; left:50%; display:inline-block; width:50px; height:50px; display:flex; justify-content:space-between; align-items:center;  }

 .contentWrap {overflow: auto; position:relative; width:100%; height:calc(100vh - 56px);  }
 .container {padding:48px 64px 280px; margin:0;  width:1312px; }//min도 추가함 수정필요하다면 삭제

.noDataAlert { display:flex;  margin:24px 24px 0 24px;  justify-content:center;  align-items:center;  flex-direction:column;  font-size:14px;  color:#303030;  height:604px; color:#333;  }

@media (max-width: 1671px) {
  .contentWrap{padding-left:49px; }
}

@media (max-width: 1505px) {
  .contentWrap{padding-left:49px; }
 .container {padding:48px 32px 280px; }
}

//scroll bar custom
.scrollWrap::-webkit-scrollbar { width:6px;  }
.scrollWrap::-webkit-scrollbar-track { background-color:#fff;  }
.scrollWrap::-webkit-scrollbar-thumb { background: #b4b4be;  border-radius: 10px;  }
.scrollWrap::-webkit-scrollbar-button { display: none;  }

.dropWrap::-webkit-scrollbar { width:6px;  }
.dropWrap::-webkit-scrollbar-track { background-color:transparent;  }
.dropWrap::-webkit-scrollbar-thumb { background: #b4b4be;  border-radius: 10px;  }
.dropWrap::-webkit-scrollbar-button { display: none;  }

.divider { height:1px; margin:50px 0; background:#ccc;  }
.pageTitle { display:flex; align-items:center; gap:10px; font-size:24px; color:#1A1A1A; font-weight:700;  }
.pageTitle .total { font-size:18px; font-weight:normal;  }
.pageTitle .total b { color:#217eff; font-weight:bold;  }
.sectionTitle { margin-bottom:12px; align-items:center; font-size:14px; color:#1A1A1A; font-weight:700;  }
.sectionTitle::after{ content:''; display:block; flex-basis:100%;  }
.contentWrap .btnWrap { display:flex; justify-content:flex-end; gap:6px;  }
.contentWrap .btnWrap + table { margin-top:30px;  }

form .formGroup { display:flex; align-items:flex-start;  }
//form .formGroup + .formGroup { margin-top:60px;  }
form .label { flex:none; display:block; width:256px; font-size:13px; padding:10px 0;  }
form section { display:flex; gap:24px; flex-wrap:wrap }
form section .inputRow { display:flex; gap:24px; margin-bottom:12px }
form section .inputRow:last-child { margin-bottom:0 }
form .inputWrap { display:flex; flex:0.5 1; flex-direction:column }
form .message { font-size:12px; margin:5px 0 0 10px; color:#ef4f30;  }
form .message.fine { color:#2c78ff;  }
form .inputBox { display:flex; gap:10px;  }
form .inputBox .btn { height:37px; line-height:37px;  }
form .inputBox .btn.outline.disable { color:#a7a7a7; border-color:#a7a7a7;  }
form .input { flex:1; width:100%; height:37px; padding:0 15px; border:1px solid #ccc; box-sizing:border-box; border-radius:2px; font-size:14px; color:#303030;  }
form .input.error { border-color:#ef4f30;  }
form .input.disable { background:#f5f5f5;  }
form .input:disabled { background:#f5f5f5;  }
form .input::placeholder { color:#a7a7a7;  }
form .input:hover { border-color:#616161;  }
form .input:active { border-color:#2c78ff;  }
form .input.input.disable:hover { border-color:#ccc;  }
form .input:disabled:hover { border-color:#ccc;  }
form .inputGroup { position:relative; padding:0;  }
form .inputGroup .input { border:none; height:35px;  }
form .inputGroup img { position:absolute; right:20px; top:10px; width:20px; height:16px; opacity:0;  }
form .inputGroup img.fine { opacity:1;  }

// ellipsis tooltip
.ellipsisTip { max-width:166px; word-break:break-all; padding:10px; box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);  }
.ellipsisTip2 { padding:10px; box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);  }
.ellipsisTipOps { box-shadow: 0 0 1px 0 #64646980, 0 2px 4px 0 #1B1D1F29;  }

`

export default Layout