import { useEffect, useRef } from 'react'
import PopupController from '../../../controller/PopupController'
import ModalEvent, { MODAL_BUTTON_TYPE, IModalButton } from '../../../events/ModalEvent'

interface IInfoPopupProps {
  list:IModalButton[]
  onClose:Function
}

const ModalAction = (props:IInfoPopupProps) => {
  const popupController = PopupController.getInstance()

  const submitRef = useRef<any>()

  useEffect(() => {
    if (submitRef.current) {
      submitRef.current.focus()
    }
  }, [])

  const actionHandler = (btn:IModalButton) => {
    props.onClose()
    if (btn.action !== MODAL_BUTTON_TYPE.CANCEL) {
      let evt:ModalEvent = new ModalEvent(ModalEvent.ACTION_MODAL)
      evt.payload = {
        key: btn.key,
        action: btn.action
      }
      popupController.dispatchEvent(evt)
    }
  }

  return (
    <div className="btnWrap">
      {
        props.list.map((btn:IModalButton, idx:number) => 
          <button key={idx} ref={btn.type === MODAL_BUTTON_TYPE.SUBMIT ? submitRef : undefined} className={btn.type === MODAL_BUTTON_TYPE.SUBMIT ? 'btn blue submitButton' : 'btn grey'} onClick={():void => actionHandler(btn)}>{btn.text}</button>
        )
      }
    </div>
  )
}

export default ModalAction