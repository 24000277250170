import PopupController from "../../../controller/PopupController"
import { IWorkspaceStatus, WORKSPACE_STATUS } from "../../../model/Workspace"
import { useEffect, useState } from "react"
import styled from "styled-components"
import TableEvent from "../../../events/TableEvent"
import ModalEvent from "../../../events/ModalEvent"
import ToastEvent from "../../../events/ToastEvent"
import { Workspace } from "../../../model/Workspace"
import { Link } from "react-router-dom"
import Tooltip from "../ui/Tooltip"

interface IWorkspaceDevStatusState {
  detailButtonFlag:boolean
  actionFunction:Function|null
  detailButtonEnabledFlag:boolean
  action:string
  className:string // 고민이 필요한 사항
  btnClass:string // 고민이 필요한 사항
}

const WorkspaceDevStatus = (props:IWorkspaceStatus) => {
  const popupController = PopupController.getInstance()
  const [ optionalQuery, setOptionalQuery ] = useState('')
  const [ state, setState ] = useState<IWorkspaceDevStatusState>({
    detailButtonFlag:false,
    actionFunction:null,
    detailButtonEnabledFlag:true,
    action:'',
    className:'', // 고민이 필요한 사항
    btnClass:''
  })

  useEffect(() => {
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    /* let now = dayjs().unix()
    if (now - Number(props.startTimestamp) < 86400) {
      setOptionalQuery('?from=' + props.startTimestamp)
    } */ //모니터링용
    switch (props.status) {
      case WORKSPACE_STATUS.ERROR:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: 'warn',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.FAILED:
        setState({
          detailButtonFlag: false,
          detailButtonEnabledFlag: true,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: 'warn',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.PENDING:
        setState({
          detailButtonFlag : true,
          detailButtonEnabledFlag: false,
          actionFunction: cancelHandler,
          action: '신청 취소',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.CANCELED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: false,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.RUNNING:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: returnHandler,
          action: '반납하기',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.RETURNED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: null,
          action: '반납 완료',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.RECALLED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.COMPLETED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: null,
          action: 'Job 완료',
          className: '',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.ADMIN_CANCELED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: false,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.CREATING:
        setState({
          detailButtonFlag: true,
          actionFunction: null,
          detailButtonEnabledFlag: false,
          action: '',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.PRE_QUEUE:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: false,
          actionFunction: cancelHandler,
          action: '신청 취소',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.SYSTEM_CANCELED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: false,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: 'off',
          btnClass: ''
        })
        break
      case WORKSPACE_STATUS.SYSTEM_RECALLED:
        setState({
          detailButtonFlag: true,
          detailButtonEnabledFlag: true,
          actionFunction: recreateHandler,
          action: '재신청하기',
          className: '',
          btnClass: ''
        })
        break
    }
  },[props.status])

  const recreateHandler = (e:React.MouseEvent<HTMLButtonElement>):void => {
    let event:TableEvent = new TableEvent(TableEvent.RECREATE_WORKSPACE)
    event.payload = {
      id: props.id
    }
    /* console.log(props.id) */
    window.dispatchEvent(event)
  }

  const  cancelHandler = async (e:React.MouseEvent<HTMLButtonElement>):Promise<void> => {
    const workspaceId:string = props.name? props.name : ''
    /* let workspaceIdArray:string[] = props.id.split('-')
    workspaceIdArray.pop()
    const workspaceId:string = workspaceIdArray.join('-') */
    let data = {name: props.name, id: props.id}
    popupController.dialouge(`${workspaceId} 의 신청을 취소합니다. 진행하시겠습니까?`, 'cancelWorkspace', data, '확인', '취소')
  }

  const  returnHandler = async (e:React.MouseEvent<HTMLButtonElement>):Promise<void> => {
    const workspaceId:string = props.name? props.name : ''
    /* let workspaceIdArray:string[] = props.id.split('-')
    workspaceIdArray.pop()
    const workspaceId:string = workspaceIdArray.join('-') */
    let data = {name: props.name, id: props.id}
    popupController.dialouge( `${workspaceId} 을(를) 반납합니다. 진행하시겠습니까?`, 'returnWorkspace', data, '확인', '취소')
  }

  const modalActionHandler = async (e:ModalEvent) => {
    if (e.payload.key.id !== props.id) {
      return false
    }
    /* let workspaceIdArray:string[] = props.id.split('-')
    const creationTime:string = workspaceIdArray.pop() || ''
    const workspaceId:string = workspaceIdArray.join('-') */
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
    let workspaceIdName:any[] = [] 
    workspaceIdName.push({
      id:e.payload.key.id,
      name:e.payload.key.name})
    let workspaceName:string[] = []
    workspaceName.push(e.payload.key.name)
    
    switch (e.payload.action) {
      case 'cancelWorkspace':
        try {
          await Workspace.returnWorkspace(workspaceIdName)
          window.dispatchEvent(new Event(TableEvent.CHANGE_SORT_ORDER))
          let e:TableEvent = new TableEvent(TableEvent.REFRESH)
          window.dispatchEvent(e)

          let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          toastEvent.payload = { message: `${workspaceName}의 신청이 취소되었습니다.` }
          window.dispatchEvent(toastEvent)
        } catch (error) {
          e.payload = { message: error }
          window.dispatchEvent(toastEvent)
        }
        break
      case 'returnWorkspace':
        try {
          await Workspace.returnWorkspace(workspaceIdName)
          window.dispatchEvent(new Event(TableEvent.CHANGE_SORT_ORDER))
          let e:TableEvent = new TableEvent(TableEvent.REFRESH)
          window.dispatchEvent(e)

          let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          toastEvent.payload = { message: `${workspaceName}이(가) 반납되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.` }
          window.dispatchEvent(toastEvent)
        } catch (error) {
          e.payload = { message: error }
          window.dispatchEvent(toastEvent)
        }
        break
    }
  }

  return (
    <WorkspaceStatusFragment>
      <div className={state.className}>
          { state.detailButtonFlag ? 
              state.detailButtonEnabledFlag ? 
              <Link to={'/workspace/'+props.id/* +'/'+props.namespace+optionalQuery */}>상세 보기<img src="/images/arrow3.png" alt="go" /></Link> : //Optionalquery는 모니터링(차트데이터)에서 필요로함
              <span>상세 보기<img src="/images/arrow3.png" alt="go" /></span>
            : props.label}
          { state.detailButtonFlag === false && state.className === 'warn' && props.statusMessage !== undefined ? 
            <Tooltip des={props.statusMessage} way="left" img="failIcon.png" width={14} />
          : false }
          { props.status === WORKSPACE_STATUS.RECALLED? <Tooltip des={'관리자에 의해 회수'} way="left" img="failIcon.png" width={14} />:false}
          { props.status === WORKSPACE_STATUS.SYSTEM_RECALLED? <Tooltip des={'시스템에 의해 자동 회수'} way="left" img="failIcon.png" width={14} />:false}
        </div>
        <div>
          { state.actionFunction !== null ? 
            <button className="btnAction" onClick={()=>{if(state.actionFunction) state.actionFunction()}}>{state.action}</button> : 
            <div>
              <span className="text">{ state.action }</span>
              {/* { props.status === WORKSPACE_STATUS.RETURNED || props.status === WORKSPACE_STATUS.RECALLED ? 
                <Tooltip des={dayjs(Number(props.endTimestamp)*1000).format('YYYY-MM-DD HH:mm:ss')} way="left" img="group-257.png" width={14} /> : 
                false
              } */}
            </div>
          }
        </div>
    </WorkspaceStatusFragment>
  )
  
}
const WorkspaceStatusFragment = styled.div`
  div { font-size:1em; color:#303030; }
  div.off { color:#a7a7a7; }
  div + div { margin-top:10px; }
  .warn { color:#f30b0b; }
  a { display:inline-block; font-size:1em; padding:0.2em 0.5em; color:#000; }
  .off a,
  .off span { color:#a7a7a7; }
  a img,
  span img { display:inline-block; width:4px; vertical-align:top; margin:4px 0 1px 7px; }
  .btnAction { display:inline-block; font-size:1em; border:1px solid #000; padding:0.2em 0.5em; background-color:#fff; border-radius: 2px;}
  .text + div { margin-left:5px; }
  .warn .btnTooltip img { margin-left:5px; }
`

export default WorkspaceDevStatus