import camelcaseKeys from 'camelcase-keys'
import { IBaseDataType, SORT_ORDER } from './BaseDataType'
import ApiController from '../controller/ApiController'
import { AxiosRequestHeaders } from "axios"
import { ERROR_TYPE } from '../interfaces/Error'

export interface ITime {
  name:string
  workingTime:any
}
export interface IUserUsage {
  id:string
  no:number
  usage:number
}
export interface IBilling {
  month:number
  totalUsage:number
  userUsage:IUserUsage[] //ITime[]
  year:number
}
export interface IBillingOps {
  month:number
  totalTime:number
  userObject:any[]
  year:number
}

export default class Billing {

  //Dev
  public static async getMontlyData(start:number, end:number) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/charge/workspaces', {
        start: start,
        end: end,
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Billing.getMontlyData error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getTotalData(from:number, to:number) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/charge/workspaces/total', {
        start: from,
        end: to
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Billing.getTotalData error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getStartTimestamp(/* customHeader?:AxiosRequestHeaders */) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/charge/start-timestamp', {})
      switch(response.status) {
        case 200:
          return response.data.start_timestamp
        default:
          console.log('Billing.getStartTimestamp error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async download(from:number, to:number, filename:string) {
    try {
      const apiController = ApiController.getInstance()

      const response:Blob = await apiController.getBlob('/charge/workspaces/excel?start='+from+'&end='+to, {})
      const fileObjectUrl = window.URL.createObjectURL(response)

      const link = document.createElement('a')
      link.href = fileObjectUrl
      link.setAttribute('download', filename)
      link.click()
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }


  //Ops
  // public static async getMonethlyDataOps(page:number, size:number, month:number, download:boolean=false) {
  //   const apiController:OpsApiController = new OpsApiController()
  //   const response = await apiController.getMonthlyBilling(page, size, month, download)
  //   switch(response.status) {
  //     case 200:
  //       return camelcaseKeys(response.data, {deep: true})
  //     default:
  //       console.log('Ops.Biiling.getMonthlyData error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }

  // public static async getTotalDataOps(from:number, to:number) {
  //   const apiController:OpsApiController = new OpsApiController()
  //   const response = await apiController.getTotalBilling(from, to)
  //   switch(response.status) {
  //     case 200:
  //       return camelcaseKeys(response.data.charge_total, {deep: true})
  //     default:
  //       console.log('Billing.getTotalData error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }

  // public static async getStartTimestampOps(customHeader?:AxiosRequestHeaders) {
  //   const apiController:OpsApiController = new OpsApiController()

  //   const response = await apiController.getStartTimestamp(customHeader)
  //   switch(response.status) {
  //     case 200:
  //       return response.data.start_timestamp
  //     default:
  //       console.log('Billing.getStartTimestamp error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }
}
