import { useEffect, useState } from "react"
import styled from 'styled-components'

interface IPagenationProps {
  defaultPage:number
  totalArticle:number
  perPage:number
  onChange?:Function
}

interface IPagenationState {
  currentPage:number,
  totalPage:number,
  pageNumberArray:number[]
}

const Pagenation = (props:IPagenationProps) => {
  const [ totalArticle, setTotalArticle ] = useState<number>(props.totalArticle)
  const [ currentPage, setCurrentPage ] = useState<number>(props.defaultPage)
  const [ totalPage, setTotalPage ] = useState<number>(Math.ceil(props.totalArticle/props.perPage))
  const [ pageNumberArray, setPageNumberArray ] = useState<number[]>([])
  
  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (totalArticle !== props.totalArticle) {
      setTotalPage(Math.ceil(props.totalArticle/props.perPage))
      goPageHandler(1)
    }
  },[totalArticle, props.totalArticle])

  
  const goPageHandler = (target:number) => {
    setCurrentPage(target)
    reset()
    if (props.onChange) props.onChange(target)
  }

  const reset = () => {
    const START_IDX = 1+(10*(Math.ceil(currentPage/10)-1))
    const END_IDX = Math.min(START_IDX+9, totalPage)
    let newPageNumberArray:number[] = []

    for (let idx:number = START_IDX; idx <= END_IDX; idx++) {
      newPageNumberArray.push(idx)
    }
    setPageNumberArray(newPageNumberArray)
  }

  return (
    <PagenationFragment>
      {
        totalPage > 10 && pageNumberArray[0] > 1 ? <>
          <button onClick={() => goPageHandler(1)} className="btnNav">1</button>
          <button onClick={() => goPageHandler(pageNumberArray[0]-1)} className="btnNav">
            <img src="/images-v2/chevron-left.svg" alt="<" />
          </button>
        </> : false
      }
      {
        pageNumberArray.map((value:number) => {
          return (<button key={value} onClick={() => goPageHandler(value)} className={"btnNo "+(value === currentPage ? 'current' : '')}>{value}</button>)
        })
      }
      {
        totalPage > 10 && pageNumberArray[pageNumberArray.length-1] < totalPage ? <>
          <button onClick={() => goPageHandler(pageNumberArray[pageNumberArray.length-1]+1)} className="btnNav">
            <img src="/images-v2/chevron-right.svg" alt=">" />
          </button>
          <button onClick={() => goPageHandler(totalPage)} className="btnNav">{totalPage}</button>
        </> : false
      }
    </PagenationFragment>
  )
}

const PagenationFragment = styled.div`
  display: flex;align-items: center;justify-content: center;margin-top: 32px;gap:4px;
  button{min-width: 32px;height: 32px;padding:0 8px;color: #B4B4BE;cursor: pointer;transition: all 0.3s;}
  button img{width:16px;transition: all 0.3s;}
  button:hover{color: #333;}
  .btnNo{font-size: 14px;}
  .btnNo.current{background: #F7F8FB;font-weight: bold;color: #333;}
  .btnNav:hover img{filter: brightness(0) saturate(100%) invert(21%) sepia(5%) saturate(39%) hue-rotate(314deg) brightness(97%) contrast(102%);}
`

export default Pagenation