import { useEffect } from 'react'
import { Link, NavigateFunction, Params , useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/ui/Table'
import { ObjType, SORT_ORDER } from "../../model/BaseDataType"
import { useRef,useState } from 'react'

import { Workspace, IWorkspaceListItem, WORKSPACE_LIST_ORDER_BY, WORKSPACE_STATUS, RESOURCE_TYPE } from '../../model/Workspace'
import TableEvent from "../../events/TableEvent"
import ModalEvent from '../../events/ModalEvent'
import Pagenation from '../components/ui/Pagenation'
import PopupController from '../../controller/PopupController'
import PageEvent from '../../events/PageEvent'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../Config'
import dayjs from "dayjs"
import BlockPopup from "../components/modal/sub/BlockPopup"
import WorkspaceDevStatus from "../components/table/WorkspaceDevStatus"
import { recreateState } from "../../states/recreateState"
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'
import Select from '../components/ui/Select'
import ISelectOption from '../../interfaces/SelectOption'
import { ResourceGroup } from '../../model/ResourceGroup'
import ToastEvent from '../../events/ToastEvent'

interface IWorkspaceListDevProps {
  navigate?:NavigateFunction
  params?:Params
}

interface IWorkspaceListDevState {
  page:number
  totalArticle:number
  tableItems:IWorkspaceListItem[]
  deleteItems:IWorkspaceListItem[]
  selectedItems?:IWorkspaceListItem[]
  resourceGroup:string
}

interface IWorkspaceListResourceGroupState {
  resourceGroupSelectList:ISelectOption[]
  rgroup:string
}

const WorkspaceList = (props:IWorkspaceListDevProps) => {
  const tableRef = useRef<any>()
  const selectResourceGroupRef = useRef<any>()
  const popupController = PopupController.getInstance()
  const [ recreate, setRecreate ] = useRecoilState(recreateState)
  const navigate = useNavigate()
  const tableColWidth = [52, 202, 346, 120, 200, 241, 153]
  const headerLabel:ITableHeader[] = [
    { label: '리소스 그룹', key: 'tableGroupName', type: TABLE_CELL_TYPE.STRING, orderFlag: true, sort: true, sortKey: 'resourceGroupName'}, 
    { label: '이름', key: 'tableWsInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'name' }, 
    { label: '신청 시간', key: 'tableCreationTimestamp', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.DESC, styleKey: 'style' }, 
    { label: '가동 시간', key: 'tableStartTimestamp', type: TABLE_CELL_TYPE.STRING, sort: true, styleKey: 'style', sortKey: 'startTimestamp' }, 
    { label: '리소스 타입 / 개수', key: 'tableResourceBlock', type: TABLE_CELL_TYPE.FLEXBOX, sort: false /* sortKey: 'resourceType' */ }, 
    { label: '상태', key: 'statusCell', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'status' }
  ]
  const [ authInfo ] = useRecoilState(authState)

  const [ state, _setState ] = useState<IWorkspaceListDevState>({
    totalArticle: 0,
    page: (props.params?.page ? Number(props.params?.page) : 1),
    tableItems: [],
    deleteItems: [],
    selectedItems: [],
    resourceGroup:''
  })

  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ groupstate, _setGroupState ] = useState<IWorkspaceListResourceGroupState>({
    resourceGroupSelectList:[],
    rgroup:''
  })

  const groupstateRef = useRef(groupstate)
  const setGroupState = (data:any) => {
    groupstateRef.current = data
    _setGroupState(data)
  }

  useEffect(() => {
    getResourceGroupList()
    window.dispatchEvent(new Event('callgetmyinfo'))
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    window.addEventListener(TableEvent.RECREATE_WORKSPACE, recreateWorkspaceHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))    
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    window.addEventListener('wscreated', eventControl)  
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      window.removeEventListener(TableEvent.RECREATE_WORKSPACE, recreateWorkspaceHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
      window.removeEventListener('wscreated', eventControl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    getData(1, groupstate.rgroup)
  }, [groupstate.rgroup])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    getData(1, groupstateRef.current.rgroup)
  }

  const getResourceGroupList = async () => {
    try {
      const response = await ResourceGroup.getRgroupList(authInfo.userNo)
      let resourceGroupSelectList:ISelectOption[] = []
  
      resourceGroupSelectList.push({  //항목에 전체 리소스그룹을 추가해주기 위함
        label: '전체 리소스 그룹',
        value: '0'
      })
  
      for (let key in response.rgroup) {
        let exchange = Number(key)
        if (response.rgroup[key].isCommon === true) {
          resourceGroupSelectList.push({
          fakeLabel: '기본 리소스 그룹', //공용리소스그룹에대한 label
          value: String(exchange+1),
          label: response.rgroup[key].name
        })
      } else {
          resourceGroupSelectList.push({
            label: response.rgroup[key].name,
            value: String(exchange+1)
          })
        }
      }
      setGroupState({
        ...groupstate,
        resourceGroupSelectList: resourceGroupSelectList
      })
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e16df0')
    }
  }

  const getData = async (page?:number, group?:string):Promise<void> => {

    let sortKey:WORKSPACE_LIST_ORDER_BY
    switch (tableRef.current?.sortKey) {
      case 'name':
        sortKey = WORKSPACE_LIST_ORDER_BY.NAME
        break
      case 'creationTimestamp':
      default:
        sortKey = WORKSPACE_LIST_ORDER_BY.CREATION_TIMESTAMP
        break
      case 'startTimestamp':
        sortKey = WORKSPACE_LIST_ORDER_BY.START_TIMESTAMP
        break
      case 'resourceType':
        sortKey = WORKSPACE_LIST_ORDER_BY.RESOURCE_TYPE
        break
      case 'resourceGroupName':
        sortKey = WORKSPACE_LIST_ORDER_BY.RESOURCE_GROUP_NAME
        break
      case 'status':
        sortKey = WORKSPACE_LIST_ORDER_BY.STATUS
        break
    }
    const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.DESC : tableRef.current?.sortOrder)
    try {
      const workspaceList = await Workspace.getList(authInfo?.userNo ? authInfo.userNo : 0 , sortKey, sortOrder, page? page : state.page, 50, group? group : groupstate.rgroup)
      
      if (workspaceList) {
        for (let eachLine of workspaceList.workspaces) {
          let statusLabel:ObjType = {
            [WORKSPACE_STATUS.ERROR]: '에러',
            [WORKSPACE_STATUS.FAILED]: '생성 실패',
            [WORKSPACE_STATUS.PENDING]: '생성 대기',
            [WORKSPACE_STATUS.CANCELED]: '신청 취소',
            [WORKSPACE_STATUS.CREATING]: '생성중',
            [WORKSPACE_STATUS.RUNNING]: '생성됨',
            [WORKSPACE_STATUS.RETURNED]: '반납 완료',
            [WORKSPACE_STATUS.RECALLED]: '회수됨',
            [WORKSPACE_STATUS.COMPLETED]: 'Job 완료',
            [WORKSPACE_STATUS.ADMIN_CANCELED]: '관리자에 의한 신청 취소',
            [WORKSPACE_STATUS.SYSTEM_CANCELED]: '시스템에 의한 취소',
            [WORKSPACE_STATUS.SYSTEM_RECALLED]: '시스템에 의한 회수'
          }
          eachLine.label = statusLabel[eachLine.detail.statusId]

          if (eachLine.detail.statusId === WORKSPACE_STATUS.ERROR) {
            eachLine.fakeName = '⚠︎ '+eachLine.name
          } else {
            eachLine.fakeName = eachLine.name
          }
          eachLine.tableGroupName = eachLine.detail.isCommon === true ? '기본 리소스 그룹': (eachLine.namespace) //규칙 통일

          eachLine.tableWsInfo = eachLine.detail.isJob ? [
            [<div className="name" style={{ width: '300px', display:'flex', marginBottom:'10px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.fakeName}</EllipsisToolTip></div>],
            [(<div style={{ width: '300px', display:'flex', height:'17px', marginBottom:'10px'}}>
              <div className="wsInfo">이미지 : </div><div style={{width:'254px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.detail.image ? eachLine.detail.image : '-'}</EllipsisToolTip></div>
            </div>)]
          ]:
          [
            [<div className="name" style={{ width: '300px', display:'flex', marginBottom:'10px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.fakeName}</EllipsisToolTip></div>],
            [(<div style={{ width: '300px', display:'flex', height:'17px'}}>
              <div className="wsInfo">SSH 주소 : </div><div style={{width:'237px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{ eachLine.detail.statusId === WORKSPACE_STATUS.RUNNING ? eachLine.ssh : '-'}</EllipsisToolTip></div>
            </div>)],
            [(<div style={{ width: '300px', display:'flex', height:'17px', marginBottom:'10px'}}>
              <div className="wsInfo" >이미지 : </div><div style={{width:'254px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.detail.image ? eachLine.detail.image : '-'}</EllipsisToolTip></div>
            </div>)],
            [ eachLine.detail.statusId === WORKSPACE_STATUS.RUNNING && eachLine.detail.isJupyter ? //운영중일때만 주피터노트북 주소가 나옴
            (<div style={{ width: '300px'}}>
              <Link to={eachLine.jupyter} target="_blank">
              <img className="jupyterlogo" src="/images/jupyter-link.png" alt="Jupyter logo"/><span className="wsInfo" style={{textDecorationLine:'underline'}}>Jupyter Notebook </span>{'>'}</Link>
              </div>) : false
            ]
          ]

          eachLine.tableCreationTimestamp = (typeof(eachLine.createdAt) === 'number' ? dayjs(eachLine.createdAt*1000).format('YYYY/MM/DD\nHH:mm:ss') : eachLine.createdAt)

          if (eachLine.detail.statusId === WORKSPACE_STATUS.FAILED) {
            eachLine.tableStartTimestamp = '-'
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.PENDING) {
            eachLine.tableStartTimestamp = eachLine.priority === null ? '생성 대기중\n(우선 순위 미포함)' : `생성 대기중\n(우선 순위 ${eachLine.priority}번째)`
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.CANCELED) {
            eachLine.tableStartTimestamp = '신청 취소'
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.SYSTEM_CANCELED) {
            eachLine.tableStartTimestamp = '시스템 자동 신청 취소'
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.ADMIN_CANCELED) {
            eachLine.tableStartTimestamp = '관리자에 의한 신청 취소'
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.CREATING) {
            eachLine.tableStartTimestamp = '생성 중'
          } else if (eachLine.detail.statusId === WORKSPACE_STATUS.PRE_QUEUE) {
            eachLine.tableStartTimestamp = '생성 대기 관리자 확인 중'
          } else if(eachLine.detail.statusId === WORKSPACE_STATUS.RUNNING) {
            eachLine.tableStartTimestamp = eachLine.startedAt? dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss')+' ~ \n(가동중)' : '-'
          } else {
            eachLine.tableStartTimestamp = eachLine.startedAt? dayjs(eachLine.startedAt*1000).format('YYYY/MM/DD HH:mm:ss')+' ~ \n'+(typeof(eachLine.deletedAt) === 'number' ? dayjs(eachLine.deletedAt*1000).format('YYYY/MM/DD HH:mm:ss') : eachLine.deletedAt): '-'
          }

          eachLine.tableResourceBlock = eachLine.resourceBlock.resourceType !== RESOURCE_TYPE.CPU ? 
          [//gpu인경우
           [(<div>{eachLine.resourceBlock.type + ' : ' + eachLine.resourceBlock.gpuNum+' 개'}</div>)],
           eachLine.detail.isMultiTraining && Config.env.REACT_APP_MULTI_NODE === 'true' ? 
           [(<div className="gpuUnderblock">{<BlockPopup blockData={eachLine.resourceBlock} />}<img src="/images/multi-training.svg"/></div>)] 
           : [(<div className="gpuUnderblock">{<BlockPopup blockData={eachLine.resourceBlock} />}</div>)]
          ] : 
          [//cpu인경우
            [(<div>{eachLine.resourceBlock.type}</div>)],
            [(<div>{'CPU : ' + eachLine.resourceBlock.cpu + ' 코어'}</div>)],
            [(<div>{'MEM : ' + eachLine.resourceBlock.mem + ' ' + eachLine.resourceBlock.memUnit}</div>)]
          ]
          
          eachLine.statusCell = [
            [(<WorkspaceDevStatus
              namespace={eachLine.namespace} id={eachLine.id/* +'-'+eachLine.createdAt */} status={eachLine.detail.statusId} label={eachLine.label} name={eachLine.fakeName}
              creationTimestamp={eachLine.createdAt} startTimestamp={eachLine.startedAt} endTimestamp={eachLine.deletedAt} statusMessage={eachLine.detail.statusMsg} isJob={eachLine.detail.isJob} />)]
          ]

          switch (eachLine.detail.statusId) {
            case WORKSPACE_STATUS.ERROR:
              eachLine.rowClassName = 'warn'
              break
            case WORKSPACE_STATUS.FAILED:
            case WORKSPACE_STATUS.PENDING:
            case WORKSPACE_STATUS.CANCELED:
            case WORKSPACE_STATUS.RETURNED:
            case WORKSPACE_STATUS.RECALLED:
            case WORKSPACE_STATUS.COMPLETED:
            case WORKSPACE_STATUS.ADMIN_CANCELED:
            case WORKSPACE_STATUS.CREATING:
            case WORKSPACE_STATUS.PRE_QUEUE:
            case WORKSPACE_STATUS.SYSTEM_CANCELED:
            case WORKSPACE_STATUS.SYSTEM_RECALLED:
              eachLine.rowClassName = 'fail'
              break
            case WORKSPACE_STATUS.RUNNING:
            default:
              eachLine.rowClassName = ''
              break
          }
        }
        setState({
          ...state,
          page: page ? page : stateRef.current.page,
          totalArticle: workspaceList.total,
          tableItems: workspaceList.workspaces
        })
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch(err) {
      console.log(err)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - f3bc6c')
    }
  }

  const newWorkspaceButtonHandler = ():void => {
    navigate('/workspace/new')
  }

  const newJobButtonHandler = ():void => {
    navigate('/job/new')
  }

  const recreateWorkspaceHandler = async (e:TableEvent):Promise<void> => {
    let recreateItem:IWorkspaceListItem|undefined = stateRef.current.tableItems.find(element => element.id === e.payload.id)
    if (recreateItem) {
      setRecreate(recreateItem)
      if (recreateItem.detail.isJob) {
        navigate('/job/new')
      } else {
        navigate('/workspace/new')
      }
    }
  }

  const navigateToPage = (page:number):void => {
    if(page === state.page) return
    /* setState({
      ...state,
      page: page
    }) */
    getData(page)
  }

  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IWorkspaceListItem[] = []
      for (let idx in selectedIdx) {
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selectedItems: selectedArray
      })
    } else {
      setState({
        ...state,
        selectedItems: []
      })
    }
  }

  const removeWorkspaceHandler = async ():Promise<void> => {
    let targetIdNameArray:any[] = []
    let runningIdArray:string[] = []
    let runningNameArray:string[] = []
    let stoppedIdArray:string[] = []
    let stoppedNameArray:string[] = []
    //let canceledIdArray:string[] = [] //취소가 다시 문구등을 분기하게 된다면 되살리도록함.
    //let canceledNameArray:string[] = []
    let pendingIdArray:string[] = []
    let pendingNameArray:string[] = []
    const items = state.tableItems
    const selectedIdx = tableRef.current?.selected

    /* let e:ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    e.payload = {} */

    if (items && selectedIdx && selectedIdx.length > 0) {
      for (let eachIdx of selectedIdx) {
        let eachItem = items[Number(eachIdx)]
        targetIdNameArray.push({
          id:eachItem.id,
          name:eachItem.name
        })
        switch (eachItem.detail.statusId) {
          case WORKSPACE_STATUS.RUNNING:
          case WORKSPACE_STATUS.CREATING:
            runningIdArray.push(eachItem.id)
            runningNameArray.push(eachItem.name)
            break
          case WORKSPACE_STATUS.PENDING:
          case WORKSPACE_STATUS.PRE_QUEUE:
            pendingIdArray.push(eachItem.id)
            pendingNameArray.push(eachItem.name)
           break
          case WORKSPACE_STATUS.CANCELED:
          case WORKSPACE_STATUS.ADMIN_CANCELED:
          case WORKSPACE_STATUS.SYSTEM_CANCELED:/*canceledIdArray.push(eachItem.id)canceledNameArray.push(eachItem.name)break*/
          case WORKSPACE_STATUS.ERROR:
          case WORKSPACE_STATUS.RETURNED:
          case WORKSPACE_STATUS.RECALLED:
          case WORKSPACE_STATUS.FAILED:
          case WORKSPACE_STATUS.COMPLETED:
          case WORKSPACE_STATUS.SYSTEM_RECALLED:
            stoppedIdArray.push(eachItem.id)
            stoppedNameArray.push(eachItem.name)
            break
        }
      }
      if (runningIdArray.length === 0) {
        let message:string = ''
        /* if (pendingIdArray.length > 0){
          if (canceledIdArray.length===0 && stoppedIdArray.length===0 &&pendingIdArray.length===1){
            message =`${pendingNameArray[0]}이(가) 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length===0 && stoppedIdArray.length===0 && pendingIdArray.length>1){
            message=`${targetIdNameArray[0].name} 외 ${targetIdNameArray.length-1}개 항목이 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length > 0 && stoppedIdArray.length > 0){
            message=`${targetIdNameArray[0].name} 외 ${targetIdNameArray.length-1}개 항목이 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length === 0 && stoppedIdArray.length > 0){
            message=`${targetIdNameArray[0].name} 외 ${targetIdNameArray.length-1}개 항목이 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length && stoppedIdArray.length === 0){
            message=`${targetIdNameArray[0].name} 외 ${targetIdNameArray.length-1}개 항목이 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          }
        } else if (pendingIdArray.length === 0){
          if (canceledIdArray.length===1&&stoppedIdArray.length===0){
            message=`${canceledNameArray[0]}이(가) 삭제됩니다. 진행하시겠습니까? `
          } else if (canceledIdArray.length===0&&stoppedIdArray.length===1){
            message=`${stoppedNameArray[0]}이(가) 삭제됩니다. 진행하시겠습니까? `
          } else if (canceledIdArray.length === 0 && stoppedIdArray.length > 1){
            message=`${targetIdNameArray[0].name}외 ${stoppedIdArray.length+canceledIdArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length > 1 && stoppedIdArray.length === 0){
            message=`${targetIdNameArray[0].name}외 ${stoppedIdArray.length+canceledIdArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length === 1 && stoppedIdArray.length > 1){
            message=`${targetIdNameArray[0].name}외 ${stoppedIdArray.length+canceledIdArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length > 1 && stoppedIdArray.length === 1){
            message=`${targetIdNameArray[0].name}외 ${stoppedIdArray.length+canceledIdArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          } else if (canceledIdArray.length > 1 && stoppedIdArray.length > 1){
            message=`${targetIdNameArray[0].name}외 ${stoppedIdArray.length+canceledIdArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          }
        } */
        if (pendingNameArray.length > 0) {
          if(stoppedNameArray.length===0 &&pendingNameArray.length===1) {
            message =`${pendingNameArray[0]}이(가) 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if(stoppedNameArray.length === 0 && pendingNameArray.length > 1) {
            message = `${pendingNameArray[0]} 외 ${targetIdNameArray.length-1} 개 항목이 삭제되며 신청도 취소됩니다. 진행하시겠습니까?`
          } else if (stoppedNameArray.length > 0 && pendingNameArray.length >= 1) {
            message=`${pendingNameArray.length} 개의 생성 대기중 항목이 포함 되어 있습니다. 삭제 시 생성 대기중 항목은 신청이 취소됩니다. 진행하시겠습니까?`
          }
        } else if (pendingNameArray.length === 0) {
          if(stoppedNameArray.length === 1) {
            message=`${stoppedNameArray[0]}이(가) 삭제됩니다. 진행하시겠습니까? `
          } else if(stoppedNameArray.length > 1) {
            message=`${stoppedNameArray[0]}외 ${stoppedNameArray.length-1}개 항목이 삭제됩니다. 진행하시겠습니까?`
          }
        }
        let payload = {
          targetIdNameArray: targetIdNameArray,
          runningNameArray: runningNameArray,
          pendingNameArray: pendingNameArray,
          stoppedNameArray: stoppedNameArray
        }
        popupController.dialouge(message, 'deleteWsDev', payload, '확인', '취소')
      } else if (runningIdArray.length === 1) {
        popupController.confirm(`${runningNameArray[0]} 을 반납한 후 삭제 가능합니다.`)
      } else {
        popupController.confirm(`${runningIdArray.length}개의 운영 중 항목이 포함 되어 있습니다. 이를 모두 반납한 후 삭제 가능합니다.`)
      }
    } else {
      popupController.confirm(`선택된 항목이 없습니다.`)
    }
    /* popupController.dispatchEvent(e) */
  }

  const  modalActionHandler = async (e:ModalEvent) => {
    const items = state.tableItems
    const selectedIdx = tableRef.current?.selected
    let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)

    switch (e.payload.action) {
      case 'deleteWsDev':
        try {
          const targetIdNameArray:any[] = e.payload.key.targetIdNameArray
          const pendingNameArray:string[] = e.payload.key.pendingNameArray
          const stoppedNameArray:string[] = e.payload.key.stoppedNameArray
          // targetIdNameArray.push({id:41234123, name: "없는워크스페이스"},{id: 4111111, name: "에러워크스페이스"}) //에러테스트용이었음
          if (items && selectedIdx && selectedIdx.length > 0) {
            const response = await Workspace.removeWorkspace(targetIdNameArray)
            let message:string = ''
            if (!response) {
              //console.log('peding>',pendingNameArray,'stopped>', stoppedNameArray,'targetid>', targetIdNameArray)
              if (pendingNameArray.length > 0) {  //대기중인항목이무조건 포함되어있을때
                if(pendingNameArray.length === 1 && stoppedNameArray.length === 0) {
                  message = `${targetIdNameArray[0].name} 이(가) 삭제 및 신청 취소되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                } else if (stoppedNameArray.length >= 0 && pendingNameArray.length >= 1) {
                  message = `생성 대기 중 항목 포함 ${targetIdNameArray.length}개의 항목이 삭제 및 신청 취소되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                }
              } else if (pendingNameArray.length === 0) {
                if (stoppedNameArray.length === 1) {
                  message = `${targetIdNameArray[0].name} 이(가) 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                } else if (stoppedNameArray.length > 1) {
                  message = `${targetIdNameArray[0].name} 외 ${targetIdNameArray.length - 1}개 항목이 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                }
              } else {
                if (targetIdNameArray.length === 1) {
                  message = `${targetIdNameArray[0].name} 이(가) 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                } else {
                  message = `${targetIdNameArray[0].name} 외 ${targetIdNameArray.length - 1}개 항목이 삭제되었습니다. 더 이상 사용하지 않는 이미지나 데이터가 있다면 함께 삭제해 주세요.`
                }
              }
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
            } else {
              //failed가 있는 상태
              message = `${targetIdNameArray.length}개 삭제 요청 중 ${response.data.failedCount}개 삭제를 실패하였습니다. 다시 시도해 주세요.\n* 삭제 성공 ${response.data.deletedCount}개 / 실패 ${response.data.failedCount}개  `
              popupController.confirm(message)
            }

            tableRef.current?.setSelected([])
            getData()
            navigateToPage(state.page)
          }

        } catch (error:any) {
          //400이외의 ERROR만 발생시 뜸
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 1f54c0')
        }
        break
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  const onChangeSelectResourceGroup = async (data:ISelectOption) => {
    let apiKey:string
    if (data.label === '전체 리소스 그룹') {
      apiKey = ''
    } else {
      apiKey = data.label
    }
    setGroupState({
      ...groupstate,
      rgroup: apiKey
    })
  }

  return (
    <WorkspaceListFragment>
      <div className="titleArea">
        <h2 className="pageTitle">
          워크스페이스 목록
          <span className="total"><b>{state.totalArticle}</b> 개</span>
        </h2>
      </div>
      <div className="titleArea bothEnd">
      <Select selectRef={selectResourceGroupRef} option={groupstate.resourceGroupSelectList}
                selected={ groupstate.resourceGroupSelectList.find(element => element.label === groupstate.rgroup) } 
                onChange={onChangeSelectResourceGroup} />
        <div className="btnWrap">
          <button className={state.selectedItems !== undefined && state.selectedItems.length > 0 ? "btn outline" : "btn outline off"} onClick={removeWorkspaceHandler}>선택 항목 삭제</button>
          <button className="btn blue" onClick={newWorkspaceButtonHandler}>워크스페이스 신청</button>
          {/* <button className="btn blue" onClick={newJobButtonHandler}>Job 신청</button> */}
        </div>
      </div>
      <Table ref={tableRef}
        colWidth={tableColWidth}
        headerLabel={headerLabel}
        items={state.tableItems}
        noData="사용중인 워크스페이스가 없습니다."//미리변경해둠 원: 사용중인 워크스페이스 또는 Job이 없습니다.
        orderPrefix={(state.page - 1) * 50}
        onUpdate={onUpdateStatus}
        onSelect={selectBoxCheckHandler}
        checkBoxType={CHECKBOX_TYPE.USE_ALL}
        defaultSortOrder={SORT_ORDER.ASC}/>
      {state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage: number) => navigateToPage(newPage)} /> : false
      }
    </WorkspaceListFragment>
  )
}

const WorkspaceListFragment = styled.div`
tbody td { height:92px; }
  .col1 {}
  .col2,
  .col3,
  .col4,
  .col5,
  .col6{ text-align:center; }

  .col0 div span { display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:5; overflow:hidden; max-height:5.8em; text-overflow:ellipsis; }
  .col4 span { display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:5; overflow:hidden; max-height:5.8em; text-overflow:ellipsis; }

  .boxleft { width:68px; }
  .bar { content:''; display:inline-block; width:2px; height:11px; background:#dfdfdf; vertical-align:middle; margin:0 15px; }
  a.url { color:#2c78ff; text-decoration:underline; }
  .titleArea p { width:578px; }//드롭박스 사이즈를 맞추려 함
  .name { font-weight:700; } 
  .wsInfo { margin-right:5px; }
  .jupyterlogo { vertical-align:bottom; width:18px; margin-right:5px; }

  .fail td {color:#a7a7a7;}
  .fail td img {opacity:1;}
  .fail td button img,
  .fail td a img {opacity:1;}
  .fail td.checkWrap img {opacity:1;}
  .warn td {color:#f30b0b;}
  .fail td .subModalPopupWrap *, .warn td .subModalPopupWrap *, .serviceError td .subModalPopupWrap * {color:#303030; }
  .fail td td, .fail td thead, .warn td td, .warn td thead, .serviceError td td, .serviceError td thead {color:#303030; }

  .gpuUnderblock { display:flex; gap:10px; margin-top:10px; }
  `

export default WorkspaceList