
import styled from 'styled-components'

const Logout = () => {
  return (
    <LogoutFragment>

    </LogoutFragment>
  )
}

const LogoutFragment = styled.div`
background-color: #fff;
`

export default Logout