import React, { Component } from 'react'
import Highcharts from 'highcharts/highcharts.js'
import HighchartsReact from 'highcharts-react-official'
import { IChartSeries } from '../../../interfaces/Chart'
import Hash from 'object-hash'

export enum YAXIS_TYPE {
  NONE = 0,
  SINGLE,
  COUPLE
}
interface IBarChartProps {
  height:number
  data:IChartSeries[]
  categories?:string[]
  datetime?:boolean
  pointWidth?:number
  max?:number
  min?:number
  unit?:string
  totalLabel?:string
  hideTotalFlag?:boolean
  yAxisType?:YAXIS_TYPE
  yAxisStyle?:Object
  //yAxisSingle?:boolean
  toolTipFormat?:Function
  yAxisHeight?:number
  legend?:boolean
  minX?:number //timestamp
  maxX?:number //timestamp
}

Highcharts.setOptions({
  colors: ['#BECC23', '#E68C4F', '#0F8D62', '#583577', '#B48E04', '#677FA4', '#CDAC80', '#502BBB', '#7C2A03', '#025F5C', '#590608', '#848614', '#B8718A', '#0A6899', '#B83A40', '#013A50', '#F45649', '#072D9F', '#1293E9', '#E2589F', '#039CA4', '#AA80E9', '#7DB498'],
  lang: {
    thousandsSep: ','
  }
})

export default class BarChart extends Component<IBarChartProps> {
  /*constructor(props:any) {
    super(props)
  }*/  
  private chartData:object = {
    chart: {
      type: 'column',
      height: this.props.height,
      spacingTop: this.props.unit ? 20 : 5,
      //marginBottom: 100
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: this.props.categories ? this.props.categories : null,
      type: this.props.datetime ? 'datetime' : '',
      dateTimeLabelFormats: {
        day: '%m/%d',
      },
      gridLineWidth: 0,
      labels: {
        rotation: 0,
        style: this.props.yAxisStyle ? this.props.yAxisStyle : {},
      },
      min: this.props.minX? this.props.minX: null,
      max: this.props.maxX? this.props.maxX: null
    },
    yAxis: 
    this.props.yAxisType === YAXIS_TYPE.NONE ? 
    [{
      visible: false,
    }
    ] : this.props.yAxisType === YAXIS_TYPE.SINGLE ? 
    [{
      title: {
        text: null
      },
      max: this.props.max ? this.props.max : null,
      floor: this.props.min ? this.props.min : null,
      lineWidth: 1,
      height: this.props.yAxisHeight? this.props.yAxisHeight : null
    }] : 
    [
      {
        title: {
          text: `(${this.props.unit})`,
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -10,
          x: -5
        },
        max: this.props.max ? this.props.max : null,
        floor: this.props.min ? this.props.min : null,
        lineWidth: 1,
        height: this.props.yAxisHeight? this.props.yAxisHeight : null
      }, {
        title: {
          text: `(${this.props.unit})`,
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -10,
          x: -5
        },
        max: this.props.max ? this.props.max : null,
        floor: this.props.min ? this.props.min : null,
        lineWidth: 1,
        linkedTo: 0,
        opposite: true,
        height: this.props.yAxisHeight? this.props.yAxisHeight : null
    }],
    tooltip: {
      enabled: true,
      valueSuffix: this.props.unit,
      footerFormat: this.props.hideTotalFlag === true ? '' : `<b>${this.props.totalLabel ? this.props.totalLabel : '합'}  {point.total:,.0f}${this.props.unit}</b>`,//divider 추가하는경우 사용 <div style="height:1px; background:#ccc; margin-top:13px;"></div>
      shared: (this.props.toolTipFormat === undefined),
      useHTML: true,
      formatter: this.props.toolTipFormat,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        },
        opacity: 1
      },
      series: {
        pointWidth: this.props.pointWidth? this.props.pointWidth : this.props.datetime ? 4 : 40,
        opacity: 0.8,
        states: {
          hover: {
            brightness: 0,
            opacity: 1
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    legend : {
      maxHeight: 69,
      enabled: this.props.legend === false ? this.props.legend : true,
    }, 
    series: this.props.data
  }
  private chartComponent:any = React.createRef()

  componentDidUpdate(prev:any) {
    if (Hash.MD5(prev.data) !== Hash.MD5(this.props.data)) {
      this.chartComponent.current?.chart.update({
        series: this.props.data
      }, true, false, false)
    }
  }

  public render():React.ReactElement {
    return (
      <HighchartsReact highcharts={Highcharts} options={this.chartData} ref={this.chartComponent} />
    )
  }
}
