import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Params, useParams, useNavigate } from 'react-router-dom'
import Table, { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from "../components/ui/Table"

import { SORT_ORDER } from '../../model/BaseDataType'
import PageEvent from "../../events/PageEvent"
import TableEvent from "../../events/TableEvent"
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from "../../events/ToastEvent"

import { IResourceGroupListItem } from '../../model/ResourceGroup'
import PopupController from '../../controller/PopupController'
import { ResourceGroup } from "../../model/ResourceGroup"
import { Link } from "react-router-dom"
import SubPopup from "../components/modal/sub/SubPopup"
import GroupResource from "../components/table/GroupResource"
import Pagenation from "../components/ui/Pagenation"
import { ERROR_TYPE } from "../../interfaces/Error"

import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../Config'


interface ResourceGroupListProps {
  params?:Params
  groupName?:string
}
interface ResourceGroupListState {
  page:number
  totalArticle:number
  tableItems:IResourceGroupListItem[]
}

const ResourceGrouList = (props:ResourceGroupListProps) => {
  const params = useParams()
  const tableRef = useRef<any>()
  const popupController = PopupController.getInstance()
  const tableColWidth = [254, 220, 563, 280]
  const headerLabel: ITableHeader[] = [
    { label: '리소스 그룹 이름', key: 'tableGroupName', type: TABLE_CELL_TYPE.STRING, orderFlag: true, sortKey: 'name', sort: SORT_ORDER.ASC, rowSpanKey: 'rowSpan'},
    { label: '사용자 그룹', key: 'tableUserList', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, rowSpanKey: 'rowSpan' },
    { label: '리소스 그룹', key: 'tableResourceGroup', type: TABLE_CELL_TYPE.FLEXBOX, sort: false},
    { label: '상태', key: 'tableStatus', type: TABLE_CELL_TYPE.FLEXBOX, sort: false}
  ]

  const [ state, _setState] = useState<ResourceGroupListState>({
    totalArticle: 0,
    page: 1,
    tableItems:[],
  })
  
  const stateRef = useRef(state)

  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const navigate = useNavigate()

  useEffect(() =>{
    //window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.addEventListener(TableEvent.GROUP_ADDED_USER, selectUserHandler)
    window.addEventListener(TableEvent.GROUP_ADDED_NODE, selectNodeHandler)
    window.addEventListener('volumemount', eventControl2) 
    return () => {
      //window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.removeEventListener(TableEvent.GROUP_ADDED_USER, selectUserHandler)
      window.removeEventListener(TableEvent.GROUP_ADDED_NODE, selectNodeHandler)
      window.removeEventListener('volumemount', eventControl2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const eventControl = (e:Event) => {
    getListData()
  }

  const eventControl2 = (e:Event) => {
    getListData(1)
  }
  
  const getListData = async (page?:number):Promise<void> => {
    /* let sortKey:GROUP_LIST_ORDER_BY
    switch(tableRef.current?.sortKey) {
      case 'name':
        sortKey = GROUP_LIST_ORDER_BY.NAME
        break
        //추후 정렬이 다른게 추가될때 쓰게되겠지만서도
    } */
    const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
    
    try {
      const groupList = await ResourceGroup.getGroupList(sortOrder, state.page, 50)
      
      if (groupList) {
        for (let eachLine of groupList.rgroup) {
          eachLine.tableGroupName = eachLine.isCommon ? '기본 리소스 그룹' : <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachLine.name}</EllipsisToolTip>

          eachLine.tableUserList = eachLine.userList && eachLine.userList.length > 0 ? 
          [[<div className='userDataBox'>
              <div style={{display:'flex', gap:'5px', fontWeight:'700', marginBottom:'10px'}}>총 {eachLine.userList.length} 명{eachLine.userList.length > 8 ?
                <div className="resourceBoxSub"><SubPopup titles={["전체 사용자"]} datas={eachLine.userList} colAlignCenter={true} onLeft={true} /></div>
                : false}</div>
              
            </div>],
            [<div className='userNameBox'>
              {eachLine.userList.map((eachUser: any, idx: number) => { 
                if (idx > 8) { return (false) }
                else { return (<div key={idx}><span><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachUser}</EllipsisToolTip></span></div>) }
              })}
            </div>],
            [<div>{eachLine.userList.length > 9 ? '외 ' + (eachLine.userList.length - 9) + ' 명' : false} </div>]
          ] :
          [
            [<div style={{ textAlignLast: 'center' }}><button className="btn outline btnSm" 
            onClick={(e) => { e.preventDefault(); popupController.groupUser(()=>{}, eachLine.name)}}>사용자 추가</button></div>
            ]
          ]

          eachLine.tableResourceGroup = [
            [<GroupResource data={eachLine}/>]
          ]

          eachLine.tableStatus =
          [
            [<div style={{marginBottom:'20px'}}>가동 중 워크스페이스 <span style={{fontWeight:'700'}}>: {eachLine.serviceCount} 개</span></div>],
            [<Link to={'/rgroups/admin/'+eachLine.name+'/detail'} className="btn btnSmRgroupStatus outline detailbtn">상세 보기</Link>],
            [<Link to={'/queue?rgroup='+eachLine.name} className="btn btnSmRgroupStatus outline detailbtn">대기열 관리</Link>]
          ]

        }
        setState({
          ...state,
          totalArticle: groupList.rgroupCount,
          tableItems: groupList.rgroup
        })
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c62f98')
    }
  }

  const groupNamePopupHandler = (e:any) => {
    e.preventDefault()
    popupController.groupName()
  }

  const selectUserHandler = async (e:TableEvent):Promise<void> => {
    try {
      let popupEvent: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
      let data = e.payload
      let result = await ResourceGroup.updateGroupUser(data.groupName, data)

      if (result !== 200) {
        popupController.confirm('사용자 추가에 실패하였습니다.')
        return
      } else if(result === 200) {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        if (data.userList.length > 1) {
          toastEvent.payload = { message: `${data.firstselectedUser.name}(ID: ${data.firstselectedUser.userId}) 외 ${data.userList.length - 1} 명의 사용자를 추가하였습니다.` }
        } else {
          toastEvent.payload = { message: `${data.firstselectedUser.name}(ID: ${data.firstselectedUser.userId}) 사용자를 추가하였습니다.` }
        }
        window.dispatchEvent(toastEvent)

        navigate('/rgroups/admin/' + data.groupName + '/detail')
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c60df8')
      return
    }
  }

  const selectNodeHandler = async (e:TableEvent):Promise<void> => {
    try {
      let popupEvent: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
      let data = e.payload
      let result = await ResourceGroup.updateGroupNode(data.groupName, data)

      if (result !== null && result.detail) {
        popupController.confirm('노드 추가에 실패하였습니다.')
        return
      }

      if (result !== null && result.detail.msg) {
        popupEvent.payload = { message: result.data.detail }
        window.dispatchEvent(popupEvent)
      } else {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        if (data.nodeList.length > 1) {
          toastEvent.payload = { message: `${data.firstselectedNode} 외 ${data.nodeList.length - 1}개의 노드를 추가하였습니다.` }
        } else {
          toastEvent.payload = { message: `${data.firstselectedNode} 노드를 추가하였습니다.` }
        }

        window.dispatchEvent(toastEvent)
        navigate('/rgroups/admin/' + data.groupName + '/detail')
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - eee634')
      return
    }
  }

  const modalActionHandler = (e:ModalEvent) => {
    switch(e.payload.action) {
      case 'closeCreateResourceGroupPopup':
        getListData()
      break
      case 'closeUserModalPopup':
        getListData()
      break
    }
  }

  const navigateToPage = (newPage:number):void => {
    // setState({
    //   ...state,
    //   page:page
    // })
    if(stateRef.current.page !== newPage) { //이전 페이지와 같으면 굳이 재호출하지 않음 (중복 호출 방지)
      getListData(newPage)
    }
  }

  return (
    <GroupListPageFragment>
      <div className="titleArea bothEnd">
        <h2 className="pageTitle">
          리소스 그룹 목록
          <span className="total"><b>{state.totalArticle}</b> 개</span>
        </h2>
        <div className="btnWrap">
          <button className="btn blue" onClick={groupNamePopupHandler}>리소스 그룹 생성</button>
        </div>
      </div>
      <Table ref={tableRef}
        colWidth={tableColWidth}
        headerLabel={headerLabel}
        items={state.tableItems}
        noData="그룹이 없습니다."
        orderPrefix={(state.page - 1) * 50}
        checkBoxPreserveKeys={['isCommon']}
        checkBoxType={CHECKBOX_TYPE.NONE}
      />
      { state.totalArticle > 0 ?
          <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage:number) => navigateToPage(newPage)} />  : false 
      }
    </GroupListPageFragment>
  )
}

const GroupListPageFragment = styled.div` 
.col1 { text-align:center; }
.btnSmRgroupStatus { width:52px; height:24px; line-height:24px; font-size:12px; padding:0 8px; margin-bottom:10px; background-color:#ffffff; }
table tr.isCommon td { background-color:#edf4f4; }
.resourceBoxSub table tr td { background-color:#ffffff;}
.userNameBox { text-align:left; max-width:220px; }
`
export default ResourceGrouList