import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { User, IUserDetail } from '../../model/User'
import { ERROR_TYPE } from '../../interfaces/Error'
import ModalMessage from '../components/ModalMessage'
import { useEffect, useState } from 'react'
import PopupController from '../../controller/PopupController'
import ModalEvent from '../../events/ModalEvent'
import InputBox, { TEXT_INPUT_TYPE } from "../components/ui/InputBox"
import regularExpression from '../../utils/regularExpression'
import CryptoJS from 'crypto-js'
import utils from '../../utils'

interface JoinProps {
}
interface JoinState {
  name:string
  nameError:string
  department:string
  departmentError:string
  phoneNumber:string
  phoneNumberError:string
  userId:string
  idError:string
  canDuplicationCheckFlag:boolean
  idDupliacationCheckFlag:boolean
  email:string
  emailError:string
  pw:string
  pwError:string
  confirmPw:string
  confirmPwError:string
  additionalInfo:string
}

const Join = (props:JoinProps) => {
  const user = User()
  const popupController = PopupController.getInstance()

  const [state, setState] = useState<JoinState>({
    name: '',
    nameError: '',
    department: '',
    departmentError: '',
    phoneNumber: '',
    phoneNumberError: '',
    userId: '',
    idError: '',
    email: '',
    emailError: '',
    pw: '',
    pwError: '',
    confirmPw: '',
    confirmPwError: '',
    canDuplicationCheckFlag: false,
    idDupliacationCheckFlag: false,
    additionalInfo:''
  })

  useEffect(() => {
    document.addEventListener('keydown', preventEnterKey)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      document.removeEventListener('keydown', preventEnterKey)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  },[])

  const preventEnterKey = (e:any) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }
  }
  const navigate = useNavigate()

  const inputHandler = (target:string, value:any) => {
    let errorText:string = ''
    let errorcheck:boolean = false

    switch (target) {
      case 'userId':
        errorText = ''
        let canDuplicationCheckFlag:boolean = false
        if (value !== '') {
          if (value.length < 6 || value.length > 30 || value.match(regularExpression.regExp.ID_REG_EXP) === null) {
            errorText = '6~30자 소문자, 숫자로 입력해 주세요.'
          } else {
            errorText = ''
            canDuplicationCheckFlag = true
          }
        }
        setState({
          ...state,
          userId: value,
          idError: errorText,
          idDupliacationCheckFlag: false, 
          canDuplicationCheckFlag: canDuplicationCheckFlag
        })
        break
      case 'name':
        errorText = ''
        if (value !== '') {
          if (!regularExpression.regExp.NAME_REG_EXP_START.test(value)||!regularExpression.regExp.NAME_REG_EXP.test(value)) {
            errorText = '한글, 영어 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
          }
        }
        setState({
          ...state,
          name: value,
          nameError: errorText,
        })
        break
      case 'department':
        errorcheck = value === '' ? false : value.match(regularExpression.regExp.DIV_REG_EXP) === null
        setState({
          ...state,
          department: value,
          departmentError: errorcheck ? '한글, 영문 대소문자, 숫자만 입력해 주세요.' : '',
        })
        break
      case 'email':
        if (value !== '') {
          if (value.match(regularExpression.regExp.MAIL_REG_EXP) === null) {
            errorText = '올바르지 않는 이메일 주소입니다.'
          }
        }
        setState({
          ...state,
          email: value,
          emailError: errorText
        })
        break
      case 'phoneNumber':
        errorcheck = value === '' ? false : regularExpression.regExp.HP_REG_EXP.test(value)
        setState({
          ...state,
          phoneNumber: value,
          phoneNumberError: errorcheck? '숫자만 입력해 주세요.' : '',
        })
      break
      case 'pw':
        if(value !== '') {
          if(value.length < 9 || value.length > 100 || value.match(regularExpression.regExp.PW_REG_EXP) === null || value.match(regularExpression.regExp.PW_REG_SPECIAL_SYMBOLS) !== null) {
            errorText = '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
          } else if(/(\w)\1\1/.test(value) || /(\$|\@|\!|\%|\*|\#|\?|\&)\1\1/.test(value) || utils.pwContinue(value)) {
            errorText = '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다'
          }
        }
        setState({
          ...state,
          pw: value,
          pwError: errorText,
          confirmPwError: value !== state.confirmPw && state.confirmPw !== '' ? '비밀번호가 일치하지 않습니다.' : '' 
        })
      break
      case 'confirmPw':
        if (value !== '') {
          if (value !== state.pw) {
            errorText = '비밀번호가 일치하지 않습니다.'
          }
        }
        setState({
          ...state,
          confirmPw: value,
          confirmPwError: errorText
        })
        break
      case 'additionalInfo':
          setState({
            ...state,
            additionalInfo: value
          })
          break
    }
  }

  const validateInput = async (e:any) => {
    e.preventDefault()

    let blankErrorFlag:boolean = false
    let validationErrorFlag:boolean = false
    let confirmErrorFlag:boolean = false

    let idError:string = state.idError
    let phoneNumberError:string = state.phoneNumberError
    let nameError:string = state.nameError
    let emailError:string = state.emailError
    let departmentError:string = state.departmentError
    let pwError:string = state.pwError
    let confirmPwError:string = state.confirmPwError

    let payload:any = {
      name: state.name,
      department: state.department,
      phoneNumber: state.phoneNumber,
      email: state.email,
      userId: state.userId, 
      pw: state.pw
    }
    if (!payload.name) {
      nameError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if(state.nameError !== '') {
      validationErrorFlag = true
    }

    if (!payload.email) {
      emailError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if(state.emailError !== '') {
      validationErrorFlag = true
    }

    if (!payload.userId) {
      idError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if (state.idError !== '') {
      validationErrorFlag = true
    }

    if (!payload.pw) {
      pwError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if (state.pwError !== '') {
      validationErrorFlag = true
    }

    if (payload.pw && (payload.pw.length < 9 || payload.pw.length > 100 || payload.pw.match(regularExpression.regExp.PW_REG_EXP) === null || payload.pw.match(regularExpression.regExp.PW_REG_SPECIAL_SYMBOLS) !== null)) {
      pwError = '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
      validationErrorFlag = true
    }

    if (!state.confirmPw) {
      confirmPwError = '필수 입력 항목입니다.'
      blankErrorFlag = true
    } else if (state.confirmPwError !== '') {
      confirmErrorFlag = true
      validationErrorFlag = true
      blankErrorFlag= false
    }

    if (state.departmentError !== '') {
      validationErrorFlag = true
    }

    if (state.phoneNumberError !== '') {
      validationErrorFlag = true
    }

    if (blankErrorFlag === true || validationErrorFlag === true) {
      setState({
        ...state,
        nameError: nameError,
        emailError: emailError,
        idError: idError,
        pwError: pwError,
        departmentError: departmentError,
        phoneNumberError: phoneNumberError,
        confirmPwError: confirmPwError
      })
    }

    if (blankErrorFlag === true) {
      popupController.confirm('필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      return false
    } else if (blankErrorFlag === false && confirmErrorFlag === true) {
      popupController.confirm('비밀번호가 일치하지 않습니다. 다시 입력해 주세요.')
      return false
    } else if (blankErrorFlag === false && validationErrorFlag === true) {
      popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      return false
    } else if (blankErrorFlag === false && validationErrorFlag === false && !state.idDupliacationCheckFlag) {
      popupController.confirm('아이디의 중복 여부를 확인해 주세요.')
      idError = '아이디 중복 확인을 해 주세요.'
      setState({
        ...state,
        nameError: nameError,
        emailError: emailError,
        idError: idError,
        pwError: pwError,
        departmentError: departmentError,
        phoneNumberError: phoneNumberError,
        confirmPwError: confirmPwError
      })
      return false
    } else if (validationErrorFlag === false && blankErrorFlag === false) {
      submitHandler()
    }
  }

  const submitHandler = async () => {
    try {
      let payload = {
        userId: state.userId,
        name: state.name,
        department: state.department,
        phoneNumber: state.phoneNumber,
        email: state.email,
        pw: state.pw,
        additionalInfo: state.additionalInfo
      }

      const response = await user.join(payload)
      if(response === null){
        popupController.confirmCustom('사용자 등록이 완료되었습니다.', 'joinComplete', 'joinComplete', '확인')
      }
    } catch(error) {
      if((error as Error).message === ERROR_TYPE.DUPLICATED){
        popupController.confirm('다른 사용자에 의해 이미 등록된 이메일 주소입니다. 이메일 주소를 변경해 주세요.' )
      }
      else if((error as Error).message === ERROR_TYPE.ERROR){
        popupController.confirm('에러가 발생했습니다.\n에러코드 - d437d1')
      }
    }
  }

  const checkDuplicateHandler = async (e:any) => {
    e.preventDefault() //없으면 페이지 새로로딩되어버림
    try { 
      const response = await user.checkUserId(state.userId)
      if(response) {
        setState({
          ...state,
          idDupliacationCheckFlag: false,
          idError: '사용중인 아이디입니다.'
        })
        popupController.confirm('사용중인 아이디입니다.')
      } else {
        setState({
          ...state,
          idDupliacationCheckFlag: true,
          idError: ''
        })
        popupController.confirm('사용 가능한 아이디입니다.')
      }
    } catch(error) {
      if((error as Error).message === ERROR_TYPE.ERROR){
        popupController.confirm('에러가 발생했습니다.\n에러코드 - aec8c2')
      }
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'joinComplete':
        navigate('/')
        break
    }
  }

  const goBack = () => {
    let postUrl:string = '/'
    navigate(postUrl)
  }

  return (
  <JoinFragment>
    <ModalMessage />
    {/* <Modal /> */}
    <div className="joinWrap">
      <div className="titleWrap">
        <h1>사용자 등록</h1>
        <p>사용자 등록 정보 중 * 표기된 부분은 필수 입력 항목입니다. 시스템 문제 발생 시 연락 가능한 정보를 입력해 주세요.</p>
      </div>
      <form>
        <div className="formGroup">
          <label className="label">이름 <span>*</span></label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="name" type={TEXT_INPUT_TYPE.TEXT} maxLength={63} value={state.name} placeholder="이름을 입력하세요." warning={state.nameError}/*  error={state.nameError != ''} */ onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">부서</label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="department" type={TEXT_INPUT_TYPE.TEXT} maxLength={100} value={state.department} placeholder="부서명을 입력하세요." warning={state.departmentError} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">전화번호</label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="phoneNumber" type={TEXT_INPUT_TYPE.TEL} placeholder="연락 가능한 전화번호를 입력하세요." maxLength={15} value={state.phoneNumber}
                warning={state.phoneNumberError} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">이메일 <span>*</span></label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="email" type={TEXT_INPUT_TYPE.TEXT} value={state.email} placeholder="연락 가능한 이메일 주소를 입력하세요." warning={state.emailError} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">아이디 <span>*</span></label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="userId" type={TEXT_INPUT_TYPE.TEXT} placeholder="아이디를 입력하세요." warning={state.idError} onChange={inputHandler} />
              <button className={"btn outline duplication " + (state.userId && state.canDuplicationCheckFlag ? '' : ' off')} onClick={checkDuplicateHandler}>중복 확인</button>
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">비밀번호 <span>*</span></label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="pw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder="비밀번호를 입력하세요." warning={state.pwError} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">비밀번호 확인 <span>*</span></label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="confirmPw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder="비밀번호를 한번 더 입력하세요." warning={state.confirmPwError} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <label className="label">기타 정보</label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="additionalInfo" type={TEXT_INPUT_TYPE.TEXT} placeholder="관리자가 요청한 정보를 입력해 주세요." maxLength={100} value={state.additionalInfo} onChange={inputHandler} />
            </div>
          </div>
        </div>
        <div className="btnWrap">
          <button className="btn grey" onClick={goBack}>취소</button>
          <button className="btn blue" onClick={validateInput}>등록 요청하기</button>
        </div>
      </form>
    </div>
  </JoinFragment>)
}

const JoinFragment = styled.div`
  overflow:auto;height:100vh;
  .joinWrap { max-width:830px; margin:164px auto 0 auto; padding:0 50px; min-height:800px; }
  .titleWrap { display:flex; align-items:center; gap:10px; margin-bottom:40px; color:#303030; }
  .titleWrap h1 { font-size:24px; }
  .titleWrap p { font-size:14px; }
  .formGroup + .formGroup { margin-top:20px; }
  .label { font-weight:bold; }
  .label span { font-weight:normal; }
  .joinWrap .btn.blue { display:block; width:240px; margin:120px auto 0;}
  .joinWrap .btnWrap { display:flex; align-items:center; justify-content:center; gap:10px; margin-top:120px; }
  .joinWrap .btnWrap .btn { width:240px;margin:0; }
  .inputBox button { width:96px; align-self: start; }
`

export default Join