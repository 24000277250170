import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import TableEvent from "../../events/TableEvent"

let flag = true

const NavigationOps = () => {
  const [ depth2Open, setDepth2Open ] = useState<boolean>(false)
  const [ path, setPath ] = useState('')
  const location = useLocation()

  useEffect(() => {
    document.addEventListener(TableEvent.RECREATE_WORKSPACE, changeStatusFalse)
    return () => {
      document.removeEventListener(TableEvent.RECREATE_WORKSPACE, changeStatusFalse)
    }
  }, [])
  
  useEffect(() => {
    const newPath = location.pathname
    const length = newPath.length
    setPath((newPath.substring(0, 9)==='/service/' &&  newPath.substring(9,13)!=='new')? //서비스 상세 페이지의경우 목록페이지에 강조색상 표기하기위함. 추후 경로가변경되면 이부분 수정필요.
    '/services' :
    newPath.substring(length-1) === '/' ? newPath.substring(0, length-1) : newPath)
  }, [location])

  const changeStatusFalse = () => {
    flag = false
    return flag
  }
  
  const setClass = (baseClass:string, pathName:string, url:string, exact:boolean=true) => {
    let depth1Class = depth2Open ? baseClass+' open' : baseClass
    if (exact === true) {
      if (pathName === url) {
        depth1Class += ' current'}
    } else {
      if (pathName.indexOf(url) === 0) {
        depth1Class += ' current'}
    }
    return depth1Class
  }

  return (
    <NavigationFragment>
      <nav>
        <h2 className="hide">메뉴</h2>
        <div className={setClass('depth1', path, '/alert')}>
          <NavLink to="/alert" onClick={() => { /* setDepth2Open(false) */ }}><img src="/images-v2/lnb-noti.svg" alt="통합 알림 센터"/><span className='clickArea'>통합 알림 센터</span></NavLink>
        </div>
        <div className={setClass('depth1', path, '/service/', false)}>
          <NavLink to="/service/new" onClick={() => { /* setDepth2Open(true) */ }}><img src="/images-v2/lnb-edit.svg" alt="서비스 생성"/><span className='clickArea'>서비스 생성</span></NavLink>
          {/* <button onClick={() => { setDepth2Open(!depth2Open) }}><img src="/images-v2/chevron-down.svg" alt="drop down" /></button> */}
        </div>
        { depth2Open ? 
          <div className={setClass('depth2', path, '/service/', false)}>
            <NavLink to="/service/new"><img src="/images-v2/lnb-down-right.svg" alt="depth2"/></NavLink>
          </div> : false }
        <div className={setClass('depth1', path, '/services')}>
          <NavLink to="/services" onClick={() => { /* setDepth2Open(false) */ }}><img src="/images-v2/lnb-notes2.svg" alt="서비스 목록"/><span className='clickArea'>서비스 목록</span></NavLink>
        </div>
        <div className={setClass('depth1', path, '/images')}>
          <NavLink to="/images" onClick={() => { /* setDepth2Open(false) */ }}><img src="/images-v2/lnb-images.svg" alt="이미지 목록"/><span className='clickArea'>이미지 목록</span></NavLink>
        </div>
      </nav>
    </NavigationFragment>
  )
}

const NavigationFragment = styled.aside`
  flex:none; width:234px;border-right: 1px solid #E8E8EE; transition: all 0.3s;background: #fff;z-index:2;
  nav{width:234px;margin-top:24px}
  nav a { display:flex; align-items:center; height:56px; color:#333; font-weight:500; transition:all 0.3s; font-size:14px; transition: width 0.3s;}
  nav .depth1{display:flex;justify-content: space-between;padding:0 16px 0 25px;cursor: pointer;}
  nav .depth1.open button img{transform: rotate(180deg);}
  nav .depth1 a{display:flex;align-items:center;gap:13px;}
  nav .depth1 button img{width:16px;transform: rotate(0);}
  nav a img{transition: all 0.3s;}
  nav .depth1:hover a,
  nav .depth2 a:hover{color:#4B82FF;}
  nav .depth1:hover img,
  nav .depth2 a:hover img,
  nav .depth1:hover img,
  nav .depth2.current img  {filter: invert(43%) sepia(95%) saturate(2194%) hue-rotate(208deg) brightness(103%) contrast(105%);}
  nav .depth1.current {background: #4B82FF;color:#fff;}
  nav .depth1.current a{color:#fff;}
  nav .depth1.current img {filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
  nav a img {width:18px;}
  nav .depth2{margin-bottom:8px;background: #F7F8FB;}
  nav .depth2 a{height:48px;gap:16px;padding:0 28px;font-weight:400;transition: all 0.3s;}
  nav .depth2 a img{width:12px;height:12px;}
  nav .depth2.current a { color:#4B82FF;font-weight:600; }

  .clickArea { width:176px; height:17px; padding:20px 0; }

  @media (max-width: 1671px) {
   overflow:hidden;width:48px;position: fixed;top:56px;left:0;bottom:0;
   :hover{width:234px;}
   nav .depth1{padding:0 15px;gap: 15px;}
   nav .depth1 a{font-size:0;}
   nav .depth2{overflow:hidden;height: 0;}
   nav .depth2 a{gap:16px;padding:0 16px;}
   :hover .depth2{height:initial;}
   :hover nav a{font-size:14px;} 
  }

`

export default NavigationOps