import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import Hash from 'object-hash'

import Button, { BUTTON_SIZE } from './Button'
import { TEXT_INPUT_SHAPE } from './InputBox'

export enum VALUE_STATUS {
  NORMAL = 'normal',
  ERROR = 'error',
}

export interface IMultiInputValue {
  value: string[],
  status: VALUE_STATUS
}

interface IMultiInputBoxProps {
  ref?:React.ForwardedRef<JSX.Element>
  id:string
  shape?:TEXT_INPUT_SHAPE
  placeholder?:string
  valueList?:IMultiInputValue[]
  useSystemAutoComplete?:boolean
  autoCompleteValue?:number[]|string[]
  helpText?:string
  errorText?:string
  maxLength?:number
  readonly?:boolean
  disabled?:boolean
  width?:string
  idx?:number
  fill?:boolean
  onChange?:Function
  onUpdate?:Function
  onDeleteButton?:Function
  button?:string
  onClickButton?:Function
}

const defaultProps = {
  width: '100%',
  useSystemAutoComplete: true,
  size: TEXT_INPUT_SHAPE.NORMAL
}

interface IMultiInputBoxState {
  focusInput:boolean
  value:string|number
  valueList:IMultiInputValue[]
  autoCompleteValue:string[]|number[]
}

const MultiInputBox = forwardRef<any, IMultiInputBoxProps>((props:IMultiInputBoxProps, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return state.value
      },
      validate: () => {
        let result = true
        for (let eachValue of state.valueList) {
          if (eachValue.status === VALUE_STATUS.ERROR) result = false
        }
        return result
      },
      getErrorMessage: () => {
        return props.errorText || ''
      }
    }
  })

  const [ state, setState ] = useState<IMultiInputBoxState>({
    focusInput: false,
    value: '',
    valueList: [],
    autoCompleteValue: props.autoCompleteValue || []
  })

  useEffect(() => {
    if (props.valueList !== undefined && props.valueList !== null && Hash.MD5(props.valueList) !== Hash.MD5(state.valueList)) {
      setState({
        ...state,
        valueList: props.valueList
      })
    }
  }, [props.valueList])

  useEffect(() => {
    if (state.autoCompleteValue !== props.autoCompleteValue) setState({
      ...state,
      autoCompleteValue: props.autoCompleteValue || []
    })
  }, [props.autoCompleteValue])

  const [ inputClass, _setInputClass ] = useState<string>()
  const setInputClass = () => {
    let newInputClass:string = 'inputBox ' + props.shape
    if (props.errorText) newInputClass += ' error'
    if (props.disabled) newInputClass += ' disable'
    if (props.fill) newInputClass += ' fill'
    if (state.focusInput) newInputClass += ' focus'
    
    _setInputClass(newInputClass)
  }

  useEffect(setInputClass, [props.shape, props.errorText, props.disabled, props.fill, state.focusInput])

  const inputIdx = props.idx ? props.idx : null
  return (
    <MultiInputBoxFragment style={{ width : props.width }} ref={ref}>
      <div className={inputClass}>
        <div className="valueWrap">
          <div className="valueList">
            { props.valueList && props.valueList.length ? 
            <>
              {props.valueList.map((ele, idx) => 
                <div className={ele.status} key={idx}>
                  { ele.value.length > 1 ? <>
                    <span className="name">{ele.value[0]}</span>
                    <span className="bar"></span>
                    {ele.value[1]}
                  </> : ele.value[0] }
                  
                  <button onClick={() => {
                    if (props.onDeleteButton) props.onDeleteButton(props.id, idx, inputIdx)
                  }}>
                    <img src={ele.status === VALUE_STATUS.ERROR ? '/images-v2/clip-x-r.png' : '/images-v2/clip-x.png'} alt="x" />
                  </button>
                </div>)}
            </> : false}
            <input type="text" id={props.id}
              value={state.value} 
              disabled={props.disabled} 
              readOnly={props.readonly}
              autoComplete={props.useSystemAutoComplete ? 'on' : 'off'}
              placeholder={state.value ? '' : props.placeholder}
              onFocus={() => {setState({ ...state, focusInput: true })}}
              onBlur={() => {
                if (props.onUpdate && state.value !== '' ) props.onUpdate(props.id, state.value, inputIdx)
                setState({ ...state, focusInput: false, value: '', autoCompleteValue: [] })
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  if (props.onUpdate && state.value !== '') props.onUpdate(props.id, String(state.value).trim(), inputIdx)
                  setState({ ...state, value: '' })
                }
              }}
              onChange={(e) => {
                setState({
                  ...state,
                  value: e.target.value
                })
                if (props.onChange) props.onChange(props.id, e.target.value, inputIdx)
              }} />
          </div>
        </div>
        {props.button ? <Button size={BUTTON_SIZE.SMALL} disabled={props.disabled} onClickButton={() => {
          if (props.onClickButton) {
            props.onClickButton(props.id, state.value, props.idx)
          }
        }}>{props.button}</Button> : false}
      </div>
      {state.autoCompleteValue && state.value !== '' && state.autoCompleteValue.length ? 
        <div className="dropWrap">
          { state.autoCompleteValue.map((eachValue:string|number, idx:number) => 
            <button key={idx} onMouseDown={():void => {
              if (props.onUpdate && state.value !== '') props.onUpdate(props.id, eachValue, inputIdx)
              setState({ ...state, value: '' })
            }}>{eachValue}</button>)}
        </div> : false
      }
      {props.helpText ? <p className="message">{props.helpText}</p> : false}
      {props.errorText ? <p className="message error">{props.errorText}</p> : false}
    </MultiInputBoxFragment>
  )
})

MultiInputBox.defaultProps = defaultProps

const MultiInputBoxFragment = styled.div`
  position: relative;
  .inputBox{position:relative;display:flex;align-items:flex-start;gap:8px;padding:6px;box-sizing:border-box;border-radius:6px;border:1px solid #D5D5DA;color:#333;transition:all 0.2s;}
  .inputBox:hover:not(.disable, .error),
  .inputBox.focus:not(.disable, .error){border-color:#646469;}
  .inputBox.normal{min-height:40px;font-size:14px;}
  .inputBox.small{min-height:32px;font-size:13px;}
  .inputBox.fill{background:#FAFAFB;}
  .inputBox.error{border-color:#FF0000;}
  .inputBox.disable{background:#FAFAFB;}
  .inputBox.disable .btnText{opacity:0.2;}
  .inputBox.disable input{opacity:0.3;}
  .inputBox input{width:100%;position:relative;flex:1;font-size:14px;color:#333;background:transparent;z-index:1;}
  .inputBox .placeholder{position:absolute;left:12px;top:10px;font-size:14px;font-weight:400;color:#333;opacity:0.4;}
  .inputBox button.small{flex:none;margin-top:2px;}
  .message{margin-top:8px;font-size:12px;line-height:20px;color:#878791;letter-spacing:0;}
  .message.error{color:#FF0000;}
  .valueWrap{flex:1;}
  .valueWrap input{margin:4px;}
  .dropWrap { overflow:auto; position:absolute;margin-top:6px;left:0; right:0; max-height:168px;padding:6px;border-radius: 6px;border:1px solid #646469; background:#fff;box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50);box-sizing:border-box;z-index:101;}
  .dropWrap button { display:block;width:100%; padding:12px; font-size:14px; text-align:left; word-break:break-all;transition: all 0.3s;color: #333;font-weight:400;}
  .dropWrap button:hover { background:#fafafa; }
  .valueList{display:inline-block;vertical-align:top;width:100%}
  .valueList > div{display:inline-block;height:20px;box-sizing:border-box;padding-left:8px;margin:4px;border-radius:12px;font-size:12px;border:1px solid #D5D5DA;color:#646469;line-height:18px;letter-spacing:0;}
  .valueList > div.error{border-color:#F66;color:#FF0000;}
  .valueList > div .name{color:#333;}
  .valueList > div .bar{display:inline-block;width:1px;height:8px;margin:0 6px;background:#D5D5DA;vertical-align:middle;}
  .valueList > div button{display:inline-block;width:20px;height:20px;margin-left:2px;vertical-align:middle;}
  .valueList > div img{display:inline-block;width:10px;}
`

export default MultiInputBox