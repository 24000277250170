import { forwardRef } from "react"
import styled from 'styled-components'

export enum TAB_SHAPE { //문서화 챙기기
  LARGE = 'normal',
  MEDIUM = 'medium',
  LINE = 'line'
}

interface ITabProps {
  children:any
  shape?:TAB_SHAPE
}

const defaultProps = {
  shape: TAB_SHAPE.LARGE
}

const Tab  = forwardRef<any, ITabProps>((props:ITabProps, ref) => {
  let tabClass = props.shape === TAB_SHAPE.MEDIUM ? props.shape : (props.shape || TAB_SHAPE.LARGE)

  return (
    <TabFragment className={tabClass} ref={ref}>
      {props.children}
    </TabFragment>
  )

})

Tab.defaultProps = defaultProps

const TabFragment = styled.div`
display:flex;
button { color:#adadad!important; }
button::after { content:"·"; margin: 0 16px; color:#d6d6d6; }
button:last-child::after { content: ""; margin: 0; }
span { font-size:14px; line-height:normal; margin-left:4px; }
&.normal { height:24px; font-weight:400 !important; line-height:normal; }
&.normal button { font-size:18px; }
&.medium { height:19px; font-weight:400 !important; font-size:16px; line-height:normal; }
&.medium button { font-size:16px; }
&.line { height:48px; }
&.line button { padding: 0px 24px; font-size:16px; border-radius: 6px 6px 0px 0px; border-bottom:1px solid  #646469; }
&.line .on { font-weight:600; color: #333!important; border-top: 1px solid #646469; border-right: 1px solid #646469; border-left: 1px solid #646469; border-bottom:none; }
&.line button::after { content:""; margin:0; }
&.line button:last-child::after { content: ""; margin: 0; }
.on { font-weight:600; color: #333!important; }
.on span { color:#4B82FF; }
`

export default Tab