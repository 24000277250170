import ModalEvent from "../events/ModalEvent"
import PageEvent from "../events/PageEvent"
import SchedulerEvent from "../events/SchedulerEvent"
import WebSocketEvent from "../events/WebSocketEvent"
import { authState } from "../states/authStates"

export default class WebSocketController extends EventTarget {
  private static instance:WebSocketController

  public static getInstance() {
    if (!WebSocketController.instance) {
      WebSocketController.instance = new WebSocketController()
    }
    return WebSocketController.instance
  }

  private constructor() {
    super()
  }

  public state = {
    authInfo: null
  }

  public async componentDidMount() {
    // const authInfo = await promiseGetRecoil(authState)
  }

  async open (url:string) {
    const ws = new WebSocket(url)
    ws.onopen = () => {
      console.log("ws connected")
    }
    ws.onclose = () => {
      console.log("ws disconneted")
      const wsDisconnectedEvent = new WebSocketEvent(WebSocketEvent.WS_DISCONNECTED)
      this.dispatchEvent(wsDisconnectedEvent)
    }
    ws.onmessage = (e:MessageEvent) => {
      if (e.data) {
        const message = JSON.parse(e.data)
        let wsToastEvent = new WebSocketEvent(WebSocketEvent.WS_TOAST)
        let wsConfirmEvent = new WebSocketEvent(WebSocketEvent.WS_CONFIRM)
        let wsConfirmCustomEvent = new WebSocketEvent(WebSocketEvent.WS_CONFIRM_CUSTOM)
        let wsEvent = new WebSocketEvent(WebSocketEvent.WS_EVENT)
        if (message.msg) {
          switch(message.status) {
            case 'SCHEDULER': //DEV
              if (message.is_stop) {
                window.dispatchEvent(new SchedulerEvent(SchedulerEvent.STOP))
              } else {
                window.dispatchEvent(new SchedulerEvent(SchedulerEvent.START))
              }
              break
            case 'MIG': //DEV
              wsConfirmEvent.payload = { message: message.msg }
              this.dispatchEvent(wsConfirmEvent)
              let pageEvent = new PageEvent(PageEvent.REFRESH)
              pageEvent.payload = { nodeName: message.name }
              window.dispatchEvent(pageEvent)
              break
            case 'WS': //DEV
              window.dispatchEvent(new Event('wscreated'))
              wsToastEvent.payload = { message: message.msg }
              this.dispatchEvent(wsToastEvent)
              break
            case 'VOLUME_MOUNT': //DEV
              window.dispatchEvent(new Event('volumemount'))
              wsEvent.payload = {action: 'volumemount', key: 'volumemount', rgroupName: message.rgroup_name }
              this.dispatchEvent(wsEvent)
              wsToastEvent.payload = { message: message.msg }
              this.dispatchEvent(wsToastEvent)
              break
            case 'NEW': //DEVOPS 신규 알림 개수 갱신
              window.dispatchEvent(new Event('refreshalarm'))
              window.dispatchEvent(new Event('refreshalarmlist'))
              break
            case 'DELETE': //DEV
              wsConfirmCustomEvent.payload = { message: message.msg, action: 'logoutdeleteuser', key: 'logoutdeleteuser' }
              this.dispatchEvent(wsConfirmCustomEvent)
              break
            case 'MULTIPLE_ACCESS_RESTRICTION': //DEV
              wsEvent.payload = { message: message.msg, action: 'multipleaccessrestriction', key: 'multipleaccessrestriction' }
              this.dispatchEvent(wsEvent)
              break
            case 'OPS': //OPS
              wsEvent.payload = { action: 'servicereload', key: 'servicereload', id: message.ops_id }
              this.dispatchEvent(wsEvent)
              break
            case 'VERSION_LIST': //OPS
              wsEvent.payload = { action: 'versionlistreload', key: 'versionlistreload', id: message.ops_id }
              this.dispatchEvent(wsEvent)
              break
            case 'RESOURCE_LIMIT_UPDATED':
              wsEvent.payload = { message: message.msg, action: 'resourceLimitreload', key: 'resourceLimitreload'}
              this.dispatchEvent(wsEvent)
              break
            case 'CONFIRM': //DEVOPS
              wsConfirmEvent.payload = { message: message.msg }
              this.dispatchEvent(wsConfirmEvent)
              break
            case 'TOAST': //DEVOPS
            default:
              wsToastEvent.payload = { message: message.msg }
              this.dispatchEvent(wsToastEvent)
              break
          }
        }
      }
    }
    ws.onerror = (e:ErrorEvent) => {
      console.log("ws error", e)
    }
  }
}