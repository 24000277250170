import { useRef, useState } from 'react'
import styled from 'styled-components'
// import Utils from '../../../utils'

interface IInputBoxProps {
  id:string
  placeholder?:string
  type:TEXT_INPUT_TYPE
  warning?:string
  default?:string
  readonly?:boolean
  onChange?:Function
  error?:boolean
  maxLength?:number
  width?:string
  value?:string|number
  idx?:number
  cancel?:boolean
  cancelSelect?:Function
}

interface IInputBoxState {
  value:string
}

export enum TEXT_INPUT_TYPE {
  TEXT = 'text',
  TEL = 'tel',
  PASSWORD = 'password'
}

const InputBox = (props:IInputBoxProps) => {
  const [ state, setState ] = useState<IInputBoxState>({
    value: props.default || ''
  })

  const inputRef = useRef<any>()

  return (
    <InputBoxFragment style={{ width : props.width ? props.width : '100%' }} >
      <input className={(props.warning ? 'input error ' : 'input ') + (props.readonly ? 'disable ' : '') + (props.maxLength ? 'inputMaxLength' : '')} //length인지 byte단위인지
        type={props.type} id={props.id} placeholder={props.placeholder} maxLength={props.maxLength}
        style={{ padding: props.cancelSelect ? '0 25px 0 15px' : '' }}
        readOnly={props.readonly} value={props.value !== undefined && props.value !== null ? props.value : state.value} onChange={(e) => {
          setState({
            ...state,
            value: e.target.value
          })
          if (props.onChange) {
            if (props.idx) {
              props.onChange(props.id, e.target.value, props.idx)
            } else {
              props.onChange(props.id, e.target.value)
            }
          }
        }}
        ref={inputRef}
        onKeyUp={(e) => {
          if(e.key === 'Enter') {
            inputRef.current.blur()
          }
        }}
         />
      {props.maxLength ? <div className="stringLengthBox"><p>{props.value !== undefined && props.value !== null ? String(props.value).length : state.value.length}/{props.maxLength}</p></div> : false }
      {props.value && props.cancelSelect ? <div className="cancelIcon"><div onClick={(e)=>{if(props.cancelSelect)props.cancelSelect()}}><img src='/images/ico-close.png' alt='삭제' /></div></div> : false }
      {props.warning ? <p className="message">{props.warning}</p> : false}
    </InputBoxFragment>
  )
}

const InputBoxFragment = styled.div`
  width:100%;
  .inputMaxLength{padding-right:60px;}
  .stringLengthBox{position:relative;}
  .stringLengthBox p {font-size:14px; position:absolute; bottom:10px; right:0px; width:60px; text-align:right; padding-right:15px;}
  .cancelIcon{position:relative;}
  .cancelIcon div {position:absolute; bottom:10px; right:10px; cursor:pointer;}
  .cancelIcon img {width:10px;}
`

export default InputBox