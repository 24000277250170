import React,{ Component } from 'react'
import styled from 'styled-components'
import ToastEvent from '../../../events/ToastEvent'

interface IToastProps {}
interface IToastState {
  toast:boolean
  message:string
}

export default class Toast extends Component<IToastProps, IToastState> {
  private timeoutId:NodeJS.Timeout

  public state:IToastState = {
    toast: false,
    message: ''
  }

  constructor(props:any) {
    super(props)

    this.timeoutId = setTimeout(() => {})
    this.openToastHandler = this.openToastHandler.bind(this)
    this.closeToastHandler = this.closeToastHandler.bind(this)
  }
  
  public componentDidMount():void {
    window.addEventListener(ToastEvent.OPEN_TOAST, this.openToastHandler)
    window.addEventListener(ToastEvent.CLOSE_TOAST, this.closeToastHandler)
  }

  public componentWillUnmount():void {
    window.removeEventListener(ToastEvent.OPEN_TOAST, this.openToastHandler)
    window.removeEventListener(ToastEvent.CLOSE_TOAST, this.closeToastHandler)
  }
  
  private openToastHandler(e:ToastEvent):void {
    clearTimeout(this.timeoutId)
    this.setState({
      toast: false
    }, () => {
      this.setState({
        toast: true,
        message: e.payload.message
      })
      this.timeoutId = setTimeout(() => {
        this.closeToastHandler()
      }, 3000)
    })
  }

  private closeToastHandler():void {
    this.setState({
      toast: false
    })
  }

  public render():React.ReactElement {
    return (
      <ToastFragment>
      {
        this.state.toast ?
          <div className="toast" onClick={this.closeToastHandler}>
            {this.state.message}
          </div>
        : false
      }
      </ToastFragment>
    )
  }
}

const ToastFragment = styled.div`
  .toast{position: fixed;top:68px;right:30px;padding:30px;background:rgba(78,78,78,0.8);box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);color:#fff;font-size:14px;text-align:center;z-index:1000;word-break:break-all;width:300px;} //width300+padding30+30=360px
`