import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ISelectOption from '../../../interfaces/SelectOption'
import CheckBox from './CheckBox'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../../Config"

interface ISelectMultiProps {
  selectRef?:any //React.ForwardedRef<JSX.Element>
  option:ISelectOption[]
  selected?:ISelectOption[]
  onChange?:Function
  placeholder:string
  nodeFlag?:boolean
}

const SelectMulti = (props:ISelectMultiProps) => {
  const [ dropFlag, _setDropFlag ] = useState<boolean>(false)
  const dropFlagRef = React.useRef(dropFlag)
  const setDropFlag = (value:boolean) => {
    dropFlagRef.current = value
    _setDropFlag(value)
  }
  useEffect(() => {
    if (dropFlag === true) { 
      document.addEventListener('mousedown', clickOutsideHandler)
    } else {
      // 창이 닫힐 때, 변경사항 반영
      if (props.onChange) props.onChange(selected)
      getSelectedList()
    }
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [dropFlag])

  const [ selected, _setSelected ] = useState<(string|number)[]>([])
  const selectedRef = useRef(selected)
  const setSelected = (data:any) => {
    selectedRef.current = data
    _setSelected(data)
  }
  const [ selectedList, setSelectedList ] = useState<string>('')

  useEffect(() => {
    let result = []
    for(let idx in props.selected) {
      result.push(Number(props.selected[Number(idx)].value))
    }
    setSelected(result)
  }, [props.selected])

  useEffect(() => { 
    if(selected.length === 0) { // 타이틀 초기화 (어드민 ws목록 1차 필터 변경 시 2차 필터 타이틀 초기화)
      getSelectedList()
    }
    else { // 어드민 ws 목록 노드 초기화 시에만 작동
      if (props.nodeFlag === true) {
        if (props.onChange) props.onChange(selected)
        getSelectedList()  
      }
    }
  }, [selected])

  const selectRef = useRef<ISelectOption>()

  const clickOutsideHandler = (event:any) => {
    let ref = props.selectRef ? props.selectRef : selectRef
    if (ref.current?.contains(event.target) === false && dropFlagRef.current === true) {
      setDropFlag(false)
    }
  }

  const selectHandler = (option:ISelectOption, id:string, checked:boolean) => {
    let currentSelected = selected.concat()
    let idx = Number(id.split('_').pop())
    if (currentSelected.indexOf(idx) === -1 && checked === true) {
      //추가
      currentSelected.push(idx)
      currentSelected.sort()
    } else {
      //제거
      currentSelected = currentSelected.filter((each:any) => each !== idx)
    }
    setSelected(currentSelected.sort((a:number, b:number) => a - b))
  }

  const getSelectedList = () => {
    let result = ''
    const items = selectedRef.current.concat()
    let i = 0
    for (let idx in props.option) {
      let index = items.indexOf(Number(props.option[idx].value))
      if (index !== -1) {
        result += props.option[idx].fakeLabel ? props.option[idx].fakeLabel : props.option[idx].label
        if (i < items.length - 1) {
          result += ', '
        }
        i++
      }
    }    
    setSelectedList(result)
  }

  return(
    <SelectMultiFragment ref={props.selectRef ? props.selectRef : selectRef}>
      <div className={ "selectP" } onClick={(): void => { 
          setDropFlag(!dropFlag) 
        }} >
        {/* 여기에 선택된 애들 표기 */}
        <EllipsisToolTip options={Config.ellipsisTipOptions}>{selectedList ? selectedList : props.placeholder}</EllipsisToolTip>
        <span className="ico" />
      </div>
      {
        dropFlag ?
          <div className="dropWrap">
            {
              props.option.map((eachProps:ISelectOption) => {
                return (
                <div key={eachProps.value} className={selected.indexOf(Number(eachProps.value)) !== -1 ? 'selected' : ''}>
                  <CheckBox id={"select_"+eachProps.value} checked={selected.indexOf(Number(eachProps.value)) !== -1} 
                    onChange={(id, checked) => {selectHandler(eachProps, id, checked)}}/>
                  <div style={{minWidth:'100px'}}>
                    <EllipsisToolTip options={Config.ellipsisTipOptions}>{ eachProps.fakeLabel ? eachProps.fakeLabel : eachProps.label}</EllipsisToolTip>
                  </div>
                </div>
                )
              })
            }
          </div> : false
      }
    </SelectMultiFragment>
  )
}

const SelectMultiFragment = styled.div`
  position: relative;
  .selectP { display:flex; align-items:center; justify-content:space-between; height:35px; padding:0 15px; border:1px solid #ccc; background:#fff; font-size: 14px; color:#303030; line-height: 35px; cursor:pointer; border-radius:2px; }
  .selectP .ico { width:24px; height:24px; margin-left:5px; background:url(/images/angle-down.png) no-repeat center / 100%; vertical-align:middle; }
  .selectP.blocked { border-color:#ccc; background-color:#f5f5f5; color:#303030; pointer-events:none }
  .dropWrap { overflow:auto; position:absolute; left:0; right:0; max-height:168px; border:1px solid #303030; background:#fff; z-index:101; border-radius:2px; }
  .dropWrap > div { display:flex; /* width:100%; */ padding:8px 16px 8px 0px; font-size:14px; text-align:left; letter-spacing:-0.5px; }
  .dropWrap > div + div { border-top:1px solid #f5f5f5; }
  .dropWrap > div:hover { background:#fafafa; }
  .dropWrap > div.selected { background-color:#e1f5ff; }  
`

export default SelectMulti