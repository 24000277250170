import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SUBPOPUP_TYPE } from '../../modal/sub/SubPopup'
import { SUB_BUTTON_SIZE } from './SubPopupTable'

interface ISubPopupListProps {
  title?:string
  datas:any[]
  isTableBtn?:boolean
  closeBtn?:boolean
  numbering?:boolean

  width?:number
  padding?:string
  type?:SUBPOPUP_TYPE

  colAlignCenter?:boolean
  onRight?:boolean
}
interface ISubPopupListState {
  isOpen:boolean,
}

const defaultProps = {
  buttonSize: SUB_BUTTON_SIZE.NORMAL,
}

const SubPopupList = (props:ISubPopupListProps) => {
  const [ state, setState ] = useState<ISubPopupListState>({
    isOpen: false,
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen:!state.isOpen })
  }

  return (
    <SubPopupListFragment>
      <div className={props.isTableBtn ? "tableWrap" : "normalWrap"}>
        <button onClick={onBtnClick} ref={modalBtnRef}>
          {state.isOpen && state.isOpen === true ?
            <img src='/images-v2/table-more-small.svg' alt='more' className='moreIconButton' />
            : <img src='/images-v2/table-more-small.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      {state.isOpen && state.isOpen === true ?
        <div className="subModalPopupWrap" ref={modalRef}>
          <div className={"commonModal" + (props.onRight === true? " onRight" : " onLeft")} style={{ 'width': props.width ? props.width : '330px', 'padding': props.padding ? props.padding : '12px 16px' }}>
            {props.title ?
              <div className='titleWrap'>
                <div style={{ padding: '3.5px 0' }}>{props.title}</div>
                {props.closeBtn ? <div className='closeBtn' onClick={closeHandler}><img src="/images-v2/popup-exit-black.svg" alt="exit" /></div> : false}
              </div> : false}
            <div className="scrollWrap">
              <table>
                <colgroup>
                  <col width="26px"></col>
                </colgroup>
                <tbody>
                  {
                    props.datas && props.datas.map((data:any, idx:number) => (
                      <tr key={idx}>
                        {props.numbering === false ? false : <td className="textAlignCenter numburing">{idx + 1}</td>}
                        {(() => {
                          switch (props.type) {
                            case SUBPOPUP_TYPE.ENVIRONMENT:
                              return (<td className="textAlignStart data">{data.key}={data.value}</td>)
                            case SUBPOPUP_TYPE.NODE:
                              return (<td className="textAlignStart data">{data.ip} / {data.name}</td>)
                            case SUBPOPUP_TYPE.VOLUME: //사용x
                              return (<td className="textAlignStart data">{data.name}</td>)
                            case SUBPOPUP_TYPE.VOLUME_OPS:
                              return (<td className="textAlignStart data">{data.name} / {data.size}</td>)
                            case SUBPOPUP_TYPE.NORMAL:
                            default:
                              return (<td className={(props.colAlignCenter === true ? 'textAlignCenter' : 'textAlignStart')+' data'}>{data}</td>)
                          }
                        })()}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        : false}
    </SubPopupListFragment>
  )
}

SubPopupList.defaultProps = defaultProps

const SubPopupListFragment = styled.div`
  .tableWrap {height:100%; display:flex; flex-direction:row; justify-content:center; align-items:center;}
  .normalWrap {}
  button.normal {width:16px; height:16px; display:block;}
  button.small {width:14px; height:14px; display:block;}
  button.norma img.moreIconButton {width:16px !important; height:16px; padding-top:0px; display:block;}
  button.small img.moreIconButton {width:14px !important; height:14px; padding-top:0px; display:block;}

  .subModalPopupWrap {position:relative; align-items:center; z-index:201;}
  .subModalPopupWrap .commonModal{position:absolute; top:6px; max-height:234px; flex-direction:column;box-sizing:border-box;padding:12px 16px;border-radius:6px;box-shadow:0 12px 16px 0 #1B1D1F0D, 0 6px 12px 0 #1B1D1F0D;border:solid 1px #DFDFDF;background:#fff;}
  .subModalPopupWrap .onLeft{right:0px;}
  .subModalPopupWrap .onRight{left:0px;}
  .subModalPopupWrap .commonModal .titleWrap{ height:24px; display:flex; justify-content:space-between; align-items:center; font-size:14px; line-height:16.71px; font-weight:700; margin-bottom:12px;}
  .subModalPopupWrap .commonModal .titleWrap *{display:block;}
  .subModalPopupWrap .commonModal .titleWrap .closeBtn{cursor:pointer;}
  .subModalPopupWrap .commonModal p{font-size:14px;line-height:24px; vertical-align:middle;}
  .subModalPopupWrap .commonModal .modalTitle + .modalTitle{margin-top:10px;}
  .subModalPopupWrap .commonModal .modalTitle{font-weight:bold;}
  .subModalPopupWrap .commonModal .modalTitle .content{font-weight:normal;}
  .scrollWrap{max-height:164px; overflow-y:auto;}

  .subModalPopupWrap .commonModal table{width:100%; border-collapse:collapse; border-top:1px solid #ebebeb; color:#333; font-size:13px; line-height:15.6px; font-weight:400;}
  .subModalPopupWrap .commonModal table tbody td{padding:8px 12px;background: #fff;border-bottom: 1px solid #ebebeb;word-break:break-all;white-space:pre-line;}
  .subModalPopupWrap .commonModal table tbody td{text-align:left;}
  .subModalPopupWrap .commonModal table tbody td.numburing{padding:8px 8px 8px 12px;}
  .subModalPopupWrap .commonModal table tbody td.data{padding-left:0px;}
`
  
export default SubPopupList
