import { useEffect, useState, useRef } from "react"
import styled from 'styled-components'

interface ITooltipProps {
  des:string
  width?:number
  img?:string
  way?:string
  off?:boolean
}

interface ITooltipState {
  visible:boolean
}

const Tooltip = (props:ITooltipProps) => {
  const [state, setState] = useState<ITooltipState>({
    visible:false
  })

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)

    return() => {
      document.removeEventListener('mousedonw', clickOutsideHandler)
    }
  },[state.visible])

  const tooltipRef = useRef<any>()

  const clickOutsideHandler = (event:any) => {
    if(!tooltipRef.current?.contains(event.target) && state.visible) {
      setState({
        visible:false
      })
    }
  }

  return (
    <TooltipFragment>
      {
        props.off && props.off === true ? <img style={{ width: props.width }} src={`/images/${props.img ? props.img : 'frame-off.png'}`} alt="" /> :
          <>
            <button className="btnTooltip" style={{ width: props.width }} ref={tooltipRef} onClick={(e) => {
              setState({ ...state, 
              visible: !state.visible }) }}>
              <img style={{ width: props.width }} src={`/images/${props.img ? props.img : 'frame.png'}`} alt="" />
            </button>
            { state.visible ? <span className={props.way ? `des ${props.way}` : 'des'} dangerouslySetInnerHTML={{ __html: props.des.replace(/\n/g, '<br/>') }}></span> : false}
          </>
      }
    </TooltipFragment>
  )

}

const TooltipFragment = styled.div`
  position:relative; display:inline-block; vertical-align:middle;
  .btnTooltip { width:20px; }
  .btnTooltip img { display:block; width:100%; }
  .des { position:absolute; bottom:18px; left:30px; padding:10px; background:#fff; color:#000; font-size:12px; white-space:nowrap; border:1px solid #dfdfdf; box-shadow:4px 4px 4px 0 rgba(0, 0, 0, 0.2); z-index:200; }
  .des:after, .des:before { content:''; position:absolute; width:0;height:0; }
  .des:after { left:-10px; bottom:-1px; border-top:7px solid transparent; border-right:10px solid #dfdfdf; z-index:0; }
  .des:before { left:-8px; bottom:0px; border-top:5px solid transparent; border-right:8px solid #fff; z-index:200; }
  .des.left { left:auto; right:30px; text-align:left; }
  .des.left:after { left:auto; right:-11px; border-right:none; border-left:10px solid #dfdfdf; }
  .des.left:before { left:auto; right:-8px; border-right:none; border-left:8px solid #fff; }
`

export default Tooltip