import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/ui/Table'
import { Image, IImageListItem, IMAGE_COMMIT_STATUS } from '../../model/Image'
import { IMAGE_TYPE, SORT_ORDER } from '../../model/BaseDataType'
import TableEvent from '../../events/TableEvent'
import ModalEvent from '../../events/ModalEvent'
import { ERROR_TYPE } from '../../interfaces/Error'
import PopupController from '../../controller/PopupController'
import Pagenation from '../components/ui/Pagenation'
import Table from '../components/ui/Table'
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'
import { ResourceGroup } from '../../model/ResourceGroup'
import ISelectOption from '../../interfaces/SelectOption'
import Select from '../components/ui/Select'
import utils from '../../utils'
import ToastEvent from '../../events/ToastEvent'

interface IImageListProps {
}

interface IImageListState {
  page:number
  reg:string //레지스트리

  //개수
  totalArticle:number
  commonTotal:number
  userTotal:number

  tableItems:IImageListItem[]
  deleteItems:IImageListItem[]
  selected:IImageListItem[]
}

const ImageList = (props:IImageListProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const popupController = PopupController.getInstance()
  const params = useParams()
  const selectRGroupRef = useRef<any>()

  const tableRef = useRef<any>()
  const tableColWidth = [27, null, 412, 412];
  const headerLabel:ITableHeader[] = [
    {
      label: '이미지 이름', //개념적 이해를 위해 이미지 이름으로 레이블만 수정 07/19
      key: 'rep',
      type: TABLE_CELL_TYPE.STRING,
      sort: SORT_ORDER.ASC,
      styleKey: 'style',
      orderFlag: true
    }, {
      label: '태그',
      key: 'tag',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }, {
      label: 'Image ID',
      key: 'id',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }
  ]

  const [ emptyText, setEmptyText ] = useState<string>('등록된 이미지가 없습니다.')
  const [ rgroupList, setRGroupList ] = useState<ISelectOption[]>([])
  const [ resourceGroup, _setRgroup ] = useState<ISelectOption>()
  const rgroupRef = useRef(resourceGroup)
  const setRgroup = (data:any) => {
    rgroupRef.current = data
    _setRgroup(data)
  }
  const [ tab, _setTab ] = useState<IMAGE_TYPE>(IMAGE_TYPE.COMMON)
  const tabRef = useRef(tab)
  const setTab = (data:any) => {
    tabRef.current = data
    _setTab(data)
  }

  const [ state, _setState ] = useState<IImageListState>({
    page: (params.page ? Number(params.page) : 1),
    reg: '',

    //개수
    totalArticle: 0,
    commonTotal: 0,
    userTotal: 0,

    tableItems: [],
    deleteItems: [],
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }
  const prevRgroup = utils.usePrevState(resourceGroup)
  const prevTab = utils.usePrevState(tab)

  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    // tab이 변경되었을 때...
    if(prevTab !== tab){
      if(tab !== IMAGE_TYPE.RGROUP){ //리소스 그룹 이미지 탭일때는 리소스 그룹 로딩이 다 끝나고나서 되어야함
        getData()
        setRGroupList([])
        setRgroup(undefined)
      }
    }
  }, [tab])

  useEffect(() => {
    // 리소스 그룹 이미지 탭일 때
    if(rgroupList.length === 0) {
      // 소속된 리소스 그룹이 없을 때
      setState({
        ...state,
        reg: '',
        tableItems: []
      })
      setEmptyText('소속된 리소스 그룹이 없어 리소스 그룹의 이미지 확인이 불가합니다.')

    } else if (prevRgroup?.label !== resourceGroup?.label && resourceGroup?.label !== '') {
      // 소속된 리소스 그룹이 있고 리소스 그룹 selectOption이 정상적일 때 
      // 1. 첫 번째 로딩(탭 바뀔때마다 초기화해서 첫번째 로딩이 됨)
      // 2. 리소스 그룹 변경 시
      getData(IMAGE_TYPE.RGROUP, 1) //리소스 그룹 변경 시 페이지 1로 초기화
    }
  }, [resourceGroup])

  const eventControl = (e:Event) => {
    getData()
  }

  const tabHandler = (target:IMAGE_TYPE = IMAGE_TYPE.COMMON) => {
    if(target === IMAGE_TYPE.RGROUP) {
      getRgroupList()
    }
    setTab(target)
  }

  const getData = async (_tab?:IMAGE_TYPE, page?:number, rgroup?:ISelectOption) => {
    try {
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      let namespace = ''
      const selectedTab = _tab ? _tab : tabRef.current
      const selectedRgroup = rgroup ? rgroup : rgroupRef.current
      switch (selectedTab) {
        case IMAGE_TYPE.RGROUP:
          if (selectedRgroup?.label === '') {
            //빈 값이면 api 호출 시 에러나는 것 방지 (이유를 모르겠는데 리소스 그룹 리스트 세팅 전에 getData가 호출됨)
            return
          }
          namespace = selectedRgroup?.label || ''
          break
        case IMAGE_TYPE.USER:
          namespace = authInfo?.id
          break
        case IMAGE_TYPE.COMMON:
        default:
          namespace = ''
      }
      if(selectedTab === IMAGE_TYPE.RGROUP && namespace === '') return
      const data = await Image.getList(namespace, page ? page : stateRef.current.page, 50, sortOrder, selectedTab)
      if (data) {
        for (let idx in data.imageList) {
          let eachItem = data.imageList[idx]
          if(eachItem.commitInfo === null) {
            eachItem.tableCommitFlag = false
          } else {
            eachItem.tableCommitFlag = true
            if(eachItem.commitInfo.status !== undefined) {
              switch(eachItem.commitInfo.status) {
                case IMAGE_COMMIT_STATUS.PROGRESS:
                case IMAGE_COMMIT_STATUS.PENDING:
                  eachItem.id = '커밋 중'
                  break
                case IMAGE_COMMIT_STATUS.FAILED:
                  eachItem.id = '커밋 실패'
                  break
              }
            }
          }
        }
        setState({
          ...state,
          totalArticle: data.total,
          commonTotal: data.commonTotal,
          userTotal: data.userTotal,
          reg: data.reg === null ? '' : data.reg,
          tableItems: data.imageList,
          page: page ? page : stateRef.current.page
        })
        if(selectedTab !== tabRef.current) {
          setTab(selectedTab)
        }
        if(data.reg === null) {
          setEmptyText('리소스 그룹의 이미지 레지스트리가 없어 이미지 확인이 불가합니다. \n관리자에게 문의해 주세요.')
        } else {
          setEmptyText('등록된 이미지가 없습니다.')
        }
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - e9c642')
      }
    }
  }

  const getRgroupList = async () => {
    try {
      const response = await ResourceGroup.getRgroupList(authInfo.userNo)
      let rgroupSelectList:ISelectOption[] = []
      for(let key in response.rgroup) {
        if(response.rgroup[key].isCommon) { continue } //기본 리소스 그룹은 제외
        rgroupSelectList.push({
          label: response.rgroup[key].name,
          value: key
        })
      }
      setRGroupList(rgroupSelectList) //리소스 그룹 리스트 세팅
      setRgroup(rgroupSelectList.length > 0 ? rgroupSelectList[0] : undefined) //기본으로 첫 번째꺼 세팅
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - e16df0')
      }
    }
  }

  const onChangeSelectRgroup = (rgroup: ISelectOption) => {
    setRgroup(rgroup)
  }

  const navigateToPage = (page:number):void => {
    if(page !== stateRef.current.page) {
      getData(tabRef.current, page)
    }
  }

  const removeImageHandler = async ():Promise<void> => {
    try{
      let targetIdArray:any[] = []
      const items = state.tableItems
      const selectedIdx = tableRef.current?.selected
      if (items && selectedIdx && selectedIdx.length > 0) {
        for (let eachIdx of selectedIdx) {
          targetIdArray.push({
            rep: items[Number(eachIdx)].rep,
            tag: items[Number(eachIdx)].tag
          })
        }
        let message = ''
        if (tab === IMAGE_TYPE.RGROUP) {
          if (targetIdArray.length > 1) {
            message = `${resourceGroup?.label} 리소스 그룹의 ${targetIdArray[0].rep}:${targetIdArray[0].tag} 외 ${targetIdArray.length - 1} 개 이미지가 삭제됩니다. 진행하시겠습니까?`
          }
          else {
            message = `${resourceGroup?.label} 리소스 그룹의 ${targetIdArray[0].rep}:${targetIdArray[0].tag} 이미지가 삭제됩니다. 진행하시겠습니까?`
          }  
        } 
        else if(tab === IMAGE_TYPE.USER) {
          if (targetIdArray.length > 1) {
            message = `${targetIdArray[0].rep}:${targetIdArray[0].tag} 외 ${targetIdArray.length - 1} 개 이미지가 삭제됩니다. 진행하시겠습니까?`
          }
          else {
            message = `${targetIdArray[0].rep}:${targetIdArray[0].tag} 이미지가 삭제됩니다. 진행하시겠습니까?`
          }  
        }

        popupController.dialouge(message, 'deleteImage', targetIdArray, '확인', '취소')
        setState({
          ...state,
          deleteItems: targetIdArray
        })
      } else {
        popupController.confirm(`선택된 항목이 없습니다.`)
      }
    } catch(error){
      popupController.confirm(`에러가 발생했습니다.`)
    }
    
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteImage':
        try {
          const deleteItems = e.payload.key
          const name = tabRef.current === IMAGE_TYPE.RGROUP ? rgroupRef.current?.label : authInfo.id
          await Image.deleteImages(deleteItems, name || '', tabRef.current)
          
          const items = stateRef.current.tableItems
          const selectedIdx = tableRef.current?.selected
          let message: string = ''
          const resourceGroup = rgroupRef.current

          if (items && selectedIdx && selectedIdx.length > 0) {
            if (tabRef.current === IMAGE_TYPE.RGROUP) {
              if (selectedIdx.length === 1) {
                message = `${resourceGroup?.label} 리소스 그룹의 ${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 이미지가 삭제되었습니다.`
              } else {
                message = `${resourceGroup?.label} 리소스 그룹의 ${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 외 ${selectedIdx.length - 1}개의 이미지가 삭제되었습니다.`
              }
            }
            else if (tabRef.current === IMAGE_TYPE.USER) {
              if (selectedIdx.length === 1) {
                message = `${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 이미지가 삭제되었습니다.`
              } else {
                message = `${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 외 ${selectedIdx.length - 1}개의 이미지가 삭제되었습니다.`
              }  
            }
            let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
            toastEvent.payload = { message: message }
            window.dispatchEvent(toastEvent)
          }

          // 삭제 후 갱신
          tableRef.current?.setSelected([])
          getData()
        } catch(error){
          popupController.confirm('에러가 발생했습니다.\n에러코드 - c75b18')
        }
        break
    }
  }

  const selectboxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0){
      let selectedArray:IImageListItem[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedArray[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  return(
    <ImageListFragment>
      <div className="titleArea">
        <div className="tabWrap">
          <div className="tabBox">
            <button className={tab === IMAGE_TYPE.COMMON ? 'pageTitle on' : 'pageTitle'}
              onClick={() => { tabHandler(IMAGE_TYPE.COMMON) }}>공용 이미지 <span className="total"><b>{state.commonTotal}</b> 개</span></button>
          </div>
          <div className="tabBox">
            <button className={tab === IMAGE_TYPE.RGROUP ? 'pageTitle on' : 'pageTitle'}
              onClick={() => { tabHandler(IMAGE_TYPE.RGROUP) }}>리소스 그룹 이미지</button>
          </div>
          <div className="tabBox">
            <button className={tab === IMAGE_TYPE.USER ? 'pageTitle on' : 'pageTitle'}
              onClick={() => { tabHandler(IMAGE_TYPE.USER) }}>사용자 정의 이미지  <span className="total"><b>{state.userTotal}</b> 개</span></button>
          </div>
        </div>
      </div>
      <div className="bothEnd" style={{ marginBottom: '30px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {tab === IMAGE_TYPE.RGROUP ?
            <div style={{ width: '478px', marginRight: '20px' }}>
              <Select selectRef={selectRGroupRef} option={rgroupList} selected={resourceGroup} disabled={rgroupList.length === 0} placeholder={rgroupList.length === 0 ? '소속된 리소스 그룹이 없습니다.' : undefined}
                onChange={onChangeSelectRgroup} />
            </div>
            : false}
          {tab !== IMAGE_TYPE.COMMON ? <p style={{ fontSize: '14px', fontWeight: '700' }}>이미지 레지스트리: {state.reg ? state.reg : '-'}</p> : false}
        </div>
        <button className={"btn outline " + (tableRef.current?.selected.length === 0 || tab === IMAGE_TYPE.COMMON ? 'off' : '')} onClick={removeImageHandler}>선택 항목 삭제</button>
      </div>
      <Table ref={tableRef}
        colWidth={tableColWidth}
        headerLabel={headerLabel}
        items={state.tableItems}
        noData={emptyText}
        orderPrefix={(state.page - 1) * 50}
        checkBoxPreserveKeys={['isDefault', 'tableCommitFlag']}
        onUpdate={onUpdateStatus}
        onSelect={selectboxCheckHandler}
        checkBoxType={tab === IMAGE_TYPE.COMMON ? CHECKBOX_TYPE.DISABLE : CHECKBOX_TYPE.USE_ALL} />
      {state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage: number) => { navigateToPage(newPage) }} /> : false
      }
    </ImageListFragment>
  )
}

const ImageListFragment = styled.div`
  //탭
  .tabWrap{display:flex;}
  .tabWrap button{color:#ccc;}
  .tabWrap button.on{color:#303030;}
  .tabBox{display:flex; align-items:center;}
  .tabBox + .tabBox:before{content:'';display:inline-block;width:2px;height:26px;margin:0 16px;background:#ccc;vertical-align:middle;}
  .tabBox > button{margin-right:10px;}

  //테이블
  .col1 { text-align:center; }
  .col2 { text-align:center; }
  tbody tr.isDefault td { background-color:#edf4f4; }
  tbody td { height:25px; }
  tbody tr.tableCommitFlag td { color:#a7a7a7; }
`

export default ImageList