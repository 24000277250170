import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IImageListItem, IMAGE_COMMIT_STATUS, Image } from '../../model/Image'
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../components/ui/Table'
import { IMAGE_TYPE, SORT_ORDER } from '../../model/BaseDataType'
import { useRecoilState } from 'recoil'
import TableEvent from '../../events/TableEvent'
import ModalEvent from '../../events/ModalEvent'
import { ERROR_TYPE } from '../../interfaces/Error'
import PopupController from '../../controller/PopupController'
import Pagenation from '../components/ui/Pagenation'
import { authState } from '../../states/authStates'
import ToastEvent from '../../events/ToastEvent'

interface IImageListProps {
}

interface IImageListState {
  page:number
  totalArticle:number
  tableItems:IImageListItem[]
  deleteItems:IImageListItem[]
  selected:IImageListItem[]
  registry:string
}

const ImageList = (props:IImageListProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const params = useParams()
  const popupController = PopupController.getInstance()

  const tableRef = useRef<any>()
  const tableColWidth = [27, null, 412, 412];
  const headerLabel:ITableHeader[] = [
    {
      label: '이미지 이름', //개념적 이해를 위해 이미지 이름으로 레이블만 수정 07/19
      key: 'rep',
      type: TABLE_CELL_TYPE.STRING,
      sort: SORT_ORDER.ASC,
      styleKey: 'style',
      orderFlag: true
    }, {
      label: '태그',
      key: 'tag',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }, {
      label: 'Image ID',
      key: 'id',
      type: TABLE_CELL_TYPE.STRING,
      sort: false,
      styleKey: 'style'
    }
  ]
  
  const [ state, _setState ] = useState<IImageListState>({
    totalArticle: 0,
    page: (params.page ? Number(params.page) : 1),
    tableItems: [],
    deleteItems: [],
    selected: [],
    registry: ''
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)

    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  const eventControl = (e:Event) => {
    getData()
  }

  const getData = async (page?:number) => {
    try {
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : tableRef.current?.sortOrder)
      const data = await Image.getList('', page? page: stateRef.current.page, 50, sortOrder, IMAGE_TYPE.COMMON) //임시
      if (data) {
        for (let idx in data.imageList) {
          let eachItem = data.imageList[idx]
          if(eachItem.commitInfo === null) {
            eachItem.tableCommitFlag = false
          } else {
            eachItem.tableCommitFlag = true
            if(eachItem.commitInfo.status !== undefined) {
              switch(eachItem.commitInfo.status) {
                case IMAGE_COMMIT_STATUS.PROGRESS:
                case IMAGE_COMMIT_STATUS.PENDING:
                  eachItem.id = '커밋 중'
                  break
                case IMAGE_COMMIT_STATUS.FAILED:
                  eachItem.id = '커밋 실패'
                  break
              }
            }
          }
        }
        setState({
          ...state,
          page: page? page: stateRef.current.page,
          totalArticle: data.total,
          tableItems: data.imageList,
          registry: data.reg
        })
      } else {
        setState({
          ...state,
          tableItems: []
        })
      }
    } catch(e) {
      if((e as Error).message === ERROR_TYPE.ERROR){
        // 이미지 팝업 불러오기 에러남
        console.log(e)
      }
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e9c642')
    }
  }

  const navigateToPage = (page:number):void => {
    getData(page)
  }

  const removeImageHandler = async ():Promise<void> => {
    try {
      let targetIdArray:any[] = []
      const items = state.tableItems
      const selectedIdx = tableRef.current?.selected
      if (items && selectedIdx && selectedIdx.length > 0) {
        for (let eachIdx of selectedIdx) {
          targetIdArray.push({
            rep: items[Number(eachIdx)].rep,
            tag: items[Number(eachIdx)].tag
          })
        }
        let message = ''
        if(targetIdArray.length > 1){
          message = `${targetIdArray[0].rep}:${targetIdArray[0].tag} 외 ${targetIdArray.length-1} 개 이미지가 삭제됩니다. 진행하시겠습니까?`
        }
        else {
          message = `${targetIdArray[0].rep}:${targetIdArray[0].tag} 이미지가 삭제됩니다. 진행하시겠습니까?`
        }
        popupController.dialouge(message, 'deleteImage', targetIdArray, '확인', '취소')
        setState({
          ...state,
          deleteItems: targetIdArray
        })
      } else {
        popupController.confirm(`선택된 항목이 없습니다.`)
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.')
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteImage':
        try {
          const deleteItems = e.payload.key
          await Image.deleteImages(deleteItems, authInfo.id, IMAGE_TYPE.COMMON)

          const items = stateRef.current.tableItems //tableRef.current?.items
          const selectedIdx = tableRef.current?.selected
          let message:string = ''

          if (items && selectedIdx && selectedIdx.length > 0) {
            if (selectedIdx.length === 1) {
              message = `${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 이미지가 삭제되었습니다.`
            } else {
              message = `${items[Number(selectedIdx[0])].rep}:${items[Number(selectedIdx[0])].tag} 외 ${selectedIdx.length-1}개의 이미지가 삭제되었습니다.`
            }
            let toastEvent:ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
            toastEvent.payload = { message: message }
            window.dispatchEvent(toastEvent)
          }
          tableRef.current?.setSelected([])
          getData()
        } catch (error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - c75b18')
        }
        break
    }
  }
  
  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IImageListItem[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  return (
    <ImageListFragment>
      <div className="titleArea">
          <h2 className="pageTitle">이미지 관리 <span className="total"><b>{state.totalArticle}</b> 개</span></h2>
          <div className='bothEnd'>
            <div className="guideText"><img src='/images/star.png' alt='important' className="importantImg" />이미지 레지스트리&nbsp;<b>{state.registry}</b>에 CMD로 기본 이미지를 등록해 주세요. </div>
            <div className="btnWrap">
              <button className={"btn outline " + (tableRef.current?.selected.length === 0 ? 'off' : '')} onClick={removeImageHandler}>선택 항목 삭제</button>
            </div>
          </div>
        </div>
        <Table ref={tableRef} 
               colWidth={tableColWidth} 
               headerLabel={headerLabel} 
               items={state.tableItems} 
               noData="등록된 이미지가 없습니다."
               orderPrefix={(state.page-1)*50}
               checkBoxPreserveKeys={['isDefault', 'tableCommitFlag']}
               onUpdate={onUpdateStatus}
               onSelect={selectBoxCheckHandler}
               checkBoxType={CHECKBOX_TYPE.USE_ALL} />
        { state.totalArticle > 0 ?
          <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage:number) => navigateToPage(newPage)} />  : false 
        }
    </ImageListFragment>
  )
}

const ImageListFragment = styled.div`
  .col1{text-align:center;}
  .col2{text-align:center;}
  tbody td{height:25px}
  .guideText {display:flex; align-items:center; font-size:13px; line-height:16px;}
  .importantImg {width:12px; height:12px; margin-right:4px; margin-bottom:2px;}
  // tbody tr.isDefault td {background-color:#edf4f4} //디자인에서 색상 있으면 적용
  tbody tr.tableCommitFlag td { color:#a7a7a7; }
`

export default ImageList