import { useEffect, useState } from 'react'
import styled from 'styled-components'

import TableEvent from '../../../events/TableEvent'
import { ISettings, changeModeState, settingState } from '../../../states/settingState'
import SchedulerEvent from '../../../events/SchedulerEvent'
import { useRecoilState } from 'recoil'

interface IPriorityUpProps {
  objName:string
}

interface IPriorityUpState {
  schedulerFlag:boolean
  changeModeFlag:boolean
}

const PriorityUp = (props:IPriorityUpProps) => {
  const [ settings ] = useRecoilState<ISettings|null>(settingState)
  const [ changeModeFlag ] = useRecoilState<boolean>(changeModeState)

  const [ state, setState ] = useState<IPriorityUpState>({
    schedulerFlag: false,
    changeModeFlag: false
  })

  useEffect(() => {
    setState({
      schedulerFlag: settings?.schedulerFlag || false,
      changeModeFlag: changeModeFlag ||false
    })

    window.addEventListener(SchedulerEvent.UPDATED, schedulerHandler)
    window.addEventListener(SchedulerEvent.EDIT_START, schedulerHandler)
    window.addEventListener(SchedulerEvent.EDIT_END, schedulerHandler)
    window.addEventListener(TableEvent.PRIORITY_MODE_FLAG_CHANGED, changeModeFlagHandler)

    return() => {
      window.removeEventListener(SchedulerEvent.UPDATED, schedulerHandler)
      window.removeEventListener(SchedulerEvent.EDIT_START, schedulerHandler)
      window.removeEventListener(SchedulerEvent.EDIT_END, schedulerHandler)
      window.removeEventListener(TableEvent.PRIORITY_MODE_FLAG_CHANGED, changeModeFlagHandler)
    }
  }, [])

  const schedulerHandler = async (e:SchedulerEvent):Promise<void> => {
    if (e.type === SchedulerEvent.EDIT_START) {
      setState({
        ...state,
        schedulerFlag: true
      })
    } else {
      setState({
        ...state,
        schedulerFlag: settings?.schedulerFlag || false
      })
    }
  }

  const changeModeFlagHandler = (e:TableEvent) => {
    setState({
      ...state,
      changeModeFlag: e.payload.modeFlag
    })
  }

  const priorityUpHandler = async () => {
    let e:TableEvent = new TableEvent(TableEvent.ACTION)
    e.payload = {
      action: 'priorityUp',
      objName: props.objName
    }
    window.dispatchEvent(e)
  }

  return (
    <PriorityUpFragment>
      <button className={"btn "+(state.changeModeFlag ? 'outline' : 'off')} onClick={priorityUpHandler}>우선순위 올리기</button>
    </PriorityUpFragment>
  )
}

const PriorityUpFragment = styled.div`
  button { width:96px;height:24px;font-size:12px;padding:0;line-height:0 }
`

export default PriorityUp