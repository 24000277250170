import ApiController from '../controller/ApiController'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from "snakecase-keys"
import { ERROR_TYPE } from '../interfaces/Error'
import ISelectOption from '../interfaces/SelectOption'
import { RESOURCE_TYPE } from './Workspace'

// export interface IMonitoring extends IBaseDataType {
  
// }

export interface IQueueUserInfo {
  userId:string
  userName:string
  department:string
}

export interface IQueueObjInfo {
  namespace:string
  name:string //objName
  priority:number
}

// export interface IWorkspacePriorityInsertInfo {
//   name:string
//   namespace:string
//   creationTimestamp:number
// }

export interface IGpu {
  name:string
  detail:IMigGpu[]
  resourceType:RESOURCE_TYPE
  isBlock?:boolean
  isMig?:boolean
  idx:number
}
export interface IMigGpu {
  name:string
  total:number
}

export interface IQueueItem {
  userInfo:IQueueUserInfo
  objInfo:IQueueObjInfo
  userId:string
  userName:string
  department:string
  namespace:string
  name:string
  creationTimestamp:number
  resourceType:string
  gpuType:string
  numCpu:number
  numGpu:number
  numResource:string
  priority:number
  userNode?:string
  changedFlag?:boolean
}

export interface IMigData {
  gpuIdx:number
  gpuName?:string
  isOnMig:boolean
  numMig:number
}

export default class Monitoring {

  // Dev
  public static async getGPUData(from:number, to:number, rgroupName:ISelectOption|undefined) {
    try {
      const apiController = ApiController.getInstance()

      const requestBody:any = {
        start_timestamp: from,
        end_timestamp: to
      }
      if(rgroupName !== undefined && rgroupName.value !== '0') {
        requestBody.rgroup_name = rgroupName.label
      }

      const response = await apiController.get('/metrics/gpu-usage', requestBody)
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Monitoring.getGPUData error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getGpuUsage(from:number, to:number, type:number) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/metrics/gpu-alloc', {
        start_timestamp: from,
        end_timestamp: to,
        grouping_type: Number(type)
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Monitoring.getGpuUsageByUser error>', response)
          break
      }  
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getServerData(time:number, rgroupName:ISelectOption|undefined) {
    try {
      const apiController = ApiController.getInstance()

      const requestBody:any = {
        timestamp: time
      }
      if(rgroupName !== undefined && rgroupName.value !== '0') {
        requestBody.rgroup_name = rgroupName.label
      }

      const response = await apiController.get('/metrics/server-monitoring', requestBody)
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Monitoring.getServerData error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  // public static async getPriority() { //Priority.ts로 옮김
  //   try {
  //     const apiController = ApiController.getInstance()

  //     const response = await apiController.get('/priority', {})
  //     switch(response.status) {
  //       case 200:
  //         return camelcaseKeys(response.data, {deep: true})
  //       default:
  //         console.log('Monitoring.getPriority error>', response)
  //         break
  //     }
  //   } catch(error:any) {
  //     throw new Error(ERROR_TYPE.ERROR)
  //   }
  // }

  // public static async updatePriority(items:(IQueueItem|IQueueObjInfo)[], priority?:number) { //Priority.ts로 옮김
  //   try {
  //     const apiController = ApiController.getInstance()

  //     let payload:any = {
  //       update_priority: []
  //     }
  //     for (let key in items) {
  //       let eachItem:(IQueueItem|IQueueObjInfo) = items[key]
  //       payload.update_priority.push({
  //         namespace: eachItem.namespace,
  //         obj_name: eachItem.name,
  //         priority: (priority !== undefined ? priority : eachItem.priority !== undefined ? eachItem.priority : Number(key)+1)
  //       })
  //     }
  
  //     const response = await apiController.patch('/priority', payload)
  //     switch(response.status) {
  //       case 200:
  //         return true
  //       default:
  //         console.log('Monitoring.updatePriority error>', response)
  //         return response
  //     }
  //   } catch(error:any) {
  //     throw new Error(ERROR_TYPE.ERROR)
  //   }
  // }

  // public static async insertPriority(items:IWorkspacePriorityInsertInfo[]) { //사용 안함! api도 없음! (버튼 가려짐)
  //   try {
  //     const apiController = ApiController.getInstance()

  //     let payload:any = {
  //       ws_list: snakecaseKeys(items)
  //     }
  
  //     const response = await apiController.patch('/workspaces/scale', payload)
  //     switch(response.status) {
  //       case 201:
  //         return true
  //       default:
  //         console.log('Monitoring.insertPriority error>', response)
  //         return response
  //     }
  //   } catch(error:any) {
  //     throw new Error(ERROR_TYPE.ERROR)
  //   }
  // }

  public static async getMigData(nodeName:string) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/migs', {
        node_name: nodeName
      })
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, { deep: true })
        default:
          console.log('Monitoring.getMigData error>', response)
          return response
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async setMigData(nodeName:string, migData:IMigData[]) {
    try {
      const apiController = ApiController.getInstance()

      const payload:any = snakecaseKeys(migData, { deep: true })
      const response = await apiController.patch('/migs', {
        node_name: nodeName,
        mig_setting_list: payload
      })
      switch (response.status) {
        case 200:
          return true
        default:
          console.log('Monitoring.setMigData error>', response)
          return response
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  // Dev/Ops 공용
  public static async getGPUUsageByServer(startTimestamp:number, endTimestamp:number, devFlag:boolean=false) {
    let response
    try {
      if (devFlag === true) {
        const apiController = ApiController.getInstance()
        
        response = await apiController.get('/monitoring/gpu-usage-by-server', {
          start_timestamp: startTimestamp,
          end_timestamp: endTimestamp
        })
      } else {
        //const apiController:OpsApiController = new OpsApiController()
        const apiController = ApiController.getInstance()
        response = await apiController.get('/monitoring/gpu-usage-by-server', {
          start_timestamp: startTimestamp,
          end_timestamp: endTimestamp
        })
      }
      switch(response && response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Monitoring.getGPUUsageByServer error>', response)
          return {
            error: response
          }
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getServerDetailData(startTimestamp:number, endTimestamp:number, nodeName:string) {
    let response
    try {
      const apiController = ApiController.getInstance()
      response = await apiController.get('/metrics/node', {
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
        node_name: nodeName
      })
      switch (response && response.status) {
        case 200:
          return camelcaseKeys(response, { deep: true })
        case 204:
          return camelcaseKeys(response, { deep: true })
        default:
          return response
      }
    } catch(error:any) {
      console.log('Monitoring.getServerDetailData error>', response)
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getServerHWSpec(nodeName:String) {
    let response
    try {
      //const apiController:DevApiController = new DevApiController()
      const apiController = ApiController.getInstance()
      response = await apiController.get('/rgroups/hw-spec', {
        node_name: nodeName
      })
      switch (response && response.status) {
        case 200:
          return camelcaseKeys(response, { deep: true })
        case 204:
          return camelcaseKeys(response, { deep: true })
        default:
          return response
      }
    } catch (error:any) {
      console.log('Monitoring.getServerHWSpec error>', response)
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  // Ops
  
  // public static async getGPUDataOps(from:number, to:number) {
  //   const apiController:OpsApiController = new OpsApiController()

  //   const response = await apiController.getGpuUsage(from, to)
  //   switch(response.status) {
  //     case 200:
  //       return camelcaseKeys(response.data, {deep: true})
  //     default:
  //       console.log('Monitoring.getGPUData error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }

  // public static async getServerDataOps(time:number) {
  //   const apiController:OpsApiController = new OpsApiController()
    
  //   const response = await apiController.getServerUsage(time)
  //   switch(response.status) {
  //     case 200:
  //       return camelcaseKeys(response.data, {deep: true})
  //     default:
  //       console.log('Monitoring.getServerData error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }
}