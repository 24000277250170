import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

export enum TIME_PICKER_TYPE {
  NONE = 0,
  HH = 1,
  HHMM = 2, //default
  MINI = 3
}

interface ITimePickerProps {
  value:string
  onChange:Function
  type?:TIME_PICKER_TYPE
}

interface ITimePickerState {
  date:string
  hour:number
  min:number
}

const TimePicker = (props:ITimePickerProps) => {
  const [ state, setState ] = useState<ITimePickerState> ({
    date: dayjs(props.value).format('YYYY-MM-DD'),
    hour: dayjs(props.value).hour(),
    //min: dayjs(props.value).minute()
    min: props.type && props.type === TIME_PICKER_TYPE.HH ? 0 : dayjs(props.value).minute()
  })
  const [ visible, setVisible ] = useState<boolean>(false)
  const timePickerRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)

    return() => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  },[visible])

  useEffect(() => {
    setState({
      ...state,
      date: dayjs(props.value).format('YYYY-MM-DD'),
      hour: dayjs(props.value).hour(),
      min: props.type && props.type === TIME_PICKER_TYPE.HH ? 0 : dayjs(props.value).minute()
    })
  }, [props.value])

  const clickOutsideHandler = (event:any) => {
    if( !timePickerRef.current?.contains(event.target)) {
      setVisible(false)
    }
  }

  const numberList = (min:number, max:number) => {
    let list:number[] = []
    for (let i = min; i <= max; i++) {
      list.push(i)
    }
    return list
  }

  const onChangeTime = (target:string, data:number) => {
    let picked = {
      date: state.date,
      hour: state.hour,
      min: state.min
    }
    let ago
    switch (target) {
      case 'ago':
        ago = dayjs().subtract(data, 'minute')
        picked.date = dayjs(ago).format('YYYY-MM-DD')
        picked.hour = dayjs(ago).hour()
        picked.min = dayjs(ago).minute()
        setVisible(false)
        break
      case 'hour':
        picked.hour = data
        break
      case 'min':
        picked.min = data
        break
    }
    setState({
      date: picked.date,
      hour: picked.hour,
      min: picked.min
    })

    const pickedDateTime = `${picked.date} ${picked.hour > 9 ? picked.hour : '0' + picked.hour}:${picked.min > 9 ? picked.min : '0' + picked.min} `
    props.onChange(pickedDateTime)
  }

  return (
    <TimePickerFragment>
      <div ref={timePickerRef}>
        <div className={'inputBox '+(props.type === TIME_PICKER_TYPE.MINI ? 'noIcon' : '')}>
          <i />
          <input type="text" readOnly 
                  value={props.type && props.type === TIME_PICKER_TYPE.HH ? 
                    `${state.hour > 9 ? state.hour : '0'+state.hour}` :
                    `${state.hour > 9 ? state.hour : '0'+state.hour} : ${state.min > 9 ? state.min : '0'+state.min}`} 
                  onClick={(e) => {
                    e.preventDefault()
                    return setVisible(!visible)
                  }}
                  style={props.type && props.type === TIME_PICKER_TYPE.HH ? {width:'88px'} : {}} />
        </div>
        {
          visible ?
            <div className="timeWrap" style={props.type && props.type === TIME_PICKER_TYPE.HH ? {width:'44px'} : {width:'89px'}}>
              <div>
                <p className="label">시</p>
                <div className="scrollWrap">
                  {
                    numberList(0, 23).map((item) =>
                      <button key={item} onClick={() => onChangeTime('hour', item)} className={state.hour === item ? 'on' : ''}>{item}</button>
                    )
                  }
                </div>
              </div>
              {props.type && props.type === TIME_PICKER_TYPE.HH ? false :
                <div>
                  <p className="label">분</p>
                  <div className="scrollWrap">
                    {
                      numberList(0, 59).map((item) =>
                        <button key={item} onClick={() => onChangeTime('min', item)} className={state.min === item ? 'on' : ''}>{item}</button>
                      )
                    }
                  </div>
                </div>
                }

              {/* <div>
                <button onClick={() => onChangeTime('ago', 0)}>지금</button>
                <button onClick={() => onChangeTime('ago', 5)}>5분 전</button>
                <button onClick={() => onChangeTime('ago', 15)}>15분 전</button>
                <button onClick={() => onChangeTime('ago', 30)}>30분 전</button>
                <button onClick={() => onChangeTime('ago', 60)}>1시간 전</button>
                <button onClick={() => onChangeTime('ago', 180)}>3시간 전</button>
                <button onClick={() => onChangeTime('ago', 360)}>6시간 전</button>
                <button onClick={() => onChangeTime('ago', 720)}>12시간 전</button>
                <button onClick={() => onChangeTime('ago', 1440)}>24시간 전</button>
              </div> */}
            </div> : false
        }
      </div>
    </TimePickerFragment>
  )
}

const TimePickerFragment = styled.div`
  position:relative;
  .inputBox { position:relative; border:1px solid #D5D5DA; border-radius:6px; }
  .inputBox i { position:absolute; right:8px; top:7px; width:14px; height:14px; background:url(/images-v2/datepicker-clock.svg) no-repeat center / 100%; }
  .inputBox input { width:100px; padding:0 43px 0 12px; color:#303030; line-height:28px; text-align:center; box-sizing:border-box; border-radius:6px; }
  .timeWrap { position:absolute; left:0; top:50px; display:flex; width:89px; padding:6px; background:#fff; border-radius:6px; z-index:1; border:1px solid #646469; 
    box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50); }
  .timeWrap > div { flex:1; }
  //.timeWrap > div:nth-child(2n) { border-left: solid 1px #E8E8EE; }
  .timeWrap .scrollWrap { overflow:auto; height:240px; padding:0 5px; }
  .timeWrap .scrollWrap::-webkit-scrollbar { width:2px; background:#ddd; }
  .timeWrap .scrollWrap::-webkit-scrollbar-thumb { background: #000; }
  .timeWrap .label { font-weight:bold; line-height:30px; text-align:center; font-size:14px; }
  .timeWrap button { display:block; width:100%; height:30px; font-size:14px; }
  .timeWrap button:hover { background:#F7F8FB; border-radius:6px; }
  .timeWrap button.on { border-radius:6px; background:#333; color:#fff; }
`

export default TimePicker