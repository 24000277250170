import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import PopupController from "../../../controller/PopupController"
import { IServiceItem, Service, IReplica, DASHBOARD_DATA_TYPE } from "../../../model/Service"
import Button, { BUTTON_COLOR } from "../uiv2/Button"
import ISelectOption from '../../../interfaces/SelectOption'
import Select, { SELECT_SIZE } from "../uiv2/Select"
import utils from '../../../utils'
import dayjs from 'dayjs'
import EllipsisToolTip from "ellipsis-tooltip-react-chan"

interface IServiceEventPopupProps {
  onClose:Function
  data:IServiceItem
}

interface IServiceEventPopupState {
  replicaList:IReplica[]
  replicaSelectList:ISelectOption[]
  body?:any
}

const ServiceEventPopup = (props:IServiceEventPopupProps) => {
  const popupController = PopupController.getInstance()
  const [state, _setState] = useState<IServiceEventPopupState>({
    replicaList: [],
    replicaSelectList: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ replicas, _setReplicas] = useState<ISelectOption>()
  const replicasRef = useRef(replicas)
  const setReplicas = (data:any) => {
    replicasRef.current = data
    _setReplicas(data)
  }

  useEffect(() => {
    getReplicaList ()
  }, [])

  useEffect(()=>{
    if(replicas !== undefined) {
      getData()
    }
  },[replicas])

  const getReplicaList = async () => {
    try {
      if(replicas?.label !== '레플리카를 선택해 주세요.') { 
        const response = await Service.getLogEventData(props.data.id)
        if(response) {
          let replicaSelectList:ISelectOption[] = []
          for(let key in response.recentReplicas) {
            replicaSelectList.push({
              label: response.recentReplicas[key].name,
              value: key,
              fakeLabel: dayjs(response.recentReplicas[key].createAt * 1000).format('YYYY/MM/DD HH:mm:ss ')+response.recentReplicas[key].name,
              error: response.recentReplicas[key].isError === true ? true : false
            })
          }
          setState({
            ...state,
            replicaList: response.recentReplicas,
            replicaSelectList: replicaSelectList
          })
        }
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - b6dfa6')
    }
  }

  const onChangeSelectReplicas = (replicas:ISelectOption) => {
    if(replicas.label !== '' ) {
      setReplicas(replicas)
    }
  }

  const getData = async () => {
    try {
      if (!props.data.id) return false
      if(replicas?.label === '현재 레플리카를 선택해 주세요.') return false
      const data = await Service.getLogEventDashboardData(props.data.id, replicas?.label? replicas?.label : '', DASHBOARD_DATA_TYPE.EVENT)
      if(data) {
        setState({
          ...state,
          body:data.url
        })
      }
    } catch (error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - be78cc')
    }
  }

  const closeHandler = () => {
    if (props.onClose) props.onClose()
  }

  return (
    <ServiceEventPopupFragment>
      {props.data.project? 
      <p className="label">
        <img src="/images-v2/icon-folder.svg" alt="" />
        {props.data.project}
      </p>
      : false}
      <h2 className="pageTitle"><span style={{maxWidth:'865px'}}><EllipsisToolTip>{props.data?.name}</EllipsisToolTip></span><span style={{width:'63px'}}>이벤트</span></h2> 
      <div className="topArea">
        <Select option={state.replicaSelectList}
            selected={replicas}
            width="320px"
            size={SELECT_SIZE.SMALL}
            disabled={state.replicaList.length === 0? true : false}
            placeholder="현재 레플리카를 선택해 주세요."
            onChange={onChangeSelectReplicas}/>
      </div>
      <div className="scrollWrap">
        <div>
        { state.replicaList.length === 0 ? 
          <div style={{width:"880px", textAlign:"center", padding:"265px 0"}}>데이터가 없습니다.</div>
          : stateRef.current.body === undefined? <div style={{width:"888px", textAlign:"center", padding:"265px 0"}}>레플리카를 먼저 선택해 주세요.</div> : <iframe width="888px;" height="610px;" style={{border:"none"}} src={stateRef.current.body}/> }

          
        </div>
      </div>
      <div className="btnWrap">
        <Button color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={closeHandler}>확인</Button>
      </div>
    </ServiceEventPopupFragment>
  )
}

const ServiceEventPopupFragment = styled.div`
  display:flex; flex-direction:column; width:1000px; height:calc(100vh - 186px); max-height:800px; padding:24px 32px; box-sizing:border-box;
  .label{display:flex;align-items:center;gap:6px;font-size:14px;color:#646469;}
  .label img{ width:7.5px }
  .pageTitle{ margin-top:0; font-size:24px; line-height:28.64px; font-weight:700; height:32px; gap:6px;}
  .pageTitle span{overflow:hidden;max-width: 680px;white-space:nowrap;text-overflow:ellipsis; height:29px;}
  .scrollWrap{overflow-y:auto; flex:1; margin-top:12px; padding:16px 24px; background:#F7F8FB; font-size:13px;color:#555; font-weight:400; line-height:20px; border-radius:6px; }
	.btnWrap{flex:none;display:flex;justify-content:end;gap:12px;margin-top:24px;}
  .btnWrap .normal { font-size:16px; }
  .topArea { display:flex; align-items:center; justify-content:space-between; margin-top:24px; }
  .topArea .count{flex:none;display:flex;align-items:center;gap:12px;}
  .topArea .count .bar{width:1px;height:10px;background:#878791;}
  .topArea dl{display:flex;align-items:center;gap:6px;font-size:13px;}
  .topArea dl.error{color:#FF0000;}
  .topArea dl.running dt{color:#646469;}
  .topArea dl.running dd{color:#333;}
  .topArea dl dt{font-weight: 400;}
  .topArea dl dd{font-weight: 600;}
  .dropWrap { min-width:320px; width:max-content; }
  .dropWrap button.selected {font-size:13px!important;}
  .selectBox.small span{height:15px;}
`

export default ServiceEventPopup