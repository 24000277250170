import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { User } from '../../model/User'
import PopupController from '../../controller/PopupController'
import { ERROR_TYPE } from '../../interfaces/Error'
import { useRecoilState } from 'recoil'
import { useRef } from 'react'
import { IUserAuthInfo, authState } from '../../states/authStates'
import PageEvent from '../../events/PageEvent'
import Verification from '../components/ui/Verification'
import UserInfoEdit from '../components/UserInfoEditLdap'
import PasswordEdit from '../components/PasswordEdit'

interface MypageProps {
}

interface MypageState {
  pwEdit:boolean
  passwordPass:boolean
  pwInitializedUser:boolean
  pwExpiredUser:boolean
}

const Mypage = (props:MypageProps) => {
  const user = User()
  const [ authInfo, setAuthInfo ] = useRecoilState<IUserAuthInfo|null>(authState)

  const popupController = PopupController.getInstance()
  const navigate = useNavigate()

  const [ state, _setState ] = useState<MypageState>({
    pwEdit: false,
    passwordPass: false,
    pwInitializedUser: false,
    pwExpiredUser: false
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if(authInfo?.userNo !== -1 && !authInfo?.isAdmin && authInfo?.passwordInitialized === true) {
      setState({
        ...state,
        pwEdit: true,
        pwInitializedUser: true
      })
      setAuthInfo({
        ...authInfo,
        passwordInitialized: false
      })
    }

    if(authInfo?.userNo !== -1 && authInfo?.isPasswordExpired === true) {
      setState({
        ...state,
        pwEdit: true,
        pwExpiredUser: true
      })
    }

    window.addEventListener(PageEvent.REFRESH_PAGE_MYPAGE, refreshPageHandler)
    document.addEventListener('keydown', preventEnterKey)
    return () => {
      window.removeEventListener(PageEvent.REFRESH_PAGE_MYPAGE, refreshPageHandler)
      document.removeEventListener('keydown', preventEnterKey)
    }
  }, [])

  const preventEnterKey = (e:any) => {
    if(e.keyCode === 13) {
      e.preventDefault()
    }
  }
  
  const refreshPageHandler = () => {
    window.location.reload()
  }

  const submitHandler = async (data:any) => {
    try {
      const response = await user.putMyInfo(data)
      if (response && response.status === 200) {
        if(authInfo?.isAdmin) {
          popupController.confirm(`관리자 정보가 수정되었습니다.`)
        } else {
          popupController.confirm(`사용자 정보가 수정되었습니다.`)
        }
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.DUPLICATED) {
        popupController.confirm('다른 사용자에 의해 이미 등록된 이메일 주소입니다. 이메일 주소를 변경해 주세요.')
      }
      else if ((e as Error).message === ERROR_TYPE.ERROR) {
        if(authInfo?.isAdmin) {
          popupController.confirm('관리자 정보 수정을 실패하였습니다.')
        } else {
          popupController.confirm('사용자 정보 수정을 실패하였습니다.')
        }
      }
    }
  }

  const submitHandlerPw = async (data:any) => {
    try {

      let payload = {
        userNo: data.userNo,
        userId: data.userId,
        orgPw: data.orgPw,
        pw: data.pw
      }

      const response = await user.putMyInfo(payload)
      if (response && response.status === 200) {
        if (authInfo) {
          setAuthInfo({
            ...authInfo,
            isPasswordExpired: false
          })
        }
        popupController.confirm('비밀번호가 변경되었습니다.')
        let postUrl = sessionStorage.getItem('url')
        if (!postUrl) { postUrl = '/' }
        navigate(postUrl)
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.WRONGPW) {
        return { error: ERROR_TYPE.WRONGPW }
      } else {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 02ef04')
        return
      }
    }
  }

  return (
    <MypageFragment>
      {state.passwordPass || state.pwInitializedUser === true || state.pwExpiredUser === true ?
        state.pwEdit ?
          <PasswordEdit pwExpiredUser={state.pwExpiredUser} onSubmit={submitHandlerPw} onCancel={() => setState({ ...state, pwEdit: false })} isAdmin={authInfo?.isAdmin} /> :
          <UserInfoEdit onSubmit={submitHandler} onPwChange={() => setState({ ...state, pwEdit: true })} isAdmin={authInfo?.isAdmin} />
        :
        <Verification onPwPass={() => setState({ ...state, passwordPass: true })} />
      }
    </MypageFragment>
  )
}

const MypageFragment = styled.div`
.formWrap{
  max-width:830px; min-height:800px; width:100%; padding:0 50px;
  .titleWrap { display:flex; align-items:center; gap:10px; margin-bottom:40px; color:#303030; }
  .titleWrap h1 { font-size:24px; }
  .titleWrap p { font-size:14px; }
  .formGroup + .formGroup { margin-top:20px; }
  .label { font-weight:bold }
  .btn.blue { display:block; width:240px; margin:120px auto 0; }
  .btnWrap { display:flex; align-items:center; justify-content:center; gap:10px; margin-top:120px; }
  .btnWrap .btn { width:240px; margin:0; }
}
`
export default Mypage