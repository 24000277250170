import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface ICheckboxProps {
  id:string
  checked:boolean
  label?:string
  disabled?:boolean
  onChange?:(id:string, checked:boolean) => void
}

const CheckBox = (props:ICheckboxProps) => {
  const [ checked, setChecked ] = useState<boolean>(props.checked)

  useEffect(() => {
    if (props.checked !== checked) setChecked(props.checked)
  }, [props.disabled, props.checked])

  return (
      <CheckBoxFragment>
        <input className="hide" type="checkbox" id={props.id} checked={!props.disabled && checked} disabled={props.disabled || false} onChange={(e) => {
          setChecked(!checked)
          if (props.onChange) props.onChange(props.id, !checked)
        }} />
        <label htmlFor={props.id} className={props.disabled ? 'disabled' : ''}>
          <span className="box"><img src="/images/checked2.png" alt="" /></span>
          {props.label ? <span className={"text "+(props.disabled ? 'disabled' : '')}>{props.label}</span> : false}
        </label>
      </CheckBoxFragment>
  )
}

const CheckBoxFragment = styled.div`
  position:relative;
  input { position:absolute; left:0; top:0; width:0; height:0; opacity:0; }
  label { display:flex; align-items:center; gap:10px; padding:0 16px; }
  label .box { display:block; width:20px; height:20px; border:2px solid #ddd; border-radius:3px; background:#fff; box-sizing:border-box; }
  label.disabled .box {background:#ebebeb; border-color:#ccc; }
  label .box img { display:block; width:14px; margin:2px auto; }
  label.disabled .box img { visibility:hidden; }
  label .text { font-size:14px; }
  label .text.disabled { color:#a7a7a7; }
  input:checked + label .box { background: #2c78ff;border-color:#2c78ff; }
`
export default CheckBox