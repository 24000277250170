import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import PopupController from "../../../controller/PopupController"
import { Service } from "../../../model/Service"
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../uiv2/Button"
import RadioBox from "../uiv2/RadioBox"

interface IServiceTypePopupProps {
  onClose:Function
  onSelected:Function
	preSelectedProject:string
}
interface IServiceTypePopupState {
  items:any[]
  selected:(string|number)[]
	// isModifiable:boolean[]
  // prevItem:string
}

const ServiceTypePopup = (props:IServiceTypePopupProps) => {
  const popupController = PopupController.getInstance()

  const [state, setState] = useState<IServiceTypePopupState>({
    items: [],
    selected: [],
		// isModifiable: [],
    // prevItem: ''
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const response = await Service.getServiceTypeList()

      if (response) {
        // 이전에 선택된게 있는지 확인
        const index = response.indexOf(props.preSelectedProject)
        let selected = 0
        if (index !== -1) {
          selected = index + 1
        } else {
          selected = 0
        }

        // 편집가능 여부 변수 초기화
        // const isModifiable = []
        // for (let eachItem in response) {
        //   isModifiable.push(false)
        // }

        setState({
          ...state,
          items: response,
          // isModifiable: isModifiable,
          selected: [selected]
        })
      } else {
        setState({
          ...state,
          items: [],
          selected: []
        })
      }

    } catch (error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 07ef51')
    }
  }

  const radioHandler = (id:string) => {
    const idx = id.split('_').pop()
		setState({
      ...state,
			selected: [Number(idx)]
		})
	}

  // const enableModifyHandler = (idx:number, enable:boolean) => {
  //   let prevItem = ''
  //   let error = false

  //   if (enable) { 
  //     // 편집 시작 전 기존 값 저장
  //     prevItem = state.items[idx]
  //   } else { 
  //     // 편집 완료 시, 검사하기
  //     let value = state.items[idx]
  //     if (!(/(^(?!-)(?![0-9]))[a-z0-9-]{0,62}[a-z0-9]$/).test(value)) { //형식 검사
  //       error = true
  //     } else if (state.items.filter(e => e === value).length > 1) { //중복 검사
  //       error = true
  //     }
  //   }

  //   // 편집 가능 여부 변경
	// 	let arrModifiable = state.isModifiable
	// 	arrModifiable[idx] = enable

  //   // 값 변경(에러일 경우 이전 값으로 변경)
  //   const arrItems = state.items
  //   if (error) { arrItems[idx] = state.prevItem }

  //   // 저장
	// 	setState({
  //     ...state,
  //     items: arrItems,
	// 		isModifiable: arrModifiable,
  //     prevItem: enable ? prevItem : ''
	// 	})
	// }

  // const modifyHandler = (idx:number, value:string) => {
  //   const arrItems = state.items
  //   arrItems[idx] = value
  //   setState({
  //     ...state,
  //     items: arrItems,
  //   })    
  // }

  const submitHandler = () => {
    const selectedIdx = Number(state.selected[0])
    if (props.onSelected) {
      if(selectedIdx > 0) {
        props.onSelected(state.items[selectedIdx-1])
      } else {
        props.onSelected('')
      }
    }
    closeHandler()
	}

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <ServiceTypePopupFragment>
      <div className="pageTitle">서비스 분류명</div>
      {/* <p className="subInfo">항목을 더블 클릭하면 수정 가능합니다. 형식에 맞게 입력해 주세요.</p> */}
      <p className="countInfo">총 <span>{state.items.length}</span>개</p>
      <div className="scrollWrap">
        <table>
          <colgroup>
            <col width="45px"/>
            <col width="auto"/>
          </colgroup>
          <tbody>
            <tr>
              <td><RadioBox id={`check_serviceType_0`} checked={state.selected.indexOf(0) !== -1 || state.items.length === 0} onChange={(id) => {radioHandler(id)}}/></td>
              <td>설정 없음</td>
            </tr>
            {
              state.items.map((item:any, idx:number) => { return (
                <tr key={idx}>
                  <td>
                    <RadioBox id={`check_serviceType_${idx+1}`} checked={state.selected.indexOf(idx+1) !== -1} onChange={(id) => {radioHandler(id)}}/>
                  </td>
                  <td className='col1'>
                    <div style={{display:'flex', alignItems:'center'}}>
                      <span style={{ marginRight: "8px" }}>{idx + 1}</span>
                      <div className={((Number(state.selected[0])-1) === idx ? 'selected' : '') + ' item'}>{item}</div>
                      {/* <div onDoubleClick={() => { enableModifyHandler(idx, true) }}>
                        {
                          state.isModifiable[idx] ?
                            <input autoFocus type="text" value={item}
                            onChange={(e) => { modifyHandler(idx, e.target.value) }}
                            onBlur={() => { enableModifyHandler(idx, false) }}
                            onKeyDown={(e)=>{if(e.keyCode === 13){enableModifyHandler(idx, false)}}} /> :
                            <div className={props.preSelectedProject === item ? 'preSelected' : ''}>{item}</div>
                        }
                      </div> */}
                    </div>
                  </td>
                </tr>
              )})
            }
          </tbody>
        </table>
      </div>
      <div className="btnWrap">
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={closeHandler}>취소</Button>
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>선택완료</Button>
      </div>
    </ServiceTypePopupFragment>
  )
}

const ServiceTypePopupFragment = styled.div`
  display:flex; flex-direction:column; width:400px; /* height:calc(100vh - 186px); */ /* max-height:558px; */ max-height:526px; padding:24px 32px 24px 32px; box-sizing:border-box;
  .pageTitle{font-size:24px; line-height:28.64px; font-weight:700;}
  p{font-size:13px; line-height:15.51px; font-weight:400px;}
  .subInfo{color:#878791; margin-top:12px; line-height:20px;}
  .countInfo{margin-top:24px;}
  .countInfo span{color:#4B82FF; font-weight:700;}
  .scrollWrap{overflow:auto;flex:1;margin-top:8px;}
	.btnWrap{flex:none;display:flex;justify-content:end;gap:12px;margin-top:24px;}

  //table
  table{width:100%;border-collapse:collapse;border-top:1px solid #333;}
  table tr td{padding:11px 0;font-size:14px;line-height:16.8px;font-weight:400;border-bottom:1px solid #ebebeb;}
  table tr td span{color:#B4B4BE;}
  table tr td div{padding:0.5px 0;}
  table tr td div.item{width:274px; white-space:normal; word-wrap:break-word;}

  //selected, preSelected
  .selected{font-weight:600;}
`

export default ServiceTypePopup