import { createRef, useEffect, useRef, useState } from "react"
import PopupController from "../../controller/PopupController"
import { User } from "../../model/User"
import styled from "styled-components"
import ModalMessage from "../components/ModalMessage"
import InputBox, { IInputBoxRule, TEXT_INPUT_RULE, TEXT_INPUT_TYPE } from "../components/uiv2/InputBox"
import Form from "../components/uiv2/Form"
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../components/uiv2/Button"
import { useNavigate } from "react-router"
import regularExpression from "../../utils/regularExpression"
import { uniqueId } from "lodash"
import { ERROR_TYPE } from "../../interfaces/Error"
import ModalEvent from "../../events/ModalEvent"
import CryptoJS from 'crypto-js'
import utils from "../../utils"

interface JoinError {
  nameError:string
  departmentError:string
  phoneNumberError:string
  idError:string
  emailError:string
  pwError:string
  confirmPwError:string
}

interface JoinProps {
}
interface JoinState {
  name:string
  department:string
  phoneNumber:string
  userId:string
  //canDuplicationCheckFlag:boolean
  //idDupliacationCheckFlag:boolean
  idCheckFlag:boolean
  email:string
  pw:string
  confirmPw:string
  additionalInfo:string
}

const Join = (props:JoinProps) => {
  const user = User()
  const popupController = PopupController.getInstance()
  const navigate = useNavigate()
  const RegExp = regularExpression.regExp

  const formRef = useRef<any>()
  let refId:string[] = []
  for (let i = 0; i <= 999; i++) {
    refId.push(uniqueId())
  }
  let elementsRef = refId.map(() => createRef())

  const [state, setState] = useState<JoinState>({
    name: '',
    department: '',
    phoneNumber: '',
    userId: '',
    email: '',
    pw: '',
    confirmPw: '',
    idCheckFlag: false,
    additionalInfo:''
  })
  
  const [error, setError] = useState<JoinError>({
    nameError: '',
    departmentError: '',
    phoneNumberError: '',
    idError: '',
    emailError: '',
    pwError: '',
    confirmPwError: ''
  })


  useEffect(() => {
    // document.addEventListener('keydown', preventEnterKey)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return () => {
    //   document.removeEventListener('keydown', preventEnterKey)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  },[])

  const checkDuplicateHandler = async () => {
    if (state.userId === '') {
      setState({
        ...state,
        idCheckFlag: false
      })
      setError({
        ...error,
        idError: '필수 입력 항목입니다.'
      })
      return false
    }

    let available:boolean = false
    try {
      available = await user.checkUserId(state.userId)
    } catch (error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - aec8c2')
      return false
    }

    if (available) {
      setError({
        ...error,
        idError: '사용 중인 아이디입니다.'
      })
      popupController.confirm('사용 중인 아이디입니다.')
    } else {
      setError({
        ...error,
        idError: ''
      })
      popupController.confirm('사용 가능한 아이디입니다.')
    }
    setState({
      ...state,
      idCheckFlag: !available
    })
  }

  const goBack = () => {
    let postUrl:string = '/'
    navigate(postUrl)
  }

  const submitHandler = async() => {
    if(formRef.current?.validate() === false) {
      if (!state.name || !state.email || !state.userId || !state.pw || !state.confirmPw) {
        popupController.confirm(`필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.`)
      } else if (state.pw !== state.confirmPw) {
        popupController.confirm(`비밀번호가 일치하지 않습니다. 다시 입력해 주세요.`)
      } else {
        popupController.confirm(`잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.`)
      }
      return false
    } else if(!state.idCheckFlag) {
      popupController.confirm('아이디의 중복 여부를 확인해 주세요.')
      setError({
        ...error,
        idError: '아이디 중복 확인을 해 주세요.'
      })
      return false
    }

    let data = formRef.current?.serialize()
    try {
      let payload = {
        userId: data.userId,
        name: data.name,
        department: data.department,
        phoneNumber: data.phoneNumber,
        email: data.email,
        pw: state.pw,
        additionalInfo: data.additionalInfo
      }
      const response = await user.join(payload)
      if(response === null) {
        popupController.confirmCustom('사용자 등록이 완료되었습니다.', 'joinComplete', 'joinComplete', '확인')
      }
    } catch (error) {
      if((error as Error).message === ERROR_TYPE.DUPLICATED){
        popupController.confirm('다른 사용자에 의해 이미 등록된 이메일 주소입니다. 이메일 주소를 변경해 주세요.' )
      }
      else if((error as Error).message === ERROR_TYPE.ERROR){
        popupController.confirm('에러가 발생했습니다.\n에러코드 - d437d1')
      }
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'joinComplete':
        navigate('/')
        break
    }
  }

  return (
    <JoinFragment>
      <ModalMessage />
      <div className="scrollWrap">
        <div className="authWrap">
          <header>
            <img src="/images/logo-login.png" alt="AIPub" />
            <div>
              <div className="title">인공지능<br /><span>개<span>발·</span>운영</span>의 모든것</div>
              <p className="sub1">AI Pub 사이트는 크롬 브라우저 사용을 권장합니다.</p>
              <p className="sub2">ⒸAIPub, TEN Inc</p>
            </div>
          </header>
          <section className="formWrap">
            <div className="formBorderArea">
              <div className="formScrollWrap">
                <p className="title">사용자 등록</p>
                <p className="des">시스템 문제 발생 시 연락 가능한 정보를 입력해 주세요. <span>•</span> 표기는 필수 입력 항목입니다. </p>
                <Form ref={formRef}>
                  <div className="formGroup">
                    <div className="label">
                      <p>이름</p><div className="required"></div>
                    </div>
                    <InputBox id="name" ref={elementsRef[0]}
                      type={TEXT_INPUT_TYPE.TEXT}
                      placeholder="이름을 입력해 주세요." maxLength={63}
                      value={state.name}
                      errorText={error.nameError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REQUIRED,
                        invalidateMessage: '필수 입력 항목입니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.NAME_REG_EXP,
                        invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.NAME_REG_EXP_START,
                        invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, name: value })
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>부서</p>
                    </div>
                    <InputBox id="department" ref={elementsRef[1]}
                      type={TEXT_INPUT_TYPE.TEXT}
                      placeholder="부서명을 입력해 주세요." maxLength={100}
                      value={state.department}
                      errorText={error.departmentError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.DIV_REG_EXP,
                        invalidateMessage: '한글, 영문 대소문자, 숫자로 입력해 주세요.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, department: value })
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>전화번호</p>
                    </div>
                    <InputBox id="phoneNumber" ref={elementsRef[2]}
                      type={TEXT_INPUT_TYPE.TEL}
                      placeholder="연락 가능한 전화번호를 입력해 주세요." maxLength={15}
                      value={state.phoneNumber}
                      errorText={error.phoneNumberError}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, phoneNumber: value })
                      }}
                      possibleRegExp={RegExp.NUMBER_ONLY}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>이메일</p><div className="required"></div>
                    </div>
                    <InputBox id="email" ref={elementsRef[3]}
                      type={TEXT_INPUT_TYPE.TEXT}
                      placeholder="연락 가능한 이메일 주소를 입력해 주세요."
                      value={state.email}
                      errorText={error.emailError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REQUIRED,
                        invalidateMessage: '필수 입력 항목입니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.MAIL_REG_EXP,
                        invalidateMessage: '올바르지 않은 이메일 주소입니다.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, email: value })
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>아이디</p><div className="required"></div>
                    </div>
                    <InputBox id="userId" ref={elementsRef[4]}
                      type={TEXT_INPUT_TYPE.TEXT}
                      placeholder="아이디를 입력해 주세요." /* maxLength={30} */
                      value={state.userId}
                      errorText={error.idError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REQUIRED,
                        invalidateMessage: '필수 입력 항목입니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.ID_REG_EXP,
                        invalidateMessage: '6~30자 영문 소문자, 숫자로 입력해 주세요.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, userId: value, idCheckFlag:false })
                      }}
                      button="중복확인" buttonDisabled={state.idCheckFlag || state.userId.length === 0}
                      onClickButton={async () => {
                        if(RegExp.ID_REG_EXP.test(state.userId) === true) {
                          await checkDuplicateHandler()
                        }
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>비밀번호</p><div className="required"></div>
                    </div>
                    <InputBox id="pw" ref={elementsRef[5]}
                      type={TEXT_INPUT_TYPE.PASSWORD}
                      placeholder="비밀번호를 입력해 주세요."
                      value={state.pw}
                      errorText={error.pwError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REQUIRED,
                        invalidateMessage: '필수 입력 항목입니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP,
                        rule: RegExp.PW_REG_EXP,
                        invalidateMessage: '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP_NOT,
                        rule: RegExp.PW_REG_SPECIAL_SYMBOLS,
                        invalidateMessage: '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP_NOT,
                        rule: RegExp.PW_REG_SAME,
                        invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.REGEXP_NOT,
                        rule: RegExp.PW_REG_SAME_SYMBOLS,
                        invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.FUNCTION,
                        rule: (value:any) => {
                          if (value && utils.pwContinue(value)) return true
                        },
                        invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.FUNCTION,
                        rule: (value:any, rule:IInputBoxRule) => {
                          if (rule.ref && rule.ref.current !== null && rule.ref.current.getValue) {
                            const targetValue = rule.ref.current.getValue()
                            if (targetValue !== value && targetValue) {
                              return true
                            } else if (targetValue === value) {
                              return false
                            }
                          }
                        },
                        ref: elementsRef[6],
                        invalidateMessage: '',
                        refsInvalidateMessage: '비밀번호가 일치하지 않습니다.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, pw: value })
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>비밀번호 확인</p><div className="required"></div>
                    </div>
                    <InputBox id="confirmPw" ref={elementsRef[6]}
                      type={TEXT_INPUT_TYPE.PASSWORD}
                      placeholder="비밀번호를 한번 더 입력해 주세요."
                      value={state.confirmPw}
                      errorText={error.confirmPwError}
                      rules={[{
                        basis: TEXT_INPUT_RULE.REQUIRED,
                        invalidateMessage: '필수 입력 항목입니다.'
                      }, {
                        basis: TEXT_INPUT_RULE.FUNCTION,
                        rule: (value:any, rule:IInputBoxRule) => {
                          if (rule.ref && rule.ref.current !== null && rule.ref.current.getValue) {
                            const targetValue = rule.ref.current.getValue()
                            if (targetValue && value && targetValue !== value) return true
                          }
                        },
                        ref: elementsRef[5],
                        invalidateMessage: '비밀번호가 일치하지 않습니다.'
                      }]}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, confirmPw: value })
                      }}
                    />
                  </div>
                  <div className="formGroup">
                    <div className="label">
                      <p>기타 정보</p>
                    </div>
                    <InputBox id="additionalInfo" ref={elementsRef[7]}
                      type={TEXT_INPUT_TYPE.TEXT}
                      placeholder="관리자가 요청한 정보를 입력해 주세요." maxLength={100}
                      value={state.additionalInfo}
                      onChange={(id:any, value:any) => {
                        setState({ ...state, additionalInfo: value })
                      }}
                    />
                  </div>
                </Form>
              </div>
            </div>
            <div className="btnWrap">
              <div>
                <Button color={BUTTON_COLOR.FILL_DEFAULT} size={BUTTON_SIZE.LARGE} onClickButton={goBack}>취소</Button>
              </div>
              <div>
                <Button color={BUTTON_COLOR.FILL_PRIMARY} size={BUTTON_SIZE.LARGE} onClickButton={submitHandler}>등록하기</Button>    
              </div>
            </div>
          </section>
        </div>
      </div>
    </JoinFragment>)
}

const JoinFragment = styled.div`
  height:100vh;background-color:#F7F8FB;/* padding:6px; */
  .scrollWrap{overflow:auto;/* min-height:876px; */max-height:100vh;}
  .authWrap{display:flex;justify-content:space-between;align-items:center;width:1440px;padding:64px;margin:0 auto;box-sizing:border-box;}
  header{text-align:start; width:644px;}
  header img{display:block;width:179.89px;}
  header .title{font-weight:700;line-height:60px;font-size:40px;color:#333333;margin-top:80px;}
  header .title span{color:#4B82FF;}
  header .title span>span{letter-spacing:3px;}
  header p.sub1{font-weight:400;font-size:13px;line-height:15.51px;color:#646469;margin-top:16px;}
  header p.sub2{font-weight:500;font-size:12px;line-height:14.32px;color:#B4B4BE;margin-top:8px;}
  section.formWrap{display:block;width:644px;min-height:632px;/* max-height:1034px; */border-radius:16px;background-color:#FFFFFF;box-shadow:0 12px 16px 0 #1B1D1F0D, 0 6px 12px 0 #1B1D1F0D;}
  section.formWrap .formBorderArea{padding:6px 6px 0 6px;}
  section.formWrap .formScrollWrap{overflow:auto;height:calc(100vh - 158px - 128px);min-height:416px;max-height:818px;padding:58px 52px 0 58px;}
  section.formWrap .title{font-weight:700;font-size:32px;line-height:38.19px;margin-bottom:16px;}
  section.formWrap .des{font-weight:400;font-size:13px;line-height:20px;color:#878791;margin-bottom:64px;}
  section.formWrap .des span{font-weight:400;font-size:16px;line-height:16px;color:#FF0000;}
  section.formWrap .btnWrap{display:flex;justify-content:space-between;gap:12px;margin:48px 64px 64px 64px;}
  section.formWrap .btnWrap>div{width:252px;display:flex;flex-direction:column;}

  .formGroup{display:block;}
  .formGroup .label{display:flex;gap:4px;margin-bottom:8px;padding:0;}
  .formGroup .label p{font-weight:400;font-size:13px;line-height:15.51px;color:#878791}
  .formGroup .label .required{width:4px;height:4px;border-radius:4px;background:#FF0000;margin-top:2px;}
  .formGroup + .formGroup {margin-top:24px;}

  /* .loginWrap{display:flex;flex-direction:column;justify-content:space-between;width:516px;height:530px;} */
  /* .formGroup{display:flex;flex-direction:column;justify-content:space-between;width:516px;gap:12px;}
  .formGroup+.formGroup{margin-top:48px;}
  .formGroup label{font-weight:700;font-size:14px;line-height:16.71px;margin-bottom:12px;} */
  /* .submitButton{margin-top:48px;width:516px;display:flex;flex-direction:column;} */
  /* .joinWrap{display:flex;flex-direction:column;justify-content:space-between;width:516px;height:70px;}
  .joinWrap p{font-weight:400; font-size:12px; line-height:14.32px; color:#878791;}
  .joinWrap p span{font-weight:600; color:#333333;} */
  form .message{margin:8px 0 0 0; font-weight:400; font-size:12px; line-height:16px;}


  //scroll bar custom
  .scrollWrap::-webkit-scrollbar { width:18px; height:18px; }
  .scrollWrap::-webkit-scrollbar-track { background:#F7F8FB; width:18px;}
  .scrollWrap::-webkit-scrollbar-thumb { background:#CFCFD6; border-radius:10px; border:6px solid #F7F8FB;}
  .scrollWrap::-webkit-scrollbar-button { display:none; }
  .scrollWrap::-webkit-scrollbar-corner { background-color:transparent;}
  .formScrollWrap::-webkit-scrollbar { width:6px; height:6px; }
  .formScrollWrap::-webkit-scrollbar-track { background-color:#FFF; width:6px;}
  .formScrollWrap::-webkit-scrollbar-thumb { background:#CFCFD6; border-radius:10px;}
  .formScrollWrap::-webkit-scrollbar-button { display:none; }
`

export default Join