import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'
import ISelectOption from '../../../interfaces/SelectOption'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../../Config'

export enum SELECT_SIZE {
  NORMAL = 'normal',
  SMALL = 'small'
}

interface ISelectProps {
  ref?:React.ForwardedRef<JSX.Element>
  id?:string
  option:ISelectOption[]
  selected?:ISelectOption
  disabled?:boolean
  readonly?:boolean
  placeholder?:string
  width?:string
  size?:SELECT_SIZE
  helpText?:string
  errorText?:string
  error?:boolean
  onChange?:Function
  titleStyle?:boolean
  maxWidth?:string
}

const defaultProps = {
  width: '100%',
  size: SELECT_SIZE.NORMAL
}

const Select = forwardRef<any, ISelectProps>((props:ISelectProps, ref:React.MutableRefObject<any>) => {
  const defaultRef = useRef<ISelectOption>()
  const selectRef:React.MutableRefObject<any> = ref ? ref : defaultRef
  
  const [ dropFlag, _setDropFlag ] = useState<boolean>(false)
  const dropFlagRef = React.useRef(dropFlag)
  const setDropFlag = (value:boolean) => {
    dropFlagRef.current = value
    _setDropFlag(value)
  }
  useEffect(() => {
    if (dropFlag === true) { 
      document.addEventListener('mousedown', clickOutsideHandler)
    } else {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [dropFlag])

  const [ selected, _setSelected ] = useState<ISelectOption>({
    label: props.selected ? props.selected.label : (props.placeholder ? props.placeholder : ''),
    value: props.selected ? props.selected.value : '',
    fakeLabel: props.selected ? props.selected.fakeLabel : ''
  })
  const selectedRef = React.useRef(selected)
  const setSelected = (value:ISelectOption) => {
    selectedRef.current = value
    _setSelected(value)
  }
  const prevSelected = Utils.usePrevState(selected)

  useEffect(() => {
    if (props.onChange) props.onChange(selected)
  }, [selected])

  useEffect(() => {
    if (prevSelected.label !== props.selected?.label) {
      let selectedOption: ISelectOption | null = null
      for (let eachOption of props.option) {
        if (eachOption.label === props.selected?.label) {
          selectedOption = eachOption
        }
      }
      if (selectedOption !== null) {
        setDropFlag(false)
        setSelected(selectedOption)
      }
    }
    if (props.option.length > 0 && props.placeholder === undefined && selected.label === '') {
      if (props.option[0].label === '') {
        // label이 빈값인 경우.
        setSelected({
          label: 'AN ERROR OCCURED!!',
          value: '------'
        })
      } else {
        setSelected(props.option[0])
      }
    }
    if(props.selected !== undefined && (props.selected?.label !== selected.label && props.selected?.value !== selected.value)) {
      setSelected({
        label: props.selected?.label || '',
        value: props.selected?.value || '',
        fakeLabel: props.selected.fakeLabel || ''
      })
    }

    // 초기화 혹은 이전 선택으로 되돌리고 싶을 때 {label: '', value: ''} 로 주면 이거 실행됨
    if(props.selected?.label === '' && prevSelected.value !== props.selected?.value) {
        setSelected({
          label: (props.placeholder ? props.placeholder : ''),
          value: '',
        })
    }
  }, [prevSelected, props.selected, props.option])

  useEffect(() => {
    selectRef.current.getValue = () => {
      return selectedRef.current
    }
  }, [])

  const clickOutsideHandler = (event:any) => {
    if (selectRef.current?.contains(event.target) === false && dropFlagRef.current === true) {
      setDropFlag(false)
    }
  }
    
  const selectHandler = (option:ISelectOption) => {
    if (option.value !== selected.value) setSelected(option)
    setDropFlag(false)
  }

  const [ inputClass, _setInputClass ] = useState<string>()
  const setInputClass = () => {
    let newInputClass:string = 'selectBox ' + props.size
    if (props.errorText) newInputClass += ' error'
    if (props.disabled) newInputClass += ' disable'
    if (props.readonly) newInputClass += ' readonly'
    if (selected.value) {
      newInputClass += ' selected'
    } else {
      newInputClass += ' noSelected'
    }
    if (dropFlag) newInputClass += ' focus'

    _setInputClass(newInputClass)
  }

  useEffect(setInputClass, [props.errorText, props.disabled, selected.value, dropFlag])

  return(
    <SelectFragment ref={selectRef} style={{ width : props.width, maxWidth : props.maxWidth }}>
      <div className={inputClass} onClick={(): void => { 
          if (props.disabled !== true) setDropFlag(!dropFlag) 
        }} >
        <span style={{ width : props.titleStyle? "100%" : "95%", display:"flex" }}>
          {props.titleStyle ? <EllipsisToolTip options={Config.ellipsisTipOptionsOps}><span className="text" style={{ maxWidth : props.maxWidth, display:"block" }}>{selected.fakeLabel ? selected.fakeLabel : (selected.label ? selected.label : (props.placeholder ? props.placeholder : ''))}</span></EllipsisToolTip>
          : <span className="text" style={{display:"block", height:'15.2px' }}><EllipsisToolTip options={Config.ellipsisTipOptionsOps}>{selected.fakeLabel ? selected.fakeLabel : (selected.label ? selected.label : (props.placeholder ? props.placeholder : ''))}</EllipsisToolTip></span>}
          {selected.error ? <span className="redPoint"><img style={{width:'12px'}} src="/images-v2/error.svg"/></span> : false}
        </span>
        {props.titleStyle && props.titleStyle === true ?
          <img src="/images-v2/chevron-withbox.svg" alt="drop" style={{height:"24px", marginLeft:"12px"}}/> : <img src="/images-v2/dropdown.png" alt="drop" />}
      </div>
      {
        dropFlag ?
          <div className={props.titleStyle? "dropWrap titleStyleMarginTop" : "dropWrap"}>
            {
              props.option.map((eachProps:ISelectOption) => {
                return <button key={eachProps.value} className={selected.value === eachProps.value ? 'selected' : ''} onClick={():void => selectHandler(eachProps)}>
                  {eachProps.fakeLabel ? eachProps.fakeLabel : eachProps.label} {eachProps.error ? <span className="redPoint"><img src="/images-v2/error.svg"/></span> : false}
                  {eachProps.subLabel ? <div className='subLabel'>{eachProps.subLabel}</div> : false}
                </button>
              })
            }
          </div> : false
      }
      {props.helpText ? <p className="message">{props.helpText}</p> : false}
      {props.errorText ? <p className="message error">{props.errorText}</p> : false}
    </SelectFragment>
  )
})

Select.defaultProps = defaultProps

const SelectFragment = styled.div`
  position: relative;
  .selectBox {display:flex; align-items:center; justify-content:space-between; height:40px;padding:0 8px 0 12px; border:1px solid #D5D5DA; background:#fff; font-size: 13px; color:#333333; cursor:pointer; border-radius:6px; box-sizing:border-box;transition: all 0.2s;}
  .selectBox .text{ flex:1; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; word-break:break-all; }
  .selectBox img { width:24px; } 
  .selectBox:hover:not(.disable, .error),
  .selectBox.focus:not(.disable, .error){border-color: #646469; align-items: center;}
  .selectBox.focus img{transform: rotate(180deg);}
  .selectBox.focus .redPoint img{transform:none;}
  .selectBox.error{border-color:#FF0000;}
  .selectBox.disable,
  .selectBox.readonly{background: #FAFAFB;pointer-events:none}
  .selectBox.disable{color: #3333334D}
  .selectBox.disable img,
  .selectBox.readonly img{opacity: 0.2;}
  .selectBox.small{height:32px;font-size:13px;}
  .selectBox.small img{width:16px;}
  .selectBox.small + .dropWrap{top:38px;}
  .selectBox.noSelected .text {opacity:0.4}
  .dropWrap { overflow:auto; position:absolute;top:46px;left:0; right:0; max-height:334px; padding:6px;border-radius: 6px;border:1px solid #646469; background:#fff;box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50);box-sizing:border-box;z-index:101;}
  .dropWrap button { display:block;width:100%; padding:12px; font-size:14px; text-align:left; word-break:break-all;transition: all 0.3s;color: #333;font-weight:400;}
  .dropWrap button:hover { background:#fafafa; }
  .dropWrap button.selected { font-weight:600 }  
  .titleStyleMarginTop {margin-top:-6.5px;}
  .redPoint { color:red; width:12px; }
  .message{margin-top:8px;font-size: 12px;line-height: 20px;color: #878791;letter-spacing: 0;}
  .message.error{color: #FF0000;}
`

export default Select