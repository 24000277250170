import { useState, useEffect, useRef, createRef } from 'react'
import styled from 'styled-components'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { User, IUserDetail } from '../../model/User'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import { useRecoilState } from 'recoil'
import InputBox, { TEXT_INPUT_RULE, TEXT_INPUT_SHAPE, TEXT_INPUT_TYPE } from '../components/uiv2/InputBox'
import PopupController from '../../controller/PopupController'
import regularExpression from "../../utils/regularExpression"
import Form from './uiv2/Form'
import Button, { BUTTON_SIZE, BUTTON_COLOR } from '../components/uiv2/Button'
import { uniqueId } from 'lodash'

interface UserInfoEditOpsProps {
  navigate?:NavigateFunction
  onPwChange:Function
  onSubmit:Function
  isAdmin?:boolean
}

interface UserInfoEditOpsState {
  name:string
  nameError:string
  department:string
  departmentError:string
  phoneNumber:string
  phoneNumberError:string
  userId:string
  email:string
  emailError:string
  additionalInfo:string
}

const UserInfoEditOps = (props: UserInfoEditOpsProps) => {
  const popupController = PopupController.getInstance()                                 
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)  
  const user = User()
  const navigate = useNavigate()
  const formRef = useRef<any>()
  const RegExp = regularExpression.regExp

  let refId:string[] = []
  for (let i = 0; i <= 999; i++) {
    refId.push(uniqueId())
  }
  let elementsRef = refId.map(()=>createRef())


  const [ state, _setState ] = useState<UserInfoEditOpsState>({
    name: '',
    nameError: '',
    department: '',
    departmentError: '',
    phoneNumber: '',
    phoneNumberError: '',
    userId: '',
    email: '',
    emailError: '',
    additionalInfo:''
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if(userInfo?.userNo !== -1) {
      setState({
        ...state,
        name: userInfo?.name,
        userId: userInfo?.userId,
        department: userInfo?.department,
        phoneNumber: userInfo?.phoneNumber,
        email: userInfo?.email,
        additionalInfo: userInfo?.additionalInfo,
      })
    }
  }, [userInfo])

  const submitHandler = async() => {
    if(formRef.current.validate() === false) {
      if(!state.name || !state.email) {
        popupController.confirm('필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      } else {
        popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      }
      return false
    }

    let data = formRef.current.serialize()
    let payload = {
      userNo: userInfo?.userNo,
      userId: data.userId,
      name: data.name,
      department: data.department,
      phoneNumber: data.phoneNumber,
      email: data.email,
      additionalInfo: data.additionalInfo
    }
    props.onSubmit(payload)
  }

  const goBack = () => {
    let postUrl:string|null
    postUrl = sessionStorage.getItem('url')
    if (!postUrl) { postUrl = '/' }
    navigate(postUrl)
  }

  return (
    <UserInfoEditOpsFragment>
      <div className="formWrap">
        {props.isAdmin ?
          <div className="titleWrap">
            <h1>관리자 정보 변경</h1>
            <div>시스템 문제 발생 시 연락 가능한 정보를 입력해 주세요. <span className="requiredMark">•</span> 표기는 필수 입력 항목입니다. </div>
          </div> :
          <div className="titleWrap">
            <h1>사용자 정보 변경</h1>
            <div>시스템 문제 발생 시 연락 가능한 정보를 입력해 주세요. <span className="requiredMark">•</span> 표기는 필수 입력 항목입니다. </div>
          </div>
        }
        <Form ref={formRef}>
          {props.isAdmin ?
            <>
              <section>
                <div className="inputWrap">
                  <label className="label">이름 <span className="requiredMark">•</span></label>
                  <InputBox id="name" ref={elementsRef[0]}
                  type={TEXT_INPUT_TYPE.TEXT}
                  placeholder="이름을 입력해 주세요." 
                  maxLength={63} 
                  value={state.name} 
                  errorText={state.nameError} 
                  rules={[{
                    basis: TEXT_INPUT_RULE.REQUIRED,
                    invalidateMessage: '필수 입력 항목입니다.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.NAME_REG_EXP,
                    invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.NAME_REG_EXP_START,
                    invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                  }]}
                  onChange={(id:any, value:any)=>{
                    setState({
                      ...state,
                      name: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">이메일 <span className="requiredMark">•</span></label>
                  <InputBox id="email" ref={elementsRef[3]}
                  type={TEXT_INPUT_TYPE.TEXT} 
                  placeholder="연락 가능한 이메일 주소를 입력해 주세요." 
                  value={state.email}
                  errorText={state.emailError}
                  rules={[{
                    basis: TEXT_INPUT_RULE.REQUIRED,
                    invalidateMessage: '필수 입력 항목입니다.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.MAIL_REG_EXP,
                    invalidateMessage: '올바르지 않은 이메일 주소입니다.'
                  }]}
                  onChange={(id:any, value:any) => {
                    setState({
                      ...state,
                      email: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">비밀번호 <span className="requiredMark">•</span></label>
                  <div>
                    <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={(e: any) => props.onPwChange()}>비밀번호 변경</Button>
                  </div>
                </div>
              </section>
            </> : <>
              <section>
                <div className="inputWrap">
                  <label className="label">이름 <span className="requiredMark">•</span></label>
                  <InputBox id="name" ref={elementsRef[0]}
                  type={TEXT_INPUT_TYPE.TEXT}
                  placeholder="이름을 입력해 주세요." 
                  maxLength={63} 
                  value={state.name} 
                  errorText={state.nameError} 
                  rules={[{
                    basis: TEXT_INPUT_RULE.REQUIRED,
                    invalidateMessage: '필수 입력 항목입니다.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.NAME_REG_EXP,
                    invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.NAME_REG_EXP_START,
                    invalidateMessage: '한글, 영문 대소문자, 숫자만 입력 가능하며 첫 글자는 한글, 영문으로 입력해 주세요.'
                  }]}
                  onChange={(id:any, value:any)=>{
                    setState({
                      ...state,
                      name: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">부서</label>
                  <InputBox id="department" ref={elementsRef[1]}
                  type={TEXT_INPUT_TYPE.TEXT} maxLength={100} 
                  placeholder="부서명을 입력해 주세요." 
                  value={state.department} 
                  errorText={state.departmentError}
                  rules={[{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.DIV_REG_EXP,
                    invalidateMessage: '한글, 영문 대소문자, 숫자로 입력해 주세요.'
                  }]}
                  onChange={(id:any, value:any) => {
                    setState({
                      ...state,
                      department: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">전화번호</label>
                  <InputBox id="phoneNumber" ref={elementsRef[2]}
                  type={TEXT_INPUT_TYPE.TEL} maxLength={15} 
                  placeholder="연락 가능한 전화번호를 입력해 주세요." 
                  value={state.phoneNumber}
                  errorText={state.phoneNumberError}
                  possibleRegExp={RegExp.NUMBER_ONLY} 
                  onChange={(id:any, value:any) => {
                    setState({
                      ...state,
                      phoneNumber: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">이메일 <span className="requiredMark">•</span></label>
                  <InputBox id="email" ref={elementsRef[3]}
                  type={TEXT_INPUT_TYPE.TEXT} 
                  placeholder="연락 가능한 이메일 주소를 입력해 주세요." 
                  value={state.email}
                  errorText={state.emailError}
                  rules={[{
                    basis: TEXT_INPUT_RULE.REQUIRED,
                    invalidateMessage: '필수 입력 항목입니다.'
                  },{
                    basis: TEXT_INPUT_RULE.REGEXP,
                    rule: RegExp.MAIL_REG_EXP,
                    invalidateMessage: '올바르지 않은 이메일 주소입니다.'
                  }]}
                  onChange={(id:any, value:any) => {
                    setState({
                      ...state,
                      email: value
                    })
                  }} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">아이디 <span className="requiredMark">•</span></label>
                  <InputBox id="id" ref={elementsRef[4]} type={TEXT_INPUT_TYPE.TEXT} readonly={true} value={state.userId} />
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">비밀번호</label>
                  <div>
                    <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={(e: any) => props.onPwChange()}>비밀번호 변경</Button>
                  </div>
                </div>
              </section>
              <section>
                <div className="inputWrap">
                  <label className="label">기타 정보</label>
                  <InputBox id="additionalInfo" ref={elementsRef[5]}
                  type={TEXT_INPUT_TYPE.TEXT} placeholder="관리자가 요청한 정보를 입력해 주세요." maxLength={100} value={state.additionalInfo} 
                  onChange={(id:any, value:any) => {
                    setState({
                      ...state,
                      additionalInfo: value
                    })
                  }} />
                </div>
              </section>
            </>
          }
          <section style={{ marginTop: '80px', flexFlow: 'row-reverse', gap: '12px' }}>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>변경하기</Button>
            <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={goBack}>취소</Button>
          </section>
        </Form>
      </div>
    </UserInfoEditOpsFragment>
  )
}

const UserInfoEditOpsFragment = styled.div`
.formWrap {
  width:644px; min-height:800px; padding:0 334px;
  .titleWrap { align-items:center; gap:10px; margin-bottom:48px; color:#1A1A1A; }
  .titleWrap h1 { font-size:24px; margin-bottom:12px;}
  .titleWrap div { font-size:13px; line-height:20px; color:#878791; font-weight:400; }
  section + section { margin-top:24px; }
  .inputWrap { flex:1 1 0%; }
  .inputWrap label.label { padding:0 0 8px 0 }
  .requiredMark { position:relative;top:-4px;color:#F00;font-size:13px;font-weight:700 }
  .inputBox button { width:96px; align-self: start; }
}
input:focus-visible {border-color:#ff0000;}
`
export default UserInfoEditOps