import { IBaseDataType, IMAGE_TYPE, SORT_ORDER } from './BaseDataType'
import ApiController from '../controller/ApiController'
import { CSSProperties } from 'react'
import { each } from 'highcharts'
import { ERROR_TYPE } from '../interfaces/Error'
import camelcaseKeys from 'camelcase-keys'

// Dev Image Popup
export interface IImageItem extends IBaseDataType {
  rep:string
  tag:string
  id:string
}
export interface IImageItemInfo extends IBaseDataType {
  imageList:IImageItem[]
  reg:string
  total:number
}

export interface IImageListItem extends IBaseDataType {
  id: string
  rep: string
  tag: string
  isDefault?: boolean
  style?: CSSProperties
  reg?: string
  adminDefault?: boolean //임시 사용 (jupyter 이미지 default 처리하기 위함)
}
export interface IImageListItemOps extends IBaseDataType {
  repository: string,
  tag: string,
  style?: CSSProperties
}

export interface IImageRegistry {
  name:string
  regIp:string
  regPort:number
  regType:number
}

export enum IMAGE_COMMIT_STATUS {
  PROGRESS = 0, //커밋중
  PENDING = 1, //커밋 대기중
  FAILED = 2 //커밋 실패
}

export class Image {

  // Dev
  public static async getListPopup(namespace:string, jupyterFlag:boolean, sortOrder:SORT_ORDER, isCommon:boolean) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/images/import', {
        namespace: namespace,
        filter: jupyterFlag ? 1 : 0,
        sort_by: sortOrder,
        is_common: isCommon ? 1 : 0
      })
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getList(namespace:string, page:number = 1, size:number = 50, sortOrder:SORT_ORDER, imageType:IMAGE_TYPE) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/images/type', 
      namespace ? { //리소스 그룹, 사용자 정의
        namespace: namespace,
        page: page,
        size: size,
        sort_by: sortOrder,
        type: imageType
      } : { //공용
        page: page,
        size: size,
        sort_by: sortOrder,
        type: imageType
      })
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep:true})
        default:
          console.log('Image.getList error>', response)
          throw new Error(ERROR_TYPE.ERROR)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async deleteImages(imageArray:any[], name:string,  imageType:IMAGE_TYPE) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.del('/images', {
        image_list: imageArray,
        name: name,
        type: imageType
      })
  
      switch (response.status) {
        case 200:
          return response.data
        default:
          console.log('Image.deleteImages error>', response)
          return response
      }
    } catch (error:any) {
      switch(error.response.status) {
        case 400:
          return camelcaseKeys(error.response.data.data, { deep:true })
        default:
          throw new Error(ERROR_TYPE.ERROR)
      }
    }
  }

  public static async getRegList() {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/images/reg',{})

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep:true})
        default:
          console.log('Image.getRegList error>', response)
          return response
      }
    } catch (error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async checkDuplicateName(regPort:number, name:string, tag:string, namespace:string) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/images/is-exist', {
        reg_port:regPort,
        name:name,
        tag:tag,
        namespace:namespace
      })
      
      switch(response.status) {
        case 200:
          return (response.data.is_exist === false)
        default:
          console.log('Image.checkDuplicateName error>', response)
          return false
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async commit(wsName:string, namespace:string, regName:string, regIp:string, regPort:number, name:string, tag:string) {
    try {
      const apiController: ApiController = ApiController.getInstance()
      const response = await apiController.post('/images', {
        obj_name: wsName,
        namespace: namespace,
        reg_name: regName,
        reg_url: regIp+':'+regPort,
        name: name,
        tag: tag
      })
      
      switch(response.status) {
        case 200:
          return response.status
        default:
          console.log('Image.commit error>', response)
          return false
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }


  // Ops

  public static async getImageListOps(namespace:string, jupyterFlag:boolean, sortOrder:SORT_ORDER, isCommon:boolean) {
    //근디.. api가 같다고 하는데 다른걸쓸필요가 있나?
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('images/import', {
        namespace: namespace,
        filter: jupyterFlag ? 1 : 0,
        sort_by: sortOrder,
        is_common: isCommon ? 1 : 0
      })

      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep:true})
        default:
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
  // public static async deleteImagesOps(namespace:string, imageArray:any[]) {
  //   const apiController = ApiController.getInstance()
  //   const response = await apiController.deleteImages(namespace, imageArray)

  //   switch(response.status) {
  //     case 201:
  //       return response.data

  //       default:
  //       console.log('Image.deleteImagesOps error>', response)
  //       return {
  //         error: response
  //       }
  //       return response
  //   }
  // }
}