import { forwardRef } from "react"
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'

export enum BUTTON_SIZE {
  LARGE = 'normal',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export enum BUTTON_COLOR {
  FILL_PRIMARY = ' fill yellow',
  FILL_DEFAULT = ' fill',
  OUTLINE_DEFAULT = ' outline',
  OUTLINE_SECONDARY = ' outline blue',
  OUTLINE_ERROR = ' outline red',
}

export enum BUTTON_LINK {
  BLANK = '_blank',
  SELF = '_self'
}

interface IButtonProps {
  children:any
  size?:BUTTON_SIZE
  color?:BUTTON_COLOR
  disabled?:boolean
  url?:string
  target?:BUTTON_LINK
  onClickButton?:Function
}

const defaultProps = {
  color: BUTTON_COLOR.FILL_DEFAULT,
  size: BUTTON_SIZE.LARGE,
  target: BUTTON_LINK.SELF
}

const Button = forwardRef<any, IButtonProps>((props:IButtonProps, ref) => {
  const navigate = useNavigate()

  let buttonClass = props.size === BUTTON_SIZE.SMALL ? props.size : (props.size || BUTTON_SIZE.LARGE) + (props.color || BUTTON_COLOR.FILL_DEFAULT)
  if (props.disabled) buttonClass += ' disable'

  const onClickHandler = () => {
    if (props.disabled) return false
    if (props.onClickButton) props.onClickButton()
    if (props.url) {
      if (props.target === BUTTON_LINK.SELF) {
        navigate(props.url)
      } else {
        window.open(props.url, '_blank')
      }
    }
  }

  return (
      <ButtonFragment className={buttonClass} ref={ref}
                      onClick={onClickHandler}>
        {props.children}
      </ButtonFragment>
  )
})

Button.defaultProps = defaultProps

const ButtonFragment = styled.button`
  display: flex; align-items:center;justify-content:center;color: #333;transition: all 0.3s;
  &:not(.small){border-radius:6px;}
  &.normal{min-width:64px;gap:8px;height:40px;padding:0 12px;font-weight:600; font-size:16px; line-height:18px; }
  &.normal img{width:18px;}
  &.medium{min-width:56px;gap:6px;height:32px;padding:0 8px;font-weight: 500; font-size:13px; line-height:normal; }
  &.medium img{width:16px;}
  &.small{min-width:40px;padding:0 8px;border-radius: 3px;border:1px solid #B4B4BE;font-size: 12px;font-weight: 400;line-height: 22px;background: #fff;}
  &.fill{background: #F1F2F6;}
  &.fill.yellow{background: #FFDB20;}
  &.outline{border:1px solid #B4B4BE;}
  &.outline.blue{border-color: #4a82ff;color:#4B82FF;}
  &.outline.blue img{filter: brightness(0) saturate(100%) invert(49%) sepia(34%) saturate(4851%) hue-rotate(205deg) brightness(101%) contrast(101%);}
  &.outline.red{border-color: #F66;color:#F00;}
  &.outline.red img{filter: brightness(0) saturate(100%) invert(12%) sepia(76%) saturate(5323%) hue-rotate(358deg) brightness(112%) contrast(121%);}
  &.disable{color:#3333334D; cursor:default;}
  &.disable img{opacity:0.3;}
  &.disable.fill{background: #F1F2F6;}
  &.disable.fill.yellow{background: #FFDB2033;}
  &.disable.outline{border-color:#D5D5DA;}
  &.disable.outline.blue{border-color: #4B82FF4D;color:#4B82FF4D;}
  &.disable.outline.red{border-color: rgba(255, 102, 102, 0.3);color:#F000004D;}
  &.disable.small{border-color: #D5D5DA;}
  &.fill:hover:not(.disable){background: #E8E8EE;}
  &.fill.yellow:hover:not(.disable){background: #FFD028;}
  &.outline:hover:not(.disable){border-color: #646469;}
  &.outline.blue:hover:not(.disable){border-color: #3c68cc;}
  &.outline.red:hover:not(.disable){border-color: #F00;}
  &.small:hover:not(.disable){border-color: #646469;}
`
export default Button