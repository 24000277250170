import { useEffect, useRef, useState } from 'react'
import { NavigateFunction, Params, useParams, useNavigate } from "react-router-dom"
import styled from 'styled-components'

import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../components/ui/Table'
import TableEvent from "../../events/TableEvent"
import { IGroupNodeadd, IGroupVolumePv, IPvcCreation, IResourceUseradd } from '../../model/ResourceGroup'

import ModalEvent from '../../events/ModalEvent'
import { IResourceGroup, ResourceGroup } from "../../model/ResourceGroup"
import ToastEvent from "../../events/ToastEvent"
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../Config'
import { SORT_ORDER } from "../../model/BaseDataType"
import { WORKSPACE_STATUS } from "../../model/Workspace"
import PopupController from "../../controller/PopupController"
import { ERROR_TYPE } from "../../interfaces/Error"
import SubPopup from "../components/modal/sub/SubPopup"
import WebSocketEvent from '../../events/WebSocketEvent'
import WebSocketController from '../../controller/WebSocketController'

interface GroupDetailProps {
  navigate?:NavigateFunction
  params?:Params
  searchParams?:URLSearchParams
  preSelectedUser?:any[]
}

interface GroupDetailState {
  data?:IResourceGroup
}

interface GroupElseState {
  groupName:string
  page:number
  deleteableFlag:boolean
}

const GroupDetail = (props:GroupDetailProps) => {
  const params = useParams()
  const nodeTableRef = useRef<any>()
  const volumeTableRef= useRef<any>()
  const tableColWidth = [320,400,319,280]
  const popupController = PopupController.getInstance()
  const webSocketController = WebSocketController.getInstance()
  const headerLabel:ITableHeader[] = [
    { label: '이름', key: 'nodeName', type: TABLE_CELL_TYPE.FLEXBOX, sort: SORT_ORDER.ASC, sortKey: 'name', orderFlag: true},
    { label: 'IP', key: 'nodeIp', type: TABLE_CELL_TYPE.STRING, sort: false, sortKey: 'ip', styleKey: 'center' },
    { label: '가동 중 항목', key: 'nodeServiceInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'serviceCount' },
    { label: '상태', key: 'nodeDeleteInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'status', styleKey: 'paddingStyle' }
  ]
  const pvcTableColWidth = [206,160,150,100,240,181,148,140]
  const pvcHeaderLabel:ITableHeader[] = [
    { label: 'PVC 이름', key: 'volumeName', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.ASC, sortKey: 'name', styleKey: 'ellipsisStyle', orderFlag:true},
    { label: 'PV 이름', key: 'volumePvName', type: TABLE_CELL_TYPE.STRING, sort: false, sortKey: 'pvName' },
    { label: '사용중 / 신청용량', key: 'volumeSize', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'usage', styleKey: 'center' },
    { label: '타입', key: 'volumePvType', type: TABLE_CELL_TYPE.STRING, sort: false, sortKey: 'pvType' },
    { label: '타입 상세', key: 'volumeTypeDetail', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, sortKey: 'typeDetail' },
    { label: '레지스트리 설정', key: 'volumeRegInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: false, sortKey: 'regInfo', styleKey: 'paddingStyle' },
    { label: '가동중 항목', key: 'volumeServiceInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'serviceCount', styleKey: 'paddingStyle' },
    { label: '상태', key: 'volumeDeleteInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'status', styleKey: 'paddingStyle' }
  ]

  const [ state, _setState ] = useState<GroupDetailState> ({})
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ groupstate, groupState ] = useState<GroupElseState> ({
    page:0,
    deleteableFlag:false,
    groupName:''
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (params) {
      groupstate.groupName = params.name as string
      getData(groupstate.groupName)
    }
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, changeOrderHandler)
    window.addEventListener(TableEvent.ACTION, tableActionHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler) 
    webSocketController.addEventListener(WebSocketEvent.WS_EVENT, websocketEventHandler)

    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, changeOrderHandler)
      window.removeEventListener(TableEvent.ACTION, tableActionHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      webSocketController.removeEventListener(WebSocketEvent.WS_EVENT, websocketEventHandler)
    }
  }, [])

  const websocketEventHandler = (e:WebSocketEvent) => {
    switch(e.payload.action) {
      case 'volumemount':
        if (groupstate.groupName === e.payload.rgroupName) {
          getData(groupstate.groupName)
        }
        break
      default:
        break
    }
  }

  const getData = async(groupName:string) => {
    try {
      const response = await ResourceGroup.getDetail(groupstate.groupName)
      let deleteableFlag = true
      if (response) {
        let i = 0
        for (let eachNode of response.nodeList) {
          eachNode.count = i
          eachNode.paddingStyle = { padding: '10px 10px', textAlign: 'center' }
          eachNode.center = { textAlign: 'center'}
          eachNode.nodeName = [
            [<div style={{width: '280px',display:'flex', alignItems:'center'}}>
              <div>
              {eachNode.isRegMount === true ? 
              (<div style={{ width:'28px', borderRadius:'16px', border:'1px solid var(--bk-800, #303030)', fontSize:'11px',padding:'3.5px 8px', marginBottom:'4px'}}>마운트</div>) : ''}
              <div style={{ width:'254px', textAlign: 'start' }}>
                <EllipsisToolTip options={Config.ellipsisTipOptions}>{eachNode.name}</EllipsisToolTip></div>
                </div></div>]]

          eachNode.nodeIp = eachNode.ip

          eachNode.nodeServiceInfo = eachNode.serviceCount && eachNode.serviceCount > 0 ? [
            [<div style={{ display: 'flex', gap: '10px' }}>{eachNode.serviceCount} 개 <SubPopup titles={['가동 중 워크스페이스']} datas={eachNode.serviceList} colAlignCenter={false} onLeft={true} /></div>]
          ] : [
            ['0 개']
          ]

          eachNode.serviceStatus = eachNode.status ? WORKSPACE_STATUS.RUNNING : WORKSPACE_STATUS.ERROR 

          if (eachNode.serviceCount > 0 || response.isCommon === true) deleteableFlag = false //운영중인 서비스가 있는경우 삭제 불가, 기본 리소스그룹인경우 삭제불가

          //nodeDeleteInfo 를 위한 정보들 
          eachNode.type = '노드'

          eachNode.key = eachNode.name
           
          eachNode.nodeDeleteInfo = [
            eachNode.status ?
              [<div className="statusTitle">Ready</div>] : [<div className="statusTitle">Not Ready<img src='/images/alert-error.png' alt='error' className='alertImg' /></div>],
            [eachNode.serviceCount === 0 && response.isCommon === false && eachNode.isRegMount === false ?
              <div className="statusBtnBox">
                <button className="btn btnSm outline detailbtn" onClick={(e) => {
                  e.preventDefault()
                  let evt: TableEvent = new TableEvent(TableEvent.ACTION)
                  evt.payload = {
                    action: 'delete-' + eachNode.type,
                    name: eachNode.key,
                    key: eachNode.count
                  }
                  window.dispatchEvent(evt)
                }}>{eachNode.type} 삭제</button>
              </div> :
              <div className="statusBtnBox">
                <button className="btn btnSm outline detailbtn off">{eachNode.type} 삭제</button>
              </div>
            ]
          ]

          switch (eachNode.status) {
            case false:
              eachNode.rowClassName = 'error'
              break
            case true:
              eachNode.rowClassName = ''
          } 
          i++
        }

        let mountButtonEnabled = true

        for (let eachVolume of response.volumeList) {
          if (eachVolume.regInfo) mountButtonEnabled = false
          else if (response.nodeList.length === 0) mountButtonEnabled = false
        }
        let j=0
        for (let eachVolume of response.volumeList) {
          eachVolume.count = j
          //eachVolume.pvPath = eachVolume.pvInfo.path
          //eachVolume.pvServer = eachVolume.pvInfo.server
          eachVolume.center = { textAlign: 'center'}
          eachVolume.volumeName = eachVolume.name 
          eachVolume.volumePvName = eachVolume.pvInfo.name 
          //eachVolume.pvInfo.type === 'nfs' ? groupstate.groupName+'-'+eachVolume.name+'-'+eachVolume.pvInfo.name: eachVolume.name+'-'+''//uuid정보까지 모든 정보 게이트웨이상의 pvInfo.name에서 알아서주는것으로 확인
          if(eachVolume.usage === null || eachVolume.usage === undefined ) {
            eachVolume.usage = 0
          } else {
            eachVolume.usage = eachVolume.usage
          }
          eachVolume.serviceStatus = eachVolume.status ? WORKSPACE_STATUS.RUNNING : WORKSPACE_STATUS.ERROR    
          eachVolume.type = '볼륨'
          eachVolume.volumeSize = eachVolume.usage + ' / ' + eachVolume.size                         //볼륨 사용중 / 신청용량
          eachVolume.volumePvType = eachVolume.pvInfo.type.toUpperCase()                                      //볼륨 타입

          if (eachVolume.pvInfo.server === null || eachVolume.pvInfo.path === null) {                         //볼륨 타입상세
            eachVolume.volumeTypeDetail = [['-']]
          } else {
            eachVolume.volumeTypeDetail = [[eachVolume.pvInfo.server + ':' + eachVolume.pvInfo.path]]
          }
          eachVolume.mountPoint = eachVolume.regInfo || '-'                                                   //레지스트리 설정 중 레지스트리정보

          eachVolume.volumeRegInfo = [
            [<div style={{marginBottom:'10px', fontWeight:'700'}}>{eachVolume.mountPoint}</div>],
            [<div className="statusBtnBox">
              <button className={"btn btnSm outline detailbtn " + (eachVolume.mountPoint !== '-' && response.isCommon !== true ?  '' : mountButtonEnabled && eachVolume.status && eachVolume.mountPoint === '-' ? '' : 'off')} onClick={(e) => {
                let evt: TableEvent = new TableEvent(TableEvent.ACTION)
                evt.payload = {
                  action: 'tablevolumemount',
                  pvcName: eachVolume.volumeName,
                  isDelete: eachVolume.mountPoint !== '-',
                  isCommon: response.isCommon 
                }
                window.dispatchEvent(evt)
              }}>{eachVolume.mountPoint === '-' ? '볼륨 마운트' : '마운트 해제'}</button>
            </div>
            ]
          ]

          eachVolume.volumeServiceInfo = eachVolume.serviceCount && eachVolume.serviceCount > 0 ? [
            [<div style={{ display: 'flex', gap: '10px' }}>{eachVolume.serviceCount} 개 <SubPopup titles={['가동 중 워크스페이스']} datas={eachVolume.serviceList} colAlignCenter={false} onLeft={true} /></div>]
          ] : [
            ['0 개']
          ]

          eachVolume.volumeDeleteInfo = [
            eachVolume.status ?
              [<div className="statusTitle">Ready</div>] : [<div>Not Ready<img src='/images/alert-error.png' alt='error' className='alertImg' /></div>],
            [eachVolume.mountPoint === '-' && eachVolume.serviceCount == 0 && response.isCommon!==true ? 
            //eachVolume.mountPoint !== '-' && eachVolume.serviceCount && eachVolume.serviceCount > 0 !== true
              <div className="statusBtnBox">
                <button className="btn btnSm outline detailbtn" onClick={(e) => {
                  e.preventDefault()
                  let evt: TableEvent = new TableEvent(TableEvent.ACTION)
                  evt.payload = {
                    action: 'delete-' + eachVolume.type,
                    name: eachVolume.volumeName,
                    key: eachVolume.count
                  }
                  window.dispatchEvent(evt)
                }}>{eachVolume.type} 삭제</button>
              </div> :
              <div className="statusBtnBox">
                <button className="btn btnSm outline detailbtn off">{eachVolume.type} 삭제</button>
              </div>
            ]
          ]

          switch (eachVolume.status) {
            case false:
              eachVolume.rowClassName = 'error'
              break
            case true:
              eachVolume.rowClassName = ''
          }
          j++
        }
      }
      
      setState({
        data: response//1. 분리하고, 필요하면 useRef, 해당하는 데이터가 변경되면 useEffect해서 그데이터가 바뀌면 그것으로 체크
      })

      groupState({
        ...groupstate,
        deleteableFlag: deleteableFlag,
      })
      

    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 95b3eb')
      }
    }
  }

  const changeOrderHandler = async (e:TableEvent) => {
    const nodeSortKey:string = nodeTableRef.current?.sortKey || ''
    const nodeSortOrder:SORT_ORDER = (nodeTableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : nodeTableRef.current?.sortOrder)
    const volumeSortKey:string = volumeTableRef.current?.sortKey || ''
    const volumeSortOrder:SORT_ORDER = (volumeTableRef.current?.sortOrder === undefined ? SORT_ORDER.ASC : volumeTableRef.current?.sortOrder)
  
    if (stateRef.current.data) {
      let newNodeList = stateRef.current.data.nodeList
      let newVolumeList = stateRef.current.data.volumeList
      //sortorder로 들어오는거랑 sortkey로 오는 내용도 확인해봐야할듯 
      if (nodeSortKey && newNodeList.length > 0 && e.payload.target === 'nodeTable') {
        newNodeList.sort(function(a, b) {
          switch(nodeSortKey) {
            case 'name':
            case 'ip':
              return nodeSortOrder === SORT_ORDER.ASC ? a[nodeSortKey].localeCompare(b[nodeSortKey]) : b[nodeSortKey].localeCompare(a[nodeSortKey])
            case 'serviceCount':
              let ai = parseInt(String(a[nodeSortKey])) || 0
              let bi = parseInt(String(b[nodeSortKey])) || 0
              return nodeSortOrder === SORT_ORDER.ASC ? ai-bi :bi-ai
            case 'status':
              return nodeSortOrder === SORT_ORDER.ASC ? Number(b[nodeSortKey])-Number(a[nodeSortKey]) : Number(a[nodeSortKey])-Number(b[nodeSortKey])
          }
          return 0
        })
      }
      
      if (volumeSortKey && newVolumeList.length > 0 && e.payload.target === 'volumeTable') {
        newVolumeList.sort(function(a, b) {
          switch(volumeSortKey) {
            case 'name':
              return volumeSortOrder === SORT_ORDER.ASC ? a[volumeSortKey].localeCompare(b[volumeSortKey]) : b[volumeSortKey].localeCompare(a[volumeSortKey])
            case 'usage':
            case 'serviceCount':
              let ai = parseInt(String(a[volumeSortKey])) || 0
              let bi = parseInt(String(b[volumeSortKey])) || 0
              return volumeSortOrder === SORT_ORDER.ASC ? ai-bi :bi-ai
            case 'status':
              return volumeSortOrder === SORT_ORDER.ASC ? Number(b[volumeSortKey])-Number(a[volumeSortKey]) : Number(a[volumeSortKey])-Number(b[volumeSortKey])
          }
          return 0
        })
      }

      setState({
        data: {
          ...stateRef.current.data,
          nodeList: newNodeList,
          volumeList: newVolumeList
        }
      })
    }
  }

  const modalActionHandler = async (e:ModalEvent) => {
    let target:string|undefined

    switch (e.payload.action) {
      case 'delete-노드':
        try {
          target = stateRef.current.data?.nodeList[e.payload.key].name
          if (target) {
            const response = await ResourceGroup.deleteNode(groupstate.groupName, target)
            if (response.status === 200) {
              let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
              let message = `${target} 노드를 삭제하였습니다.`
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
              getData(groupstate.groupName)
            } else if (response.status !== 200 && response.message !== '') {
              popupController.confirm(`${target} 노드 삭제를 실패하였습니다.`)
              return
            }
          }
        } catch (error:any) {
          popupController.confirm(`${target} 노드 삭제를 실패하였습니다.`)
          return
        }
        break
      case 'delete-볼륨':
        try {
          target = stateRef.current.data?.volumeList[e.payload.key].name
          if (target) {
            const response = await ResourceGroup.deleteVolume(groupstate.groupName, target)
            if (response.status === 200) {
              let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
              let message = `${target} 볼륨을 삭제하였습니다.`
              toastEvent.payload = { message: message }
              window.dispatchEvent(toastEvent)
              getData(groupstate.groupName)
            } else if (response.status !== 200 && response.message !== '') {
              popupController.confirm(`${target} 볼륨 삭제를 실패하였습니다.`)
              return
            }
          }
        } catch (error:any) {
          popupController.confirm(`${target} 볼륨 삭제를 실패하였습니다.`)
          return
        }
        break
      case 'deleteResourceGroup':
        try {
          const response = await ResourceGroup.delete(groupstate.groupName)
          if (response.status === 200) {
            popupController.confirm(`${groupstate.groupName} 리소스 그룹을 삭제하였습니다.`)
            navigate('/rgroups/admin')
          } else if (response.status !== 200 && response.message !== '') {
            popupController.confirm(`${groupstate.groupName} 리소스 그룹 삭제를 실패하였습니다.`)
            return
          }
          break
        } catch (error:any) {
          popupController.confirm(`${groupstate.groupName} 리소스 그룹 삭제를 실패하였습니다.`)
          return
        }
    }
  }

  const groupUserPopupHandler = (e:any) => {
    e.preventDefault()
    popupController.groupUser(
      selectUserHandler,
      stateRef.current.data?.name ,
      stateRef.current.data ? stateRef.current.data.userList : []
      )
  }

  const selectUserHandler = async (selectedUser:IResourceUseradd) => {
    try {
      //console.log(selectedUser)
      let data = selectedUser
      let result = await ResourceGroup.updateGroupUser(groupstate.groupName, selectedUser)

      if (result !== 200) {
        popupController.confirm('사용자 추가에 실패하였습니다.')
        return
      } else if(result === 200) {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        if (selectedUser.isDelete === true) {
          toastEvent.payload = { message: `${data.userNameList[0]}(ID: ${data.userIdList[0]}) 사용자를 삭제하였습니다.` }
          window.dispatchEvent(toastEvent)
        } else {
          if (data.newUser.totalUser > 1) {
            toastEvent.payload = { message: `${data.newUser.firstuserName}(ID: ${data.newUser.firstuserId}) 외 ${data.newUser.totalUser - 1} 명의 사용자를 추가하였습니다.` }
          } else {
            toastEvent.payload = { message: `${data.newUser.firstuserName}(ID: ${data.newUser.firstuserId}) 사용자를 추가하였습니다.` }
          }
          if(data.newUser.firstuserName === undefined) {  //내용체크
          } else {
            window.dispatchEvent(toastEvent)
          }
        }
        //window.dispatchEvent(toastEvent)
        getData(groupstate.groupName)
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c60df8')
      return
    }
  }

  const removeUserHandler = async (userNo:number, userName:string, userId:string) => {
    selectUserHandler({
      userList: [userNo],
      userNameList: [userName],
      userIdList: [userId],
      isDelete: true
    })
  }

  const nodePopupHandler = (e:any) => {
    e.preventDefault()
    popupController.groupNode(
      selectNodeHandler,
      stateRef.current.data?.name
      )
  }
  
  const selectNodeHandler = async (selectedNode:IGroupNodeadd) => {
    try {
      let popupEvent: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
      let result = await ResourceGroup.updateGroupNode(groupstate.groupName, selectedNode)
      if (result !== null && result.detail) {
        let errorEvt: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
        errorEvt.payload = {
          message: '노드 추가를 실패하였습니다.'
        }
        window.dispatchEvent(errorEvt)
        return
      }
      if (result !== null && result.detail.msg) {
        popupEvent.payload = { message: result.data.detail }
        window.dispatchEvent(popupEvent)
      } else {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        toastEvent.payload = { message: `${selectedNode.nodeList[0]}${selectedNode.nodeList.length > 1 ? '외 ' + (selectedNode.nodeList.length - 1) + '개의' : ''} 노드를 추가하였습니다.` }
        window.dispatchEvent(toastEvent)

        getData(groupstate.groupName)
      }
    } catch (e) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - eee634')
      return
    }   
  }

  const pvPopupHandler = (e:any) => {
    e.preventDefault()
    popupController.groupPv(
      selectPvHandler,
      state.data?.name
    )
  }

  const selectPvHandler = async (selectedPv:IGroupVolumePv[]) => {
    try {
      const response = await ResourceGroup.setPvcWithPv(groupstate.groupName, selectedPv)
      if (response !== 200) {
        popupController.confirm('볼륨 추가를 실패하였습니다.')
      } else {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        toastEvent.payload = { message: `${selectedPv[0].name}${selectedPv.length > 1 ? '외 ' + (selectedPv.length - 1) + '개의' : ''} 볼륨을 추가하였습니다.` }
        window.dispatchEvent(toastEvent)

        getData(groupstate.groupName)
      }
    } catch (error: any) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 3457efs')
      return
    }
  }

  const pvCreationPopupHandler = (e:any) => {
    e.preventDefault()
    popupController.pvCreation(
      pvcCreateHandler,
      state.data?.name
    )
  }

  const pvcCreateHandler = async (payload:IPvcCreation, result:any) => {
    try {
      if (result === 200) {
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        toastEvent.payload = { message: `${payload.pvcName} 볼륨을 추가하였습니다.` }
        window.dispatchEvent(toastEvent)
        getData(state.data ? state.data.name : '')
      } else {
        popupController.confirm('볼륨 생성을 실패하였습니다.')
        return
      }
    } catch (error: any) {
      popupController.confirm('볼륨 생성을 실패하였습니다.')
      return
    }
  }

  const tableActionHandler = async (e:TableEvent) => {
    let target:string|undefined
    let errorEvt: ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    
    switch (e.payload.action) {
      case 'delete-노드':
        target = stateRef.current.data?.nodeList[e.payload.key].name
        if (target) {
          if (parseInt(String(stateRef.current.data?.nodeList[e.payload.key].serviceCount)) > 0) {
            popupController.confirm(`${target}을 사용 중인 워크스페이스/Job이 있습니다. 가동중인 워크스페이스 또는 Job 삭제 후 노드 삭제 가능합니다.`)
          } 
          else if(stateRef.current.data?.nodeList[e.payload.key].isRegMount === true) {
            popupController.dialouge(`마운트된 노드입니다. 볼륨 마운트 해제 후 노드 삭제 가능합니다.`,e.payload.action,e.payload.key,'확인','취소')
          } 
          else {
            popupController.dialouge(`${e.payload.name} 노드를 삭제합니다. 진행하시겠습니까?`,e.payload.action,e.payload.key,'확인','취소')
          }
        }
        break
      case 'delete-볼륨':
        target = stateRef.current.data?.volumeList[e.payload.key].name
        if (target) {
          if (stateRef.current.data?.volumeList[e.payload.key].isReg) {
            popupController.confirm(`${target} 레지스트리 마운트 된 볼륨이 있습니다. 마운트 해제 후 볼륨 삭제 가능합니다.` )//없어진(발생하지않는조건) 삭제버튼 자체가 비활성화되기때문임 혹시몰라 둠. 
          } else if (parseInt(String(stateRef.current.data?.volumeList[e.payload.key].serviceCount)) > 0) {
            popupController.confirm(`${target} 볼륨을 사용 중인 워크스페이스/Job이 있습니다. 가동중인 워크스페이스/Job 삭제 후 볼륨 삭제 가능합니다.` )
          } else {
            popupController.dialouge(`${stateRef.current.data?.volumeList[e.payload.key].usage !== null ? e.payload.name+'볼륨을 삭제합니다. 진행하시겠습니까?' : e.payload.name+'볼륨을 삭제합니다. 진행하시겠습니까?'}`,e.payload.action,e.payload.key,'확인','취소')
          }
        }
        break
      case 'tablevolumemount':
        if (e.payload) {
          try {
            const response = await ResourceGroup.mountVolume(groupstate.groupName, e.payload.pvcName, e.payload.isDelete, e.payload.isCommon)
            if (response.result === true) {
              let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
              if (e.payload.isDelete === false) {
                toastEvent.payload = { message: `${e.payload.pvcName} 볼륨을 마운트합니다.` }
              } else {
                toastEvent.payload = { message: `${e.payload.pvcName} 볼륨에 레지스트리 주소가 삭제되었습니다.` }
              }
              window.dispatchEvent(toastEvent)
              getData(groupstate.groupName)
            } else {
              if (e.payload.isDelete === false) {
                popupController.confirm(`${e.payload.pvcName} 볼륨에 레지스트리 주소 생성을 실패하였습니다.`)
              } else {
                popupController.confirm(`${e.payload.pvcName} 볼륨에 레지스트리 주소 삭제를 실패하였습니다.`)
              }
              return
            }
            
          } catch (error: any) {
            popupController.confirm('에러가 발생했습니다.\n에러코드 - 39be02')
            return
          }
        }
        break
    }
  }

  const deleteHandler = () => {
    if (state.data) {
      if (state.data.volumeList.length > 0 || state.data.nodeList.length > 0) {
        popupController.confirm('삭제하지 않은 노드 또는 볼륨이 있습니다. 전체 노드와 볼륨 삭제 후 리소스 그룹이 삭제 가능합니다.')
        return
      }
      popupController.dialouge(`${state.data.name} 리소스 그룹을 삭제합니다. 진행하시겠습니까?`,'deleteResourceGroup', 'deleteResourceGroup', '확인', '취소' )
    }
  }

  return (
    <GroupDetailPageFragment>
      {state.data ?
        <div className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
          <div className="">
            <div className="titleArea" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 className="pageTitle">
                <div style={{ display: 'flex' }}>
                  <div className="ellipsisWidth">
                    <EllipsisToolTip options={Config.ellipsisTipOptions3}>{state.data?.isCommon ? '기본 리소스 그룹' : state.data?.name}</EllipsisToolTip>
                  </div>
                  &nbsp;상세
                </div>
              </h2>
              <div style={{ textAlign: 'right' }}>
                <button disabled={!groupstate.deleteableFlag} className={"btn outline " + (groupstate.deleteableFlag ? '' : 'off')} onClick={deleteHandler}>리소스 그룹 삭제</button>
              </div>
            </div>
            <div className="formGroup">
              <div className="inputWrap">
                <div className="inputBox subTitle">
                  <h3 style={{alignSelf:'center'}}>사용자 그룹 <span className="total"><b>{state.data?.userList.length}</b> 명</span></h3>
                  {
                  state.data?.isCommon === true ? 
                  <button className="btn outline off">가져오기</button> 
                  : <button className="btn outline" onClick={groupUserPopupHandler}>가져오기</button>
                  }
                </div>
                {state.data?.userList && state.data?.userList.length > 0 ?
                  <div className="infoList">
                    {state.data.isCommon ? 
                      state.data?.userList.map((eachUser, idx) =>
                        <div className="infoBox" key={idx}>
                          {eachUser.userId} | {eachUser.name} | {eachUser.department !== null ? eachUser.department : '-'}
                          <img src='/images/ico-close4.png' alt='삭제'/>  
                          {/* 이미지만 교체하면 됨 */}
                        </div>) :
                      state.data?.userList.map((eachUser, idx) =>
                        <div className="infoBox" key={idx}>
                          {eachUser.userId} | {eachUser.name} | {eachUser.department !== null ? eachUser.department : '-'}
                          <img src='/images/ico-close3.png' alt='삭제' onClick={() => removeUserHandler(eachUser.userNo, eachUser.name, eachUser.userId)} />
                        </div>)
                    }
                  </div> :
                  <div className="noData">
                    추가된 사용자 정보가 없습니다.
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="titleArea">
            <div className="subTitle">
              <h3>리소스 그룹</h3>
              {state.data.isCommon ? <div style={{ marginTop: "20px", fontSize: "14px"}}> </div> : <div style={{ marginTop: "20px", fontSize: "14px"}}><img src='/images/star.png' style={{ width:'14px' }}/> 노드와 볼륨 삭제 시, 가동 중 워크스페이스가 없는 경우 삭제 가능하며, 마운트된 볼륨은 마운트 해제 후 삭제 가능합니다.</div>}
            </div>
          </div>
          <div className="formGroup">
            <div className="inputWrap">
              <div className="inputBox inputBoxTitle bothEnd" style={{ marginBottom: "20px" }}>
                <h3><span className='groupSubTitle'>노드 </span></h3>
                {
                  state.data?.isCommon === true ? 
                  <button className="btn outline off">가져오기</button>
                  : <button className="btn outline" onClick={nodePopupHandler}>가져오기</button>
                  }
              </div>
              <Table name="nodeTable" ref={nodeTableRef}
                colWidth={tableColWidth}
                headerLabel={headerLabel}
                items={state.data?.nodeList && state.data?.nodeList.length > 0? state.data.nodeList : []}
                noData="추가한 노드 정보가 없습니다."
                orderPrefix={0}
                defaultSortOrder={SORT_ORDER.ASC}
                checkBoxType={CHECKBOX_TYPE.NONE}
              />
            </div>
          </div>
          <div className="formGroup" style={{ marginTop: "60px", marginBottom: "200px" }}>
            <div className="inputWrap">
              <div className="inputBox inputBoxTitle bothEnd" style={{ marginBottom: "20px" }}>
                <h3><span className='groupSubTitle'>볼륨 </span></h3>
                <div className="pvc">
                  <button className={state.data?.isCommon === true ? "btn outline off":"btn outline"} onClick={pvPopupHandler}>가져오기</button>
                  <button className={state.data?.isCommon === true ? "btn off" : "btn blue"} onClick={pvCreationPopupHandler}>생성하기</button>
                </div>
              </div>
              <Table name="volumeTable" ref={volumeTableRef}
                colWidth={pvcTableColWidth}
                headerLabel={pvcHeaderLabel}
                items={state.data?.volumeList && state.data?.volumeList.length > 0 ? state.data.volumeList : []}
                noData="추가한 볼륨 정보가 없습니다."
                orderPrefix={0}
                defaultSortOrder={SORT_ORDER.ASC}
                checkBoxType={CHECKBOX_TYPE.NONE}
              />
            </div>
          </div>
        </div>
        : false}
    </GroupDetailPageFragment>
  )

}

const GroupDetailPageFragment = styled.div` 
  .divider { width:1320px; }
  .formWrap { max-width: 1320px; }
  .formWrap .pvc{display: flex; gap: 10px;}
  .formWrap .pvc .btn { width:80px; }
  .formWrap .btnWrap { margin-top:120px; width:1320px; justify-content:center; }
  .formWrap .btnWrap .btn { width:240px; }
  .narrowForm { max-width: 850px; }
  .subTitle h3 { font-size:18px }
  .inputBox { display:flex; }
  .inputBox + .inputBox { margin-top:10px; }
  .inputBox.inputBoxTitle:not(:first-of-type) { padding-top:50px; margin-top:0px; }
  .inputBox.inputBoxTitle + .inputBox { margin-top: 7px; }
  .inputBox .input { max-width:500px; }
  .inputBox .btn { width:82px; white-space:pre; }
  .inputBox { gap:19px; font-size:14px; }
  .inputBox .inputBoxTitle { font-size:14px; line-height:17px; }
  .label .btnTooltip, .inputBoxTitle .btnTooltip { width:13px; }
  .label .btnTooltip { margin-left:10px; }
  .inputBoxTitle .btnTooltip { margin-left:-5px; }
  .label, .inputBoxTitle { font-weight:500; }
  img.disabled { opacity:0; visibility:hidden; }
  button.disabled { opacity:0.5; pointer-events:none; }

  .alignItemsStart { align-items:flex-start; }
  .inputBox .stringLengthBox { position:relative; }
  .inputBox .stringLengthBox p { font-size:14px; position:absolute; bottom:10px; right:0px; width:60px; text-align:right; padding-right:15px; }
  .inputBox .numOfCharBox { width:500px; }
  .inputBox .numOfCharBox .input { padding-right:63px; }
  .resourceBoxGroup { margin:15px 0 40px 0; gap:15px; flex-wrap:wrap; }
  .inputWrap .resourceErrorBoxDetail { font-size:12px; }
  .resourceErrorBoxDetail { background-color:#F5F5F5; border-radius:30px; padding:5px 10px; margin:10px 0; font-weight:bold; }
  .redPoint { font-weight:bold; color:#EF4F30; }
  .messageBox { margin-bottom:10px }

  .total { margin-left:5px; font-size:16px; }
  .total b { color:#217eff; font-weight:bold; }
  .infoList { gap:10px 20px; display:flex; margin-top:20px; flex-wrap:wrap; width:1320px; max-height:145px; overflow:auto; }
  .infoBox { box-sizing:border-box; background:#F5F5F5; border:1px solid #DFDFDF; border-radius:100px; height:21px; line-height:21px; padding-right:27px; padding-left:10px; position:relative; max-width:100%; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; font-size:14px; }
  .infoBox img { position:absolute; right:10px; top:7px; cursor:pointer; }
  .noData { width:1320px; margin-top:20px; color:#868686; font-size:14px; text-align:center; }
  .groupSubTitle { margin-right:10px; }

  .ellipsisWidth { max-width:900px; }
  .alertImg { width:14px; height:12px; }

  .error td { color:#f30b0b; }
  .statusTitle { margin-bottom:10px; }
  .statusBtnBox { display:flex; justify-content:center; align-items:center; gap:10.41px; }
  `

  export default GroupDetail