import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import ResourceViewPopup from '../modal/sub/ResourceViewPopup'
import ModalEvent, { MODAL_TYPE } from '../../../events/ModalEvent'
import PopupController from '../../../controller/PopupController'

interface IResourceLimitProps {
  data?: any
  permissionStatus?: number
  userInfo?: any
}

interface IResourceLimitState {
}

const ResourceLimit = (props: IResourceLimitProps) => {
  const popupController = PopupController.getInstance()
  let autoReturnColor: string = ''
  let autoReturnTime: string = ''
  let isAdminApproval: string = ''
  
  const [state, setState] = useState<IResourceLimitState>({
  })
  return (
    <ResourceLimitFragment>
      {
        <div>
          {props.data.isGpuResourceBlocksEnabled === true || props.data.isWorkspaceAutoReclaimEnabled === true || props.data.isAdminApproval === true ?
            <div className="resourceLimit">
              <div style={{ display: "none" }}>{props.data.isGpuResourceBlocksEnabled ? autoReturnColor = 'color' : autoReturnColor = 'disable'}</div>
              <div className={"resources " + autoReturnColor}> 개수</div><img className="separator" src="/images/vertical-separator.png" />
              <div style={{ display: "none" }}>{props.data.isWorkspaceAutoReclaimEnabled ? autoReturnTime = 'color' : autoReturnTime = 'disable'}</div>
              <div className={"resources " + autoReturnTime}> 회수</div><img className="separator" src="/images/vertical-separator.png" />
              <div style={{ display: "none" }}>{props.data.isAdminApproval ? isAdminApproval = 'color' : isAdminApproval = 'disable'}</div>
              <div className={"resources " + isAdminApproval}> 대기</div>

              <ResourceViewPopup data={props.data} userInfo={props.userInfo} onLeft={true} />
            </div>
            :
            <div className="resourceLimit disable">
              <div>개수</div><img className="separator" src="/images/vertical-separator.png" />
              <div>회수</div><img className="separator" src="/images/vertical-separator.png" />
              <div>대기</div>
            </div>
          }
          { props.permissionStatus === 0 ?
            <div style={{ textAlignLast: 'center' }}><button style={{ width: 'none'}} className="btn outline btnSm disable">리소스 제한</button></div>
            :
            <div style={{ textAlignLast: 'center' }}><button className="btn outline btnSm" onClick={(e) => {
              e.preventDefault()
              popupController.resourceLimit(props.data, props.userInfo)
            }}>리소스 제한</button></div>}

        </div>
      }
    </ResourceLimitFragment>
  )
}

const ResourceLimitFragment = styled.div`
.resourceLimit { display:inline-flex; text-align:center; gap:5px; height:17px; margin-bottom:5px; }
.resourceLimit .resources { margin-bottom:5px; }
.separator { height:10px; padding:3px 0px; }
.disable { color:#A7A7A7; border-color:#A7A7A7; }
.color { color:#217eff; }
`

export default ResourceLimit