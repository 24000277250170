import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'
import { IGpu } from "../../../../model/Monitoring"
import { RESOURCE_TYPE } from "../../../../model/Workspace"


interface IGPUInfoPopupProps {
  title?:string
  data:IGpu[]
  onLeft?:boolean
}

interface IGPUInfoPopupState {
  isOpen: boolean
}

const GPUInfoPopup = (props: IGPUInfoPopupProps) => {
  const [state, setState] = useState<IGPUInfoPopupState>({
    isOpen: false
  })

  const modalBtnRef = useRef<any>()
  const modalRef = useRef<any>()

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [state.isOpen])

  const closeHandler = () => {
    setState({ ...state, isOpen: false })
  }

  const clickOutsideHandler = (event: any) => {
    if (!modalBtnRef.current?.contains(event.target)
      && !modalRef.current?.contains(event.target) && state.isOpen) {
      closeHandler()
    }
  }

  const onBtnClick = () => {
    setState({ ...state, isOpen: !state.isOpen })
  }

  return (
    <GPUInfoPopupFragment>
      <div>
        <button onClick={() => { onBtnClick() }} ref={modalBtnRef}>
          {state.isOpen === true ?
            <img src='/images/buttonMoreRounded.svg' alt='more' className='moreIconButton' />
            : <img src='/images/buttonMoreRounded_1.svg' alt='more' className='moreIconButton' />}
        </button>
      </div>
      {
        state.isOpen === true ?
          <div className="subModalPopupWrap" ref={modalRef}>
            <div className={"commonModal" + (props.onLeft ? " onLeft" : " onRight")}>
              <table>
                <thead>
                  <tr>
                    <th className="" style={{textAlign:'left'}}>{props.title ? props.title : '전체 GPU 모델명 및 분할 설정 정보'}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.data.map((gpu, idx) => {
                      let tag = ''
                      switch (gpu.resourceType) {
                        case RESOURCE_TYPE.MIG:
                          tag = 'MIG'
                          break
                        case RESOURCE_TYPE.BLOCK:
                          tag = 'Block'
                          break
                      }
                      return (
                        <tr key={idx}>
                          <td>
                            { gpu.resourceType !== RESOURCE_TYPE.GPU ?
                            <>
                              <div className='bottomLine'>
                                GPU {gpu.idx}.<span>{gpu.name}</span><span className="mig">{tag}</span>
                              </div>
                              {
                                gpu.detail.map((eachMig, idx2) => {
                                  return (<div key={idx2} className='bottomLine'>
                                    └ {eachMig.name}<span>{eachMig.total}개</span>
                                  </div>)
                                })
                              }
                            </> : 
                            <div className='bottomLine'>
                                GPU {gpu.idx}.<span>{gpu.name}</span>
                            </div>}
                          </td>
                        </tr>)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          : false
      }
    </GPUInfoPopupFragment>
  )
}

const GPUInfoPopupFragment = styled.div`
.subModalPopupWrap {position:relative;}
img.moreButton { width:17px;height:18px;vertical-align:bottom; padding-top:4px;}

.subModalPopupWrap .commonModal{position:absolute;width:400px;max-height:250px;overflow-y:auto;flex-direction:column;box-sizing:border-box;padding:15px 30px;border-radius: 2px;box-shadow: 3px 3px 3px 0px #DFDFDF;border: solid 1px #DFDFDF;background:#fff; z-index:201; }
.subModalPopupWrap .commonModal p {font-size:14px;line-height:16px; text-align: left;}
.subModalPopupWrap .commonModal .modalTitle{margin-bottom:10px;font-weight:bold;}
.subModalPopupWrap .commonModal .content + .content {margin-top:5px;}
.subModalPopupWrap .onRight{left:-20px;}
.subModalPopupWrap .onLeft{left:-360px;}

.subModalPopupWrap .commonModal table {width:100%; border-collapse:collapse; font-size:14px; line-height:38px;}
.subModalPopupWrap .commonModal table thead tr {border-bottom: 1px solid #DFDFDF;}
.subModalPopupWrap .commonModal table tbody tr:last-child td{border-bottom: 0;}
/* .subModalPopupWrap .commonModal table tbody td,  */
.bottomLine {border-bottom: 1px solid #DFDFDF; line-height:19px; padding-top:10px; padding-bottom:10px;}
.subModalPopupWrap .commonModal table td{text-align:left;}

.subModalPopupWrap .commonModal table td span {margin-left:10px;}
.subModalPopupWrap .commonModal table td span.mig {display:inline-block;padding:0 3px;font-size:12px;background-color:#E1F5FF;border-radius:2px;}

/* 상위 테이블 css에 영향받는 요소들 재설정 */
.subModalPopupWrap .commonModal table thead th{background-color:white; font-weight:bold;}
.subModalPopupWrap .commonModal table td{padding: 0; border-right:0;}

`

export default GPUInfoPopup