export enum MODAL_TYPE {
  NOT_SPECIFIED = '',
  IMAGE = 'image',
  INFO = 'info',
  SERVICE_TYPE = 'serviceType',
  EMAIL = 'email',
  IMAGE_SERVICE = 'imageService',
  VOLUME = 'volume',
  LOG = 'log',
  VERSION_DETAIL = 'verDetail',
  PORT_EDIT = 'portEdit',
  GROUP_USER = 'groupUser',
  NODE = 'node',
  PV = 'pv',
  PVCREATE = 'pvCreate',
  GROUP_NAME = 'groupName',
  SERVICE_TRANSFER = 'serviceTransfer',
  MIG_SETTING = 'migSetting',
  RESOURCE_LIMIT = 'resourceLimit',
  WAITING_QUEUE = 'waitingQueue',
  COMMIT = 'commit',
  IMAGE_OPS = 'imageOps',
  VOLUME_OPS = 'volumeOps',
  PORT_OPS = 'port',
  LOG_OPS = 'log',
  SERVICE_EVENT = 'serviceEvent'
}

export enum MODAL_BUTTON_TYPE {
  SUBMIT = 'submit',
  CANCEL = 'cancel'
}

export interface IModalButton {
  type:MODAL_BUTTON_TYPE
  text:string
  action:string
  key?:any
}

export default class ModalEvent extends Event {
  public static OPEN_MODAL:string = 'openModal'
  public static CLOSE_MODAL:string = 'closeModal'
  public static ACTION_MODAL:string = 'actionModal'

  public static OPEN_MESSAGE:string = 'openModalMessage'
  public static CLOSE_MESSAGE:string = 'closeModalMessage'
  public static CLEAR_MESSAGE:string = 'clearModalMessage'

  public modalType:MODAL_TYPE = MODAL_TYPE.NOT_SPECIFIED
  public payload:any = {}
}