export default {
  regExp: {
    HP_REG_EXP: /[^0-9$]/,
    ID_REG_EXP: /^[0-9a-z]{6,30}$/,
    NAME_REG_EXP: /^[가-힣a-zA-Z0-9]+$/,
    NAME_REG_EXP_START: /^[가-힣a-zA-Z]{1}/,
    MAIL_REG_EXP: /^[0-9a-zA-Z][0-9a-zA-Z+._-]*@[0-9a-zA-Z_-]+(\.[0-9a-zA-Z_-]+){1,10}$/,
    PW_REG_EXP: /(?=.*[a-zA-Z])(?=.*[$@!%*#?&])(?=.*[0-9]).{9,100}$/,//필수항목
    PW_REG_SPECIAL_SYMBOLS:/[`~^()_|+\-=;:'",.<>\{\}\[\]\\\/ ]/,
    PW_REG_SAME: /(\w)\1\1/,
    PW_REG_SAME_SYMBOLS: /(\$|\@|\!|\%|\*|\#|\?|\&)\1\1/,
    DIV_REG_EXP: /^[가-힣a-zA-Z0-9]*$/,
    WS_NAME_RULE: /(^(?!-)(?![0-9]))[a-z0-9-]{0,62}[a-z0-9]$/, ///(^(?!-))[a-z0-9-]{0,62}[a-z]$/, ///^[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
    GROUP_NAME_RULE: /^[a-z0-9][a-z0-9-]{0,62}[a-z0-9]{0}$/,
    SERVICE_NAME_RULE: /^(([a-z][a-z0-9-]{0,62}[a-z0-9]{1})|([a-z]{1}))$/,
    KEY_RULE: /^[a-zA-Z][a-zA-Z0-9._-]{0,62}$/,
    VALUE_RULE: /^[a-zA-Z0-9][a-zA-Z0-9-]{0,62}[a-zA-Z0-9]{0}$/,
    //PVC_NAME_RULE:/^[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,  //wsnamerule이랑 동일
    PVC_SIZE_RULE:/^[0-9]{1,}$/,
    CPU_NODE_SCALE_RULE:/^[0-9]*\.?[0-9]{0,2}$/,
    PATH_RULE:/^[/][a-zA-Z0-9/._-]+$/,
    IMAGE_NAME_RULE:/^[a-z0-9/.-]{0,66}$/,
    NUMBER_ONLY: /^[0-9]*$/,
  }
}