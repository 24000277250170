import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Pagenation from '../components/ui/Pagenation'
import { Alert, IAlertListItem, ALERT_ORDER_BY, ALERT_STATUS } from '../../model/Alert'
import { ITableHeader, TABLE_CELL_TYPE, CHECKBOX_TYPE } from '../components/ui/Table'
import { SORT_ORDER } from '../../model/BaseDataType'
import PageEvent from '../../events/PageEvent'
import TableEvent from '../../events/TableEvent'
import PopupController from '../../controller/PopupController'
import Table from '../components/ui/Table'
import dayjs from 'dayjs'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import { useRecoilState } from 'recoil'
import { authState } from '../../states/authStates'

interface IAlertCenterPageProps {
}

interface IAlertCenterState {
  page:number
  totalCount:number
  tableItems:IAlertListItem[]
  deleteItems:string[]
  selected:IAlertListItem[]
}

const AlertCenter = (props:IAlertCenterPageProps) => {
  const [ authInfo ] = useRecoilState(authState)
  const popupController = PopupController.getInstance()
  const [ state, _setState ] = useState<IAlertCenterState> ({
    page: 1,
    totalCount:0,
    tableItems: [],
    deleteItems: [],
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const tableRef = useRef<any>()
  const tableColWidth = [52, 226, null, 228]
  const headerLabel:ITableHeader[] = [
    { label: '상태', key: 'level', type: TABLE_CELL_TYPE.HTML, styleKey: 'style', sort: true, sortKey:'level'},
    { label: '내용', key: 'msg', type: TABLE_CELL_TYPE.STRING, styleKey: 'style', sort: false },
    { label: '시간', key: 'timestamp', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.DESC, styleKey: 'style', sortKey: 'datetime' }
  ]

  useEffect(() => {
    // getData(state.page)
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return () => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
  },[])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    getData(state.page)
  }

  const getStatus = (status:ALERT_STATUS) => {
    let text = ''
    switch(status) {
      case ALERT_STATUS.WARN:
        text = '경고'
        break
      case ALERT_STATUS.CONCERN:
        text = '주의'
        break
      case ALERT_STATUS.NOTI:
        text = '알림'
        break
    }
    return text
  }

  const getData = async(page?:number): Promise<void> => {
    try {
      tableRef.current?.setSelected([])
      const to = Math.floor(new Date().getTime()/1000)
      const from = to-2630000
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.DESC : tableRef.current?.sortOrder)
      const sortBy = tableRef.current?.sortKey === 'level' ? ALERT_ORDER_BY.STATUS : ALERT_ORDER_BY.DATETIME
      let data:any = await Alert.getList(authInfo.userNo, from, to, page? page: state.page, 50, sortBy, sortOrder)

      if (data) {
        for(let eachLine of data.alertList) {
          if (eachLine.isRead === false) eachLine.style = { fontWeight:'bold' }
          eachLine.level = `<span class = 'alert${eachLine.level}'><i></i>${getStatus(eachLine.level)}</span>`
          eachLine.timestamp = dayjs(eachLine.timestamp*1000).format('YYYY/MM/DD\nHH:mm:ss')
        }
        setState({
          ...state,
          page: page,
          tableItems: data.alertList,
          totalCount: data.totalCount
        })
        window.dispatchEvent(new Event('refreshalarm'))
      }
    }
    catch(error:any) {
      //console.log('Alert.getList error>')
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6b380c')
    } 
  }

  const removeAlertHandler = async (): Promise<void> => {
    let targetIdArray:any[] = []
    const items = state.tableItems
    const selectedIdx = tableRef.current?.selected

    if (items && selectedIdx && selectedIdx.length > 0) {
      for (let eachIdx of selectedIdx) {
        targetIdArray.push(items[Number(eachIdx)].alertId)
      }
      popupController.dialouge(`${targetIdArray.length} 개의 알림이 삭제됩니다. 진행하시겠습니까?`, 'deleteAlert', targetIdArray , '확인', '취소')
      setState({
        ...state,
        deleteItems: targetIdArray
      })
    } else {
      popupController.confirm(`선택된 항목이 없습니다.`)
    }    
  }

  const navigateToPage = (page:number):void => {
    if(stateRef.current.page !== page) {
      getData(page)
    }
  } 

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'deleteAlert':
        try {
          const deleteItems = e.payload.key
          await Alert.deleteList(deleteItems)
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${deleteItems.length} 개의 알림이 삭제되었습니다.` }
          window.dispatchEvent(evt)

          // 삭제 후 갱신
          tableRef.current?.setSelected([])
          getData(stateRef.current.page)
        } catch (error) {
          console.log(error)
          popupController.confirm(`에러가 발생했습니다.\n에러코드 - 8de9e4`)
        }
        break
    }
  }

  const selectboxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0){
      let selectedArray:IAlertListItem[] = []

      for(let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  return (
    <AlertCenterFragment>
        <div className="titleArea bothEnd">
          <h2 className="pageTitle">
            워크스페이스 알림
            <span className="total"><b>{state.totalCount}</b> 개</span>
          </h2>
          <div className="btnWrap">
            <button className={"btn outline "+(tableRef.current?.selected.length === 0 ? 'off' : '') } onClick={removeAlertHandler}>선택 항목 삭제</button>
          </div>
        </div>

        <Table ref={tableRef} 
               colWidth={tableColWidth} 
               headerLabel={headerLabel} 
               items={state.tableItems} 
               noData="알림이 없습니다."
               onUpdate={onUpdateStatus}
               onSelect={selectboxCheckHandler}
               checkBoxType={CHECKBOX_TYPE.USE_ALL}
               defaultSortOrder={SORT_ORDER.DESC} />
        { state.totalCount > 0 ?
          <Pagenation defaultPage={state.page} totalArticle={state.totalCount} perPage={50} onChange={(newPage:number) => navigateToPage(newPage)} />  : false 
        }
      </AlertCenterFragment>
  )
}

const AlertCenterFragment = styled.div`
  .pageTitle .new{ display:inline-block; width:20px; height:20px; background:url(/images/new.png) no-repeat center / 100%; }
  table tbody td { padding:11px 20px }
  table .col0, .col2{ text-align:center; }

  i {display:inline-block;width:12px;height:12px;margin-right:10px;vertical-align:middle;}
  .alert0 i {background:url(/images/alert.png) no-repeat center / 100%;}
  .alert1 i {background:url(/images/alert-warn.png) no-repeat center / 100%;}
  .alert2 i {width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}
`

export default AlertCenter