import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { IUserDetail, USER_LIST_ORDER_BY, User } from '../../model/User'
import { SORT_ORDER } from '../../model/BaseDataType'
import ModalEvent, { IModalButton, MODAL_BUTTON_TYPE, MODAL_TYPE } from '../../events/ModalEvent'
import Table, { CHECKBOX_TYPE, TABLE_CELL_TYPE, ITableHeader } from '../components/uiv2/Table'
import TableEvent from "../../events/TableEvent"
import Pagenation from '../components/ui/Pagenation'
import PageEvent from '../../events/PageEvent'
import Utils from '../../utils'
import dayjs from 'dayjs'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import PopupController from '../../controller/PopupController'
import ResourceLimit from '../components/table/ResourceLimit'
import ToastEvent from '../../events/ToastEvent'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../components/uiv2/Button'

export enum PERMISSION_STATUS {
  NOTPERMITTED = 0,
  PERMITTED = 1
}

interface IUserListProps {
  // navigate?:NavigateFunction
  // selected:IUserDetail[]
}

interface IUserListState {
  page:number
  totalArticle:number
  tableItems:IUserDetail[]
  deleteItems:string[]
  preSelected:number[]
  selected:IUserDetail[]
}

const UserList = (props:IUserListProps) => {
  const user = User()
  const popupController = PopupController.getInstance()
  const navigate = useNavigate()

  // const tableRef:React.RefObject<Table> = React.createRef()
  const tableRef = useRef<any>()
  const tableColWidth = [42, 272, 272, 160, 273, 101, 106, 85]
  const headerLabel:ITableHeader[] = [
    { label: '아이디', key: 'userId', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'ID' },
    { label: '이름', key: 'tableUserInfo', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'NAME' },
    { label: '부서', key: 'department', type: TABLE_CELL_TYPE.STRING, sort: true, sortKey: 'DEPARTMENT', default:'-' },
    { label: '연락처', key: 'tableTelEmail', type: TABLE_CELL_TYPE.FLEXBOX, sort: false },
    { label: '등록일', key: 'tableDate', type: TABLE_CELL_TYPE.STRING, sort: SORT_ORDER.DESC, sortKey: 'CREATION_TIMESTAMP', default:'-' },
    { label: '비밀번호 초기화', key: 'tableResetPW', type: TABLE_CELL_TYPE.FLEXBOX, sort: false },
    { label: '승인여부', key: 'tablePermissionState', type: TABLE_CELL_TYPE.FLEXBOX, sort: true, sortKey: 'IS_CONFIRM' }
  ]

  const [ state, _setState ] = useState<IUserListState>({
    totalArticle: 0,
    page: 1,
    tableItems: [],
    deleteItems: [],
    preSelected: [],
    selected: []
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    window.addEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    return() => {
      window.removeEventListener(TableEvent.CHANGE_SORT_ORDER, eventControl)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ShowRefreshButton = () => {
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  }

  const eventControl = (e:Event) => {
    getData()
  }

  const resetPassword = (userId:string, name:string, userNo:any) => {
    popupController.dialouge(`${name}(ID: ${userId}) 님의 비밀번호를 초기화합니다. 진행하시겠습니까?`, 'resetPw', {userId:userId, userNo:userNo, name:name}, '확인', '취소')
  }

  const permitUser = (userId:string, name:string, userNo:any) => {
    popupController.dialouge(`${name}(ID: ${userId}) 님의 서비스 사용을 승인합니다. 진행하시겠습니까?`, 'permitted', {userId:userId, userNo:userNo, name:name}, '확인', '취소')
  }

  const getData = async (page?:number) => {
    try {
      let orderBy:USER_LIST_ORDER_BY
      switch (tableRef.current?.sortKey) {
        case 'NAME' :
          orderBy = USER_LIST_ORDER_BY.NAME
          break
        case 'DEPARTMENT' :
          orderBy = USER_LIST_ORDER_BY.DEPARTMENT
          break
        case 'IS_CONFIRM' :
          orderBy = USER_LIST_ORDER_BY.IS_CONFIRM
          break
        case 'ID' :
          orderBy = USER_LIST_ORDER_BY.ID
          break
        case 'CREATION_TIMESTAMP':
          orderBy = USER_LIST_ORDER_BY.CREATION_TIMESTAMP
          break
        default:
          orderBy = USER_LIST_ORDER_BY.CREATION_TIMESTAMP
          break
      }
      if (tableRef.current !== undefined) {
        // console.log(USER_LIST_ORDER_BY[this.tableRef.current.state.sortKey])
      }
      const sortOrder = (tableRef.current?.sortOrder === undefined ? SORT_ORDER.DESC : tableRef.current?.sortOrder)
      const data = await user.getList(page ? page : state.page, 50, orderBy, sortOrder)

      if (data) {
        for (let eachRow of data.userList) {
          //이름+기타정보
          eachRow.tableUserInfo = 
          eachRow.additionalInfo ?
          [[eachRow.name],[(<div style={{width:'100px', whiteSpace:'pre-wrap'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachRow.additionalInfo}</EllipsisToolTip></div>)]]
          : [[eachRow.name]]

          // 연락처
          eachRow.tableTelEmail = [
            [eachRow.phoneNumber ? Utils.parseTelNo(eachRow.phoneNumber) : '-'],
            [(<div style={{width:'163px'}}><EllipsisToolTip options={Config.ellipsisTipOptions}>{eachRow.email || '-'}</EllipsisToolTip></div>)]
          ]

          // 등록일
          eachRow.tableDate = (typeof(eachRow.creationTimestamp) === 'number' ? `${dayjs(eachRow.creationTimestamp*1000).format('YYYY/MM/DD')}` : eachRow.creationTimestamp)

          // 비밀번호 초기화
          eachRow.tableResetPW = [
            [
              <Button size={BUTTON_SIZE.SMALL} disabled={!(eachRow.permissionStatus === PERMISSION_STATUS.PERMITTED && eachRow.passwordInitialized === false)}
              onClickButton={() => resetPassword(eachRow.userId, eachRow.name, eachRow.userNo)}>초기화</Button>
            ]
          ]

          // 승인 여부
          eachRow.tablePermissionState = [
            [eachRow.permissionStatus === PERMISSION_STATUS.PERMITTED ? 
              (eachRow.isDormancy === true ? 
                (<Button size={BUTTON_SIZE.SMALL} onClickButton={() => {
                  popupController.dialouge(
                    `${eachRow.name}(ID: ${eachRow.userId}) 님의 계정이 휴면 해제됩니다. 진행하시겠습니까?`, 
                    'resetDormancy', 
                    {name:eachRow.name, userNo:eachRow.userNo, userId:eachRow.userId}
                    )
                  }}>휴면 해제</Button>) : '승인됨') :
                (<Button size={BUTTON_SIZE.SMALL} onClickButton={() => permitUser(eachRow.userId, eachRow.name, eachRow.userNo)}>승인</Button>)
            ]
          ]
        }
        setState({
          ...state,
          totalArticle: data.total ? data.total : 0,
          tableItems: data.userList,
        })
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 4412a6')
    }
  }

  const navigateToPage = (page:number):void => {
    setState({
      ...state,
      page:page
    })
    getData(page)
  }

  const selectBoxCheckHandler = (selectedIdx:number[]):void => {
    if (state.tableItems && selectedIdx && selectedIdx.length > 0) {
      let selectedArray:IUserDetail[] = []

      for (let idx in selectedIdx){
        selectedArray.push(state.tableItems[Number(selectedIdx[idx])])
      }
      setState({
        ...state,
        selected: selectedArray
      })
    } else {
      setState({
        ...state,
        selected: []
      })
    }
  }

  const onUpdateStatus = () => {
    getData()
  }

  const openModalHandler = (msg:string, button:IModalButton[]|null) => {
    // let e:ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    // e.payload = { 
    //   message: msg
    // }
    // if(button) e.payload.button = button
    // window.dispatchEvent(e)
  }

  const removeUserConfirm = async ():Promise<void> => {
    let targetIdArray:any[] = []
    let targetNoArray:any[] = []
    let targetNameArray:any[] = []
    let targetDataArray:any = {}
    const items = state.tableItems//tableRef.current?.items
    const selectedIdx = tableRef.current?.selected
    if (items && selectedIdx && selectedIdx.length > 0) {
      let wsCount:number[] = [],
          operating:boolean = false,
          item:any
      for (let eachIdx of selectedIdx) {
        item = items[Number(eachIdx)]
        targetIdArray.push(item.userId)
        wsCount.push(item.wsCount)
        targetNoArray.push(item.userNo)
        targetNameArray.push(item.name)
        if (!operating) operating = (item.wsCount > 0)
      }
      let message:string = ''
      let button:IModalButton[] = []
      let wsCountSum:number = 0
      let text:any
      //console.log(operating)
      if (operating) {
        for (let i: number = 0; i < wsCount.length; i++) {
          wsCountSum += wsCount[i]
          if (selectedIdx.length > 1) {
            if (wsCount[i] > 0) {
              //console.log(wsCount[i])
              //message += message === '' ? '' : `<br1>`
              message = `${targetNameArray[0]}(ID: ${targetIdArray[0]}) 님 외 ${selectedIdx.length - 1}명의 계정에 ${wsCountSum}개의 운영 중인 워크스페이스가 있습니다. 계정 삭제 전에 운영 중인 항목을 회수해 주세요.`
              text = `${targetNameArray[0]}(ID: ${targetIdArray[0]})`
              targetDataArray = { name: text, targetNoArray }
            }
            // 버튼이 3가지임 (메인버튼 2개 이상일 때 케이스 구현 가능?)
            button = [
              { type: MODAL_BUTTON_TYPE.CANCEL, text: '취소', action: 'cancel' },
              { type: MODAL_BUTTON_TYPE.SUBMIT, text: '회수하고 삭제하기', action: 'recoverDelete', key: targetDataArray },
              { type: MODAL_BUTTON_TYPE.SUBMIT, text: '회수하러 가기', action: 'recover', key: 'recover' }
            ]
            //popupController.messagePopup(message, button)
          } else if(selectedIdx.length === 1) {
            if (wsCount[i] > 0) {
              //message += message === '' ? '' : '<br2>'
              message = `${targetNameArray[0]}(ID: ${targetIdArray[0]}) 님의 계정에 ${wsCount[i]}개의 운영 중인 워크스페이스가 있습니다. 계정 삭제 전에 운영 중인 항목을 회수해 주세요.`
              text = `${targetNameArray[0]}(ID: ${targetIdArray[0]})`
              targetDataArray = { name: text, targetNoArray }
            }
            // 버튼이 3가지임 (메인버튼 2개 이상일 때 케이스 구현 가능?)
            button = [
              { type: MODAL_BUTTON_TYPE.CANCEL, text: '취소', action: 'cancel' },
              { type: MODAL_BUTTON_TYPE.SUBMIT, text: '회수하고 삭제하기', action: 'recoverDelete', key: targetDataArray },
              { type: MODAL_BUTTON_TYPE.SUBMIT, text: '회수하러 가기', action: 'recover', key: 'recover' }
            ]
            //popupController.messagePopup(message, button)
          }
        }
        popupController.messagePopup(message, button)
      } else {
        let text:any
        let targetDataArray:any = {}
        if (targetIdArray.length > 1) {
          text = `${targetNameArray[0]}(ID: ${targetIdArray[0]})`
          targetDataArray = {name:text, targetNoArray}
          message = `${targetNameArray[0]}(ID: ${targetIdArray[0]}) 님 외 ${targetIdArray.length-1}개의 사용자 계정이 삭제됩니다. 진행하시겠습니까? \n*계정 삭제 후 해당 사용자가 사용하던 데이터를 삭제하여 스토리지 공간을 확보해 주세요.`
        } else {
          text = `${targetNameArray[0]}(ID: ${targetIdArray[0]})`
          targetDataArray = {name:text, targetNoArray}
          //message = targetNameArray[0]+'(ID: ',targetIdArray[0]+') 님의 계정이 삭제됩니다. 진행하시겠습니까?'+'\n*계정 삭제 후 해당 사용자가 사용하던 데이터를 삭제하여 스토리지 공간을 확보해 주세요.'
          message = `${targetNameArray[0]}(ID: ${targetIdArray[0]}) 님의 계정이 삭제됩니다. 진행하시겠습니까? \n*계정 삭제 후 해당 사용자가 사용하던 데이터를 삭제하여 스토리지 공간을 확보해 주세요.`
        }
        popupController.dialouge(message, 'deleteUser', targetDataArray, '확인', '취소')
      }
      // openModalHandler(message, button)
      setState({
        ...state,
        deleteItems: targetNoArray
      })
    } else {
      popupController.confirm('선택된 항목이 없습니다.')
    }
  }

  // const resourceLimit = async () => {
  //   let data = state.selected? state.selected : []
  //   let userCheck = false

  //   for (let idx in state.selected) {
  //     if (data[idx].permissionStatus === 0) {
  //       userCheck = true
  //     }
  //   }
  //   if (userCheck) {
  //     popupController.confirm('승인이 완료된 사용자만 리소스 사용 제한이 가능합니다.')
  //   } else {
  //     for (let idx in state.selected) {
  //       let resourceLimit:any = data[idx].resourceLimit
  //       let userInfo:any = {
  //         userId: data[idx].userId,
  //         name: data[idx].name,
  //         department: data[idx].department,
  //         userNo: data[idx].userNo
  //       }
  //       if (resourceLimit === '') {
  //         let evt: ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
  //         evt.modalType = MODAL_TYPE.RESOURCE_LIMIT
  //         evt.payload = {
  //           resourceLimitUserList: data
  //         }
  //         window.dispatchEvent(evt)
  //         popupController.resourceLimit(resourceLimit, userInfo)
  //       } else {
  //         popupController.confirm('이미 리소스 제한 설정이 되어있는 사용자입니다. 개별 사용자의 리소스 제한 버튼을 클릭하여 리소스 사용 제한 설정이 가능합니다.') //☆문구확인필요 및 추후 처리 확인필요//
  //       }
  //     }
  //   }
  // }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'resetPw':
        try {
          await user.resetPassword(e.payload.key)
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${e.payload.key.name}(ID: ${e.payload.key.userId}) 님의 비밀번호가 초기화되었습니다.` }
          window.dispatchEvent(evt)
          getData()
        } catch (error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 900b33')
        }
        break
      case 'permitted':
        try {
          await user.updateUser(e.payload.key.userNo, true)
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${e.payload.key.name}(ID: ${e.payload.key.userId}) 님의 계정이 승인되었습니다.` }
          window.dispatchEvent(evt)
          getData()
        } catch (error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - dd8070')
        }
        break
      case 'deleteUser':
        removeUserHandler(e.payload.key)
        break
      case 'recoverDelete':
        removeUserHandler(e.payload.key)
        break
      case 'recover':
        navigate('/workspace')
        break
      case 'resetDormancy':
        try {
          await user.resetDormancy(e.payload.key.userNo)
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${e.payload.key.name}(ID: ${e.payload.key.userId}) 님의 계정이 휴면 해제되었습니다.` }
          window.dispatchEvent(evt)
          getData()
        } catch (error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.\n에러코드 - fbffe9')
        }
        break
      case 'resourceLimit':
        try {
          let evt: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
          evt.payload = { message: `${e.payload.name}(ID: ${e.payload.userId}) 님의 리소스 제한 설정이 적용되었습니다.` }
          window.dispatchEvent(evt)
          getData()
        } catch (error) {
          console.log(error)
          popupController.confirm('에러가 발생했습니다.')
        }
    }
  }

  const removeUserHandler = async (targetDataArray:any) => {
    try {
      const deleteItems = targetDataArray.targetNoArray
      const removeResponse = await user.deleteUser(deleteItems)
      if (removeResponse === null) {
        let message: string = ''
        if (deleteItems.length > 1) {
          message = `${targetDataArray.name} 님 외 ${deleteItems.length - 1}개 계정이 삭제되었습니다.`
        } else {
          message = `${targetDataArray.name} 님의 계정이 삭제되었습니다.`
        }
        let toastEvent: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
        toastEvent.payload = { message: message }
        window.dispatchEvent(toastEvent)
      }
      setState({
        ...state,
        deleteItems: []
      })
      tableRef.current?.setSelected([])
      navigateToPage(state.page)
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 3d9955')
    }
  }

  return (
    <UserListFragment>
      <div className='pageTitle' style={{display:'flex', gap:'16px'}}>
        <div>사용자 계정 관리</div>
        <div className='dividerVertical'></div>
        <div className='count'><span>{state.totalArticle}</span>명</div>
      </div>
      <div style={{display:'flex', alignItems:'start', justifyContent:'space-between', marginBottom:'12px'}}>
        <div style={{display:'flex', gap:'12px', width:'1000px' }}></div>
        <div>
          <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_DEFAULT} onClickButton={removeUserConfirm} disabled={tableRef.current?.selected.length === 0}>선택 항목 삭제</Button>
        </div>
      </div>
      <Table ref={tableRef}
              colWidth={tableColWidth} 
              headerLabel={headerLabel} 
              items={state.tableItems} 
              noData="등록된 사용자가 없습니다."
              checkBoxType={CHECKBOX_TYPE.USE_ALL} 
              onUpdate={onUpdateStatus}
              onSelect={selectBoxCheckHandler}
              defaultSortOrder={SORT_ORDER.ASC}/>
      { state.totalArticle > 0 ?
        <Pagenation defaultPage={state.page} totalArticle={state.totalArticle} perPage={50} onChange={(newPage:number) => navigateToPage(newPage)} />  : false 
      }
    </UserListFragment>
  )
}

const UserListFragment = styled.div`
  .pageTitle{font-size:24px; line-height:28.8px; font-weight:700; color:#1A1A1A; margin-bottom:49.5px;}
  .pageTitle .dividerVertical{border-left:1px solid #D5D5DA; height:20px;}
  .pageTitle .count{font-size:18px; line-height:21.6px; font-weight:400; color:#878791;}
  .pageTitle .count span{font-weight:700; color:#4B82FF;}

  table td { text-align:center; }
  table td.col0, 
  table td.col1, 
  table td.col3 { text-align:left; }
  table td.col1 .flexbox, 
  table td.col3 .flexbox { justify-content:left; padding:15px 0 }
  .userListBtn { width:60px; height:24px; font-size:12px; border:1px solid #000; border-radius:2px; }
  .userListBtn.off { color:#a7a7a7; border-color:#a7a7a7; pointer-events:none; }
`

export default UserList
