import React, { Component } from 'react'
import styled from 'styled-components'

const NotFound = () => {
  return (
    <NotFoundFragment>
      <div className='messageBox'>
        <img src='/images/errorHeaderImage.png' alt='an error occurred' />
        <div className='textBox'>
          <h2>⚠︎ 404 에러</h2>
          <p>이 페이지를 표시할 수 없습니다.</p>
          <p className='copyright'><img src='/images/logoGraySmall.png' alt='AI PUB' /></p>
        </div>
      </div>
    </NotFoundFragment>
    )
}

const NotFoundFragment = styled.div`
  overflow:auto;height:100vh;display:flex;align-items:center;justify-content:center;
  .messageBox {padding:28px;}
  .textBox {text-align:center;margin-top:32px;line-height:1.67}
  .textBox h2 {color:#ffb422}
  p.copyright { margin-top:59px; }
`

export default NotFound