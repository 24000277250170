import React,{ Component } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavigationDevAdmin = () => {
  return(
    <NavigationFragment>
      <h1><NavLink to="/monitoring"><img src="/images/logoAdmin.png" alt='AIPub' /></NavLink></h1>
      <nav>
        <h2 className="hide">관리자 메뉴</h2>
        <NavLink to="/alert"><img src="/images/nav-admin-alert.png" alt="" />통합 알림 센터</NavLink>
        <NavLink to="/monitoring"><img src="/images/nav-admin-monitoring.png" alt="" />GPU 및 서버 모니터링</NavLink>
        {/* <NavLink to="/gpumonitoring"><img src="/images/nav-admin-gpumonitoring.png" alt=""/>서버별 GPU 가동률</NavLink> */}
        <NavLink to="/images"><img src="/images/nav-admin-image-smile.png" alt="" />이미지 관리</NavLink>
        <NavLink to="/workspace"><img src="/images/nav-admin-ws.png" alt="" />워크스페이스 목록</NavLink>
        <NavLink to="/queue"><img src="/images/nav-admin-queue.png" alt="" />대기열 우선 순위 관리</NavLink>
        <NavLink to="/user"><img src="/images/nav-admin-user.png" alt="" />사용자 계정 관리</NavLink>
        <NavLink to="/rgroups/admin"><img src="/images/nav-admin-group-managing.png" alt="" />리소스 그룹 관리</NavLink>
        <NavLink to="/billing"><img src="/images/nav-admin-billing.png" alt="" />사용 내역 관리</NavLink>
      </nav>
      {/* <div className="outlink">
        <h3>Powered by</h3>
        <a href="https://jupyter.org/" target="_blank" rel="noreferrer"><img src="/images/jupiter-w.png" alt="Jupyter Notebook icon" />Jupyter Notebook</a>
        <a href="https://tensorboard.dev/" target="_blank" rel="noreferrer"><img src="/images/ten-w.png" alt="Tensorboard icon" />Tensorboard</a>
      </div> */}
      {/* <div className="outlink">
        <a href="/Ten_AI Pub_서비스 매뉴얼_1.1_관리자용.pdf" download={"Ten_AI Pub_서비스 매뉴얼_1.1_관리자용.pdf"}><img src="/images/nav-admin-menual-download.png" alt="Menual Download icon" />매뉴얼 다운로드</a>
      </div> */}
    </NavigationFragment>
  )  
}

const NavigationFragment = styled.aside`
  flex:none; width:234px; background:#4e4e4e;
  h1 {height:60px;background:#4e4e4e;font-size:0;}
  h1 img {margin:17px 30px;width:84px;}
  nav a {display:flex;align-items:center;gap:10px;height:40px;margin-top:15px;padding:0 0 0 33px;color:#fff;font-weight:bold;transition:all 0.3s;font-size:14px}
  nav a:hover {background:rgba(255, 255, 255, 0.05);}
  nav a.active {background:rgba(44, 120, 255, 0.25);}
  nav .depth p {margin-top:15px;padding:0 30px;line-height:40px;color:#868686;font-weight:bold;}
  nav .depth a {margin-top:0;}
  nav a img {width:13px;}

  div.outlink {margin:30px;padding:10px;border:1px dashed #fff;font-size:12px;color:#dfdfdf;}
  div.outlink h3 {margin-bottom:8px;font-weight:normal;font-size:12px;}
  div.outlink a {display:block;color:#dfdfdf;}
  div.outlink a+a {margin-top:5px;}
  div.outlink a img {display: inline-block;height:11px;margin-right:5px;vertical-align:middle;}
`

export default NavigationDevAdmin