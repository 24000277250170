import { atom } from "recoil"
//import { recoilPersist } from 'recoil-persist'

// const { persistAtom } = recoilPersist({
//   key: 'tenAuthInfo',
//   storage: localStorage
// })

export interface IUserInfo {
  userNo:number
  userId:string
  pw:string|null
  name:string
  phoneNumber:string
  email:string
  department:string
  additionalInfo:string
  permissionStatus:number|null
  creationTimestamp:number|null
  passwordInitialized?:boolean
  isDormacy?:boolean
  isPasswordExpired?:boolean
  
  resourceLimit?:any|null
  wsCount?:number|null
  startTimestamp?:number
}

// export const loginState = atom<IUserInfo|null>({
//   key: 'loginState',
//   default: null,
//   effects_UNSTABLE: [persistAtom]
// })

export const userInfoState = atom<IUserInfo|null>({
  key: 'userInfoState',
  default: {
    creationTimestamp: null,
    department: '',
    phoneNumber: '',
    userId: '',
    email: '',
    name: '',
    userNo: -1,
    permissionStatus: null,
    pw: null,
    additionalInfo:'',
    passwordInitialized:false,
    isDormacy:false,
    isPasswordExpired:false
  }
})