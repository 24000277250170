import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import NavigationAdmin from './layout/NavigationDevAdmin'
import NavigationDev from './layout/NavigationDev'
import { useRecoilState } from 'recoil'
import { IUserInfo, userInfoState } from '../states/userInfoState'
import { User } from '../model/User'
import { IUserAuthInfo, authState } from '../states/authStates'
import ApiController from '../controller/ApiController'
import ApiEvent from '../events/ApiEvent'
import ToastEvent from '../events/ToastEvent'
import PageEvent from '../events/PageEvent'
import WebSocketEvent from '../events/WebSocketEvent'
import PopupController from '../controller/PopupController'
import WebSocketController from '../controller/WebSocketController'
import BillingAPI from "../model/Billing"
import Select from './components/ui/Select'
import ISelectOption from '../interfaces/SelectOption'
import { ISettings, settingState } from '../states/settingState'
import SchedulerEvent from '../events/SchedulerEvent'
import { Priority } from '../model/Priority'
import ModalEvent from '../events/ModalEvent'

import lottie from 'lottie-web'
import { ALERT_ORDER_BY, ALERT_TYPE, Alert } from '../model/Alert'
import { SORT_ORDER } from '../model/BaseDataType'
import ModalMessage from './components/ModalMessage'
import Modal from './components/Modal'
import utils from '../utils'
import Config from '../Config'

interface ILayoutProps {
  // location:Location
}

interface ILayoutState {
  alertList:any[] //IAlertListItem[]
  alertPaging: {
    hasMore:boolean
    page:number
    total:number
  }
}

const Layout = (props:ILayoutProps) => {
  const [ authInfo, _setAuthInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const authInfoRef = useRef(authInfo)
  const setAuthInfo = (data:any) => {
    authInfoRef.current = data
    _setAuthInfo(data)
  }
  const [ userInfo, setUserInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  const [ settingInfo, setSettingInfo ] = useRecoilState<ISettings|null>(settingState)
  const settingInfoRef = useRef(settingInfo)

  const navigate = useNavigate()
  const user = User()
  const popupController = PopupController.getInstance()
  const apiController = ApiController.getInstance()
  const webSocketController = WebSocketController.getInstance()

  // const [ intervalTimer, setIntervalTimer ] = useState<any>(null) // 5초 새로고침 숨김처리
  const [ loadingFlag, setLoadingFlag ] = useState<boolean>(false)
  const [ refreshButtonShowFlag, setRefreshButtonShowFlag ] = useState<boolean>(false)
  const [ dropAlert, setDropAlert ] = useState<boolean>(false)
  const [ alertCount, setAlertCount ] = useState<number>(0)
  const [ dropMy, setDropMy ] = useState<boolean>(false)
  // const [ page, setPage ] = useState<number>(1)
  const [ loadingCount, _setLoadingCount ] = useState<number>(0)
  const loadingCountRef = useRef(loadingCount)
  const setLoadingCount = (data:any) => {
    loadingCountRef.current = data
    _setLoadingCount(data)
  }
  const [ state, _setState ] = useState<ILayoutState>({
    alertList: [],
    alertPaging: {
      page: 1,
      hasMore: true,
      total: -1
    }
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const ENDPOINT = Config.env.REACT_APP_SOCKET_PROTOCOL+"://"+(Config.env.REACT_APP_LDAP === 'true' ? Config.env.REACT_APP_LDAP_SOCKET_URL : Config.env.REACT_APP_SOCKET_URL)+"/ws/"+authInfo?.id+(authInfo?.isAdmin ? "?admin=true" : "")

  const autoRefreshOptions:ISelectOption[] = [
    { label: 'Off', value: 'OFF' },
    { label: '5sec', value: 'ON' }
  ]

  const dropAlertBtnRef = useRef<any>()
  const dropAlertRef = useRef<any>()
  const dropMyBtnRef = useRef<any>()
  const dropMyRef = useRef<any>()
  const loadingAnimationRef = useRef<any>()

  let dmsTimeoutId:NodeJS.Timeout

  useEffect(() => {
    // webSocketController.open(Config.env.REACT_APP_SOCKET_PROTOCOL+"://"+Config.env.REACT_APP_BASE_URL+":"+Config.env.REACT_APP_SOCKET_PORT+"/ws/"+authInfo?.id)
    webSocketController.open(ENDPOINT)

    webSocketController.addEventListener(WebSocketEvent.WS_TOAST, toastEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_CONFIRM, confirmEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_CONFIRM_CUSTOM, confirmCustomEventControl)
    webSocketController.addEventListener(WebSocketEvent.WS_DISCONNECTED, websocketReconnectHandler)
    webSocketController.addEventListener(WebSocketEvent.WS_EVENT, websocketEventHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    apiController.addEventListener(ApiEvent.LOAD_START, pageLoadStartHandler)
    apiController.addEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)
    apiController.addEventListener(ApiEvent.AUTH_EXPIRED, authExpiredHandler)
    window.addEventListener('callgetmyinfo', getMyInfo)
    window.addEventListener('refreshalarm', updateAlertCounter)    
    window.addEventListener(SchedulerEvent.START, schedulerHandler)
    window.addEventListener(SchedulerEvent.STOP, schedulerHandler)
    window.addEventListener(SchedulerEvent.UPDATE, schedulerHandler)
    // window.addEventListener(PageEvent.SHOW_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
    // window.addEventListener(PageEvent.HIDE_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
    // window.addEventListener(PageEvent.STOP_REFRESH, stopRefreshHandler) //5초 새로고침 숨김처리
    document.addEventListener('mousedown', resetDmsHandler)
    resetDmsHandler()

    schedulerHandler(new SchedulerEvent(SchedulerEvent.UPDATE))

    if (loadingAnimationRef.current !== null) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData:require('../assets/loader.json')
      })
    }

    updateAlertCounter()
    // setIntervalTimer(setInterval(autoRefreshHandler, 5000)) // 5초 새로고침 숨김처리
    // window.dispatchEvent(new PageEvent(PageEvent.LAYOUT_INIT_FINISHED)) // 5초 새로고침 숨김처리

    return () => {
      webSocketController.removeEventListener(WebSocketEvent.WS_TOAST, toastEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_CONFIRM, confirmEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_CONFIRM_CUSTOM, confirmCustomEventControl)
      webSocketController.removeEventListener(WebSocketEvent.WS_DISCONNECTED, websocketReconnectHandler)
      webSocketController.removeEventListener(WebSocketEvent.WS_EVENT, websocketEventHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      apiController.removeEventListener(ApiEvent.LOAD_START, pageLoadStartHandler)
      apiController.removeEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)
      apiController.removeEventListener(ApiEvent.AUTH_EXPIRED, authExpiredHandler)
      window.removeEventListener('callgetmyinfo', getMyInfo)
      window.removeEventListener('refreshalarm', updateAlertCounter)
      window.removeEventListener(SchedulerEvent.START, schedulerHandler)
      window.removeEventListener(SchedulerEvent.STOP, schedulerHandler)
      window.removeEventListener(SchedulerEvent.UPDATE, schedulerHandler)
      // window.removeEventListener(PageEvent.SHOW_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
      // window.removeEventListener(PageEvent.HIDE_REFRESH, refreshButtonShowHandler) // 임시로 5초 새로고침 숨김처리 (UI)
      // window.removeEventListener(PageEvent.STOP_REFRESH, stopRefreshHandler) // 5초 새로고침 숨김처리
      // if (intervalTimer) setIntervalTimer(clearInterval(intervalTimer)) // 5초 새로고침 숨김처리
      document.removeEventListener('mousedown', resetDmsHandler)
    }
  }, [])

  useEffect(() => {
    if (authInfo?.userNo !== -1 && userInfo?.userNo === -1) {
      getMyInfo()
    }
    if (authInfo?.userNo !== -1 && !authInfo?.isAdmin && authInfo?.passwordInitialized === true || authInfo?.isPasswordExpired === true) {
      // 비밀번호 변경 화면으로 이동
      navigate('/mypage')
    }
  }, [authInfo])

  useEffect(() => {
    settingInfoRef.current = settingInfo
  }, [settingInfo])

  useEffect(() => { // 알림 버튼 껐다가 다시 켤 때, 초기화가 완전히 된 후 list 불러오도록 하기 위함!
    if (state.alertList.length === 0 && state.alertPaging.page === 1 && state.alertPaging.total !== 0 && dropAlert) {
      getAlertList()
    }
  }, [state.alertList])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [dropMy, dropAlert])

  useEffect(() => {
    if (dropMy) {
      window.dispatchEvent(new Event('callgetmyinfo'))
    }
  }, [dropMy])
  
  const resetDmsHandler = () => {
    clearTimeout(dmsTimeoutId)
    // dmsTimeoutId = setTimeout(logoutHandler, 1800000) // 30m
    dmsTimeoutId = setTimeout(() => {
      popupController.confirmCustom('장시간 사용하지 않아 보안을 위해 자동 로그아웃 되었습니다. 다시 로그인해 주세요.', 'logoutnoaction', 'logoutnoaction')
    }, 1800000) // 30m
  }

  const authExpiredHandler = () => {
    popupController.confirmCustom('일시적인 오류로 자동 로그아웃 되었습니다. 다시 로그인해 주세요.', 'logoutauthexpired', 'logoutauthexpired')
  }

  const clickOutsideHandler = (event:any) => {
    if (!dropMyBtnRef.current?.contains(event.target)
      && !dropMyRef.current?.contains(event.target) && dropMy) {
      setDropMy(false)
    }
    if (!dropAlertBtnRef.current?.contains(event.target)
      && !dropAlertRef.current?.contains(event.target) && dropAlert) {
      setDropAlert(false)
    }
  }

  const toastEventControl = (e:WebSocketEvent) => {
    let toastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
    toastEvent.payload = { message: e.payload.message }
    window.dispatchEvent(toastEvent)
  }

  const confirmEventControl = (e:WebSocketEvent) => {
    popupController.confirm(e.payload.message)
  }

  const confirmCustomEventControl = (e:WebSocketEvent) => {
    popupController.confirmCustom(e.payload.message, e.payload.action, e.payload.key)
  }

  const websocketReconnectHandler = () => {
    console.log('ws reconnecting...')
    webSocketController.open(ENDPOINT)
  }

  const websocketEventHandler = (e:WebSocketEvent) => {
    switch(e.payload.action) {
      case 'multipleaccessrestriction':
        if (authInfo?.multipleAccessRestriction !== e.payload.message) {
          popupController.confirmCustom('다른 기기에서 동일한 아이디로 로그인하여 보안을 위해 자동 로그아웃 되었습니다. \n본인이 아닌 경우, 비밀번호를 변경해 주세요.', 'logoutmultipleaccessuser', 'logoutmultipleaccessuser')
        }
        break
      case 'resourceLimitreload':
        popupController.confirm(e.payload.message)
        getMyInfo()
        break
      default:
        break
    }
  }

  const schedulerHandler = async (e:SchedulerEvent) => {
    let priorityStatus
    switch(e.type) {
      case SchedulerEvent.START:
        priorityStatus = true
        break
      case SchedulerEvent.STOP:
        priorityStatus = false
        break
      case SchedulerEvent.UPDATE:
        try {
          priorityStatus = await Priority.getPriorityStatus()
        } catch(error) {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 30e343')
        }
        break
    }

    if (priorityStatus !== undefined) {
      setSettingInfo({
        autoRefreshFlag: settingInfo?.autoRefreshFlag || false,
        loadingMaskFlag: settingInfo?.loadingMaskFlag || true,
        schedulerFlag: priorityStatus
      })
      let e:SchedulerEvent = new SchedulerEvent(SchedulerEvent.UPDATED)
      e.payload = {schedulerFlag: priorityStatus}
      window.dispatchEvent(e)
      //window.dispatchEvent(new SchedulerEvent(SchedulerEvent.UPDATED), {priorityStatus: priorityStatus})
    }
  }

  const logoutHandler = ():void => {
    setDropMy(false)
    user.logout(false/* authInfo?.isOps */)
  }

  // my info
  const getMyInfo = async () => {
    if (authInfoRef.current?.userNo === -1) return
    try {
      const response:any = await user.getMyInfo(authInfoRef.current?.userNo || -1)
      let startTimestamp
      try {
        startTimestamp = await BillingAPI.getStartTimestamp()
      } catch(error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - e405d3')
      }
      if(response) {
        setUserInfo({
          creationTimestamp: null,
          department: response.department !== null ? response.department : '',
          phoneNumber: response.phoneNumber !== null ? response.phoneNumber : '',
          userId: response.userId,
          email: response.email,
          name: response.name,
          userNo: response.userNo,
          permissionStatus: response.permissionStatus !== null ? response.permissionStatus : '',
          pw: null,
          resourceLimit: response.resourceLimit !== null ? response.resourceLimit : null,
          wsCount: response.wsCount !== null ? response.wsCount : null,
          startTimestamp: startTimestamp,
          additionalInfo: response.additionalInfo,
          passwordInitialized: response.passwordInitialized,
          isDormacy: response.isDormacy,
          isPasswordExpired:response.isPasswordExpired
        })
  
        // 관리자 로그인 시, 필수 입력 정보 없는 경우
        if (authInfoRef.current?.isAdmin && (response.name === '' || response.email === '') && window.location.pathname !== '/mypage' && authInfoRef.current?.lackOfAdminUserInfo){
          popupController.dialouge('시스템 이상 알림을 받기 위해 관리자의 이메일 등록이 필요합니다. 진행하시겠습니까?', 'gotomypage', 'gotomypage', '확인', '다음에 하기')
          setAuthInfo({
            ...authInfoRef.current,
            lackOfAdminUserInfo: false
          })
        }  
      }
    } catch (error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6600cb')
    }
  }

  // alert
  const updateAlertCounter = async ():Promise<void> => {
    try {
      let alertInfo = await Alert.getAlertCount()
      if (alertInfo) {
        setAlertCount(alertInfo.numNewAlert)
      }  
    }
    catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 8c67c5')
    }
  }

  const loadMore = () => {
    // setState({
    //   ...state, 
    //   alertPaging: {
    //     ...state.alertPaging,
    //     page: state.alertPaging.page + 1
    //   }
    // })
    // updateAlertCounter()
    getAlertList(stateRef.current.alertPaging.page+1)
  }

  const getAlertList = async (page?:number):Promise<void> => {
    const to = Math.floor(new Date().getTime()/1000)
    const from = to-2630000
    const currentPage = page ? page : stateRef.current.alertPaging.page
    let data
    if (authInfo?.isAdmin) {
      try {
        data = await Alert.getListAdmin(ALERT_ORDER_BY.DATETIME, SORT_ORDER.DESC, ALERT_TYPE.ALL, currentPage, 50)
      } catch (error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - ecde69')
      }
    } else {
      try {
        data = await Alert.getList(authInfo?.userNo || -1, from, to, currentPage, 50, ALERT_ORDER_BY.DATETIME, SORT_ORDER.DESC)
      } catch (error) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 6b380c')
      }
    }

    if (data) {
      let newList = state.alertList.concat(data.alertList)
      let total = authInfo?.isAdmin ? data.totalCount : data.totalCount
      // let newCount = 0
      // for (let i = 0; i < newList.length; i++) {
      //   if (!newList[i].isRead) newCount = newCount + 1
      // }
      setState({
        ...state,
        alertList: newList,
        alertPaging: {
          hasMore: (newList.length !== total),
          page: currentPage,
          total: total
        }
      })
      setLoadingFlag(false)
      updateAlertCounter()
    }
  }

  const pageLoadStartHandler = ():void => {
    if (loadingCountRef.current === 0){
      setLoadingFlag(true)
    }
    setLoadingCount(loadingCountRef.current+1)
  }

  const pageLoadEndHandler = ():void => {
    if (loadingCountRef.current-1 === 0){
      setLoadingFlag(false)
    }
    setLoadingCount(Math.max(loadingCountRef.current-1, 0))
  }

  const movetoMypage = () => {
    setDropMy(false)

    if (window.location.pathname === '/mypage') {
      let e = new PageEvent(PageEvent.REFRESH_PAGE_MYPAGE)
      window.dispatchEvent(e)
      // window.location.reload()
    } else {
      sessionStorage.setItem('url',window.location.pathname)
      navigate('/mypage')
    }
  }

  // auto refresh
  const autoRefreshHandler = async () => {
    if (settingInfoRef.current?.autoRefreshFlag === true) {
      let event:PageEvent = new PageEvent(PageEvent.REFRESH)
      window.dispatchEvent(event)
    }
  }

  const refreshButtonShowHandler = (e:PageEvent) => {
    switch(e.type) {
      case PageEvent.SHOW_REFRESH:
        setRefreshButtonShowFlag(true)
        break
      case PageEvent.HIDE_REFRESH:
        setRefreshButtonShowFlag(false)
        break
    }
  }

  const refreshToggleHandler = async (e?:any, value?:boolean):Promise<void> => {
    setSettingInfo({
      autoRefreshFlag: value !== undefined ? value : !settingInfo?.autoRefreshFlag,
      loadingMaskFlag: settingInfo?.loadingMaskFlag || true,
      schedulerFlag: settingInfo?.schedulerFlag || false
    })
  }

  const stopRefreshHandler = ():void => {
    refreshToggleHandler('', false)
  }

  const refreshHandler = async () => {
    window.location.reload()
  }

  const modalActionHandler = async (e:ModalEvent) => {
      switch (e.payload.action) {
        case 'startPriority':
          try {
            await Priority.startPriority()
          } catch(error) {
            popupController.confirm('에러가 발생했습니다.\n에러코드 - 2af68c')
          }
          break
        case 'stopPriority':
          try {
            await Priority.stopPriority()
          } catch(error) {
            popupController.confirm('에러가 발생했습니다.\n에러코드 - 2c63a0')
          }
          break
        case 'logoutdeleteuser':
        case 'logoutnoaction':
        case 'logoutauthexpired':
        case 'logoutmultipleaccessuser':
          logoutHandler()
          break
        case 'gotomypage':
          movetoMypage()
          break
      }
  }

  return(
    <LayoutFragment>
      <ModalMessage />
      <Modal />
      <section className={'loadingWrap ' + (loadingFlag === true ? '' : 'off')}>
        <div className='animation' ref={loadingAnimationRef}></div>
      </section>
      {authInfo?.isAdmin ? <NavigationAdmin /> : <NavigationDev/>}
      <main>
        <header className="top">
          <h2 className="hide">AiPub</h2>
          
          { authInfo?.isAdmin === true ? <div className="progrss">
              { settingInfo?.schedulerFlag ? 
                <button onClick={() => { 
                  popupController.dialouge('스케줄러 작동을 중지합니다. 스케줄러 중지 시 리소스 할당과 워크스페이스 생성이 불가능합니다. 진행하시겠습니까?', 
                  'stopPriority', 'stopPriority', '확인', '취소')
                }} className="btn low redOutline">스케줄러 멈춤</button> :
                <button onClick={() => { 
                  popupController.dialouge('스케줄러 작동을 재개합니다. 진행하시겠습니까?', 
                  'startPriority', 'startPriority', '확인', '취소')
                }} className="btn low red">스케줄러 재개</button>
              }
            </div> : <div className="progrss"></div> }

          <div className="btnWrap">
            {/* 리프레시 버튼 */
              refreshButtonShowFlag ? <>
              <button className="btnIco refresh" onClick={refreshHandler}><img src="/images/2.png" alt="+"/>refresh</button>
              <div className="inputWrap selectWrap timerSelect">
                <Select option={autoRefreshOptions} // empty="" 
                  selected={autoRefreshOptions[settingInfo?.autoRefreshFlag === true ? 1 : 0]} 
                  onChange={(selectedItem:ISelectOption) => { 
                    if (selectedItem.value === 'ON') {
                      refreshToggleHandler('', true)
                    } else {
                      refreshToggleHandler('', false)
                    }
                  }} />
              </div>
              </> : false
            }


            {/* 알람 버튼 및 팝업 */}
            <div className="subWrap">
              <button className="btnIco" ref={dropAlertBtnRef} onClick={() => {
                if (dropAlert === false) {
                  setState({
                    ...state,
                    alertList: [],
                    alertPaging: {
                      ...state.alertPaging,
                      page: 1
                    }
                  })
                  // getAlertList() useEffect에서 대신
                }
                setDropAlert(!dropAlert)
              }}>
                <img src="/images/3.png" alt="+" />
                alert
                {alertCount > 0 ? <span className="count">{alertCount > 99 ? '99+' : alertCount}</span> : false}
              </button>
              {dropAlert ?
                <div className="drop2" ref={dropAlertRef} id="alertWrap">
                  <button className="btnClose" onClick={() => setDropAlert(false)}>
                    <img src="/images/ico-close2.png" alt="x" />
                  </button>
                  <div className="dropHead">
                    <h3>알림</h3>
                    <Link className="btn outline" to="/alert" onClick={() => setDropAlert(false)}>알림 전체보기</Link>
                  </div>
                  {
                    state.alertList.length > 0 ?
                      <InfiniteScroll className="listWrap"
                        scrollableTarget="alertWrap"
                        dataLength={state.alertList.length}
                        next={loadMore}
                        hasMore={state.alertPaging.hasMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                          <p className="endMsg">모든 알림을 불러왔습니다.</p>
                        } >
                        {
                          state.alertList.map((alert, idx) => (
                            <div key={idx} className={alert.isRead === true ? 'alert read' : 'alert'}>
                              {
                                idx === 0 ?
                                  <div className="date"><h4>{dayjs.unix(Number(state.alertList[0].timestamp)).format('YYYY.MM.DD')}</h4></div> :
                                  dayjs.unix(Number(state.alertList[idx - 1].timestamp)).format('YYYY.MM.DD') !== dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD') ?
                                    <div className="date"><h4>{dayjs.unix(Number(alert.timestamp)).format('YYYY.MM.DD')}</h4></div> : false
                              }
                              <div className="msg">
                                {/* <Link to="/alert"> */}
                                <a>
                                  <div>
                                    <i className={`status status${alert.level}`}></i>
                                    <div>
                                      <p className="text">{alert.msg}</p>
                                      <p className="datetime">{dayjs.unix(Number(alert.timestamp)).format('HH:mm:ss')}</p>
                                    </div>
                                  </div>
                                  {/* <i className="arrow"></i> */}
                                  {/* </Link> */}
                                </a>
                              </div>
                            </div>
                          ))
                        }
                      </InfiniteScroll>
                      : <div className="noDataAlert" /* style={{fontSize:'14px', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', margin:'-30px 0'}} */>알림이 없습니다.</div>

                  }
                  {/* <p className="toast">읽지 않은 알림 <img src="/images/counter-w.png" alt="down arrow"/></p> */}
                </div> : false}
            </div>

            
            { // 마이메뉴
              authInfo?.isAdmin ?
                // 어드민
                <div className="subWrap">
                  <button className="btnIco" ref={dropMyBtnRef} onClick={(): void => { setDropMy(!dropMy) }}>
                    <img src="/images/4.png" alt="+" />
                  </button>
                  {dropMy ?
                    <div className="drop" ref={dropMyRef}>
                      <dl className="inner">
                        <dt>관리자 아이디</dt>
                        <dd>{authInfo?.id}</dd>
                      </dl>
                      <div className="inner">
                      <button onClick={movetoMypage}><img src="/images/logout.png" alt="logout" />{ Config.env.REACT_APP_LDAP ==='true' ? '관리자 정보' : '관리자 정보 변경' }</button>
                        <button onClick={logoutHandler}><img src="/images/logout.png" alt="logout" />로그아웃</button>
                      </div>
                    </div> : false}
                </div> :
                
                // 사용자
                <div className="subWrap">
                  <button className="btnIco" ref={dropMyBtnRef} onClick={(): void => { setDropMy(!dropMy) }}>
                    <img src="/images/4.png" alt="+" />
                  </button>
                  {dropMy ?
                    <div className="drop" ref={dropMyRef}>
                      <dl className="inner">
                        <dt>사용자 아이디</dt>
                        <dd>{authInfo?.id}</dd>
                      </dl>

                      <dl className="inner">
                        <dt>총 사용 가능 GPU 리소스 블록 개수</dt>
                        {userInfo?.resourceLimit.isGpuResourceBlocksEnabled ? <dd> ㄴ 총 {utils.numberMark(userInfo?.resourceLimit.totalGpuResourceBlocks || 0)}개 중 {utils.numberMark(userInfo?.resourceLimit.usedGpuResourceBlocks || 0)}개 사용</dd> : <dd className="disabled">ㄴ 설정 없음</dd>}
                        <dt className="innerInterval">워크스페이스 자동 회수 시간</dt>
                        {userInfo?.resourceLimit.isWorkspaceAutoReclaimEnabled ? <dd> ㄴ 생성시간 기준 {utils.numberMark(userInfo?.resourceLimit.workspaceAutoReclaimHours || 0)}시간 후 자동 회수</dd> : <dd className="disabled">ㄴ 설정 없음</dd>}
                        <dt className="innerInterval">워크스페이스 신청 시 대기열 포함 여부</dt>
                        {userInfo?.resourceLimit.isAdminApproval ? <dd> ㄴ 관리자 확인 후 대기열에 포함</dd> : <dd className="disabled"> ㄴ 자동 대기열 포함</dd>}
                      </dl>

                      <div className="inner">
                        <button onClick={movetoMypage}><img src="/images/edit.png" alt="edit" />{Config.env.REACT_APP_LDAP ==='true' ? '사용자 정보' : '사용자 정보 변경'}</button>
                        <button onClick={logoutHandler}><img src="/images/logout.png" alt="logout" />로그아웃</button>
                      </div>
                    </div> : false}
                </div>
            }
          </div>
          {authInfo?.isAdmin !== true || settingInfo?.schedulerFlag ? false :
            <p className="note">&#x26A0; 스케줄러가 멈춰 있는 상태입니다. 워크스페이스 생성과 리소스 할당을 다시 작동하기 위해 스케줄러를 재개해야 합니다.</p>
          }
        </header>
        <section className="contentWrap">
          <div className="container">
            <Outlet />
          </div>
        </section>
      </main>
    </LayoutFragment>
  )
}

const LayoutFragment = styled.div`
position:relative; display:flex; min-height:100vh; z-index: 1;
main {overflow:auto; flex:1;}

header.top{position:relative;display:flex;justify-content:space-between;align-items:center;height:50px;padding:0 0 0 34px;z-index:997;border-bottom:1px solid #ebebeb;}
header.top .progrss{display:flex;align-items:center;gap:8px;color:#f30b0b;font-weight:bold;}
header.top #circle{position:relative;}
header.top .progress-ring__circle{transition:0.35s stroke-dashoffset;transform:rotate(-90deg);transform-origin:50% 50%;}
header.top .progrss .count{position:absolute;top:4px;left:4px;width:26px;height:26px;border:3px solid #e8e8e8;border-radius:50%;text-align:center;font-size:13px;line-height:26px;z-index:-1;}
header.top .btnWrap .btnIco{position:relative;display:inline-block;width:50px;height:50px;transition:all 0.3s;cursor:pointer; font-size:0;vertical-align:top;}
header.top .note{position:absolute;left:0;right:0;bottom:-22px;padding:2px 30px 0;background:#FF4622;font-size:11px;color:#fff;line-height:20px;}
header.top .btnWrap .btnIco:hover{background:rgba(0, 0, 0, 0.05);}
header.top .btnWrap .btnIco img{display:block;width: 20px;margin:15px auto;}
header.top .btnWrap .btnIco.refresh { width:29px;height:29px;border:1px solid #ccc;border-right:0;border-radius:2px 0 0 2px;margin-top:10px }
header.top .btnWrap .btnIco.refresh img { margin:0 8px;width:15px }
header.top .count{position:absolute;top:10px;left:30px;padding:2px 3px;border-radius:8px;min-width:9px;background:#f30b0b;color:#fff;font-size:10px;}
header.top .subWrap{position:relative;display:inline-block;}
header.top .drop{position:absolute;top:55px;right:10px;width:280px;background:#fff;border:1px solid #ebebeb;z-index:1;font-size:14px;box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.1);}
header.top .drop:before,
header.top .drop:after{content:'';position:absolute;width:0;height:0;border-bottom:10px solid;}
header.top .drop:before{border-bottom-color:#fff;top:-8px;right:15px;border-left:5px solid transparent;border-right:5px solid transparent;z-index:1;}
header.top .drop:after{border-bottom-color:#ebebeb;top:-10px;right:13px;border-left:7px solid transparent;border-right:7px solid transparent;z-index:0;}
header.top .drop .inner{padding: 16px 30px;}
header.top .drop .inner .innerInterval{padding-top: 10px;}
header.top .drop dl.inner{border-bottom:1px solid #ebebeb;}
header.top .drop dd{margin-top:7px;font-weight:bold;}
header.top .drop button,
header.top .drop a{display:block;height:32px;line-height:32px;font-size:14px;color:#303030;text-align:left;}
header.top .drop button img,
header.top .drop a img{display:inline-block;width:14px;margin:8px 5px 0 0;vertical-align:top;}

header.top .drop2{overflow:auto;position:fixed;top:51px;right:0;bottom:0;width: 344px;padding:20px 25px;box-sizing:border-box;box-shadow: 0 4px 4px 0 rgba(16, 16, 16, 0.25);background:#fff;z-index:3;}
header.top .drop2 .btnClose{position:absolute;top:0;right:0;width:46px;height:46px;}
header.top .drop2 .btnClose img{display:block;width:16px;margin:0 auto;}
header.top .drop2 .dropHead{display:flex;align-items:center;gap: 15px;}
header.top .drop2 .dropHead h3{font-size:24px;color: #303030;}
header.top .drop2 .dropHead .btn{height:24px;line-height:24px;}
header.top .listWrap .date{margin-top:30px;}
header.top .listWrap .date h4{margin-bottom:5px;font-size:14px;color: #303030;}
header.top .listWrap .alert{border-bottom:1px solid #dfdfdf;}
header.top .listWrap a{display:flex;justify-content:space-between;align-items:center;padding: 20px 0;gap: 40px;}
header.top .listWrap a > div{display:flex;align-items:flex-start;gap:10px;}
header.top .listWrap .text{font-size:12px;font-weight:bold; color:#303030;}
header.top .listWrap .datetime{margin-top:10px;font-size:12px;color:#868686;}
header.top .listWrap .status{flex:none;width:14px;height:12px;}
header.top .listWrap .status0{background:url(/images/alert.png) no-repeat center / 100%;}
header.top .listWrap .status1{background:url(/images/alert-warn.png) no-repeat center / 100%;}
header.top .listWrap .status2{width:12px;background:url(/images/alert-error.png) no-repeat center / 100%;}
header.top .listWrap .arrow{flex:none;width:7px;height:17px;background:url(/images/arrow2.png) no-repeat center / 100%;}
header.top .listWrap .toast{position:fixed;right:108px;bottom: 70px;padding:0 15px;background:rgba(78, 78, 78, 0.8); box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);line-height:37px;color:#fff;font-size:14px;font-weight:bold;}
header.top .listWrap .toast img{display:inline-block;width:18px;vertical-align:middle;}
header.top .listWrap .endMsg{margin-top:30px;font-size:14px;color:#303030}
header.top .listWrap .alert.read .text{font-weight:normal; word-break:break-all;}

.timerSelect { display:inline-block;vertical-align:top;margin:10px 10px 0 0 }
.timerSelect .ico { display:none }
.timerSelect .dropWrap button { padding: 13px 10px }
.timerSelect p { height:27px;border-radius:0 2px 2px 0 }

// 로딩 마스크
 .loadingWrap { position:fixed;width:100%;height:100%;background-color:rgba(0,0,0,0.3);display:flex;justify-content:space-between;align-items:center;z-index:9999 }
 .loadingWrap.off { display:none }
 .loadingWrap .animation { position:relative;left:50%;display:inline-block;width:50px;height:50px;display:flex;justify-content:space-between;align-items:center; }

.contentWrap {overflow:auto; height:calc(100vh - 82px); padding-top:22px;}
.container {width:1320px; padding:50px 30px 280px; margin:0 auto;}

.noDataAlert { display:flex; justify-content:center; align-items:center; flex-direction:column; font-size:14px; color:#303030; height:100%; margin:-15px 0; color:rgb(134, 134, 134); }

.disabled { color:#A7A7A7; font-size:14px; font-weight:700; line-height:20px; }
// FORM 속성 (OPS와 통합시 index로)

`

export default Layout