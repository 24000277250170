import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import TimePicker, { TIME_PICKER_TYPE } from '.././uiv2/TimePicker'
import ModalEvent from '../../../events/ModalEvent'
import PageEvent from '../../../events/PageEvent'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import Utils from '../../../utils'
import PopupController from '../../../controller/PopupController'

dayjs.extend(isSameOrBefore)

interface IDateRangePickerProps {
  ref?:React.ForwardedRef<JSX.Element>
  single?:boolean
  changeMessageHideFlag?:boolean
  initial:{
    from:string
    to?:string
  }
  default:{
    from:string
    to:string
  }
  data:{
    from:string
    to:string
  }
  limit?:{
    from:string
    to:string
  }
  max?:{
    from:string
    to:string
  }
  onChange?:Function
  onChangeCalendar?:Function
  type?:TIME_PICKER_TYPE
}

interface IDateRangePickerState {
  default:{
    from:string
    to:string
  }
  limit?:{
    from:DayValue
    to:DayValue
  }
}

interface IDateDetailState {
  date:{
    from:DayValue
    to:DayValue
  }
  time:{
    from:string
    to:string
  }
  max:{
    from:DayValue
    to:DayValue
  }
}

interface IDateChangeFlagState {
  changeFlag:boolean
  modifiedFlag:boolean
}

const DateRangePicker = forwardRef<any, IDateRangePickerProps>((props:IDateRangePickerProps, ref) => {
  const popupController = PopupController.getInstance()

  const parseYearMonthDay = (date?:string) => {
    return {
      year: dayjs(date).year(),
      month: dayjs(date).month()+1,
      day: dayjs(date).get('date')
    }
  }
  
  const [ fromToError, setFromToError ] = useState<string>('')
  const [ state, setState ] = useState<IDateRangePickerState> ({
    default: {
      from: props.data.from,
      to: props.data.to
    }
  })

  const [ detailstate, setDetail ] = useState<IDateDetailState> ({
    date: {
      from: parseYearMonthDay(props.data.from),
      to: parseYearMonthDay(props.data.to)
    },
    time: props.data,
    max: {
      from: parseYearMonthDay(props.data.to),
      to: parseYearMonthDay()
    }
  })

  const [ flag, setFlag ] = useState<IDateChangeFlagState> ({
    changeFlag: false,
    modifiedFlag: false
  })

  const prevProps = Utils.usePrevState(props)

  useEffect(() => {
    if (props.limit) {
      setState({
        ...state,
        limit: {
          from: parseYearMonthDay(props.limit.from),
          to: parseYearMonthDay(props.limit.to)
        }
      })
    }
  }, [props.limit])

  useEffect(() => {
    // (1) default 비교
    let defaultValue = { ...state.default }
    let updateDefaultFlag = false
    
    if (prevProps.default.from !== props.default.from) {
      defaultValue.from = props.default.from
      updateDefaultFlag = true
    }
    if (prevProps.default.to !== props.default.to) {
      defaultValue.to = props.default.to
      updateDefaultFlag = true
    }

    // (2) data, limit 비교
    let date = { ...detailstate.date }
    let time = { ...detailstate.time }
    let max = { ...detailstate.max }
    let limit = state.limit
    let updateDataFlag = false

    if (prevProps.data.from !== props.data.from) {
      date.from = parseYearMonthDay(props.data.from)
      time.from = props.data.from
      updateDataFlag = true
    }
    if (prevProps.data.to !== props.data.to) {
      date.to = parseYearMonthDay(props.data.to)
      time.to = props.data.to
      max = {
        from: parseYearMonthDay(props.data.to),
        to: parseYearMonthDay()
      }
      updateDataFlag = true
    }
    if (props.limit) {
      if (prevProps.limit.from !== props.limit.from || prevProps.limit.to !== props.limit.to) {
        limit = {
          from: parseYearMonthDay(props.limit.from),
          to: parseYearMonthDay(props.limit.to)
        }
        updateDataFlag = true
      }
    }

    // 결과 반영
    if (updateDataFlag === true || updateDefaultFlag === true) {
      setDetail({
        date: date,
        time: time,
        max: max
      })
      setState({
        default: defaultValue,
        limit: limit
      })
    }
  },[props.default, props.data, props.limit])

  useImperativeHandle(ref, () => {
    return {
      modifiedFlag: flag.modifiedFlag,
      clearRange: clearRange,
      getValue: () => {
        return detailstate
      }
    }
  })
  // const getModifiedFlag = () => {
  //   return flag.modifiedFlag
  // }

  const onChangeCalendar = (target:string, date:DayValue) => {
    let picked = detailstate.date
    switch (target) {
      case 'from':
        picked.from = date
        break
      case 'to':
        picked.to = date
        break
    }
    const from = `${detailstate.date.from?.year}-${detailstate.date.from?.month}-${detailstate.date.from?.day} ${dayjs(detailstate.time.from).format('HH:mm')}`
    const to = `${detailstate.date.to?.year}-${detailstate.date.to?.month}-${detailstate.date.to?.day} ${dayjs(detailstate.time.to).format('HH:mm')}`
    validateFromTo(target)

    setDetail({
      ...detailstate,
      date: {
        from: picked.from,
        to: picked.to
      },
      time: {
        from: from,
        to: to
      }
    })
    setFlag({
      ...flag,
      changeFlag: true
    })

    if(props.onChangeCalendar) {
      props.onChangeCalendar({
        from: from,
        to: to
      })
    }
  }

  const onChangeSingleCalendar = (date:DayValue) => {
    setDetail({
      ...detailstate,
      date: {
        ...detailstate.date,
        to: date
      },
      time: {
        to: `${date?.year}-${date?.month}-${date?.day} ${dayjs(detailstate.time.to).format('HH:mm')}`,
        from: ''
      }
    })
    setFlag({
      ...flag,
      changeFlag: true
    })
  }

  const onChangeTime = (target:string, datetime:any) =>{
    let picked = detailstate.time
    switch (target) {
      case 'from':
        picked.from = datetime
        break
      case 'to':
        picked.to = datetime
        break
    }
    validateFromTo(target)

    setDetail({
      ...detailstate,
      date: {
        from: parseYearMonthDay(picked.from),
        to: parseYearMonthDay(picked.to)
      },
      time: picked,
      max: {
        ...detailstate.max,
        from: parseYearMonthDay(picked.to)
      }
    })
    setFlag({
      ...flag,
      changeFlag: true
    })
  }

  const validateFromTo = (target:string) => {
    const from = `${detailstate.date.from?.year}-${detailstate.date.from?.month}-${detailstate.date.from?.day} ${dayjs(detailstate.time.from).format('HH:mm')}`
    const to = `${detailstate.date.to?.year}-${detailstate.date.to?.month}-${detailstate.date.to?.day} ${dayjs(detailstate.time.to).format('HH:mm')}`
    let message = ''
    if (dayjs(from).unix() > dayjs(to).unix()) {
      if (target === 'from') {
        message = '시작 시간은 종료 시간보다 과거로 설정해 주세요.'
      } else {
        message = '종료 시간은 시작 시간보다 미래로 설정해 주세요.'
      }
      popupController.confirm(message)
      setFromToError(message)
    } else {
      setFromToError('')
    }
  }

  const onChangeSingleTime = (datetime:any) => {
    setDetail({
      ...detailstate,
      date: {
        ...detailstate.date,
        to: parseYearMonthDay(datetime)
      },
      time: {
        to: datetime,
        from: ''
      }
    })
    setFlag({
      ...flag,
      changeFlag: true
    })
  }

  const updateRange = () => {
    if (fromToError !== '') {
      popupController.confirm(fromToError)
    } else if (props.single !== true && dayjs(detailstate.time.to).diff(dayjs(detailstate.time.from)) > 31622400000) {
      popupController.confirm('선택 가능한 기간은 12개월을 초과할 수 없습니다.')
    } else if (dayjs(detailstate.time.from).isSameOrBefore(dayjs(detailstate.time.to)) || props.single) {
      window.dispatchEvent(new PageEvent(PageEvent.STOP_REFRESH))
      setFlag(newFlag => {
        newFlag = {
          ...flag,
          modifiedFlag: true
        }
        if (props.onChange) { 
          props.onChange(detailstate.time) 
        }
        return newFlag
      })

    } else {
      popupController.confirm('유효하지 않은 기간입니다.')
    }
  }

  const clearRange = ():void => {
    setDetail({
      ...detailstate,
      date: {
        from: parseYearMonthDay(props.initial.from),
        to: parseYearMonthDay(props.initial.to)
      },
      time: {
        from: props.initial.from,
        to: props.initial.to || dayjs().format('YYYY-MM-DD HH:mm')
      },
      max: {
        ...detailstate.max,
        from: parseYearMonthDay(props.initial.to)
      }
    })
    setFlag({
      ...flag,
      changeFlag: false,
      modifiedFlag: false
    })
    if(props.onChange) {
      props.onChange(props.initial)
    }
  }

  return(
    <>
        <DateRangePickerFragment ref={ref}>
          {
            props.single ? 
            <div className={"pickerWrap "+(props.type === TIME_PICKER_TYPE.MINI ? 'noIcon' : '')}>
              {
                detailstate.max.to ? 
                <DatePicker value={detailstate.date.to} 
                            calendarPopperPosition="bottom"
                            minimumDate={state.limit?.from ? state.limit?.from : undefined}
                            maximumDate={detailstate.max.to}
                            calendarClassName="custom-calendar"
                            colorPrimary="#333"
                            onChange={(date) => onChangeSingleCalendar(date)} /> : false
              }
              {
                props.type !== undefined && (props.type === TIME_PICKER_TYPE.NONE || props.type === TIME_PICKER_TYPE.MINI) ? false : 
                  <TimePicker value={detailstate.time.to}
                              onChange={(datetime:any) => onChangeSingleTime(datetime)}
                              type={props.type} />
              }
            </div>
            : <div className={"pickerWrap "+(props.type === TIME_PICKER_TYPE.MINI ? 'noIcon' : '')}>
              {
                detailstate.max.from ? 
                <DatePicker value={detailstate.date.from} 
                            calendarPopperPosition="bottom"
                            minimumDate={state.limit?.from ? state.limit?.from : undefined}
                            maximumDate={detailstate.max.from}
                            calendarClassName="custom-calendar"
                            colorPrimary="#333"
                            onChange={(date) => onChangeCalendar('from', date)} /> : false
              }
              {
                props.type !== undefined && (props.type === TIME_PICKER_TYPE.NONE || props.type === TIME_PICKER_TYPE.MINI) ? false : 
                  <TimePicker value={detailstate.time.from}
                              onChange={(datetime:any) => onChangeTime('from', datetime)}
                              type={props.type} />
              }
              <span className="bar">~</span>
              {
                detailstate.max.to && detailstate.date.from ?
                <DatePicker value={detailstate.date.to} 
                            calendarPopperPosition="bottom"
                            minimumDate={detailstate.date.from}
                            maximumDate={detailstate.max.to}
                            calendarClassName="custom-calendar"
                            colorPrimary="#333"
                            onChange={(date) => onChangeCalendar('to', date)} /> : false
              }
              {
                props.type !== undefined && (props.type === TIME_PICKER_TYPE.NONE || props.type === TIME_PICKER_TYPE.MINI) ? false : 
                  <TimePicker value={detailstate.time.to}
                              onChange={(datetime:any) => onChangeTime('to', datetime)}
                              type={props.type} />
              }
            </div>
          }
          { props.type !== TIME_PICKER_TYPE.MINI ?
            <button className="btn text end" onClick={updateRange}>적용하기<img src="/images-v2/chevron-right.svg" alt='action' /></button> : false
          }
          {
            flag.modifiedFlag && (props.initial.from !== props.data.from || props.initial.to !== props.data.to) ? 
            <div style={{display:'flex', alignItems:'center' }}><div className="verBar"></div><button className="btn text end" onClick={clearRange}>초기화<img src="/images-v2/repeat.svg" alt='action' /></button></div>
            : false
          }
        </DateRangePickerFragment>
        { 
          false /* flag.changeFlag === true && props.changeMessageHideFlag !== true */ ? <MessageFragment>
            {/* 날짜 변경 시 5초 자동 새로고침 기능은 Off 상태가 됩니다. 데이터 자동 갱신을 위해서는 다시 5초 자동 새로고침 기능을 켜 주세요. */}
            날짜 변경 시 5초 자동 새로고침 기능은 Off 상태가 됩니다.
          </MessageFragment> : false 
        }
      </>
  )
})

const DateRangePickerFragment = styled.div`
  display:flex; align-items:center; gap:12px;
  .pickerWrap { display:flex; gap:10px; align-items:center;}
  .bar { font-size:16px; font-weight: 400; line-height:normal;}
  .btn {padding:0 2px;}
  .DatePicker { position:relative; border:1px solid #D5D5DA; border-radius:6px; }
  .DatePicker input { width:65px; height:30px; padding:0 43px 0 12px; border:none; border-radius:6px; }
  .DatePicker > div { left:0; transform: translateY(0); }
  .DatePicker:before { content:''; position:absolute; top:7px; right:8px; width:14px; height:14px; background:url(/images-v2/datepicker-calendar.svg) no-repeat center / 100%; }
  .pickerWrap.noIcon { gap:4px; }
  .pickerWrap.noIcon .DatePicker input { padding:0 12px 0 12px; width:70px; }
  .pickerWrap.noIcon .DatePicker:before { top:0; right:0; width:0; height:0; background:none; }
  .filterBox button { height:16px; }
  .btn.text img {}
  .verBar { font-size:16px; width:1px; height:12px; background:#D5D5DA; margin-right:12px; }

  .custom-calendar { border-radius:6px; border: 1px solid #646469; margin-top:-10px; width:264px; min-height: 268px;
    box-shadow: 0px 12px 16px 0px rgba(27, 29, 31, 0.05), 0px 6px 12px 0px rgba(27, 29, 31, 0.05), 0px 0px 1px 0px rgba(100, 100, 105, 0.50); font-size:9px; }
  .Calendar__header {padding: 12px 4.9em 16px 4.9em;}
  .Calendar__sectionWrapper { min-height:175px; }
  .Calendar__section { padding: 0 16px; }
  .Calendar__day.-ltr { height:28px !important; width:28px; min-height:28px;}
  .Calendar__weekDays { padding:0 16px; justify-content:stretch; gap:6px; }
  .Calendar__weekDay { width:28px; }
  .Calendar__weekRow { align-items:flex-start; height:28px; margin-top:6px; gap:6px; }
  .Calendar__weekRow:first-child { margin-top:0; }
  .Calendar__day.-selected  { max-height:28px; border-radius:6px; }
  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {border-radius:6px;}
  
`

const MessageFragment = styled.div`
  margin-top:5px;font-size:13px
`

export default DateRangePicker