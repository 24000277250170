import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Toggle from '../../components/ui/Toggle'
import ModalEvent from '../../../events/ModalEvent'
import { IResourceLimit, User } from "../../../model/User"
import TableEvent from "../../../events/TableEvent"
import PopupController from '../../../controller/PopupController'
import { IUserInfo } from '../../../states/userInfoState'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from '../../../Config'


interface IResourceLimitPopupProps {
  onClose: Function
  resourceLimit: any
  userInfo?: any
}

interface IResourceLimitPopupState {
  userInfo?: IUserInfo
  maxGpuFlag: boolean
  autoReturnFlag: boolean
  isAdminApprovalFlag: boolean
  blockError: string
  timeError: string
  maxGpuResource: string
  autoReturnResource: string
  userNo: number
  userId: string
  gpuError: string
  isErrorCheck: boolean
  usedGpu: number
  check: boolean
}

const ResourceLimitPopup = (props:IResourceLimitPopupProps) => {
  const user = User()
  const popupController = PopupController.getInstance()
  const [state, _setState] = useState<IResourceLimitPopupState>({
    // limitUserList: [],
    maxGpuFlag: props.resourceLimit.isGpuResourceBlocksEnabled,
    autoReturnFlag: props.resourceLimit.isWorkspaceAutoReclaimEnabled,
    isAdminApprovalFlag: props.resourceLimit.isAdminApproval,
    blockError: '',
    timeError: '',
    maxGpuResource: '',
    autoReturnResource: '',
    userId: '',
    userNo: 0,
    gpuError: '',
    isErrorCheck: false,
    usedGpu: 0,
    check: false
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    if (props.userInfo.userNo !== -1) {
      getMyInfo()
    }
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)

    return () => {
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    }
  }, [])

  useEffect(() => {
    setState({
      ...state,
      maxGpuFlag: state.userInfo?.resourceLimit.isGpuResourceBlocksEnabled ? state.userInfo?.resourceLimit.isGpuResourceBlocksEnabled : false,
      autoReturnFlag: state.userInfo?.resourceLimit.isWorkspaceAutoReclaimEnabled ? state.userInfo?.resourceLimit.isWorkspaceAutoReclaimEnabled : false,
      isAdminApprovalFlag: state.userInfo?.resourceLimit.isAdminApproval ? state.userInfo?.resourceLimit.isAdminApproval : false,
      maxGpuResource: state.userInfo?.resourceLimit.totalGpuResourceBlocks !== 0 || state.userInfo?.resourceLimit.totalGpuResourceBlocks !== null ? state.userInfo?.resourceLimit.totalGpuResourceBlocks : undefined,
      autoReturnResource: state.userInfo?.resourceLimit.workspaceAutoReclaimHours!== 0 || state.userInfo?.resourceLimit.workspaceAutoReclaimHours!== null ? state.userInfo?.resourceLimit.workspaceAutoReclaimHours : undefined,
    })
  },[state.userInfo])

  const getMyInfo = async () => {
    if (props.userInfo.userNo === -1) return
    try{
      const userInfo:any = await user.getMyInfo(props.userInfo.userNo || -1)
      setState({
        ...state,
        userInfo: userInfo
      })
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e405d3')
    }
  }

  const modalActionHandler = async (e: ModalEvent) => {
    switch (e.payload.action) {
      case 'submitrl':
        sendData()
        break
    }
  }

  const submitHandler = async () => {
    let message = ''
    let submitErrorCheck = false
    let gpuError = state.blockError
    let timeError = state.timeError

    // if (state.isErrorCheck === true) {
    //   submitErrorCheck = true
    // } 
    
    if (state.maxGpuFlag === true && state.autoReturnFlag === true && state.autoReturnResource === '' && state.maxGpuResource === '') {
      gpuError = '1 이상의 숫자로 입력해 주세요.'
      timeError = '1 이상의 숫자로 입력해 주세요.'
      submitErrorCheck = true
    } 
    if (state.maxGpuFlag === true && isNaN(Number(state.maxGpuResource)) === true || state.maxGpuFlag === true && state.maxGpuResource === '') { //마저작업
      gpuError = '1 이상의 숫자로 입력해 주세요.'
      submitErrorCheck = true
    } 
    if (state.autoReturnFlag === true && isNaN(Number(state.autoReturnResource)) === true || state.autoReturnFlag === true && state.autoReturnResource === '') {
      timeError = '1 이상의 숫자로 입력해 주세요.'
      submitErrorCheck = true
    } 

    if(submitErrorCheck === true) {
      message = `입력 값이 없거나 잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.`
      popupController.confirm(message)

      setState({
        ...state,
        blockError: gpuError,
        timeError: timeError,
        isErrorCheck: submitErrorCheck
      })
      return
    }
    
    if (state.autoReturnFlag === true) {
      message = `${state.userInfo?.name} (${state.userInfo?.userId}) 님의 리소스 제한 설정이 적용되며, 현재 설정한 시간을 초과하여 사용 중인 워크스페이스는 설정 즉시 회수됩니다. 진행하시겠습니까?`
      popupController.dialouge(message, 'submitrl', 'submitrl', '확인', '취소')
    } else if (submitErrorCheck === false) {
      message = `${state.userInfo?.name} (${state.userInfo?.userId}) 님의 리소스 제한 설정을 적용합니다. 진행하시겠습니까?`
      popupController.dialouge(message, 'submitrl', 'submitrl', '확인', '취소')
    }
  }

  const sendData = async () => {
    try {
      let payload: any = {
        isGpuResourceBlocksEnabled : stateRef.current.maxGpuFlag,
        totalGpuResourceBlocks : stateRef.current.maxGpuFlag ? Number(stateRef.current.maxGpuResource) : 0,
        isWorkspaceAutoReclaimEnabled : stateRef.current.autoReturnFlag,
        workspaceAutoReclaimHours : stateRef.current.autoReturnFlag ? Number(stateRef.current.autoReturnResource) : 0,
        isAdminApproval : stateRef.current.isAdminApprovalFlag
      }
      const response = await user.userResourceLimit(stateRef.current.userInfo?.userNo || -1, payload)

     if (response.status === 200) {
        let e: TableEvent = new TableEvent(TableEvent.RESOURCE_LIMITED)
        window.dispatchEvent(e)
        let e2:ModalEvent = new ModalEvent(ModalEvent.ACTION_MODAL)
        e2.payload = {
          key: 'resourceLimit',
          action: 'resourceLimit',
          name:stateRef.current.userInfo?.name,
          userId:props.userInfo.userId
        }
        popupController.dispatchEvent(e2)
        closeHandler()
      }
    } catch(error) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - e9eb83')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const inputHandler = (target: string, value: any) => {
    let newNumError = state.blockError
    let newTimeError = state.timeError
    let gpuError = state.gpuError
    let isError: boolean = false
    value = value.trim()
    switch (target) {
      case 'maxGpuResource':
        isError = false
        if (value === '' || null || undefined) {
          isError = true
          newNumError = '1 이상의 숫자로 입력해 주세요.'
        } else if (isNaN(Number(value)) === true || value < 1 || value.includes('.') || value.indexOf('0') === 0) {
          isError = true
          newNumError = '1 이상의 숫자로 입력해 주세요.'
        } else {
          isError = false
          newNumError = ''
        }

        if (value !=='' && state.userInfo?.resourceLimit.pendingGpuResourceBlocks + state.userInfo?.resourceLimit.runningGpuResourceBlocks > value){
          gpuError = '제한하려는 GPU 개수보다 대기 + 사용중 GPU 개수가 많습니다. 제한 설정 후에도 대기중 워크스페이스는 생성이 가능합니다.'
        } else {
          gpuError = ''
        }

        if (state.maxGpuFlag === false) {
          isError = false
          value = ''
          newNumError = ''
        }
        setState({
          ...state,
          maxGpuResource: value,
          blockError: newNumError,
          gpuError: gpuError,
          isErrorCheck: isError
        })
        break

      case 'autoReturn':
        isError = false
        if (value === '' || null || undefined) {
          isError = true
          newTimeError = '1 이상의 숫자로 입력해 주세요.'
        } else if (isNaN(Number(value)) === true || value < 1 || value.includes('.') || value.indexOf('0') === 0) {
          isError = true
          newTimeError = '1 이상의 숫자로 입력해 주세요.'
        } else {
          isError = false
          newTimeError = ''
        }
        if (state.autoReturnFlag === false) {
          isError = false
          value = ''
          newTimeError = ''
        }
        setState({
          ...state,
          autoReturnResource: value,
          timeError: newTimeError,
          isErrorCheck: isError
        })
        break

      case 'maxGpuFlag':
        isError = false
        value = ''
        newNumError = ''
        setState({
          ...state,
          maxGpuResource: 0
        })
    }
  }

  return (
    <ResourceLimitPopupFragment>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
        <button onClick={closeHandler} className="btnClose">닫기</button>
        <div className="pageTitle inputBox inputBoxTitle">
          리소스 제한 설정
          {/* <Tooltip des="사용자들의 인프라 사용 개수를 제한하거나, 특정 시간 후 자동 회수되게 하거나,<br> 인프라 신청 시 자동으로 대기열에 포함되지 않도록 제약을 설정할 수 있습니다." /> */}
        </div>
        <div className="infosection">
          <div className="infoBox">
            <div className="infoBundle">
              <div className="infoLabel">사용자 정보</div>
              <div className="infoContent"><div style={{ maxWidth: '120px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{state.userInfo?.userId}</EllipsisToolTip></div>
                <img className="separator" src="/images/vertical-separator.png" />
                <div style={{ maxWidth: '72px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{state.userInfo?.name}</EllipsisToolTip></div>
                <img className="separator" src="/images/vertical-separator.png" />
                <div style={{ maxWidth: '120px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{state.userInfo?.department !== null && state.userInfo?.department !== '' ? state.userInfo?.department : '-'}</EllipsisToolTip></div></div>
            </div>
            <div className="infoBundle">
              <div className="infoLabel">GPU 현황</div>
              <div className="infoContent">대기중 : {state.userInfo?.resourceLimit.pendingGpuResourceBlocks}개<img className="separator" src="/images/vertical-separator.png" />사용중 : {state.userInfo?.resourceLimit.runningGpuResourceBlocks}개</div>
            </div>
          </div>
          <div className="error">{state.gpuError}
          </div>
        </div>
        
        <div className="settings">
          <div className="inputWrap">
            <div className="inputBox innerLabel">
              <h4>총 사용 가능 GPU 리소스 블록 개수 </h4>
              <div className='toggleBox'>
                <Toggle id='maxGpuFlag' checked={state.maxGpuFlag} onChange={(checked: boolean) => {
                  setState({ ...state, maxGpuFlag: checked, maxGpuResource: '', isErrorCheck: false, blockError: '' })
                }} />
              </div>
              <div className='flex'>
                <div> <div className='postText'>대기+사용 GPU 포함 </div></div>
                <div className={'inputGroup input'}>
                  <input className='input' type="number" disabled={state.maxGpuFlag === false ? true : false} value={state.maxGpuFlag === false ? '' : state.maxGpuResource || ''}
                    onChange={(e) => { inputHandler('maxGpuResource', e.target.value)}}/>
                </div>
                <div style={{ marginLeft: '-5px' }}>개</div>
              </div>
            </div>
          </div>
          {state.blockError ? <p className="message">{state.blockError}</p> : false}
          <div className="inputWrap">
            <div className="inputBox innerLabel">
              <h4>워크스페이스 자동 회수 시간 </h4>
              <div className='toggleBox'>
                <Toggle id={'autoreturnTimeFlag'} checked={state.autoReturnFlag} onChange={(checked: boolean) => {
                  setState({
                    ...state,
                    autoReturnFlag: checked,
                    autoReturnResource: '',
                    isErrorCheck: false, 
                    timeError: ''
                  })
                }} />
              </div>
              <div className='flex'>
                <div> <div className='postText'>생성 시간 기준</div></div>
                <div className={'inputGroup input'}>
                  <input className='input' type="number" placeholder="" disabled={state.autoReturnFlag === false ? true : false} value={state.autoReturnFlag === false ? '' : state.autoReturnResource || ''}
                    onChange={(e) => { inputHandler('autoReturn', e.target.value) }} />
                </div>
                <div style={{ marginLeft: '-5px' }}> <div>시간 후 자동 회수됨</div><div>(기존 생성된 워크스페이스 포함)</div></div>
              </div>
            </div>
          </div>
          {state.timeError ? <p className="message">{state.timeError}</p> : false}
          <div className="inputWrap">
            <div className="inputBox innerLabel">
              <h4>관리자 승인 후 대기열 포함 </h4>
              <div className='toggleBox'>
                <Toggle id={'permissionListFlag'} checked={state.isAdminApprovalFlag} onChange={(checked:boolean) => { setState({ ...state, isAdminApprovalFlag: checked }) }} />
              </div>
              <div className='flex'>
                <div style={{ padding: '10px 0' }}>사용자가 워크스페이스 신청 시, 관리자 확인 후 대기 목록으로 이동</div>
              </div>
            </div>
          </div>
          <div className="btnWrap">
            <button className="btn grey" onClick={closeHandler}>취소</button>
            <button className={"btn blue"} onClick={(e) => { submitHandler() }}>설정하기</button>
          </div>
        </div>

      </form>
    </ResourceLimitPopupFragment>
  )
}

const ResourceLimitPopupFragment = styled.div`
  .formWrap{display:flex; flex-direction:column; width:880px; min-height:480px; padding: 50px 50px 20px;box-sizing:border-box; font-size:14px;}

  .total {margin-left:5px; font-size:12px;}
  .total b{color:#217eff; font-weight:bold;}
  .inputBoxTitle {margin-bottom:30px;}
  h4 { font-weight:500; display:flex; align-items: baseline; width:195px; height:37px; align-items:center; margin-right:30px; }
  .scrollWrap{overflow:auto; flex:1; margin-bottom:30px;}

  h4 div .btnTooltip{width:13px;}
  .btnTooltip{margin-left:10px;}
  input::-webkit-inner-spin-button{
  -webkit-appearance: none; 
  margin: 0; 
  }
  .infosection {margin:0 0 50px 0; }
  .infoBox { display:flex; width:748px; padding:12px 16px; align-items:flex-start; background:#F7F8FB; border-radius:2px; font-size:14px; gap:32px; height:17px;}
  .error { margin-top:12px; font-size: 12px; color:#FF0000; }
  .infoBox .infoBundle .infoLabel { color:#000; font-size:14px; font-weight:600; line-height: normal; margin-right:8px; }
  .infoBundle { display:flex; gap:8px; }
  .infoContent { display:flex; line-height:17px; }

  .inputWrap { display:flex; height:37px; margin-top:24px; align-items:center; font-weight:500;}
  .inputWrap:first-of-type { margin-top:0; }
  .inputGroup { width:156px;}
  form .inputBox { gap:0; }
  form .inputBox .input{ max-width:102px; }
  form .inputBox input{ width:100px; }
  .flex {display:flex; gap:10px; align-items: center; margin-left:20px; }
  .toggleBox { align-items:center; padding:3px 0;}
  .separator { height:10px; padding:2px 0px; margin:0 8px; position:relative; top:2px; }

  .btnWrap{ flex:none; display:flex; justify-content:center; gap:20px; margin-top:70px; }
  .btnWrap .btn{width:188px; height:40px; line-height:40px;}
  .message { margin-left:437px;  height:19px;}
  .postText { width:117px; }
`

export default ResourceLimitPopup