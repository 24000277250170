import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { NavigateFunction, Params } from 'react-router-dom'
import ISelectOption from '../../../interfaces/SelectOption'
import { ResourceGroup, IPvcCreation } from '../../../model/ResourceGroup'
import Select from '../ui/Select'
import regularExpression from '../../../utils/regularExpression'
import CheckBox from '../ui/CheckBox'
import PopupController from '../../../controller/PopupController'
import InputBox, { TEXT_INPUT_TYPE } from '../ui/InputBox'
import { ERROR_TYPE } from '../../../interfaces/Error'

interface IPVCreatePopupProps {
  navigate?: NavigateFunction
  params?: Params
  onClose: Function
  onSelected: Function
  groupName: string
}

interface IPVCreatePopupState {
  volumeType:ISelectOption[]
  sizeType:ISelectOption[]
  nodeList:ISelectOption[]
  resType:string
  payload:IPvcCreation
  error: {
    type:string
    name:string
    size:string
    server:string
    path:string
  }
  volumeType1:ISelectOption
  sizeType1:ISelectOption
}

interface IPVCreatePopupsizestate {
  sizeUnit:string
  selfInputFlag:boolean
}

let sizeType_:any[] = []

const PvcCreatePopup = (props:IPVCreatePopupProps) => {
  const regExp = regularExpression.regExp
  
  const [ state, _setState ] = useState<IPVCreatePopupState>({
    volumeType: [{
      label: '볼륨 타입을 선택해 주세요.',
      value: ''
    }],
    sizeType: [{
      label: '선택',
      value: '-'
    }],
    nodeList: [{
      label: '서버 주소를 선택해 주세요.',
      value: '-'
    }],
    resType: '',
    payload: {
      pvcName: '',
      size: '',
      pvInfo: {
        server: '',
        type: '',
        path: ''
      }
    },
    error: {
      type: '',
      name: '',
      size: '',
      server: '',
      path: ''
    },
    volumeType1:{label: '',
    value: ''},
    sizeType1:{label: '',
    value: ''}
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ sizestate, _setsizestate ] = useState<IPVCreatePopupsizestate>({
    selfInputFlag: false,
    sizeUnit: '선택'
  })

  const selectVolumeRef = useRef<any>()

  const popupController = PopupController.getInstance()

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const getData = async () => {
    try {
      const response = await ResourceGroup.getPvcCreationInfo(props.groupName)
      sizeType_ = response.sizeUnits
      if (response) {
        let volumeType: ISelectOption[] = []
        let sizeType: ISelectOption[] = []
        let nodeList: ISelectOption[] = []
        for (let eachType of response.creationType) {
          volumeType.push({
            label: eachType.volType,
            value: eachType.resType,
            fakeLabel: eachType.volType.toUpperCase()
          })
        }
        for (let eachNode of response.nodeList) {
          nodeList.push({
            label: eachNode.ip,
            value: eachNode.ip
          })
        }
        
        setState({
          ...state,
          volumeType: volumeType,
          sizeType: sizeType,
          nodeList: nodeList
        })
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 7c8da9')
    }
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const typeSelectHandler = (data:ISelectOption) => {
    state.resType = data.value
    let sizeType:ISelectOption[] = []
    let pvInfo = state.payload.pvInfo

    for (let eachSizeType of sizeType_) {
      if (Number(eachSizeType.resType) === Number(data.value)) {
        for (let eachSizeUnit of eachSizeType.units) {
          sizeType.push({
            label: eachSizeUnit,
            value: eachSizeUnit
          })
        }
      }
    }
    switch(Number(data.value)) {
      case 1:
        // NFS
        pvInfo.server = ''
        pvInfo.path = ''
        pvInfo.type = data.label
        setState({
          ...state,
          sizeType: sizeType,
          selfInputFlag: true,
          payload: { 
            ...state.payload,
            pvInfo: pvInfo
          },
          error: { 
            ...state.error,
            type: '',
            path: '',
            server: ''
          }
        })
        _setsizestate({ 
          sizeUnit: '선택',
          selfInputFlag: false
        })
        break
      case 2:
        // NetApp ONTAP
        pvInfo.type = data.label
        setState({
          ...state,
          sizeType: sizeType,
          error: { 
            ...state.error,
            type: ''
          },
          payload: { 
            ...state.payload,
            pvInfo: pvInfo
          },
        })
        _setsizestate({
          ...sizestate,
          sizeUnit: '선택'
        })
        break

      case 3:
        // lustre
        pvInfo.type = data.label
        setState({
          ...state,
          sizeType: sizeType,
          error: {
            ...state.error,
            type: ''
          },
          payload: { 
            ...state.payload,
            pvInfo: pvInfo
          },
        })

        _setsizestate({
          ...sizestate,
          sizeUnit: '선택'
        })
        break
    }
  }

  const inputHandler = (target:string, value:any, idx:number = 0) => {
    let isError:boolean = false
    let error:boolean = false
    let errorText:string = ''
    switch (target) {
      case 'name':
        error = value === '' ? false : !regExp.WS_NAME_RULE.test(value)
        setState({
          ...state,
          payload:{
            ...state.payload,
            pvcName: value
          },
          error: {
            ...state.error,
            name: error ? '영어 소문자, 숫자, 하이픈(-) 만 입력 가능하며, 이름의 시작은 소문자로 끝은 소문자 혹은 숫자로 입력해 주세요.' : ''
          }
        })
        break
      case 'size':
        if (value.length > 0 && !regExp.PVC_SIZE_RULE.test(value.trim())) {
          isError = true
          errorText = '숫자만 입력 가능합니다.'
        } else if (Number(value) > 1023) {
          isError = true
          errorText = '1,023이하 숫자로 입력해 주세요'
        }
        setState({
          ...state,
          payload: {
            ...state.payload,
            size: value
          },
          error: {
            ...state.error,
            size: errorText
          }
        })
        break
      case 'server':
        setState({
          ...state,
          payload:{
            ...state.payload,
            pvInfo:{
              ...state.payload.pvInfo,
              server: value
            }
          },
          error: {
            ...state.error,
            server: error ? '' : ''
          }
        })
        break
      case 'path':
        error = value === '' ? false : !regExp.PATH_RULE.test(value)
        setState({
          ...state,
          payload:{
            ...state.payload,
            pvInfo:{
              ...state.payload.pvInfo,
              path: value 
            }
          },
          error: {
            ...state.error,
            path: error ? '영어 대소문자, 숫자, 특수문자(-)(_)(.)(/)만 입력 가능하며, 시작은 슬래시(/)로 입력해 주세요.' : ''
          }
        })
    }
  }

  const unitSelectHandler = (data:ISelectOption) => {
    _setsizestate({
      ...sizestate,
      sizeUnit: data.label
    })
    setState({
      ...state,
      sizeType1: {
        label: data.label,
        value: data.value
      }
    })
  }

  const serverSelectHandler = (data:ISelectOption) => {
    let pvInfo = state.payload.pvInfo
    pvInfo.server = data.label

    setState({
      ...state,
      payload: { 
        ...state.payload,
        pvInfo: pvInfo
      },
      error: {
        ...state.error,
        server: ''
      }
    })
  }

  const submitHandler = async () => {
    let errorFlag = false
    let typeError = ''
    let nameError = ''
    let sizeError = ''
    let pathError = ''
    let serverError = ''
    let blankError:boolean = false
    let validationError:boolean = false
    let payload:IPvcCreation = state.payload
    
    if (state.resType === '-' || state.resType === '') {
      errorFlag = true
      typeError = '필수 선택 항목입니다.'
      blankError = true
    } else {
      if (state.payload.pvcName.trim() === '') {
        errorFlag = true
        nameError = '필수 입력 항목입니다.'
        blankError = true
      } else if (!regExp.WS_NAME_RULE.test(state.payload.pvcName.trim())) {
        errorFlag = true
        nameError = '영어 소문자, 숫자, 하이픈(-) 만 입력 가능하며, 이름의 시작은 소문자로 끝은 소문자 혹은 숫자로 입력해 주세요.'
        validationError = true
      }
      if (sizestate.sizeUnit === '' || sizestate.sizeUnit === '선택') {
        errorFlag = true
        sizeError = '단위를 선택해 주세요.'
        validationError = true
      }
      if (state.payload.size.trim() === '') {
        errorFlag = true
        sizeError = '필수 입력 항목입니다.'
        blankError = true
      } else if (state.payload.size.length > 0 && !regExp.PVC_SIZE_RULE.test(state.payload.size.trim())) {
        errorFlag = true
        sizeError = '숫자만 입력 가능합니다.'
        validationError = true
      } else if (Number(state.payload.size) > 1023) {
        errorFlag = true
        sizeError = '1,023이하 숫자로 입력해 주세요'
        validationError = true
      }

      if (Number(state.resType) === 1) {
        if ((state.payload.pvInfo.server !== undefined && state.payload.pvInfo.server.trim() === '') || state.payload.pvInfo.server === undefined) {
          errorFlag = true
          serverError = '필수 입력 항목입니다.'
          blankError = true
        }
        if (state.payload.pvInfo.path !== undefined && state.payload.pvInfo.path.trim() === '') {
          errorFlag = true
          pathError = '필수 입력 항목입니다.'
          blankError = true
        } else if (state.payload.pvInfo.path && !regExp.PATH_RULE.test(state.payload.pvInfo.path.trim())) {
          errorFlag = true
          pathError = '영어 대소문자, 숫자, 특수문자(-)(_)(.)(/)만 입력 가능하며, 시작은 슬래시(/)로 입력해 주세요.'
          validationError = true
        }
      }
    }

    if (blankError === true) {
      popupController.confirm('입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
    } else if (validationError === true) {
      popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
    }

    if (errorFlag === false) {
      try {
        payload.sizeEnd = payload.size + sizestate.sizeUnit
        //console.log(payload.sizeEnd)
        let result = await ResourceGroup.createPvc(props.groupName, payload)
        if (props.onSelected) props.onSelected(state.payload, result)
        closeHandler()
      } catch (e) {
        if ((e as Error).message === ERROR_TYPE.DUPLICATED) {
          popupController.confirm('사용 중인 PVC 이름입니다.')
          errorFlag = true
          setState({
            ...state,
            error: {
              ...state.error,
              name: '사용 중인 PVC 이름입니다.'
            }
          })
        } else {
          popupController.confirm('에러가 발생했습니다.\n에러코드 - 3457ef')
          return
        }
      }
    } else {
      setState({
        ...state,
        error: {
          type: typeError,
          name: nameError,
          size: sizeError,
          path: pathError,
          server: serverError
        }
      })
    }
  }

  return (
    <PVCreatePopupFragment>
      <form className="formWrap" onSubmit={(e) => { e.preventDefault() }}>
        <button onClick={closeHandler} className="btnClose">닫기</button>
        <div className="pageTitle">
          PVC 생성
        </div>
        <div className="formGroup bothEnd">
          <label className="inputBox inputBoxTitle" style={{ paddingTop: "10px" }}>
            타입 선택*
          </label>
          <div className="inputWrap selectWrap">
            <Select selectRef={selectVolumeRef} option={state.volumeType} placeholder={'볼륨 타입을 선택해 주세요.'} onChange={typeSelectHandler} />
            {state.error.type ? <p className="message">{state.error.type}</p> : false}
          </div>
        </div>
        <div className="formGroup bothEnd">
          <label className="inputBox inputBoxTitle" style={{ paddingTop: "10px" }}>
            이름*
          </label>
          <div className="inputWrap">
            <div className="inputBox">
              <div className="numOfCharBox">
                <InputBox id='name' type={TEXT_INPUT_TYPE.TEXT} maxLength={63} readonly={state.resType === ""} placeholder="소문자, 숫자, 하이픈(-) 만 입력 가능, 시작은 소문자로 끝은 소문자, 숫자로 입력해 주세요." value={state.payload.pvcName}
                  warning={state.error.name} onChange={inputHandler} />
              </div>
            </div>
          </div>
        </div>
        <div className="formGroup bothEnd">
          <label className="inputBox inputBoxTitle" style={{ paddingTop: "10px" }}>
            용량*
          </label>
          <div className="inputWrap">
            <div className="inputBox testbox">
              <InputBox id='size' type={TEXT_INPUT_TYPE.TEL} placeholder="1,023이하 숫자로 입력해 주세요." width='470px' value={state.payload.size} readonly={state.resType === ""} warning={state.error.size} onChange={inputHandler} />
              <div className="inputWrap selectWrap">
                <div className='size' style={{ width: '100px' }}>
                  <Select option={state.sizeType} placeholder={'선택'} selected={state.sizeType1.label!==''? state.sizeType1 : state.sizeType.find(element => element.label === 'GiB')} disabled={stateRef.current.resType === ""} onChange={unitSelectHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formGroup bothEnd">
          <label className="inputBox inputBoxTitle" style={{ paddingTop: "10px" }}>
            서버 선택{Number(stateRef.current.resType) === 1 ? '*' : ''}
          </label>
          <div className="inputWrap">
            <div style={{ marginLeft: '-16px', marginBottom: '10px', width:'116px' }}>
              <CheckBox id="selfInput" label="직접 입력" checked={sizestate.selfInputFlag} disabled={state.resType !== '' && Number(stateRef.current.resType) !== 1 || state.resType === ""}
                onChange={(id: string, checked: boolean) => {
                  let pvInfo = state.payload.pvInfo
                  pvInfo.server = ''
                  setState({
                    ...state,
                    payload: {
                      ...state.payload,
                      pvInfo: pvInfo
                    }
                  })
                  _setsizestate({
                    ...sizestate,
                    selfInputFlag: checked
                  })
                }} />
            </div>
            { sizestate.selfInputFlag ?
              <div className="inputWrap">
                <div className="inputBox">
                  <InputBox id='server' type={TEXT_INPUT_TYPE.TEXT} placeholder='IP 또는 도메인 형식으로 입력해 주세요.' value={state.payload.pvInfo.server} warning={state.error.server} readonly ={state.resType !== '' && Number(state.resType) !== 1 || state.resType === ""} onChange={inputHandler} />
                </div>
              </div>
              : <div className="inputWrap selectWrap">
                <Select option={state.nodeList} placeholder={'서버 주소를 선택해 주세요.'} onChange={serverSelectHandler} disabled={state.resType !== '' && Number(state.resType) !== 1 || state.resType === ""} />
                {state.error.server ? <p className="message">{state.error.server}</p> : false}
              </div>
            }
          </div>
        </div>
        <div className="formGroup bothEnd">
          <label className="inputBox inputBoxTitle" style={{ paddingTop: "10px" }}>
            경로{Number(state.resType) === 1 ? '*' : ''}
          </label>
          <div className="inputWrap">
            <div className="inputBox">
              <InputBox id="path" type={TEXT_INPUT_TYPE.TEXT} placeholder='서버로 설정한 경로를 입력해 주세요.' value={state.payload.pvInfo.path} warning={state.error.path} readonly={state.resType !== '' && Number(state.resType) !== 1 || state.resType === ""} onChange={inputHandler}/>
            </div>
          </div>
        </div>

        <div className="btnWrap">
          <button className="btn grey" onClick={closeHandler}>취소</button>
          <button className={"btn blue"} onClick={(e) => { submitHandler() }}>{'생성하기'}</button>
        </div>
      </form>
    </PVCreatePopupFragment>
  )
}

const PVCreatePopupFragment = styled.div`
  .formWrap { display:flex; flex-direction:column; width:800px; min-height:570px; padding:50px 50px 20px; box-sizing:border-box; }
  .serviceTypeTitleBox { display:flex; gap:5px; margin-bottom:10px; }
  .pageTitle { flex:none; margin-bottom:0px; }
  p { font-size:14px; }
  .btnWrap { flex:none;display:flex; justify-content:center; gap:20px; margin-top:70px; }
  .btnWrap .btn { width:188px; height:40px; line-height:40px; }
  .logBox { width:100%; height:100%; background-color:#EBEBEB; font-size:14px; }
  .logBox div { padding: 20px; word-break:break-all; }
  form .formGroup { margin-top: 30px; }

  .blocked { background-color:#f5f5f5; }
  .blocked p { background-color:#f5f5f5; }

  .portWrap { width: 758px; }
  .inputGroup { margin-left:10px; }
  .inputWrap { flex: none; }
  .inputWrap p { width:546px; }
  .size p { width:68px; }
  .inputBox + .inputBox { margin-top: 30px; }
  .inputBox.inputBoxTitle:not(:first-of-type) { padding-top: 20px; margin-top:0px; }
  .inputBox.inputBoxTitle + .inputBox { margin-top:7px; }
  form .inputBox .input { width:578px; max-width:578px; }
  form .inputBox input { width:578px; }
  .inputBox .btn { width: 79px; white-space:pre; }
  .inputBox { font-size:14px; }
  .inputBoxTitle { font-size:14px; }
  .label, .inputBoxTitle { font-weight:500; }
  .message { font-size:12px; margin: 5px 0 0 0; }
  img.disabled { opacity:0; visibility:hidden }
  button.disabled { opacity:0.5; pointer-events:none }

  .inputBox .stringLengthBox { position:relative; }
  .inputBox .stringLengthBox p { font-size:14px; position:absolute; bottom:10px; right:0px; width:60px; text-align:right; padding-right:15px; }
  .inputBox .numOfCharBox { width:578px; }
  .inputBox .numOfCharBox .input { padding-right:63px; }
  .testbox .input { width:470px !important; }
`

export default PvcCreatePopup