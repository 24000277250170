import { useEffect, useRef, useState } from 'react'
import { Params, useLocation, useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import EllipsisToolTip from 'ellipsis-tooltip-react-chan'
import Config from "../../Config"
import { IWorkspaceDetail, RESOURCE_TYPE, WORKSPACE_STATUS, Workspace } from '../../model/Workspace'
import { IChartSeries } from '../../interfaces/Chart'
import { IUserDetail, PERMISSION_STATUS } from '../../model/User'
//5초 새로고침용
//import PageEvent from '../../events/PageEvent'
import ModalEvent from '../../events/ModalEvent'
import ToastEvent from '../../events/ToastEvent'
import PopupController from '../../controller/PopupController'
import SubPopup from '../components/modal/sub/SubPopup'
import GaugeChart from '../components/chart/GaugeChart'
import DateRangePicker from '../components/ui/DateRangePicker'
import LineChart from '../components/chart/LineChart'
import NoData from '../components/NoData'
import UserInfoPopup from '../components/modal/sub/UserInfoPopup'
import PageEvent from "../../events/PageEvent"
import { ERROR_TYPE } from "../../interfaces/Error"
import { Link } from "react-router-dom"
import Utils from '../../utils'

dayjs.extend(isSameOrBefore)

interface IWorkspaceDetailProps {
  params?:Params
}

interface IWorkspaceDetailState {
  detailData?:IWorkspaceDetail
  imageConfigData: any
  day:number
  hour:number
  minute:number
  second:number
  limit: {
    from:string
    to:string
  }
  range: {
    from:string
    to:string
  }
  user:IUserDetail
  portModal:boolean
  volumeModalFlag:boolean
  refreshFlag:boolean
  initialFrom:string
  initialTo:string
}

interface IChartDataState {
  chartData:{
    gpuMemory:IChartSeries[]
    gpuUtil:IChartSeries[]
    gpuTemp:IChartSeries[]
    netUsage:IChartSeries[]
  }
}

interface IGaugeDataState {
  cpuUsage:number
  storageUsage:number
  memoryUsage:number
}

const WorkspaceDetail = (props:IWorkspaceDetailProps) => {
  const params = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const popupController = PopupController.getInstance()
  const navigate = useNavigate()

  let workspaceID:string = ''
  let namespace:string = ''
  let creationTimestamp:number = 0
  const startFrom:string = dayjs(creationTimestamp * 1000).format('YYYY-MM-DD HH:mm')  //기존에는 계정의 start.
  let defaultFrom:string = dayjs().subtract(24, 'hour').unix() < creationTimestamp ? startFrom : dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let initialFrom:string = dayjs().subtract(24, 'hour').unix() < creationTimestamp ? startFrom : dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  let initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')

  const pickerRef = useRef<any>()
  const [ localState, setLocalState ] = useState<any>({
    workspaceID: '',
    namespace: '',
    creationTimestamp: ''
  })

  const [ state, _setState ] = useState<IWorkspaceDetailState>({
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: defaultFrom,
      to: defaultTo
    },
    user: {
      userNo:0,
      creationTimestamp: 0,
      department: '',
      phoneNumber: '',
      userId: '',
      email: '',
      name: '',
      permissionStatus: PERMISSION_STATUS.PERMITTED,
      pw: null
    },
    portModal: false,
    volumeModalFlag: false,
    refreshFlag: false,
    imageConfigData: null,
    initialFrom: '',
    initialTo: '',
  })

  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const [ chartData, _setChartData ] = useState<IChartDataState>({
    chartData: {
      gpuMemory: [],
      gpuUtil: [],
      gpuTemp: [],
      netUsage: []
    }
  })
  const chartDataRef = useRef(chartData)
  const setChartData = (data:any) => {
    chartDataRef.current = data
    _setChartData(data)
  }

  const [ gaugeData, setGaugeData] = useState<IGaugeDataState>({
    cpuUsage: 0,
    storageUsage: 0,
    memoryUsage: 0
  })

  useEffect(() => {
    getData()
    getMonitoring(state.range)
    window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
    window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    popupController.addEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
    return() => {
      window.dispatchEvent(new PageEvent(PageEvent.HIDE_REFRESH))
      window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
      popupController.removeEventListener(ModalEvent.ACTION_MODAL, modalActionHandler)
      //window.removeEventListener(PageEvent.LAYOUT_INIT_FINISHED, ShowRefreshButton)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //5초 새로고침
  // const ShowRefreshButton = () => {
  //   window.dispatchEvent(new PageEvent(PageEvent.SHOW_REFRESH))
  // }

  const autoRefreshHandler = async() => {
    let range
    if (pickerRef.current?.modifiedFlag === true) {
      initialFrom = defaultFrom = dayjs().subtract(24,'hour').format('YYYY-MM-DD HH:mm')
      initialTo = defaultTo = dayjs().format('YYYY-MM-DD HH:mm')
      pickerRef.current?.clearRange()
      range = {
        from: initialFrom,
        to: initialTo
      }
      getMonitoring(range)
    } else {
      range = {
        from: dayjs(stateRef.current.range.to).add(1,'second').format('YYYY-MM-DD HH:mm:ss'),
        to: dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
      if(range.to !== range.from) {
        onChangeDatePicker(range, true)
      }
    }
  }

  const getData = async (): Promise<void> => {
    try {
      let response = await Workspace.getDetail(params.id ? Number(params.id) : 0)
      switch (response.status) {
        case 200:
          const data = response.data
          namespace = data.namespace
          const runningTime = (data.deletedAt ? dayjs.unix(data.deletedAt) : dayjs()).diff(dayjs.unix(data.startedAt), 'second')
          let totalMin = Math.floor(runningTime / 60)
          const second = runningTime % 60
          const minute = totalMin % 60
          totalMin = Math.floor(totalMin / 60)
          const hour = totalMin % 24
          const day = Math.floor(totalMin / 24)

          let initialFrom = ''
            let initialTo = ''
            let limitFrom = ''
            let limitTo = ''
            if(data.deletedAt) {  //회수, 반납된, 장애가 난 워크스페이스인경우
              limitFrom = dayjs(data.createdAt*1000).format('YYYY-MM-DD HH:mm')
              limitTo = dayjs(data.deletedAt*1000).format('YYYY-MM-DD HH:mm')
              if(data.deletedAt-data.createdAt < 86400) { //  운영된시간이 24시간 미만인 경우
                initialFrom = dayjs(data.createdAt*1000).format('YYYY-MM-DD HH:mm')
                initialTo = dayjs(data.deletedAt*1000).format('YYYY-MM-DD HH:mm')
              } else {  //운영된 시간이 24시간 이상인 경우
                initialFrom = dayjs((data.deletedAt-86400)*1000).format('YYYY-MM-DD HH:mm')
                initialTo = dayjs(data.deletedAt*1000).format('YYYY-MM-DD HH:mm')
              }
            } else {  //그외 운영중인 워크스페이스인 경우
              limitFrom = dayjs(data.createdAt*1000).format('YYYY-MM-DD HH:mm')
              limitTo = dayjs().format('YYYY-MM-DD HH:mm')
              if(Math.floor(Date.now()/1000)-data.createdAt < 86400) { //  운영된 시간이 24시간 미만인 경우
                initialFrom = dayjs(data.createdAt*1000).format('YYYY-MM-DD HH:mm')
                initialTo = dayjs().format('YYYY-MM-DD HH:mm')
              } else { //운영된 시간이 24시간 이상인 경우
                initialFrom = dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
                initialTo = dayjs().format('YYYY-MM-DD HH:mm')
              }
            }
    
            setState({
              ...state,
              detailData: data,
              day: day,
              hour: hour,
              minute: minute,
              second: second,
              limit: {
                from: limitFrom,
                to: limitTo
              },
              range:{
                ...state.range,
                from: initialFrom,
                to: initialTo
              },
              initialFrom: initialFrom,
              initialTo: initialTo
            })
          
          getMonitoring(stateRef.current.range)
          break
        case 204:
          navigate('/404')
        }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.ERROR) {
        popupController.confirm('에러가 발생했습니다.\n에러코드 - 4e5684')
      }
    }
  }

  const getMonitoring = async (newRange:any):Promise<void> => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()

      if (FROM !== TO && stateRef.current.detailData) {
        const data = await Workspace.getMonitoring(stateRef.current.detailData.name, stateRef.current.detailData.namespace, FROM, TO, 
          stateRef.current.detailData.gpuUuid, stateRef.current.detailData.resourceBlock.resourceType, stateRef.current.detailData.createdAt)
        if (data) {
          let gpuMemory = data.wsTimeSeries.gpuMemory
          for (let eachGpu of gpuMemory) {
            for (let eachGpuAdditionalData of data.wsTimeSeries.gpuMemory) {
              if (eachGpu.name === eachGpuAdditionalData.label) {
                eachGpu.data = eachGpu.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let gpuUtil = data.wsTimeSeries.gpuUtil
          for (let eachGpu of gpuUtil) {
            for (let eachGpuAdditionalData of data.wsTimeSeries.gpuUtil) {
              if (eachGpu.name === eachGpuAdditionalData.label) {
                eachGpu.data = eachGpu.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let gpuTemp = data.wsTimeSeries.gpuTemp
          for (let eachGpu of gpuTemp) {
            for (let eachGpuAdditionalData of data.wsTimeSeries.gpuTemp) {
              if (eachGpu.name === eachGpuAdditionalData.label) {
                eachGpu.data = eachGpu.data.concat(eachGpuAdditionalData.data)
              }
            }
          }
          let netUsage = data.wsTimeSeries.network //.concat(data.netUsage)
          for (let eachIo of netUsage) {
            for (let eachIoAdditionalData of data.wsTimeSeries.network) {
              if (eachIo.name === eachIoAdditionalData.label) {
                eachIo.data = eachIo.data.concat(eachIoAdditionalData.data)
              }
            }
          }

          setChartData({
            chartData: {
              gpuMemory: parseChartData(gpuMemory),
              gpuUtil: parseChartData(gpuUtil),
              gpuTemp: parseChartData(gpuTemp),
              netUsage: parseChartData(netUsage)
            }
          })

          setGaugeData({
            cpuUsage: data.wsGauge.cpu,
            storageUsage: data.wsGauge.storage,
            memoryUsage: data.wsGauge.memory,
          })
      } else {
        // DOES NOTHING
      }
    }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - da304e')
    }
  }

  const parseChartData = (data: any) => {
    let i: number, item: any, j: number, jtem: any
    let chartData: any = []
    let dataList: any = []
    for (i = 0; i < data.length; i++) {
      item = data[i]
      dataList = []
      for (j = 0; j < item.data.length; j++) {
        jtem = item.data[j]
        dataList.push([new Date(dayjs.unix(jtem.timestamp).utc().format()).getTime(), jtem.value])
      }
      chartData.push({
        name: item.label ==='in'? 'In' : item.label === 'out'? 'Out' : item.label, //inOut대문자요청,
        data: dataList
      })
    }
    return chartData
  }

  const onChangeDatePicker = (range:any, autoRefreshFlag?:boolean) => {
    setState({
      ...state,
      chartData: {
        gpuMemory: [],
        gpuUtil: [],
        gpuTemp: [],
        netUsage: []
      },
      range: {
        from: range.from,
        to: range.to
      }
    })
    getMonitoring(range)
  }

  const returnModalHandler = () => {
    let message = ''
    if(state.detailData?.detail.isJob === false){
      message = `${state.detailData?.name} 워크스페이스가 회수됩니다. 진행하시겠습니까?`
    } else {
      message = `${state.detailData?.name} Job이 회수됩니다. 진행하시겠습니까?`
    }
    popupController.dialouge(message, 'return', 'return', '확인', '취소')
  }

  const modalActionHandler = async (e:ModalEvent) => {
    switch (e.payload.action) {
      case 'return':
        returnHandler()
        break
      case 'closePortPopup':
        getData()
        break
    }
  }

  const returnHandler = async () => {
    try {
      let e: ToastEvent = new ToastEvent(ToastEvent.OPEN_TOAST)
      let workspaceIdName:any[]=[]
      workspaceIdName.push({
        id:stateRef.current.detailData ? stateRef.current.detailData.id : 0,
        name:stateRef.current.detailData ? stateRef.current.detailData.name : '없는워크스페이스'}
        )
      const response = await Workspace.returnWorkspace(workspaceIdName)
      if (response === null) {
        e.payload = { message: `${workspaceIdName[0].name}이(가) 회수되었습니다.` }
        window.dispatchEvent(e)
        navigate(-1)
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 1f54c0')
    }
  }

  //엑셀다운로드용
  // const downloadHandler = async () => {
  //   const response = await Workspace.downloadWorkspace(localState.workspaceID, localState.namespace, localState.creationTimestamp)
  //   if (response && response.status) {
  //     popupController.confirm(response.data.msg)
  //   }
  // }

  //이건뭐지
/*   const parseGpuClass = (no: number) => {
    let className: string = ''
    if (state.detailData) {
      let ratio: number = Math.round(no / state.detailData.gpuQty * 100)
      if (ratio >= 80) {
        className = 'normal'
      } else if (ratio >= 50) {
        className = 'caution'
      } else {
        className = 'warn'
      }
    }
    return className
  } */

  const detailData = state.detailData
  const chartDatas = chartData.chartData
  let detailDataNode = []
  let detailDataVolume = []
  let nodeIpName:any
  let volumefullData:any
  if (state.detailData && state.detailData.rgroupInfo.nodeList && state.detailData.rgroupInfo.nodeList.length > 0){
    let nodeTotal = state.detailData?.rgroupInfo.nodeList
    for (let list of nodeTotal) {
     nodeIpName = list.ip+' / '+list.name
    detailDataNode.push(nodeIpName)
  }}
  if (state.detailData && state.detailData.rgroupInfo.volumeList && state.detailData.rgroupInfo.volumeList.length > 0){
    let volumeTotal = state.detailData?.rgroupInfo.volumeList
    for (let list of volumeTotal) {
      volumefullData = list.name+' / '+list.size
      detailDataVolume.push(volumefullData)
  }}

  return (
    <WorkspaceDetailFragment>
      {detailData ? <>
        <div className="titleArea">
          <div className={detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? 'error' : ''}>
            <h2 className="pageTitle bothEnd">
              {detailData.name} 상세 {detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? '(장애)' : detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ? '(반납)'
              : detailData.detail.statusId === WORKSPACE_STATUS.RECALLED ? '(회수)' : detailData.detail.statusId === WORKSPACE_STATUS.SYSTEM_RECALLED ? '(시스템에 의한 회수)' : false}
              <div className="btnWrap">
            <button className={"btn outline " + (detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ||
              detailData.detail.statusId === WORKSPACE_STATUS.RECALLED || detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? 'off' : '')} onClick={returnModalHandler} disabled={
                detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ||
                detailData.detail.statusId === WORKSPACE_STATUS.RECALLED}>회수하기</button>
            <button className={"btn outline " + (detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ||
              detailData.detail.statusId === WORKSPACE_STATUS.RECALLED || detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? 'off' : '')} onClick={(e) => {
              e.preventDefault()
              popupController.portEdit(
                detailData.id,
                detailData.ports,
                detailData.namespace,
                detailData.name
              )
            }}>{detailData.ports.length > 0 ? '포트 변경' : '포트 설정'}</button>
            {/* <button className={"btn outline " + (detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ||
              detailData.detail.statusId === WORKSPACE_STATUS.RECALLED || detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? 'off' : '')} onClick={(e) => {
              e.preventDefault()
              popupController.commit(
                detailData.namespace,
                detailData.name
              )
            }}>커밋하기</button> */}
          </div>
            </h2>
          </div>
        </div>
        <div className="bothEnd">
          <div style={{display:'flex', alignItems:'center', fontWeight:'700'}}>
            {detailData.user.userId !== null ? detailData.user.userId : '-'} | {detailData.user.name !== null ? detailData.user.name : '-'} | {detailData.user.department !== null ? detailData.user.department : '-'} 
            <div style={{fontWeight:'400'}}><UserInfoPopup data={detailData?.user ? {department:detailData.user.department,email:detailData.user.email,phoneNumber:detailData.user.phoneNumber,userId:detailData.user.userId,name:detailData.user.name} : {department:'-',email:'-',phoneNumber:'-',userId:'-',name:'-'}} /></div></div>
        </div>
        <div className="bothEndTop info">
          <div>
            <div className="box" style={{ padding: "30px" }}>
              <div className="runningTime">
                <div className="bothEnd" style={{ lineHeight: "17px" }}>
                  <p className="titleBold">총 가동 시간</p>
                  <div style={{color:'red'}}>{detailData.detail.statusId === WORKSPACE_STATUS.ERROR ? ('장애 발생 시간: '+ (detailData.detail.errorTimestamp!==null ? dayjs.unix(detailData.detail.errorTimestamp).format('YYYY/MM/DD HH:mm:ss') : ' -')) : false}</div>
                  {detailData.detail.statusId === WORKSPACE_STATUS.RETURNED ? <div>{'반납 시간: '+ (detailData.deletedAt!==null ? dayjs.unix(detailData.deletedAt).format('YYYY/MM/DD HH:mm:ss') : ' -')}</div> : false }
                  {detailData.detail.statusId === WORKSPACE_STATUS.RECALLED || detailData.detail.statusId === WORKSPACE_STATUS.SYSTEM_RECALLED ? <div>{'회수 시간: '+ (detailData.deletedAt!==null ? dayjs.unix(detailData.deletedAt).format('YYYY/MM/DD HH:mm:ss') : ' -')}</div> : false}
                </div>
                <div className="center">
                  <p style={{ fontSize: "30px", fontWeight: "bold" }}>{state.day}일 {state.hour}시간 {state.minute}분 {state.second}초{detailData.detail.statusId === WORKSPACE_STATUS.RUNNING ? '[가동중]' : false}</p>
                  <p style={{ marginTop: "7px", lineHeight: "17px" }}>워크스페이스 생성 : {dayjs.unix(detailData.startedAt).format('YYYY/MM/DD HH:mm:ss')}</p>
                </div>
                <div className="divider" style={{ margin: "0 -20px 22px" }}></div>
              </div>
              <div className="dl">
                <div className="bothEnd">
                  <div className="innerBox">
                    <p className="dt titleBold titleBottom">이미지 이름</p>
                    <div className="" style={{ paddingRight: '10px', height:'17px'}}>
                      <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.detail.image}</EllipsisToolTip>
                    </div>
                  </div>
                  <div className="innerBox">
                    <div className="subTitleStart titleBottom">
                      <p className="dt titleBold">SSH 주소</p>
                    </div>
                    {detailData.ssh &&
                    detailData.detail.statusId !== WORKSPACE_STATUS.ERROR && 
                    detailData.detail.statusId !== WORKSPACE_STATUS.RETURNED &&
                    detailData.detail.statusId !== WORKSPACE_STATUS.RECALLED &&
                    detailData.detail.statusId !== WORKSPACE_STATUS.SYSTEM_RECALLED ?
                      <div className="" style={{ paddingRight: '10px' }}>
                        <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.ssh}</EllipsisToolTip>
                      </div>
                      : <p className="">{'-'}</p>}
                  </div>
                </div>
              </div>
              <div className="dl" style={{ paddingTop: '22px' }}>
                <div className="bothEnd">
                  <div className="innerBox">
                    <div className="subTitleStart titleBottom">
                      <p className="dt titleBold">Jupyter notebook</p>
                    </div>
                    {detailData.detail.isJupyter && 
                    detailData.detail.statusId !== WORKSPACE_STATUS.ERROR&&
                    detailData.detail.statusId !== WORKSPACE_STATUS.RETURNED &&
                    detailData.detail.statusId !== WORKSPACE_STATUS.RECALLED &&
                    detailData.detail.statusId !== WORKSPACE_STATUS.SYSTEM_RECALLED ?
                      <div className="" style={{ paddingRight: '10px' }}>
                        <EllipsisToolTip options={Config.ellipsisTipOptions}><Link to={detailData.jupyter?detailData.jupyter : '#'} target="_blank">{detailData.jupyter}</Link></EllipsisToolTip>
                      </div> : <div className="">{'-'}</div>}
                  </div>
                </div>
              </div>
              <div className="dl" style={{ marginTop: "22px" }}>
                <div className="bothEnd">
                  <div className="innerBox bothEnd">
                    <p className="dt titleBold">데이터 볼륨</p>
                    {detailData.detail.templateSpec.volumes.length > 0 ?
                      <div className="dd" style={{ paddingRight: '10px' }}>
                        <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.detail.templateSpec.volumes[0].pvc}</EllipsisToolTip>
                      </div>
                      : <p className="dd">-</p>}
                  </div>
                  <div className="innerBox bothEnd">
                    <p className="dt titleBold">마운트 경로</p>
                    {detailData.detail.templateSpec.volumes.length > 0 ?
                      <div className="dd">
                        <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.detail.templateSpec.volumes[0].mountPath}</EllipsisToolTip>
                      </div>
                      : <p className="dd">-</p>}
                    {detailData.detail.templateSpec.volumes.length > 1 ?
                      <SubPopup titles={["데이터 볼륨", "마운트 경로"]} datas={detailData.detail.templateSpec.volumes} /> : false}  {/* //data두개임 */}
                  </div>
                </div>
              </div>
              <div className="divider" style={{ margin: "22px -20px 22px" }}></div>
              <div className="dl subTitleStart">
                <div className="innerBox bothEnd">
                  <p className="dt titleBold">접속 주소</p>
                  { detailData.ip ? 
                  <p className="dd">{detailData.ip}</p> : <p className="dd">-</p> }
                </div>
                <div className="innerBox bothEnd">
                  <div className="bothEnd" style={{ width: '145px' }}>
                    <p className="dt titleBold">내부 포트</p>
                    {detailData.ports.length > 0 ?
                      <p className="dd">{detailData.ports[0].internal}</p> : <p className="dd">-</p>}
                  </div>
                  <div className="bothEnd" style={{ width: '145px' }}>
                    <p className="dt titleBold">외부 포트</p>
                    {detailData.ports.length > 0 ?
                      <p className="dd">{detailData.ports[0].external}</p> : <p className="dd externalportbox">-</p>}
                  </div>
                </div>
                <div style={{ width: "0px", position: "relative" }}>
                  <div style={{ position: "absolute", right: "0px", bottom: "-9px" }}>
                    {detailData.ports.length > 1 ?
                      <SubPopup titles={['내부포트', '외부포트']} datas={detailData.ports} /> : false}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="topbox">
              <div className="titleBold" style={{ marginBottom: '30px' }}>{detailData.rgroupInfo.isCommon === true ? '기본 리소스 그룹' : detailData.namespace}</div>
              <div className="resourceGroupBox bothEnd" style={{ alignItems: 'start' }}>
              <div className="dl vertical half">
                  <div className="vertical">
                    <div className="dt resSubtitle">노드 (IP/이름){detailData.rgroupInfo.nodeList.length > 1 ?
                      <div style={{fontWeight:'400'}}><SubPopup titles={["노드"]} datas={detailDataNode} /></div> : false}</div>
                    {detailData.rgroupInfo.nodeList.length > 0 ?
                      <div className="dd resContent">
                        <p>{detailData.rgroupInfo.nodeList[0].ip + " / " + detailData.rgroupInfo.nodeList[0].name}</p>
                      </div>
                      : <div className="dd resContent">{'-'}</div>}
                    <div className="dt resSubtitle">이미지 레지스트리</div>
                    <div className="dd resContent">
                      <p>{detailData.rgroupInfo.reg ? detailData.rgroupInfo.reg : '-'}</p>
                    </div>
                  </div>
                </div>
                <div className="dl vertical half">
                  <div className="dt resSubtitle">볼륨{detailData.rgroupInfo.volumeList && detailData.rgroupInfo.volumeList.length > 1 ?
                    <div style={{fontWeight:'400'}}><SubPopup titles={["볼륨"]} datas={detailDataVolume} /></div> : false}</div>
                  {detailData.rgroupInfo.volumeList && detailData.rgroupInfo.volumeList.length > 0 ?
                    <>
                      <div className="dd resContent">
                        <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.rgroupInfo.volumeList[0].name + " / " + detailData.rgroupInfo.volumeList[0].size}</EllipsisToolTip>
                      </div>
                    </> : <div className="dd resContent">{'-'}</div>}
                </div>
              </div>
              <div className="divider" style={{ margin: "22px -20px 30px" }}></div>
              {/* <div className="divider" style={{ margin: "20px 0px" }}></div> */}
              <div className="resourceBox">
                <div className="titleBox">
                  <div className="titleBoxWrap">
                    <p className="boxTitle">리소스 타입</p> 
                  </div>
                  <div className="detail titleBold" style={{display:'flex'}}>
                    <div className='tooltipWrap' style={{ maxWidth: '540px' }}>
                      <EllipsisToolTip options={Config.ellipsisTipOptions}>{detailData.resourceBlock.type}</EllipsisToolTip>
                    </div>
                    {detailData.resourceBlock.resourceType === RESOURCE_TYPE.CPU ? false : <div style={{ display: 'inline' }}>[<span className='color'>{detailData.resourceBlock.gpuNum}</span>개]</div>}
                  </div>
                </div>
              </div>
              <div className="bottomBox bothEnd" style={{ alignItems: "end" }}>
                <div className="resourceBox vertical half">
                  {
                    detailData.resourceBlock.resourceType === RESOURCE_TYPE.CPU ? 
                    <div className="inner">
                      <div className="" style={{fontWeight:'700'}}>
                        <div className="contentBox">
                          <p className="dl">
                            <span className="dt">CPU: </span>
                            <span className="dd">{detailData.resourceBlock.cpu} 코어</span>
                          </p>
                          <p className="dl">
                            <span className="dt">MEM: </span>
                            <span className="dd">{detailData.resourceBlock.mem}{' '+detailData.resourceBlock.memUnit}</span>
                          </p>
                        </div>
                      </div>
                    </div> : 
                    <div className="inner">
                    <div className="">
                      <div className="contentBox">
                        <p className="dl">
                          <span className="dt">CPU: </span>
                          <span className="dd">{detailData.resourceBlock.cpu} 코어</span>
                        </p>
                        <p className="dl">
                          <span className="dt">MEM: </span>
                          <span className="dd">{detailData.resourceBlock.mem}{' '+detailData.resourceBlock.memUnit}</span>
                        </p>
                      </div>
                    </div>
                  </div> 
                  }
                </div>
                <div className="blockPodBox vertical half">
                  <div className="inner">
                    <div className="dl">
                      <span className="dt">GPU: </span>
                      <span className="dd">{detailData.resourceBlock.gpuPer ? detailData.resourceBlock.gpuPer+' %': '-'}</span>
                    </div>
                    <div className="dl" style={{ marginTop: "5px" }}>
                      <span className="dt">GPU MEM: </span>
                      <span className="dd">{detailData.resourceBlock.gpuMem ? detailData.resourceBlock.gpuMem+ ' ' + detailData.resourceBlock.gpuMemUnit : '-'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col3">
          <div>
            <h3 className="chartTitle">CPU</h3>
            {
              gaugeData.cpuUsage > -1 && gaugeData.cpuUsage !== null ?
                <GaugeChart data={gaugeData.cpuUsage} stops={{ warning: 0.8, error: 0.9 }} /> : <NoData height={200} />
            }
          </div>
          <div>
            <h3 className="chartTitle">Storage</h3>
            {
              gaugeData.storageUsage > -1 && gaugeData.storageUsage !== null ?
                <GaugeChart data={gaugeData.storageUsage} stops={{ warning: 0.6, error: 0.7 }} /> : <NoData height={200} />
            }
          </div>
          <div>
            <h3 className="chartTitle">Memory</h3>
            {
              gaugeData.memoryUsage > -1 && gaugeData.memoryUsage !== null ?
                <GaugeChart data={gaugeData.memoryUsage} stops={{ warning: 0.8, error: 0.9 }} /> : <NoData height={200} />
            }
          </div>
        </div>
        <section>
          <DateRangePicker ref={pickerRef} initial={{ from: state.initialFrom, to: state.initialTo }} default={{ from: state.range.from, to: state.range.to }} limit={state.limit} data={state.range} onChange={onChangeDatePicker} />

          {
            detailData.resourceBlock.resourceType === RESOURCE_TYPE.GPU || detailData.resourceBlock.resourceType === RESOURCE_TYPE.MIG ? //분할된 GPU가 아니고 CPU가 아닐 때 그래프 다 보여줌
              detailData.resourceBlock.resourceType === RESOURCE_TYPE.GPU ?
                <>
                  <h3 className="chartTitle">GPU Memory</h3>
                  {
                    chartDatas.gpuMemory.length ?
                      <LineChart unit="%" height={350} min={0} max={100} data={chartDatas.gpuMemory} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                      <NoData height={322} />
                  }
                  <h3 className="chartTitle">GPU Utilization rate</h3>
                  {
                    chartDatas.gpuUtil.length ?
                      <LineChart unit="%" height={350} min={0} max={100} data={chartDatas.gpuUtil} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                      <NoData height={322} />
                  }
                  <h3 className="chartTitle">GPU Temperature</h3>
                  {
                    chartDatas.gpuTemp.length ?
                      <LineChart unit="℃" height={350} min={0} data={chartDatas.gpuTemp} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                      <NoData height={322} />
                  }
                </> : <>
                  <h3 className="chartTitle">GPU Memory</h3>
                  {
                    chartDatas.gpuMemory.length ?
                      <LineChart unit="%" height={350} min={0} max={100} data={chartDatas.gpuMemory} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                      <NoData height={322} />
                  }
                  <h3 className="chartTitle">GPU Temperature</h3>
                  {
                    chartDatas.gpuTemp.length ?
                      <LineChart unit="℃" height={350} min={0} data={chartDatas.gpuTemp} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                      <NoData height={322} />
                  }
                </>
              : detailData.resourceBlock.resourceType === RESOURCE_TYPE.BLOCK ?
              <>
                <h3 className="chartTitle">GPU Temperature</h3>
                {
                  chartDatas.gpuTemp.length ?
                    <LineChart unit="℃" height={350} min={0} data={chartDatas.gpuTemp} datetime={true} doubleY={true} legend={true} sharedTooltip={true} showTooltipUnit={true} zoomType="x" /> :
                    <NoData height={322} />
                }
              </> : false
          }
          <h3 className="chartTitle">Network I/O</h3>
          {
            chartDatas.netUsage.length ?
              <LineChart height={320} zoomType="x" /* unit="Byte" */ format={"B"} datetime={true} data={chartDatas.netUsage} legend={true} doubleY={true} sharedTooltip={true} showTooltipUnit={true} minX={dayjs(state.range.from).unix()*1000} maxX={dayjs(state.range.to).unix()*1000} 
              toolTipFormat={function(this:Highcharts.TooltipFormatterContextObject) {
                const date =  dayjs(this.x).format('MM/DD HH:mm')
                let result = `${date}<br>`
                this.points?.forEach((d:any)=>{
                  const data = Utils.byteTransform(d.y)
                    result += `<span style="color:${d.color}">\u25CF</span> ${d.series.name}: <b>${data.value} ${data.size}</b><br/>`
                  //result += `<span style="color:${d.color}">\u25CF</span> ${d.series.name}: <b> ${d.y?.toLocaleString('ko-KR')}Byte</b><br/>`
                })
                return result
              }}/> :
              <NoData height={322} />
          }
        </section>
      </> : false}
    </WorkspaceDetailFragment>)
}

const WorkspaceDetailFragment = styled.div`
  .titleArea .error h2 { color:#f30b0b; }
  .titleArea p { margin-top:10px; font-size:14px; color:#f30b0b; }
  .pageTitle p { font-size: 24px; padding:5px 15px; color:#303030; min-width:200px; }
  .titleBold { font-weight:bold; } 
  .subTitleStart { display:flex; justify-content:start; align-items:center; }
  .titleBottom { margin-bottom:7px; }
  .resSubtitle {align-items:center; font-weight:700; }
  .dropWrap { right:-32px; }
  .datetime { font-size:14px; color:#303030; }
  .info { margin-top:10px; gap:40px; }
  .info .title { margin-top:25px; font-size:14px; }
  .summary { display:flex; gap:30px; margin-top:10px; }
  .summary p {display:flex; gap:10px; align-items:center; font-size:14px; }
  .summary span { width:10px; height:10px; border-radius:50%; background:#a7a7a7; }
  .summary span.normal { background:#198038; }
  .summary span.caution { background:#ffb83f; }
  .summary span.warn { background:#e95000; }
  .summaryEmpty { font-size:14px; color:#a7a7a7; text-align:center; margin-top:10px; }

  div.box { width:640px; box-sizing:border-box; height:404px; }
  .box { background:#f5f5f5; border-radius:2px; padding:30px 30px 20px; font-size:14px; color:#303030; }
  .box img { display:inline-block; vertical-align: text-bottom; }
  .box img.moreButton { width:17px; height:17px; margin:0; }
  .center { text-align:center; padding:17px; }
  .box .dl { display:flex; line-height:17px; align-items:center;}
  //.box .dl + .dl{margin-top:12px;}
  .box .dt{flex:none;}
  .box .dd{overflow:hidden;flex:1;display:flex; align-items:center; padding-right:1px;}
  .box .dd:before{content:'';display:inline-block;width:1px;height:11px;margin-right:12px;background:none;}
  .box .dd button{overflow:hidden; margin-left:8px; width:18px; height:18px; white-space:nowrap; text-overflow:ellipsis; color:#303030;}
  .normalWrap button{overflow:hidden; margin-left:10px; width:18px; height:18px; white-space:nowrap; text-overflow:ellipsis; color:#303030;}
  .box .dd a{color:#2c78ff;text-decoration:underline;}
  .box .vertical{display:block;}
  .box .dt + .vertical{margin-top:15px;}
  .box .topBox {width:590px;}
  .box .resourceGroupBox .dt{display:flex;}
  .box .resourceGroupBox .dt button{margin-left:10px; width:18px; height:17px;}
  .box .resourceGroupBox .dd:before{display:none;}
  .box .resourceGroupBox .dd + .dt{margin-top:22px;}
  .box .resourceGroupBox div{line-height:17px; align-items:center;}
  .box .bottomBox{width:580px;}
  .box .bottomBox .dd:before{display:none;}
  .box .resPreviewBox{max-width:490px; height:22px; overflow:hidden; display:flex; align-items:start;}
  .box .resPreviewWrap{display:flex; gap:10px; flex-wrap:wrap;}
  .box .resPreviewWrap > p{font-size:14px; border:1px solid #DFDFDF; border-radius:100px; background-color:#F5F5F5; padding: 1px 10px;}
  .box .resContent {margin-top:5px;}
  .half {width:50%;}

  .bothEndTop{display:flex; align-items:top; justify-content: space-between;}
  .ellipsisToolTipWrap {display:flex; align-items:center;}
  .info {margin-top:10px;}

  .resourceBox{color:#303030;font-size: 0}
  .resourceBox .titleBox div{line-height: 17px;}
  .resourceBox .titleBox .boxTitle {font-weight:bold; font-size:14px}
  .resourceBox .contentBox{padding:18px 0px 0px;}
  .resourceBox .contentBox .dl .dd{margin-left:4px;}
  .resourceBox .detail{margin-top:5px; font-size:14px;}
  .tooltipWrap {height: 17px;}
  .resourceBox .dl{align-items:center;justify-content:space-between;font-size:14px;}
  .resourceBox .dl + .dl {margin-top:7px }
  .resourceBox .dd:before{content:'';display:none;}
  .resourceBox b span{color:#2c78ff;}
  .blockPodBox .dd {margin-left:4px;}

  .col3 { display:flex; gap:60px; margin-top:40px; }
  .col3 > div { flex:1; }

  .col3 .chartTitle { margin-top:0;}
  section{ margin-top:50px; padding-top:50px; border-top:1px solid #ccc; }
  .period + .chartTitle { margin-top:30 px; }
  .chartTitle { margin:80px 0 10px; font-size:14px; text-align:center; font-weight:600; }

  .leftChart {width:50%; margin:0 20px 70px 0;}
  .rightChart {width:50%; margin:0 0 70px 20px;}
  .divider {background:#DFDFDF;}
  .autoscaleText {font-weight:bold; color:#2C78FF;}
  .autoscaleField .dd {margin-left:18px;}
  .ipBox {width:220px;}
  .portBox {width:360px; }
  .externalportbox {width:100px;}
  .innerBox {width:290px;}
  .innerBox .dd span{width:172px;}
  .bothEndTop { display:flex; align-items:start; justify-content: space-between; }
  .ellipsisToolTipWrap { max-width:160px; display:flex; align-items:center; }
  .ellipsisToolTipWrapCpu { max-width:270px; display:flex; align-items:center; }
  .color { color:#2C78FF; }
  .disableview { display:none; }
`

export default WorkspaceDetail