import styled from 'styled-components'

import ModalAction from '../modal/ModalAction'
import { IModalButton } from '../../../events/ModalEvent'
import { useEffect, useRef } from 'react'
import { divide } from 'lodash'

interface IMessagePopupProps {
  message:string
  button?:IModalButton[]
  // title?:string
  onClose:Function
}

const MessagePopup = (props:IMessagePopupProps) => {
  const closeHandler =() => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitRef = useRef<any>()

  useEffect(() => {
    if (submitRef.current) {
      submitRef.current.focus()
    }
  }, [])

  return (
    <MessagePopupFragment>
      {props.button?.length ?
        <div className="dialougeRect">
          <p>{props.message}</p>
          <ModalAction list={props.button} onClose={closeHandler} />
        </div> :
        <div className="confirmRect">
          {/* ok button */}
          <p>{props.message}</p>
          <div className="btnWrap">
            <button ref={submitRef} className="btn blue submitButton" onClick={closeHandler}>확인</button>
          </div>

          {/* title + (x) button
          {props.title ?
            <>
              <div className="confirmWrap" style={{alignItems:'center'}}>
                <div style={{ width: '288px' }} className="confirmTitle">{props.title}</div>
                <div className="exitBtnRect" onClick={closeHandler}><img src="/images-v2/popup-exit-white.svg" alt="exit" /></div>
              </div>
              <p style={{marginTop:'12px'}}>{props.message}</p>
            </>
            :
            <div className="confirmWrap">
              <p style={{ width: '288px' }}>{props.message}</p>
              <div className="exitBtnRect" onClick={closeHandler}><img src="/images-v2/popup-exit-white.svg" alt="exit" /></div>
            </div>
          } */}
        </div>
      }
    </MessagePopupFragment>
  )
}

const MessagePopupFragment = styled.div`
  .dialougeRect{width:336px; padding:24px 32px 16px 32px;}
  //.confirmRect{width:344px; padding:24px 24px 24px 32px;} title + (x) button
  .confirmRect{width:336px; padding:24px 32px 16px 32px;} // ok button
  .confirmWrap{display:flex; align-items:start; justify-content:space-between;}
  .exitBtnRect{padding:3px; cursor:pointer;}
  .exitBtnRect img{display:block;}
  .confirmTitle{font-size:18px;line-height:21.48px;font-weight:700px;padding:5.5px 0;}

  p {font-size:inherit; word-break:break-all; white-space:pre-line;}
  
  .btnWrap{display:flex; align-items:center; justify-content:flex-end; gap:8px; margin-top:24px; margin-right:-16px;}
  .btnWrap .btn{min-width:56px; height:32px; line-height:32px; border-radius:6px; font-weight:500; background-color:#444444;}
  .btnWrap .btn.grey{opacity:0.4;}
  .btnWrap .btn.blue{opacity:1;}
  .btnWrap .btn:hover{opacity:1; background-color:#555555; color:#FFFFFF;}
`

export default MessagePopup