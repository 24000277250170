import styled from "styled-components"
import Config from "../Config"
import Button, { BUTTON_COLOR, BUTTON_LINK } from "./components/uiv2/Button"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import EllipsisToolTip from "ellipsis-tooltip-react-chan"


interface WaitingForJoinProps {
}
interface WaitingForJoinState {
  id:string
  name:string
}

const WaitingForJoin = (props:WaitingForJoinProps) => {
  const location = useLocation()

  const [ state, setState ] = useState<WaitingForJoinState>({
    id: '',
    name: ''
  })

  useEffect(() => {
    setState({
      ...state,
      id: location.state.data.userId,
      name: location.state.data.name
    })
  }, [])

  return (
    <WaitingForJoinFragment>
      <div className="scrollWrap">
        <div className="authWrap">
          <header>
            <img src="/images-v2/logo-login.svg" alt="AIPub" />
            <div>
              <div className="title">인공지능<br /><span>개<span>발·</span>운영</span>의 모든것</div>
              <p className="sub1">AI Pub 사이트는 크롬 브라우저 사용을 권장합니다.</p>
              <p className="sub2">ⒸAIPub, TEN Inc</p>
              <p className="sub3 version">BUILD <span>{Config.env.build}</span></p>
            </div>
          </header>
          <section className="infoWrap">
            <p className="title"><span>{state.name}</span>님의<br/>계정 등록 승인을 요청했습니다.</p>
            <div className="infoBox">
              <div className="infoLine">
                <div className="label">이름</div>
                <div style={{ maxWidth: '450px', height: '16px' }}><EllipsisToolTip options={Config.ellipsisTipOptions}>{state.name}</EllipsisToolTip></div>
              </div>
              <div className="infoLine">
                <div className="label">아이디</div>
                <div>{state.id}</div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="descBox">
              <span className="bold">{state.name}</span>님은 현재 <span className="color">’승인 대기’</span> 상태입니다.<br/>
              본 서비스는 관리자 승인 완료 후 사용 가능합니다.<br/>
              승인 지연 발생 시 관리자에게 문의 바랍니다.<br/>
            </div>
            <div className="btnWrap">
                <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} target={BUTTON_LINK.SELF} url="/">로그인 화면으로 가기</Button>
            </div>
          </section>
        </div>
      </div>
    </WaitingForJoinFragment>
  )
}

const WaitingForJoinFragment = styled.div`
  height:100vh;background-color:#F7F8FB;
  .scrollWrap{overflow:auto;height:100vh;display:flex;}
  .authWrap{display:flex;justify-content:space-between;align-items:center;width:1440px;min-height:760px;padding:64px;margin:0 auto;box-sizing:border-box;}
  header{text-align:start; width:644px;}
  header img{display:block;width:179.89px;}
  header .title{font-weight:700;line-height:60px;font-size:40px;color:#333333;margin-top:80px;}
  header .title span{color:#4B82FF;}
  header .title span>span{letter-spacing:3px;}
  header p.sub1{font-weight:400;font-size:13px;line-height:15.51px;color:#646469;margin-top:16px;height:16px;}
  header p.sub2{font-weight:500;font-size:12px;line-height:14.32px;color:#B4B4BE;margin-top:8px;height:14px;}
  header p.sub3{font-weight:500;font-size:9px;line-height:10.74px;color:#FAFAFB;margin-top:8px;height:11px;}
  section.infoWrap{display:block;width:516px;height:418px;border-radius:16px;padding:64px;background-color:#FFFFFF;box-shadow:0 12px 16px 0 #1B1D1F0D, 0 6px 12px 0 #1B1D1F0D;}
  section.infoWrap .title{font-weight:700;font-size:32px;line-height:48px;margin-bottom:64px;color:#333333;word-break:break-all;}
  section.infoWrap .title span{color:#4B82FF;}
  .infoBox .infoLine{display:flex;justify-content:start;align-items:start;font-size:14px;font-weight:400;line-height:normal;}
  .infoBox .infoLine .label{color:#878791;font-size:13px;width:56px;}
  .infoBox .infoLine+.infoLine{margin-top:12px;}
  .divider{height:1px; margin:24px 0; background:#ccc;}
  .descBox{font-size:13px;font-weight:400;line-height:20px;color:#333333;margin-bottom:64px;}
  .descBox span{font-weight:600;}
  .descBox span.color{color:#4B82FF;}
  .btnWrap{display:flex;flex-direction:column;width:516px;}

  //scroll bar custom
  /* .scrollWrap::-webkit-scrollbar { width:18px; height:18px; }
  .scrollWrap::-webkit-scrollbar-track { background-color:#F7F8FB; width:18px;}
  .scrollWrap::-webkit-scrollbar-thumb { background:#CFCFD6; border-radius:10px; border:6px solid #F7F8FB;}
  .scrollWrap::-webkit-scrollbar-button { display:none; }
  .scrollWrap::-webkit-scrollbar-corner { background-color:transparent;} */
`

export default WaitingForJoin