import { useRef, useState } from "react"
import PopupController from "../../controller/PopupController"
import { ILoginResult, LOGIN_RESULT, User } from "../../model/User"
import styled from "styled-components"
import ModalMessage from "../components/ModalMessage"
import InputBox, { TEXT_INPUT_RULE, TEXT_INPUT_SHAPE, TEXT_INPUT_TYPE } from "../components/uiv2/InputBox"
import Button, { BUTTON_COLOR, BUTTON_LINK, BUTTON_SIZE } from "../components/uiv2/Button"
import Form from "../components/uiv2/Form"


interface LoginProps { }
interface LoginState {
  id: string,
  pw: string,
  error: boolean | string,
  blankErrorMsgId: string,
  blankErrorMsgPw: string
}

const Login = (props: LoginProps) => {
  const popupController = PopupController.getInstance()
  const user = User()
  const formRef = useRef<any>()
  let idRef = useRef<any>()
  let pwRef = useRef<any>()

  const [state, setState] = useState<LoginState>({
    id: "",
    pw: "",
    error: false,
    blankErrorMsgId: "",
    blankErrorMsgPw: ""
  })

  const submitHandler = async (e:any) => {
    let errorMessage:string = ''

    // if(!formRef.current?.validate()) {
    //   return
    // } 
    
    if (!state.id) {
      errorMessage = '아이디를 입력해 주세요.'
      setState({
        ...state,
        error: errorMessage ? errorMessage : '',
        blankErrorMsgId: state.blankErrorMsgId === ' ' ? '  ' : ' '
      })
      return
    } else if (!state.pw) {
      errorMessage = '비밀번호를 입력해 주세요.'
      setState({
        ...state,
        error: errorMessage ? errorMessage : '',
        blankErrorMsgPw: state.blankErrorMsgPw === ' ' ? '  ' : ' '
      })
      return
    }

    try {
      let payload = formRef.current?.serialize()
      const response:ILoginResult = await user.login(payload.id, payload.pw)
      switch (response.status) {
        case LOGIN_RESULT.OKAY:
          // OK - DOES NOTHING
          // window.dispatchEvent(new PageEvent(PageEvent.GET_MY_INFO))
          break
        case LOGIN_RESULT.NOT_AUTHORIZED:
          errorMessage = '아직 승인되지 않은 계정입니다. 관리자의 승인 완료 후 로그인 가능합니다.'
          break
        case LOGIN_RESULT.ACCOUNT_LOCKOUT:
          // popupController.confirm('비밀번호 오류 횟수 5회 이상 실패하여 계정이 사용 중지되었습니다. \n관리자에게 계정 잠금 해제를 요청해 주세요.')
          errorMessage = '비밀번호 5회 오류로 계정 사용이 중지되었습니다. 관리자에게 문의해 주세요.'
          break
        case LOGIN_RESULT.ACCOUNT_DORMANT:
          // popupController.confirm('90일 이상 미사용으로 개인 정보 보호를 위해 휴면상태로 전환된 계정입니다. \n관리자에게 사용자 계정 휴면 해제를 요청해 주세요.')
          errorMessage = '90일 이상 미사용으로 휴면 상태로 전환된 계정입니다. 관리자에게 문의해 주세요.'
          break
        case LOGIN_RESULT.INVALID_ID:
          errorMessage = '일치하는 아이디가 존재하지 않습니다. 아이디를 확인해 주세요.'
          break
        case LOGIN_RESULT.INVALID_PW:
          errorMessage = `비밀번호 ${response.failedPasswordAttempts}회 오류입니다. 비밀번호 5회 오류 시, 사용이 중지됩니다.`
          break
        case LOGIN_RESULT.INVAILD_INFO:
        default:
          errorMessage = '잘못된 아이디 혹은 비밀번호 입니다. 로그인 정보를 잊어버린 경우 관리자에게 문의해 주세요.'
          break
      }
      setState({
        ...state,
        error: errorMessage ? errorMessage : false
      })
    } catch(error) {

    }
  }

  return (
    <LoginFragment>
      <ModalMessage />
      <div className="scrollWrap">
        <div className="authWrap">
          <header>
            <img src="/images/logo-login.png" alt="AIPub" />
            <div>
              <div className="title">인공지능<br /><span>개<span>발·</span>운영</span>의 모든것</div>
              <p className="sub1">AI Pub 사이트는 크롬 브라우저 사용을 권장합니다.</p>
              <p className="sub2">ⒸAIPub, TEN Inc</p>
            </div>
          </header>
          <section className="formWrap">
            <p className="title">로그인</p>
            <div className="loginWrap">
              <Form ref={formRef}>
                <div className="formGroup">
                  <label htmlFor="name">아이디</label>
                  <InputBox id="id" ref={idRef}
                    type={TEXT_INPUT_TYPE.TEXT}
                    shape={TEXT_INPUT_SHAPE.UNDERLINE}
                    value={state.id} errorText={state.blankErrorMsgId}
                    placeholder="아이디를 입력해 주세요."
                    /* rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED,
                      invalidateMessage: " " //"아이디를 입력해 주세요."
                    }]} */
                    onChange={(id:any, value:any) => {setState({...state, id: value})}} />
                </div>
                <div className="formGroup">
                  <label htmlFor="name">비밀번호</label>
                  <InputBox id="pw" ref={pwRef}
                    type={TEXT_INPUT_TYPE.PASSWORD}
                    shape={TEXT_INPUT_SHAPE.UNDERLINE}
                    value={state.pw} errorText={state.blankErrorMsgPw}
                    placeholder="비밀번호를 입력해 주세요."
                    /* rules={[{
                      basis: TEXT_INPUT_RULE.REQUIRED,
                      invalidateMessage: " " //"비밀번호를 입력해 주세요."
                    }]} */
                    onChange={(id:any, value:any) => {setState({...state, pw: value})}} />
                </div>
                <div className="submitButton">
                  <Button color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>로그인</Button>
                  {state.error === false ? false : <p className="message">{state.error}</p>}
                </div>
              </Form>
              <div className="joinWrap">
                <p>아이디가 없으신가요? <span>사용자 등록</span>을 하시면 AI Pub을 이용하실 수 있습니다.</p>
                <Button color={BUTTON_COLOR.OUTLINE_DEFAULT} target={BUTTON_LINK.SELF} url="/join">사용자 등록</Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </LoginFragment>
  )
}

const LoginFragment = styled.div`
  height:100vh;background-color:#F7F8FB;
  .scrollWrap{overflow:auto;height:100vh;}
  .authWrap{display:flex;justify-content:space-between;align-items:center;width:1440px;min-height:760px;padding:64px;margin:0 auto;box-sizing:border-box;}
  header{text-align:start; width:644px;}
  header img{display:block;width:179.89px;}
  header .title{font-weight:700;line-height:60px;font-size:40px;color:#333333;margin-top:80px;}
  header .title span{color:#4B82FF;}
  header .title span>span{letter-spacing:3px;}
  header p.sub1{font-weight:400;font-size:13px;line-height:15.51px;color:#646469;margin-top:16px;}
  header p.sub2{font-weight:500;font-size:12px;line-height:14.32px;color:#B4B4BE;margin-top:8px;}
  section.formWrap{display:block;width:516px;height:632px;border-radius:16px;padding:64px;background-color:#FFFFFF;box-shadow:0 12px 16px 0 #1B1D1F0D, 0 6px 12px 0 #1B1D1F0D;}
  section.formWrap .title{font-weight:700;font-size:32px;line-height:38.19px;margin-bottom:64px;}
  .loginWrap{display:flex;flex-direction:column;justify-content:space-between;width:516px;height:530px;}
  .formGroup{display:flex;flex-direction:column;justify-content:space-between;width:516px;/* height:76px; */gap:12px;}
  .formGroup+.formGroup{margin-top:48px;}
  .formGroup label{font-weight:700;font-size:14px;line-height:16.71px;margin-bottom:12px;}
  .submitButton{margin-top:48px;width:516px;display:flex;flex-direction:column;}
  .joinWrap{display:flex;flex-direction:column;justify-content:space-between;width:516px;height:70px;}
  .joinWrap p{font-weight:400; font-size:12px; line-height:14.32px; color:#878791;}
  .joinWrap p span{font-weight:600; color:#333333;}
  form .message{margin:8px 0 0 0; font-weight:400; font-size:12px; line-height:16px;}


  //scroll bar custom
  .scrollWrap::-webkit-scrollbar { width:18px; height:18px; }
  .scrollWrap::-webkit-scrollbar-track { background-color:#F7F8FB; width:18px;}
  .scrollWrap::-webkit-scrollbar-thumb { background:#CFCFD6; border-radius:10px; border:6px solid #F7F8FB;}
  .scrollWrap::-webkit-scrollbar-button { display:none; }
  .scrollWrap::-webkit-scrollbar-corner { background-color:transparent;}

`

export default Login