import { useRef, useState, createRef } from "react"
import regularExpression from "../../utils/regularExpression"
import utils from "../../utils"
import styled from "styled-components"
import { User } from "../../model/User"
import { useRecoilState } from "recoil"
import { IUserAuthInfo, authState } from "../../states/authStates"
import { IUserInfo, userInfoState } from "../../states/userInfoState"
import { useNavigate } from "react-router"
import PopupController from "../../controller/PopupController"
import { ERROR_TYPE } from "../../interfaces/Error"
import Form from '../components/uiv2/Form'
import Button, { BUTTON_SIZE, BUTTON_COLOR } from '../components/uiv2/Button'
import InputBox, { IInputBoxRule, TEXT_INPUT_RULE, TEXT_INPUT_TYPE } from '../components/uiv2/InputBox'
import { uniqueId } from 'lodash'


interface PasswordEditOpsProps {
  pwExpiredUser:boolean
  onSubmit:Function
  onCancel:Function
  isAdmin?:boolean
  passwordInitialized:boolean
}

interface PasswordEditOpsState {
  pw:string
  pwError:string
  confirmPw:string
  confirmPwError:string
  currentPw:string
  currentPwError:string
}

const PasswordEdit = (props:PasswordEditOpsProps) => {
  //console.log(props)
  const [ authInfo, setAuthInfo ] = useRecoilState<IUserAuthInfo|null>(authState)
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)
  const user = User()
  const navigate = useNavigate()
  const popupController = PopupController.getInstance()
  const formRef = useRef<any>()
  const RegExp = regularExpression.regExp

  let refId:string[] = []
  for (let i = 0; i <= 999; i++) {
    refId.push(uniqueId())
  }
  let elementsRef = refId.map(()=>createRef())

  const [ state, _setState ] = useState<PasswordEditOpsState>({
    pw: '',
    pwError:'',
    confirmPw: '',
    confirmPwError:'',
    currentPw: '',
    currentPwError:'',
  })
  const stateRef = useRef(state)
  const setState = (data:any) => {
    stateRef.current = data
    _setState(data)
  }

  const pwEditPassHandler = async (e:any) => {
    try{
      //e.preventDefault()
      const response = await user.pwEditPass(Number(userInfo?.userNo))
      if(response === 200) {
        if(authInfo) {
          setAuthInfo({
            ...authInfo,
            isPasswordExpired: false
          })
        }
        navigate('/services')  
      }
    } catch (error: any) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - d3d1f3')
      return
    }
  }

  const submitHandlerPw = async () => {
    if(formRef.current.validate() === false) {
      if ( !state.currentPw || !state.pw || !state.confirmPw ) {
        popupController.confirm('필수 입력 값이 없는 필드가 존재합니다. 해당 필드를 확인해 주세요.')
      } else if (state.pw !== state.confirmPw) {
        popupController.confirm('비밀번호가 일치하지 않습니다. 다시 입력해 주세요.')
      } else {
        popupController.confirm('잘못된 입력 값이 존재합니다. 해당 필드를 확인해 주세요.')
      }
      return false
    }
    let data = formRef.current?.serialize()
    
    try {
      let payload = {
        userNo: userInfo?.userNo,
        userId: userInfo?.userId,
        orgPw: data.currentPw,
        pw: data.pw,
      }
      const response = await user.checkPw(userInfo?.userId? userInfo.userId : '',state.currentPw)
      if (response?.status === 200) {
        if (stateRef.current.pw === stateRef.current.currentPw) {
          setState({
            ...state,
            pwError: '사용 중인 비밀번호와 일치합니다.'
          })
          popupController.confirm('사용 중인 비밀번호와 일치합니다. 다른 비밀번호로 입력해 주세요.')
          return false
        } else {
          const response = await props.onSubmit(payload)
        }
      }
    } catch (e) {
      if ((e as Error).message === ERROR_TYPE.WRONGPW) {
        popupController.confirm('사용 중인 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.')
        setState({
          ...state,
          currentPwError: '사용 중인 비밀번호와 일치하지 않습니다.'
        })
      } else {
        // 여기서 api 호출을 하지 않음, api 호출해주는 부분에서 에러 코드 띄워주기 때문에 여긴 주석처리 함
        return
      }
    }
  }

  const cancelHandler = (e:any) => {
    //e.preventDefault()
    props.onCancel()
  }

  return (
    <PasswordEditOpsFragment>
      <div className="formWrap">
        <div className="titleWrap">
          <h1>비밀번호 변경</h1>
          {props.passwordInitialized === true ?
            props.pwExpiredUser ?
              <div className="subscription">
                <div style={{ color: '#333' }}>60일 이상 동일 비밀번호를 사용 중입니다. </div>
                <div>소중한 개인 정보 보호를 위해 비밀번호를 주기적으로 변경해 주세요.</div>
              </div>
              : <div className="subscription">
                <div style={{ color: '#333' }}>관리자에 의해 초기화 된 비밀번호는 반드시 새 비밀번호로 변경해 주세요.</div>
                <div>안전한 비밀번호로 내정보를 보호하세요.</div>
              </div>
            : <div className="subscription">
              안전한 비밀번호로 내정보를 보호하세요.</div>
          }
        </div>
        <Form ref={formRef} >
          <section>
            <div className="inputWrap">
              <label className="label">현재 비밀번호 <span className="requiredMark">•</span></label>
              <InputBox id="currentPw" ref={elementsRef[0]} 
              type={TEXT_INPUT_TYPE.PASSWORD} 
              placeholder='현재 사용 중인 비밀번호를 입력해 주세요.' 
              value={state.currentPw} 
              errorText={state.currentPwError} 
              onChange={(id:any, value:any) => {
                setState({
                  ...state,
                  currentPw: value
                })
              }} />
            </div>
          </section>
          <section>
            <div className="inputWrap">
              <label className="label">새 비밀번호 <span className="requiredMark">•</span></label>
              <InputBox id="pw" ref={elementsRef[1]}
              type={TEXT_INPUT_TYPE.PASSWORD} 
              placeholder="신규 비밀번호를 입력해 주세요." 
              value={state.pw} 
              errorText={state.pwError} 
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED,
                invalidateMessage: '필수 입력 항목입니다.'
              },{
                basis: TEXT_INPUT_RULE.REGEXP,
                rule: RegExp.PW_REG_EXP,
                invalidateMessage: '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP_NOT,
                rule: RegExp.PW_REG_SPECIAL_SYMBOLS,
                invalidateMessage: '9자 이상 영어 대소문자, 숫자, 특수문자 $@!%*#?&를 사용하여 입력해 주세요.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP_NOT,
                rule: RegExp.PW_REG_SAME,
                invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
              }, {
                basis: TEXT_INPUT_RULE.REGEXP_NOT,
                rule: RegExp.PW_REG_SAME_SYMBOLS,
                invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
              }, {
                basis: TEXT_INPUT_RULE.FUNCTION,
                rule: (value:any) => {
                  if (value && utils.pwContinue(value)) return true
                },
                invalidateMessage: '3자 이상 동일하거나 연속된 문자, 숫자 사용이 불가합니다.'
              },{
                basis: TEXT_INPUT_RULE.FUNCTION,
                rule: (value:any, rule:IInputBoxRule) => {
                  if (rule.ref && rule.ref.current !== null && rule.ref.current.getValue) {
                    const targetValue = rule.ref.current.getValue()
                    if(targetValue !== value && targetValue) {
                      return true
                    } else if (targetValue === value) {
                      return false
                    }
                  }
                },
                ref: elementsRef[2],
                invalidateMessage: '',
                refsInvalidateMessage: '비밀번호가 일치하지 않습니다.'
              }]}
              onChange={(id:any, value:any) => {
                setState({
                  ...state,
                  pw: value
                })
                }} />
            </div>
          </section>
          <section>
            <div className="inputWrap">
              <label className="label">새 비밀번호 확인 <span className="requiredMark">•</span></label>
              <InputBox id="confirmPw" ref={elementsRef[2]}
              type={TEXT_INPUT_TYPE.PASSWORD} 
              placeholder="신규 비밀번호를 한번 더 입력해 주세요." 
              value={state.confirmPw}
              errorText={state.confirmPwError}
              rules={[{
                basis: TEXT_INPUT_RULE.REQUIRED,
                invalidateMessage: '필수 입력 항목입니다.'
              },{
                basis: TEXT_INPUT_RULE.FUNCTION,
                rule: (value:any, rule:IInputBoxRule) => {
                  const targetValue = rule.ref?.current.getValue()
                  if (targetValue && value && targetValue !==value) return true
                },
                ref: elementsRef[1],
                invalidateMessage: '비밀번호가 일치하지 않습니다.'
              }]}
              onChange={(id:any, value:any) => {
                setState({
                  ...state,
                  confirmPw: value
                })
              }} />
            </div>
          </section>
          <section style={{ marginTop: '80px', flexFlow: 'row-reverse', gap: '12px' }}>
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandlerPw}>변경하기</Button>
            {props.pwExpiredUser === true ?
              <Button size={BUTTON_SIZE.LARGE}  color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={pwEditPassHandler}>60일 후 변경</Button> :
              props.passwordInitialized === false ? <Button size={BUTTON_SIZE.LARGE}  color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={cancelHandler}>취소</Button> : false
            }
          </section>
        </Form>
      </div>
    </PasswordEditOpsFragment>
  )
}

const PasswordEditOpsFragment = styled.div`
.formWrap{
  width:644px; min-height:800px; padding:0 334px;
  .titleWrap { align-items:center; gap:10px; margin-bottom:40px; color:#1A1A1A; }
  .titleWrap h1 { font-size:24px; margin-bottom:12px;}
  .titleWrap div { font-size:13px; line-height:20px; color:#878791; font-weight:400; }
  section + section { margin-top:24px; }
  .inputWrap { flex: 1 1 0%; }
  .inputWrap label.label { padding:0 0 8px 0 }
  .subscription { color:#878791; font-size:13px; line-height:20px; }
  .requiredMark { position:relative;top:-4px;color:#F00;font-size:13px;font-weight:700 }
}
`
export default PasswordEdit