import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Utils from '../../../utils'
import ISelectOption from '../../../interfaces/SelectOption'

interface ISelectProps {
  selectRef?:any //React.ForwardedRef<JSX.Element>
  option:ISelectOption[]
  selected?:ISelectOption
  onChange?:Function
  disabled?:boolean
  error?:boolean
  placeholder?:string
  bold?:boolean
}

const Select = (props:ISelectProps) => {
  const [ dropFlag, _setDropFlag ] = useState<boolean>(false)
  const dropFlagRef = React.useRef(dropFlag)
  const setDropFlag = (value:boolean) => {
    dropFlagRef.current = value
    _setDropFlag(value)
  }
  useEffect(() => {
    if (dropFlag === true) { 
      document.addEventListener('mousedown', clickOutsideHandler)
    }
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [dropFlag])

  const [ selected, setSelected ] = useState<ISelectOption>({
    label: props.selected ? props.selected.label : (props.placeholder ? props.placeholder : ''),
    value: props.selected ? props.selected.value : '',
    fakeLabel: props.selected ? props.selected.fakeLabel : ''
  })
  const selectedRef = useRef<ISelectOption>()
  const prevSelected = Utils.usePrevState(selected)
  useEffect(() => {
    if (props.onChange) props.onChange(selected)
  }, [selected])

  useEffect(() => {
    if (prevSelected.label !== props.selected?.label) {
      let selectedOption: ISelectOption | null = null
      for (let eachOption of props.option) {
        if (eachOption.label === props.selected?.label) {
          selectedOption = eachOption
        }
      }
      if (selectedOption !== null) {
        setDropFlag(false)
        setSelected(selectedOption)
      }
    }
    if (props.option.length > 0 && props.placeholder === undefined && selected.label === '') {
      if (props.option[0].label === '') {
        // label이 빈값인 경우.
        setSelected({
          label: 'AN ERROR OCCURED!!',
          value: '------'
        })
      } else {
        setSelected(props.option[0])
      }
    }
    if(props.selected !== undefined && (props.selected?.label !== selected.label && props.selected?.value !== selected.value)) {
      setSelected({
        label: props.selected?.label || '',
        value: props.selected?.value || '',
        fakeLabel: props.selected.fakeLabel || ''
      })
    }

    // 초기화 혹은 이전 선택으로 되돌리고 싶을 때 {label: '', value: ''} 로 주면 이거 실행됨
    if(props.selected?.label === '' && prevSelected.value !== props.selected?.value) {
        setSelected({
          label: (props.placeholder ? props.placeholder : ''),
          value: '',
        })
    }
  }, [prevSelected, props.selected, props.option])

  const clickOutsideHandler = (event:any) => {
    let ref = props.selectRef ? props.selectRef : selectedRef
    if (ref.current?.contains(event.target) === false && dropFlagRef.current === true) {
      setDropFlag(false)
    }
  }
    
  const selectHandler = (option:ISelectOption) => {
    setSelected(option)
    setDropFlag(false)
  }

  return(
    <SelectFragment ref={props.selectRef ? props.selectRef : selectedRef}>
      <p className={ (props.disabled === true ? "blocked" : "") + (props.bold === true ? " bold" : "") } onClick={(): void => { 
          if (props.disabled !== true) setDropFlag(!dropFlag) 
        }} >
        { selected.fakeLabel ? selected.fakeLabel : (selected.label ? selected.label : (props.placeholder ? props.placeholder : '')) }
        { selected.error ? <span className="redPoint">&nbsp;{'⚠︎'}&nbsp;</span> : false} 
        <span className="ico" />
      </p>
      {
        dropFlag ?
          <div className="dropWrap">
            {
              props.option.map((eachProps:ISelectOption) => {
                return <button key={eachProps.value} className={(selected.value === eachProps.value ? 'selected' : '') + (props.bold === true ? " bold" : "")} onClick={():void => selectHandler(eachProps)}>
                  {eachProps.fakeLabel ? eachProps.fakeLabel : eachProps.label} {eachProps.error ? <span className="redPoint">{' ⚠︎'}</span> : false}
                  {eachProps.subLabel ? <div className='subLabel'>{eachProps.subLabel}</div> : false}
                </button>
              })
            }
          </div> : false
      }
    </SelectFragment>
  )
}

const SelectFragment = styled.div`
  position: relative;
  p { display:flex; align-items:center; justify-content:space-between; height:35px;padding:0 15px; border:1px solid #ccc; background:#fff; font-size: 14px; color:#303030; line-height: 35px; cursor:pointer; border-radius:2px; }
  p .ico { width:24px; height:24px; margin-left:5px; background:url(/images/angle-down.png) no-repeat center / 100%; vertical-align:middle; }
  p.blocked { border-color:#ccc; background-color:#f5f5f5; color:#303030; pointer-events:none }
  .dropWrap { overflow:auto; position:absolute; left:0; right:0; max-height:168px; border:1px solid #303030; background:#fff; z-index:101; border-radius:2px; }
  .dropWrap button { display:block;width:100%; padding:13px 16px; font-size:14px; text-align:left; letter-spacing:-0.5px; }
  .dropWrap button + button { border-top:1px solid #f5f5f5; }
  .dropWrap button:hover { background:#fafafa; }
  .dropWrap button.selected { background-color:#e1f5ff; }  
  .redPoint { color:red; }
  .bold { font-weight:bold; }
`

export default Select