import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ILoginResult, LOGIN_RESULT, User } from '../../model/User'
import InputBox, { TEXT_INPUT_TYPE } from '../components/ui/InputBox'
import PopupController from '../../controller/PopupController'
import ModalMessage from '../components/ModalMessage'
import Config from '../../Config'
import Lottie from 'lottie-web'
import ApiController from '../../controller/ApiController'
import ApiEvent from '../../events/ApiEvent'

interface LoginProps { }
interface LoginState {
  id: string,
  pw: string,
  error: boolean | string,
  emptyId: boolean,
  emptyPw: boolean
}

const Login = (props: LoginProps) => {
  const popupController = PopupController.getInstance()
  const apiController = ApiController.getInstance() 
  const navigate = useNavigate()

  const loadingAnimationRef = useRef<any>()
  const [ loadingFlag, setLoadingFlag ] = useState<boolean>(false)


  const [state, setState] = useState<LoginState>({
    id: '',
    pw: '',
    error: false,
    emptyId: false,
    emptyPw: false
  })
  const user = User()

  useEffect(() => {
    apiController.addEventListener(ApiEvent.LOAD_START, pageLoadStartHandler)
    apiController.addEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)

    if (loadingAnimationRef.current !== null) {
      Lottie.loadAnimation({
        container: loadingAnimationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData:require('../../assets/loader.json')
      })
    }

    return () => {
      apiController.removeEventListener(ApiEvent.LOAD_START, pageLoadStartHandler)
      apiController.removeEventListener(ApiEvent.LOAD_END, pageLoadEndHandler)
    }
  }, [])

  const pageLoadStartHandler = ():void => {
    setLoadingFlag(true)
  }

  const pageLoadEndHandler = ():void => {
    setLoadingFlag(false)
  }

  const submitHandler = async (e: any) => {
    e.preventDefault()
    setState({
      ...state,
      error: false
    })

    if (!state.id || !state.pw) {
      setState({
        ...state,
        emptyId: (!state.id),
        emptyPw: (!state.pw),
      })
      return false
    }
    const response:ILoginResult = await user.login(state.id, state.pw)
    let errorMessage:string = ''
    switch (response.status) {
      case LOGIN_RESULT.OKAY:
        // OK - DOES NOTHING
        // window.dispatchEvent(new PageEvent(PageEvent.GET_MY_INFO))
        break
      case LOGIN_RESULT.NOT_AUTHORIZED:
        navigate('/waitingforjoin', {state: {data: response.data}})
        // errorMessage = '승인되지 않은 사용자입니다. 관리자의 승인 완료 후 로그인 가능합니다.'
        break
      // case LOGIN_RESULT.ACCOUNT_LOCKOUT:
      // case LOGIN_RESULT.ACCOUNT_DORMANT:
      // case LOGIN_RESULT.INVALID_ID:
      // case LOGIN_RESULT.INVALID_PW:
      // case LOGIN_RESULT.INVAILD_INFO:
      default:
        errorMessage = '로그인에 실패하였습니다. 입력하신 정보를 확인해 주세요. 문제가 지속되면 관리자에게 문의해 주세요.'
        break
    }
    setState({
      ...state,
      error: errorMessage
    })
  }

  const onKeyPressHandler = (e: any) => {
    if (e.key === 'Enter') {
      setState({
        ...state,
        pw: e.target.value
      })

      submitHandler(e)
    }
  }

  const onChange = (id:string, value:string) => {
    switch (id) {
      case 'id':
        if (value !== '') {
          state.emptyId = false
        }
        break
      case 'pw':
        if (value !== '') {
          state.emptyPw = false
        }
        break
    }

    setState({
      ...state,
      [id]: value,
      error: ''
    })
  }

  return (
    <LoginFragment>
      <ModalMessage />
      <section className={'loadingWrap ' + (loadingFlag === true ? '' : 'off')}>
        <div className='animation' ref={loadingAnimationRef}></div>
      </section>
      <div className="authWrap">
        <header>
          <h1><img src="/images/logo-login.png" alt="AIPub" /></h1>
          <p>ⒸAIPub, TEN Inc</p>
          <p className="version">BUILD <span>{Config.env.build}</span></p>
        </header>
        <section className="formWrap">
          <h2>로그인</h2>
          <form>
            <div className="formGroup">
              <label htmlFor="name">아이디</label>
              {/* <input className={state.emptyId ? 'error' : ''} type="text" id="id" placeholder="아이디를 입력해 주세요." onChange={(e) => setState({ ...state, id: e.target.value })} />
              {state.emptyId ? <p className="message">아이디를 입력해 주세요.</p> : false} */}
              <InputBox id="id" type={TEXT_INPUT_TYPE.TEXT} placeholder="아이디를 입력해 주세요."
               warning={state.emptyId ? '아이디를 입력해 주세요.' : ''} onChange={onChange} />
            </div>
            <div className="formGroup">
              <label htmlFor="name">패스워드</label>
              {/* <input className={state.emptyPw ? 'error' : ''} type="password" id="pw" placeholder="비밀번호를 입력해 주세요." onChange={(e) => setState({ ...state, pw: e.target.value })} onKeyPress={onKeyPressHandler} />
              {state.emptyPw ? <p className="message">비밀번호를 입력해 주세요.</p> : false} */}
              <InputBox id="pw" type={TEXT_INPUT_TYPE.PASSWORD} placeholder="비밀번호를 입력해 주세요." 
               warning={state.emptyPw ? '비밀번호를 입력해 주세요.' : ''} onChange={onChange} />
            </div>
            <button className="btn blue full" onClick={submitHandler}>로그인</button>
            {state.error === false || state.error === '' ? false : <p className="message">{state.error}</p>}
          </form>
        </section>
      </div>
    </LoginFragment>
  )
}

const LoginFragment = styled.div`
  overflow:auto;height:100vh;display:flex;align-items:center;justify-content:center;
  .authWrap{display:flex;justify-content:space-between;align-items:center;max-width:1200px;min-height:800px;width:100%;padding:0 50px;box-sizing:border-box;}
  .authWrap > *{flex:1;max-width:480px;}
  header{text-align:center;}
  header h1{display:inline-block}
  header h1 img{display:block;width:209px;}
  header p{margin-top:30px;font-size:14px;font-weight:bold;}
  .formWrap h2{margin-bottom:50px;font-size:28px;font-weight:bold;}
  .formGroup{flex-direction:column;}
  .formGroup + .formGroup{margin-top:30px}
  .formWrap label{display:block;font-weight:bold;}
  .formWrap input{display:block;margin-top:20px;width:100%;height:50px;padding: 0 15px;border:1px solid #ccc;box-sizing:border-box;border-radius: 2px;font-size:14px; color:#303030;}
  .formWrap input.error{border-color: #ef4f30;}
  .formWrap input.disable{background: #f5f5f5;}
  .formWrap input::placeholder{color:#a7a7a7;}
  .formWrap input:hover{border-color: #616161;}
  .formWrap input:active{border-color:#2c78ff;}
  .formWrap .message{margin:10px 0 0 15px;}
  .formWrap .btn.full{height:50px;font-size:16px;line-height:50px;}
  .formWrap .btn.blue{margin-top:52px;}
  .formWrap .btn.outline{margin-top:10px;}
  .note{color:#303030;font-size:13px;}
  .note:before{content:'';display:inline-block;width:12px;height:11px;margin-right:3px;background:url('/images/star.png') no-repeat center / 100%;}
  .version{color:#FAFAFB;font-size:9px;font-weight:500;line-height:10.74px;height:11px;margin-top:8px;}

  // 로딩 마스크
 .loadingWrap { position:fixed;width:100%;height:100%;background-color:rgba(0,0,0,0.3);display:flex;justify-content:space-between;align-items:center;z-index:9999 }
 .loadingWrap.off { display:none }
 .loadingWrap .animation { position:relative;left:50%;display:inline-block;width:50px;height:50px;display:flex;justify-content:space-between;align-items:center; }
`

export default Login