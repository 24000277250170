import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { IUserInfo, userInfoState } from '../../states/userInfoState'
import MonitoringAPI, { IGpu } from '../../model/Monitoring'
import Select from '../components/uiv2/Select'
import ISelectOption from '../../interfaces/SelectOption'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../components/uiv2/Button'
import DateRangePicker from '../components/uiv2/DateRangePicker'
import { IChartSeries } from '../../interfaces/Chart'
import PopupController from '../../controller/PopupController'
import { ResourceGroup } from '../../model/ResourceGroup'
import { SORT_ORDER } from '../../model/BaseDataType'
import BarChart, { YAXIS_TYPE } from '../components/chart/BarChart'
import NoData from '../components/NoData'
import Node, { INode, IStorage, SERVER_STATUS } from '../components/uiv2/Node'

export enum CLASSTYPE {
  CPU = 0,
  STORAGE = 1,
  MEMORY = 2,
  STORAGE_USAGE = 3,
}
export enum MONITORING_TYPE {
  MASTER = 0,
  STORAGE = 1,
  WORKER = 2
}

interface IMonitoringServerData {
  masterList: INode[]
  workerList: INode[]
  storageList: IStorage[]
  categories: string[]
}

interface IMonitoringProps {
}

interface IMonitoringState {
  range: {
    from:string
    to:string
  }
  limit: {
    from:string
    to:string
  }
  serverTime:string
  nodeList:INode[]
  refreshFlag:boolean
  settingModeFlag:boolean
  nodeNameFilter:string
}

const Monitoring = (props:IMonitoringProps) => {
  const popupController = PopupController.getInstance()
  const [ userInfo ] = useRecoilState<IUserInfo|null>(userInfoState)

  let startTimestamp:number = userInfo?.startTimestamp || 0
  let startFrom:string = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
  let defaultFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom :dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  const initialFrom:string = dayjs().subtract(24, 'hour').unix() < startTimestamp ? startFrom :dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm')
  const initialTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  const initialServerTime:string = dayjs().format('YYYY-MM-DD HH:mm')
  let defaultTo:string = dayjs().format('YYYY-MM-DD HH:mm')
  let defaultServerTime:string = dayjs().format('YYYY-MM-DD HH:mm')

  const pickerRef1 = useRef<any>()
  const pickerRef2 = useRef<any>()

  const [ rgroupList, setRgroupList ] = useState<any[]>([])

  const [ filterListChart, setFilterListChart ] = useState<ISelectOption[]>([])
  const [ filterChart, _setFilterChart ] = useState<ISelectOption>()
  const filterChartRef = useRef(filterChart)
  const setFilterChart = (data:any) => {
    filterChartRef.current = data
    _setFilterChart(data)
  }

  const [ chartTitle, setChartTitle ] = useState<string>('GPU 가동률')
  const [ filterListNode, setFilterListNode ] = useState<ISelectOption[]>([])
  const [ filterNode, _setFilterNode ] = useState<ISelectOption>()
  const filterNodeRef = useRef(filterNode)
  const setFilterNode = (data:any) => {
    filterNodeRef.current = data
    _setFilterNode(data)
  }

  const [ gpuData, setGpuData ] = useState<IChartSeries[]>([])
  const [ serverData, setServerData ] = useState<IMonitoringServerData>({
    categories: [],
    masterList: [],
    storageList: [],
    workerList: []
  })

  const [state, setState] = useState<IMonitoringState>({
    range: {
      from: defaultFrom,
      to: defaultTo
    },
    limit: {
      from: startFrom,
      to: defaultTo
    },
    serverTime: defaultServerTime,
    nodeList: [],
    refreshFlag: false,
    settingModeFlag: false,//(props.mode === 'setting'),
    nodeNameFilter:''
  })

  useEffect(() => {
    getRGroupList()
  }, [])

  useEffect(() => { // setting FilterList
    if (rgroupList.length === 0) return

    // 차트 필터 리스트
    let filterListChart:ISelectOption[] = [{ label: '클러스터 전체 GPU 가동률', value: '0' }]
    for (let idx in rgroupList) {
      if(rgroupList[idx].isCommon) {
        filterListChart.push({ fakeLabel: '기본 리소스 그룹', label: rgroupList[idx].name, value: String(Number(idx)+1) })
      } else {
        filterListChart.push({ label: rgroupList[idx].name, value: String(Number(idx)+1) })
      }
    }
    setFilterListChart(filterListChart)

    // 노드 필터 리스트
    let filterListNode:ISelectOption[] = [{ label: '전체 서버 모니터링', value: '0' }]
    for (let idx in rgroupList) {
      if(rgroupList[idx].isCommon) {
        filterListNode.push({ fakeLabel: '기본 리소스 그룹', label: rgroupList[idx].name, value: String(Number(idx)+1) })
      } else {
        filterListNode.push({ label: rgroupList[idx].name, value: String(Number(idx)+1) })
      }
    }
    setFilterListNode(filterListNode)
  }, [rgroupList])

  useEffect(() => {
    if (filterChart !== undefined && filterChart.value !== '') {
      if(filterChart.value === '0') {
        setChartTitle('클러스터 전체 GPU 가동률')
      } else {
        setChartTitle((filterChart.fakeLabel ? filterChart.fakeLabel : filterChart.label) + ' GPU 가동률')
      }
      getGPUData(state.range)
    }
  }, [filterChart])

  useEffect(() => {
    if (filterNode !== undefined && filterNode.value !== '') {
      getServerData(state.serverTime)
    }
  }, [filterNode])

  useEffect(() => {
    document.addEventListener('mousedown', closeNodePopupHandler) //mousedown 대신 타입스크립트에서 제공하는 기본 마우스 이벤트 사용 - MouseEvent.이벤트명
    // window.addEventListener(PageEvent.REFRESH, autoRefreshHandler)
    return() => {
      document.removeEventListener('mousedown', closeNodePopupHandler)
      // window.removeEventListener(PageEvent.REFRESH, autoRefreshHandler)
    }
  }, [serverData.workerList])

  useEffect(() => {
    if(state.settingModeFlag === false) {
      defaultFrom = initialFrom
      defaultTo = initialTo
      let range = {
        from: defaultFrom,
        to: defaultTo
      }
      setState({
        ...state,
        range: range
      })
    }
  }, [state.settingModeFlag])

  useEffect(() => {
    startTimestamp = userInfo?.startTimestamp || 0
    startFrom = dayjs(startTimestamp * 1000).format('YYYY-MM-DD HH:mm')
    setState({
      ...state,
      limit: {
        from: startFrom,
        to: defaultTo
      },
    })
  }, [userInfo?.startTimestamp])

  const onChangeDatePicker = (range:any) => {
    setGpuData([])
    setState({
      ...state,
      range: {
        from: range.from,
        to: range.to
      }
    })
    getGPUData(range)
  }

  const getRGroupList = async () => {
    try {
      let result = await ResourceGroup.getGroupList(SORT_ORDER.ASC)
      if(result) {
        setRgroupList(result.rgroup)
      }
    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - c62f98')
    }
  }
  const getGPUData = async (newRange:any): Promise<void> => {
    try {
      const FROM = dayjs(newRange.from).unix()
      const TO = dayjs(newRange.to).unix()
      let data = await MonitoringAPI.getGPUData(FROM, TO, filterChartRef.current)
      if (data) {
        setGpuData(parseChartData(data.gpuUsageList))
      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 6ad9f0')
    }
  }
  const parseChartData = (data:any) => {
    let chartData:any = []

    chartData = [
      { name: '가동률', data: [], color: '#BECC23' }
    ]

    for (let i:number = 0; i < data.length; i++) {
      let item:any = data[i]
      let assigned:number = item.allocationGpu / item.totalGpu
      // let used:number = item.useGpu / item.totalGpu
      chartData[0].data.push([item.timestamp * 1000, Math.round(assigned * 10000) / 100])
    }
    return chartData
  }

  const getServerData = async (newServerTime:any): Promise<void> => {
    try {
      const TIME = dayjs(newServerTime).unix()
      let data = await MonitoringAPI.getServerData(TIME, filterNodeRef.current),
        storage:any = []
  
      // set chart categories
      let categories:string[] = [],
          date:string = ''
      const today = dayjs().format('YYYY-MM-DD')
       for (let i = 0; i < 7; i++) {
        date = dayjs.unix(TIME).subtract(i, 'day').format('YYYY-MM-DD')
        // categories.push(today === date ? '오늘' : dayjs(date).format('DD')+'일')
        categories.push(dayjs(date).format('DD'))
      }
 
      // set data
      if (data) {
        // Server Status 검사
        for(let idx in data){
          for(let i=0; i<data[idx].length; ++i){
            let node = data[idx][i]
            if(node.nodeResource){ // 마스터노드, 워커 노드
              node.statusCpu = getStatus(node.nodeResource.cpu, CLASSTYPE.CPU)
              node.statusStorage = getStatus(node.nodeResource.storage, CLASSTYPE.STORAGE) 
              node.statusMemory = getStatus(node.nodeResource.memory, CLASSTYPE.MEMORY)
  
              if (!node.nodeResource.isRunning) {
                node.statusTotal = SERVER_STATUS.CRITICAL
              } else {
                node.statusTotal = node.statusCpu
                if(node.statusTotal <= node.statusStorage) {node.statusTotal = node.statusStorage}
                if(node.statusTotal <= node.statusMemory) {node.statusTotal = node.statusMemory}
              }
            } else { // 스토리지
              node.status = getStatus(node.storageRate, CLASSTYPE.STORAGE_USAGE)
            }
          }
        }

        setServerData({
          ...serverData,
          masterList: data.masterList,
          storageList: data.storageList,
          workerList: data.workerList,
          categories: categories.reverse() //앞에 있던 부분 여기로 옮김
        })

      }
    } catch(error:any) {
      console.log(error)
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 9cedc0')
    }
  }

  const onChangeServerDatePicker = (range:any) => {
    setState({
      ...state,
      serverTime: range.to,
      nodeList: []
    })
    setServerData({
      ...serverData,
      storageList: [],
    })
    getServerData(range.to)
  }

  const getStatus = (value:number, type:CLASSTYPE): SERVER_STATUS => {
    let result:SERVER_STATUS = SERVER_STATUS.NORMAL
    let warn:number = 90
    let noti:number = 80

    switch(type){
      case CLASSTYPE.CPU:
        warn = 90
        noti = 80
        break
      case CLASSTYPE.STORAGE:
        warn = 70
        noti = 60
        break
      case CLASSTYPE.MEMORY:
        warn = 90
        noti = 80
        break
      case CLASSTYPE.STORAGE_USAGE:
        warn = 70
        noti = 60
        break
      default:
        warn = 70
        noti = 60
        break
    }

    if (value >= warn) {
      result = SERVER_STATUS.CRITICAL
    } else if (value >= noti) {
      result = SERVER_STATUS.CONCERN
    } else {
      result = SERVER_STATUS.NORMAL
    }
    return result
  }

  const closeNodePopupHandler = () => {
    let workerList = serverData.workerList
    for (let idx in workerList) {
      let node:INode = workerList[idx]
      let nodeData:INode = {
        ...node,
        popupOpenFlag: false
      }
      workerList[idx] = nodeData
    }

    setServerData({
      ...serverData,
      workerList: workerList
    })
  }

  const renderTooltipImg = (status:SERVER_STATUS) => {
    let img:string = 'status.png'
    switch(status){
      case SERVER_STATUS.CRITICAL:
        img = 'alert-error.png'
        break;
      case SERVER_STATUS.CONCERN:
        img = 'alert-warn.png'
        break;
      case SERVER_STATUS.NORMAL:
      default:
        img = 'status.png'
    }
    return img
  }
  const renderTooltipDes = (data:any, type:MONITORING_TYPE) => {
    let des:string = ''
    let node:INode, storage:IStorage
    //console.log(data)
    switch(type){
      case MONITORING_TYPE.MASTER:
        node = data
        // 경고 critical (빨강)
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고인 애들 표기
          if(node.nodeResource.isRunning === false){
            des += '시스템 장애가 발생했습니다.\n'
          }
          else {
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des += `[경고] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n` }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des += `[경고] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 신속하게 확보해 주세요.\n` }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des += `[경고] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n` }
            
            //주의인 애들 표기
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des += `[주의] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. CPU 리소스를 확보해 주세요.\n` }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des += `[주의] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 AI Pub 사용이 불가할 수 있습니다. 스토리지 가용량을 신속하게 확보해 주세요.\n` }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des += `[주의] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 AI Pub 사용이 불가할 수 있습니다. 메모리 리소스를 확보해 주세요.\n` }
          }
        }
        break;
      case MONITORING_TYPE.WORKER:
        node = data
        // 경고 critical (빨강)
        if(node.statusTotal !== SERVER_STATUS.NORMAL){
          //경고인 애들 표기
          if(node.nodeResource.isRunning === false){
            des += '시스템 장애가 발생했습니다.\n'
          }
          else {
            if(node.statusCpu === SERVER_STATUS.CRITICAL){ des += `[경고] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusStorage === SERVER_STATUS.CRITICAL){ des += `[경고] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusMemory === SERVER_STATUS.CRITICAL){ des += `[경고] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            
            //주의인 애들 표기
            if(node.statusCpu === SERVER_STATUS.CONCERN){ des += `[주의] CPU 사용률이 ${node.nodeResource.cpu}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusStorage === SERVER_STATUS.CONCERN){ des += `[주의] 스토리지 사용률이 ${node.nodeResource.storage}%입니다.\n 사용량 80% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }
            if(node.statusMemory === SERVER_STATUS.CONCERN){ des += `[주의] 메모리 사용률이 ${node.nodeResource.memory}%입니다.\n 사용률이 95% 초과 시 생성된 워크스페이스의 정상 사용이 불가할 수 있습니다.\n` }  
          }
        }
        break;
      case MONITORING_TYPE.STORAGE:
        storage = data
        if(storage.status === SERVER_STATUS.CRITICAL){
          des = `[경고] Storage 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 신속하게 확보해 주세요.`
        }
        else if(storage.status === SERVER_STATUS.CONCERN){
          des = `[주의] Storage 사용량이 ${storage.storageRate}%입니다.\n 스토리지 가용량을 확보해 주세요`
        }
        break;
      default:
        des = 'alert.png'
    }
    return des
  }
  const getColorClass = (status:SERVER_STATUS):string => {
    let result:string = ''

    if (status === SERVER_STATUS.CRITICAL) {
      result = 'warn'
    } else if (status === SERVER_STATUS.CONCERN) {
      result = 'noti'
    } else {
      result = 'okay'
    }
    return result
  }

  return (
    <MonitoringFragment>
      {state.settingModeFlag === false ?
        <>
          <div>
            <div className='pageTitle'>
              <div>전체 GPU 활용 추이</div>
            </div>
            <div className='pickerWrap'>
              <div className='selectWrap'>
                <Select option={filterListChart} selected={filterChart} 
                onChange={setFilterChart} />
              </div>
              <div className="dateRangePicker">
                <DateRangePicker ref={pickerRef1} initial={{ from: initialFrom, to: initialTo }} default={{ from: state.range.from, to: state.range.to }} limit={state.limit} data={state.range} onChange={onChangeDatePicker} />
              </div>
            </div>
            <div className="chartWrap">
              {
                gpuData.length ?
                  <BarChart height={320} max={100} min={0} unit="%" datetime={true} hideTotalFlag={true} totalLabel="할당된 GPU " data={gpuData} legend={false} minX={dayjs(state.range.from).unix() * 1000} maxX={dayjs(state.range.to).unix() * 1000}
                    toolTipFormat={function (this: Highcharts.TooltipFormatterContextObject) {
                      const date = dayjs(this.x).format('MM/DD HH:mm') //'YYYY/MM/DD HH:mm'
                      return date + `<br><span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.y}%</b><br/>`
                    }} /> :
                  <NoData height={400} />
              }
            </div>
          </div>
        </> : false}
      <div className="divider"></div>


      <div>
        <div className='pageTitle'>
          <div>서버 모니터링</div>
          {/* <Button size={BUTTON_SIZE.MEDIUM} color={BUTTON_COLOR.OUTLINE_SECONDARY} disabled={true}
          onClickButton={() => {
            setState({
              ...state,
              settingModeFlag: !state.settingModeFlag
            })
          }}>
            {state.settingModeFlag ? '서버 모니터링 모드' : '서버 설정 모드'}
          </Button> */}
        </div>
        <div className='pickerWrap'>
          <div className='selectWrap'>
            <Select option={filterListChart} selected={filterNode}
            onChange={setFilterNode} />
          </div>
          <div className='dateRangePicker'>
            <DateRangePicker ref={pickerRef2} initial={{ to: initialServerTime, from: '' }} default={{ to: state.serverTime, from: '' }} limit={state.limit} data={{ to: state.serverTime, from: '' }} single={true} onChange={onChangeServerDatePicker} />
          </div>
        </div>
        <div className='serverWrap'>
          {
            serverData.masterList.map((node, idx) => {
              return (<div key={idx}>
                <Node idx={idx} node={node} settingModeFlag={state.settingModeFlag} title={'Master'} type={MONITORING_TYPE.MASTER} categories={serverData.categories} />
              </div>)
            })
          }
          {
            serverData.storageList.map((storage, idx) => {
              return (<div key={idx}>
                <Node idx={idx} node={storage} settingModeFlag={state.settingModeFlag} title={'Storage'} type={MONITORING_TYPE.STORAGE} />
              </div>)
            })
          }
        </div>
        <div className="serverWrap" style={{marginTop:'48px'}}>
          {
            serverData.workerList.map((node, idx) => {
              return (<div key={idx}>
                <Node idx={idx} node={node} settingModeFlag={state.settingModeFlag} title={'Worker'} type={MONITORING_TYPE.WORKER} categories={serverData.categories} />
              </div>)
            })
          }
        </div>
      </div>
    </MonitoringFragment>
  )
}

const MonitoringFragment = styled.div`
  .pageTitle{font-size:24px; line-height:normal; font-weight:700; color:#1A1A1A; display:flex; justify-content:space-between; gap:12px; margin-bottom:48px;}
  .pageTitle>div {padding:1.5px 0;}
  .pickerWrap {display:flex; justify-content:space-between;}
  .selectWrap{width:310px;}
  .chartWrap{margin-top:24px;}
  .divider{margin:56px 0;}

  .serverStyle {font-weight:600;font-size:14px;line-height:normal;height:16px;margin-bottom:16px;}
  .serverWrap{margin:24px 0;font-size:0; display:flex; flex-wrap: wrap; gap:24px;}
  .serverWrap > div {display:flex;flex-direction:column;justify-content:end;box-sizing:border-box;vertical-align:top;}
`

export default Monitoring