import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { IBaseDataType, SORT_ORDER } from './BaseDataType'
import ApiController from '../controller/ApiController'
import { ERROR_TYPE } from '../interfaces/Error'
import Config from '../Config'

export enum ALERT_STATUS {
  WARN = 2,
  CONCERN = 1,
  NOTI = 0
}

export enum ALERT_TYPE {
  ALL = 0,
  INFRA = 1,
  USER = 2,
  WORKSPACE = 3
}

export enum ALERT_TYPE_OPS {
  INFRA = 1,
  USER = 2,
  SERVICE = 3,
  ALL = 4
}

export enum ALERT_ORDER_BY {
  STATUS = 0,
  DATETIME = 1
}

export enum ALERT_CATEGORY {
  DEV = 0,
  OPS = 1,
  ADMIN = 2
}

export interface IAlertCount extends IBaseDataType {
  isNew:boolean
  totalAlert:number
}

export interface IAlertListItem extends IBaseDataType {
  alertId:number
  isRead:boolean
  msg:string
  status:ALERT_STATUS
  timestamp:Date
  type:ALERT_TYPE
}

export interface IAlertListItemOps extends IBaseDataType {
  alertId:number
  isRead?:boolean
  msg?:string
  level?:ALERT_STATUS
  timestamp?:Date
  type:ALERT_TYPE_OPS
}

export interface IAlertDeleteList {
  alertId: string[],
  alertType: ALERT_TYPE_OPS
}

export class Alert {
  // Dev
  public static async getList(userNo:number, from:number, to:number, page:number, size:number=50, orderBy:ALERT_ORDER_BY, sortOrder:SORT_ORDER, levelArray:boolean[]=[true, true, true], authInfo?:any, alertTypeFilter?:number) {
    try {
      const apiController = ApiController.getInstance()
      const authInfo_ = authInfo
      
      let level:string[] = []
      for (let i = 0; i < 3; i++) {
        if (levelArray[i] === true) level.push(String(i))
      }

      const response = await apiController.get(Config.env.REACT_APP_MODE === 'OPS' ? (authInfo_ && authInfo_.isAdmin ? '/alerts/ops-admin' : '/alerts/ops') : '/alerts/user', {
        user_no: userNo,
        page: page,
        size: size,
        start_timestamp: from,
        end_timestamp: to,
        sort_by: sortOrder,
        order_by: orderBy,
        alert_type_filter: alertTypeFilter ? alertTypeFilter : 0,
        level: level.join(',')
      })
      
      switch(response.status){
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Alert.getList error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }
  
  public static async getListAdmin(orderBy:ALERT_ORDER_BY, sortOrder:SORT_ORDER, alertType:ALERT_TYPE, page:number, size:number=50) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get('/alerts/admin', {
        order_by: orderBy,
        sort_by: sortOrder,
        alert_type_filter: alertType,
        page: page,
        size: size,
      })

      switch(response.status){
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Alert.getList error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async deleteList(list:string[]) {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.del('/alerts', {
        id_list: list
      })
      switch(response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Alert.deleteList error>', response)
          break
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async getAlertCount() {
    try {
      const apiController = ApiController.getInstance()

      const response = await apiController.get(Config.env.REACT_APP_MODE === 'OPS' ? '/alerts/ops-new-count' : '/alerts/new-count', {})
      switch (response.status) {
        case 200:
          return camelcaseKeys(response.data, {deep: true})
        default:
          console.log('Alert.getAlertCount error>', response)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }


  // // Ops
  // public static async getListOps(page:number=1, size:number=50, orderBy:ALERT_ORDER_BY, sortOrder:SORT_ORDER) {
  //   const apiController:OpsApiController = new OpsApiController()
  //   let newState:IUserLoginInfo = await promiseGetRecoil(loginState)
  //   const response = await apiController.getAlertList(page, size, orderBy, sortOrder, newState.userNo)
  //   /* console.log('response', response) */
  //   switch(response.status) {
  //     case 200:
  //       return {
  //         count: {
  //           total: response.data.total_count
  //         },
  //         list: camelcaseKeys(response.data.alert_list)
  //       }
  //     default:
  //       console.log('Alert.getList error>', response)
  //       return {
  //         count: {},
  //         list: {},
  //         error: response
  //       }
  //       break
  //   }
  // }
  
  // public static async deleteListOps(list:any) {
  //   const apiController:OpsApiController = new OpsApiController()
  //   const response = await apiController.deleteAlertList(snakecaseKeys(list,{deep:true}))
  //   switch(response.status) {
  //     case 200:
  //       return response.data
  //     default:
  //       console.log('Alert.deleteList error>', response)
  //       return {
  //         error: response
  //       }
  //       break
  //   }
  // }

  // public static async getAlertCountOps() {
  //   const apiController:OpsApiController = new OpsApiController()

  //   const response = await apiController.getAlertCount()
  //   switch(response.status) {
  //     case 200:
  //       return {
  //         newFlag: response.data.is_new === true,
  //         count: response.data.total_alert
  //       }
  //     default:
  //       console.log('Alert.getAlertCount error>', response)
  //       return {
  //         newFlag: false,
  //         count: {},
  //         error: response
  //       }
  //       break
  //   }
  // }

  // public static async getListOpsAdmin(page:number=1, size:number=50, orderBy:ALERT_ORDER_BY, sortOrder:SORT_ORDER, type:ALERT_TYPE_OPS) {
  //   const apiController:OpsApiController = new OpsApiController()

  //   const response = await apiController.getAlertListAdmin(page, size, orderBy, sortOrder, type)
  //   switch(response.status) {
  //     case 200:
  //       return {
  //         count: {
  //           infra: camelcaseKeys(response.data.infra_count),
  //           user: camelcaseKeys(response.data.user_count),
  //           ops: camelcaseKeys(response.data.ops_count)
  //         },
  //         list: camelcaseKeys(response.data.alert_list)
  //       }
  //     default:
  //       console.log('Alert.getList error>', response)
  //       return {
  //         count: {
  //           infra: {isNew:false, totalAlert:0},
  //           user: {isNew:false, totalAlert:0},
  //           ops: {isNew:false, totalAlert:0}
  //         },
  //         list: {},
  //         error: response
  //       }
  //       break
  //   }
  // }
}