import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import SubPopup, {SUBPOPUP_TYPE} from "../modal/sub/SubPopup"
/* import ModalEvent, { MODAL_TYPE } from '../../events/ModalEvent' */
import PopupController from "../../../controller/PopupController"

interface IGroupResourceProps {
  data:any
}

interface IGroupResourceState {
  groupNodeModal:boolean
  groupVolumeModal:boolean
  clientHeight:number
}

const GroupResource = (props:IGroupResourceProps) => {
  const [ state, setState ] = useState({
    groupNodeModal: false,
    groupVolumeModal: false,
    clientHeight: 0
  })

  const resNodeRef = useRef<any>()
  const resVolRef = useRef<any>()
  const data = props.data
  const popupController = PopupController.getInstance()
  //console.log('resVolRef.current.clientHeight' ,resVolRef.current?.clientHeight)

  useEffect(() =>{
    setState({
      ...state,
      clientHeight : resVolRef.current?.clientHeight
    })
  }, [resVolRef.current]) //동작이없긴한데 ref를한번 갱신해주어서 데이터가 들어간상태로 나옴

  return (
    <GroupResourceFragment>
        {
          data.nodeList.length > 0 || data.volumeList.length > 0 || data.reg ?
          <div className='left-align' style={{width:'503px'}}>
            <div className='resourceBoxTitle'>
              <h3>노드</h3>
              <div style={{display:'flex'}}>
                <div className='resourceBoxWrap'>
                  <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}} ref={resNodeRef}>
                    {data.nodeList.length > 0 ? 
                    <>
                      {data.nodeList.map((eachNode:any, idx:number) => {
                        return (<div key={idx} className='resourceBox'>{eachNode.ip+' '} / {' '+eachNode.name}</div>)
                      })}
                    </> 
                    : <>{'-'}</>}
                  </div>
                </div>
                {Number(resNodeRef.current?.clientHeight) > 30 ?
                  <div className="resourceBoxSub"> <SubPopup titles={["노드 IP"]} datas={data.nodeList} type={SUBPOPUP_TYPE.NODE} onLeft={true}/></div> : false}
              </div>
            </div>
            <div className='resourceBoxTitle'>
              <h3>볼륨</h3>
              <div style={{display:'flex'}}>
                <div className='resourceBoxWrap'>
                  <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}} ref={resVolRef}>
                    {data.volumeList.length > 0 ?
                    <>
                      {data.volumeList.map((eachVolume: any, idx: number) => {
                          return (<div key={idx} className='resourceBox'>{eachVolume.name}</div>)
                      })}
                    </>
                    : <>{'-'}</>}
                  </div>
                </div>
                { Number(resVolRef.current?.clientHeight) > 30 ?
                  <div className="resourceBoxSub"> <SubPopup titles={["전체 볼륨"]} datas={data.volumeList} type={SUBPOPUP_TYPE.VOLUME} onLeft={true}/></div>  : false}
              </div>
            </div>
            <div className='resourceBoxTitle'>
              <h3>이미지 레지스트리</h3>
              <div className='resourceBoxWrap'>
                {data.reg? 
                  <div className='resourceBox'>{data.reg}</div>
                :<>{'-'}</>}
              </div>
            </div>
          </div>
          : <div style={{ textAlignLast: 'center' }}><button className="btn outline btnSm" onClick={(e) => {
            e.preventDefault()
            popupController.groupNode(()=>{}, data.name)
          }}>리소스 설정</button></div>
        }
      </GroupResourceFragment>
  )
}

const GroupResourceFragment = styled.div`
  .resourceBoxTitle h3 { margin-bottom:10px; }
  .resourceBoxWrap { margin-bottom:10px; max-width:500px; height:22px; overflow:hidden; display:flex; align-items:start; }
  .resourceBoxWrap .resourceBox { padding:1px 10px; background-color: #F5F5F5; box-sizing:border-box; border: 1px solid #DFDFDF; border-radius:2px; }
  .resourceBoxSub table tr td { background-color:#ffffff;}

`
export default GroupResource