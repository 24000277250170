import ModalEvent, { MODAL_BUTTON_TYPE, MODAL_TYPE } from "../events/ModalEvent"
import { IPort } from "../model/Ports"
import { IEmail, IServiceItem } from "../model/Service"
import { IVolume } from "../model/Workspace"
import { IPortMap } from "../model/Workspace"

export default class PopupController extends EventTarget {
  private static instance:PopupController

  public static getInstance() {
    if (!PopupController.instance) {
      PopupController.instance = new PopupController()
    }
    return PopupController.instance
  }

  private constructor() {
    super()
  }

  public confirm = (message:string, title?:string) => {
    this.messagePopup(message, [], title)
  }

  public confirmCustom = (message:string, action:string, key:any, submitLabel:string='확인') => {
    this.messagePopup(message, [
      { type: MODAL_BUTTON_TYPE.SUBMIT, text: submitLabel, action: action, key: key }
    ])
  }

  public dialouge = (message:string, action:string, key:any, submitLabel:string='확인', cancelLabel:string='취소') => {
    this.messagePopup(message, [
      { type: MODAL_BUTTON_TYPE.CANCEL, text: cancelLabel, action: 'cancel' },
      { type: MODAL_BUTTON_TYPE.SUBMIT, text: submitLabel, action: action, key: key}
    ])
  }

  public messagePopup = (message:string, buttons?:any[], title?:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MESSAGE)
    evt.payload = {
      message: message,
      button: buttons,
      title: title
    }
    this.dispatchEvent(evt)
  }

  public image = (onSelected:Function, jupyterFlag:boolean, rgroup:string, reg:string, image:string, isCommon:boolean) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.IMAGE
    evt.payload = { 
      onSelected: onSelected,
      jupyterFlag: jupyterFlag,
      rgroup: rgroup,
      preSelectedReg: reg,
      preSelectedImage: image,
      isCommon: isCommon
    }
    this.dispatchEvent(evt)
  }

  public volume = (onSelected:Function, idx:number, rgroup:string, preSelectedVolume:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.VOLUME
    evt.payload = { 
      onSelected: onSelected,
      idx: idx,
      rgroup: rgroup,
      preSelectedVolume: preSelectedVolume
    }
    this.dispatchEvent(evt)
  }

  public mig = (onSelected:Function, nodeName:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.MIG_SETTING
    evt.payload = { 
      onSelected: onSelected,
      nodeName: nodeName
    }
    this.dispatchEvent(evt)
  }

  public resourceLimit = (resourceLimit:any, userInfo:any) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.RESOURCE_LIMIT
    evt.payload = {
      resourceLimit: resourceLimit,
      userInfo: userInfo
    }
    this.dispatchEvent(evt)
  }

  public groupName = () => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.GROUP_NAME
    evt.payload = {
      onSuccess: () => {
        // console.log('SUCCESS!!',evt.payload)
       }
    }
    this.dispatchEvent(evt)
  }

  public groupUser = (onSelected:Function, groupName:any, preSelectedUser?:any[]) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.GROUP_USER
    evt.payload = {
      onSelected: onSelected,
      groupName: groupName,
      preSelectedUser: preSelectedUser,
    }
    this.dispatchEvent(evt)
  }

  public groupNode = (onSelected:Function, groupName:any) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.NODE
    evt.payload = {
      onSelected: onSelected,
      groupName: groupName
    }
    this.dispatchEvent(evt)
  }

  public groupPv = (onSelected:Function, groupName:any) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.PV
    evt.payload = {
      onSelected: onSelected,
      groupName: groupName
    }
    this.dispatchEvent(evt)
  }

  public pvCreation = (onSelected:Function, groupName:any) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.PVCREATE
    evt.payload = {
      groupName: groupName,
      onSelected: onSelected
    }
    this.dispatchEvent(evt)
  }

  public portEdit = (id:number, ports:IPortMap[], namespace:string, name:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.PORT_EDIT
    evt.payload = {
      id: id,
      ports: ports,
      namespace: namespace,
      name: name
    }
    this.dispatchEvent(evt)
  }

  public commit = (namespace:string, name:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.COMMIT
    evt.payload = {
      namespace: namespace,
      name: name
    }
    this.dispatchEvent(evt)
  }

  //ops
  public email = (onSelected:Function, preSelected:IEmail[]) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.EMAIL
    evt.payload = {
      onSelected: onSelected,
      selected: preSelected
    }
    this.dispatchEvent(evt)
  }

  public serviceType = (onSelected:Function, preSelected:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.SERVICE_TYPE
    evt.payload = {
      onSelected: onSelected,
      preSelectedProject: preSelected
    }
    this.dispatchEvent(evt)
  }

  public imageOps = (onSelected:Function, jupyterFlag:boolean, namespace:string, isCommon:boolean, reg?:string, image?:string) => { 
    //ops로 명명된것 다시 수정필요함 같은거 분기가 필요할지 또다시의문
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.IMAGE_OPS
    evt.payload = {
      onSelected: onSelected,
      jupyterFlag: jupyterFlag,
      namespace: namespace,
      isCommon: isCommon,
      preSelectedReg: reg,
      preSelectedImage: image
    }
    this.dispatchEvent(evt)
  }

  public volumeOps = (onSelected:Function, idx:number, namespace:string, preSelectedVolume?:string) => { //ops로 명명된것 다시 수정필요함 
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.VOLUME_OPS
    evt.payload = { 
      onSelected: onSelected,
      idx: idx,
      namespace: namespace,
      preSelectedVolume: preSelectedVolume
    }
    this.dispatchEvent(evt)
  }

  public versionDetail = (verName:string, verNamespace:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.VERSION_DETAIL
    evt.payload = {
      name: verName,
      namespace: verNamespace
    }
    this.dispatchEvent(evt)
  }

  public port = (onSelected:Function, data:IServiceItem) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.PORT_OPS
    evt.payload = {
      onSelected: onSelected,
      data: data
    }
    this.dispatchEvent(evt)
  }

  public log = (data:IServiceItem) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.LOG_OPS
    evt.payload = {
      data: data
    }
    this.dispatchEvent(evt)
  }

  public serviceEvent = (data:IServiceItem) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.SERVICE_EVENT
    evt.payload = {
      data: data
    }
    this.dispatchEvent(evt)
  }

  public waitingQueue = (onSelected:Function, resourceGroup:string) => {
    let evt:ModalEvent = new ModalEvent(ModalEvent.OPEN_MODAL)
    evt.modalType = MODAL_TYPE.WAITING_QUEUE
    evt.payload = {
      onSelected: onSelected,
      resourceGroup: resourceGroup
    }
    this.dispatchEvent(evt)
  }
}