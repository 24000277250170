import ApiController from '../controller/ApiController'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from "snakecase-keys"
import { IPortMap } from './Workspace'
import { ERROR_TYPE } from '../interfaces/Error'

export interface IPort {
  internal:number|string|null
  external:number|string|null
  autoFlag:boolean
}

export interface IPortMap2 {
  autoFlag:boolean
  internal:number|string
  external:number|string
}

export class Ports {
  public static async getPorts(id:number) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/workspaces/'+id,{})
    switch (response.status) {
      case 200:
        return response.data
      default:
        console.log('Ports.getPorts error>', response)
        return {
          error: response
        }
    }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async checkDuplicatePort(port:number, id?:number):Promise<any> {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/ports/check', {
        port:port,
        id:id
      })
      switch (response.status) {
        case 200:
          return {
            result: response.data.is_exist
          }
        default:
          console.log('Ports.checkDuplicatePort error>', response)
          return false
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async create(id:number, ports:IPortMap[]): Promise<any> {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.post('/ports/workspaces/'+id, snakecaseKeys(ports))
      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Ports.create error>', response)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }

  }

  public static async update(id:number, ports:IPortMap[]) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/ports/workspaces/' + id, snakecaseKeys(ports))
      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Ports.update error>', response)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  // ops
  public static async createOps(id:number, ports:IPort[]): Promise<any> {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.post('/ports/operations/'+id, snakecaseKeys(ports))
      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Ports.createOps error>', response)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async updateOps(id:number, ports:IPort[]) {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.patch('/ports/operations/'+ id, snakecaseKeys(ports))
      switch (response.status) {
        case 200:
          return response
        default:
          console.log('Ports.updateOps error>', response)
          break
      }
    } catch (error: any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }

  public static async checkDuplicateOpsPort(port:number, id?:number):Promise<any> {
    try {
      const apiController = ApiController.getInstance()
      const response = await apiController.get('/ports/operations/check', {
        port:port,
        id:id
      })
      switch (response.status) {
        case 200:
          return {
            result: response.data.is_exist
          }
        default:
          console.log('Ports.checkDuplicateOpsPort error>', response)
          return false
      }
    } catch(error:any) {
      throw new Error(ERROR_TYPE.ERROR)
    }
  }


}