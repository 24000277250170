import { useState, useRef, useEffect } from "react"
import styled from 'styled-components'
import { IVolume } from "../../../model/Workspace"
import Table, { CHECKBOX_TYPE, ITableHeader, TABLE_CELL_TYPE } from '../uiv2/Table'
import Button, { BUTTON_COLOR, BUTTON_SIZE } from '../uiv2/Button'
import RadioBox from "../uiv2/RadioBox"
import { ResourceGroup } from "../../../model/ResourceGroup"
import PopupController from "../../../controller/PopupController"

interface IVolumeListPopupProps {
  idx: number
  namespace: string
  onClose: Function
  onSelected: Function
  preSelectedVolume: string
  preSelectedVolumes?: IVolume[] // 비활성용 (중복 방지)
  isDev?: boolean
}
interface IVolumeListPopupState {
  items: any[]
  selected: (string | number)[]
  //preSelected: string[] // 비활성용 (중복 방지)
}

const VolumeListPopup = (props:IVolumeListPopupProps) => {

  const [state, setState] = useState<IVolumeListPopupState>({
    items: [],
    selected: [],
  })

  const tableRef = useRef<any>()  //table시도하다 멈춤 없애야함
  const tableColWidth = [42, 312]
  
  const popupController = PopupController.getInstance()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const volumes = (await ResourceGroup.getRgroupVolume(props.namespace)).result.volumeList
  
      const items = []
      for (let eachVolume of volumes) {
        // if(eachVolume.isUserVol === true) {
        //   eachVolume.fakeName = '사용자 개인 볼륨'
        // }
        
        items.push(eachVolume)
      }

      let index = -1
      if (props.preSelectedVolume) {
        // let index = (props.isDev && props.isDev === true) ?
        //   state.items.findIndex(i => i === props.preSelectedVolume?.pvc) :
        //   state.items.findIndex(i => i.name === props.preSelectedVolume?.pvc)
        index = items.findIndex(i => i.name === props.preSelectedVolume)
      }

      setState({
        ...state,
        items: items,
        selected: index >= 0 ? [Number(index)+1] : [0]
      })

    } catch(error) {
      popupController.confirm('에러가 발생했습니다.\n에러코드 - 928f5f')
    }
  }
  const radioHandler = (id: string) => {
    setState({
      ...state,
      selected: [Number(id)]
    })
  }

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const submitHandler = () => {
    if (props.onSelected) {
      if (Number(state.selected[0]) > 0) {
        props.onSelected(state.items[Number(state.selected[0]) - 1])
      }
      else {
        props.onSelected('')
      }
      closeHandler()
    }
  }
  return (
    <VolumeListPopupFragment>
      <div className="titleArea">
        <h1 className="pageTitle"> 데이터 볼륨 </h1>
        <div className="countInfo">총 <b>{state.items.length}</b>개</div>
      </div>
      <>
        <div className="scrollWrap">
          <table>
            <colgroup>
              <col width="45px" />
              <col width="fit-content;" />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <RadioBox id={'0'} checked={state.selected.indexOf(0) !== -1}
                    onChange={(id) => { radioHandler(id) }} />
                </td>
                <td className='col1'><div>{'설정 없음'}</div></td>
              </tr>
              {state.items.length > 0 ?/* 볼륨 리스트 */
                state.items.map((item: any, idx: number) => {
                  let isPreSelected = false //this.props.isDev && this.props.isDev === true? this.state.preSelected?.indexOf(item) !== -1: this.state.preSelected?.indexOf(item.name) !== -1 // 비활성용 (중복 방지)
                  return (
                    <tr key={idx} className={state.selected.indexOf(idx + 1) !== -1 ? 'selected' : ''}>
                      <td className="col0">
                        <RadioBox /* name={this.tableName} */ id={`${idx + 1}`} checked={state.selected.indexOf(idx + 1) !== -1}
                          onChange={(id) => { radioHandler(id) }} disabled={isPreSelected} />
                      </td>
                      <td className={'col1'}>
                        <div style={{display:'flex', alignItems:'center'}}>
                        <span style={{marginRight:'8px'}} className={isPreSelected ? 'disabledData col1' : 'col1'}>{idx + 1}</span>
                        {/* <div className={isPreSelected? 'disabledData' : ''}>{(props.isDev && props.isDev === true)? `${item}`: `${item.name} / ${item.type}`}</div> */}
                        <div className={isPreSelected ? 'disabledData' : ''}>{item.fakeName ? `${item.fakeName}` : `${item.name}`}</div>
                        </div>
                      </td>
                    </tr>
                  )
                })
                : false
              }
            </tbody>
          </table>
        </div>
      </>
      <div className="btnWrap">
        <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={closeHandler}>취소</Button>
        {state.items.length > 0 && state.selected.length > 0 ?
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_PRIMARY} onClickButton={submitHandler}>선택완료</Button> :
          <Button size={BUTTON_SIZE.LARGE} color={BUTTON_COLOR.FILL_DEFAULT} onClickButton={submitHandler} disabled={true}>선택완료</Button>
        }
      </div>
    </VolumeListPopupFragment>
  )
}
const VolumeListPopupFragment = styled.div`
display:flex; flex-direction:column; width:400px; max-height:553px; padding:24px 32px 24px 32px; box-sizing:border-box;
  .titleArea { margin-bottom:0; }
  .pageTitle { font-size:24px; line-height:28.64px; font-weight:700; }
  p { font-size:13px; line-height:15.51px; font-weight:400px; }
  .subInfo { color:#878791; margin-top:12px; }
  .countInfo { margin-top:24px; font-size:13px; }
  .countInfo b { color:#4B82FF; font-weight:700; }
  .scrollWrap { overflow:auto; flex:1; margin-top:8px; max-height:328px;}
	.btnWrap { flex:none; display:flex; justify-content:end; gap:12px; margin-top:24px; }

  //table
  table { width:100%;border-collapse:collapse;border-top:1px solid #333; }
  table tr td { padding:10px 0; font-size:14px; line-height:16.8px; font-weight:400; border-bottom:1px solid #ebebeb; }
  table tr td span { color:#B4B4BE; }
  table tr td div { padding:0.5px 0; }
  .normal { font-size:16px; padding: 0px 16px; }

  .selected .col1{ font-weight:600 !important; }
`

export default VolumeListPopup